import React, { useEffect, useState } from 'react';
import {
  DKLabel,
  DKIcon,
  DKInput,
  DKIcons,
  showAlert
} from 'deskera-ui-library';
import { ILoginPayload } from '../models/Auth';
import { loginUser, selectTenantSettings } from '../store/slices/authSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { setItemInLocalStorage } from '../utility/LocalStorage';
import user_profile from '../assets/images/user_login.png';
import { DEFAULT_THEME_COLOR } from '../constants/Constant';
interface ILoginProps {
  onSignUpClick?: () => void;
}

const Login: React.FC<ILoginProps> = (props) => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [canValidate, setCanValidate] = useState(false);
  const dispatch = useAppDispatch();
  const settings = useAppSelector(selectTenantSettings);

  useEffect(() => {
    const pathName = window.location.pathname?.split("/");
    setItemInLocalStorage(
      'subdomain',
      pathName?.[1] || ""
    );
    return () => {};
  }, []);

  const onLogin = () => {
    const isFormValid = (): boolean => {
      return userName?.trim()?.length !== 0 && password?.trim()?.length !== 0;
    };
    setCanValidate(isFormValid());
    if (!formSubmitted && isFormValid()) {
      setFormSubmitted(true);
      const payload: ILoginPayload = {
        username: userName,
        password: password
      };
      dispatch(loginUser(payload))
        .then((res: any) => {
          if (res?.meta?.requestStatus === 'rejected') {
            showAlert(
              'Invalid Credentials',
              'Please enter a valid Username and Password'
            );
          }
        })
        .finally(() => setFormSubmitted(false));
    }
  };
  return (
    <div
      className="column justify-content-center align-items-center screen-width screen-height position-fixed bg-gray1 z-index-3"
      style={{ top: 0, left: 0 }}
    >
      <div
        className="column bg-white border-radius-m  border-m p-xxl align-items-center justify-content-center"
        style={{ width: 430 }}
      >
        <img
          src={settings?.logo || user_profile}
          alt={user_profile}
          onError={(e: any) => {
            e.target?.setAttribute('src', user_profile);
          }}
          className="ic-l mt-s"
        />
        <DKLabel
          text={`Login to ${settings?.companyName || 'Deskera'} Portal`}
          className=" text-gray fs-l mt-l mb-m"
        />
        <DKInput
          title="Username / Email address"
          direction="vertical"
          type={'email'}
          titleStyle={{ color: 'gray' }}
          className="mt-xxl"
          value={userName}
          canValidate={canValidate}
          onChange={(text: string) => {
            setUserName(text.toLowerCase());
          }}
          autoFocus={true}
        />
        <DKInput
          title="Password"
          direction="vertical"
          type="password"
          titleStyle={{ color: 'gray' }}
          className="mt-l"
          value={password}
          canValidate={canValidate}
          onChange={(text: string) => {
            setPassword(text.toLowerCase());
          }}
        />
        <div
          className={
            'column align-items-center text-white border-radius-s p-r mt-xxl fw-m parent-width ' +
            (formSubmitted ? '' : 'cursor-hand')
          }
          style={{
            backgroundColor: settings?.theme || DEFAULT_THEME_COLOR
          }}
          onClick={onLogin}
        >
          <DKLabel text={formSubmitted ? 'Loading..' : 'Log in'} />
        </div>
      </div>
    </div>
  );
};

export default Login;
