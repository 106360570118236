export const DateType = {
    START: 0,
    END: 1,
    ADDITIONAL: 2,
}

export const DateFormat = {
    DD_MM_YYYY_SLASH: 'dd/mm/yyyy',
    MM_DD_YYYY_SLASH: 'mm/dd/yyyy',
    MMDDYYYY: 'mmddyyyy',
    DDMMYYYY: 'ddmmyyyy',
    DDMMMMYYYY: 'ddmmmmyyyy',
    DD_MM_YYYY_DASH: 'dd-mm-yyyy',
    MM_DD_YYYY_DASH: 'mm-dd-yyyy',
    DDMMMYYYY: 'ddmmmyyyy',
    MMMDDYYYY: 'mmmddyyyy',
    YYYY_MM_DD_DASH: 'yyyy-mm-dd',
    DD_MM_YYYY_HH_MM_SS_SLASH: 'dd/MM/yyyy hh:mm:ss a',
    D_MMM_YYYY_SPACE: 'd mmm yyyy'
}

export const EditorInfoType = {
    documentType: 'documentType',
    companyLogo: 'companyLogo',
    companyName: 'companyName',
    from: 'from',
    clientBillToAddress: 'clientBillToAddress',
    clientShipToAddress: 'clientShipToAddress',
    clientShipFromAddress: 'clientShipFromAddress',
    currency: 'currency',
    refNumber: 'refNumber',
    termsAndCondition: 'termsAndCondition',
    notes: 'notes',
    startDate: 'startDate',
    dueDate: 'dueDate',
    additionalDate: 'additionalDate',

    billToTitle: 'billToTitle',
    shipToTitle: 'shipToTitle',
    shipFromTitle: 'shipFromTitle',

    signature: 'signature',
    logo: 'logo',

    eInvoiceSection: 'eInvoiceSection',
    linkDocumentNumber: 'linkDocumentNumber',
    repeatableHeader: 'repeat ableHeader',
    repeatableHeaderFooter: 'repeatableHeaderFooter',
    repeatableFooter: 'repeatableFooter',
    headerFooter: "headerFooter",
    payslipField: "payslipField",
    payslipTable: "payslipTable",
    payslipOvertime: "payslipOvertime",
    payslipLOP: "payslipLOP",
    termsAndConditionTitle: 'termsAndConditionTitle',
    notesTitle: 'notesTitle',
    additionalLeftFooterTable: 'additionalLeftFooterTable',
    watermark: 'watermark',
    placeOfSupply: 'placeOfSupply',
    sourceOfSupply: 'sourceOfSupply',
    destinationOfSupply: 'destinationOfSupply',
    paymentFooter: "paymentFooter",
    generatedMessage: "generatedMessage",
    documentDetails: "documentDetails",
    topCustomFieldTable: 'topCustomFieldTable',
    bottomCustomFieldTable: 'bottomCustomFieldTable',
    topSectionAboveTable: 'topSectionAboveTable',
    tableColumnAlignment: 'tableColumnAlignment',
    tableColumnVerticalAlignment: 'tableColumnVerticalAlignment',
    payslipEarningsFields: "payslipEarningsFields",
    accountProductTable: 'accountProductTable',
}

export const CurrencyFormatType = {
    SYMBOL: "symbol",
    NAME: "name",
    SYMBOL_NAME: "symbol_name",
    CUSTOM: "custom"
}

export const TableDimensionType = {
    ROW_HEIGHT: "rowHeight",
    PADDING_HORIZONTAL: "paddingHorizontal",
    PADDING_VERTICAL: "paddingVertical",
}
