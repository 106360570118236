import { MOBILE_WIDTH, PAGE_HEIGHT, PAGE_PADDING, PAGE_PRINT_RATIO, PAGE_WIDTH, PAPER_FORMAT, PIXEL_TO_VW, PREVIEW_SCALE, PRINT_SCALE, REDUCE_SPACING_TEMPLATE_ID, TABLE_GRID_TEMPLATE_ID } from "../Constants/Constants";
import { DateFormat, EditorInfoType } from "../Components/TemplateEditors/DocumentEditor";
import { LongMonth, ShortMonth, ShortMonth_3_char } from "../DKUILibrary/dkdatepicker/Constants";
import AppManager from "../Manager/AppManager";
import TemplateSettingsManager from "../Manager/TemplateSettingsManager";
import NumberUtility from "./NumberUtility";
import { PaymentTable } from "../DKUILibrary/dktable/payment/PaymentTable";
import ComponentManager from "../Manager/ComponentManager";
import AddressParser, { OwnerType } from "../Helper/AddressParser";
import { HTMLTag } from "./HTMLTag";
import { eInvoiceSectionPosition } from "../DKUILibrary/DKEInvoiceSection";
import { getLocalisedText } from "../Translate/LanguageManager";
import { DocumentDetailType } from "../Helper/DocumentDetailsParser";
import CustomFieldManager from "../Manager/CustomFieldManager";
import { DESIGNER_TEMPLATE } from "../../../constants/Constant";

export function getVW(value, isVW) {
    var vw = 0
    if (isVW === undefined) {
        vw = value / PIXEL_TO_VW
        if (TemplateSettingsManager.getPrintInfo() !== undefined) {
            vw = vw * PAGE_PRINT_RATIO
        }
        return vw + "vw"
    }
    if (TemplateSettingsManager.getPrintInfo() !== undefined) {
        vw = Number(value.replace('vw', ''))
        return (vw * PAGE_PRINT_RATIO) + "vw"
    }
    return value
}

export function getPX(value) {
    return Number(value.replace('vw', '')) * PIXEL_TO_VW
}

export function getBoundingClientRect(value) {
    var element = document.getElementById(value)
    if (element) {
        return element.getBoundingClientRect()
    }
    return undefined
}



export function getConvertedDate(date, format = null) {
    if (format === null || format === undefined) {
        return date
    } else {
        return getDateString(getDateFrom(date, DateFormat.DD_MM_YYYY_SLASH), format)
    }
}


export function getPageHeight(printScale = PRINT_SCALE) {
    var format = undefined
    var paper = undefined
    if (TemplateSettingsManager.getPrintInfo() !== undefined) {
        var ratio = Number(PAGE_HEIGHT.replace('vw', '')) / Number(PAGE_WIDTH.replace('vw', ''))
        var paddingVW = PAGE_PADDING
        var padding = 4.76 // Number(paddingVW.replace('vw', '')) refer to the app.css margin top and bottom
        var pageCountHeight = 0
        if (TemplateSettingsManager.getRepeatableHeaderFooterIsVisible()) {
            pageCountHeight = 12
        }
        format = TemplateSettingsManager.getPageFormat()
        if (format === undefined || format === null) {
            format = 'a4'
        }

        if (format !== '') {
            format = format.toLowerCase()
        }
        paper = PAPER_FORMAT[format]
        if (paper !== undefined && paper !== null) {
            if (format !== 'a4') {
                ratio = (getPX(getPageWidth()) * paper.ratio / PIXEL_TO_VW / 100)
            }
        }
        if (format === 'a5') {
            padding = 0
        }
        else if (format === 'a4') {
            padding = 0 // Number(paddingVW.replace('vw', '')) * 2
        }
        else if (format === 'three_five' || format === 'four_six') {
            return `${Utility.convertToPrintScale(10, printScale)}vw`;
        }

        //add handling for the watermark section during print
        var watermarkText = getBoundingClientRect(HTMLTag.WATERMARK_TEXT)
        if (watermarkText) {
            var temp = Number(getVW(watermarkText.height).replace('vw', ''))
            padding = padding + temp
        }
        var bottomEInvoice = getBoundingClientRect(HTMLTag.E_INVOICE_PREFIX + 'bottom')
        if (bottomEInvoice) {
            var bottomEInvoiceTemp = Number(getVW(bottomEInvoice.height).replace('vw', ''))
            padding = padding + bottomEInvoiceTemp
        }

        if (Utility.getIsUsingTableGrid()) {
            var tableGridPadding = Number(getVW('1vw', true).replace('vw', ''))
            padding = padding + tableGridPadding
        }

        var pageHeight = ((100 * ratio) - padding - pageCountHeight);
        return `${Utility.convertToPrintScale(pageHeight, printScale)}vw`;
    }
    else {
        format = TemplateSettingsManager.getPageFormat()
        if (format === null || format === undefined) {
            return `${Utility.convertToPrintScale(Number(PAGE_HEIGHT.replace('vw', '')), printScale)}vw`;
        }
        if (format !== '') {
            format = format.toLowerCase()
        }
        paper = PAPER_FORMAT[format]
        if (paper === undefined || paper === null) {
            return `${Utility.convertToPrintScale(Number(PAGE_HEIGHT.replace('vw', '')), printScale)}vw`;
        }
        var defaultWidth = parseFloat(getPageWidth().replace('vw', ''))
        if (defaultWidth === undefined || defaultWidth === null) {
            return `${Utility.convertToPrintScale(Number(PAGE_HEIGHT.replace('vw', '')), printScale)}vw`;
        }

        var pageHeight = (paper.ratio * defaultWidth);
        return `${Utility.convertToPrintScale(pageHeight, printScale)}vw`;
    }
}


export function getPageWidth() {
    if (TemplateSettingsManager.getPrintInfo() !== undefined) {
        var paddingVW = getVW(PAGE_PADDING, true)
        var padding = Number(paddingVW.replace('vw', ''))
        return (100 - (padding * 2)) + 'vw'
    }
    return PAGE_WIDTH
}

export function getFontSizeClass() {
    var textAlign = ''
    if (TemplateSettingsManager.defaultTemplateSettings.languageInfo.code === 'ar') {
        textAlign = 'text-align-right'
    }

    if (TemplateSettingsManager.getPrintInfo() !== undefined) {
        return ' fontSizePrint ' + textAlign
    }
    return ' fontSizeNormal ' + textAlign
}

export function getFontSize(fontSize, ownerType = OwnerType.tenant, printScale = PRINT_SCALE) {
    if (fontSize === undefined || fontSize === null) {
        return undefined
    }
    var additionalFontSize = Utility.getGlobalFontStyle('size', ownerType)
    var fontSizeNum = Number(fontSize.replace('vw', '')) + additionalFontSize
    var id = TemplateSettingsManager.getRemoteTemplateUID();
    if (TemplateSettingsManager.getPrintInfo() !== undefined || TemplateSettingsManager.getRemoteTemplateUID() === undefined) {
        var scale = fontSizeNum * printScale
        if (scale !== undefined && !isNaN(scale)) {
            return scale.toFixed(2) + 'vw'
        }
    }
    return fontSizeNum + 'vw'
}

export function getPaperFormat() {
    return localStorage.getItem('paper_format')
}

export function setPaperFormat(value) {
    return localStorage.setItem('paper_format', value)
}


export function getCapitalized(name) {
    if (name === undefined || name === null) {
        return ''
    }
    if (name.trim() === '') {
        return ''
    }
    let str = name
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getIsAccountModule(data, type) {
    if (data !== undefined && data !== null) {
        if (data.type !== undefined && data.type !== null) {
            if (data.type.toLowerCase() === 'expense' || data.type.toLowerCase() === 'deposit'
                || data.type.toLowerCase() === 'credit_note' || data.type.toLowerCase() === 'debit_note'
                || data.type.toLowerCase() === 'debit name' || data.type.toLowerCase() === 'credit note') {
                return true
            }
            else {
                return false
            }
        }
    }

    if (type !== undefined && type !== null) {
        if (type.toLowerCase() === 'expense' || type.toLowerCase() === 'deposit'
            || type.toLowerCase() === 'credit_note' || type.toLowerCase() === 'debit_note'
            || type.toLowerCase() === 'debit name' || type.toLowerCase() === 'credit note') {
            return true
        }
        else {
            return false
        }
    }

}

export function getIsBillOrInvoice(type) {
    if (type !== undefined && type !== null) {
        if (type.toLowerCase() === 'bill' || type.toLowerCase() === 'invoice') {
            return true
        }
        else {
            return false
        }
    }
    else {
        return false
    }
}

export function getIsExpenseOrDeposit(type) {
    if (type !== undefined && type !== null) {
        if (type.toLowerCase() === 'expense' || type.toLowerCase() === 'deposit') {
            return true
        }
        else {
            return false
        }
    }
    else {
        return false
    }
}

export function getIsDebitOrCreditNote(type) {
    if (type !== undefined && type !== null) {
        if (getIsCreditNote(type) || getIsDebitNote(type)) {
            return true
        }
        else {
            return false
        }
    }
    else {
        return false
    }
}

export function getIsCreditNote(type) {
    if (type !== undefined && type !== null) {
        if (type.toLowerCase() === 'credit_note' || type.toLowerCase() === 'cebit note') {
            return true
        }
        else {
            return false
        }
    }
    else {
        return false
    }
}

export function getIsDebitNote(type) {
    if (type !== undefined && type !== null) {
        if (type.toLowerCase() === 'debit name' || type.toLowerCase() === 'debit_note' || type.toLowerCase() === 'debit note') {
            return true
        }
        else {
            return false
        }
    }
    else {
        return false
    }
}


export function getIsMakePayment(type) {
    if (type !== undefined && type !== null) {
        if (type.toLowerCase() === 'make_payment' || type.toLowerCase() === 'payment') {
            return true
        }
        else {
            return false
        }
    }
    else {
        return false
    }
}

export function getIsReceivePayment(type) {
    if (type !== undefined && type !== null) {
        if (type.toLowerCase() === 'receive_payment' || type.toLowerCase() === 'receipt') {
            return true
        }
        else {
            return false
        }
    }
    else {
        return false
    }
}

export function getIsFulfillment(type) {
    if (type !== undefined && type !== null) {
        if (type.toLowerCase() === 'fulfillment') {
            return true
        }
        else {
            return false
        }
    }
    else {
        return false
    }
}

export function getIsGoodsReceipt(type) {
    if (type !== undefined && type !== null) {
        if (type.toLowerCase() === 'goods_receipt' || type.toLowerCase() === 'good receipt') {
            return true
        }
        else {
            return false
        }
    }
    else {
        return false
    }
}

export function getIsJournalEntry(type) {
    if (type !== undefined && type !== null) {
        if (type.toLowerCase() === 'journal entry' || type.toLowerCase() === 'journal_entry') {
            return true
        }
        else {
            return false
        }
    }
    else {
        return false
    }
}

export function getIsExpenseBill(type, items) {
    if (type !== undefined && type !== null) {
        if (type.toLowerCase() === 'bill') {
            if (items.length > 0) {
                var item = items[0]
                if ((item.quantity === null || item.quantity === undefined) &&
                    (item.unitPrice === null || item.unitPrice === undefined)) {
                    return true
                }
            }
        }
    }
    return false
}

export function getIsPickList(type) {
    if (type !== undefined && type !== null) {
        if (type.toLowerCase() === 'pick_list' || type.toLowerCase() === 'picklist' || type.toLowerCase() === 'pick list') {
            return true
        }
        else {
            return false
        }
    }
    else {
        return false
    }
}

export function getIsPackList(type) {
    if (type !== undefined && type !== null) {
        if (type.toLowerCase() === 'pack_list' || type.toLowerCase() === 'packlist' || type.toLowerCase() === 'pack list') {
            return true
        }
        else {
            return false
        }
    }
    else {
        return false
    }
}

export function getIsShipList(type) {
    if (type !== undefined && type !== null) {
        if (type.toLowerCase() === 'ship_list' || type.toLowerCase() === 'shiplist' || type.toLowerCase() === 'ship list') {
            return true
        }
        else {
            return false
        }
    }
    else {
        return false
    }
}

export function getIsEwayBill(type) {
    if (type !== undefined && type !== null) {
        if (type.toLowerCase() === 'e_way_bill' || type.toLowerCase() === 'ewayBill') {
            return true
        }
        else if (getIsEwayBillSummary(type)) {
            return true
        }
        else if (getIsEwayBillDetails(type)) {
            return true
        }
    }
    else {
        return false
    }
}

export function getIsEwayBillSummary(type) {
    if (type !== undefined && type !== null) {
        if (type.toLowerCase() === 'e_way_bill_summary'
            || type.toLowerCase() === 'eway bill summary'
            || type.toLowerCase() === 'ewayBillSummary') {
            return true
        }
        else {
            return false
        }
    }
    else {
        return false
    }
}

export function getIsEwayBillDetails(type) {
    if (type !== undefined && type !== null) {
        if (type.toLowerCase() === 'e_way_bill_detail'
            || type.toLowerCase() === 'eway bill detail'
            || type.toLowerCase() === 'ewayBillDetail') {
            return true
        }
        else {
            return false
        }
    }
    else {
        return false
    }
}

export function getIsQuotation(type) {
    return (type !== undefined && type !== null) ? (type.toLowerCase() === 'quotation' || type.toLowerCase() === 'quote' || type.toLowerCase() === 'estimate') ? true : false : false
}

export function getIsSalesOrder(type) {
    return (type !== undefined && type !== null) ? (type.toLowerCase() === 'sales order' || (type.toLowerCase() === 'sales_order')) ? true : false : false
}

export function getIsInvoice(type) {
    return (type !== undefined && type !== null) ? (type.toLowerCase() === 'invoice') ? true : false : false
}

export function getIsBill(type) {
    return (type !== undefined && type !== null) ? (type.toLowerCase() === 'bill') ? true : false : false
}

export function getIsOrder(type) {
    return (type !== undefined && type !== null) ? (type.toLowerCase() === 'order') ? true : false : false
}

export function getIsStockTransfer(type) {
    return (type !== undefined && type !== null) ? (type.toLowerCase() === 'stock_transfer') ? true : false : false
}

export function getIsStockAdjustment(type) {
    return (type !== undefined && type !== null) ? (type.toLowerCase() === 'stock_adjustment') ? true : false : false
}

export function getIsProductDocumentForBuySell(data) {
    if (data !== undefined && data !== null) {
        var type = data.type
        var lineItems = data.lineItems
        if (type !== undefined && type !== null && lineItems !== undefined && lineItems !== null) {
            if (getIsQuotation(type)
                || getIsSalesOrder(type)
                || getIsInvoice(type)
                || getIsOrder(type)) {
                return true
            }
            else if (getIsBill(type) && !getIsExpenseBill(type, lineItems)) {
                return true
            }
        }

    }
    return false
}

export function getIsPayslip(type) {
    return (type !== undefined && type !== null) ? (type.toLowerCase() === 'payslip') ? true : false : false
}

export function getIsCheque(type) {
    return (type !== undefined && type !== null) ? (type.toLowerCase() === 'cheque') ? true : false : false
}

export function getIsPayrollCheck(type) {
    return (type !== undefined && type !== null) ? (type.toLowerCase() === 'payroll_check') ? true : false : false
}

export function getIsWorkOrder(type) {
    return (type !== undefined && type !== null) ? (type.toLowerCase() === 'work_order') ? true : false : false
}

export function getIsJobCard(type) {
    return (type !== undefined && type !== null) ? (type.toLowerCase() === 'job_card') ? true : false : false
}

export function getIsPurchaseRequisition(type) {
    return (type !== undefined && type !== null) ? (type.toLowerCase() === 'purchase_requisition') ? true : false : false
}

export function getIsQcTemplate(type) {
    return (type !== undefined && type !== null) ? (type.toLowerCase() === 'qc_template') ? true : false : false
}

export function getTableColumnVisibility() {
    if (TemplateSettingsManager.defaultTemplateSettings === undefined) {
        return false
    }
    var newList = {}
    if (TemplateSettingsManager.defaultTemplateSettings === undefined) {
        return undefined
    }

    if (TemplateSettingsManager.defaultTemplateSettings.tableInfo === undefined) {
        return undefined
    }

    TemplateSettingsManager.defaultTemplateSettings.tableInfo.forEach(column => {
        newList[column.type] = column.isSelected
    });

    return newList
}

export function getIsShowTableColumn(headerColumnList, name) {
    var filterValue = headerColumnList.filter(x => x.name === name)
    if (filterValue === undefined || filterValue === null) {
        return false
    }
    if (filterValue.length === 0) {
        return false
    }
    return filterValue[0].columnOption.isShowColumn
}

export function getDateFrom(dateString, format) {
    if (dateString === undefined && dateString === null) { return undefined }
    // let dateParts = dateString.split('/');
    let dateParts
    let month, year, day
    var monthIndex = -1
    switch (format) {
        case DateFormat.DD_MM_YYYY_SLASH:
            dateParts = dateString.split('/');
            return new Date(dateParts[2], dateParts[1] - 1, dateParts[0])
        case DateFormat.MM_DD_YYYY_SLASH:
            dateParts = dateString.split('/');
            return new Date(dateParts[2], dateParts[0] - 1, dateParts[1])
        case DateFormat.DDMMYYYY:
            day = dateString.slice(0, 2)
            month = dateString.slice(2, 4)
            year = dateString.slice(4, 8)
            return new Date(year, month - 1, day)
        case DateFormat.MMDDYYYY:
            day = dateString.slice(2, 4)
            month = dateString.slice(0, 2)
            year = dateString.slice(4, 8)
            return new Date(year, month - 1, day)
        case DateFormat.DDMMMMYYYY:
            dateParts = dateString.split(' ');
            return new Date(dateParts[2], LongMonth.indexOf(dateParts[1]), dateParts[0])
        case DateFormat.DD_MM_YYYY_DASH:
            dateParts = dateString.split('-');
            return new Date(dateParts[2], dateParts[1] - 1, dateParts[0])
        case DateFormat.MM_DD_YYYY_DASH:
            dateParts = dateString.split('-');
            return new Date(dateParts[2], dateParts[0] - 1, dateParts[1])
        case DateFormat.YYYY_MM_DD_DASH:
            dateParts = dateString.split('-');
            return new Date(dateParts[0], dateParts[1] - 1, dateParts[2])
        case DateFormat.DDMMMYYYY:
            dateParts = dateString.split(' ');
            if (ShortMonth.indexOf(dateParts[1]) >= 0) {
                monthIndex = ShortMonth.indexOf(dateParts[1])
            }
            else if (ShortMonth_3_char.indexOf(dateParts[1]) >= 0) {
                monthIndex = ShortMonth_3_char.indexOf(dateParts[1])
            }
            return new Date(dateParts[2], monthIndex, dateParts[0])
        case DateFormat.MMMDDYYYY:
            var updatedSateString = dateString.replace(',', '')
            dateParts = updatedSateString.split(' ');
            if (ShortMonth.indexOf(dateParts[0]) >= 0) {
                monthIndex = ShortMonth.indexOf(dateParts[0])
            }
            else if (ShortMonth_3_char.indexOf(dateParts[0]) >= 0) {
                monthIndex = ShortMonth_3_char.indexOf(dateParts[0])
            }
            return new Date(dateParts[2], monthIndex, dateParts[1])
        case DateFormat.DD_MM_YYYY_HH_MM_SS_SLASH:
            month = dateString.slice(3, 5)
            day = dateString.slice(0, 2)
            year = dateString.slice(6, 10)
            return new Date(year, month - 1, day)
        case DateFormat.D_MMM_YYYY_SPACE:
            dateParts = dateString.split(' ');
            if (ShortMonth.indexOf(dateParts[1]) >= 0) {
                monthIndex = ShortMonth.indexOf(dateParts[1])
            }
            else if (ShortMonth_3_char.indexOf(dateParts[1]) >= 0) {
                monthIndex = ShortMonth_3_char.indexOf(dateParts[1])
            }
            return new Date(dateParts[2], monthIndex, dateParts[0])
        default:
            dateParts = dateString.split('/');
            return new Date(dateParts[2], dateParts[1] - 1, dateParts[0])
    }
}

export function getDateString(date, format) {
    if (date === undefined) { return undefined }
    var d = date,
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    switch (format) {
        case DateFormat.DD_MM_YYYY_SLASH:
            return [day, month, year].join('/');
        case DateFormat.MM_DD_YYYY_SLASH:
            return [month, day, year].join('/');
        case DateFormat.DDMMYYYY:
            return day + month + year;
        case DateFormat.MMDDYYYY:
            return month + day + year;
        case DateFormat.DDMMMMYYYY:
            return day + " " + LongMonth[month - 1] + " " + year;
        case DateFormat.DD_MM_YYYY_DASH:
            return [day, month, year].join('-');
        case DateFormat.MM_DD_YYYY_DASH:
            return [month, day, year].join('-');
        case DateFormat.YYYY_MM_DD_DASH:
            return [year, month, day].join('-');
        case DateFormat.DDMMMYYYY:
            return day + " " + ShortMonth[month - 1] + " " + year;
        case DateFormat.MMMDDYYYY:
            return ShortMonth[month - 1] + " " + day + ", " + year;
        case DateFormat.D_MMM_YYYY_SPACE:
            return day + ' ' + ShortMonth[month - 1] + ' ' + year;
        default:
            return [day, month, year].join('/');
    }
}

export function convertDateFromERPFormatToDocFormat(date) {
    return date.split('-').join('/')
    // return date.replaceAll("-", "/");
}

export default class Utility {
    static getSharableURL(documentData) {
        let mainURL = String(window.location.href)

        let addressURL = mainURL
        let indexOfQuestionMark = addressURL.indexOf('?')
        if (indexOfQuestionMark !== -1) {
            mainURL = mainURL.substring(0, indexOfQuestionMark)
        }

        let encodedString = Utility.encodeString(JSON.stringify(documentData))
        let sharableLink = mainURL + "?dt=" + encodedString

        return sharableLink
    }

    static encodeString(json) {
        let temp = encodeURI(json)
        return btoa(temp);
    }

    static decodeString(base64String) {
        return JSON.parse(atob(base64String));
    }

    static encodeStringForTemplate(json) {
        console.log('encodeStringForTemplate')
        return btoa(unescape(encodeURIComponent(json)))
    }

    static decodeStringForTemplate(base64String) {
        return JSON.parse(decodeURIComponent(escape(atob(base64String))));
    }

    static isDevice() {
        if (window.screen.width <= 1142) {
            return true
        }
        return false
    }

    static toCurrencyFormat(number, currency) {
        if (currency !== undefined && currency !== null && currency !== '') {
            if (this.isArabicText(currency)) {
                return NumberUtility.getFormatNumber(parseFloat("" + number), AppManager.getDecimalScale(), AppManager.getNumberFormat()) + ' ' + currency
            }
            return currency + ' ' + NumberUtility.getFormatNumber(parseFloat("" + number), AppManager.getDecimalScale(), AppManager.getNumberFormat())
        }
        return NumberUtility.getFormatNumber(parseFloat("" + number), AppManager.getDecimalScale(), AppManager.getNumberFormat())
    }

    static toDecimalFormat(number, decimal) {
        return NumberUtility.getFormatNumber(parseFloat("" + number), decimal, AppManager.getNumberFormat())
    }

    static toCurrency(number, currency) {
        if (currency !== undefined && currency !== null && currency !== '') {
            return currency + ' ' + number
        }
        return number
    }

    static openInNewTab = (url) => {
        window.open(url, '_blank')
    }

    static isValidNumber(number) {
        return number.match(/^[0-9]+$/) === null;
    }
    static isValidEmail(email) {
        // eslint-disable-next-line no-useless-escape
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    ////////////////////////////////////////////////////////
    //////////// START - LOCAL STORAGE FEATURES ////////////
    ////////////////////////////////////////////////////////

    static setPersistentValue(value, key) {
        localStorage.setItem(key, value)
    }

    static getPersistentValue(key) {
        return localStorage.getItem(key)
    }

    static getURLOptionValue(urlOption) {
        return urlOption === undefined || urlOption === null ? false : urlOption.toLowerCase() === 'true' ? true : false
    }

    static getIsMobileView() {
        return window.innerWidth < MOBILE_WIDTH
    }

    static getIsShareMobileView(isSharing) {
        return (this.getIsMobileView() && isSharing)
    }

    static getIsIndiaCountry(data) {
        if (data === undefined || data === null) {
            return false
        }
        if (data.country === undefined || data.country === null) {
            return false
        }
        if (data.country.toLowerCase() === 'in') {
            return true
        }

        return false
    }

    static getIsContainsTotalSection(data) {
        return !getIsFulfillment(data.type) &&
            !getIsGoodsReceipt(data.type) &&
            !getIsJournalEntry(data.type) &&
            !getIsPickList(data.type) &&
            !getIsPackList(data.type) &&
            !getIsShipList(data.type) &&
            !getIsEwayBill(data.type) &&
            !getIsStockTransfer(data.type) &&
            !getIsStockAdjustment(data.type)
    }

    static getTotalValue(data) {
        var currency = NumberUtility.getCurrency(data)
        if (getIsMakePayment(data.type) || getIsReceivePayment(data.type)) {
            if (this.isArabicText(data.currency)) {
                return Utility.toCurrencyFormat(PaymentTable.getTotalAmount(data.lineItems)) + ' ' + data.currency
            }
            return Utility.toCurrencyFormat(PaymentTable.getTotalAmount(data.lineItems), currency)
        }

        var total = ComponentManager.getTotalOfLineItems(data.lineItems, getIsAccountModule(data), false, ComponentManager.getRoundingOffValue(data))
        if (data.total !== undefined) {
            total = data.total
        }

        if (this.isArabicText(data.currency)) {
            return Utility.toCurrencyFormat(total) + ' ' + data.currency
        }

        return Utility.toCurrencyFormat(total, currency)
    }

    static isShowCustomerOrderNumber(data) {
        return !!data.customerOrderNumber;
    }

    static isShowLinkDocument(data) {
        if (getIsFulfillment(data.type) || getIsJournalEntry(data.type) || getIsPackList(data.type) || getIsShipList(data.type)) {
            if (data.linkDocumentType !== '' && data.linkDocumentNumber !== '') {
                if (data.showLinkDocumentNumber) {
                    return data.showLinkDocumentNumber
                }
            }
        }
        else if (TemplateSettingsManager.getRemoteTemplateUID() === null) {
            if (data.showLinkDocumentNumber) {
                return data.showLinkDocumentNumber
            }
        }
        return false
    }

    static getLinkDocumentList(data) {
        var list = []
        if (data !== undefined && data !== null) {
            if (data.linkedDocuments !== undefined && data.linkedDocuments !== null) {
                list = data.linkedDocuments
            }
        }
        return list
    }

    static getInputDirection() {
        if (TemplateSettingsManager.defaultTemplateSettings.languageInfo.code === 'ar') {
            return 'rtl'
        }
        return 'ltr'
    }

    static getIsSaudiArabiaCountry(data) {
        if (data.country === undefined || data.country === null) {
            return false
        }
        if (data.country.toLowerCase() === 'sa') {
            return true
        }

        return false
    }

    static getIsArabicLang() {
        if (TemplateSettingsManager.defaultTemplateSettings.languageInfo.code === 'ar') {
            return true
        }
        return false
    }

    static isArabicText(text) {
        var pattern = /[\u0600-\u06FF\u0750-\u077F]/;
        return pattern.test(text);
    }

    static getCompanyName(data) {
        if (this.getIsArabicLang() && this.getIsSaudiArabiaCountry(data)) {
            return data.companyNameInArabic
        }
        return data.companyName
    }

    static getCompanyAddress(data) {
        if (this.getIsArabicLang() && this.getIsSaudiArabiaCountry(data)) {
            return data.fromInArabic
        }
        return data.from
    }

    static getClientBillToAddress(data) {
        if (this.getIsArabicLang() && this.getIsSaudiArabiaCountry(data)) {
            return data.clientBillToAddressInArabic
        }
        return data.clientBillToAddress
    }

    static getClientShipToAddress(data) {
        if (this.getIsArabicLang() && this.getIsSaudiArabiaCountry(data)) {
            return data.clientShipToAddressInArabic
        }
        return data.clientShipToAddress
    }

    static getClientShipFromAddress(data) {
        return data.clientShipFromAddress
    }

    static getTermsAndConditionTitle(defaultValue) {
        var terms = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.termsAndConditions
        if (terms !== undefined && terms !== null) {
            if (terms.title !== undefined && terms.title !== null) {
                return terms.title
            }
        }
        return defaultValue
    }

    static getNotesTitle(defaultValue) {
        var notes = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.notes
        if (notes !== undefined && notes !== null) {
            if (notes.title !== undefined && notes.title !== null) {
                return notes.title
            }
        }
        return defaultValue
    }


    static getIsProductDocument(data) {
        if (data.type !== undefined && data.type !== null) {
            if (data.type !== '') {
                var type = data.type.toLowerCase()
                if (type === 'quote' || type === 'quotation' || type === 'estimate' || type === 'quote') {
                    return true
                } else if (type === 'invoice') {
                    return true
                }
                else if (type === 'order') {
                    return true
                } else if (type === 'bill') {
                    return true
                }
            }

        }
        return false
    }

    static getPaymentMethod(type, country) {
        if (type !== undefined && type !== null && type !== '') {
            var value = getCapitalized(type.toLowerCase())
            if (type.toLowerCase() === 'bank_transfer') {
                value = 'Bank Transfer'
            }

            if (country !== undefined && country !== null) {
                if (country.toLowerCase() === 'us') {
                    if (type.toLowerCase() === 'cheque') {
                        value = 'Check'
                    }
                }
            }

            return value
        }
        return ''
    }

    static getGlobalFontStyle(property, ownerType) {
        var style = TemplateSettingsManager.defaultTemplateSettings.templateInfo.style
        if (style === undefined || style === null) {
            style = TemplateSettingsManager.getDefaultTemplateStyle()
        }

        if (style !== undefined && style !== null) {
            if (ownerType === OwnerType.contact) {
                if (style.contact !== undefined && style.contact !== null) {
                    if (style.contact.font !== undefined && style.contact.font !== null) {
                        if (style.contact.font[property] !== undefined && style.contact.font[property] !== null) {
                            return style.contact.font[property]
                        }
                    }
                }
            }
            else {
                if (style.font !== undefined && style.font !== null) {
                    if (style.font[property] !== undefined && style.font[property] !== null) {
                        return style.font[property]
                    }
                }
            }
        }
    }


    static calculateMaxOrMinSize(action, currentValue, size, maxRange, minRange) {
        if (action === 'up') {
            currentValue = currentValue + size;
            if (currentValue > maxRange) {
                currentValue = maxRange;
            }
        }
        else if (action === 'down') {
            currentValue = currentValue - size;
            if (currentValue < minRange) {
                currentValue = minRange;
            }
        }
        return currentValue;
    }

    static updateGlobalFontSize(action, ownerType) {
        var fontSize = Utility.getGlobalFontStyle('size', ownerType)
        var size = 0.05
        var maxRange = .5
        var minRange = -0.5

        fontSize = Utility.calculateMaxOrMinSize(action, fontSize, size, maxRange, minRange);

        var style = TemplateSettingsManager.defaultTemplateSettings.templateInfo.style
        if (style === undefined || style === null) {
            style = TemplateSettingsManager.getDefaultTemplateStyle()
        }
        var newStyle = style
        if (ownerType === OwnerType.tenant) {
            newStyle.font.size = fontSize
        }
        else if (ownerType === OwnerType.contact) {
            newStyle.contact.font.size = fontSize
        }
        TemplateSettingsManager.updateTemplateStyle(newStyle)
    }

    static getFontStyleProp(style, printScale) {
        var newStyle = {}
        if (style.fontSize !== undefined) {
            newStyle['fontSize'] = style.fontSize
            var newFontSize = getFontSize(style.fontSize, undefined, printScale)
            if (newFontSize !== undefined) {
                newStyle['fontSize'] = newFontSize
            }
        }
        if (style.color !== undefined) {
            newStyle['color'] = style.color
        }
        if (this.getGlobalFontStyle('family', OwnerType.tenant) !== undefined) {
            newStyle['font-family'] = this.getGlobalFontStyle('family', OwnerType.tenant)
        }
        if (this.getGlobalFontStyle('style', OwnerType.tenant) !== undefined) {
            newStyle['font-style'] = this.getGlobalFontStyle('style', OwnerType.tenant)
        }
        if (this.getGlobalFontStyle('weight', OwnerType.tenant) !== undefined) {
            newStyle['font-weight'] = this.getGlobalFontStyle('weight', OwnerType.tenant)
        }
        if (this.getGlobalFontStyle('isOverrideThemeColor', OwnerType.tenant) !== undefined && this.getGlobalFontStyle('isOverrideThemeColor', OwnerType.tenant) !== null) {
            if (this.getGlobalFontStyle('isOverrideThemeColor', OwnerType.tenant)) {
                if (this.getGlobalFontStyle('color', OwnerType.tenant) !== undefined) {
                    newStyle['color'] = this.getGlobalFontStyle('color', OwnerType.tenant)
                }
            }
        }

        if (style.ownerType !== undefined && style.ownerType !== null) {
            var isContactEnable = this.getGlobalFontStyle('isEnable', style.ownerType)
            if (isContactEnable !== undefined) {
                if (isContactEnable) {
                    if (style.fontSize !== undefined) {
                        newStyle['fontSize'] = getFontSize(style.fontSize, style.ownerType, printScale)
                    }
                    if (style.color !== undefined) {
                        newStyle['color'] = style.color
                    }
                    if (this.getGlobalFontStyle('family', style.ownerType) !== undefined) {
                        newStyle['font-family'] = this.getGlobalFontStyle('family', style.ownerType)
                    }
                    if (this.getGlobalFontStyle('style', style.ownerType) !== undefined) {
                        newStyle['font-style'] = this.getGlobalFontStyle('style', style.ownerType)
                    }
                    if (this.getGlobalFontStyle('weight', style.ownerType) !== undefined) {
                        newStyle['font-weight'] = this.getGlobalFontStyle('weight', style.ownerType)
                    }
                    if (this.getGlobalFontStyle('isOverrideThemeColor', style.ownerType) !== undefined && this.getGlobalFontStyle('isOverrideThemeColor', style.ownerType) !== null) {
                        if (this.getGlobalFontStyle('isOverrideThemeColor', style.ownerType)) {
                            if (this.getGlobalFontStyle('color', style.ownerType) !== undefined) {
                                newStyle['color'] = this.getGlobalFontStyle('color', style.ownerType)
                            }
                        }
                    }
                }
            }
        }
        return newStyle
    }

    static updateGlobalFontFamily(fontFamily, ownerType) {
        var style = TemplateSettingsManager.defaultTemplateSettings.templateInfo.style
        if (style === undefined || style === null) {
            style = TemplateSettingsManager.getDefaultTemplateStyle()
        }
        var newStyle = style
        if (ownerType === OwnerType.tenant) {
            newStyle.font.family = fontFamily
        }
        else if (ownerType === OwnerType.contact) {
            newStyle.contact.font.family = fontFamily
        }
        TemplateSettingsManager.updateTemplateStyle(newStyle)
    }

    static updateGlobalFontStyle(fontStyle, ownerType) {
        var style = TemplateSettingsManager.defaultTemplateSettings.templateInfo.style
        if (style === undefined || style === null) {
            style = TemplateSettingsManager.getDefaultTemplateStyle()
        }
        var newStyle = style
        if (ownerType === OwnerType.tenant) {
            if (newStyle.font.style === 'italic') {
                newStyle.font.style = 'normal'
            }
            else {
                newStyle.font.style = fontStyle
            }
        }
        else if (ownerType === OwnerType.contact) {
            if (newStyle.contact.font.style === 'italic') {
                newStyle.contact.font.style = 'normal'
            }
            else {
                newStyle.contact.font.style = fontStyle
            }
        }
        TemplateSettingsManager.updateTemplateStyle(newStyle)
    }

    static updateGlobalFontWeight(fontWeight, ownerType) {
        var style = TemplateSettingsManager.defaultTemplateSettings.templateInfo.style
        if (style === undefined || style === null) {
            style = TemplateSettingsManager.getDefaultTemplateStyle()
        }
        var newStyle = style
        if (ownerType === OwnerType.tenant) {
            if (newStyle.font.weight === 'bold') {
                newStyle.font.weight = undefined
            }
            else {
                newStyle.font.weight = fontWeight
            }
        }
        else if (ownerType === OwnerType.contact) {
            if (newStyle.contact.font.weight === 'bold') {
                newStyle.contact.font.weight = undefined
            }
            else {
                newStyle.contact.font.weight = fontWeight
            }
        }
        TemplateSettingsManager.updateTemplateStyle(newStyle)
    }

    static updateGlobalFontIsOverrideTextColor(ownerType) {
        var style = TemplateSettingsManager.defaultTemplateSettings.templateInfo.style
        if (style === undefined || style === null) {
            style = TemplateSettingsManager.getDefaultTemplateStyle()
        }
        var newStyle = style
        if (ownerType === OwnerType.tenant) {
            if (newStyle.font.isOverrideThemeColor === undefined || newStyle.font.isOverrideThemeColor === null) {
                newStyle.font.isOverrideThemeColor = false
            }
            newStyle.font.isOverrideThemeColor = !newStyle.font.isOverrideThemeColor
        }
        else if (ownerType === OwnerType.contact) {
            if (newStyle.contact.font.isOverrideThemeColor === undefined || newStyle.contact.font.isOverrideThemeColor === null) {
                newStyle.contact.font.isOverrideThemeColor = false
            }
            newStyle.contact.font.isOverrideThemeColor = !newStyle.contact.font.isOverrideThemeColor
        }
        TemplateSettingsManager.updateTemplateStyle(newStyle)
    }

    static updateGlobalFontTextColor(textColor, ownerType) {
        var style = TemplateSettingsManager.defaultTemplateSettings.templateInfo.style
        if (style === undefined || style === null) {
            style = TemplateSettingsManager.getDefaultTemplateStyle()
        }
        var newStyle = style
        if (ownerType === OwnerType.tenant) {
            if (newStyle.font.color === undefined || newStyle.font.color === null) {
                newStyle.font.color = ''
            }
            newStyle.font.color = textColor
        }
        else if (ownerType === OwnerType.contact) {
            if (newStyle.contact.font.color === undefined || newStyle.contact.font.color === null) {
                newStyle.contact.font.color = ''
            }
            newStyle.contact.font.color = textColor
        }
        TemplateSettingsManager.updateTemplateStyle(newStyle)
    }



    static updateGlobalIsEnableContactText(ownerType) {
        var style = TemplateSettingsManager.defaultTemplateSettings.templateInfo.style
        if (style === undefined || style === null) {
            style = TemplateSettingsManager.getDefaultTemplateStyle()
        }
        var newStyle = style
        if (ownerType === OwnerType.contact) {
            if (newStyle.contact.font.isEnable === undefined || newStyle.contact.font.isEnable === null) {
                newStyle.contact.font.isEnable = false
            }
            newStyle.contact.font.isEnable = !newStyle.contact.font.isEnable
        }
        TemplateSettingsManager.updateTemplateStyle(newStyle)
    }


    static getOwnerType(data, addressType) {
        var addressObjName = AddressParser.getDataAddressField(addressType)

        if (addressObjName !== undefined) {
            if (data[addressObjName] !== undefined) {
                var ownerType = data[addressObjName].ownerType
                if (ownerType !== undefined) {
                    return ownerType
                }
            }
        }

        return OwnerType.tenant
    }

    static getImageSize(type) {
        var size = 0
        if (EditorInfoType.logo === type) {
            var logoProps = TemplateSettingsManager.defaultTemplateSettings.documentInfo.companyDetails.logo
            if (logoProps.size !== undefined && logoProps.size !== null) {
                size = logoProps.size
            }
        }
        else if (EditorInfoType.signature === type) {
            var signatureProps = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.signature
            if (signatureProps.size !== undefined && signatureProps.size !== null) {
                size = signatureProps.size
            }
        }
        return size
    }

    static updateImageSize(action, type) {
        var imageSize = Utility.getImageSize(type)
        var size = 2
        var maxRange = 64
        var minRange = -30

        if (action === 'up') {
            imageSize = imageSize + size
            if (imageSize > maxRange) {
                imageSize = maxRange
            }
        }
        else if (action === 'down') {
            imageSize = imageSize - size
            if (imageSize < minRange) {
                imageSize = minRange
            }
        }

        if (EditorInfoType.logo === type) {
            TemplateSettingsManager.updateCompanyLogoImageSize(imageSize)
        }
        else if (EditorInfoType.signature === type) {
            TemplateSettingsManager.updateSignatureImageSize(imageSize)
        }
    }

    static getIsReceiptPaperSize() {
        if (TemplateSettingsManager.getPageFormat() !== undefined &&
            TemplateSettingsManager.getPageFormat() !== null) {
            return (TemplateSettingsManager.getPageFormat() === 'three_five' ||
                TemplateSettingsManager.getPageFormat() === 'four_six')
        }
        return false
    }

    static getIsTemplateChangeRequired(oldPaperSize, newPaperSize) {
        var isRequired = false
        if (oldPaperSize === undefined || oldPaperSize === null) {
            return isRequired
        }
        if (newPaperSize === undefined || newPaperSize === null) {
            return isRequired
        }
        if (oldPaperSize !== newPaperSize) {
            if (newPaperSize === 'three_five') {
                if (oldPaperSize !== 'four_six') {
                    isRequired = true
                }
            }
            else if (newPaperSize === 'four_six') {
                if (oldPaperSize !== 'three_five') {
                    isRequired = true
                }
            }
            else {
                if (oldPaperSize === 'three_five' || oldPaperSize === 'four_six') {
                    isRequired = true
                }
            }
        }

        return isRequired
    }

    static getIsShowTermAndCondition(data) {
        if (data.showTermsAndCondition === undefined || data.termsAndCondition === undefined) {
            return false
        }

        if (data.showTermsAndCondition && data.termsAndCondition !== '') {
            return true
        }

        return false
    }

    static getIsShowNotes(data) {
        if (data.showNotes === undefined || data.notes === undefined) {
            return false
        }

        if (data.showNotes && data.notes !== '') {
            return true
        }

        return false
    }

    static getIsShowSignature(data) {
        if (data.showSignature !== undefined) {
            return data.showSignature
        }

        return false
    }

    static getGlobalTableFontStyle() {
        var style = TemplateSettingsManager.defaultTemplateSettings.templateInfo.style
        if (style === undefined || style === null) {
            style = TemplateSettingsManager.getDefaultTemplateStyle()
        }

        if (style.table === undefined || style.table === null) {
            let font = TemplateSettingsManager.getDefaultTableStyle()
            style.table = font
        }

        if (style.table.font === undefined || style.table.font === null) {
            style.table['font'] = TemplateSettingsManager.getDefaultTableStyle()
        }

        return style
    }

    static getGlobalTableFontStyleWithProperty(property) {
        var style = this.getGlobalTableFontStyle()
        if (style.table.font[property] !== undefined && style.table.font[property] !== null) {
            return style.table.font[property]
        }

        return undefined
    }

    static updateGlobalTableIsEnable() {
        var style = this.getGlobalTableFontStyle()
        style.table.font.isEnable = !style.table.font.isEnable
        TemplateSettingsManager.updateTemplateStyle(style)
    }


    static updateGlobalTableProperty(property, value) {
        var style = this.getGlobalTableFontStyle()
        if (property === 'style') {
            if (style.table.font.style === 'italic') {
                style.table.font.style = 'normal'
            }
            else {
                style.table.font.style = value
            }
        }
        else if (property === 'weight') {
            if (style.table.font.weight === 'bold') {
                style.table.font.weight = undefined
            }
            else {
                style.table.font.weight = value
            }
        }
        else {
            style.table.font[property] = value
        }
        TemplateSettingsManager.updateTemplateStyle(style)
    }

    static updateGlobalTableFontSize(action) {
        var style = this.getGlobalTableFontStyle()
        var fontSize = style.table.font.size
        var size = 0.05
        var maxRange = .5
        var minRange = -0.5

        if (action === 'up') {
            fontSize = fontSize + size
            if (fontSize > maxRange) {
                fontSize = maxRange
            }
        }
        else if (action === 'down') {
            fontSize = fontSize - size
            if (fontSize < minRange) {
                fontSize = minRange
            }
        }

        this.updateGlobalTableProperty('size', fontSize)
    }

    static updateGlobalTableDimensionByProperty(property) {
        var style = this.getGlobalTableFontStyle()
        if (style.table.font[property] !== undefined && style.table.font[property] !== null) {
            style.table.font[property] = !style.table.font[property]
        }
        TemplateSettingsManager.updateTemplateStyle(style)
    }

    static updateGlobalTableRowHeightSize(action) {
        var style = this.getGlobalTableFontStyle()
        var rowHeight = style.table.font.rowHeight
        var size = 2.5
        var maxRange = 100
        var minRange = 0

        rowHeight = Utility.calculateMaxOrMinSize(action, rowHeight, size, maxRange, minRange);

        this.updateGlobalTableProperty('rowHeight', rowHeight)
    }

    static updateGlobalTablePadding(fieldName, action) {
        var style = this.getGlobalTableFontStyle()
        var paddingSize = style.table.font[fieldName]
        var size = 0.05
        var maxRange = .5
        var minRange = -0.5

        paddingSize = Utility.calculateMaxOrMinSize(action, paddingSize, size, maxRange, minRange);

        this.updateGlobalTableProperty(fieldName, paddingSize)
    }

    static getTableFontSize(fontSize, printScale = PRINT_SCALE) {
        if (fontSize === undefined || fontSize === null) {
            return undefined
        }
        var additionalFontSize = Utility.getGlobalTableFontStyleWithProperty('size')
        var fontSizeNum = Number(fontSize.replace('vw', '')) + additionalFontSize
        if (TemplateSettingsManager.getPrintInfo() !== undefined) {
            var scale = fontSizeNum * printScale
            if (scale !== undefined && !isNaN(scale)) {
                return scale.toFixed(2) + 'vw'
            }
        }
        return fontSizeNum + 'vw'
    }

    static getIsShowPlaceOfSupply(data) {
        if (data.placeOfSupply) {
            return TemplateSettingsManager.getSupplyConfigurationVisibility('place')
        }
        return false
    }

    static getIsShowSourceOfSupply(data) {
        if (data.sourceOfSupply) {
            return TemplateSettingsManager.getSupplyConfigurationVisibility('source')
        }
        return false
    }
    static getIsShowDestinationOfSupply(data) {
        if (data.destinationOfSupply) {
            return TemplateSettingsManager.getSupplyConfigurationVisibility('destination')
        }
        return false
    }

    static getIsShowEInvoiceCorrectPosition(selectedPosition) {
        let position = TemplateSettingsManager.getEInvoiceSectionPosition()
        if (position !== undefined && position !== null) {
            return position === selectedPosition
        }
        return false
    }

    static getLogoTopPositionForEInvoice(data, top, defaultValue) {
        if (data.showEInvoiceSection) {
            var position = TemplateSettingsManager.getEInvoiceSectionPosition()
            if (position === eInvoiceSectionPosition.top) {
                var totalOffset = top + TemplateSettingsManager.getEInvoiceSectionQRResizeValue()
                return getVW(totalOffset)
            }
        }
        return getVW(defaultValue)
    }

    static getIsContainsCustomField(position) {
        var customFieldList = TemplateSettingsManager.defaultTemplateSettings.documentInfo.customFieldList

        if (position !== undefined && position !== null) {
            if (position === 'bottom') {
                customFieldList = TemplateSettingsManager.defaultTemplateSettings.documentInfo.bottomCustomFieldList
            }
        }

        if (customFieldList !== undefined && customFieldList !== null) {
            customFieldList = customFieldList.filter(x => x.isSelected)
            return customFieldList.length > 0
        }
        return false
    }

    static getGeneratedMessage(type) {
        var customText = TemplateSettingsManager.getGeneratedMessage().customText
        if (customText !== undefined && customText !== null) {
            if (customText.length > 0) {
                if (customText === 'print_only_message') {
                    return getLocalisedText(customText) + ' ' + getLocalisedText(type)
                }
                return getLocalisedText(customText)
            }
            return getLocalisedText('')
        }
        return getLocalisedText('print_only_message') + ' ' + getLocalisedText(type)
    }

    static getGeneratedVisibility() {
        var generatedMessage = TemplateSettingsManager.getGeneratedMessage()
        if (generatedMessage !== undefined && generatedMessage !== null) {
            return generatedMessage.isVisible
        }
        return false
    }

    static getGeneratedMessagePaddingBottom(spacingRatio) {
        var defaultPadding = PAGE_PADDING
        var templateId = TemplateSettingsManager.getTemplateID()
        if (REDUCE_SPACING_TEMPLATE_ID.includes(templateId)) {
            var value = Number(defaultPadding.replace('vw', ''))
            if (!isNaN(value)) {
                value = value * spacingRatio
                defaultPadding = value + 'vw'
            }
        }

        return getVW(PAGE_PADDING, true)
    }

    static getDocumentDetailsList(data) {
        var list = TemplateSettingsManager.getDocumentDetailsList(data)
        list = list.filter(x => x.isSelected)
        return this.getCustomFieldValue(data, list)
    }

    static getCustomFieldValue(data, list) {
        list.forEach(element => {
            if (element.type === DocumentDetailType.customField) {
                element.customFieldValue = CustomFieldManager.parseCustomField(data, element.code)
            }
        });
        return list
    }

    static getIsUsingTableGrid() {
        var templateId = TemplateSettingsManager.getTemplateID()
        return (TABLE_GRID_TEMPLATE_ID.includes(templateId))
    }

    static getIsReduceSpacingTemplate() {
        var templateId = TemplateSettingsManager.getTemplateID()
        return (REDUCE_SPACING_TEMPLATE_ID.includes(templateId)) || getIsWorkOrder(AppManager.getDocumentType())
    }

    static getReduceSpacingTablePadding(isPaddingEnable) {
        if (this.getIsReduceSpacingTemplate() && !isPaddingEnable) {
            return -0.5
        }
        return undefined
    }

    static getIsShowAccountProductTable(documentData) {
        var isVisible = TemplateSettingsManager.getTableColumnForAccountProductIsVisible()

        if(isVisible) {
            var isContains = false
            if(documentData !== undefined && documentData !== null) {
                if(documentData.productLineItems !== undefined && documentData.productLineItems !== null) {
                    if(documentData.productLineItems.length > 0) {
                        isContains = true
                    }
                }
            }
            isVisible = isContains
        }
        return isVisible
    }

    static getTableConfig(tableType) {
        var tableConfig = TemplateSettingsManager.defaultTemplateSettings.tableInfo

        if(tableType !== undefined && tableType !== null) {
            if(tableType !== '') {
                tableConfig = Utility.getTableColumnForAccountProduct()
            }
        }

        return tableConfig
    }

    static getTableColumnForAccountProduct() {
        return TemplateSettingsManager.getTableColumnForAccountProduct()
    }

    static getProductTableConfiguration(key) {
        var value = undefined
        var productTableConfiguration = TemplateSettingsManager.defaultTemplateSettings.productTableConfiguration
        if (productTableConfiguration === undefined || productTableConfiguration === null) {
            productTableConfiguration = TemplateSettingsManager.getDefaultProductTableConfiguration()
        }

        if (productTableConfiguration[key] !== undefined && productTableConfiguration[key] !== undefined) {
            value = productTableConfiguration[key]
        }
        else {
            productTableConfiguration = TemplateSettingsManager.getDefaultProductTableConfiguration()
            if (productTableConfiguration[key] !== undefined && productTableConfiguration[key] !== undefined) {
                value = productTableConfiguration[key]
            }
        }
        return value
    }

    static convertToPrintScale(size, printScale) {
        if (typeof size === "number") {
            return (size / PRINT_SCALE) * printScale;
        }

        return size;
    }

    static getProductTableConfiguration(key) {
        var value = undefined
        var productTableConfiguration = TemplateSettingsManager.defaultTemplateSettings.productTableConfiguration
        if (productTableConfiguration === undefined || productTableConfiguration === null) {
            productTableConfiguration = TemplateSettingsManager.getDefaultProductTableConfiguration()
        }

        if (productTableConfiguration[key] !== undefined && productTableConfiguration[key] !== undefined) {
            value = productTableConfiguration[key]
        }
        else {
            productTableConfiguration = TemplateSettingsManager.getDefaultProductTableConfiguration()
            if (productTableConfiguration[key] !== undefined && productTableConfiguration[key] !== undefined) {
                value = productTableConfiguration[key]
            }
        }
        return value
    }
    static roundOffToTenantDecimalScale(val, decimalScale) {
    const tenantDecimalScale = AppManager.getDecimalScale();
    return Utility.roundingOff(
      val,
      decimalScale ? decimalScale : tenantDecimalScale ? tenantDecimalScale : 2
    );
    }
    static roundingOff(val,precisionVal) {
        val = Number(val);
        val = val + 1 / Math.pow(10, precisionVal + 10);
        var newnumber =
          Math.round(val * Math.pow(10, precisionVal)) / Math.pow(10, precisionVal);
        return newnumber;
      }
}

