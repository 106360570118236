export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const ENDPOINTS = {
  AUTH: {
    LOGIN: (hash: string) => `/v1/client-portal/openep/${hash}/login`,
    STATUS: (subDomain: string) =>
      `/v1/client-portal/cp-user/${subDomain}/status`
  },
  SETTINGS: {
    GET: (subDomain: string) => `/v1/client-portal/openep/${subDomain}/settings`
  },
  TABLES: {
    GET: `/v1/client-portal/cp-user/table`
  },
  QUOTES: {
    GET: `/v1/client-portal/cp-user/books/quotations`
  },
  PRODUCT: {
    GET: `/v1/client-portal/cp-user/books/products`
  },
  SALES_ORDERS: {
    GET: (countryCode: string) =>
      `v1/client-portal/cp-user/books/invoices/salesorder/${countryCode}`,
    CREATE: (countryCode: string) =>
      `v1/client-portal/cp-user/books/invoices/salesorder/${countryCode}`
  },
  PURCHASE_ORDERS: {
    GET: `v1/client-portal/cp-user/books/orders/purchase`
  },
  INVOICES: {
    GET: `/v1/client-portal/cp-user/books/invoices/sales/in`,
    SUMMARY: `/v1/client-portal/cp-user/books/reports/document/sales/summary`
  },
  PAYMENTS: {
    GET: `/v1/client-portal/cp-user/books/payments/receive/deposits`
  },
  PRINT: {
    GET_PRINT_INFO: `/v1/client-portal/cp-user/books/template/template-print-info`,
    GET_PRINT_INFO_BY_ID: (id: string) =>
      `/v1/client-portal/cp-user/books/template/print-info/${id}`,
    PRINT_DESIGN_DOC_BY_ID: (id: string) =>
      `/v1/client-portal/cp-user/books/template/print-info/print-design-doc-by-uid/${id}`
  },
  CONTACT: {
    GET_LOGGED_IN_USER: (country: string) =>
    `/v1/client-portal/cp-user/books/contact/${country}`
  },
  CART: {
    GET: `/v1/client-portal/cp-user/books/cart`,
    ADD_BULK: `/v1/client-portal/cp-user/books/cart/bulk`,
    UPDATE_BULK: `/v1/client-portal/cp-user/books/cart/bulk`,
    DELETE_BULK: `/v1/client-portal/cp-user/books/cart/bulk`
  },
  FILES: {
    GET: `/v1/client-portal/cp-user/books/attachment/getAttachments`,
    DOWNLOAD: `/v1/client-portal/cp-user/books/attachment/download`,
    DELETE: `/v1/client-portal/cp-user/books/attachment/delete`,
    UPLOAD: `/v1/client-portal/cp-user/books/attachment/upload`,
    GOOGLE_VIEWER: (filePath: string) =>
      `https://docs.google.com/viewer?url=${filePath}&embedded=true`,
    OFFICE_VIEWER: (filePath: string) =>
      `https://view.officeapps.live.com/op/embed.aspx?src=${filePath}`
  },
  TENANT: {
    DETAILS: `/v1/client-portal/cp-user/books/tenants/details`
  }
};
