import axios from 'axios';
import { BASE_URL } from '../constants/EndPoints';
import { getItemFromLocalStorage } from '../utility/LocalStorage';
import {
  COUNTRY_CODES,
  COUNTRY_SPECIFIC_URL_CODE
} from '../constants/Constant';
import store from '../store';

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true
});

const requestInterceptSuccess = (config: any) => {
  config['headers']['x-access-token'] =
    getItemFromLocalStorage('x-access-token');
  return config;
};
const requestInterceptError = (error: any) => {
  return Promise.reject(error);
};
const responseInterceptSuccess = (response: any) => {
  return response?.data;
};
const responseInterceptError = (error: any) => {
  return Promise.reject({
    error: error?.response?.data,
    status: error?.status
  });
};

axiosInstance.interceptors.request.use(
  (response: any) => requestInterceptSuccess(response),
  (error: any) => requestInterceptError(error)
);

axiosInstance.interceptors.response.use(
  (response: any) => responseInterceptSuccess(response),
  (error: any) => responseInterceptError(error)
);

export default axiosInstance;
