import ApiConstants from "../API/ApiConstants";
import Utility, { getIsBill, getIsCheque, getIsInvoice, getIsOrder, getIsPayslip, getIsPayrollCheck, getIsQuotation, getIsShipList, getIsSalesOrder, getIsEwayBillDetails, getIsEwayBillSummary, getIsWorkOrder, getIsJobCard, getIsPurchaseRequisition, getIsQcTemplate  } from "../Utilities/Utility";
export default class TemplateManager {

  static getAllTemplates(type, data) {
    if(this.getIsLabelType(type)) {
      return this.getAllLabelTemplates()
    }
    else if (getIsPayslip(type)) {
      return this.getAllPayslipTemplates(data)
    }
    else if (getIsCheque(type)) {
      return this.getAllChequeTemplates()
    }
    else if (getIsPayrollCheck(type)) {
      return this.getAllPayrollChequeTemplates(data)
    }
    else if (Utility.getIsReceiptPaperSize()) {
      return this.getAllReceiptTemplates()
    }
    else if (getIsEwayBillDetails(type)) {
      return this.getAllEWayBillDetailsTemplates()
    }
    else if (getIsEwayBillSummary(type)) {
      return this.getAllEWayBillSummaryTemplates()
    }
    else if (getIsWorkOrder(type)) {
      return this.getAllWorkOrderTemplates()
    }
    else if (getIsJobCard(type)) {
      return this.getAllJobCardTemplates()
    }
    else if (getIsPurchaseRequisition(type)) {
      return this.getAllPurchaseRequisitionTemplates()
    }
    else if (getIsQcTemplate(type)) {
      return this.getAllQCTemplates()
    }

    return this.getAllDocumentTemplates(type, data)
  }

  static getInitTemplate(type) {
    if (getIsPayslip(type)) {
        return this.getPeopleInitTemplate()
    }

    return 1//36 // should be 1
  }

  static getPeopleInitTemplate(country) {
    var countryCode = '';
    if (country !== undefined) {
      countryCode = country;
    } else {
      countryCode = ApiConstants.TENANT_COUNTRY;
    }

    switch(countryCode) {
        case "IN":
            return 1;
        case "US":
            return 2;
        case "SG":
            return 3
        case "MY":
            return 4;
        default:
            return 5;
    }
  }

  static getAllDocumentTemplates(type, data) {
    var defaultTemplate = this.getDefaultDocumentTemplates()
    if (Utility.getIsIndiaCountry(data)) {
      if (getIsQuotation(type)
        || getIsSalesOrder(type)
        || getIsInvoice(type)
        || getIsBill(type)
        || getIsOrder(type)) {
        var newTemplates = this.getIndiaDocumentTemplates(type)
        defaultTemplate.forEach(template => {
          newTemplates.push(template)
        });
        return newTemplates
      }
    }
    return defaultTemplate
  }

  static getIndiaDocumentTemplates(type) {
    var newTemplates = []
    newTemplates.push({
      id: 36,
      thumbnailImage: require("../Assets/Template Samples/Template_36.png"),
    })
    newTemplates.push({
      id: 38,
      thumbnailImage: require("../Assets/Template Samples/Template_38.png"),
    })
    newTemplates.push({
      id: 40,
      thumbnailImage: require("../Assets/Template Samples/Template_40.png"),
    })
    newTemplates.push({
      id: 42,
      thumbnailImage: require("../Assets/Template Samples/Template_42.png"),
    })
    newTemplates.push({
      id: 43,
      thumbnailImage: require("../Assets/Template Samples/Template_43.png"),
    })
    newTemplates.push({
      id: 46,
      thumbnailImage: require("../Assets/Template Samples/Template_36.png"),
    })
    newTemplates.push({
      id: 47,
      thumbnailImage: require("../Assets/Template Samples/Template_40.png"),
    })
    newTemplates.push({
      id: 48,
      thumbnailImage: require("../Assets/Template Samples/Template_43.png"),
    })

    if (getIsBill(type) || getIsOrder(type)) {
        //check if purchase order or bill module
      newTemplates.push({
        id: 44,
        thumbnailImage: require("../Assets/Template Samples/Template_44.png"),
      })
      newTemplates.push({
        id: 45,
        thumbnailImage: require("../Assets/Template Samples/Template_45.png"),
      })
    }

    return newTemplates
  }

  static getDefaultDocumentTemplates() {
    return [
      {
        id: 1,
        thumbnailImage: require("../Assets/Template Samples/Template_1.png"),
      },
      {
        id: 2,
        thumbnailImage: require("../Assets/Template Samples/Template_2.png"),
      },
      {
        id: 3,
        thumbnailImage: require("../Assets/Template Samples/Template_3.png"),
      },
      {
        id: 4,
        thumbnailImage: require("../Assets/Template Samples/Template_4.png"),
      },
      {
        id: 5,
        thumbnailImage: require("../Assets/Template Samples/Template_5.png"),
      },
      {
        id: 6,
        thumbnailImage: require("../Assets/Template Samples/Template_6.png"),
      },
      {
        id: 7,
        thumbnailImage: require("../Assets/Template Samples/Template_7.png"),
      },
      {
        id: 8,
        thumbnailImage: require("../Assets/Template Samples/Template_8.png"),
      },
      {
        id: 9,
        thumbnailImage: require("../Assets/Template Samples/Template_9.png"),
      },
      {
        id: 10,
        thumbnailImage: require("../Assets/Template Samples/Template_10.png"),
      },
      {
        id: 11,
        thumbnailImage: require("../Assets/Template Samples/Template_11.png"),
      },
      {
        id: 12,
        thumbnailImage: require("../Assets/Template Samples/Template_12.png"),
      },
      {
        id: 13,
        thumbnailImage: require("../Assets/Template Samples/Template_13.png"),
      },
      {
        id: 14,
        thumbnailImage: require("../Assets/Template Samples/Template_14.png"),
      },
      {
        id: 15,
        thumbnailImage: require("../Assets/Template Samples/Template_15.png"),
      },
      {
        id: 16,
        thumbnailImage: require("../Assets/Template Samples/Template_16.png"),
      },
      {
        id: 17,
        thumbnailImage: require("../Assets/Template Samples/Template_17.png"),
      },
      {
        id: 18,
        thumbnailImage: require("../Assets/Template Samples/Template_18.png"),
      },
      {
        id: 19,
        thumbnailImage: require("../Assets/Template Samples/Template_19.png"),
      },
      {
        id: 20,
        thumbnailImage: require("../Assets/Template Samples/Template_20.png"),
      },
      {
        id: 21,
        thumbnailImage: require("../Assets/Template Samples/Template_21.png"),
      },
      {
        id: 22,
        thumbnailImage: require("../Assets/Template Samples/Template_22.png"),
      },
      {
        id: 23,
        thumbnailImage: require("../Assets/Template Samples/Template_23.png"),
      },
      {
        id: 24,
        thumbnailImage: require("../Assets/Template Samples/Template_24.png"),
      },
      {
        id: 25,
        thumbnailImage: require("../Assets/Template Samples/Template_25.png"),
      },
      {
        id: 26,
        thumbnailImage: require("../Assets/Template Samples/Template_26.png"),
      },
      {
        id: 27,
        thumbnailImage: require("../Assets/Template Samples/Template_27.png"),
      },
      {
        id: 28,
        thumbnailImage: require("../Assets/Template Samples/Template_28.png"),
      },
      {
        id: 29,
        thumbnailImage: require("../Assets/Template Samples/Template_29.png"),
      },
      {
        id: 30,
        thumbnailImage: require("../Assets/Template Samples/Template_30.png"),
      },
      {
        id: 31,
        thumbnailImage: require("../Assets/Template Samples/Template_31.png"),
      },
      {
        id: 32,
        thumbnailImage: require("../Assets/Template Samples/Template_32.png"),
      },
      {
        id: 33,
        thumbnailImage: require("../Assets/Template Samples/Template_33.png"),
      },
      {
        id: 34,
        thumbnailImage: require("../Assets/Template Samples/Template_34.png"),
      },
      {
        id: 35,
        thumbnailImage: require("../Assets/Template Samples/Template_35.png"),
      },
      {
        id: 37,
        thumbnailImage: require("../Assets/Template Samples/Template_37.png"),
      },
      {
        id: 39,
        thumbnailImage: require("../Assets/Template Samples/Template_39.png"),
      },
      {
        id: 41,
        thumbnailImage: require("../Assets/Template Samples/Template_41.png"),
      },
      {
        id: 49,
        thumbnailImage: require("../Assets/Template Samples/Template_2.png"),
      },
      {
        id: 50,
        thumbnailImage: require("../Assets/Template Samples/Template_3.png"),
      },
    ];
  }


  static getAllLabelTemplates() {
    return [
      {
        id: 1,
        thumbnailImage: require("../Assets/Label Samples/Label_Template_1.png"),
      },
    ];
  }

  static getAllEWayBillDetailsTemplates() {
    return [
      {
        id: 1,
        thumbnailImage: require("../Assets/EWayBillDetails Sample/EWayBill_Template_1.png"),
      },
    ];
  }

  static getAllEWayBillSummaryTemplates() {
    return [
      {
        id: 1,
        thumbnailImage: require("../Assets/EWayBillSummary Sample/EWayBill_Template_1.png"),
      },
    ];
  }

  static getAllChequeTemplates() {
    const country = ApiConstants.TENANT_COUNTRY;
    switch(country) {
      case 'US':
        return [
          { id: 1, thumbnailImage: require("../Assets/Cheque Samples/Cheque_Template_1.png") },
          { id: 2, thumbnailImage: require("../Assets/Cheque Samples/Cheque_Template_1.png") },
          { id: 3, thumbnailImage: require("../Assets/Cheque Samples/Cheque_Template_1.png") },
          { id: 4, thumbnailImage: require("../Assets/Cheque Samples/Cheque_Template_1.png") }
        ]
      default:
        return [
          { id: 1, thumbnailImage: require("../Assets/Cheque Samples/Cheque_Template_1.png") },
          { id: 2, thumbnailImage: require("../Assets/Cheque Samples/Cheque_Template_1.png") },
          { id: 3, thumbnailImage: require("../Assets/Cheque Samples/Cheque_Template_1.png") }
        ]
    }
  }

  static getAllReceiptTemplates() {
    return [
      {
        id: 1,
        thumbnailImage: require("../Assets/Receipt Sample/Receipt_Template_1.png"),
      },
      {
        id: 2,
        thumbnailImage: require("../Assets/Receipt Sample/Receipt_Template_2.png"),
      },
    ];
  }

  static getAllPayrollChequeTemplates(data) {
    const country = data.country || ApiConstants.TENANT_COUNTRY;
    switch (country) {
        case "US":
            return [
                {
                  id: 1,
                  thumbnailImage: require("../Assets/PayrollChequeSamples/USPayrollChequeSample_1.png"),
                }
            ];
        default:
            return [];
    }
  }

  static getAllPayslipTemplates(data) {
    // refer to 'getPayslipTemplateWith'
    // As of now supporting only 1 template per country
    const country = data.country || ApiConstants.TENANT_COUNTRY;
    switch (country) {
      case "IN":
        return [
          { id: 1, thumbnailImage: require("../Assets/Payslip Samples/IN_Payslip_1.png") },
          { id: 6, thumbnailImage: require("../Assets/Payslip Samples/IN_Payslip_2.png") },
          { id: 7, thumbnailImage: require("../Assets/Payslip Samples/IN_Payslip_3.png") },
          { id: 8, thumbnailImage: require("../Assets/Payslip Samples/IN_Payslip_4.png") },
          { id: 9, thumbnailImage: require("../Assets/Payslip Samples/IN_Payslip_5.png") },
          { id: 10, thumbnailImage: require("../Assets/Payslip Samples/IN_Payslip_6.png") },
          { id: 11, thumbnailImage: require("../Assets/Payslip Samples/IN_Payslip_7.png") },
          { id: 12, thumbnailImage: require("../Assets/Payslip Samples/IN_Payslip_1.png") }
        ];
      case "US":
        return [
          { id: 2, thumbnailImage: require("../Assets/Payslip Samples/US_Payslip_1.png"), },
          { id: 13, thumbnailImage: require("../Assets/Payslip Samples/IN_Payslip_2.png") },
          { id: 14, thumbnailImage: require("../Assets/Payslip Samples/IN_Payslip_3.png") },
          { id: 15, thumbnailImage: require("../Assets/Payslip Samples/IN_Payslip_4.png") },
          { id: 16, thumbnailImage: require("../Assets/Payslip Samples/IN_Payslip_5.png") },
          { id: 17, thumbnailImage: require("../Assets/Payslip Samples/IN_Payslip_6.png") },
          { id: 18, thumbnailImage: require("../Assets/Payslip Samples/IN_Payslip_7.png") }
        ];
      case "SG":
        return [
          { id: 3, thumbnailImage: require("../Assets/Payslip Samples/SG_Payslip_1.png"), },
          { id: 20, thumbnailImage: require("../Assets/Payslip Samples/SG_Payslip_2.png"), },
          { id: 21, thumbnailImage: require("../Assets/Payslip Samples/SG_Payslip_3.png"), },
          { id: 22, thumbnailImage: require("../Assets/Payslip Samples/SG_Payslip_4.png"), },
          { id: 23, thumbnailImage: require("../Assets/Payslip Samples/SG_Payslip_5.png"), }
        ];
      case "MY":
        return [
          { id: 4, thumbnailImage: require("../Assets/Payslip Samples/MY_Payslip_1.png"), },
          { id: 19, thumbnailImage: require("../Assets/Payslip Samples/MY_Payslip_2.png"), }
        ];
      default:
        return [
          { id: 5, thumbnailImage: require("../Assets/Payslip Samples/Payslip_Template_1.png") }
        ];
    }
  }


  static getAllWorkOrderTemplates() {
    return [
      {
        id: 1,
        thumbnailImage: require("../Assets/Work Order Sample/Work_Order_Template_1.png"),
      },
    ];
  }

  static getAllJobCardTemplates() {
    return [
      {
        id: 1,
        thumbnailImage: require("../Assets/Cheque Samples/Cheque_Template_1.png"),
      },
    ];
  }

  static getAllPurchaseRequisitionTemplates() {
    return [
      {
        id: 1,
        thumbnailImage: require("../Assets/Cheque Samples/Cheque_Template_1.png"),
      },
    ];
  }

  static getAllQCTemplates() {
    return [
      {
        id: 1,
        thumbnailImage: require("../Assets/Cheque Samples/Cheque_Template_1.png"),
      },
    ];
  }

  static getIsLabelType(type) {
    return getIsShipList(type)
  }
}
