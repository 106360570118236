import React, { Fragment } from "react";
import TemplateSettingsManager from "../../Manager/TemplateSettingsManager"
import { getLocalisedText } from "../../Translate/LanguageManager"
import PayslipUtility from "./PayslipUtility"

export const PayslipFieldType = {
    employeeNameAndAddress: 'employeeNameAndAddress',
    aliasNameAndAddress: 'aliasNameAndAddress',
    dateOfJoining: 'dateOfJoining',
    payPeriod: "payPeriod",
    payDate: 'payDate',
    designation: 'designation',
    employeeNetPay: "employeeNetPay",
    ssn: 'ssn',
    taxableMaritalStatus: 'taxableMaritalStatus',
    netSalaryPaid: "netSalaryPaid",
    exemptions: 'exemptions',
    pfACNumber: 'pfACNumber',
    esiNumber: "esiNumber",
    uanNumber: 'uanNumber',
    employeeId: 'employeeId',
    nricFinWpn: 'nricFinWpn',
    paidDays: 'paidDays',
    unpaidDays: 'unpaidDays',
    fixedAllowance: 'fixedAllowance'
}

export default class PayslipFieldUtility {

    static getPayslipCommonField() {
        return [
            { index: 0, name: 'employee_name_address', type: PayslipFieldType.employeeNameAndAddress, isSelected: true },
            { index: 1, name: 'date_of_joining', type: PayslipFieldType.dateOfJoining, isSelected: true },
            { index: 2, name: 'pay_period', type: PayslipFieldType.payPeriod, isSelected: true },
            { index: 3, name: 'pay_date', type: PayslipFieldType.payDate, isSelected: true },
            { index: 4, name: 'employee_id', type: PayslipFieldType.employeeId, isSelected: true },
            { index: 5, name: 'designation', type: PayslipFieldType.designation, isSelected: true },
            { index: 6, name: 'employee_net_pay', type: PayslipFieldType.employeeNetPay, isSelected: true },
        ]
    }

    static getPayslipUSField() {
        return [
            { index: 0, name: 'ssn', type: 'ssn', isSelected: true },
            { index: 1, name: 'taxable_marital_status', type: 'taxableMaritalStatus', isSelected: true },
            { index: 2, name: 'exemptions', type: 'exemptions', isSelected: true },
        ]
    }

    static getPayslipINField() {
        return [
            { index: 0, name: 'pf_a_c_NUMBER', type: PayslipFieldType.pfACNumber, isSelected: true },
            { index: 1, name: 'esi_number', type: PayslipFieldType.esiNumber, isSelected: true },
            { index: 2, name: 'uan_number', type: PayslipFieldType.uanNumber, isSelected: true },
        ]
    }

    static getDefaultSGCommonField() {
        return [
            { index: 0, name: 'employee_id', type: PayslipFieldType.employeeId, isSelected: false },
            { index: 1, name: 'designation', type: PayslipFieldType.designation, isSelected: false },
            { index: 2, name: 'date_of_joining', type: PayslipFieldType.dateOfJoining, isSelected: false },
            { index: 3, name: 'employee_name_address', type: PayslipFieldType.employeeNameAndAddress, isSelected: true },
            { index: 4, name: 'nric_fin_wpn', type: PayslipFieldType.nricFinWpn, isSelected: false },
            { index: 4, name: 'pay_period', type: PayslipFieldType.payPeriod, isSelected: true },
            { index: 5, name: 'pay_date', type: PayslipFieldType.payDate, isSelected: true },
            { index: 6, name: 'paid_days', type: PayslipFieldType.paidDays, isSelected: false },
            { index: 7, name: 'unpaid_days', type: PayslipFieldType.unpaidDays, isSelected: false },
            { index: 8, name: 'employee_net_pay', type: PayslipFieldType.employeeNetPay, isSelected: false },
            { index: 9, name: 'alias_name_address', type: PayslipFieldType.aliasNameAndAddress, isSelected: false }
        ]
    }

    static getDefaultMYCommonField() {
        return [
            { index: 0, name: 'employee_id', type: PayslipFieldType.employeeId, isSelected: false },
            { index: 1, name: 'designation', type: PayslipFieldType.designation, isSelected: false },
            { index: 2, name: 'date_of_joining', type: PayslipFieldType.dateOfJoining, isSelected: false },
            { index: 3, name: 'employee_name_address', type: PayslipFieldType.employeeNameAndAddress, isSelected: true },
            { index: 4, name: 'pay_period', type: PayslipFieldType.payPeriod, isSelected: true },
            { index: 5, name: 'pay_date', type: PayslipFieldType.payDate, isSelected: true },
            { index: 6, name: 'paid_days', type: PayslipFieldType.paidDays, isSelected: false },
            { index: 7, name: 'unpaid_days', type: PayslipFieldType.unpaidDays, isSelected: false },
            { index: 8, name: 'employee_net_pay', type: PayslipFieldType.employeeNetPay, isSelected: false },
            { index: 9, name: 'alias_name_address', type: PayslipFieldType.aliasNameAndAddress, isSelected: false },
        ]
    }

    static getDefaultCommonField() {
        return [
            { index: 0, name: 'pay_period', type: PayslipFieldType.payPeriod, isSelected: true },
            { index: 1, name: 'pay_date', type: PayslipFieldType.payDate, isSelected: true },
            { index: 2, name: 'employee_name_address', type: PayslipFieldType.employeeNameAndAddress, isSelected: true },
        ]
    }

    static getDefaultUSField() {
        return [
            { index: 0, name: 'date_of_joining', type: PayslipFieldType.dateOfJoining, isSelected: false },
            { index: 1, name: 'pay_period', type: PayslipFieldType.payPeriod, isSelected: true },
            { index: 2, name: 'pay_date', type: PayslipFieldType.payDate, isSelected: true },
            { index: 3, name: 'employee_name_address', type: PayslipFieldType.employeeNameAndAddress, isSelected: true },
            { index: 4, name: 'ssn', type: PayslipFieldType.ssn, isSelected: true },
            { index: 5, name: 'taxable_marital_status', type: PayslipFieldType.taxableMaritalStatus, isSelected: true },
            { index: 6, name: 'exemptions', type: PayslipFieldType.exemptions, isSelected: true },
            { index: 7, name: 'employee_id', type: PayslipFieldType.employeeId, isSelected: false },
            { index: 8, name: 'designation', type: PayslipFieldType.designation, isSelected: false },
            { index: 9, name: 'paid_days', type: PayslipFieldType.paidDays, isSelected: false },
            { index: 10, name: 'unpaid_days', type: PayslipFieldType.unpaidDays, isSelected: false },
            { index: 11, name: 'employee_net_pay', type: PayslipFieldType.employeeNetPay, isSelected: false }
        ]
    }

    static getDefaultINField() {
        return [
            { index: 0, name: 'date_of_joining', type: PayslipFieldType.dateOfJoining, isSelected: true },
            { index: 1, name: 'pay_period', type: PayslipFieldType.payPeriod, isSelected: true },
            { index: 2, name: 'pay_date', type: PayslipFieldType.payDate, isSelected: true },
            { index: 3, name: 'employee_name_address', type: PayslipFieldType.employeeNameAndAddress, isSelected: true },
            { index: 4, name: 'pf_a_c_NUMBER', type: PayslipFieldType.pfACNumber, isSelected: true },
            { index: 5, name: 'esi_number', type: PayslipFieldType.esiNumber, isSelected: true },
            { index: 6, name: 'uan_number', type: PayslipFieldType.uanNumber, isSelected: true },
            { index: 7, name: 'employee_id', type: PayslipFieldType.employeeId, isSelected: true },
            { index: 8, name: 'designation', type: PayslipFieldType.designation, isSelected: true },
            { index: 9, name: 'paid_days', type: PayslipFieldType.paidDays, isSelected: false },
            { index: 10, name: 'unpaid_days', type: PayslipFieldType.unpaidDays, isSelected: false },
            { index: 11, name: 'employee_net_pay', type: PayslipFieldType.employeeNetPay, isSelected: true },
            { index: 12, name: 'alias_name_address', type: PayslipFieldType.aliasNameAndAddress, isSelected: false },
        ]
    }

    static getPayslipINEarningsField() {
        return [
            { index: 0, name: 'Fixed Allowance', type: PayslipFieldType.fixedAllowance, isSelected: true }
        ]
    }

    static getDefaultField(data) {
        switch (PayslipUtility.getCountryCode(data)) {
            case "IN":
                return PayslipFieldUtility.getDefaultINField();
            case "US":
                return PayslipFieldUtility.getDefaultUSField();
            case "SG":
                return PayslipFieldUtility.getDefaultSGCommonField();
            case "MY":
                return PayslipFieldUtility.getDefaultMYCommonField();
            default:
                return PayslipFieldUtility.getDefaultCommonField();
        }
    }

    static getFields(data) {
        var rowItemList = []
        var payslipFields = TemplateSettingsManager.defaultTemplateSettings.payslipFieldConfiguration
        if (payslipFields === undefined
            || payslipFields === null
            || Object.keys(payslipFields).length === 0
            || payslipFields.length === 0) {
            payslipFields = this.getDefaultFields(data)
        }

        if (payslipFields.length > 0) {
            payslipFields.forEach((element, index) => {
                var newRowItem = {}
                newRowItem.isSelected = element.isSelected
                newRowItem.code = element.type
                newRowItem.index = element.index
                newRowItem.label = getLocalisedText(element.name)
                rowItemList.push(newRowItem)
            });
        }

        return rowItemList
    }

    static getEarningFields(data) {
        var rowItemList = []
        var payslipEarningsFields = TemplateSettingsManager.defaultTemplateSettings.payslipEarningsFieldConfiguration
        if (payslipEarningsFields === undefined
            || payslipEarningsFields === null
            || Object.keys(payslipEarningsFields).length === 0
            || payslipEarningsFields.length === 0) {
                payslipEarningsFields = this.getEarningDefaultFields(data)
        }

        if (payslipEarningsFields.length > 0) {
            payslipEarningsFields.forEach((element, index) => {
                var newRowItem = {}
                newRowItem.isSelected = element.isSelected
                newRowItem.code = element.type
                newRowItem.index = element.index
                newRowItem.label = getLocalisedText(element.name)
                rowItemList.push(newRowItem)
            });
        }

        return rowItemList
    }

    static getDefaultFields(data) {
        var rowItemList = []

        // get templates setting for payslip field
        var commonFieldList = PayslipFieldUtility.getPayslipCommonField()
        var countryCode = PayslipUtility.getCountryCode(data)
        var fieldList = this.getDefaultField(data)

        if (countryCode === 'IN') {
            PayslipFieldUtility.getPayslipINField().forEach(element => {
                commonFieldList.push(element)
            });
        }
        else if (countryCode === 'US') {
            PayslipFieldUtility.getPayslipUSField().forEach(element => {
                commonFieldList.push(element)
            });
        }

        var excludedList = []

        if (fieldList.length > 0) {
            var commonFieldName = commonFieldList.map(x => x.name)
            var fieldName = fieldList.map(x => x.name)
            commonFieldName.forEach(element => {
                if (!fieldName.includes(element)) {
                    excludedList.push(element)
                }
            });

            fieldList.forEach((element) => {
                rowItemList.push(element)
            });

            if (excludedList.length > 0) {
                var startIndex = 0
                if (rowItemList.length > 0) {
                    startIndex = rowItemList.length - 1
                }

                excludedList.forEach((element) => {
                    var newRowItem = {}
                    var item = commonFieldList.find(x => x.name === element)
                    if (item !== undefined) {
                        newRowItem = item
                        newRowItem.isSelected = false
                        newRowItem.index = startIndex + 1
                        rowItemList.push(newRowItem)
                        startIndex = startIndex + 1
                    }
                });
            }
        }

        return rowItemList
    }

    static getEarningDefaultFields(data) {
        var rowItemList = []

        var countryCode = PayslipUtility.getCountryCode(data);

        if (countryCode === 'IN') {
            PayslipFieldUtility.getPayslipINEarningsField().forEach(element => {
                rowItemList.push(element)
            });
        }

        return rowItemList
    }

    static renderTopFieldsSection(data, currency, count, styles, isFromERP) {
        var fieldList = TemplateSettingsManager.defaultTemplateSettings.payslipFieldConfiguration
        if (fieldList === undefined
            || fieldList === null
            || Object.keys(fieldList).length === 0
            || fieldList.length === 0) {
            fieldList = this.getDefaultFields(data)
        }

        if (fieldList.length === 0) {
            return undefined
        }

        var sortedList = fieldList.sort((a, b) => a.index - b.index).filter(x => x.isSelected)

        if(sortedList.length > count) {
            sortedList = sortedList.slice(0, count)
        }
        return sortedList.map((element, index) => {
            var style = undefined
            if(styles.length > index) {
                style = styles[index]
            }
            return PayslipUtility.renderPayslipDetailsContainer(
                this.getHeaderText(data, isFromERP, element),
                this.getDataByField(data, currency, isFromERP, element.type),
                this.getFontReverse(element, style)
            )
        });
    }

    static getHeaderText(data, isFromERP, element) {
        if(element.type === PayslipFieldType.employeeNameAndAddress) {
            return PayslipUtility.getEmployeeName(data, isFromERP);
        } else if (element.type === PayslipFieldType.aliasNameAndAddress) {
            return PayslipUtility.getAliasName(data, isFromERP);
        }
        return getLocalisedText(element.name);
    }

    static getFontReverse(element, style) {
        var newStyle = style
        if (element.type === PayslipFieldType.employeeNameAndAddress || element.type === PayslipFieldType.aliasNameAndAddress) {
            if(style !== undefined) {
                newStyle['fontReverse'] = true
            }
        }
        return newStyle
    }

    static getDataByField(data, currency, isFromERP, type) {
        var value = ''
        switch (type) {
            case PayslipFieldType.dateOfJoining:
                value = PayslipUtility.getDate('dateOfJoining', data, isFromERP)
                break;
            case PayslipFieldType.payPeriod:
                value = PayslipUtility.getPayPeriod(data, isFromERP)
                break;
            case PayslipFieldType.payDate:
                value = PayslipUtility.getDate('payDate', data, isFromERP)
                break;
            case PayslipFieldType.employeeNameAndAddress:
                value = PayslipUtility.getEmployeeAddress(data, isFromERP)
                break;
            case PayslipFieldType.aliasNameAndAddress:
                value = PayslipUtility.getEmployeeAddress(data, isFromERP)
                break;
            case PayslipFieldType.pfACNumber:
                value = PayslipUtility.getValue('pfAccountNumber', data.employeeDetails.compliance, isFromERP)
                break;
            case PayslipFieldType.esiNumber:
                value = PayslipUtility.getValue('esiNumber', data.taxInfo.compliance, isFromERP)
                break;
            case PayslipFieldType.uanNumber:
                value = PayslipUtility.getValue('uanNumber', data.employeeDetails.compliance, isFromERP)
                break;
            case PayslipFieldType.employeeId:
                value = PayslipUtility.getValue('employeeId', data.employeeDetails, isFromERP)
                break;
            case PayslipFieldType.designation:
                value = PayslipUtility.getValue('designation', data.employeeDetails, isFromERP)
                break;
            case PayslipFieldType.employeeNetPay:
                value = PayslipUtility.getCurrentValue('netPay', data, isFromERP, currency)
                break;
            case PayslipFieldType.ssn:
                value = PayslipUtility.getValue('SSN', data.employeeDetails.compliance, isFromERP)
                break;
            case PayslipFieldType.taxableMaritalStatus:
                value = PayslipUtility.getValue('federalFilingStatus', data.employeeDetails.compliance, isFromERP)
                if (value === 'singleormarriedfilingseparately') {
                    value = 'Single Or Married Filing Separately'
                }
                break;
            case PayslipFieldType.exemptions:
                value = PayslipUtility.getValue('taxExemptions', data.employeeDetails.compliance, isFromERP)
                if(value === 'TRUE') {
                    value = 'YES'
                }
                else {
                    value = 'NO'
                }
                break;
            case 'netPay':
                value = '#NetPay'
                break;
            case PayslipFieldType.nricFinWpn:
                value = PayslipUtility.getNricFinWpnValue(data.employeeDetails.compliance, isFromERP);
                break;
            case PayslipFieldType.paidDays:
                value = PayslipUtility.getPaidDaysValue(data, isFromERP);
                break;
            case PayslipFieldType.unpaidDays:
                value = PayslipUtility.getValue('daysOff', data, isFromERP);
                break;
            default:
                break;
        }
        return value
    }

    static getStyleObject(width, paddingTop, divClass, textAlign, titleColor, valueColor, titleOpacity) {
        const style = {
            width: width,
            paddingTop: paddingTop,
            divClass: divClass
        };

        if (textAlign) {
            style['textAlign'] = textAlign;
        }

        if (titleColor) {
            style['titleColor'] = titleColor;
        }

        if (valueColor) {
            style['valueColor'] = valueColor;
        }

        if (titleOpacity) {
            style['titleOpacity'] = titleOpacity;
        }

        return style;
    }
    
    static getFieldStyle(templateId, globalData, data, visibleCount) {
        var fieldsStyle = [];
        var width = [];
        var titleColor;
        var valueColor;
        var titleOpacity;

        switch (templateId) {
            case 8:
            case 15:
                titleColor = globalData.themeColor;
                valueColor = globalData.themeColor;
                titleOpacity = 0.8;
                break;
            case 9:
            case 16:
                titleColor = globalData.themeColor;
                break;
            default:
                break;
        }

        var countryCode = PayslipUtility.getCountryCode(data)
        switch (visibleCount) {
            case 1:
                switch (countryCode) {
                    case "IN":
                    case "SG":
                    case "MY":
                        width = ['30%'];
                        break;
                    case "US":
                        width = ['25%'];
                        break;
                    default:
                        break;
                }
                break;
            case 2:
                switch (countryCode) {
                    case "IN":
                    case "SG":
                    case "MY":
                        width = ['30%', '70%'];
                        break;
                    case "US":
                        width = ['25%', '75%'];
                        break;
                    default:
                        break;
                }
                break;
            case 3:
                switch (countryCode) {
                    case "IN":
                    case "SG":
                    case "MY":
                        width = ['30%', '50%', '20%'];
                        break;
                    case "US":
                        width = ['25%', '55%', '20%'];
                        break;
                    default:
                        break;
                }
                break;
            case 4:
                switch (countryCode) {
                    case "IN":
                    case "SG":
                    case "MY":
                        width = ['30%', '25%', '25%', '20%'];
                        break;
                    case "US":
                        width = ['25%', '25%', '30%', '20%'];
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }

        switch (visibleCount) {
            case 1:
                fieldsStyle = [
                    this.getStyleObject(width[0], 10, 'RowDiv', 'left', titleColor, valueColor, titleOpacity)
                ];
                break;
            case 2:
                fieldsStyle = [
                    this.getStyleObject(width[0], 10, 'RowDiv', 'left', titleColor, valueColor, titleOpacity),
                    this.getStyleObject(width[1], 10, 'RowReverseDiv', null , titleColor, valueColor, titleOpacity)
                ];
                break;
            case 3:
                fieldsStyle = [
                    this.getStyleObject(width[0], 10, 'RowDiv', 'left', titleColor, valueColor, titleOpacity),
                    this.getStyleObject(width[1], 10, 'RowReverseDiv', null , titleColor, valueColor, titleOpacity),
                    this.getStyleObject(width[2], 10, 'RowReverseDiv', null , titleColor, valueColor, titleOpacity)
                ];
                break;
            case 4:
                fieldsStyle = [
                    this.getStyleObject(width[0], 10, 'RowDiv', 'left', titleColor, valueColor, titleOpacity),
                    this.getStyleObject(width[1], 10, 'RowReverseDiv', null , titleColor, valueColor, titleOpacity),
                    this.getStyleObject(width[2], 10, 'RowReverseDiv', null , titleColor, valueColor, titleOpacity),
                    this.getStyleObject(width[3], 10, 'RowReverseDiv', null , titleColor, valueColor, titleOpacity)
                ];
                break;
            default:
                break;
        }

        return fieldsStyle;
    }

    static renderEmployeeTopSection(templateId, globalData, data, currency, isFromERP, sortedList) {
        const visibleFieldCount = sortedList.length + 1;
        let fieldStyle = this.getFieldStyle(templateId, globalData, data, visibleFieldCount);
        const titleWidth = fieldStyle[0].width;
        fieldStyle = fieldStyle.slice(1);

        return <div className='RowDiv' style={{ alignItems: 'baseline' }}>
            <div className='RowDiv' style={{ width: titleWidth }}>
                {PayslipUtility.showHeaderTitle() &&
                    <div style={{
                        fontSize: 20,
                        textAlign: 'left',
                        fontWeight: 700,
                        paddingTop: 10,
                        paddingBottom: 10,
                        marginTop: 20,
                    }}>
                        {getLocalisedText(globalData.documentType)}
                    </div>
                }
            </div>
            {PayslipFieldUtility.renderSelectedFieldsSection(data, currency, sortedList, fieldStyle, isFromERP)}
        </div>
    }

    static renderFieldSection(templateId, globalData, data, currency, isFromERP, sortedList) {
        const fieldStyle = this.getFieldStyle(templateId, globalData, data, sortedList.length);

        return <div className='RowDiv' style={{ alignItems: 'baseline' }}>
            {PayslipFieldUtility.renderSelectedFieldsSection(data, currency, sortedList, fieldStyle, isFromERP)}
        </div>
    }

    static renderPayslipFields(templateId, globalData, data, currency, isFromERP) {
        const fields = this.getPayslipFields(data);
        const row1Fields = [];
        const row2Fields = [];
        const row3Fields = [];
        const row4Fields = [];

        if (Array.isArray(fields)) {
            const sortedFields = fields.sort((a, b) => a.index - b.index);
            sortedFields.forEach((field, index) => {
                if (field.isSelected) {
                    switch (index) {
                        case 0:
                        case 1:
                        case 2:
                            row1Fields.push(field);
                            break;
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                            row2Fields.push(field);
                            break;
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                            row3Fields.push(field);
                            break;
                        default:
                            row4Fields.push(field);
                            break;
                    }
                }
            });
        }

        return (
            <Fragment>
                {row1Fields.length > 0 && this.renderEmployeeTopSection(templateId, globalData, data, currency, isFromERP, row1Fields)}
                {row2Fields.length > 0 && this.renderFieldSection(templateId, globalData, data, currency, isFromERP, row2Fields)}
                {row3Fields.length > 0 && this.renderFieldSection(templateId, globalData, data, currency, isFromERP, row3Fields)}
                {row4Fields.length > 0 && this.renderFieldSection(templateId, globalData, data, currency, isFromERP, row4Fields)}
            </Fragment>
        )
    }
    
    static getPayslipFields(data) {
        var fields = TemplateSettingsManager.defaultTemplateSettings.payslipFieldConfiguration;
        if (fields === undefined || fields === null || Object.keys(fields).length === 0 || fields.length === 0) {
            fields = this.getDefaultFields(data) || [];
        } else {
            var defaultFields = this.getDefaultFields(data);
            //Add missing default fields
            if (Array.isArray(fields) && Array.isArray(defaultFields)) {
                defaultFields.forEach((df) => {
                    const f = fields.find((tf) => tf.type === df.type);
                    if (!f) {
                        fields.push(df);
                    }
                });
            }
        }

        fields = fields.map((field, index) => {
            return {...field, index: index}
        });

        var earningsFields = TemplateSettingsManager.defaultTemplateSettings.payslipEarningsFieldConfiguration;
        if (earningsFields === undefined || earningsFields === null || Object.keys(earningsFields).length === 0 || earningsFields.length === 0) {
            earningsFields = this.getEarningDefaultFields(data) || [];
        }

        TemplateSettingsManager.updatePayslipFieldConfiguration(fields);
        TemplateSettingsManager.updatePayslipEarningsFieldConfiguration(earningsFields)

        return fields;
    }

    static renderSelectedFieldsSection(data, currency, sortedList, styles, isFromERP) {
        return sortedList.map((element, index) => {
            var style = undefined
            if(styles.length > index) {
                style = styles[index]
            }
            return PayslipUtility.renderPayslipDetailsContainer(
                this.getHeaderText(data, isFromERP, element),
                this.getDataByField(data, currency, isFromERP, element.type),
                this.getFontReverse(element, style)
            )
        });
    }
}
