import { Suspense, useEffect, useState } from 'react';
import '../styles/App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { DKSpinner, isMacintosh, showAlert } from 'deskera-ui-library';
import ErrorBoundary from './ErrorBoundary';
import TopMenu from './menu/TopMenu';
import LeftMenu from './menu/LeftMenu';
import { APP_SCROLL_CONTAINER_ID, PAGE_ROUTES } from '../constants/Constant';
import Login from '../pages/Login';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
  getSettings,
  selectLoggedInStatus,
  userLogInStatus
} from '../store/slices/authSlice';
import Quotations from '../pages/Quotations';
import Invoices from '../pages/Invoices';
import PaymentReceipts from '../pages/PaymentReceipts';
import { BOOKS_DOCS_TYPES } from '../constants/Enum';
import {
  fetchLoggedInContact,
  fetchTables,
  fetchTenantDetails,
  selectIsSOUnable,
  selectTenantDetails
} from '../store/slices/booksSlice';
import RouteManager from '../managers/RouteManager';
import SalesOrders from '../pages/SalesOrder';
import PurchaseOrders from '../pages/PurchaseOrder';
import Products from '../pages/Products';
import { fetchCartItems } from '../store/slices/cartSlice';
import Attachments from '../pages/Attachment';
import { isEmptyObject } from '../utility/Helper';

const App = (props: any) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const loggedInStatus = useAppSelector(selectLoggedInStatus);
  const tenantInfo = useAppSelector(selectTenantDetails);
  const isSOEnable = useAppSelector(selectIsSOUnable);

  useEffect(() => {
    dispatch(userLogInStatus({}));
    dispatch(getSettings({}));
  }, []);

  useEffect(() => {
    if (!loggedInStatus.isLoggedIn && loggedInStatus.errorWhileLogin) {
      setLoading(false);
      showAlert('Error Occurred While Logging In', 'Please try again later');
      return;
    }

    if (loggedInStatus?.isLoggedIn) {
      RouteManager.initiateRoutes();
      const TABLE_TO_FETCH = [
        BOOKS_DOCS_TYPES.BOOKS_QUOTE,
        BOOKS_DOCS_TYPES.BOOKS_INVOICE,
        BOOKS_DOCS_TYPES.BOOKS_PURCHASE_ORDER,
        BOOKS_DOCS_TYPES.BOOKS_PRODUCT,
        BOOKS_DOCS_TYPES.BOOKS_SALES_ORDER
      ];

      Promise.all([
        dispatch(
          fetchTables({
            objectTypes: TABLE_TO_FETCH.join(',')
          })
        ),
        dispatch(fetchTenantDetails({})),
        dispatch(fetchCartItems())
      ]).then((res: any) => {
        if (res) {
          setLoading(false);
        }
      });
    }
  }, [loggedInStatus?.isLoggedIn]);

  useEffect(() => {
    if (!isEmptyObject(tenantInfo)) {
      dispatch(fetchLoggedInContact({}));
    }
  }, [tenantInfo]);

  return (
    <Router>
      <div className="main-holder bg-white row align-items-start app-font">
        {/* Left Menu */}
        {!loggedInStatus?.isLoggedIn && <Login />}
        {loggedInStatus?.isLoggedIn && (
          <>
            <LeftMenu />
            <div className="parent-size">
              <TopMenu />
              <div
                id={APP_SCROLL_CONTAINER_ID}
                className={
                  'main-holder-padding parent-size column position-relative padding-bottom-50' +
                  (isMacintosh() ? '' : ' show-scroll-bar')
                }
                style={{
                  overflowY: 'auto',
                  flexGrow: 1
                }}
              >
                {loading ? (
                  <div className="row flex-1 justify-content-center">
                    <DKSpinner />
                  </div>
                ) : (
                  <ErrorBoundary>
                    <Suspense
                      fallback={
                        <div className="row flex-1 justify-content-center">
                          <DKSpinner />
                        </div>
                      }
                    >
                      <Switch>
                        <Route
                          path={PAGE_ROUTES.INVOICES}
                          component={Invoices}
                        />
                        <Route
                          path={PAGE_ROUTES.PAYMENT_RECEIPTS}
                          component={PaymentReceipts}
                        />
                        <Route
                          path={PAGE_ROUTES.QUOTATIONS}
                          component={Quotations}
                        />
                        {isSOEnable && (
                          <Route
                            path={PAGE_ROUTES.SALES_ORDER}
                            component={SalesOrders}
                          />
                        )}
                        {isSOEnable && (
                          <Route
                            path={PAGE_ROUTES.PRODUCTS}
                            component={Products}
                          />
                        )}
                        {/* <Route
                          path={PAGE_ROUTES.PURCHASE_ORDER}
                          component={PurchaseOrders}
                        /> */}

                        <Route
                          path={PAGE_ROUTES.ATTACHMENTS}
                          component={Attachments}
                        />
                        <Route path={PAGE_ROUTES.HOME} component={Invoices} />
                      </Switch>
                    </Suspense>
                  </ErrorBoundary>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </Router>
  );
};

export default App;
