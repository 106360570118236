/* *************** App Enums ***************** */
export enum COMMON_EVENTS {
  SUBSCRIPTION_CHECK = 'onAuthorizationCheckStateChanged',
  NAVIGATION_TRIGGERED = 'onNavigationTriggered',
  ERROR_OCCURRED = 'onErrorOccurred'
}

export const LOCAL_STORAGE_KEYS = {
  IS_SIDE_BAR_EXPANDED: 'isSideBarExpanded'
};

export enum BOOKS_DOCS_TYPES {
  BOOKS_INVOICE = 'BOOKS_INVOICE',
  BOOKS_QUOTE = 'BOOKS_QUOTE',
  BOOKS_SALES_ORDER = 'BOOKS_SALES_ORDER',
  BOOKS_PRODUCT = 'BOOKS_PRODUCT',
  BOOKS_PURCHASE_ORDER = 'BOOKS_ORDER',
  BOOKS_PAYMENT = 'BOOKS_PAYMENT'
}

export enum RECURRING_DOCUMENT_TYPE {
  RECURRING = 'RECURRING',
  NON_RECURRING = 'NON_RECURRING'
}

export enum DATE_FORMATS {
  'D MMM YYYY' = 'D MMM YYYY',
  'DD-MM-YYYY' = 'DD-MM-YYYY',
  'DD-MMM-YYYY' = 'DD-MMM-YYYY',
  'YYYY-MM-DD' = 'YYYY-MM-DD',
  'MM-DD-YYYY' = 'MM-DD-YYYY',
  'MM/DD/YYYY' = 'MM/DD/YYYY',
  'DD/MM/YYYY' = 'DD/MM/YYYY',
  'DATE_FORMAT_MEDIUM' = 'DD-MM-YYYY HH:mm:ss',
  'ISO-FORMAT' = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z"
}

export enum TEMPLATE_CATEGORY {
  INVOICE = 'INVOICE',
  QUOTE = 'QUOTE',
  SALES_ORDER = 'SALES_ORDER',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  PAYMENT = 'PAYMENT'
}
