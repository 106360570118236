import {
  createAsyncThunk,
  createSelector,
  createSlice
} from '@reduxjs/toolkit';
import { RootState } from '..';
import { ILoginPayload } from '../../models/Auth';
import { checkUserStatus, fetchSettings, login } from '../../services/auth';
import { deleteCookie, parseJwt } from '../../utility/Helper';
import {
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
  setItemInLocalStorage
} from '../../utility/LocalStorage';
import { LOGIN_COOKIE_NAME } from '../../constants/Constant';
interface IAuthState {
  isLoggedIn: boolean;
  errorWhileLogin: boolean;
  userInfo: any;
  tenantSettings: any;
}
const initialState: IAuthState = {
  isLoggedIn: false,
  errorWhileLogin: false,
  tenantSettings: {},
  userInfo: {}
};
export const userLogInStatus = createAsyncThunk(
  'auth/loginStatus',
  async (data: any, thunkAPI) => {
    const response = await checkUserStatus();
    return response;
  }
);

export const loginUser = createAsyncThunk(
  'auth/login',
  async (data: ILoginPayload, thunkAPI) => {
    const response = await login(data);
    return response;
  }
);
export const logoutUser = createAsyncThunk(
  'auth/logout',
  async (data: any, thunkAPI) => {
    deleteCookie(LOGIN_COOKIE_NAME);
    removeItemFromLocalStorage(LOGIN_COOKIE_NAME);
    return Promise.resolve('Succesfully logged out!');
  }
);
export const getSettings = createAsyncThunk(
  'auth/settings',
  async (data: any, thunkAPI) => {
    const response = await fetchSettings();
    return response;
  }
);
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isLoggedIn = !!action?.payload?.token;
        setItemInLocalStorage(LOGIN_COOKIE_NAME, action?.payload?.token);
        state.userInfo = parseJwt(action?.payload?.token);
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.errorWhileLogin = true;
      })
      .addCase(getSettings.fulfilled, (state, action) => {
        state.tenantSettings = action?.payload?.data;
      })
      .addCase(userLogInStatus.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.userInfo = parseJwt(
          getItemFromLocalStorage(LOGIN_COOKIE_NAME) as any
        );
      })
      .addCase(userLogInStatus.rejected, (state, action) => {
        state.isLoggedIn = false;
      });
  }
});
const selectSelf = (state: RootState) => state;

export const selectLoggedInStatus = createSelector(selectSelf, (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  errorWhileLogin: state.auth.errorWhileLogin
}));
export const selectTenantSettings = createSelector(
  selectSelf,
  (state) => state.auth.tenantSettings
);
export const selectUserInfo = createSelector(
  selectSelf,
  (state) => state.auth.userInfo
);
