import CustomFieldManager from "../Manager/CustomFieldManager"
import TemplateSettingsManager from "../Manager/TemplateSettingsManager"
import { getLocalisedText } from "../Translate/LanguageManager"
import Utility, { getCapitalized, getIsPayslip } from "../Utilities/Utility"


export const AddressFieldType = {
    contactName: 'contactName',
    address1: 'address1',
    address2: 'address2',
    city: "city",
    state: "state",
    postalCode: "postalCode",
    country: "country",
    contactNumber: "contactNumber",
    contactCode: "contactCode",
    panIndia: "panIndia",
    cinIndia: "cinIndia",
    gstin: 'gstin',
    customField: 'customField'
}

export const AddressType = {
    billTo: 'billTo',
    shipTo: 'shipTo',
    from: "from",
    tenantAddress: 'tenantAddress',
    shipFrom: 'shipFrom',
}

export const OwnerType = {
    contact: 'contact',
    tenant: 'tenant',
}

export default class AddressParser {

    getAddress() {

    }

    static getPreferAddress(addressList) {
        if (addressList === undefined || addressList === null) {
            return undefined
        }

        if (addressList.length === 0) {
            return undefined
        }

        var preferAddress = addressList.filter(x => x.preferred)

        if (preferAddress.length > 0) {
            return preferAddress[0]
        }

        return addressList[0]
    }

    static getAddressTypeName(addressType) {
        if (addressType !== undefined && addressType !== null) {
            switch (addressType) {
                case AddressType.from:
                    return 'companyAddress'
                case AddressType.billTo:
                    return 'billTo'
                case AddressType.shipTo:
                    return 'shipTo'
                case AddressType.tenantAddress:
                    return 'tenantAddress'
                case AddressType.shipFrom:
                    return 'shipFrom'
                default:
                    return undefined
            }
        }
        return undefined
    }

    static getFormattedAddress(addressFields, addressType, data) {
        var addressFieldList = addressFields
        if (addressFieldList === undefined || addressFieldList === null) {
            addressFieldList = this.getAddressFormat(addressType, getIsPayslip(data.type), data)
        }

        var selectedAddressKeyList = []
        var addressObjField = this.getDataAddressField(addressType)
        if (Utility.getIsArabicLang() && Utility.getIsSaudiArabiaCountry(data)) {
            var addressObjFieldInArabic = this.getDataAddressFieldInArabic(addressType)
            if (data[addressObjFieldInArabic] !== undefined && data[addressObjFieldInArabic] !== null) {
                addressObjField = addressObjFieldInArabic
            }
        }

        var address = undefined
        var addressString = ''
        if(addressFieldList !== undefined && addressObjField !== undefined) {
            if(addressFieldList.length > 0) {
                if(data[addressObjField] !== undefined && data[addressObjField] !== null) {
                    address = data[addressObjField]
                    addressFieldList = addressFieldList.sort((a, b) => a.index - b.index)
                }
            }
        }
        if (address !== undefined) {
            if (data && Utility.getIsIndiaCountry(data) && !getIsPayslip(data.type)) {
                if(address.ownerType === OwnerType.tenant) {
                    const tenantDetails = data.tenantInfo;
                    address['panIndia'] = tenantDetails?.panIndia;
                    address['cinIndia'] = tenantDetails?.cinIndia;
                }
                else if (address.ownerType === OwnerType.contact) {
                    const contactDetails = data.contactInfo;
                    address['panIndia'] = contactDetails?.pan;
                }
            }
            //update is selected flag for the undefined field
            addressFieldList.forEach(element => {
                if (element.isSelected) {
                    if(element.type === AddressFieldType.customField) {
                        selectedAddressKeyList.push(element.type)
                    }
                    else if (address[element.type] !== undefined && address[element.type] !== null) {
                        selectedAddressKeyList.push(element.type)
                    }
                }
            });
            if(address.name !== undefined && address.name !== null) {
                if(address.name.trim() !== '') {
                    addressString = getCapitalized(address.name) + '<br>'
                }
            }

            addressFieldList.forEach(element => {
                if(element.isSelected) {
                    if(selectedAddressKeyList.includes(element.type)) {
                        if ((element.type === AddressFieldType.panIndia || element.type === AddressFieldType.cinIndia) && address[element.type]) {
                            addressString += this.formatedTDSDetailsFields(AddressParser.getAdditionalLabel(element),  address[element.type]);
                        } else if (element.type === AddressFieldType.gstin && address[element.type] && (address.gstin !== null && address.gstin !== undefined && address.gstin.trim() !== "") ) {
                            addressString += '<div><b>GSTIN : </b>' + address.gstin + '</div>'
                        }
                        else {
                            addressString += this.getAddressByAddressField(element, address, selectedAddressKeyList, data)
                        }
                    }
                }
            });

            // if (!(address.panIndia && address.panIndia.trim()) && address.pan !== null && address.pan !== undefined && address.pan.trim() !== "") {
            //     addressString += '<div><b>PAN : </b>' + address.pan + '</div>'
            // }
        }
        return addressString
    }

    static formatedTDSDetailsFields(label, value) {
        return '<div><b>'+label+' </b>' + value + '</div>'
    }

    static getAddressFormat(type, isPayslip, data) {
        var addressFieldList = []
        var addressFormat = TemplateSettingsManager.defaultTemplateSettings.addressFormat

        if (addressFormat === undefined || addressFormat === null) {
            addressFormat = TemplateSettingsManager.getDefaultAddressFormat()
        }

        var addressType = this.getAddressTypeName(type)
        if (addressType !== undefined) {
            if (addressFormat[addressType] !== undefined && addressFormat[addressType] !== null) {
                addressFieldList = addressFormat[addressType]
            }
            else {
                addressFieldList = TemplateSettingsManager.getDefaultAddressFormat()[addressType]
            }
        }

        //added phone number to list if not exist
        var requiredSortKey = []

        var contactName = addressFieldList.find(x => x.type === AddressFieldType.contactName)
        if (contactName === undefined) {
            addressFieldList.push({ type: AddressFieldType.contactName, isSelected: false, name: 'contact_name', index: 0 })
            requiredSortKey.push({ type: AddressFieldType.contactName, isSelected: false, name: 'contact_name', index: 0 })
        }

        var contactNumber = addressFieldList.find(x => x.type === AddressFieldType.contactNumber)
        if (contactNumber === undefined) {
            requiredSortKey.push({ type: AddressFieldType.contactNumber, isSelected: false, name: 'contact_number', index: 7 })
            addressFieldList.push({ type: AddressFieldType.contactNumber, isSelected: false, name: 'contact_number', index: 7 })
        }
        var contactCode = addressFieldList.find(x => x.type === AddressFieldType.contactCode)
        if (contactCode === undefined) {
            requiredSortKey.push({ type: AddressFieldType.contactCode, isSelected: false, name: 'contact_code', index: 8 })
            addressFieldList.push({ type: AddressFieldType.contactCode, isSelected: false, name: 'contact_code', index: 8 })
        }

        if(isPayslip) {
            addressFieldList = addressFieldList.filter(x => x.type !== AddressFieldType.contactNumber)
            addressFieldList = addressFieldList.filter(x => x.type !== AddressFieldType.contactCode)
            addressFieldList = addressFieldList.filter(x => x.type !== AddressFieldType.contactName)
        }
        else {
            var rawAddress = data[this.getDataAddressField(type)]
            if(rawAddress !== undefined) {
                if (rawAddress.ownerType !== undefined && rawAddress.ownerType !== null) {
                    if(rawAddress.ownerType === OwnerType.tenant) {
                        addressFieldList = addressFieldList.filter(x => x.type !== AddressFieldType.contactNumber)
                        addressFieldList = addressFieldList.filter(x => x.type !== AddressFieldType.contactCode)
                    }
                    else if (rawAddress.ownerType === OwnerType.contact) {
                        addressFieldList = addressFieldList.filter(x => x.type !== AddressFieldType.cinIndia)
                    }
                }
            }
            if (rawAddress !== undefined && data !== undefined && Utility.getIsIndiaCountry(data) && !getIsPayslip(data.type)) {
                addressFieldList = AddressParser.getIndiaAddressConfiguration(rawAddress, data, addressFieldList)
            }
        }

        if(requiredSortKey.length > 0) {
            var addressFieldListType = addressFieldList.map(x => x.type)
            requiredSortKey.forEach(element => {
                if(addressFieldListType.includes(element.type)) {
                    var newList = addressFieldList.filter(x => x.type !== element.type)
                    newList.splice(element.index, 0, element)
                    newList.forEach((addItem, index) => {
                        addItem.index = index
                    });
                    addressFieldList = newList
                }
            });
            //update key
            addressFormat[addressType] = addressFieldList
            TemplateSettingsManager.updateAddressFormat(addressFormat)
        }
        return addressFieldList
    }

    static getIndiaAddressConfiguration(rawAddress, data, addressFieldList) {
        const gstin = addressFieldList.find(e => e.type === AddressFieldType.gstin)
        if (gstin === undefined) {
            addressFieldList.push({ type: AddressFieldType.gstin, isSelected: true, name: 'GSTIN', index: addressFieldList.length })
        }

        const panObj = addressFieldList.find(e => e.type === AddressFieldType.panIndia)
        if (panObj === undefined) {
            addressFieldList.push({ type: AddressFieldType.panIndia, isSelected: false, name: 'PAN Number', index: addressFieldList.length })
        }

        if (rawAddress.ownerType === OwnerType.tenant && Utility.getIsIndiaCountry(data) && !getIsPayslip(data.type)) {
            const cinObj = addressFieldList.find(e => e.type === AddressFieldType.cinIndia)
            if (cinObj === undefined) {
                addressFieldList.push({ type: AddressFieldType.cinIndia, isSelected: false, name: 'CIN Number', index: addressFieldList.length })
            }
        }

        addressFieldList.forEach((element, index) => {
            element.index = index
        });
        return addressFieldList
    }

    static getDataAddressField(addressType) {
        if (addressType !== undefined && addressType !== null) {
            switch (addressType) {
                case AddressType.from:
                    return 'fromObj'
                case AddressType.billTo:
                    return 'clientBillToAddressObj'
                case AddressType.shipTo:
                    return 'clientShipToAddressObj'
                case AddressType.tenantAddress:
                    return 'tenantAddressObj'
                case AddressType.shipFrom:
                    return 'clientShipFromAddressObj'
                default:
                    return undefined
            }
        }
        return undefined
    }

    static getDataAddressFieldInArabic(addressType) {
        if (addressType !== undefined && addressType !== null) {
            switch (addressType) {
                case AddressType.from:
                    return 'fromInArabicObj'
                case AddressType.billTo:
                    return 'clientBillToAddressInArabicObj'
                case AddressType.shipTo:
                    return 'clientShipToAddressInArabicObj'
                default:
                    return undefined
            }
        }
        return undefined
    }

    static getAddressByAddressField(addressType, addressObj, selectedAddressKeyList, data) {
        var addressString = ''
        var additionalLabel = ''
        var addressField = addressType.type
        if (addressObj[addressField] !== undefined && addressObj[addressField] !== null) {
            if (addressObj[addressField].trim() !== '') {
                if (addressField === AddressFieldType.contactCode || addressField === AddressFieldType.contactNumber) {
                    additionalLabel = AddressParser.getAdditionalLabel(addressType)
                }
                addressString = additionalLabel + addressObj[addressField] + this.getAddressFieldSeparator(addressField, selectedAddressKeyList)
            }
        }
        else if (addressField === AddressFieldType.customField) {
            addressString = this.getCustomFieldString(addressType, data)
        }
        return addressString
    }

    static getAdditionalLabel(addressType, ) {
        var additionalLabel = ''
        if (addressType.name === 'blank') {
            additionalLabel = ''
        }
        else {
            additionalLabel = getLocalisedText(addressType.name) + ': '
        }
        return additionalLabel
    }

    static getAddressFieldSeparator(addressField, selectedAddressKeyList) {
        if (    addressField === AddressFieldType.contactName
                || addressField === AddressFieldType.address1
                || addressField === AddressFieldType.address2
                || addressField === AddressFieldType.contactNumber
                || addressField === AddressFieldType.contactCode) {
            return '<br>'
        }

        var excludedAddress1N2 = selectedAddressKeyList.filter(x => x !== AddressFieldType.address1
                                                                && x !== AddressFieldType.address2
                                                                && x !== AddressFieldType.contactNumber
                                                                && x !== AddressFieldType.contactCode
                                                                && x !== AddressFieldType.contactName)

        var indexOf = selectedAddressKeyList.indexOf(addressField)
        var nextIndex = indexOf + 1
        var nextAddressField = ''
        var indexOf2 = 0

        if(addressField === AddressFieldType.city) {
            if (excludedAddress1N2.length > 0) {
                indexOf2 = excludedAddress1N2.indexOf(addressField) + 1
                if (indexOf2 % 2 === 1) {
                    if (selectedAddressKeyList.length > nextIndex) {
                        nextAddressField = selectedAddressKeyList[nextIndex]
                        if (nextAddressField === AddressFieldType.state || nextAddressField === AddressFieldType.country) {
                            return ', '
                        }
                    }
                }
            }
        }

        if (selectedAddressKeyList.length > nextIndex) {
            nextAddressField = selectedAddressKeyList[nextIndex]
            if (nextAddressField === AddressFieldType.contactName
                || nextAddressField === AddressFieldType.address1
                || nextAddressField === AddressFieldType.address2
                || nextAddressField === AddressFieldType.contactNumber
                || nextAddressField === AddressFieldType.contactCode) {
                return '<br>'
            }
        }

        if (excludedAddress1N2.length > 0) {
            indexOf2 = excludedAddress1N2.indexOf(addressField) + 1
            if (indexOf2 % 2 === 0) {
                return '<br>'
            }
        }

        return ' '
    }


    static assignAddressObjToString(addressFields, addressType, data) {
        var addressString = this.getFormattedAddress(addressFields, addressType, data)
        var fieldName = this.getDataAddressField(addressType)

        if (Utility.getIsArabicLang() && Utility.getIsSaudiArabiaCountry(data)) {
            var addressObjFieldInArabic = this.getDataAddressFieldInArabic(addressType)
            if (data[addressObjFieldInArabic] !== undefined && data[addressObjFieldInArabic] !== null) {
                fieldName = addressObjFieldInArabic
            }
        }
        if (fieldName !== undefined && fieldName !== null) {
            fieldName = fieldName.replace('Obj', '')
            data[fieldName] = addressString
        }
        return data
    }

    static assignAddressObjToStringForNonArabic(addressFields, addressType, data) {
        var addressString = this.getFormattedAddress(addressFields, addressType, data)
        var fieldName = this.getDataAddressField(addressType)

        if (fieldName !== undefined && fieldName !== null) {
            fieldName = fieldName.replace('Obj', '')
            data[fieldName] = addressString
        }
        return data
    }

    static assignAddressObjToStringForArabic(addressFields, addressType, data) {
        var addressString = this.getFormattedAddress(addressFields, addressType, data)
        var fieldName = this.getDataAddressField(addressType)

        if (Utility.getIsArabicLang() && Utility.getIsSaudiArabiaCountry(data)) {
            var addressObjFieldInArabic = this.getDataAddressFieldInArabic(addressType)
            fieldName = addressObjFieldInArabic
        }
        if (fieldName !== undefined && fieldName !== null) {
            fieldName = fieldName.replace('Obj', '')
            data[fieldName] = addressString
        }
        return data
    }


    static getPayslipFormattedAddress(addressFields, addressType, data) {
        var addressFieldList = addressFields
        if (addressFieldList === undefined || addressFieldList === null) {
            addressFieldList = this.getAddressFormat(addressType, true, data)
        }
        var selectedAddressKeyList = []

        var address = undefined
        var addressString = ''

        if (addressFieldList !== undefined ) {
            if (addressFieldList.length > 0) {
                if (data !== undefined && data !== null) {
                    address = data
                    addressFieldList = addressFieldList.sort((a, b) => a.index - b.index)
                }
            }
        }

        if (address !== undefined) {
            //update is selected flag for the undefined field
            addressFieldList.forEach(element => {
                if (element.isSelected) {
                    if (address[element.type] !== undefined && address[element.type] !== null) {
                        selectedAddressKeyList.push(element.type)
                    }
                }
            });

            if (address.name !== undefined && address.name !== null) {
                if (address.name.trim() !== '') {
                    addressString = getCapitalized(address.name) + '<br>'
                }
            }

            addressFieldList.forEach(element => {
                if (element.isSelected) {
                    if (selectedAddressKeyList.includes(element.type)) {
                        addressString += this.getAddressByAddressField(element, address, selectedAddressKeyList, data)
                    }
                }
            });

            if (address.pan !== null && address.pan !== undefined && address.pan.trim() !== "") {
                addressString += '<br><b>PAN : </b>' + address.pan
            }

            if (address.gstin !== null && address.gstin !== undefined && address.gstin.trim() !== "") {
                addressString += '<br><b>GSTIN : </b>' + address.gstin
            }
        }
        return addressString
    }

    static getCustomFieldString(element, data) {
        var customFieldString = ''
        var customFieldValue = ''
        customFieldValue = CustomFieldManager.parseCustomField(data, element.code)
        if(element.name !== undefined && element.name !== null) {
            customFieldString = '<b>' + element.name + ' :</b> ' + customFieldValue + '<br>'
        }
        return customFieldString
    }

    static getTenantAddressFromApi(address, tenantInfo) {
        if(address === undefined && address === null) {
            if (tenantInfo.billingAddresses !== undefined && tenantInfo.billingAddresses !== null) {
                return AddressParser.getPreferAddress(tenantInfo.billingAddresses)
            }
        }

        var newAddress = address
        var sameAddress = undefined
        if (tenantInfo.billingAddresses !== undefined && tenantInfo.billingAddresses !== null) {
            var billingAddresses = tenantInfo.billingAddresses

            billingAddresses.forEach(element => {
                if(this.isSameAddress(address, element)) {
                    sameAddress = element
                }
            });

            if(sameAddress === undefined) {
                var preferAddress = AddressParser.getPreferAddress(billingAddresses)
                newAddress = preferAddress
            }
            else {
                newAddress = sameAddress
            }
        }
        return newAddress
    }

    static isSameAddress(add1, add2) {
        if (add1 !== undefined && add1 !== null && add2 !== undefined && add2 !== null) {
            return (
                add1.address1 === add2.address1 &&
                add1.address2 === add2.address2 &&
                add1.city === add2.city &&
                add1.state === add2.state &&
                add1.postalCode === add2.postalCode &&
                add1.country === add2.country
            )
        }

        return false
    }
}
