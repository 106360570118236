import CustomFieldManager from "../../Manager/CustomFieldManager"
import TemplateSettingsManager from "../../Manager/TemplateSettingsManager"
import { getDateString } from "../../Utilities/Utility"
import DataParser from "../DataParser"
import LineItemsParser from "../LineItemsParser"
import TenantParser from "../TenantParser"

export default class WorkOrderParser {
    static parseData(dataFromAPI, templateDataToReturn) {

        //custom field
        let customFields = this.getWorkOrderResponse(dataFromAPI) ? this.getWorkOrderResponse(dataFromAPI).productCustomField ? this.getWorkOrderResponse(dataFromAPI).productCustomField  : [] : [] 
        templateDataToReturn.customFields = customFields
        CustomFieldManager.setCustomFields(customFields)
        templateDataToReturn = DataParser.assignCustomFieldValue(templateDataToReturn)

        //ref number
        templateDataToReturn.refNumber = this.getWorkOrderResponse(dataFromAPI) ? this.getWorkOrderResponse(dataFromAPI).documentSequenceCode : ''

        //link document type 
        templateDataToReturn.linkDocumentType = undefined
        templateDataToReturn.linkDocumentNumber = undefined
        var parentWorkOrderSeqCode = this.getWorkOrderResponse(dataFromAPI) ? this.getWorkOrderResponse(dataFromAPI).parentWorkOrderSeqCode : undefined
        if (parentWorkOrderSeqCode !== undefined && parentWorkOrderSeqCode !== null) {
            // templateDataToReturn.linkDocumentType = 'parent_work_order'
            templateDataToReturn.parentWorkOrder = parentWorkOrderSeqCode
        }
        
        templateDataToReturn.linkDocumentType = 'sales_order'
        var workOrderSourceDetails = this.getWorkOrderResponse(dataFromAPI) ? this.getWorkOrderResponse(dataFromAPI).workOrderSourceDetails : undefined
        if (workOrderSourceDetails !== undefined && workOrderSourceDetails !== null) {
            var salesOrderSequenceCode = workOrderSourceDetails.salesOrderSequenceCode
            if (salesOrderSequenceCode !== undefined && salesOrderSequenceCode !== null) {
                templateDataToReturn.linkDocumentType = 'sales_order'
                templateDataToReturn.linkDocumentNumber = salesOrderSequenceCode
            }
        }

          //start date
        let startDate = ''
        let startDateTimeStamp = this.getWorkOrderResponse(dataFromAPI) ? this.getWorkOrderResponse(dataFromAPI).actualStartDate : ''
        if (startDateTimeStamp !== '') {
            startDate = getDateString(new Date(startDateTimeStamp), templateDataToReturn.dateFormat)
        }
        templateDataToReturn.date = startDate

        let endDate = ''
        let endDateTimeStamp = this.getWorkOrderResponse(dataFromAPI) ? this.getWorkOrderResponse(dataFromAPI).actualEndDate : ''
        if (endDateTimeStamp !== '') {
            endDate = getDateString(new Date(endDateTimeStamp), templateDataToReturn.dateFormat)
        }
        templateDataToReturn.dueDate = endDate
        templateDataToReturn.showDueDate = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.dueDate.isVisible

        let deliveryDate = ''
        let deliveryDateTimeStamp = this.getWorkOrderResponse(dataFromAPI) ? this.getWorkOrderResponse(dataFromAPI).deliveryDate : ''
        if (deliveryDateTimeStamp !== '') {
            deliveryDate = getDateString(new Date(deliveryDateTimeStamp), templateDataToReturn.dateFormat)
        }
        templateDataToReturn.deliveryDate = deliveryDate

        templateDataToReturn = this.getDefaultValue(dataFromAPI, templateDataToReturn, 'productName')
        templateDataToReturn = this.getDefaultValue(dataFromAPI, templateDataToReturn, 'productCode')
        templateDataToReturn = this.getDefaultValue(dataFromAPI, templateDataToReturn, 'productDocSeqCode')
        if (templateDataToReturn.productDocSeqCode) {
            templateDataToReturn.productCode = templateDataToReturn.productDocSeqCode
        }
        templateDataToReturn = this.getDefaultValue(dataFromAPI, templateDataToReturn, 'estimatedTime')
        templateDataToReturn = this.getDefaultValue(dataFromAPI, templateDataToReturn, 'actualQuantity')
        templateDataToReturn = this.getDefaultValue(dataFromAPI, templateDataToReturn, 'manufactureQuantity')

        templateDataToReturn.customerOrderNumber = ''
        if (dataFromAPI.salesOrderCustomerOrderNumber) {
            templateDataToReturn.customerOrderNumber = dataFromAPI.salesOrderCustomerOrderNumber
        }
        if (workOrderSourceDetails !== undefined && workOrderSourceDetails !== null) {
            if (workOrderSourceDetails.salesOrderCustomerOrderNumber !== undefined
                && workOrderSourceDetails.salesOrderCustomerOrderNumber !== null) {
                templateDataToReturn.customerOrderNumber = workOrderSourceDetails.salesOrderCustomerOrderNumber
            }
        }
        //line items
        templateDataToReturn.lineItems = LineItemsParser.getWorkOrderItems(dataFromAPI, templateDataToReturn.dateFormat)
        
        //check if contains 'SCRAP' or 'COPRODUCT'
        templateDataToReturn.isContainsScrapProduct = false
        templateDataToReturn.isContainsCoproduct = false
        if (templateDataToReturn.lineItems.length > 0) {
            var scrapProduct = templateDataToReturn.lineItems.find(x => x.produceProductType === 'SCRAP')
            templateDataToReturn.isContainsScrapProduct = scrapProduct !== undefined
            var coproductProduct = templateDataToReturn.lineItems.find(x => x.produceProductType === 'COPRODUCT')
            templateDataToReturn.isContainsCoproduct = coproductProduct !== undefined
        }

        templateDataToReturn = TenantParser.parseLocationDetail(dataFromAPI, templateDataToReturn)

        //work order barcode
        var barcodeList = []
        if(dataFromAPI.jobCardResponseList !== undefined && dataFromAPI.jobCardResponseList !== null) {
            if(dataFromAPI.jobCardResponseList.length > 0) {
                dataFromAPI.jobCardResponseList.forEach(element => {
                    var item = {
                        label: element.operationName,
                        value: element.documentSequenceCode 
                    }
                    barcodeList.push(item)
                });
            } 
        }
        templateDataToReturn.barcodeList = barcodeList
        return templateDataToReturn
    }

    static getWorkOrderResponse(dataFromApi) {
        if (dataFromApi.workOrderResponse !== undefined && dataFromApi.workOrderResponse !== null) {
            return dataFromApi.workOrderResponse
        }
        return undefined
    }

    static getDefaultValue(dataFromAPI, templateDataToReturn, key) {
        templateDataToReturn[key] = this.getWorkOrderResponse(dataFromAPI) ? this.getWorkOrderResponse(dataFromAPI)[key] : ''
        return templateDataToReturn
    }
}
