export default class Address {

    constructor(name, add, pan, gstin, contactNumber, contactCode, ownerType) {
        this.name = name
        if(add !== undefined && add !== null) {
            this.address1 = add.address1
            this.address2 = add.address2
            this.city = add.city
            this.state = add.state
            this.postalCode = add.postalCode
            this.country = add.country
            this.contactName = add.contactName
        }
        this.pan = pan
        this.gstin = gstin
        this.contactNumber = contactNumber
        this.contactCode = contactCode
        if(ownerType !== undefined) {
            this.ownerType = ownerType
        }
    }

    initPayslipAddress(add) {
        this.address1 = add.addressLine1
        this.address2 = add.addressLine2
        this.city = add.city
        this.state = add.state
        this.postalCode = add.zipCode
        this.country = add.country
    }
}
