export default class ApiConstants {
  static URL = {
    BASE: process.env.REACT_APP_BASE_URL,
    LOGIN_REDIRECT: process.env.REACT_APP_LOGIN_URL,
    SIGN_UP: process.env.REACT_APP_SIGN_UP_URL,
    BASE_WITHOUT_VERSION: process.env.REACT_APP_BASE_URL_NO_VERSION,
    DOC_BUILDER: process.env.REACT_APP_DOC_BUILDER_URL,

    LOGIN_STATUS: "v1/iam/auth/sign-in/login/status",

    CUSTOM_FIELDS: "v1/dimension?limit=1000&page=0",
    CREATE_CUSTOM_FIELDS: "v1/settings/custom-fields",

    FILE_UPLOAD: "v1/file/upload",
    ALL_UPLOADED_IMAGES: "v1/file/upload/list",

    GET_INVOICE: "v1/template/print-info",
    SEND_EMAIL: "v1/anonymous/document-designer/email",
    ATTACHMENT_UPLOAD: "v1/attachment/file-upload",
    TEMPLATE_SETTINGS: "v1/template/document",
    GET_ERP_TEMPLATE_SETTINGS: "v1/template/document/ERP/",
    GET_PEOPLE_TEMPLATE_SETTINGS: "v1/template/document/PEOPLE/",
    GET_MRP_TEMPLATE_SETTINGS: "v1/template/document/MRP/",
    CREATE_SHARE_LINK: "v1/template/print-info",
    GET_SHARE_LINK: "/v1/template/deskera/open/print-info/",
    GET_PRINT_INFO: "/v1/template/deskera/open/print-payload",
    COOKIE_DOMAIN: process.env.REACT_APP_COOKIE_DOMAIN,
  };

  static TENANT_ID = null;
  static TENANT_NAME = null;
  static ACCESS_TOKEN = null;
  static USER_EMAIL = null;
  static TENANT_COUNTRY = null;
  static TENANT_CURRENCY = null;
  static LOCALHOST_URL = "localhost:3000";
}
