import React, { Component } from "react";
import "../../DocPreview.css";
import "../../CommonStyles/FontStyle.css";
import { COLOR_LIGHTGRAY, FONT_SIZE } from '../../Constants/Constants';
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import DKEInvoiceSection, { eInvoiceSectionPosition } from "../../DKUILibrary/DKEInvoiceSection";
import DKSignatureSection from "../../DKUILibrary/DKSignatureSection";
import { AddressType } from "../../Helper/AddressParser";
import AppManager from "../../Manager/AppManager";
import ComponentManager from "../../Manager/ComponentManager";
import DocumentManager from '../../Manager/DocumentManager';
import { getLocalisedText } from '../../Translate/LanguageManager';
import { HTMLTag } from "../../Utilities/HTMLTag";
import Utility, { getCapitalized, getFontSizeClass, getPageHeight, getVW, getIsFulfillment, getIsGoodsReceipt } from "../../Utilities/Utility";
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";

export default class DocumentTemplate24 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    addCompanyNameLabel() {
        return (
            <div className="RowDiv"
                style={{
                    width: Utility.getIsArabicLang() ? '100%' : getVW('18vw', true),
                    alignItems: 'flex-start',
                    flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                }}>
                <text
                    style={{
                        fontWeight: "bold",
                        color: 'gray',
                        textAlign: 'start',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                    }}
                >
                    {getLocalisedText(Utility.getCompanyName(this.state.data)).toUpperCase()}
                </text>
            </div>
        )
    }

    addAddressField(title, value, addressType) {
        return (
            <div>
                {ComponentManager.getDescriptionSection(
                    title,
                    value,
                    getVW(`${Utility.convertToPrintScale(15, this.state.data.printScale)}vw`, true),
                    'gray',
                    false,
                    '#000000',
                    getVW(6),
                    Utility.getOwnerType(this.state.data, addressType),
                    this.state.data.printScale
                )}
            </div>
        )
    }


    addTypeLabel() {
        return (
            <div className="RowDiv" style={{ align: 'flex-start' }}>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        paddingBottom: getVW(5),
                        color: this.state.data.themeColor,
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xLarge }, this.state.data.printScale)
                    }}>
                        {getCapitalized(getLocalisedText(this.state.data.documentType))}
                </text>
            </div>
        )
    }

    addTableViewWithCompanyDetails() {
        return (
            <div className="ColumnDiv" style={{ width: '100%' }}>
                {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(40, this.state.data.printScale))}
                <div className="RowDiv" style={{ alignItems: 'flex-start' }}>
                    <div style={{ width: '20%' }}>
                        {this.addInvoiceNumberAndDOIView()}
                    </div>
                    <div style={{
                        width: '80%',
                        paddingLeft: Utility.getIsArabicLang() ? getVW(Utility.convertToPrintScale(10, this.state.data.printScale)) : 0,
                    }}>
                        {this.getTableSection()}
                    </div>

                </div>
            </div>
        )
    }

    addInvoiceNumberAndDOIView() {
        return (
            <div className="ColumnDiv" style={{ width: '100%', alignItems: 'flex-end', textAlign: 'end',   }}>
                {this.getDocumentDetailsSection()}
                {Utility.getIsContainsCustomField('top') &&
                    <>
                    {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(Utility.convertToPrintScale(15, this.state.data.printScale))}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} headerColor={'gray'} style={this.getCustomFieldTableStyle()} customFieldPosition={'top'} />
                    </>
                }
            </div>
        )
    }

    getDocumentDetailsSection() {
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        return documentDetailList.map((element, index) => {
            var value = ''
            if (element.key !== undefined) {
                value = this.state.data[element.key]
            }
            else if (element.customFieldValue !== undefined) {
                value = element.customFieldValue
            }

            return <>
                {ComponentManager.getTitleAndValueColumn(
                    element.label,
                    value,
                    'gray',
                    "black",
                    Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    this.state.data.printScale
                )}
                {ComponentManager.addVerticalSpace(15)}
            </>
        });
    }

    addFromField() {
        return (
            <div>
                {ComponentManager.getDescriptionSection(
                    "",
                    Utility.getCompanyAddress(this.state.data),
                    Utility.getIsArabicLang() ? '100%' : getVW('20vw', true),
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6),
                    Utility.getOwnerType(this.state.data, AddressType.from),
                    this.state.data.printScale
                )}
            </div>
        )
    }

    addHeaderSection() {
        return (
            <div className="ColumnDiv" style={{ width: '100%', alignItems: 'flex-start' }}>
                {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(20, this.state.data.printScale))}
                <div className="RowDiv" style={{ justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <div>
                        {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(20, this.state.data.printScale))}
                        {this.addTypeLabel()}
                        {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(20, this.state.data.printScale))}
                        <div className="RowDiv" style={{ alignItems: 'flex-start' }}>
                            {this.state.data.showClientShipToAddress && this.addAddressField(this.state.data.shipToTitle, Utility.getClientShipToAddress(this.state.data), AddressType.shipTo)}
                            {(this.state.data.showClientShipToAddress && this.state.data.showClientShipToAddress) && ComponentManager.addHorizontalSpace(Utility.convertToPrintScale(10, this.state.data.printScale))}
                            {this.state.data.showClientBillToAddress && this.addAddressField(this.state.data.billToTitle, Utility.getClientBillToAddress(this.state.data), AddressType.billTo)}
                        </div>
                        {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data) || Utility.getIsShowPlaceOfSupply(this.state.data)) && ComponentManager.addVerticalSpace(20)}
                        {Utility.getIsShowPlaceOfSupply(this.state.data) && this.getPlaceOfSupplyInfoSection()}
                        <div className="RowDiv" style={{ alignItems: 'flex-start' }}>
                            {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && this.getSourceOfSupplyInfoSection()}
                        </div>
                        {(this.state.data.showCompanyName || this.state.data.showFrom) && ComponentManager.addVerticalSpace(20)}
                        {this.state.data.showCompanyName && this.addCompanyNameLabel()}
                        {this.state.data.showFrom && this.addFromField()}
                    </div>
                    {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) &&ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(80, this.state.data.printScale), {right: 0, top: 0, position: "static"})}
                </div>
            </div>
        )
    }

    getTableSection() {
        return (
            <div className="ColumnDiv">
                {
                    TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                    <>
                        <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                            dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                        {ComponentManager.addVerticalSpace(5)}
                    </>
                }
                <div className="RowDiv">
                    {this.getProductTable()}
                </div>
                {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(10)}
                <div className="RowDiv">
                    {this.getEditableDocumentTable()}
                </div>
                <div style={{ marginTop: getVW('-0.25vw', true), width: getVW(`${Utility.convertToPrintScale(15, this.state.data.printScale)}vw`, true), height: getVW(1), backgroundColor: 'black', alignSelf: 'flex-end' }}></div>
                {ComponentManager.addVerticalSpace(10)}
                {
                    Utility.getIsContainsTotalSection(this.state.data) &&
                    ComponentManager.getTitleAndValueColumn(
                        AppManager.getTypeTotalString(this.state.data.type).toUpperCase(),
                        Utility.getTotalValue(this.state.data),
                        'gray',
                        "gray",
                        "flex-end",
                        FONT_SIZE.medium,
                        FONT_SIZE.medium,
                        'bold',
                        5,
                        Utility.getIsSaudiArabiaCountry(this.state.data) ? 'rtl' : 'ltr',
                        this.state.data.printScale
                    )
                }
                <div className="RowReverseDiv" id={HTMLTag.SIGNATURE}>
                    <div className="ColumnDiv">
                        {ComponentManager.addVerticalSpace(30)}
                        {this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature, this.state.data.printScale)}
                    </div>
                </div>
            </div>
        )
    }

    getTermsAndConditions() {
        return (
            <div id={HTMLTag.TERMS_NOTE_PREFIX + HTMLTag.TERMS} style={{ bottom: 0, width: '100%' }}>
                {this.state.data.termsAndCondition &&
                    ComponentManager.getDescriptionSection(
                        Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition')),
                        this.state.data.termsAndCondition,
                        "100%",
                        'gray',
                        undefined,
                        undefined,
                        getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                        undefined,
                        this.state.data.printScale
                    )}
            </div>
        )
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ justifyContent: 'space-between', minHeight: getPageHeight(this.state.data.printScale) }}>
                <div className='parent-width' style={{ width: '100%', paddingBottom: getVW(30)  }}>
                    {/*<div className="DocumentHolder">*/}
                    <DKEInvoiceSection
                        data={this.state.data}
                        position={eInvoiceSectionPosition.top}
                    />
                    {this.addHeaderSection()}
                    {this.addTableViewWithCompanyDetails()}
                    {/*</div>*/}
                </div>
                <div className='parent-width' style={{ width: '100%' }}>
                    {(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) && <DKSignatureSection data={this.state.data} onSignatureSectionClicked={this.props.tableClicked} headerColor={'gray'}/>}
                    {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && this.getTermsAndConditions()}
                </div>
                <DKEInvoiceSection
                    data={this.state.data}
                    position={eInvoiceSectionPosition.bottom}
                />
            </div>
        );
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getProductTable() {
        if(Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKProductTable(
                Utility.getTableColumnForAccountProduct(),
                this.getTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerBackgroundColor = this.state.data.themeColor;
        style.headerTextColor = "#ffffff";
        style.headerBorderColor = this.state.data.themeColor;
        style.itemBorderColor = 'white';
        style.itemBackgroundColor = COLOR_LIGHTGRAY;
        style.itemBackgroundColorIsAlternate = false;
        style.footerBackgroundColor = 'transparent'
        style.footerTextColor = "transparent";
        style.footerBorderColor = "transparent";
        style.subTotalBorderColor = 'transparent';
        return style;
    }

    getCustomFieldTableStyle() {
        let style = {}
        style.columnCount = 1
        style.columnWidths = ['100%']
        return style;
    }

    getPlaceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getPlaceOfSupply()}

            </div>
        )
    }
    getPlaceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '100%'
            }}>
                {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('place_of_supply'),
                    this.state.data.placeOfSupply,
                    "100%",
                    'gray',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>

        </div>;

    }


    getSourceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getSourceOfSupply()}

            </div>
        )
    }

    getSourceOfSupply() {
        return <div className='RowDiv' style={{ width: '100%',marginTop:"10px" }}>
            <div className='ColumnDiv' style={{
                width: '51%'
            }}>
                {Utility.getIsShowSourceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('source_of_supply'),
                    this.state.data.sourceOfSupply,
                    "100%",
                    'gray',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>
            <div className='ColumnDiv' style={{
                width: '50%'
            }}>
                {Utility.getIsShowDestinationOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('destination_of_supply'),
                    this.state.data.destinationOfSupply,
                    "100%",
                    'gray',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
          </div>

        </div>;
    }
}

