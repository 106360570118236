import React, { Component } from "react";
import "../../DocPreview.css";
import "../../CommonStyles/FontStyle.css";
import { FONT_SIZE } from '../../Constants/Constants';
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import DKEInvoiceSection, { eInvoiceSectionPosition } from "../../DKUILibrary/DKEInvoiceSection";
import DKSignatureSection from "../../DKUILibrary/DKSignatureSection";
import { AddressType } from "../../Helper/AddressParser";
import ComponentManager from "../../Manager/ComponentManager";
import DocumentManager from '../../Manager/DocumentManager';
import { getLocalisedText } from '../../Translate/LanguageManager';
import { HTMLTag } from "../../Utilities/HTMLTag";
import Utility, { getFontSizeClass, getPageHeight, getVW, getIsFulfillment, getIsGoodsReceipt, getIsJournalEntry } from "../../Utilities/Utility";
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";

// import {StyleSheet} from "react";

export default class DocumentTemplate31 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ justifyContent: 'space-between', minHeight: getPageHeight(this.state.data.printScale) }}>
                <div className='parent-width' style={{ width: '100%', paddingBottom: getVW(30)  }}>
                    <DKEInvoiceSection
                        data={this.state.data}
                        position={eInvoiceSectionPosition.top}
                    />
                    {this.getHeaderSection()}
                    {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.addVerticalSpace(20)}
                    {Utility.getIsShowPlaceOfSupply(this.state.data) && this.getPlaceOfSupplyInfoSection()}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && ComponentManager.addVerticalSpace(20)}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && this.getSourceOfSupplyInfoSection()}
                    {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(20)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} customFieldPosition={'top'} />
                    {ComponentManager.addVerticalSpace(20)}
                    {/* {this.getDocumentInfoSection()} */}
                    {this.getDocumentDetailsSection()}
                    {ComponentManager.addVerticalSpace(10)}
                    {
                        TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                        <>
                            <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                                dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                            {ComponentManager.addVerticalSpace(5)}
                        </>
                    }
                    {this.getProductTableSection()}
                    {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(10)}
                    {this.getTableSection()}
                    {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(20)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} customFieldPosition={'bottom'} />
                    <div id={HTMLTag.SIGNATURE}>
                        {ComponentManager.addVerticalSpace(30)}
                        {this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature, this.state.data.printScale)}
                    </div>
                </div>
                <div className='parent-width' style={{ width: '100%' }}>
                    {this.getFooterSection()}
                </div>
                <DKEInvoiceSection
                    data={this.state.data}
                    position={eInvoiceSectionPosition.bottom}
                />
            </div>
        );
    }

    /////// Header Section ///////////
    getHeaderSection() {
        return (
            <div className="ColumnDiv" style={{ width: '100%' }}>
                <div className="RowDiv" style={{ width: '100%', justifyContent: 'center' }}>
                    {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) && ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(80, this.state.data.printScale), {position: 'relative'})}
                </div>
                <div className="RowDiv"
                    style={{ width: '100%', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <div className="ColumnDiv" style={{ width: '100%' }}>
                        {this.state.data.showClientBillToAddress && this.getBillingAddress()}
                    </div>
                    <div className="ColumnDiv" style={{ width: '100%' }}>
                        {(this.state.data.showCompanyName || this.state.data.showFrom) && this.getCompanyAddress()}
                    </div>
                </div>
            </div>
        )
    }

    getCompanyAddress() {
        return <>
            {this.state.data.showCompanyName && <div className="RowReverseDiv" style={{ width: '100%' }}>
                <text className={"docFontStyleBold" + getFontSizeClass()} style={{
                    paddingBottom: getVW(5),
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }, this.state.data.printScale),
                }}>
                    {getLocalisedText(Utility.getCompanyName(this.state.data))}
                </text>
            </div>}
            {this.state.data.showFrom && <div className="RowReverseDiv" style={{ width: '100%' }}>
                <div className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        textAlign: 'right',
                        width: '100%',
                        overflow: 'hidden',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.from) }, this.state.data.printScale),
                    }}
                        dangerouslySetInnerHTML={{__html: getLocalisedText(Utility.getCompanyAddress(this.state.data))}}>
                </div>
            </div>}
        </>;
    }

    getBillingAddress() {
        return <>
            <text className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                    paddingBottom: getVW(5),
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium, ownerType: Utility.getOwnerType(this.state.data, AddressType.billTo) }, this.state.data.printScale),
                }}>
                    {getLocalisedText(this.state.data.billToTitle).toUpperCase()}
                </text>
            <div className={"docFontStyle" + getFontSizeClass()}
                style={{
                    width: '100%',
                    overflow: 'hidden',
                    textAlign: 'left',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.billTo) }, this.state.data.printScale),
                }} dangerouslySetInnerHTML={{__html: getLocalisedText(Utility.getClientBillToAddress(this.state.data))}}>

            </div>
        </>;
    }

    /////// Document Info Section ///////////
    //style={{paddingRight: 15, backgroundColor: 'pink'}}

    getDocumentInfoSection(mainContainer) {
        return (
            <div className="RowDiv" style={{
                backgroundColor: this.state.data.themeColor,
                borderRadius: getVW(Utility.convertToPrintScale(3, this.state.data.printScale)),
                paddingTop: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                paddingBottom: getVW(Utility.convertToPrintScale(10, this.state.data.printScale))
            }}>
                <div className="RowDiv" style={{ margin: '2%', width: '98%', justifyContent: 'space-between' }}>
                    {mainContainer}
                </div>
            </div>
        )
    }

    getDocumentDetailsSection() {
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        var maxSize = 4

        if(documentDetailList.length <= maxSize) {
            var row = documentDetailList.map(element => {
                var value = ''
                if (element.key !== undefined) {
                    value = this.state.data[element.key]
                }
                else if (element.customFieldValue !== undefined) {
                    value = element.customFieldValue
                }
                return this.getDocumentDetailsItem(
                    element.label,
                    value
                )
            });

            return this.getDocumentInfoSection(row)
        }
        else {
            var rowArr = []
            while (documentDetailList.length) {
                rowArr.push(documentDetailList.splice(0, 4))
            };

            return rowArr.map((rowElement) => {
                var value = ''
                var row = rowElement.map(element => {
                    if (element.key !== undefined) {
                        value = this.state.data[element.key]
                    }
                    else if (element.customFieldValue !== undefined) {
                        value = element.customFieldValue
                    }
                    return this.getDocumentDetailsItem(
                        element.label,
                        value
                    )
                })

                return this.getDocumentInfoSection(row)
            });
        }

    }

    getDocumentDetailsItem(label, value) {
        return <div className="ColumnDiv">
            <text className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                    color: 'white',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xMedium }, this.state.data.printScale),
                }}>
                {label}
            </text>
            {ComponentManager.addVerticalSpace(5)}
            {ComponentManager.addDivider({ backgroundColor: '#FFFFFF4D', height: getVW(Utility.convertToPrintScale(2, this.state.data.printScale)), width: '100%' })}
            {ComponentManager.addVerticalSpace(5)}
            <text className={"docFontStyle" + getFontSizeClass()}
                style={{
                    color: 'white',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }, this.state.data.printScale),
                }}>
                {value}
            </text>
        </div>;
    }

    /////// Table Section ///////////
    getTableSection() {
        return (<div className="RowDiv">
            {this.getEditableDocumentTable()}
        </div>)
    }

    getProductTableSection() {
        return (<div className="RowDiv">
            {this.getProductTable()}
        </div>)
    }

    /////// Footer Section ///////////
    getFooterSection() {
        return (
            <div className="ColumnDiv" style={{ marginLeft: '5%', width: '90%', bottom: 0 }}>
                {ComponentManager.addVerticalSpace(20)}
                {(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) && <DKSignatureSection data={this.state.data} onSignatureSectionClicked={this.props.tableClicked} />}
                <div id={HTMLTag.TERMS_NOTE_PREFIX} className="RowDiv" style={{ alignItems: 'flex-start' }}>
                    {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) &&
                        ComponentManager.getDescriptionSection(
                            Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition')),
                            this.state.data.termsAndCondition,
                            "100%",
                            undefined,
                            undefined,
                            undefined,
                            getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                            undefined,
                            this.state.data.printScale
                        )}
                    {((this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && (this.state.data.showNotes && this.state.data.notes)) && ComponentManager.addHorizontalSpace(20)}
                    {(this.state.data.showNotes && this.state.data.notes) &&
                        ComponentManager.getDescriptionSection(
                            Utility.getNotesTitle(getIsJournalEntry(this.state.data.type) ? getLocalisedText('memo') : getLocalisedText('notes')),
                            this.state.data.notes,
                            "100%",
                            undefined,
                            undefined,
                            undefined,
                            getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                            undefined,
                            this.state.data.printScale
                        )}
                </div>
            </div>

        )
    }


    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getProductTable() {
        if(Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKProductTable(
                Utility.getTableColumnForAccountProduct(),
                this.getTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerTextColor = this.state.data.themeColor;
        style.footerTextColor = 'white';
        style.headerBackgroundColor = "transparent";
        style.headerBorderColor = "transparent";
        style.itemBorderColor = "transparent";
        style.footerBackgroundColor = this.state.data.themeColor;
        style.footerBorderColor = "transparent";
        return style;
    }

    getPlaceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getPlaceOfSupply()}

            </div>
        )
    }
    getPlaceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('place_of_supply'),
                    this.state.data.placeOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>

        </div>;

    }

    getSourceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getSourceOfSupply()}

            </div>
        )
    }

    getSourceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '30%'
            }}>
                {Utility.getIsShowSourceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('source_of_supply'),
                    this.state.data.sourceOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowDestinationOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('destination_of_supply'),
                    this.state.data.destinationOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
          </div>

        </div>;
    }
}
