import React, { Component } from "react";
import "../../DocPreview.css";
import { FONT_SIZE } from "../../Constants/Constants";
import NumberUtility from "../../Utilities/NumberUtility";
import Utility, { getPageHeight, getVW } from "../../Utilities/Utility";
import "./css/Templates.css";

export default class ChequeTemplate3 extends Component {

    totalHeight = 196.64
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ justifyContent: 'center', minHeight: getPageHeight(this.state.data.printScale)}}>
                <div className='parent-width' style={{ width: '100%'}}>
                    {this.renderCheque()}
                    {/* {this.renderChequeContainer()} */}
                    {/* {this.renderChequeContainer()} */}
                    {/* {this.renderChequeContainer()} */}
                </div>
            </div>
        );
    }

    renderCheque() {
        if (this.state.data.lineItems === undefined || this.state.data.lineItems === null) {
            return undefined
        }

        if (this.state.data.lineItems.length === 0) {
            return undefined
        }

        var cheques = this.state.data.lineItems.map(element => {
            return this.renderChequeContainer(element)
        });

        return cheques
    }

    renderChequeContainer(element) {
        return <div className="break-inside-avoid" style={{ paddingTop: getVW(7.6), paddingBottom: getVW(7.6) }}>
            <div style={{ border: 'solid windowText 1.33px', }}>
                <div style={{
                    height: getVW(Utility.convertToPrintScale(100, this.state.data.printScale)),
                }}>
                </div>
                {this.renderDateContainer(element)}
                <div style={{
                    height: getVW(Utility.convertToPrintScale(25, this.state.data.printScale)),
                }}>
                </div>
                {this.renderTopHeaderContainer(element)}
                <div style={{
                    height: getVW(Utility.convertToPrintScale(12, this.state.data.printScale)),
                }}>
                </div>
                {this.renderMiddleContainer(element)}
                <div style={{
                    height: getVW(Utility.convertToPrintScale(12, this.state.data.printScale)),
                }}>
                </div>
                {this.renderBottomContainer(element)}
                <div style={{
                    height: getVW(5),
                }}>
                </div>
            </div>
        </div>
    }

    renderDateContainer(element) {
        return <div className="RowDiv" style={{ margin: getVW(Utility.convertToPrintScale(8, this.state.data.printScale)), marginBottom: 0 }}>
            {/* {this.renderTopHeaderTitleColumn(getLocalisedText('pay_to'))}
            {this.renderTopHeaderDataColumn(element.payTo)} */}
            <div style={{
                width: getVW(Utility.convertToPrintScale(670, this.state.data.printScale)),
            }}>
            </div>
            {/* {this.renderTopHeaderTitleColumn(getLocalisedText('date'))} */}
            {this.renderTopHeaderDataColumn(element.date)}
        </div>
    }

    renderTopHeaderContainer(element) {
        return <div className="RowDiv" style={{ margin: getVW(8), marginBottom: 0 }}>
            <div style={{
                width: '10%',
            }}>
            </div>
            <div style={{
                width: '70%',
            }}>
                {this.renderTopHeaderDataColumn(element.payTo)}
            </div>
            <div style={{
                width: '20%',
            }}>
                {this.renderMiddleDataColumn(Utility.toCurrencyFormat(element.amount, NumberUtility.getCurrency(this.state.data)))}
            </div>
        </div>
    }

    renderMiddleContainer(element) {
        return <div className="RowDiv"
            style={{
                alignItems: 'baseline',
                marginLeft: getVW(8),
                marginRight: getVW(8),
            }}>
            {/* {this.renderMiddleTitleColumn(getLocalisedText('amount_in_words'))} */}
            {this.renderMiddleDataColumn(element.amountInWords, { padding: getVW(7.6)})}
            {/* {this.renderMiddleTitleColumn(NumberUtility.getCurrency(this.state.data))}
            {this.renderMiddleDataColumn(Utility.toCurrencyFormat(element.amount, undefined), { border: 'solid windowText 1.33px', height: getVW(28.533), width: getVW(135), justifyContent: 'center', paddingLeft: getVW(7.6) })} */}
        </div>
    }

    renderBottomContainer(element) {
        return <div className="RowDiv"
            style={{
                alignItems: 'baseline',
                margin: getVW(8),
                marginTop: 0,
            }}>
            <div style={{
                width: getVW(Utility.convertToPrintScale(50, this.state.data.printScale)),
            }}>
            </div>
            <div style={{
                ...this.defaultStyle(),
                }}
                dangerouslySetInnerHTML={{ __html: element.signature }}>
            </div>
        </div>
    }

    renderTopHeaderTitleColumn(text) {
        return <div style={{
            fontWeight: 'bold',
            ...this.defaultStyle()
        }}>
            {text} &nbsp;
        </div>
    }

    renderTopHeaderDataColumn(text) {
        return <div style={{
            ...this.defaultStyle()
        }}>
            {text} &nbsp;
        </div>
    }

    renderMiddleTitleColumn(text) {
        return <div style={{
            fontWeight: 'bold',
            paddingTop: getVW(7.6),
            paddingBottom: getVW(7.6),
            ...this.defaultStyle()
        }}>
            {text} &nbsp;
        </div>
    }

    renderMiddleDataColumn(text, additionalStyle) {
        return <div className="ColumnDiv" style={{
            ...additionalStyle,
            ...this.defaultStyle()
        }}>
            {text} &nbsp;
        </div>
    }



    defaultStyle() {
        return {
            fontFamily: 'calibri san-serif',
            lineHeight: '115%',
            textAlign: 'left',
            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium },this.state.data.printScale)
        }
    }
}
