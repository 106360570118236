export const Constants = {
    title: {
        textColor: 'black',
    },
    calendar: {
        width: '200px',
        height: '200px',
        maxWidth: '200px',
        maxHeight: '170px',
        backgroundColor: 'white',
    },
    dayContainer: {
        padding: '0px',
        singleSelectedColor: 'rgb(211, 16, 17)',
        multiSelectedColor: 'grey',
        selectedTextColor: 'white',
        todayContainerColor: 'pink',
        textColor: 'black',
        textFontSize: '12px'
    },
    monthContainer: {
        isLongMonth: true
    },
    actionButton: {
        onSubmitTextColor: 'blue',
        onCancelTextColor: 'red',
        backgroundColor: 'rgba(201, 195, 195)',
        borderColor: 'rgba(201, 195, 195)'
    }
}

export const WeekdayList = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']

export const LongMonth = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const ShortMonth = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

export const ShortMonth_3_char = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
