import { APP_SCROLL_CONTAINER_ID } from '../constants/Constant';
import { showAlert } from 'deskera-ui-library';
import store from '../store';
import { logoutUser } from '../store/slices/authSlice';
import { getItemFromLocalStorage } from '../utility/LocalStorage';

export default class AppManager {
  static scrollToTop() {
    const appContainer = document.getElementById(APP_SCROLL_CONTAINER_ID);
    if (!appContainer) return;

    appContainer.scrollTop = 0;
  }

  static reloadApp() {
    const subDomain = getItemFromLocalStorage('subdomain') || "";
    window.location.href = `${window.location.origin}/${subDomain}`;
    console.log(window.location.href, subDomain);
  }

  static showLogoutConfirmation() {
    let buttons = [
      {
        title: "Cancel",
        className: "bg-gray2 border-m ",
        onClick: () => {},
      },
      {
        title: "Log out",
        className: "bg-red text-white ml-r",
        onClick: () => {
          store.dispatch(logoutUser({})).then(() => AppManager.reloadApp());
        }
      }
    ];
    showAlert("Log out", "Are you sure want to log out the session?", buttons);
  }
}
