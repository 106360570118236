import { ENDPOINTS } from '../constants/EndPoints';
import httpClient from './index';
export interface ICartPayload {
  documentSequenceCode: string;
  cartQuantity: number;
}
export interface ICartResponse extends ICartPayload {
  description: string;
  productId: string;
  name: string;
  salesPrice: number;
  availableQuantity: number;
  productInfo: any;
}
export const getCart = (): Promise<ICartResponse> => {
  return httpClient.get(ENDPOINTS.CART.GET);
};
export const addToCart = (payload: ICartPayload[]) => {
  return httpClient.post(ENDPOINTS.CART.ADD_BULK, payload);
};
export const updateCart = (payload: ICartPayload[]) => {
  return httpClient.put(ENDPOINTS.CART.UPDATE_BULK, payload);
};

export const deleteFromCart = (productIds: string[]) => {
  return httpClient.delete(ENDPOINTS.CART.ADD_BULK, { data: productIds });
};
export const preparePayloadForCart = (
  products: any[],
  existingProducts: ICartResponse[] = []
): ICartPayload[] => {
  return products.map((product) => {
    const itemInCart = existingProducts?.find(
      (item) => item.documentSequenceCode === product.documentSequenceCode
    );
    return {
      documentSequenceCode: product.documentSequenceCode,
      cartQuantity: (itemInCart?.cartQuantity ?? 0) + 1
    };
  });
};
