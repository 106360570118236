import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  DKDateRangePicker,
  DKLabel,
  DKIcons,
  INPUT_TYPE
} from 'deskera-ui-library';
import DocumentGrid from '../components/common/DocumentGrid';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
  fetchInvoices,
  fetchInvoiceSummary,
  selectInvoices,
  selectInvoiceSummary,
  selectTablesData
} from '../store/slices/booksSlice';
import { BOOKS_DOCS_TYPES, DATE_FORMATS } from '../constants/Enum';
import { getQueryFromFilter, sortAndUpdateColumns } from '../services/tables';
import {
  debounce,
  deepClone,
  getCurrencySymbolFromCode,
  isEmptyObject,
  isNotEmpty,
  toCurrencyFormat
} from '../utility/Helper';
import { getDateFromString, getDateStrFromDate } from '../utility/Date';
import {
  COLUMN_CODE,
  COLUMN_CONFIG_FOR_IN,
  COUNTRY_CODES,
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  DEFAULT_THEME_COLOR,
  DELIVERY_STATUS_COLUMN
} from '../constants/Constant';
import { startOfYear } from 'date-fns';
import { endOfYear } from 'date-fns/esm';
import PrintPreview from '../components/common/PrintPreview';
import {
  selectTenantSettings,
  selectUserInfo
} from '../store/slices/authSlice';
import Utility from '../components/DocTemplate/Utilities/Utility';
import SummaryBarGraph from '../components/common/SummaryBarGraph';
import { attachmentRenderer } from '../utility/renderer';
import AttachmentPopup from '../components/common/AttachmentPopup';

interface IInvoicesProps {}
const VISIBLE_COLUMNS_CODES = [
  COLUMN_CODE.INVOICE.Number,
  COLUMN_CODE.INVOICE.CustomerOrderNo,
  COLUMN_CODE.INVOICE.InvoiceDate,
  COLUMN_CODE.INVOICE.InvoiceDueDate,
  COLUMN_CODE.INVOICE.TotalAmount,
  COLUMN_CODE.INVOICE.DueAmount,
  COLUMN_CODE.INVOICE.FulfillmentStatus,
  COLUMN_CODE.INVOICE.PendingStatus
];
const Invoices: React.FC<IInvoicesProps> = (props) => {
  const [searchText, setSearchText] = useState<string>();
  const [pagination, setPagination] = useState<any>({
    page: DEFAULT_PAGE_NO - 1,
    limit: DEFAULT_PAGE_SIZE,
    sortDir: 'DESC',
    sort: 'documentSequenceCode'
  });
  const tableData = useAppSelector(
    selectTablesData(BOOKS_DOCS_TYPES.BOOKS_INVOICE)
  );
  const [columns, setColumns] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const invoiceData = useAppSelector(selectInvoices);
  const [filters, setFilters] = useState<any>();
  const [dateFilters, setDateFilters] = useState<any>({
    financialStartDate: startOfYear(new Date()),
    financialEndDate: endOfYear(new Date())
  });
  const [showHideCalendar, setShowHideCalendar] = useState<boolean>(false);
  const [showHidePrintPopup, setShowHidePrintPopup] = useState<boolean>(false);
  const [selectedInvoice, setSelectedInvoice] = useState<any>();
  const [updating, setUpdating] = useState<boolean>(false);
  const apiCallInProgress = useRef<boolean>(false);
  const settings = useAppSelector(selectTenantSettings);
  const userInfo = useAppSelector(selectUserInfo);
  const invoiceSummary: any = useAppSelector(selectInvoiceSummary);
  const [showAttachmentPopup, setShowHideAttachmentPopup] =
    useState<boolean>(false);
  const invoiceSelectedFor = useRef<'PRINT' | 'ATTACHMENTS' | undefined>(
    undefined
  );

  useEffect(() => {
    fetchInvoiceData();
  }, []);

  useMemo(() => {
    let updateColumns = sortAndUpdateColumns(tableData.columnsMetaData || []);
    updateColumns =
      userInfo?.country === COUNTRY_CODES.US
        ? updateColumns.filter(
            (column) => column.columnCode !== COLUMN_CODE.INVOICE.InvoiceNow
          )
        : updateColumns;

    updateColumns.forEach((column) => {
      switch (column.columnCode) {
        case COLUMN_CODE.INVOICE.DueAmount:
        case COLUMN_CODE.INVOICE.TotalAmount:
        case COLUMN_CODE.INVOICE.COGS:
          return (column.renderer = (obj: any) => {
            const currencyCode = getCurrencySymbolFromCode(
              obj?.rowData?.currency
            );
            return (
              <div className="row justify-content-end">
                {obj.value !== '' && toCurrencyFormat(obj.value, currencyCode)}
              </div>
            );
          });
        case COLUMN_CODE.INVOICE.Profit:
          return (column.renderer = (obj: any) => {
            const currencyCode = getCurrencySymbolFromCode(
              obj?.rowData?.currency
            );
            const subTotalAmount =
              obj?.rowData?.totalAmount - obj?.rowData?.taxAmount;
            const cogsAmount = obj?.rowData?.cogsAmount;

            return (
              <div className="row justify-content-end">
                {toCurrencyFormat(subTotalAmount - cogsAmount, currencyCode)}
              </div>
            );
          });
        case COLUMN_CODE.INVOICE.GrossMargin:
          return (column.renderer = (obj: any) => {
            const subTotalAmount =
              obj?.rowData?.totalAmount - obj?.rowData?.taxAmount;
            const cogsAmount = obj?.rowData?.cogsAmount;

            return (
              <div className="row justify-content-end">
                {cogsAmount
                  ? Utility.roundOffToTenantDecimalScale(
                      ((subTotalAmount - cogsAmount) / subTotalAmount) * 100
                    ) + '%'
                  : ''}
              </div>
            );
          });

        default:
          break;
      }
    });
    setColumns(updateColumns);
  }, [tableData]);

  useEffect(() => {
    if (invoiceSelectedFor.current === 'PRINT') {
      setShowHidePrintPopup(isNotEmpty(selectedInvoice));
    }
    if (invoiceSelectedFor.current === 'ATTACHMENTS') {
      setShowHideAttachmentPopup(isNotEmpty(selectedInvoice));
    }
  }, [selectedInvoice]);

  useEffect(() => {
    fetchInvoiceData();
  }, [searchText, pagination, filters, dateFilters]);

  const getPayload = () => ({
    limit: pagination.limit,
    page: pagination.page,
    search: searchText,
    fetchAttachmentDetails: true,
    query: getQueryFromFilter(
      columns,
      filters?.query,
      dateFilters,
      BOOKS_DOCS_TYPES.BOOKS_INVOICE
    )?.join(','),
    sortDir: pagination.sortDir || 'DESC',
    sort: pagination.sort
  });
  const fetchInvoiceData = () => {
    if (!apiCallInProgress.current) {
      apiCallInProgress.current = true;
      setUpdating(true);
      const payload = getPayload();
      dispatch(
        fetchInvoiceSummary({
          search: payload.search,
          query: { module: 'SALES_INVOICE' },
          fromDate: getDateStrFromDate(dateFilters.financialStartDate),
          toDate: getDateStrFromDate(dateFilters.financialEndDate)
        })
      );
      dispatch(fetchInvoices(payload)).finally(() => {
        apiCallInProgress.current = false;
        setUpdating(false);
      });
    }
  };
  const setInvoiceForPrint = (invoice: any) => {
    invoiceSelectedFor.current = 'PRINT';
    setSelectedInvoice(invoice);
  };
  const getContextMenuForRow = (invoice: any, isContext: boolean = false) => {
    const menuItems = [];
    if (!isEmptyObject(invoice?.paymentInformation?.paymentLink)) {
      menuItems.push({
        title: 'Make Payment',
        icon: !isContext ? null : DKIcons.ic_sales,
        className: !isContext ? 'bg-button text-white' : '',
        onClick: ({ rowIndex, rowData }: any) => {
          window.open(invoice?.paymentInformation?.paymentLink, '_blank');
        }
      });
    }
    if (isContext) {
      menuItems.push({
        title: 'View',
        icon: DKIcons.ic_view,
        onClick: ({ rowIndex, rowData }: any) => setInvoiceForPrint(rowData)
      });
    }
    return menuItems;
  };
  const parseRowData = (invoiceList: any[]) => {
    let updatedRows = [];

    if (isNotEmpty(invoiceList)) {
      updatedRows = deepClone(invoiceList);
      updatedRows = updatedRows.map((row) => {
        row.contact = row?.contact.name;
        row.status = [row.status];
        row.fulfillmentStatus = [row.fulfillmentStatus];
        row.paymentStatus = [row.paymentStatus];
        row.recurring = row.recurring ? ['RECURRING'] : ['NON_RECURRING'];
        row.salesInvoiceDate = getDateFromString(
          row.salesInvoiceDate,
          DATE_FORMATS['DD-MM-YYYY']
        );
        row.salesInvoiceDueDate = getDateFromString(
          row.salesInvoiceDueDate,
          DATE_FORMATS['DD-MM-YYYY']
        );
        row.allowRowEdit = true;
        row.rowContextMenu = getContextMenuForRow(row, true);
        row.rowButtons = getContextMenuForRow(row);
        return row;
      });
    }
    return updatedRows;
  };
  const onSearch = debounce((searchQuery: string) => {
    setSearchText(searchQuery);
  }, 500);
  const getUpdatedColumns = () => {
    let updatedColumns = [...columns];
    if (userInfo?.country === COUNTRY_CODES.IN) {
      updatedColumns = localizeColumnConfig(updatedColumns);
    }
    updatedColumns = updatedColumns.filter((item) =>
      VISIBLE_COLUMNS_CODES.includes(item.columnCode)
    );
    updatedColumns.push({
      name: `Amount Paid`,
      type: 'number',
      index: updatedColumns?.length - 1,
      options: null,
      required: false,
      width: 130,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'linkedDocuments',
      key: 'linkedDocuments',
      allowFilter: false,
      renderer: ({ rowData }: any) => {
        const currencyCode = getCurrencySymbolFromCode(rowData?.currency);
        return (
          <div className="row justify-content-end">
            {toCurrencyFormat(
              (rowData.totalAmount || 0) - (rowData.dueAmount || 0),
              currencyCode
            )}
          </div>
        );
      }
    });
    updatedColumns.push({
      name: `Linked ${
        userInfo?.country === COUNTRY_CODES.US ? 'Estimates' : 'Quotes'
      }`,
      type: 'text',
      index: updatedColumns?.length - 1,
      options: null,
      required: false,
      width: 130,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'linkedDocuments',
      key: 'linkedDocuments',
      allowFilter: false,
      renderer: ({ rowData }: any) => {
        if (isEmptyObject(rowData?.linkedDocuments)) {
          return '-';
        }
        const linkedQuotes = rowData?.linkedDocuments?.filter(
          (doc: any) => doc.documentType === 'QUOTATION'
        );
        return linkedQuotes.map((doc: any) => (
          <div className="row">{doc.documentSequenceCode}</div>
        ));
      }
    });
    updatedColumns.push({
      name: 'Attachments',
      type: 'text',
      index: 12,
      options: [],
      required: true,
      width: 153,
      editable: false,
      hidden: false,
      uiVisible: true,
      columnCode: 'attachments',
      dataSource: [],
      key: 'attachments',
      allowAddOption: false,
      allowFilter: false,
      id: '4rXh',
      datasource: null,
      formula: null,
      renderer: (obj: any) =>
        attachmentRenderer(obj, () => {
          invoiceSelectedFor.current = 'ATTACHMENTS';
          setSelectedInvoice(obj.rowData);
        }),
      currency: null
    });

    return [...updatedColumns, getActionButtonsColumn()];
  };
  const localizeColumnConfig = (tempColumnConfig: any[]) => {
    const fulfillmentColumnConfigIndex = tempColumnConfig?.findIndex(
      (config: any) =>
        config.columnCode === COLUMN_CODE.INVOICE.FulfillmentStatus
    );
    if (fulfillmentColumnConfigIndex !== -1) {
      tempColumnConfig[fulfillmentColumnConfigIndex] = {
        ...tempColumnConfig[fulfillmentColumnConfigIndex],
        name: DELIVERY_STATUS_COLUMN,
        options: COLUMN_CONFIG_FOR_IN
      };
    }
    return tempColumnConfig;
  };
  const getActionButtonsColumn = () => {
    return {
      key: 'actions',
      name: 'Actions',
      type: INPUT_TYPE.BUTTON,
      width: 180,
      options: []
    };
  };
  const onFilter = (data: any) => {
    setFilters(data);
  };
  const onPageChange = (page: number) => {
    setPagination({ ...pagination, page: page });
  };
  const getDateRangePicker = () => {
    return (
      <div className="position-absolute shadow-m bg-white z-index-6 top-12 right-20">
        <DKDateRangePicker
          className="border shadow "
          onClose={() => setShowHideCalendar(false)}
          color={settings?.theme || DEFAULT_THEME_COLOR}
          showPresetList={true}
          startDate={dateFilters.financialStartDate}
          selectedStartDate={dateFilters.financialStartDate}
          selectedEndDate={dateFilters.financialEndDate}
          onSelectDateRange={(startDate: Date, endDate: Date) => {
            if (startDate && endDate) {
              setShowHideCalendar(false);
              setDateFilters({
                financialStartDate: startDate,
                financialEndDate: endDate
              });
            }
          }}
        />
      </div>
    );
  };
  const getDateRangeString = () => {
    if (isNotEmpty(dateFilters)) {
      return (
        getDateStrFromDate(dateFilters.financialStartDate) +
        ' to ' +
        getDateStrFromDate(dateFilters.financialEndDate)
      );
    } else {
      return '';
    }
  };
  const getDocumentGridHeaderButtons = () => {
    let buttons = [];
    buttons.push({
      title: getDateRangeString(),
      className: 'border-m bg-white ml-r position-relative',
      icon: DKIcons.ic_calendar,
      onClick: () => setShowHideCalendar(true)
    });
    return buttons;
  };
  const getNumber = (value: any) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    return formatter.format(value);
  };
  const renderSummaryView = () => {
    const currencyCode = getCurrencySymbolFromCode(userInfo?.currency);
    return (
      <div
        className="row flex-no-wrap align-items-start invoice-summary-section"
        style={{ gap: 24 }}
      >
        <div
          className="column parent-width border-radius-m shadow-s border-s p-xl bg-white border-box summary-section"
          style={{ height: '150px' }}
        >
          <div className="row justify-content-between">
            <DKLabel
              className="text-align-left fw-m fs-m"
              text="Invoice Summary"
            />
          </div>
          <div className="row justify-content-between mt-l parent-width align-items-end">
            <div className="column">
              <DKLabel
                style={{ whiteSpace: 'nowrap' }}
                className="text-align-left text-gray fs-m fw-m"
                text="⦿ Total Amount"
              />
              <DKLabel
                style={{ whiteSpace: 'nowrap' }}
                className="fs-l mt-m fw-m"
                text={`${currencyCode} ${getNumber(
                  invoiceSummary?.invoiceAmount || 0
                )}`}
              />
            </div>
            <div
              className="bg-gray2"
              style={{ width: '1px', height: '70px' }}
            ></div>
            <div className="column align-items-center">
              <DKLabel
                style={{ whiteSpace: 'nowrap' }}
                className="text-align-left text-red fs-m fw-m"
                text="⦿ Unpaid"
              />
              <DKLabel
                style={{ whiteSpace: 'nowrap' }}
                className="fs-l mt-m fw-m"
                text={`${currencyCode} ${getNumber(
                  invoiceSummary?.invoiceDueAmount || 0
                )}`}
              />
            </div>
            <div
              className="bg-gray2"
              style={{ width: '1px', height: '70px' }}
            ></div>

            <div className="column align-items-end">
              <DKLabel
                style={{ whiteSpace: 'nowrap' }}
                className="text-align-left text-green fs-m fw-m"
                text="⦿ Paid"
              />
              <DKLabel
                style={{ whiteSpace: 'nowrap' }}
                className="fs-l mt-m fw-m"
                text={`${currencyCode} ${getNumber(
                  (invoiceSummary?.invoiceAmount || 0) -
                    (invoiceSummary?.invoiceDueAmount || 0)
                )}`}
              />
            </div>
          </div>
        </div>
        <SummaryBarGraph
          data={[
            {
              title: 'Paid',
              value:
                (invoiceSummary?.invoiceAmount
                  ? invoiceSummary?.invoiceAmount
                  : 0) -
                (invoiceSummary?.invoiceDueAmount
                  ? invoiceSummary?.invoiceDueAmount
                  : 0),
              color: '#4CAF50'
            },
            {
              title: 'Unpaid',
              value: invoiceSummary?.invoiceDueAmount,
              color: 'rgb(230 230 230)'
            }
          ]}
          title="Overall Comparison"
          leftTitle={{
            title: 'Paid',
            value:
              currencyCode +
              getNumber(
                (invoiceSummary?.invoiceAmount || 0) -
                  (invoiceSummary?.invoiceDueAmount || 0)
              )
          }}
          rightTitle={{
            title: 'Unpaid',
            value:
              currencyCode + getNumber(invoiceSummary?.invoiceDueAmount || 0)
          }}
        />
      </div>
    );
  };
  return (
    <>
      {renderSummaryView()}
      <div className="column parent-width mb-s position-relative flex-1 pt-xl">
        <div className="row justify-content-between p-l border-radius-m border-m bg-deskera-secondary">
          <DKLabel
            text="💼 Manage Invoices here! You can view, download, print invoices."
            className=""
          />
        </div>
        <div className="column pt-xl parent-width mb-s position-relative flex-1">
          <div className="parent-width row position-relative">
            <div
              className="position-absolute z-index-3 mobile-left-0"
              style={{ right: 630, top: 45 }}
            >
              {showHideCalendar && getDateRangePicker()}
            </div>
          </div>
          <DocumentGrid
            title={'📑 My Invoices'}
            documentType={'Invoice'}
            rows={parseRowData(invoiceData?.content || [])}
            columns={getUpdatedColumns()}
            needBoldTheme={true}
            needTrailingColumn={true}
            allowSearch={true}
            onSearch={onSearch}
            allowFilter={true}
            currentPage={pagination.page}
            totalPageCount={invoiceData?.totalPages}
            onPageChange={onPageChange}
            onFilter={onFilter}
            filterData={filters?.query}
            allowRowEdit={true}
            headerButtons={getDocumentGridHeaderButtons()}
            updating={updating}
            onRowOpenClick={({ rowData }: any) => setInvoiceForPrint(rowData)}
          />
        </div>
        {showHidePrintPopup && (
          <PrintPreview
            category="INVOICE"
            document={{
              ...selectedInvoice,
              documentCode: selectedInvoice?.salesInvoiceCode,
              documentType: 'SALES_INVOICE'
            }}
            event="PRINT"
            onClose={() => {
              setShowHidePrintPopup(false);
              setSelectedInvoice(null);
            }}
          />
        )}
        {showAttachmentPopup && (
          <AttachmentPopup
            document={selectedInvoice}
            entityType="INVOICE"
            attachments={selectedInvoice?.attachmentsWithLink || []}
            onClose={() => {
              setShowHideAttachmentPopup(false);
              setSelectedInvoice(null);
            }}
            onUpload={() => {
              setShowHideAttachmentPopup(false);
              setSelectedInvoice(null);
              fetchInvoiceData();
            }}
          />
        )}
      </div>
    </>
  );
};

export default Invoices;
