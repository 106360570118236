import { DATE_FORMATS } from './Enum';

/* ************** APP MAIN CONSTANTS ************** */
export const LOGIN_COOKIE_NAME = 'x-access-token';
export const APP_SCROLL_CONTAINER_ID = 'clientPortalContainer';

export const PAGE_ROUTES = {
  HOME: '/',
  INVOICES: '/invoices',
  QUOTATIONS: '/quotations',
  SALES_ORDER: '/sales-order',
  PRODUCTS: '/products',
  PURCHASE_ORDER: '/purchase-order',
  ATTACHMENTS: '/attachments',
  PAYMENT_RECEIPTS: '/payment-receipts'
};

export const VIEWPORT_SIZES = {
  EXTRA_SMALL: 'xs',
  SMALL: 'sm',
  LARGE: 'lg'
};
export const DEPOSIT_OPTIONS: any = {
  ADVANCE_PAYMENT: 'Deposit Advpayment',
  SALES_INVOICE: 'Sales Invoice',
  DEPOSIT_ADVPAYMENT: 'DEPOSIT_ADVPAYMENT',
  GRID_ADVANCE_PAYMENT_WITH_SPACE: 'Advance Payment'
};

export const BREAKPOINTS = {
  SMALL: 560,
  LARGE: 1000
};
export const COLUMN_CODE = {
  INVOICE: {
    RowType: 'rowType',
    Number: 'documentSequenceCode',
    Code: 'salesInvoiceCode',
    CustomerOrderNo: 'customerOrderNumber',
    InvoiceDate: 'salesInvoiceDate',
    InvoiceDueDate: 'salesInvoiceDueDate',
    TotalAmount: 'totalAmount',
    PendingStatus: 'paymentStatus',
    FulfillmentStatus: 'fulfillmentStatus',
    Attachments: 'attachments',
    Contact: 'contact',
    DueAmount: 'dueAmount',
    Id: 'id',
    ApprovalStatus: 'approvalStatus',
    InvoiceNow: 'invoiceNow',
    ReservedStock: 'reservedStock',
    EwayBillNo: 'ewayBillNumber',
    Recurring: 'recurring',
    EwayBillStatus: 'ewayBillStatus',
    COGS: 'cogsAmount',
    Profit: 'profit',
    GrossMargin: 'grossMargin'
  },
  QUOTE: {
    ID: 'id',
    NUMBER: 'documentSequenceCode',
    CONTACT: 'contact',
    CONTACT_CODE: 'contactCode',
    QUOTE_DATE: 'documentDate',
    DUE_DATE: 'validTillDate',
    TOTAL_AMOUNT: 'totalAmount',
    FULFILLMENT: 'fulfillmentStatus',
    APPROVAL_STATUS: 'approvalStatus',
    CREATED_BY: 'createdBy',
    RECURRING: 'recurring',
    STATUS: 'status'
  },
  SALES_ORDER: {
    SALE_ORDER_DATE: 'salesOrderDate',
    NUMBER: 'documentSequenceCode',
    CODE: 'salesOrderCode',
    DUE_DATE: 'salesOrderDueDate',
    TOTAL_AMOUNT: 'totalAmount',
    AMOUNT_PAID: 'amountPaid',
    BALANCE_DUE: 'balanceDue',
    FULFILLMENT: 'Fulfillment',
    STATUS: 'status',
    RECURRING: 'recurring',
    COGS: 'cogsAmount',
    PROFIT: 'profit',
    GROSSMARGIN: 'grossMargin'
  },
  PRODUCT: {
    NUMBER: 'documentSequenceCode',
    STATUS: 'status',
    NAME: 'name',
    ON_GOING_QTY: 'outgoingQty',
    TYPE: 'type',
    REORDER: 'reorder',
    OUTGOING: 'outgoing',
    TRANSACTION_TYPE: 'productTransactionType',
    ATTACHMENT: 'attachments',
    DESCRIPTION: 'description'
  },
  PURCHASE_ORDER: {
    ORDER_DATE: 'documentDate',
    NUMBER: 'documentSequenceCode',
    CODE: 'purchaseOrderCode',
    DUE_DATE: 'dueDate',
    TOTAL_AMOUNT: 'totalAmount',
    RECEIPT_STATUS: 'receiptStatus',
    ORDER_TYPE: 'orderType',
    FULFILLMENT: 'Fulfillment',
    STATUS: 'status',
    RECURRING: 'recurring',
    COGS: 'cogsAmount',
    PROFIT: 'profit',
    GROSSMARGIN: 'grossMargin'
  }
};

export const APPROVAL_STATUS_LIST: any = {
  PENDING_FOR_APPROVAL: 'Pending for approval',
  PENDING: 'Pending for approval',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  NOT_REQUIRED: 'Not Required'
};

/**
 * @description - this is the mapping of the date format to the date-fns format
 */
export const DATE_FUNCTION_DATE_FORMATS: any = {
  [DATE_FORMATS['DD-MM-YYYY']]: 'dd-MM-yyyy',
  [DATE_FORMATS['DD-MMM-YYYY']]: 'dd-MMM-yyyy',
  [DATE_FORMATS['MM-DD-YYYY']]: 'MM-dd-yyyy',
  [DATE_FORMATS['YYYY-MM-DD']]: 'yyyy-MM-dd',
  [DATE_FORMATS['DD/MM/YYYY']]: 'dd/MM/yyyy',
  [DATE_FORMATS['MM/DD/YYYY']]: 'MM/dd/yyyy',
  [DATE_FORMATS['D MMM YYYY']]: 'd MMM yyyy',
  [DATE_FORMATS['ISO-FORMAT']]: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
};

/**
 * @description - Source (2021) : United Nations (list of UN member states and permanent observers plus Kosovo and Taiwan)
 */
export const COUNTRIES_WITH_CURRENCIES = [
  {
    country: 'Afghanistan',
    currencyName: 'Afghan afghani',
    currencyCode: 'AFN',
    countryCode: 'AF'
  },
  {
    country: 'Albania',
    currencyName: 'Albanian lek',
    currencyCode: 'ALL',
    countryCode: 'AL'
  },
  {
    country: 'Algeria',
    currencyName: 'Algerian dinar',
    currencyCode: 'DZD',
    countryCode: 'DZ'
  },
  {
    country: 'Andorra',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Angola',
    currencyName: 'Angolan kwanza',
    currencyCode: 'AOA',
    countryCode: 'AO'
  },
  {
    country: 'Antigua and Barbuda',
    currencyName: 'East Caribbean dollar',
    currencyCode: 'XCD',
    countryCode: 'AI'
  },
  {
    country: 'Argentina',
    currencyName: 'Argentine peso',
    currencyCode: 'ARS',
    countryCode: 'AR'
  },
  {
    country: 'Armenia',
    currencyName: 'Armenian dram',
    currencyCode: 'AMD',
    countryCode: 'AM'
  },
  {
    country: 'Australia',
    currencyName: 'Australian dollar',
    currencyCode: 'AUD',
    countryCode: 'AQ'
  },
  {
    country: 'Austria',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Azerbaijan',
    currencyName: 'Azerbaijani manat',
    currencyCode: 'AZN',
    countryCode: 'AZ'
  },
  {
    country: 'Bahamas',
    currencyName: 'Bahamian dollar',
    currencyCode: 'BSD',
    countryCode: 'BS'
  },
  {
    country: 'Bahrain',
    currencyName: 'Bahraini dinar',
    currencyCode: 'BHD',
    countryCode: 'BH'
  },
  {
    country: 'Bangladesh',
    currencyName: 'Bangladeshi taka',
    currencyCode: 'BDT',
    countryCode: 'BD'
  },
  {
    country: 'Barbados',
    currencyName: 'Barbadian dollar',
    currencyCode: 'BBD',
    countryCode: 'BB'
  },
  {
    country: 'Belgium',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Belize',
    currencyName: 'Belize dollar',
    currencyCode: 'BZD',
    countryCode: 'BZ'
  },
  {
    country: 'Benin',
    currencyName: 'West African CFA franc',
    currencyCode: 'XOF',
    countryCode: 'BJ'
  },
  {
    country: 'Bhutan',
    currencyName: 'Bhutanese ngultrum',
    currencyCode: 'BTN',
    countryCode: 'BT'
  },
  {
    country: 'Bolivia',
    currencyName: 'Bolivian boliviano',
    currencyCode: 'BOB',
    countryCode: 'BO'
  },
  {
    country: 'Bosnia and Herzegovina',
    currencyName: 'Bosnia and Herzegovina convertible mark',
    currencyCode: 'BAM',
    countryCode: 'BA'
  },
  {
    country: 'Botswana',
    currencyName: 'Botswana pula',
    currencyCode: 'BWP',
    countryCode: 'BW'
  },
  {
    country: 'Brazil',
    currencyName: 'Brazilian real',
    currencyCode: 'BRL',
    countryCode: 'BR'
  },
  {
    country: 'Brunei',
    currencyName: 'Brunei dollar',
    currencyCode: 'BND',
    countryCode: 'BN'
  },
  {
    country: 'Bulgaria',
    currencyName: 'Bulgarian lev',
    currencyCode: 'BGN',
    countryCode: 'BG'
  },
  {
    country: 'Burkina Faso',
    currencyName: 'West African CFA franc',
    currencyCode: 'XOF',
    countryCode: 'BJ'
  },
  {
    country: 'Burundi',
    currencyName: 'Burundian franc',
    currencyCode: 'BIF',
    countryCode: 'BI'
  },
  {
    country: 'Cambodia',
    currencyName: 'Cambodian riel',
    currencyCode: 'KHR',
    countryCode: 'KH'
  },
  {
    country: 'Cameroon',
    currencyName: 'Central African CFA franc',
    currencyCode: 'XAF',
    countryCode: 'CM'
  },
  {
    country: 'Canada',
    currencyName: 'Canadian dollar',
    currencyCode: 'CAD',
    countryCode: 'CA'
  },
  {
    country: 'Cape Verde',
    currencyName: 'Cape Verdean escudo',
    currencyCode: 'CVE',
    countryCode: 'CV'
  },
  {
    country: 'Central African Republic',
    currencyName: 'Central African CFA franc',
    currencyCode: 'XAF',
    countryCode: 'CM'
  },
  {
    country: 'Chad',
    currencyName: 'Central African CFA franc',
    currencyCode: 'XAF',
    countryCode: 'CM'
  },
  {
    country: 'Chile',
    currencyName: 'Chilean peso',
    currencyCode: 'CLP',
    countryCode: 'CL'
  },
  {
    country: 'China',
    currencyName: 'Chinese yuan',
    currencyCode: 'CNY',
    countryCode: 'CN'
  },
  {
    country: 'Comoros',
    currencyName: 'Comorian franc',
    currencyCode: 'KMF',
    countryCode: 'KM'
  },
  {
    country: 'Costa Rica',
    currencyName: 'Costa Rican colón',
    currencyCode: 'CRC',
    countryCode: 'CR'
  },
  {
    country: 'Croatia',
    currencyName: 'Croatian kuna',
    currencyCode: 'HRK',
    countryCode: 'HR'
  },
  {
    country: 'Cyprus',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Czech Republic',
    currencyName: 'Czech koruna',
    currencyCode: 'CZK',
    countryCode: 'CZ'
  },
  {
    country: 'Democratic Republic of Congo',
    currencyName: 'Congolese franc',
    currencyCode: 'CDF',
    countryCode: 'CD'
  },
  {
    country: 'Denmark',
    currencyName: 'Danish krone',
    currencyCode: 'DKK',
    countryCode: 'DK'
  },
  {
    country: 'Djibouti',
    currencyName: 'Djiboutian franc',
    currencyCode: 'DJF',
    countryCode: 'DJ'
  },
  {
    country: 'Dominica',
    currencyName: 'East Caribbean dollar',
    currencyCode: 'XCD',
    countryCode: 'AI'
  },
  {
    country: 'Dominican Republic',
    currencyName: 'Dominican peso',
    currencyCode: 'DOP',
    countryCode: 'DO'
  },
  {
    country: 'East Timor',
    currencyName: 'United States dollar',
    currencyCode: 'USD',
    countryCode: 'TLS'
  },
  {
    country: 'Ecuador',
    currencyName: 'United States dollar',
    currencyCode: 'USD',
    countryCode: 'EC'
  },
  {
    country: 'Egypt',
    currencyName: 'Egyptian pound',
    currencyCode: 'EGP',
    countryCode: 'EG'
  },
  {
    country: 'El Salvador',
    currencyName: 'United States dollar',
    currencyCode: 'USD',
    countryCode: 'SLV'
  },
  {
    country: 'Equatorial Guinea',
    currencyName: 'Central African CFA franc',
    currencyCode: 'XAF',
    countryCode: 'CM'
  },
  {
    country: 'Eritrea',
    currencyName: 'Eritrean nakfa',
    currencyCode: 'ERN',
    countryCode: 'ER'
  },
  {
    country: 'Estonia',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Eswatini',
    currencyName: 'Swazi lilangeni',
    currencyCode: 'SZL',
    countryCode: 'SZ'
  },
  {
    country: 'Ethiopia',
    currencyName: 'Ethiopian birr',
    currencyCode: 'ETB',
    countryCode: 'ET'
  },
  {
    country: 'Fiji',
    currencyName: 'Fijian dollar',
    currencyCode: 'FJD',
    countryCode: 'FJ'
  },
  {
    country: 'Finland',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'France',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Gabon',
    currencyName: 'Central African CFA franc',
    currencyCode: 'XAF',
    countryCode: 'CM'
  },
  {
    country: 'Gambia',
    currencyName: 'Gambian dalasi',
    currencyCode: 'GMD',
    countryCode: 'GM'
  },
  {
    country: 'Georgia',
    currencyName: 'Georgian lari',
    currencyCode: 'GEL',
    countryCode: 'GE'
  },
  {
    country: 'Germany',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Ghana',
    currencyName: 'Ghanaian cedi',
    currencyCode: 'GHS',
    countryCode: 'GH'
  },
  {
    country: 'Greece',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Grenada',
    currencyName: 'East Caribbean dollar',
    currencyCode: 'XCD',
    countryCode: 'AI'
  },
  {
    country: 'Guatemala',
    currencyName: 'Guatemalan quetzal',
    currencyCode: 'GTQ',
    countryCode: 'GT'
  },
  {
    country: 'Guinea',
    currencyName: 'Guinean franc',
    currencyCode: 'GNF',
    countryCode: 'GN'
  },
  {
    country: 'Guinea-Bissau',
    currencyName: 'West African CFA franc',
    currencyCode: 'XOF',
    countryCode: 'BJ'
  },
  {
    country: 'Guyana',
    currencyName: 'Guyanese dollar',
    currencyCode: 'GYD',
    countryCode: 'GY'
  },
  {
    country: 'Haiti',
    currencyName: 'Haitian gourde',
    currencyCode: 'HTG',
    countryCode: 'HT'
  },
  {
    country: 'Honduras',
    currencyName: 'Honduran lempira',
    currencyCode: 'HNL',
    countryCode: 'HN'
  },
  {
    country: 'Hungary',
    currencyName: 'Hungarian forint',
    currencyCode: 'HUF',
    countryCode: 'HU'
  },
  {
    country: 'Iceland',
    currencyName: 'Icelandic króna',
    currencyCode: 'ISK',
    countryCode: 'IS'
  },
  {
    country: 'India',
    currencyName: 'Indian rupee',
    currencyCode: 'INR',
    countryCode: 'IN'
  },
  {
    country: 'Indonesia',
    currencyName: 'Indonesian rupiah',
    currencyCode: 'IDR',
    countryCode: 'ID'
  },
  {
    country: 'Iran',
    currencyName: 'Iranian rial',
    currencyCode: 'IRR',
    countryCode: 'IR'
  },
  {
    country: 'Iraq',
    currencyName: 'Iraqi dinar',
    currencyCode: 'IQD',
    countryCode: 'IQ'
  },
  {
    country: 'Ireland',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Israel',
    currencyName: 'Israeli new shekel',
    currencyCode: 'ILS',
    countryCode: 'IL'
  },
  {
    country: 'Italy',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Ivory Coast',
    currencyName: 'West African CFA franc',
    currencyCode: 'XOF',
    countryCode: 'BJ'
  },
  {
    country: 'Jamaica',
    currencyName: 'Jamaican dollar',
    currencyCode: 'JMD',
    countryCode: 'JM'
  },
  {
    country: 'Japan',
    currencyName: 'Japanese yen',
    currencyCode: 'JPY',
    countryCode: 'JP'
  },
  {
    country: 'Jordan',
    currencyName: 'Jordanian dinar',
    currencyCode: 'JOD',
    countryCode: 'JO'
  },
  {
    country: 'Kazakhstan',
    currencyName: 'Kazakhstani tenge',
    currencyCode: 'KZT',
    countryCode: 'KZ'
  },
  {
    country: 'Kenya',
    currencyName: 'Kenyan shilling',
    currencyCode: 'KES',
    countryCode: 'KE'
  },
  {
    country: 'Kiribati',
    currencyName: 'Australian dollar',
    currencyCode: 'AUD',
    countryCode: 'AQ'
  },
  {
    country: 'Korea, North',
    currencyName: 'North Korean won',
    currencyCode: 'KPW',
    countryCode: 'KP'
  },
  {
    country: 'Korea, South',
    currencyName: 'South Korean won',
    currencyCode: 'KRW',
    countryCode: 'KR'
  },
  {
    country: 'Kosovo',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Kuwait',
    currencyName: 'Kuwaiti dinar',
    currencyCode: 'KWD',
    countryCode: 'KW'
  },
  {
    country: 'Kyrgyzstan',
    currencyName: 'Kyrgyzstani som',
    currencyCode: 'KGS',
    countryCode: 'KG'
  },
  {
    country: 'Laos',
    currencyName: 'Lao kip',
    currencyCode: 'LAK',
    countryCode: 'LA'
  },
  {
    country: 'Latvia',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Lebanon',
    currencyName: 'Lebanese pound',
    currencyCode: 'LBP',
    countryCode: 'LB'
  },
  {
    country: 'Lesotho',
    currencyName: 'Lesotho loti',
    currencyCode: 'LSL',
    countryCode: 'LS'
  },
  {
    country: 'Liberia',
    currencyName: 'Liberian dollar',
    currencyCode: 'LRD',
    countryCode: 'LR'
  },
  {
    country: 'Libya',
    currencyName: 'Libyan dinar',
    currencyCode: 'LYD',
    countryCode: 'LY'
  },
  {
    country: 'Liechtenstein',
    currencyName: 'Swiss franc',
    currencyCode: 'CHF',
    countryCode: 'LI'
  },
  {
    country: 'Lithuania',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Luxembourg',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Madagascar',
    currencyName: 'Malagasy ariary',
    currencyCode: 'MGA',
    countryCode: 'MG'
  },
  {
    country: 'Malawi',
    currencyName: 'Malawian kwacha',
    currencyCode: 'MWK',
    countryCode: 'MW'
  },
  {
    country: 'Malaysia',
    currencyName: 'Malaysian ringgit',
    currencyCode: 'MYR',
    countryCode: 'MY'
  },
  {
    country: 'Maldives',
    currencyName: 'Maldivian rufiyaa',
    currencyCode: 'MVR',
    countryCode: 'MV'
  },
  {
    country: 'Mali',
    currencyName: 'West African CFA franc',
    currencyCode: 'XOF',
    countryCode: 'BJ'
  },
  {
    country: 'Malta',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Marshall Islands',
    currencyName: 'United States dollar',
    currencyCode: 'USD',
    countryCode: 'MHL'
  },
  {
    country: 'Mauritania',
    currencyName: 'Mauritanian ouguiya',
    currencyCode: 'MRO',
    countryCode: 'MR'
  },
  {
    country: 'Mauritius',
    currencyName: 'Mauritian rupee',
    currencyCode: 'MUR',
    countryCode: 'MU'
  },
  {
    country: 'Mexico',
    currencyName: 'Mexican peso',
    currencyCode: 'MXN',
    countryCode: 'MX'
  },
  {
    country: 'Micronesia',
    currencyName: 'United States dollar',
    currencyCode: 'USD',
    countryCode: 'FSM'
  },
  {
    country: 'Moldova',
    currencyName: 'Moldovan leu',
    currencyCode: 'MDL',
    countryCode: 'MD'
  },
  {
    country: 'Monaco',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Mongolia',
    currencyName: 'Mongolian tögrög',
    currencyCode: 'MNT',
    countryCode: 'MN'
  },
  {
    country: 'Montenegro',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Morocco',
    currencyName: 'Moroccan dirham',
    currencyCode: 'MAD',
    countryCode: 'MA'
  },
  {
    country: 'Mozambique',
    currencyName: 'Mozambican metical',
    currencyCode: 'MZN',
    countryCode: 'MZ'
  },
  {
    country: 'Myanmar',
    currencyName: 'Burmese kyat',
    currencyCode: 'MMK',
    countryCode: 'MM'
  },
  {
    country: 'Namibia',
    currencyName: 'Namibian dollar',
    currencyCode: 'NAD',
    countryCode: 'NA'
  },
  {
    country: 'Nauru',
    currencyName: 'Australian dollar',
    currencyCode: 'AUD',
    countryCode: 'AQ'
  },
  {
    country: 'Nepal',
    currencyName: 'Nepalese rupee',
    currencyCode: 'NPR',
    countryCode: 'NP'
  },
  {
    country: 'Netherlands',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'New Zealand',
    currencyName: 'New Zealand dollar',
    currencyCode: 'NZD',
    countryCode: 'CK'
  },
  {
    country: 'Nicaragua',
    currencyName: 'Nicaraguan córdoba',
    currencyCode: 'NIO',
    countryCode: 'NI'
  },
  {
    country: 'Niger',
    currencyName: 'West African CFA franc',
    currencyCode: 'XOF',
    countryCode: 'BJ'
  },
  {
    country: 'Nigeria',
    currencyName: 'Nigerian naira',
    currencyCode: 'NGN',
    countryCode: 'NG'
  },
  {
    country: 'North Macedonia',
    currencyName: 'Macedonian denar',
    currencyCode: 'MKD',
    countryCode: 'MK'
  },
  {
    country: 'Norway',
    currencyName: 'Norwegian krone',
    currencyCode: 'NOK',
    countryCode: 'BV'
  },
  {
    country: 'Oman',
    currencyName: 'Omani rial',
    currencyCode: 'OMR',
    countryCode: 'OM'
  },
  {
    country: 'Pakistan',
    currencyName: 'Pakistani rupee',
    currencyCode: 'PKR',
    countryCode: 'PK'
  },
  {
    country: 'Palau',
    currencyName: 'United States dollar',
    currencyCode: 'USD',
    countryCode: 'PLW'
  },
  {
    country: 'Palestine',
    currencyName: 'Israeli new shekel',
    currencyCode: 'ILS',
    countryCode: 'IL'
  },
  {
    country: 'Panama',
    currencyName: 'Panamanian balboa',
    currencyCode: 'PAB',
    countryCode: 'PA'
  },
  {
    country: 'Papua New Guinea',
    currencyName: 'Papua New Guinean kina',
    currencyCode: 'PGK',
    countryCode: 'PG'
  },
  {
    country: 'Paraguay',
    currencyName: 'Paraguayan guaraní',
    currencyCode: 'PYG',
    countryCode: 'PY'
  },
  {
    country: 'Peru',
    currencyName: 'Peruvian sol',
    currencyCode: 'PEN',
    countryCode: 'PE'
  },
  {
    country: 'Philippines',
    currencyName: 'Philippine peso',
    currencyCode: 'PHP',
    countryCode: 'PH'
  },
  {
    country: 'Poland',
    currencyName: 'Polish zloty',
    currencyCode: 'PLN',
    countryCode: 'PL'
  },
  {
    country: 'Portugal',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Qatar',
    currencyName: 'Qatari riyal',
    currencyCode: 'QAR',
    countryCode: 'QA'
  },
  {
    country: 'Republic of the Congo',
    currencyName: 'Central African CFA franc',
    currencyCode: 'XAF',
    countryCode: 'CM'
  },
  {
    country: 'Romania',
    currencyName: 'Romanian leu',
    currencyCode: 'RON',
    countryCode: 'RO'
  },
  {
    country: 'Russia',
    currencyName: 'Russian ruble',
    currencyCode: 'RUB',
    countryCode: 'RU'
  },
  {
    country: 'Rwanda',
    currencyName: 'Rwandan franc',
    currencyCode: 'RWF',
    countryCode: 'RW'
  },
  {
    country: 'Saint Kitts and Nevis',
    currencyName: 'East Caribbean dollar',
    currencyCode: 'XCD',
    countryCode: 'AI'
  },
  {
    country: 'Saint Lucia',
    currencyName: 'East Caribbean dollar',
    currencyCode: 'XCD',
    countryCode: 'AI'
  },
  {
    country: 'Saint Vincent and the Grenadines',
    currencyName: 'East Caribbean dollar',
    currencyCode: 'XCD',
    countryCode: 'AI'
  },
  {
    country: 'Samoa',
    currencyName: 'Samoan tala',
    currencyCode: 'WST',
    countryCode: 'WS'
  },
  {
    country: 'San Marino',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'São Tomé and Príncipe',
    currencyName: 'São Tomé and Príncipe dobra',
    currencyCode: 'STD',
    countryCode: 'ST'
  },
  {
    country: 'Saudi Arabia',
    currencyName: 'Saudi riyal',
    currencyCode: 'SAR',
    countryCode: 'SA'
  },
  {
    country: 'Senegal',
    currencyName: 'West African CFA franc',
    currencyCode: 'XOF',
    countryCode: 'BJ'
  },
  {
    country: 'Serbia',
    currencyName: 'Serbian dinar',
    currencyCode: 'RSD',
    countryCode: 'RS'
  },
  {
    country: 'Seychelles',
    currencyName: 'Seychellois rupee',
    currencyCode: 'SCR',
    countryCode: 'SC'
  },
  {
    country: 'Sierra Leone',
    currencyName: 'Sierra Leonean leone',
    currencyCode: 'SLL',
    countryCode: 'SL'
  },
  {
    country: 'Singapore',
    currencyName: 'Singapore dollar',
    currencyCode: 'SGD',
    countryCode: 'SG'
  },
  {
    country: 'Slovakia',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Slovenia',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Solomon Islands',
    currencyName: 'Solomon Islands dollar',
    currencyCode: 'SBD',
    countryCode: 'SB'
  },
  {
    country: 'Somalia',
    currencyName: 'Somali shilling',
    currencyCode: 'SOS',
    countryCode: 'SO'
  },
  {
    country: 'South Africa',
    currencyName: 'South African rand',
    currencyCode: 'ZAR',
    countryCode: 'ZA'
  },
  {
    country: 'South Sudan',
    currencyName: 'South Sudanese pound',
    currencyCode: 'SSP',
    countryCode: 'SS'
  },
  {
    country: 'Spain',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Sri Lanka',
    currencyName: 'Sri Lankan rupee',
    currencyCode: 'LKR',
    countryCode: 'LK'
  },
  {
    country: 'Sudan',
    currencyName: 'Sudanese pound',
    currencyCode: 'SDG',
    countryCode: 'SD'
  },
  {
    country: 'Suriname',
    currencyName: 'Surinamese dollar',
    currencyCode: 'SRD',
    countryCode: 'SR'
  },
  {
    country: 'Sweden',
    currencyName: 'Swedish krona',
    currencyCode: 'SEK',
    countryCode: 'SE'
  },
  {
    country: 'Switzerland',
    currencyName: 'Swiss franc',
    currencyCode: 'CHF',
    countryCode: 'LI'
  },
  {
    country: 'Syria',
    currencyName: 'Syrian pound',
    currencyCode: 'SYP',
    countryCode: 'SY'
  },
  {
    country: 'Taiwan',
    currencyName: 'New Taiwan dollar',
    currencyCode: 'TWD',
    countryCode: 'TW'
  },
  {
    country: 'Tajikistan',
    currencyName: 'Tajikistani somoni',
    currencyCode: 'TJS',
    countryCode: 'TJ'
  },
  {
    country: 'Tanzania',
    currencyName: 'Tanzanian shilling',
    currencyCode: 'TZS',
    countryCode: 'TZ'
  },
  {
    country: 'Thailand',
    currencyName: 'Thai baht',
    currencyCode: 'THB',
    countryCode: 'TH'
  },
  {
    country: 'Togo',
    currencyName: 'West African CFA franc',
    currencyCode: 'XOF',
    countryCode: 'BJ'
  },
  {
    country: 'Tonga',
    currencyName: "Tongan pa'anga",
    currencyCode: 'TOP',
    countryCode: 'TO'
  },
  {
    country: 'Trinidad and Tobago',
    currencyName: 'Trinidad and Tobago dollar',
    currencyCode: 'TTD',
    countryCode: 'TT'
  },
  {
    country: 'Tunisia',
    currencyName: 'Tunisian dinar',
    currencyCode: 'TND',
    countryCode: 'TN'
  },
  {
    country: 'Turkey',
    currencyName: 'Turkish lira',
    currencyCode: 'TRY',
    countryCode: 'TR'
  },
  {
    country: 'Turkmenistan',
    currencyName: 'Turkmenistan manat',
    currencyCode: 'TMT',
    countryCode: 'TM'
  },
  {
    country: 'Tuvalu',
    currencyName: 'Australian dollar',
    currencyCode: 'AUD',
    countryCode: 'AQ'
  },
  {
    country: 'Uganda',
    currencyName: 'Ugandan shilling',
    currencyCode: 'UGX',
    countryCode: 'UG'
  },
  {
    country: 'Ukraine',
    currencyName: 'Ukrainian hryvnia',
    currencyCode: 'UAH',
    countryCode: 'UA'
  },
  {
    country: 'United Arab Emirates',
    currencyName: 'United Arab Emirates dirham',
    currencyCode: 'AED',
    countryCode: 'AE'
  },
  {
    country: 'United Kingdom',
    currencyName: 'British pound',
    currencyCode: 'GBP',
    countryCode: 'GG'
  },
  {
    country: 'United States of America',
    currencyName: 'United States dollar',
    currencyCode: 'USD',
    countryCode: 'US'
  },
  {
    country: 'Uruguay',
    currencyName: 'Uruguayan peso',
    currencyCode: 'UYU',
    countryCode: 'UY'
  },
  {
    country: 'Uzbekistan',
    currencyName: 'Uzbekistani som',
    currencyCode: 'UZS',
    countryCode: 'UZ'
  },
  {
    country: 'Vanuatu',
    currencyName: 'Vanuatu vatu',
    currencyCode: 'VUV',
    countryCode: 'VU'
  },
  {
    country: 'Vatican City',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Venezuela',
    currencyName: 'Venezuelan bolívar',
    currencyCode: 'VEF',
    countryCode: 'VE'
  },
  {
    country: 'Vietnam',
    currencyName: 'Vietnamese dong',
    currencyCode: 'VND',
    countryCode: 'VN'
  },
  {
    country: 'Yemen',
    currencyName: 'Yemeni rial',
    currencyCode: 'YER',
    countryCode: 'YE'
  },
  {
    country: 'Zambia',
    currencyName: 'Zambian kwacha',
    currencyCode: 'ZMW',
    countryCode: 'ZM'
  },
  {
    country: 'Zimbabwe',
    currencyName: 'United States dollar',
    currencyCode: 'USD',
    countryCode: 'ZWE'
  }
];
export const CURRENCY_SYMBOLS: any = {
  ZWD: '$',
  ZMW: 'ZK',
  ZMK: 'ZK',
  ZAR: 'R',
  YER: '﷼',
  XPF: '₣',
  XOF: 'CFA',
  XCD: '$',
  XAF: 'FCFA',
  WST: 'WS$',
  VUV: 'VT',
  VND: '₫',
  VEF: 'Bs',
  UZS: 'лв',
  UYU: '$U',
  USD: '$',
  UGX: 'USh',
  UAH: '₴',
  TZS: 'TSh',
  TWD: 'NT$',
  TTD: 'TT$',
  TRY: '₺',
  TOP: 'T$',
  TND: 'د.ت',
  TMT: 'T',
  TMM: 'T',
  TJS: 'ЅM',
  THB: '฿',
  SZL: 'E',
  SYP: '£',
  STD: 'Db',
  SSP: '£',
  SRD: '$',
  SOS: 'S',
  SLL: 'Le',
  SKK: 'Sk',
  SHP: '£',
  SGD: '$',
  SEK: 'kr',
  SDG: 'SD',
  SCR: '₨',
  SBD: 'Si$',
  SAR: '﷼',
  RWF: 'FRw',
  RUB: '₽',
  RSD: 'Дин.',
  RON: 'lei',
  QAR: '﷼',
  PYG: '₲',
  PLN: 'zł',
  PKR: '₨',
  PHP: '₱',
  PGK: 'K',
  PEN: 'S/',
  PAB: 'B/.',
  OMR: '﷼',
  NZD: '$',
  NPR: '₨',
  NOK: 'kr',
  NIO: 'C$',
  NGN: '₦',
  NAD: '$',
  MZN: 'MT',
  MYR: 'RM',
  MXN: '$',
  MWK: 'MK',
  MVR: 'Rf',
  MUR: '₨',
  MTL: '₤',
  MRO: 'UM',
  MOP: 'MOP$',
  MNT: '₮',
  MMK: 'K',
  MKD: 'ден',
  MGA: 'Ar',
  MDL: 'L',
  MAD: 'DH',
  LYD: 'ل.د',
  LVL: 'Ls',
  LTL: 'Lt',
  LSL: 'L',
  LRD: '$',
  LKR: '₨',
  LBP: '£',
  LAK: '₭',
  KZT: 'лв',
  KYD: '$',
  KWD: 'د.ك',
  KRW: '₩',
  KPW: '₩',
  KMF: 'CF',
  KHR: '៛',
  KGS: 'лв',
  KES: 'KSh,',
  JPY: '¥',
  JOD: 'د.ا',
  JMD: 'J$',
  ISK: 'kr',
  IRR: '﷼',
  IQD: 'ع.د',
  INR: '₹',
  ILS: '₪',
  IDR: 'Rp',
  HUF: 'Ft',
  HTG: 'G',
  HRK: 'kn',
  HNL: 'L',
  HKD: '$',
  GYD: 'GY$',
  GTQ: 'Q',
  GNF: 'FG',
  GMD: 'D',
  GIP: '£',
  GHS: '₵',
  GHC: 'GH₵',
  GEL: 'ლ',
  GBP: '£',
  FKP: '£',
  FJD: 'FJ$',
  EUR: '€',
  ETB: 'ብር',
  ERN: 'ናቕፋ',
  EGP: '£',
  EEK: 'EEK',
  DZD: 'دج',
  DOP: 'RD$',
  DKK: 'kr.',
  DJF: 'Fdj',
  CZK: 'Kč',
  CYP: '£',
  CVE: '$',
  CUC: '$',
  CRC: '₡',
  COP: '$',
  CNY: '¥',
  CLP: '$',
  CHF: 'CHF',
  CDF: 'FC',
  CAD: '$',
  BZD: 'BZ$',
  BYR: 'Br',
  BWP: 'P',
  BTN: 'Nu.',
  BSD: '$',
  BRL: 'R$',
  BOB: 'b$',
  BND: '$',
  BMD: '$',
  BIF: 'FBu',
  BHD: '.د.ب',
  BGN: 'лв',
  BDT: '৳',
  BBD: '$',
  BAM: 'KM',
  AZN: '₼',
  AWG: 'Afl',
  AUD: '$',
  ARS: '$',
  AOA: 'Kz',
  ANG: 'Naf',
  AMD: 'դր',
  ALL: 'Lek',
  AFN: '؋',
  AED: 'د.إ'
};

export const DEFAULT_PAGE_NO = 1;
export const DEFAULT_PAGE_SIZE = 20;

export enum FILTER_OPERATORS {
  EQUAL = 'eq',
  NOT_EQUAL = 'neq',
  GREATER_THAN = 'gt',
  GREATER_THAN_OR_EQUAL = 'gte',
  GREATER_EQUAL = 'gteq' /* only used in automation for dates */,
  LESS_THAN = 'lt',
  LESS_THAN_OR_EQUAL = 'lte',
  CONTAINS = 'c',
  IN = 'in'
}
export enum FILTER_LOGICAL_OPERATORS {
  AND = 'and',
  OR = 'or'
}

export const DOCUMENT_DESIGNER = 'Document Designer Template';
export const DOCUMENT_THERMAL_DESIGNER = 'Document Thermal Template';
export const THREE_FIVE = 'three_five';
export const APP_NAME = 'ERP';

export const DESIGNER_TEMPLATE = {
  id: 0,
  templateName: DOCUMENT_DESIGNER,
  appName: APP_NAME,
  templateData: ''
};

export const DESIGNER_THERMAL_TEMPLATE = {
  id: 'THERMAL_PRINT',
  templateName: DOCUMENT_THERMAL_DESIGNER,
  appName: APP_NAME,
  templateData: '',
  pageFormat: THREE_FIVE
};
export const FONT_SIZE = {
  xSmall: '0.7vw',
  small: '0.76vw',
  regular: '0.91vw',
  medium: '1.12vw',
  xMedium: '1.39vw',
  large: '1.67vw',
  xLarge: '2.09vw',
  xxLarge: '2.45vw',
  xxxLarge: '3.5vw'
};

export const PAGE_PADDING = '2.8vw';
export const NEGATIVE_PAGE_PADDING = '-2.8vw';
export const DEFAULT_THEME_COLOR = 'rgb(52, 73, 130)';
export const COLOR_BLUE = '#3773e1';
export const COLOR_LIGHTGRAY = '#f4f4f4';
export const COLOR_LIGHTGRAY2 = 'rgb(230,230,230)';
export const COLOR_LIGHTGRAY3 = 'rgb(80, 80, 80)';

export const COLOR_GRAY = 'rgb(174,174,174)';

export const LINE_ITEM_HEIGHT = '2.8vw';
export const LINE_ITEM_HEIGHT_2 = '1.64706vw';
export const PIXEL_TO_VW = 14.3;

export const PAGE_WIDTH = '55.45vw';
export const PAGE_HEIGHT = '78.63vw';
export const PAGE_PADDING_DOUBLE = '5.6vw';
export const PAGE_BORDER_PADDING = '2.0vw';
export const PAGE_BORDER_PADDING_HALF = '1.0vw';
export const PAGE_BORDER_PADDING_DOUBLE = '4.0vw';
export const PAGE_PRINT_RATIO = 1.7024;

export const PRINT_SCALE = 1.7;
export const PREVIEW_SCALE = 0.85;
export const EDITOR_WIDTH = 270;

export const IMG_MAX_UPLOAD_SIZE = 500 * 1024;

export const LANDING_PAGE_URL = 'https://invoice-template.deskera.com/';
export const DESKERA_URL =
  'https://www.deskera.com/?utm_source=inv_designer&utm_medium=inv_email&utm_campaign=freeinvoicetool';
export const DESKERA_GO_URL =
  'https://gous.deskera.com/?utm_source=inv_designer&utm_medium=inv_email&utm_campaign=freeinvoicetool';

export const USERDEFAULT_savedData = 'savedDocdata';
export const MOBILE_WIDTH = 800;

// templateId === 36 || templateId === 38 || templateId === 40 || templateId === 42 || templateId === 43 || templateId === 44
export const INDIA_TEMPLATES_ID = [36, 38, 40, 42, 43, 44, 45, 46, 47, 48];

export const REDUCE_SPACING_TEMPLATE_ID = [46, 47, 48, 49, 50];

export const TABLE_GRID_TEMPLATE_ID = [43, 48];

export const FONT_SIZE_PRINT = {
  xSmall: '1.19vw',
  small: '1.3vw',
  regular: '1.55vw',
  medium: '1.91vw',
  xMedium: '2.36vw',
  large: '2.84vw',
  xLarge: '3.56vw',
  xxLarge: '4.17vw',
  xxxLarge: '5.96vw'
};

export const FONT_FAMILY = [
  { label: 'Default', value: '' },
  { label: 'Arial', value: 'Arial' },
  { label: 'Arial Black', value: 'Arial Black' },
  { label: 'Arial narrow', value: 'Arial narrow' },
  { label: 'Arial Nova', value: 'Arial Nova' },
  { label: 'Calibri', value: 'Calibri' },
  // { label: 'Calibri Light', value: 'Calibri Light' },
  // { label: 'Comic Sans', value: 'Comic Sans' },
  { label: 'Comic Sans MS', value: 'Comic Sans MS' },
  // { label: 'Cursive', value: 'cursive' },
  // { label: 'Fantasy', value: 'fantasy' },
  { label: 'Helvetica', value: 'helvetica' },
  { label: 'Impact', value: 'Impact' },
  { label: 'Japanese', value: 'Japanese' },
  // { label: 'Javanese Text', value: 'Javanese Text' },
  { label: 'Korean', value: 'Korean' },
  { label: 'Lao', value: 'Lao' },
  { label: 'Monospace', value: 'monospace' },
  { label: 'Montserrat', value: 'montserrat' },
  { label: 'Sans-serif', value: 'sans-serif' },
  { label: 'Segoe UI', value: 'Segoe UI' },
  { label: 'Simplified Arabic', value: 'Simplified Arabic' },
  { label: 'Simplified Chinese', value: 'Simplified Chinese' },
  { label: 'Serif', value: 'serif' },
  { label: 'System UI', value: 'system-ui' },
  { label: 'Times', value: 'times' }
];

export const ERROR_MESSAGES = {
  EMAIL_API_ERROR:
    'Error occurred while sending email, please try again after sometime.',
  EMAIL_API_SUCCESS:
    'Email has been sent successfully, please check your inbox.'
};

// A5      2480 x 1748
// A4      3508 x 2480
// A3      4961 x 3605
// Legel   4205 x 2551
// letter  3295 x 2551

export const PAPER_FORMAT = {
  a3: {
    height: 4961,
    width: 3605,
    ratio: 1.37614424411
  },
  a4: {
    height: 3508,
    width: 2480,
    ratio: 1.41451612903
  },
  a5: {
    height: 2480,
    width: 1748,
    ratio: 1.41876430206
  },
  legal: {
    height: 3900,
    width: 2250,
    ratio: 1.73333333333
  },
  letter: {
    height: 3000,
    width: 2250,
    ratio: 1.33333333333
  },
  three_five: {
    height: 1983.33333333,
    width: 680,
    ratio: 2.916666666666667
  },
  four_six: {
    height: 1458.33333333,
    width: 500,
    ratio: 2.916666666666667
  }
};

export const INDIA_STATE_MAPPING = [
  {
    code: 'Andaman & Nicobar Islands',
    name: 'Andaman & Nicobar Islands',
    stateCode: '35'
  },
  { code: 'Andhra Pradesh', name: 'Andhra Pradesh', stateCode: '37' },
  { code: 'Arunachal Pradesh', name: 'Arunachal Pradesh', stateCode: '12' },
  { code: 'Assam', name: 'Assam', stateCode: '18' },
  { code: 'Bihar', name: 'Bihar', stateCode: '10' },
  { code: 'Chandigarh', name: 'Chandigarh', stateCode: '04' },
  { code: 'Chhattisgarh', name: 'Chhattisgarh', stateCode: '22' },
  {
    code: 'Dadra & Nagar Haveli',
    name: 'Dadra & Nagar Haveli',
    stateCode: '26'
  },
  { code: 'Daman & Diu', name: 'Daman & Diu', stateCode: '25' },
  { code: 'Delhi', name: 'Delhi', stateCode: '07' },
  { code: 'Goa', name: 'Goa', stateCode: '30' },
  { code: 'Gujarat', name: 'Gujarat', stateCode: '24' },
  { code: 'Haryana', name: 'Haryana', stateCode: '06' },
  { code: 'Himachal Pradesh', name: 'Himachal Pradesh', stateCode: '02' },
  { code: 'Jammu & Kashmir', name: 'Jammu & Kashmir', stateCode: '01' },
  { code: 'Jharkhand', name: 'Jharkhand', stateCode: '20' },
  { code: 'Karnataka', name: 'Karnataka', stateCode: '29' },
  { code: 'Kerala', name: 'Kerala', stateCode: '32' },
  { code: 'Lakshadweep', name: 'Lakshadweep', stateCode: '31' },
  { code: 'Madhya Pradesh', name: 'Madhya Pradesh', stateCode: '23' },
  { code: 'Maharashtra', name: 'Maharashtra', stateCode: '27' },
  { code: 'Manipur', name: 'Manipur', stateCode: '14' },
  { code: 'Meghalaya', name: 'Meghalaya', stateCode: '17' },
  { code: 'Mizoram', name: 'Mizoram', stateCode: '15' },
  { code: 'Nagaland', name: 'Nagaland', stateCode: '13' },
  { code: 'Odisha', name: 'Odisha', stateCode: '21' },
  { code: 'Puducherry', name: 'Puducherry', stateCode: '34' },
  { code: 'Punjab', name: 'Punjab', stateCode: '03' },
  { code: 'Rajasthan', name: 'Rajasthan', stateCode: '08' },
  { code: 'Sikkim', name: 'Sikkim', stateCode: '11' },
  { code: 'Tamil Nadu', name: 'Tamil Nadu', stateCode: '33' },
  { code: 'Telangana', name: 'Telangana', stateCode: '36' },
  { code: 'Tripura', name: 'Tripura', stateCode: '16' },
  { code: 'Uttar Pradesh', name: 'Uttar Pradesh', stateCode: '09' },
  { code: 'Uttarakhand', name: 'Uttarakhand', stateCode: '05' },
  { code: 'West Bengal', name: 'West Bengal', stateCode: '19' },
  { code: 'Ladakh', name: 'Ladakh', stateCode: '38' }
];

export const TEXT_PLACEHOLDER = [
  { label: 'none', value: '' },
  { label: 'company_name', value: 'companyName' },
  { label: 'contact_name', value: 'contactName' },
  { label: 'document_date', value: 'documentDate' },
  { label: 'document_type', value: 'documentType' },
  { label: 'current_date', value: 'currentDate' },
  { label: 'total', value: 'total' },
  { label: 'amount_in_words', value: 'amountInWords' }
];

export const CONSTANTS = {
  FIXED_ALLOWANCE: 'Fixed Allowance',
  FIXED_ALLOWANCE_TYPE: 'fixedAllowance',
  EMPTY_SPACE: ''
};

export const CURRENCY = {
  ZWD: '$',
  ZMW: 'ZK',
  ZMK: 'ZK',
  ZAR: 'R',
  YER: '﷼',
  XPF: '₣',
  XOF: 'CFA',
  XCD: '$',
  XAF: 'FCFA',
  WST: 'WS$',
  VUV: 'VT',
  VND: '₫',
  VEF: 'Bs',
  UZS: 'лв',
  UYU: '$U',
  USD: '$',
  UGX: 'USh',
  UAH: '₴',
  TZS: 'TSh',
  TWD: 'NT$',
  TTD: 'TT$',
  TRY: '₺',
  TOP: 'T$',
  TND: 'د.ت',
  TMT: 'T',
  TMM: 'T',
  TJS: 'ЅM',
  THB: '฿',
  SZL: 'E',
  SYP: '£',
  STD: 'Db',
  SSP: '£',
  SRD: '$',
  SOS: 'S',
  SLL: 'Le',
  SKK: 'Sk',
  SHP: '£',
  SGD: '$',
  SEK: 'kr',
  SDG: 'SD',
  SCR: '₨',
  SBD: 'Si$',
  SAR: '﷼',
  RWF: 'FRw',
  RUB: '₽',
  RSD: 'Дин.',
  RON: 'lei',
  QAR: '﷼',
  PYG: '₲',
  PLN: 'zł',
  PKR: '₨',
  PHP: '₱',
  PGK: 'K',
  PEN: 'S/',
  PAB: 'B/.',
  OMR: '﷼',
  NZD: '$',
  NPR: '₨',
  NOK: 'kr',
  NIO: 'C$',
  NGN: '₦',
  NAD: '$',
  MZN: 'MT',
  MYR: 'RM',
  MXN: '$',
  MWK: 'MK',
  MVR: 'Rf',
  MUR: '₨',
  MTL: '₤',
  MRO: 'UM',
  MOP: 'MOP$',
  MNT: '₮',
  MMK: 'K',
  MKD: 'ден',
  MGA: 'Ar',
  MDL: 'L',
  MAD: 'DH',
  LYD: 'ل.د',
  LVL: 'Ls',
  LTL: 'Lt',
  LSL: 'L',
  LRD: '$',
  LKR: '₨',
  LBP: '£',
  LAK: '₭',
  KZT: 'лв',
  KYD: '$',
  KWD: 'د.ك',
  KRW: '₩',
  KPW: '₩',
  KMF: 'CF',
  KHR: '៛',
  KGS: 'лв',
  KES: 'KSh,',
  JPY: '¥',
  JOD: 'د.ا',
  JMD: 'J$',
  ISK: 'kr',
  IRR: '﷼',
  IQD: 'ع.د',
  INR: '₹',
  ILS: '₪',
  IDR: 'Rp',
  HUF: 'Ft',
  HTG: 'G',
  HRK: 'kn',
  HNL: 'L',
  HKD: '$',
  GYD: 'GY$',
  GTQ: 'Q',
  GNF: 'FG',
  GMD: 'D',
  GIP: '£',
  GHS: '₵',
  GHC: 'GH₵',
  GEL: 'ლ',
  GBP: '£',
  FKP: '£',
  FJD: 'FJ$',
  EUR: '€',
  ETB: 'ብር',
  ERN: 'ናቕፋ',
  EGP: '£',
  EEK: 'EEK',
  DZD: 'دج',
  DOP: 'RD$',
  DKK: 'kr.',
  DJF: 'Fdj',
  CZK: 'Kč',
  CYP: '£',
  CVE: '$',
  CUC: '$',
  CRC: '₡',
  COP: '$',
  CNY: '¥',
  CLP: '$',
  CHF: 'CHF',
  CDF: 'FC',
  CAD: '$',
  BZD: 'BZ$',
  BYR: 'Br',
  BWP: 'P',
  BTN: 'Nu.',
  BSD: '$',
  BRL: 'R$',
  BOB: 'b$',
  BND: '$',
  BMD: '$',
  BIF: 'FBu',
  BHD: '.د.ب',
  BGN: 'лв',
  BDT: '৳',
  BBD: '$',
  BAM: 'KM',
  AZN: '₼',
  AWG: 'Afl',
  AUD: '$',
  ARS: '$',
  AOA: 'Kz',
  ANG: 'Naf',
  AMD: 'դր',
  ALL: 'Lek',
  AFN: '؋',
  AED: 'د.إ'
};

export const BARCODE_FORMAT = {
  CODE_128: 'CODE128',
  CODE_39: 'CODE39',
  EAN_8: 'EAN8',
  EAN_13: 'EAN13',
  UPC_A: 'UPC'
};

export const BARCODE_FONT_SIZE = 8;

export const COLUMN_CONFIG_FOR_IN = [
  {
    id: 'FULLY_FULFILLED',
    name: 'Delivered',
    color: 'data-grid-badge-color-6'
  },
  {
    id: 'PARTIAL_FULFILLED',
    name: 'Partially Delivered',
    color: 'data-grid-badge-color-5'
  },
  {
    id: 'UNFULFILLED',
    name: 'Not Delivered',
    color: 'data-grid-badge-color-10'
  }
];
export const DELIVERY_STATUS_COLUMN = 'Delivery Status';

export const COUNTRY_CODES = {
  US: 'US',
  IN: 'IN',
  IL: 'IL',
  ID: 'ID',
  SG: 'SG',
  PH: 'PH',
  AE: 'AE', //Used for UAE
  MY: 'MY',
  AU: 'AU',
  NL: 'NL',
  BE: 'BE',
  CA: 'CA',
  NZ: 'NZ',
  UK: 'UK',
  DE: 'DE',
  SA: 'SA'
};
export const COUNTRY_SPECIFIC_URL_CODE = {
  IN: 'in',
  IL: 'il',
  SG: 'sg',
  US: 'us',
  INDONESIA: 'id',
  MALAYSIA: 'my',
  UAE: 'ae',
  PHILIPPINES: 'ph',
  CANADA: 'ca',
  UK: 'uk',
  ISRAEL: 'il'
};
export const PRODUCT_TYPE:any = {
  TRACKED: 'Tracked',
  NONTRACKED: 'Non Tracked',
  BILL_OF_MATERIALS: 'Bill of Materials'
}
export const COUNTRIES_ALLOWED_FOR_SALES_ORDER = [
  COUNTRY_CODES.IN,
  COUNTRY_CODES.US,
  COUNTRY_CODES.SG,
  COUNTRY_CODES.IL,
  COUNTRY_CODES.MY,
  COUNTRY_CODES.UK
]