
import React from 'react';
import './css/dktable.css'
import { HeaderColumn } from './HeaderColumn';
import { ItemColumn } from './ItemColumn';
import { TableOption } from './TableOption';
import { getBottomBorderColor, getBottomBorderWidth, getFirstHeaderColumnIndex, getFontWeight, getHeaderColumnIndex, getIsExcludedFooter, getLastHeaderColumnIndex, getNameToId, getExcludedFooterCount, getSortedHeaderList, getTextAlign, getTopBorder, isLastTableRow, getBottomBorder, getRepeatableHeaderClass, getIsTotalRow, getIsNoProductGrouping, getFirstTableColumnType, getSortedHeaderType, getGroupHeaderColSpan, getReceiptFooterData, getReceiptFooterColumnSpan, getTableDataHeight, getTableFontStyleProp } from './TableUtility';
import { getLocalisedText } from "../../Translate/LanguageManager";
import Utility, { getFontSizeClass, getVW } from "../../Utilities/Utility";
import { HTMLTag } from '../../Utilities/HTMLTag';
import { DocumentSectionType } from '../../Components/Popup/HeaderFooterPopup';
import TableFooterUtility from '../../Utilities/TableFooterUtility';
interface Props {
    tableOption: TableOption
    data: any
    headerColumn: HeaderColumn[]
    isStockOrPaymentTable: Boolean
    isPaymentTable: Boolean
    documentType?: string
    onUpdateRowIndex: (rowIndex: number, action: string) => void
    onFooterRowChange?: (rowIndex: number, action: string) => void
    onUpdateTableWidth: (header: any, percentage: number) => void
    rawData: any
    groupHeaderIndexList?: number[]
    additionalTablePrefix?: any
    tableColumnOptions: any
}


interface States {
    selectedRow: number | undefined
    selectedRowType: string
    selectedColumn: number | undefined
    selectedResizeColumn: string | undefined
}

export default class DKTable extends React.Component<Props, States> {
    mousePositionX = 0
    mousePositionW = 0
    isMouseDown = false
    isMouseMove = false
    isMouseUp = false
    constructor(props: any) {
        super(props)
        this.state = {
            selectedRow: undefined,
            selectedColumn: undefined,
            selectedRowType: 'none',
            selectedResizeColumn: undefined,
        }
    }

    getDocumentTypePrefix() {
        var prefix = ''

        if(this.props.documentType !== undefined && this.props.documentType !== null) {
            prefix = this.props.documentType;
        }

        return prefix
    }

    getAdditionalTablePrefix() {
        var prefix = ''

        if(this.props.additionalTablePrefix !== undefined && this.props.additionalTablePrefix !== null) {
            prefix = this.props.additionalTablePrefix
        }

        return prefix
    }

    render() {
        if (this.isShowTable()) {
            return (
                <>
                    <table className='doc-container' id={HTMLTag.TABLE_PREFIX + this.getDocumentTypePrefix() + '_' + this.getAdditionalTablePrefix()}
                        onMouseUp={(ev) => this.onMouseUp()}
                        onMouseLeave={(ev) => this.onMouseLeave()}
                        style={{
                            borderSpacing: getVW(Utility.convertToPrintScale(2, this.props.rawData.printScale))
                        }}>
                        <thead className={getRepeatableHeaderClass()} style={{
                            borderCollapse: 'collapse',
                        }}>
                            <tr className='row-cell' id={HTMLTag.TABLE_HEADER_PREFIX  + this.getDocumentTypePrefix() + '_' + this.getAdditionalTablePrefix()}>
                                {this.renderHeader()}
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderItemData()}
                        </tbody>
                        {/* <tfoot className='break-inside-avoid'>
                            {this.renderData()}
                        </tfoot> */}
                    </table>
                    <table className='doc-container' id={HTMLTag.TABLE_PREFIX + this.getDocumentTypePrefix() + '_' + this.getAdditionalTablePrefix()}
                        style={{
                            borderSpacing: getVW(Utility.convertToPrintScale(2, this.props.rawData.printScale))
                        }}>
                        <tfoot id={HTMLTag.TABLE_FOOTER_PREFIX + this.getDocumentTypePrefix() + '_' + this.getAdditionalTablePrefix()} className='break-inside-auto'>
                            {this.renderFooterData()}
                        </tfoot>
                    </table>
                </>
            )
        }
        else {
            return (<div>
                The Table is hide by prop.isShowTable flag
            </div>)
        }
    }

    renderHeader() {
        var headerList = this.props.headerColumn

        //sort data if the index exist
        if (this.props.documentType !== undefined) {
            headerList = getSortedHeaderList(headerList, this.props.tableColumnOptions)
        }
        if (headerList === undefined || headerList === null) {
            return (<th></th>)
        }
        if (headerList.length === 0) {
            return (<th></th>)
        }
        return headerList.map((element, index: number) => {
            var isLastIndex = false
            return this.renderHeaderColumn(element, index, isLastIndex)
        });
    }

    renderHeaderColumn(header: HeaderColumn, index: number, isLastIndex: boolean) {
        if (header.columnOption.isShowColumn) {
            return (
                <th
                    key={'th_' + this.getAdditionalTablePrefix() + '_' + index.toString()}
                    className='cell'
                    id={this.getHeaderId(header)}
                    style={{
                        width: header.style.width,
                        height: header.style.height,
                        background: header.style.backgroundColor,
                        textAlign: getTextAlign(header.style.alignment),
                        textOverflow: 'clip ellipsis',
                        borderWidth: this.isShowBorder() ? header.style.borderWidth : undefined,
                        borderColor: this.isShowBorder() ? header.style.borderColor : undefined,
                        borderStyle: this.isShowBorder() ? header.style.borderStyle : undefined,
                        borderBottomColor: this.isShowBorder() ? getBottomBorderColor(header.style) : undefined,
                        borderBottomWidth: this.isShowBorder() ? getBottomBorderWidth(header.style) : undefined,
                        borderCollapse: 'collapse',
                        paddingTop: header.style.paddingTop,
                        paddingBottom: header.style.paddingBottom,
                        paddingLeft: header.style.paddingLeft,
                        paddingRight: header.style.paddingRight,
                        marginTop: header.style.marginTop,
                        marginBottom: header.style.marginBottom,
                        marginLeft: header.style.marginLeft,
                        marginRight: header.style.marginRight,
                        fontWeight: getFontWeight(header.style.fontWeight),
                        verticalAlign: header.style.verticalAlignment,
                        // ...Utility.getFontStyleProp({ fontSize: header.style.fontSize})
                        ...getTableFontStyleProp({ fontSize: header.style.fontSize }, this.props.rawData.printScale)
                    }}>
                    <div key={'div_' + this.getAdditionalTablePrefix() + '_' + index.toString()} style={{
                        position: 'relative',
                        textAlign: getTextAlign(header.style.alignment),
                    }}>
                        <text key={'text_' + this.getAdditionalTablePrefix() + '_' + index.toString()} className={"docFontStyleBold" + getFontSizeClass()} style={{
                            fontStyle: header.style.fontStyle,
                            paddingLeft: this.props.isStockOrPaymentTable ? header.style.paddingLeft : 0,
                            // ...Utility.getFontStyleProp({ fontSize: header.style.fontSize, color: header.style.fontColor }),
                            ...getTableFontStyleProp({ fontSize: header.style.fontSize, color: header.style.fontColor }, this.props.rawData.printScale)
                        }}>{getLocalisedText(header.label)}</text>
                        {!isLastIndex &&
                            <div
                                key={'resize_div_' + this.getAdditionalTablePrefix() + '_' + index.toString()}
                                className='column-resize'
                                style={{
                                    height: this.getRowHeaderHeight(),
                                    width: this.getResizeColumnWidth(header),
                                    marginRight: header.style.paddingRight ? '-' + header.style.paddingRight : undefined,
                                    marginTop: header.style.paddingTop ? '-' + header.style.paddingTop : undefined,
                                }}
                                onMouseDown={(ev) => this.onMouseDown(ev, header)}
                                onMouseUp={(ev) => this.onMouseUp()}
                                onMouseMove={(ev) => this.onMouseMove(ev, header)}
                                onMouseLeave={(ev) => this.onMouseLeave()}
                            >
                            </div>
                        }
                    </div>
                </th>
            )
        }
    }

    getHeaderId(header: HeaderColumn) {
        if (header.code !== undefined) {
            return HTMLTag.TABLE_HEADER_COLUMN_PREFIX + this.getAdditionalTablePrefix() + '_' + getNameToId(header.name) + '_' + header.code
        }
        return HTMLTag.TABLE_HEADER_COLUMN_PREFIX + this.getAdditionalTablePrefix() + '_' + getNameToId(header.name)
    }
    getRowHeaderHeight() {
        var height = '100%'
        if (this.props.documentType === undefined) {
            return height
        }

        var headerElement = document.getElementById('row_header_' + this.getDocumentTypePrefix() + '_' + this.getAdditionalTablePrefix())
        if (headerElement !== undefined && headerElement !== null) {
            var styles = window.getComputedStyle(headerElement)
            if (styles.height !== undefined) {
                height = styles.height
            }
        }

        return height
    }

    getResizeColumnWidth(header: HeaderColumn) {
        var width = '50%'
        if (header.style.width !== undefined && header.style.width !== null) {
            var headerWidth = parseFloat(header.style.width.replace('%', ''))
            if (headerWidth < 7) {
                width = '100%'
            }
        }
        return width
    }
    onMouseDown(ev: any, header: HeaderColumn) {
        if (this.state.selectedResizeColumn === undefined) {
            this.mousePositionX = ev.clientX;
            this.isMouseDown = true
            this.isMouseMove = true
            this.isMouseUp = true

            var col = document.getElementById(this.getHeaderId(header))

            if (col !== undefined && col !== null) {
                const styles = window.getComputedStyle(col);
                this.mousePositionW = parseInt(styles.width, 10);
            }
            this.setState({ selectedResizeColumn: this.getHeaderId(header) })
        }
        // // Calculate the current width of column
        // var headerElement = document.getElementById('header_' + getNameToId(type))
        // if(headerElement !== undefined && headerElement !== null) {
        //     const styles = window.getComputedStyle(headerElement);
        //     this.mousePositionW = parseInt(styles.width, 10);
        // }

    }

    onMouseLeave() {
        this.onResetResize()
    }

    onMouseUp() {
        this.onResetResize()
    }

    onResetResize() {
        this.mousePositionX = 0
        this.mousePositionW = 0
        this.isMouseDown = false
        this.isMouseUp = false
        this.isMouseMove = false

        this.setState({ selectedResizeColumn: undefined })
    }

    onMouseMove(ev: any, header: HeaderColumn) {
        if (this.props.documentType === undefined) {
            return
        }

        if (!this.isMouseMove) {
            return
        }

        if (this.state.selectedResizeColumn === undefined) {
            return
        }

        var col = document.getElementById(this.getHeaderId(header))
        if (col !== undefined && col !== null) {
            var styles = window.getComputedStyle(col);
            var dx = (ev.clientX - this.mousePositionX) * 10
            var movePixel = 0
            if (styles.width !== undefined) {
                var rowWidth = Number(styles.width.replace('px', ''))
                movePixel = rowWidth + dx
                if (rowWidth !== movePixel) {
                    var percent = (movePixel - rowWidth) / rowWidth
                    this.props.onUpdateTableWidth(header, percent)
                }
            }
        }
    }
    renderData(data: any, dataType: string) {
        var records = data
        if (records === undefined || records === null) {
            return (<tr></tr>)
        }
        if (records.length === 0) {
            return (<tr></tr>)
        }
        else if (records.length > 0) {
            var columnData = records.map((element: string | ItemColumn, index: number) => {
                return this.renderColumnRow(data, element, index, dataType)
            });
            return columnData
        }
        else {
            return (<tr></tr>)
        }
    }

    renderFooterData() {
        var footerData = this.getTableData(true)
        if (Utility.getIsReceiptPaperSize()) {
            footerData = getReceiptFooterData(footerData, this.props.headerColumn, this.props.documentType)
        }
        return this.renderData(footerData, DocumentSectionType.footer)
    }

    renderItemData() {
        var footerData = this.getTableData(false)
        return this.renderData(footerData, DocumentSectionType.data)
    }
    renderColumnRow(data: any, rowData: any, rowIndex: number, dataType: string) {
        if (rowData === undefined || rowData === null) {
            return
        }
        if (rowData.length === 0) {
            return
        }

        var rowRecords = rowData.map((element: string | ItemColumn, index: number) => {
            return this.renderColumnItem(data, element, index, rowIndex, dataType)
        });

        //to get the group header
        rowRecords = this.getGroupHeader(dataType, rowRecords, rowData, rowIndex, data);

        return (<tr
            key={'tr_' + rowIndex.toString()}
            className='row-cell'
            style={{
                height: getTableDataHeight()
            }}
            id={HTMLTag.TABLE_DATA_ROW_INDEX_PREFIX + this.getDocumentTypePrefix() + '_' + this.getAdditionalTablePrefix() + '_' + dataType + '_' + rowIndex}
            onMouseEnter={() => this.setState({ selectedRow: rowIndex, selectedRowType: dataType })}
            onMouseLeave={() => this.setState({ selectedRow: undefined, selectedRowType: 'none' })}
        >
            {rowRecords}
        </tr>)
    }

    private getGroupHeader(dataType: string, rowRecords: any, rowData: any, rowIndex: number, data: any) {
        var groupHeaderIndexList = this.props.groupHeaderIndexList ? this.props.groupHeaderIndexList : [];
        var footerList = TableFooterUtility.getFooterListByType('product')
        if (dataType === DocumentSectionType.data && !getIsNoProductGrouping()) {
            rowRecords = rowData.map((element: ItemColumn, index: number) => {
                if (groupHeaderIndexList.includes(rowIndex)) {
                    var firstColumnType = getFirstTableColumnType(footerList, this.props.documentType);
                    if (firstColumnType !== undefined && firstColumnType !== null) {
                        var headerIndex = getSortedHeaderType(this.props.tableColumnOptions).indexOf(firstColumnType);
                        var newElement = element;
                        newElement.isSkipColumn = index > headerIndex;
                        if (headerIndex === index) {
                            newElement.isGroupHeaderColumn = true
                        }
                        return this.renderColumnItem(data, newElement, index, rowIndex, dataType);
                    }
                }
                return this.renderColumnItem(data, element, index, rowIndex, dataType);
            });
        }
        return rowRecords;
    }

    renderColumnItem(data: any, item: string | ItemColumn, columnIndex: number, rowIndex: number, dataType: string) {
        if (this.props.headerColumn === undefined || this.props.headerColumn === null) {
            return
        }
        var headerDefinition = this.props.headerColumn[columnIndex]

        if (item === undefined) {
            return (<div></div>)
        }

        var itemColumnValue = item as ItemColumn
        if (itemColumnValue.name !== undefined) {
            if (this.isUseHeaderStyle()) {
                return this.renderPlainColumn(data, itemColumnValue.value, headerDefinition, rowIndex, columnIndex, this.isShowSortButton(dataType, data, rowIndex, columnIndex, itemColumnValue), dataType, itemColumnValue.name)
            }
            else {
                return this.renderCustomStyleColumn(data, itemColumnValue, headerDefinition, rowIndex, columnIndex, this.isShowSortButton(dataType, data, rowIndex, columnIndex, itemColumnValue), dataType)
            }
        }

        var stringValue = item as string
        if (stringValue !== undefined) {
            return this.renderPlainColumn(data, stringValue, headerDefinition, rowIndex, columnIndex, this.isShowSortButton(dataType, data, rowIndex, columnIndex, undefined), dataType)
        }

        return (<div></div>)
    }

    renderPlainColumn(data: any, value: string, header: HeaderColumn, rowIndex: number, columnIndex: number, isShowArrowDiv: boolean, dataType: string, name?: string) {
        var headerColumn = this.getHeaderColumn(data, rowIndex)

        if (header === undefined || header === null) {
            return (<td></td>)
        }
        if (header.columnOption.isShowColumn) {
            var id = name === undefined ? getNameToId(header.label + '_value') : getNameToId(name)
            return (
                <td
                    key={'td_' + rowIndex.toString() + '_' + columnIndex.toString()}
                    className='cell'
                    id={HTMLTag.TABLE_DATA_COLUMN_INDEX_PREFIX + id}
                    colSpan={this.getColSpan(dataType, value, false)}
                    onMouseEnter={() => this.setState({ selectedColumn: columnIndex })}
                    onMouseLeave={() => this.setState({ selectedColumn: undefined })}
                    style={{
                        width: header.style.width,
                        height: header.style.height,
                        background: headerColumn ? headerColumn.style.backgroundColor : header.style.backgroundColor,
                        textOverflow: 'clip ellipsis',
                        borderWidth: this.isShowBorder() ? (headerColumn ? headerColumn.style.borderWidth : header.style.borderWidth) : undefined,
                        borderColor: this.isShowBorder() ? (headerColumn ? headerColumn.style.borderColor : header.style.borderColor) : undefined,
                        borderStyle: this.isShowBorder() ? (headerColumn ? headerColumn.style.borderStyle : header.style.borderStyle) : undefined,
                        borderTop: this.isShowBorder() ? (headerColumn ? getTopBorder(headerColumn.style, isLastTableRow(rowIndex, data, dataType)) : undefined) : undefined,
                        borderBottom: this.isShowBorder() ? (headerColumn ? getBottomBorder(headerColumn.style, isLastTableRow(rowIndex, data, dataType)) : undefined) : undefined,
                        borderCollapse: 'collapse',
                        paddingTop: header.style.paddingTop,
                        paddingBottom: header.style.paddingBottom,
                        paddingLeft: header.style.paddingLeft,
                        paddingRight: header.style.paddingRight,
                        marginTop: header.style.marginTop,
                        marginBottom: header.style.marginBottom,
                        marginLeft: header.style.marginLeft,
                        marginRight: header.style.marginRight,
                        verticalAlign: header.style.verticalAlignment,
                    }}>
                    <div style={{
                        display: isShowArrowDiv ? 'flex' : 'block',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        // textAlign: getTextAlign(header.style.alignment),
                    }}>
                        <div className={"docFontStyle" + getFontSizeClass()} style={{
                            textAlign: dataType === 'footer' ? getTextAlign(header.style.alignment) : headerColumn ? getTextAlign(headerColumn.style.alignment) : getTextAlign(header.style.alignment),
                            fontStyle: headerColumn ? headerColumn.style.fontStyle : header.style.fontStyle,
                            fontWeight: headerColumn ? getFontWeight(headerColumn.style.fontWeight) : getFontWeight(header.style.fontWeight),
                            direction: Utility.getIsSaudiArabiaCountry(this.props.rawData) ? 'rtl' : 'ltr',
                            // ...Utility.getFontStyleProp({ fontSize: headerColumn ? headerColumn.style.fontSize : header.style.fontSize,
                            //     color: headerColumn ? headerColumn.style.fontColor : header.style.fontColor
                            // }),
                            ...getTableFontStyleProp({
                                fontSize: headerColumn ? headerColumn.style.fontSize : header.style.fontSize,
                                color: headerColumn ? headerColumn.style.fontColor : header.style.fontColor
                            }, this.props.rawData.printScale)
                        }}>{value}</div>
                        {isShowArrowDiv && this.renderArrowButtonBox(data, rowIndex)}
                    </div>
                </td>
            )
        }
    }

    getHeaderColumn(data: any, rowIndex: number): ItemColumn | undefined {
        if (data[rowIndex] !== undefined) {
            var columnList: any[] = []
            data[rowIndex].forEach((element: ItemColumn | string) => {
                if (typeof element === 'object') {
                    if (element.isHeaderColumn) {
                        columnList.push(element)
                    }
                }
            });
            if (columnList.length > 0) {
                return columnList[0]
            }
        }
        return undefined
    }
    getLastHeaderDataColumnIndex(): number {
        var endIndex: number = 0
        this.props.headerColumn.forEach((element: HeaderColumn, index: number) => {
            if (element.columnOption.isShowColumn !== undefined && index > endIndex) {
                endIndex = index
            }
        });
        return endIndex
    }
    renderCustomStyleColumn(data: any, element: ItemColumn, header: HeaderColumn, rowIndex: number, columnIndex: number, isShowArrowDiv: boolean, dataType: string) {
        if (header === undefined || header === null) {
            return (<td></td>)
        }
        if (header.columnOption.isShowColumn && !element.isSkipColumn) {
            return (
                <td
                    key={'td_' + rowIndex.toString() + '_' + columnIndex.toString()}
                    className='cell'
                    id={HTMLTag.TABLE_DATA_COLUMN_INDEX_PREFIX + getNameToId(element.name)}
                    onMouseEnter={() => this.setState({ selectedColumn: columnIndex })}
                    onMouseLeave={() => this.setState({ selectedColumn: undefined })}
                    colSpan={this.getColSpan(dataType, element, true)}
                    style={{
                        width: element.columnOption.isUsingHeaderWidth ? header.style.width : element.style.width,
                        height: element.style.height,
                        background: element.style.backgroundColor,
                        // textOverflow: 'clip ellipsis',
                        borderWidth: this.isShowBorder() ? element.style.borderWidth : undefined,
                        borderColor: this.isShowBorder() ? element.style.borderColor : undefined,
                        borderStyle: this.isShowBorder() ? element.style.borderStyle : undefined,
                        borderTop: this.isShowBorder() ? getTopBorder(element.style, isLastTableRow(rowIndex, data, dataType)) : undefined,
                        borderBottom: this.isShowBorder() ? getBottomBorder(element.style, isLastTableRow(rowIndex, data, dataType)) : undefined,
                        borderCollapse: 'collapse',
                        paddingTop: element.style.paddingTop,
                        paddingBottom: element.style.paddingBottom,
                        paddingLeft: element.style.paddingLeft,
                        paddingRight: element.style.paddingRight,
                        marginTop: element.style.marginTop,
                        marginBottom: element.style.marginBottom,
                        marginLeft: element.style.marginLeft,
                        marginRight: element.style.marginRight,
                        verticalAlign: header.style.verticalAlignment,
                    }}>
                    <div key={'td_div_' + rowIndex.toString() + '_' + columnIndex.toString()} style={{
                        display: isShowArrowDiv ? 'flex' : 'block',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        // textAlign: element.columnOption.isUsingHeaderAlignment ? getTextAlign(header.style.alignment) : getTextAlign(element.style.alignment),
                    }}>
                        {this.renderCellContainer(element, header, columnIndex, dataType)}

                        {isShowArrowDiv && this.props.data.length > 1 && this.renderArrowButtonBox(data, rowIndex)}
                    </div>
                </td>
            )
        }
    }

    getColSpan(dataType: string, element: ItemColumn | string, isGroupHeaderColumn: boolean) {
        var colSpan = undefined
        if (isGroupHeaderColumn) {
            if (typeof element === 'object') {
                colSpan = getGroupHeaderColSpan(element.isGroupHeaderColumn, this.props.tableColumnOptions)
                if (Utility.getIsReceiptPaperSize() && dataType === DocumentSectionType.footer) {
                    colSpan = getReceiptFooterColumnSpan().left
                }
            }
        }
        else {
            if (Utility.getIsReceiptPaperSize() && dataType === DocumentSectionType.footer) {
                colSpan = getReceiptFooterColumnSpan().right
            }
        }
        return colSpan
    }

    renderCellContainer(element: ItemColumn, header: HeaderColumn, columnIndex: number, dataType: string) {
        if (this.props.isPaymentTable) {
            return <div className="ColumnDiv " style={{
                textAlign: element.columnOption.isUsingHeaderAlignment ? getTextAlign(header.style.alignment) : getTextAlign(element.style.alignment),
                padding: 10,
                // ...Utility.getFontStyleProp({
                //     fontSize: getVW(12),
                //     fontStyle: element.style.fontStyle,
                //     fontWeight: getFontWeight(element.style.fontWeight),
                //     color: element.style.fontColor
                // }),
                ...getTableFontStyleProp({
                    fontSize: element.style.fontSize,
                    fontStyle: element.style.fontStyle,
                    fontWeight: getFontWeight(element.style.fontWeight),
                    color: element.style.fontColor
                }, this.props.rawData.printScale),
            }}>
                <a className='nav-link' target="_blank" rel="noopener noreferrer" href={element.value}>{getLocalisedText('click_here_to_pay')}</a>
            </div>
        }
        else if (header.name === 'image' && element.value !== undefined && element.value !== null && element.value !== '') {
            return <div>
                <img
                    src={element.value}
                    alt=""
                    style={{
                        cursor: "pointer",
                        width: '100%',
                        padding: getVW(2),
                    }}
                    onClick={() => { }}
                />
            </div>
        }

        return <div className={"docFontStyle" + getFontSizeClass()}
            dangerouslySetInnerHTML={{ __html: element.value }}
            style={{
                textAlign: this.getTextAlign(element, header, dataType, columnIndex),
                fontStyle: element.style.fontStyle,
                fontWeight: getFontWeight(element.style.fontWeight),
                paddingLeft: this.props.isStockOrPaymentTable ? element.style.paddingLeft : 0,
                direction: Utility.getIsSaudiArabiaCountry(this.props.rawData) ? 'rtl' : 'ltr',
                // ...Utility.getFontStyleProp({ fontSize: element.style.fontSize, color: element.style.fontColor }),
                ...getTableFontStyleProp({ fontSize: element.style.fontSize, color: element.style.fontColor }, this.props.rawData.printScale),
            }} />
    }

    getTextAlign(element: any, header: any, dataType: string, columnIndex: number) {
        if (element.isGroupHeaderColumn) {
            return 'left'
        }
        if (dataType === DocumentSectionType.footer && Utility.getIsReceiptPaperSize()) {
            if (columnIndex === 0) {
                return 'left'
            }
        }
        if (element.columnOption.isUsingHeaderAlignment) {
            return getTextAlign(header.style.alignment)
        }

        return getTextAlign(element.style.alignment)
    }

    renderArrowButtonBox(data: any, rowIndex: number) {
        var headerColumnIndex = getHeaderColumnIndex(data, rowIndex)
        var offsetArraySize = 1
        var currentIndex = rowIndex
        if (headerColumnIndex !== undefined) {
            var firstHeaderColumn = getFirstHeaderColumnIndex(data)
            if (firstHeaderColumn !== undefined) {
                currentIndex = rowIndex - firstHeaderColumn
                offsetArraySize = 2 + getExcludedFooterCount(data)
            }
        }
        else {
            if (getIsTotalRow(data) > 0) {
                offsetArraySize = 2
            }
        }
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
            }}>
                {currentIndex > 0 &&
                    <img
                        className='arrow-button'
                        src={require('../../Assets/ic_upArrow.png')}
                        alt={''}
                        onClick={() => {
                            if (headerColumnIndex !== undefined) {
                                if (this.props.onFooterRowChange !== undefined) {
                                    this.props.onFooterRowChange(currentIndex, 'up')
                                }
                            }
                            else {
                                this.props.onUpdateRowIndex(currentIndex, 'up')
                            }
                        }
                        }
                    />
                }
                {currentIndex < data.length - offsetArraySize &&
                    <img
                        className='arrow-button'
                        src={require('../../Assets/ic_downArrow.png')}
                        alt={''}
                        onClick={() => {
                            if (headerColumnIndex !== undefined) {
                                if (this.props.onFooterRowChange !== undefined) {
                                    this.props.onFooterRowChange(currentIndex, 'down')
                                }
                            }
                            else {
                                this.props.onUpdateRowIndex(currentIndex, 'down')
                            }
                        }
                        }
                    />
                }
            </div>
        )
    }

    getTableData(isHeaderColumn: boolean): any[] {
        var data: any[] = []
        this.props.data.forEach((element: any, index: number) => {
            if (isHeaderColumn) {
                if (this.getHeaderColumn(this.props.data, index) !== undefined) {
                    data.push(element)
                }
            }
            else {
                if (this.getHeaderColumn(this.props.data, index) === undefined) {
                    if (element.length > 0) {
                        // if(!element[0].isTotalColumn) {
                        data.push(element)
                        // }
                    }
                }
            }
        });
        return data
    }

    isShowTable() {
        return this.props.tableOption.isShowTable ? this.props.tableOption.isShowTable : false
    }
    isShowHeader() {
        return this.props.tableOption.isShowHeader ? this.props.tableOption.isShowHeader : true
    }

    isUseHeaderStyle() {
        return this.props.tableOption.isUseHeaderStyle
    }

    isShowBorder() {
        return this.props.tableOption.isShowBorder ? this.props.tableOption.isShowBorder : false
    }

    isShowSortButton(dataType: string, data: any, rowIndex: number, columnIndex: number, item: ItemColumn | undefined) {
        if (this.state.selectedRow === undefined) {
            return false
        }

        var firstSortColumn = this.getFirstColumnIndex()
        var headerColumnIndex = getHeaderColumnIndex(data, rowIndex)
        if (headerColumnIndex !== undefined) {
            firstSortColumn = headerColumnIndex
            var lastHeaderColumnIndex = getLastHeaderColumnIndex(data) - getExcludedFooterCount(data)
            if (getIsExcludedFooter(data, rowIndex)) {
                return false
            }
            else if (lastHeaderColumnIndex > 0) {
                if (rowIndex === lastHeaderColumnIndex) {
                    return false
                }
            }
        }

        if (dataType === DocumentSectionType.data && !getIsNoProductGrouping()) {
            return false
        }
        if (this.state.selectedColumn !== undefined) {
            if (this.state.selectedColumn < firstSortColumn) {
                return false
            }
        }

        if (item !== undefined) {
            if (item.isTotalColumn) {
                return false
            }
        }

        if (this.props.tableOption.isShowSortButton) {
            if (columnIndex === firstSortColumn) {
                if (rowIndex === this.state.selectedRow && this.state.selectedRowType === dataType) {
                    return true
                }
                else {
                    return false;
                }
            }
            else {
                return false
            }
        }
        else {
            return false
        }
    }

    getFirstColumnIndex() {
        var firstColumn = 0
        var found = false
        this.props.headerColumn.forEach(element => {
            if (!element.columnOption.isShowColumn && !found) {
                firstColumn = firstColumn + 1
            }
            else {
                found = true
            }
        });

        return firstColumn
    }
}
