import { TableColumnPopupType } from "../Components/Popup/TableColumnPopup";
import TemplateSettingsManager from "../Manager/TemplateSettingsManager";
import { getIsAccountModule, getIsExpenseBill, getIsFulfillment, getIsGoodsReceipt, getIsJournalEntry, getIsMakePayment, getIsPackList, getIsPickList, getIsReceivePayment } from "../Utilities/Utility";

export const TableColumnAlignmentType = {
    center: 'center',
    left: 'left',
    right: "right",
    top: 'top',
    bottom: 'bottom',
    default: 'default',
    middle: 'middle',
}

export default class TableColumnAlignmentParser {

    static getTableColumnVerticalAlignment() {
        var tableColumnVerticalAlignmentList = []
        var style = TemplateSettingsManager.defaultTemplateSettings.templateInfo.style

        if (style !== undefined && style !== null) {
            if (style.table !== undefined && style !== null) {
                if (style.table.tableColumnVerticalAlignmentList !== undefined && style.table.tableColumnVerticalAlignmentList !== null) {
                    tableColumnVerticalAlignmentList = style.table.tableColumnVerticalAlignmentList

                    if (tableColumnVerticalAlignmentList.length > 0) {

                        tableColumnVerticalAlignmentList.forEach(element => {
                            if(element.alignment === TableColumnAlignmentType.default) {
                                element.alignment = undefined
                            }
                        });

                    }
                }
            }
        }

        return tableColumnVerticalAlignmentList
    }

    static getTableColumnAlignment() {
        var tableColumnAlignmentList = []
        var style = TemplateSettingsManager.defaultTemplateSettings.templateInfo.style

        if(style !== undefined && style !== null) {
            if (style.table !== undefined && style !== null) {
                if (style.table.tableColumnAlignmentList !== undefined && style.table.tableColumnAlignmentList !== null) {
                    tableColumnAlignmentList = style.table.tableColumnAlignmentList
                }
            }
        }

        return tableColumnAlignmentList
    }

    static getDefaultTableColumnAlignmentListbyDocument(documentData) {
        var tableColumnAlignmentList = this.getDefaultAlignmentForProduct()
        if (getIsAccountModule(undefined, documentData.type)) {
            tableColumnAlignmentList = this.getDefaultAlignmentForAccount()
        }
        else if (getIsExpenseBill(documentData.type, documentData.lineItems)) {
            tableColumnAlignmentList = this.getDefaultAlignmentForAccount()
        }
        else if (getIsMakePayment(documentData.type) || getIsReceivePayment(documentData.type)) {
            tableColumnAlignmentList = this.getDefaultAlignmentForPayment()
        }
        else if (getIsJournalEntry(documentData.type)) {
            tableColumnAlignmentList = this.getDefaultAlignmentForJournalEntry()
        }
        else if (getIsFulfillment(documentData.type)) {
            tableColumnAlignmentList = this.getDefaultAlignmentForFulfilment()
        }
        else if (getIsGoodsReceipt(documentData.type)) {
            tableColumnAlignmentList = this.getDefaultAlignmentForGoodsReceipt()
        }
        else if (getIsPackList(documentData.type)) {
            tableColumnAlignmentList = this.getDefaultAlignmentForPackingList()
        }
        else if (getIsPickList(documentData.type)) {
            tableColumnAlignmentList = this.getDefaultAlignmentForPickingList()
        }
        return tableColumnAlignmentList
    }

    static getDefaultAlignmentForProduct() {
        return [
            { type: TableColumnPopupType.lineNumber, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.code, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.productName, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.description, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.productNameWithDescription, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.quantity, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.uom, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.quantityWithUom, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.serialBatch, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.serialNumber, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.batchNumber, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.manufacturingDate, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.expiryDate, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.unitPrice, alignment: TableColumnAlignmentType.right },
            { type: TableColumnPopupType.amount, alignment: TableColumnAlignmentType.right },
            { type: TableColumnPopupType.productTax, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.productDiscount, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.productCustomField, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.hsn, alignment: TableColumnAlignmentType.center },
        ]
    }

    static getDefaultAlignmentForAccount() {
        return [
            { type: TableColumnPopupType.accountName, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.description, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.amount, alignment: TableColumnAlignmentType.right },
            { type: TableColumnPopupType.tax, alignment: TableColumnAlignmentType.right },
            { type: TableColumnPopupType.taxAmount, alignment: TableColumnAlignmentType.right },
            { type: TableColumnPopupType.paymentAmount, alignment: TableColumnAlignmentType.right },
        ]
    }

    static getDefaultAlignmentForFulfilment() {
        return [
            { type: TableColumnPopupType.code, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.productName, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.description, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.uom, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.serialBatch, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.serialNumber, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.batchNumber, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.manufacturingDate, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.expiryDate, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.requiredQuantity, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.committedQuantity, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.warehouseCode, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.warehouseName, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.productCustomField, alignment: TableColumnAlignmentType.center },
        ]
    }

    static getDefaultAlignmentForGoodsReceipt() {
        return [
            { type: TableColumnPopupType.code, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.productName, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.description, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.uom, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.requiredQuantity, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.receivedQuantity, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.warehouseCode, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.warehouseName, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.productCustomField, alignment: TableColumnAlignmentType.center },
        ]
    }

    static getDefaultAlignmentForJournalEntry() {
        return [
            { type: TableColumnPopupType.date, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.journalEntryNumber, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.accountCode, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.accountName, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.description, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.credit, alignment: TableColumnAlignmentType.right },
            { type: TableColumnPopupType.debit, alignment: TableColumnAlignmentType.right },
            { type: TableColumnPopupType.productCustomField, alignment: TableColumnAlignmentType.center },
        ]
    }

    static getDefaultAlignmentForPackingList() {
        return [
            { type: TableColumnPopupType.id, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.code, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.productName, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.barcode, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.unit, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.packedQuantity, alignment: TableColumnAlignmentType.center },
        ]
    }

    static getDefaultAlignmentForPayment() {
        return [
            { type: TableColumnPopupType.snNumber, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.invoiceNumber, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.billNumber, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.paymentMedium, alignment: TableColumnAlignmentType.right },
            { type: TableColumnPopupType.paymentMade, alignment: TableColumnAlignmentType.right },
        ]
    }

    static getDefaultAlignmentForPickingList() {
        return [
            { type: TableColumnPopupType.invoiceOrQuotation, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.code, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.productName, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.barcode, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.quantityToPick, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.quantityRequiredForOrder, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.picked, alignment: TableColumnAlignmentType.center },
        ]
    }
}