import TemplateSettingsManager from "../../Manager/TemplateSettingsManager"
import LineItemsParser from "../LineItemsParser"
import TemplateParser from "./TemplateParser"

export default class ChequeParser {
    static parseData(dataFromAPI, templateDataToReturn) {
        //line items
        if (TemplateSettingsManager.getRemoteTemplateUID() === undefined || TemplateSettingsManager.getRemoteTemplateUID() === null) {
            //getDefault date format
            if (dataFromAPI.chequePayload !== undefined && dataFromAPI.chequePayload !== null) {
                if(dataFromAPI.chequePayload.length > 0) {
                    templateDataToReturn.dateFormat = dataFromAPI.chequePayload[0].dateFormat.toLowerCase()
                }
            }
        }

        if (dataFromAPI.chequePayload !== undefined && dataFromAPI.chequePayload !== null) {
            if (dataFromAPI.chequePayload.length > 0) {
                if (dataFromAPI.chequePayload[0].currencySymbol !== undefined && dataFromAPI.chequePayload[0].currencySymbol !== null) {
                    templateDataToReturn.currency = dataFromAPI.chequePayload[0].currencySymbol
                    templateDataToReturn.currencyName = dataFromAPI.chequePayload[0].currency
                }
                else {
                    templateDataToReturn.currencyName = dataFromAPI.chequePayload[0].currency
                }
                templateDataToReturn = TemplateParser.assignCurrencySymbol(templateDataToReturn)
            }
        }

        templateDataToReturn.lineItems = LineItemsParser.getChequeItem(dataFromAPI, templateDataToReturn.dateFormat)
        templateDataToReturn.documentType = this.passCustomTextForUS(templateDataToReturn)
        return templateDataToReturn
    }

    static passCustomTextForUS(templateDataToReturn) {
        var documentType = undefined
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.type !== undefined && TemplateSettingsManager.defaultTemplateSettings.documentInfo.type !== null) {
            var type = TemplateSettingsManager.defaultTemplateSettings.documentInfo.type
            if (type.labelText !== undefined && type.labelText !== null) {
                documentType = type.labelText
            }
        }

        if (documentType === undefined) {
            if (templateDataToReturn.country !== undefined && templateDataToReturn.country !== null) {
                if (templateDataToReturn.country.toLowerCase() === 'us') {
                    documentType = 'Check'
                }
            }
            else {
                documentType = templateDataToReturn.documentType
            }
        }

        return documentType
    }
}
