import { DKButton } from 'deskera-ui-library';
import Attachments, { Attachment } from '../../pages/Attachment';

interface IAttachmentPopupProps {
  onClose: () => void;
  onUpload: () => void;
  attachments: Attachment[];
  document: any;
  entityType:
    | 'INVOICE'
    | 'SALES_ORDER'
    | 'CONTACT'
    | 'QUOTATION'
    | 'PURCHASE_ORDER';
}

const AttachmentPopup: React.FC<IAttachmentPopupProps> = (props) => {
  const getHeader = () => {
    return (
      <div className="row-reverse p-h-l p-v-s bg-gray1" style={{ gap: 10 }}>
        <DKButton
          title={'Close'}
          className="bg-white border-m"
          onClick={props?.onClose}
        />
      </div>
    );
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window overflow-hidden"
        style={{
          maxHeight: '95%',
          height: '40%',
          width: '55%',
          maxWidth: '90%',
          padding: 0
        }}
      >
        <>{getHeader()}</>
        <Attachments
          document={props.document}
          entity={props.entityType}
          onAttachmentUpload={props.onUpload}
        />
      </div>
    </div>
  );
};

export default AttachmentPopup;
