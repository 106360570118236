import React from 'react'
import { AccountTable } from '../DKUILibrary/dktable/account/AccountTable'
import DKDocumentTable from '../DKUILibrary/dktable/DKDocumentTable'
import { FulfillmentTable } from '../DKUILibrary/dktable/fulfillment/FulfillmentTable'
import { GoodsReceiptTable } from '../DKUILibrary/dktable/goodsReceipt/GoodsReceiptTable'
import { ProductTable } from '../DKUILibrary/dktable/product/ProductTable'
import { PaymentTable } from '../DKUILibrary/dktable/payment/PaymentTable'
import { getIsAccountModule, getIsExpenseBill, getIsFulfillment, getIsGoodsReceipt, getIsJournalEntry, getIsMakePayment, getIsPackList, getIsPickList, getIsReceivePayment, getIsShipList, getIsStockAdjustment, getIsStockTransfer, getIsWorkOrder } from '../Utilities/Utility'
import { JournalEntryTable } from '../DKUILibrary/dktable/journalEntry/JournalEntryTable'
import { PickingListTable } from '../DKUILibrary/dktable/pickingList/PickingListTable'
import { PackingListTable } from '../DKUILibrary/dktable/packingList/PackingListTable'
import { WorkOrderTable } from '../DKUILibrary/dktable/workOrder/WorkOrder'
import { StockTransferTable } from '../DKUILibrary/dktable/stockTransfer/StockTransferTable'
import { StockAdjustmentTable } from '../DKUILibrary/dktable/stockAdjustment/StockAdjustmentTable'

interface Props {
    tableColumnOptions: any
    tableStyle: any
    data: any
    stockTableCallback: any
    onUpdateDataCallback: any
    paymentTableCallback: any
    additionalTableCallback: any
    productLineItems: any
}

interface States {
}

export default class TableComponentManger extends React.Component<Props, States> {
    render() {
        return (
            <div>
                {this.renderTable()}
            </div>
        )
    }

    renderTable = () => {
        if(this.isContainsProductLineItem()) {
            return this.renderAccountProductTable()
        }
        else if(getIsAccountModule(this.props.data, undefined)) {
            return this.renderAccountTable()
        }
        else if (getIsExpenseBill(this.props.data.type, this.props.data.lineItems)) {
            return this.renderAccountTable()
        }
        else if (getIsFulfillment(this.props.data.type)) {
            return this.renderFulfillmentTable()
        }
        else if (getIsGoodsReceipt(this.props.data.type)) {
            return this.renderGoodsReceiptTable()
        }
        else if (getIsMakePayment(this.props.data.type)) {
            return this.renderReceiptTable()
        }
        else if (getIsReceivePayment(this.props.data.type)) {
            return this.renderReceiptTable()
        }
        else if (getIsJournalEntry(this.props.data.type)) {
            return this.renderJournalEntryTable()
        }
        else if (getIsPickList(this.props.data.type)) {
            return this.renderPickingListTable()
        }
        else if (getIsPackList(this.props.data.type)) {
            return this.renderPackingListTable()
        }
        else if (getIsShipList(this.props.data.type)) {
            return <></>
        }
        else if (getIsWorkOrder(this.props.data.type)) {
            return this.renderWorkOrderTable()
        }
        else if (getIsStockTransfer(this.props.data.type)) {
            return this.renderStockTransferTable()
        }
        else if (getIsStockAdjustment(this.props.data.type)) {
            return this.renderStockAdjustmentTable()
        }
        return this.renderProductTable()
    }

    isContainsProductLineItem = () => {
        var isContains = false
        if (
            this.props.productLineItems !== undefined &&
            this.props.productLineItems !== null
        ) {
            if (this.props.productLineItems.length > 0) {
                isContains = true
            }
        }
        return isContains
    }

    renderProductTable = () => {
        var header = ProductTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data)
        if (header === undefined) {
            header = []
        }
        return <>
            <DKDocumentTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={ProductTable.getTableOption(!this.props.data.isReadOnlyMode)}
                data={ProductTable.getItemColumn(ProductTable.getData(this.props.data), this.props.tableStyle, false, this.props.data)}
                footerRow={ProductTable.getFooterRow(this.props.data, this.props.tableColumnOptions, this.props.tableStyle)}
                stockColumn={ProductTable.getStockHeaderList(this.props.tableStyle, this.props.data)}
                stockData={ProductTable.getItemColumn(ProductTable.getStockData(this.props.data), this.props.tableStyle, true, this.props.data)}
                showStockTable={this.props.data.showStockTable}
                stockTableCallback={this.props.stockTableCallback}
                onUpdateRowIndexCallback={(rowIndex, action) => {
                    ProductTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                }}
                onDataUpdateCallback={(footerRow) => {
                    var data = this.props.data
                    data.footerRow = footerRow
                    this.props.onUpdateDataCallback(data)
                }}
                paymentColumn={ProductTable.getPaymentHeaderList(this.props.tableStyle)}
                paymentData={ProductTable.getItemColumn(ProductTable.getPaymentData(this.props.data), this.props.tableStyle, true, this.props.data)}
                showPaymentTable={this.props.data.showPaymentTable}
                paymentTableCallback={this.props.paymentTableCallback}
                isReadOnlyMode={this.props.data.isReadOnlyMode}
                documentType={this.props.data.type}
                onUpdateTableWidth={(header, percentage) => {
                    ProductTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, false, (data: any) => {
                        this.props.onUpdateDataCallback(data)
                    })
                }}
                groupHeaderIndexList={ProductTable.getGroupHeaderIndexList()}
                tableStyle={this.props.tableStyle}
                additionalTableCallback={this.props.additionalTableCallback}
                tableColumnOptions={this.props.tableColumnOptions}
            />
        </>
    }

    renderAccountTable = () => {
        var header = AccountTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data)
        if (header === undefined) {
            header = []
        }
        return <>
            <DKDocumentTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={AccountTable.getTableOption(!this.props.data.isReadOnlyMode)}
                data={AccountTable.getItemColumn(AccountTable.getData(this.props.data, this.props.tableColumnOptions), this.props.tableStyle, this.props.data)}
                footerRow={AccountTable.getFooterRow(this.props.data, this.props.tableColumnOptions, this.props.tableStyle, this.props.data)}
                onUpdateRowIndexCallback={(rowIndex, action) => {
                    AccountTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                }}
                onDataUpdateCallback={(footerRow) => {
                    var data = this.props.data
                    data.footerRow = footerRow
                    this.props.onUpdateDataCallback(data)
                }}
                isReadOnlyMode={this.props.data.isReadOnlyMode}
                showStockTable={false}
                showPaymentTable={false}
                documentType={this.props.data.type}
                onUpdateTableWidth={(header, percentage) => {
                    AccountTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, (data: any) => {
                        this.props.onUpdateDataCallback(data)
                    })
                }}
                tableStyle={this.props.tableStyle}
                additionalTableCallback={this.props.additionalTableCallback}
                tableColumnOptions={this.props.tableColumnOptions}
            />
        </>
    }

    renderFulfillmentTable = () => {
        var header = FulfillmentTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data)
        if (header === undefined) {
            header = []
        }
        return <>
            <DKDocumentTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={FulfillmentTable.getTableOption(!this.props.data.isReadOnlyMode)}
                data={FulfillmentTable.getItemColumn(FulfillmentTable.getData(this.props.data), this.props.tableStyle, this.props.data)}
                footerRow={FulfillmentTable.getFooterRow(this.props.data, this.props.tableColumnOptions, this.props.tableStyle)}
                onUpdateRowIndexCallback={(rowIndex, action) => {
                    FulfillmentTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                }}
                onDataUpdateCallback={(footerRow) => {
                    var data = this.props.data
                    data.footerRow = footerRow
                    this.props.onUpdateDataCallback(data)
                }}
                isReadOnlyMode={this.props.data.isReadOnlyMode}
                showStockTable={false}
                showPaymentTable={false}
                documentType={this.props.data.type}
                onUpdateTableWidth={(header, percentage) => {
                    FulfillmentTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, (data: any) => {
                        this.props.onUpdateDataCallback(data)
                    })
                }}
                groupHeaderIndexList={FulfillmentTable.getGroupHeaderIndexList()}
                tableStyle={this.props.tableStyle}
                additionalTableCallback={this.props.additionalTableCallback}
                tableColumnOptions={this.props.tableColumnOptions}
            />
        </>
    }


    renderGoodsReceiptTable = () => {
        var header = GoodsReceiptTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data)
        if (header === undefined) {
            header = []
        }
        return <>
            <DKDocumentTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={GoodsReceiptTable.getTableOption(!this.props.data.isReadOnlyMode)}
                data={GoodsReceiptTable.getItemColumn(GoodsReceiptTable.getData(this.props.data), this.props.tableStyle, this.props.data)}
                footerRow={GoodsReceiptTable.getFooterRow(this.props.data, this.props.tableColumnOptions, this.props.tableStyle)}
                onUpdateRowIndexCallback={(rowIndex, action) => {
                    GoodsReceiptTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                }}
                onDataUpdateCallback={(footerRow) => {
                    var data = this.props.data
                    data.footerRow = footerRow
                    this.props.onUpdateDataCallback(data)
                }}
                isReadOnlyMode={this.props.data.isReadOnlyMode}
                showStockTable={false}
                showPaymentTable={false}
                documentType={this.props.data.type}
                onUpdateTableWidth={(header, percentage) => {
                    GoodsReceiptTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, (data: any) => {
                        this.props.onUpdateDataCallback(data)
                    })
                }}
                groupHeaderIndexList={GoodsReceiptTable.getGroupHeaderIndexList()}
                tableStyle={this.props.tableStyle}
                additionalTableCallback={this.props.additionalTableCallback}
                tableColumnOptions={this.props.tableColumnOptions}
            />
        </>
    }

    renderReceiptTable = () => {
        var header = PaymentTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data)
        if (header === undefined) {
            header = []
        }
        return <>
            <DKDocumentTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={PaymentTable.getTableOption(!this.props.data.isReadOnlyMode)}
                data={PaymentTable.getItemColumn(PaymentTable.getData(this.props.data), this.props.tableStyle, this.props.data)}
                footerRow={PaymentTable.getFooterRow(this.props.data, this.props.tableColumnOptions, this.props.tableStyle, this.props.data)}
                onUpdateRowIndexCallback={(rowIndex, action) => {
                    PaymentTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                }}
                onDataUpdateCallback={(footerRow) => {
                    var data = this.props.data
                    data.footerRow = footerRow
                    this.props.onUpdateDataCallback(data)
                }}
                showStockTable={false}
                showPaymentTable={false}
                isReadOnlyMode={this.props.data.isReadOnlyMode}
                documentType={this.props.data.type}
                onUpdateTableWidth={(header, percentage) => {
                    PaymentTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, (data: any) => {
                        this.props.onUpdateDataCallback(data)
                    })
                }}
                tableStyle={this.props.tableStyle}
                additionalTableCallback={this.props.additionalTableCallback}
                tableColumnOptions={this.props.tableColumnOptions}
            />
        </>
    }


    renderJournalEntryTable = () => {
        var header = JournalEntryTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data)
        if (header === undefined) {
            header = []
        }
        return <>
            <DKDocumentTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={JournalEntryTable.getTableOption(!this.props.data.isReadOnlyMode)}
                data={JournalEntryTable.getItemColumn(JournalEntryTable.getData(this.props.data), this.props.tableStyle, this.props.data)}
                footerRow={JournalEntryTable.getFooterRow(this.props.data, this.props.tableColumnOptions, this.props.tableStyle)}
                onUpdateRowIndexCallback={(rowIndex, action) => {
                    JournalEntryTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                }}
                onDataUpdateCallback={(footerRow) => {
                    var data = this.props.data
                    data.footerRow = footerRow
                    this.props.onUpdateDataCallback(data)
                }}
                showStockTable={false}
                showPaymentTable={false}
                isReadOnlyMode={this.props.data.isReadOnlyMode}
                documentType={this.props.data.type}
                onUpdateTableWidth={(header, percentage) => {
                    JournalEntryTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, (data: any) => {
                        this.props.onUpdateDataCallback(data)
                    })
                }}
                tableStyle={this.props.tableStyle}
                additionalTableCallback={this.props.additionalTableCallback}
                tableColumnOptions={this.props.tableColumnOptions}
            />
        </>
    }

    renderPickingListTable = () => {
        var header = PickingListTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data)
        if (header === undefined) {
            header = []
        }
        return <>
            <DKDocumentTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={PickingListTable.getTableOption(!this.props.data.isReadOnlyMode)}
                data={PickingListTable.getItemColumn(PickingListTable.getData(this.props.data), this.props.tableStyle, this.props.data)}
                footerRow={PickingListTable.getFooterRow(this.props.data, this.props.tableColumnOptions, this.props.tableStyle)}
                onUpdateRowIndexCallback={(rowIndex, action) => {
                    PickingListTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                }}
                onDataUpdateCallback={(footerRow) => {
                    var data = this.props.data
                    data.footerRow = footerRow
                    this.props.onUpdateDataCallback(data)
                }}
                isReadOnlyMode={this.props.data.isReadOnlyMode}
                showStockTable={false}
                showPaymentTable={false}
                documentType={this.props.data.type}
                onUpdateTableWidth={(header, percentage) => {
                    PickingListTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, (data: any) => {
                        this.props.onUpdateDataCallback(data)
                    })
                }}
                tableStyle={this.props.tableStyle}
                additionalTableCallback={this.props.additionalTableCallback}
                tableColumnOptions={this.props.tableColumnOptions}
            />
        </>
    }

    renderPackingListTable = () => {
        var header = PackingListTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data)
        if (header === undefined) {
            header = []
        }
        return <>
            <DKDocumentTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={PackingListTable.getTableOption(!this.props.data.isReadOnlyMode)}
                data={PackingListTable.getItemColumn(PackingListTable.getData(this.props.data), this.props.tableStyle, this.props.data)}
                footerRow={PackingListTable.getFooterRow(this.props.data, this.props.tableColumnOptions, this.props.tableStyle)}
                onUpdateRowIndexCallback={(rowIndex, action) => {
                    PackingListTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                }}
                onDataUpdateCallback={(footerRow) => {
                    var data = this.props.data
                    data.footerRow = footerRow
                    this.props.onUpdateDataCallback(data)
                }}
                isReadOnlyMode={this.props.data.isReadOnlyMode}
                showStockTable={false}
                showPaymentTable={false}
                documentType={this.props.data.type}
                onUpdateTableWidth={(header, percentage) => {
                    PackingListTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, (data: any) => {
                        this.props.onUpdateDataCallback(data)
                    })
                }}
                tableStyle={this.props.tableStyle}
                additionalTableCallback={this.props.additionalTableCallback}
                tableColumnOptions={this.props.tableColumnOptions}
            />
        </>
    }

    renderAccountProductTable = () => {
        var header = ProductTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data)
        if (header === undefined) {
            header = []
        }

        return <>
            <DKDocumentTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={ProductTable.getTableOption(!this.props.data.isReadOnlyMode)}
                data={ProductTable.getItemColumn(ProductTable.getAccountProductData(this.props.data), this.props.tableStyle, false, this.props.data)}
                footerRow={[]}
                stockColumn={undefined}
                stockData={undefined}
                showStockTable={false}
                stockTableCallback={undefined}
                onUpdateRowIndexCallback={(rowIndex, action) => {
                    ProductTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                }}
                onDataUpdateCallback={(footerRow) => {
                    var data = this.props.data
                    data.footerRow = footerRow
                    this.props.onUpdateDataCallback(data)
                }}
                paymentColumn={ProductTable.getPaymentHeaderList(this.props.tableStyle)}
                paymentData={ProductTable.getItemColumn(ProductTable.getPaymentData(this.props.data), this.props.tableStyle, true, this.props.data)}
                showPaymentTable={this.props.data.showPaymentTable}
                paymentTableCallback={this.props.paymentTableCallback}
                isReadOnlyMode={this.props.data.isReadOnlyMode}
                documentType={this.props.data.type}
                additionalTablePrefix={'product'}
                onUpdateTableWidth={(header, percentage) => {
                    ProductTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, true, (data: any) => {
                        this.props.onUpdateDataCallback(data)
                    })
                }}
                groupHeaderIndexList={ProductTable.getGroupHeaderIndexList()}
                tableStyle={this.props.tableStyle}
                additionalTableCallback={this.props.additionalTableCallback}
                tableColumnOptions={this.props.tableColumnOptions}
            />
        </>
    }

    renderWorkOrderTable = () => {
        var header = WorkOrderTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data)
        if (header === undefined) {
            header = []
        }
        return <>
            <DKDocumentTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={WorkOrderTable.getTableOption(!this.props.data.isReadOnlyMode)}
                data={WorkOrderTable.getItemColumn(WorkOrderTable.getData(this.props.data), this.props.tableStyle, this.props.data)}
                footerRow={WorkOrderTable.getFooterRow(this.props.data, this.props.tableColumnOptions, this.props.tableStyle)}
                onUpdateRowIndexCallback={(rowIndex, action) => {
                    WorkOrderTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                }}
                onDataUpdateCallback={(footerRow) => {
                    var data = this.props.data
                    data.footerRow = footerRow
                    this.props.onUpdateDataCallback(data)
                }}
                isReadOnlyMode={this.props.data.isReadOnlyMode}
                showStockTable={false}
                showPaymentTable={false}
                documentType={this.props.data.type}
                onUpdateTableWidth={(header, percentage) => {
                    WorkOrderTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, (data: any) => {
                        this.props.onUpdateDataCallback(data)
                    })
                }}
                groupHeaderIndexList={WorkOrderTable.getGroupHeaderIndexList()}
                tableStyle={this.props.tableStyle}
                additionalTableCallback={this.props.additionalTableCallback}
                tableColumnOptions={this.props.tableColumnOptions}
            />
        </>
    }

    renderStockTransferTable = () => {
        var header = StockTransferTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle)
        if (header === undefined) {
            header = []
        }
        return <>
            <DKDocumentTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={StockTransferTable.getTableOption(!this.props.data.isReadOnlyMode)}
                data={StockTransferTable.getItemColumn(StockTransferTable.getData(this.props.data), this.props.tableStyle)}
                footerRow={StockTransferTable.getFooterRow(this.props.data, this.props.tableColumnOptions, this.props.tableStyle)}
                onUpdateRowIndexCallback={(rowIndex, action) => {
                    StockTransferTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                }}
                onDataUpdateCallback={(footerRow) => {
                    var data = this.props.data
                    data.footerRow = footerRow
                    this.props.onUpdateDataCallback(data)
                }}
                isReadOnlyMode={this.props.data.isReadOnlyMode}
                showStockTable={false}
                showPaymentTable={false}
                documentType={this.props.data.type}
                onUpdateTableWidth={(header, percentage) => {
                    StockTransferTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, (data: any) => {
                        this.props.onUpdateDataCallback(data)
                    })
                }}
                tableStyle={this.props.tableStyle}
                additionalTableCallback={this.props.additionalTableCallback}
                tableColumnOptions={this.props.tableColumnOptions}
            />
        </>
    }

    renderStockAdjustmentTable = () => {
        var header = StockAdjustmentTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle)
        if (header === undefined) {
            header = []
        }
        return <>
            <DKDocumentTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={StockAdjustmentTable.getTableOption(!this.props.data.isReadOnlyMode)}
                data={StockAdjustmentTable.getItemColumn(StockAdjustmentTable.getData(this.props.data), this.props.tableStyle)}
                footerRow={StockAdjustmentTable.getFooterRow(this.props.data, this.props.tableColumnOptions, this.props.tableStyle)}
                onUpdateRowIndexCallback={(rowIndex, action) => {
                    StockAdjustmentTable.onRowChange(this.props.data, rowIndex, action, (data: any) => this.props.onUpdateDataCallback(data))
                }}
                onDataUpdateCallback={(footerRow) => {
                    var data = this.props.data
                    data.footerRow = footerRow
                    this.props.onUpdateDataCallback(data)
                }}
                isReadOnlyMode={this.props.data.isReadOnlyMode}
                showStockTable={false}
                showPaymentTable={false}
                documentType={this.props.data.type}
                onUpdateTableWidth={(header, percentage) => {
                    StockAdjustmentTable.onUpdateTableWidth(header, percentage, this.props.data, this.props.tableColumnOptions, (data: any) => {
                        this.props.onUpdateDataCallback(data)
                    })
                }}
                tableStyle={this.props.tableStyle}
                additionalTableCallback={this.props.additionalTableCallback}
                tableColumnOptions={this.props.tableColumnOptions}
            />
        </>
    }
}
