import TemplateSettingsManager from "../Manager/TemplateSettingsManager";
import Utility, {convertDateFromERPFormatToDocFormat, getCapitalized, getConvertedDate, getDateFrom, getDateString, getIsBill, getIsExpenseBill, getIsExpenseOrDeposit, getIsFulfillment, getIsGoodsReceipt, getIsInvoice, getIsJournalEntry, getIsMakePayment, getIsOrder, getIsPackList, getIsPickList, getIsQuotation, getIsReceivePayment, getIsSalesOrder, getIsShipList} from "../Utilities/Utility";
import ComponentManager from "../Manager/ComponentManager";
import {TableColumnPopupType} from "../Components/Popup/TableColumnPopup";
import AppManager from "../Manager/AppManager";
import { getIsAccountModule } from "../Utilities/Utility";
import { CurrencyFormatType, DateFormat } from "../Components/TemplateEditors/DocumentEditor";
import CustomFieldManager from "../Manager/CustomFieldManager";
import { getLocalisedText } from "../Translate/LanguageManager";
import IndiaTaxParser from "./IndiaTaxParser";
import Address from "./Address";
import AddressParser, { AddressType } from "./AddressParser";

export default class DataParser {
    static getTemplateDataFromERP(dataFromAPI, isReadOnlyMode = false, isGeneratedData) {
        let templateDataToReturn = {}

         //type
        dataFromAPI.type = this.getApiType(dataFromAPI.type)
        let type =  dataFromAPI.type.toLowerCase()
        templateDataToReturn.type = this.getTemplateType(dataFromAPI.type)
        templateDataToReturn.documentType = this.getTemplateDocumentType(templateDataToReturn.type)
        templateDataToReturn.showDocumentType = TemplateSettingsManager.defaultTemplateSettings.documentInfo.type === undefined ? true : TemplateSettingsManager.defaultTemplateSettings.documentInfo.type.isVisible

        if (getIsAccountModule(null, dataFromAPI.type.toLowerCase())) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }
        else if (getIsFulfillment(dataFromAPI.type) || getIsGoodsReceipt(dataFromAPI.type)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }
        else if (getIsExpenseBill(dataFromAPI.type, dataFromAPI.lineItems)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }
        else if (getIsBill(dataFromAPI.type)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }
        else if (getIsMakePayment(dataFromAPI.type) || getIsReceivePayment(dataFromAPI.type)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }
        else if (getIsJournalEntry(dataFromAPI.type)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }
        else if (getIsPickList(dataFromAPI.type)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }
        else if (getIsPackList(dataFromAPI.type)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }
        else if (getIsShipList(dataFromAPI.type)) {
            TemplateSettingsManager.resetDefaultData(dataFromAPI, '')
        }

        // get decimal scale
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.decimalScale === undefined) {
            var scale = (dataFromAPI.decimalScale !== null && dataFromAPI.decimalScale !== undefined ) ? dataFromAPI.decimalScale : 2
            TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails['decimalScale'] = { 'value': scale }
            AppManager.setDecimalScale(scale)
        }
        else {
            AppManager.setDecimalScale(TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.decimalScale.value)
        }

        //get number format
        if(dataFromAPI.tenantInfo !== undefined && dataFromAPI.tenantInfo !== null) {
            if (dataFromAPI.tenantInfo.numberFormat !== undefined && dataFromAPI.tenantInfo.numberFormat !== null) {
                AppManager.setNumberFormat(dataFromAPI.tenantInfo.numberFormat)
            }
            if (dataFromAPI.tenantInfo.country !== undefined && dataFromAPI.tenantInfo.country !== null) {
                templateDataToReturn.country = dataFromAPI.tenantInfo.country
            }
            if (dataFromAPI.tenantInfo.decimalScale !== undefined && dataFromAPI.tenantInfo.decimalScale !== null) {
                AppManager.setTenantDecimalScale(dataFromAPI.tenantInfo.decimalScale)
            }
            else {
                AppManager.setTenantDecimalScale(AppManager.getDecimalScale())
            }
        }

        //Update data as per API
        templateDataToReturn.clientName = dataFromAPI.clientName //not being used

        //Template Info
        //id
        templateDataToReturn.templateId = TemplateSettingsManager.defaultTemplateSettings.templateInfo.id
        if (dataFromAPI.templateId) {
            templateDataToReturn.templateId = dataFromAPI.templateId
        }

        //theme color
        templateDataToReturn.themeColor = TemplateSettingsManager.defaultTemplateSettings.templateInfo.themeColor
        if (dataFromAPI.themeColor) {
            templateDataToReturn.themeColor = dataFromAPI.themeColor
        }

        //Language
        templateDataToReturn.languageInfo = TemplateSettingsManager.defaultTemplateSettings.languageInfo

        //Company Details
        //Logo
        templateDataToReturn.logo = TemplateSettingsManager.defaultTemplateSettings.documentInfo.companyDetails.logo.image
        templateDataToReturn.showCompanyLogo = TemplateSettingsManager.defaultTemplateSettings.documentInfo.companyDetails.logo.isVisible === undefined ? true : TemplateSettingsManager.defaultTemplateSettings.documentInfo.companyDetails.logo.isVisible

        //Owner Email
        templateDataToReturn.ownerEmail = dataFromAPI.ownerEmail

        //Company Name
        var name = ''
        if (getIsFulfillment(templateDataToReturn.type)
            || getIsGoodsReceipt(templateDataToReturn.type)
            || getIsJournalEntry(templateDataToReturn.type)) {
            if (dataFromAPI.tenantInfo !== undefined && dataFromAPI.tenantInfo !== null) {
                if (dataFromAPI.tenantInfo.name !== undefined && dataFromAPI.tenantInfo.name !== null) {
                    name = dataFromAPI.tenantInfo.name
                }
            }
            templateDataToReturn.companyName = name
        }
        else if (getIsPickList(templateDataToReturn.type)) {
            name = ''
            if (this.getPayloadData(dataFromAPI) !== undefined) {
                if (this.getPayloadData(dataFromAPI).warehouseName !== undefined && this.getPayloadData(dataFromAPI).warehouseName !== null) {
                    name = this.getPayloadData(dataFromAPI).warehouseName
                }
            }
            templateDataToReturn.companyName = name
        }
        else if (getIsPackList(templateDataToReturn.type)) {
            name = ''
            if (this.getPayloadData(dataFromAPI) !== undefined) {
                if (this.getPayloadData(dataFromAPI).wName !== undefined && this.getPayloadData(dataFromAPI).wName !== null) {
                    name = this.getPayloadData(dataFromAPI).wName
                }
            }
            templateDataToReturn.companyName = name
        }
        else if (getIsShipList(templateDataToReturn.type)) {
            name = ''
            if (this.getPayloadData(dataFromAPI) !== undefined) {
                if (this.getPayloadData(dataFromAPI).companyName !== undefined && this.getPayloadData(dataFromAPI).companyName !== null) {
                    name = this.getPayloadData(dataFromAPI).companyName
                }
            }
            templateDataToReturn.companyName = name
        }
        else {
            templateDataToReturn.companyName = this.IsOrderOrBill(templateDataToReturn.type) === true ? getCapitalized(dataFromAPI.billToName) : dataFromAPI.companyName
        }
        templateDataToReturn.showCompanyName = TemplateSettingsManager.defaultTemplateSettings.documentInfo.companyDetails.name.isVisible
        //Company Address
        // templateDataToReturn.from = dataFromAPI.from
        var fromAddressObj = undefined
        var warehouseAddress = undefined
        var address = undefined
        var preferAddress = undefined
        if (getIsAccountModule(null, templateDataToReturn.type) || getIsMakePayment(templateDataToReturn.type) || getIsReceivePayment(templateDataToReturn.type)) {
            //get data from tenant info
            templateDataToReturn.from = ''
            if (dataFromAPI.tenantInfo !== undefined && dataFromAPI.tenantInfo !== null) {
                if (dataFromAPI.tenantInfo.billingAddresses !== undefined && dataFromAPI.tenantInfo.billingAddresses !== null) {
                    preferAddress = AddressParser.getPreferAddress(dataFromAPI.tenantInfo.billingAddresses)
                    if (preferAddress !== undefined) {
                        fromAddressObj = new Address(undefined, preferAddress, dataFromAPI.pan, dataFromAPI.gstin)
                    }
                }
            }
        }
        else if (getIsFulfillment(templateDataToReturn.type) || getIsGoodsReceipt(templateDataToReturn.type) || getIsJournalEntry(templateDataToReturn.type)) {
            templateDataToReturn.from = ''
            if (dataFromAPI.tenantInfo !== undefined && dataFromAPI.tenantInfo !== null) {
                if (dataFromAPI.tenantInfo.address !== undefined && dataFromAPI.tenantInfo.address !== null) {
                    fromAddressObj = new Address(undefined, dataFromAPI.tenantInfo.address, dataFromAPI.pan, dataFromAPI.gstin)
                }
            }
        }
        else if (getIsPickList(templateDataToReturn.type)) {
            templateDataToReturn.from = ''
            if (this.getPayloadData(dataFromAPI) !== undefined) {
                warehouseAddress = this.getPayloadData(dataFromAPI)
                address = {
                    address1: warehouseAddress.warehouseAdd1,
                    address2: warehouseAddress.warehouseAdd2,
                    postalCode: warehouseAddress.warehouseZipCode,
                    country: warehouseAddress.warehouseCountry,
                }
                fromAddressObj = new Address(undefined, address, undefined, undefined)
            }
        }
        else if (getIsPackList(templateDataToReturn.type)) {
            templateDataToReturn.from = ''
            if (this.getPayloadData(dataFromAPI) !== undefined) {
                warehouseAddress = this.getPayloadData(dataFromAPI)
                address = {
                    address1: warehouseAddress.wAdd1,
                    address2: warehouseAddress.wAdd2,
                    postalCode: warehouseAddress.wZip,
                    country: warehouseAddress.wCountry,
                }
                fromAddressObj = new Address(undefined, address, undefined, undefined)
            }
        }
        else {
            if (this.IsOrderOrBill(templateDataToReturn.type)) {
                fromAddressObj = new Address(undefined, dataFromAPI.shipFromAddress, dataFromAPI.contactInfo.pan, dataFromAPI.contactInfo.gstin)
            }
            else {
                fromAddressObj = new Address(undefined, dataFromAPI.shipFromAddress, dataFromAPI.pan, dataFromAPI.gstin)
                if (dataFromAPI.tenantInfo !== undefined && dataFromAPI.tenantInfo !== null) {
                    if (dataFromAPI.tenantInfo.billingAddresses !== undefined && dataFromAPI.tenantInfo.billingAddresses !== null) {
                        preferAddress = AddressParser.getPreferAddress(dataFromAPI.tenantInfo.billingAddresses)
                        if (preferAddress !== undefined) {
                            fromAddressObj = new Address(undefined, preferAddress, dataFromAPI.pan, dataFromAPI.gstin)
                        }
                    }
                }
            }
        }

        templateDataToReturn.fromObj = fromAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.from, templateDataToReturn)
        templateDataToReturn.showFrom = TemplateSettingsManager.defaultTemplateSettings.documentInfo.companyDetails.address.isVisible

        //ClientAddresses
        //Bill To Address
        templateDataToReturn.billToTitle = TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.billToDetails.title
        templateDataToReturn.showClientBillToAddress = TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.billToDetails.isVisible
        var clientBillToAddressObj = undefined
        var contactName = undefined

        if (getIsAccountModule(null, templateDataToReturn.type) || getIsMakePayment(templateDataToReturn.type) || getIsReceivePayment(templateDataToReturn.type)) {
            if (dataFromAPI.contactInfo !== undefined && dataFromAPI.contactInfo !== null) {
                if (dataFromAPI.contactInfo.billingAddress !== undefined && dataFromAPI.contactInfo.billingAddress !== null) {
                    clientBillToAddressObj = new Address(dataFromAPI.clientName, dataFromAPI.contactInfo.billingAddress, undefined, undefined)
                }
            }
        }
        else if (getIsFulfillment(templateDataToReturn.type) || getIsGoodsReceipt(templateDataToReturn.type)) {
            if (dataFromAPI.parentDocumentData !== undefined || dataFromAPI.parentDocumentData !== null) {
                if (dataFromAPI.parentDocumentData.shipTo !== undefined || dataFromAPI.parentDocumentData.shipTo !== null) {
                    clientBillToAddressObj = new Address(undefined, dataFromAPI.parentDocumentData.shipTo, dataFromAPI.pan, dataFromAPI.gstin)
                }
            }
        }
        else if (getIsJournalEntry(templateDataToReturn.type) || getIsPickList(templateDataToReturn.type)) {
            templateDataToReturn.billTo = ''
            templateDataToReturn.clientBillToAddress = ''
        }
        else if (getIsPackList(templateDataToReturn.type)) {
            var billTo = this.getPayloadData(dataFromAPI)
            address = {
                address1: billTo.cAdd1,
                address2: billTo.cAdd2,
                postalCode: billTo.cZip,
                country: billTo.cCountry,
            }

            contactName = ''
            if(billTo.cName !== undefined && billTo.cName !== null) {
                contactName = billTo.cName
            }
            clientBillToAddressObj = new Address(contactName, address, dataFromAPI.pan, dataFromAPI.gstin)
        }
        else if (getIsShipList(templateDataToReturn.type)) {
            var customer = this.getPayloadData(dataFromAPI)
            address = {
                address1: customer.custAdd1,
                address2: customer.custAdd2,
                postalCode: customer.custZip,
                country: customer.custCountry,
            }

            contactName = ''
            if (customer.custName !== undefined && customer.custName !== null) {
                contactName = customer.custName
            }
            clientBillToAddressObj = new Address(contactName, address, dataFromAPI.pan, dataFromAPI.gstin)
        }
        else {
            clientBillToAddressObj = this.IsOrderOrBill(templateDataToReturn.type) === true ?
                new Address(dataFromAPI.companyName, dataFromAPI.billToAddress, dataFromAPI.pan, dataFromAPI.gstin) :
                new Address(dataFromAPI.billToName, dataFromAPI.billToAddress, dataFromAPI.contactInfo.pan, dataFromAPI.contactInfo.gstin)
        }
        templateDataToReturn.clientBillToAddressObj = clientBillToAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.billTo, templateDataToReturn)

        //Ship To Address
        templateDataToReturn.shipToTitle = TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.shipToDetails.title
        templateDataToReturn.showClientShipToAddress = TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.shipToDetails.isVisible

        var clientShipToAddressObj = undefined
        var shipTo = AddressType.shipTo
        if (getIsExpenseOrDeposit(dataFromAPI.type) || getIsMakePayment(templateDataToReturn.type) || getIsReceivePayment(templateDataToReturn.type)) {
            shipTo = this.get_CN_DN_Payment_Detail(dataFromAPI)
        }
        else if (getIsFulfillment(templateDataToReturn.type) || getIsGoodsReceipt(templateDataToReturn.type)) {
            if (dataFromAPI.parentDocumentData !== undefined || dataFromAPI.parentDocumentData !== null) {
                if (dataFromAPI.parentDocumentData.shipFrom !== undefined || dataFromAPI.parentDocumentData.shipFrom !== null) {
                    clientShipToAddressObj = new Address(undefined, dataFromAPI.parentDocumentData.shipFrom, dataFromAPI.pan, dataFromAPI.gstin)
                }
            }
        }
        else if (getIsJournalEntry(templateDataToReturn.type) || getIsPickList(templateDataToReturn.type) || getIsPackList(templateDataToReturn.type)) {
            shipTo = ''
        }
        else if (getIsShipList(templateDataToReturn.type)) {
            var company = this.getPayloadData(dataFromAPI)
            address = {
                address1: company.comAdd1,
                address2: company.comAdd2,
                postalCode: company.comZip,
                country: company.comCountry,
            }

            var companyName = ''
            if (company.companyName !== undefined && company.companyName !== null) {
                companyName = company.companyName
            }
            clientShipToAddressObj = new Address(companyName, address, dataFromAPI.pan, dataFromAPI.gstin)
        }
        else {
            clientShipToAddressObj = this.IsOrderOrBill(templateDataToReturn.type) === true ?
                new Address(dataFromAPI.companyName, dataFromAPI.shipToAddress, dataFromAPI.pan, dataFromAPI.gstin) :
                new Address(dataFromAPI.shipToName, dataFromAPI.shipToAddress, dataFromAPI.contactInfo.pan, dataFromAPI.contactInfo.gstin)
        }

        templateDataToReturn.clientShipToAddressObj = clientShipToAddressObj
        if (shipTo === AddressType.shipTo) {
            templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.shipTo, templateDataToReturn)
        }
        else {
            templateDataToReturn.clientShipToAddress = shipTo
        }

        //get SA name and address
        templateDataToReturn = this.getSaudiArabiaAddress(dataFromAPI, templateDataToReturn)

        //Currency
        if(dataFromAPI.currencySymbol !== undefined && dataFromAPI.currencySymbol !== null) {
            templateDataToReturn.currency = dataFromAPI.currencySymbol
            templateDataToReturn.currencyName = dataFromAPI.currency
        }
        else if (getIsJournalEntry(templateDataToReturn.type)) {
            templateDataToReturn.currency = dataFromAPI.journalEntry.currencySymbol
            templateDataToReturn.currencyName = dataFromAPI.journalEntry.currency
        }
        else  {
            // templateDataToReturn.currency = ''
            templateDataToReturn.currencyName = dataFromAPI.currency
        }

        var templateCurrency = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.currency
        if (templateCurrency !== undefined && templateCurrency !== null) {
            var newTemplateCurrency = templateCurrency
            if (templateCurrency.format !== null && templateCurrency.format !== undefined) {
                if (templateCurrency.format === CurrencyFormatType.SYMBOL &&
                    (templateDataToReturn.currency === null || templateDataToReturn.currency === undefined)
                ) {
                    var currency = {
                        isVisible: templateCurrency.isVisible,
                        format: CurrencyFormatType.NAME,
                        customValue: undefined
                    }
                    newTemplateCurrency = currency
                }
            }
            templateDataToReturn.currencyFormat = newTemplateCurrency.format
            templateDataToReturn.currencyCustomValue = newTemplateCurrency.customValue

            //display the type according to document currency symbol/name
            if(newTemplateCurrency.format === CurrencyFormatType.SYMBOL) {
                templateDataToReturn.currencyCustomValue = templateDataToReturn.currency
            }
            else if (newTemplateCurrency.format === CurrencyFormatType.NAME) {
                templateDataToReturn.currencyCustomValue = templateDataToReturn.currencyName
            }
            else if (newTemplateCurrency.format === CurrencyFormatType.SYMBOL_NAME) {
                templateDataToReturn.currencyCustomValue = (templateDataToReturn.currencyName ? templateDataToReturn.currencyName : '$') + ' ' + (templateDataToReturn.currency ? templateDataToReturn.currency : '$')
            }

            templateDataToReturn.showCurrency = newTemplateCurrency.isVisible
        }
        else {
            var defaultCurrency = {
                isVisible: true,
                format: templateDataToReturn.currency !== undefined && templateDataToReturn.currency !== null ? CurrencyFormatType.CUSTOM : CurrencyFormatType.NAME,
                customValue: templateDataToReturn.currency !== undefined && templateDataToReturn.currency !== null ? templateDataToReturn.currency : templateDataToReturn.currencyName
            }

            TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.currency = defaultCurrency
            templateDataToReturn.showCurrency = true
            templateDataToReturn.currencyFormat = defaultCurrency.format
            templateDataToReturn.currencyCustomValue = defaultCurrency.customValue
        }

        //Document Details
        //Ref Number
        var linkDocumentNumber = ''
        var linkDocumentType = ''
        var refNumber = ''
        if(getIsFulfillment(dataFromAPI.type)) {
            var fulfillmentCode = ''
            if (dataFromAPI.fulfillment !== undefined && dataFromAPI.fulfillment !== null) {
                fulfillmentCode = this.getDefaultValue(dataFromAPI.fulfillment.fulfillment_code)
                if (dataFromAPI.parentDocumentData !== undefined && dataFromAPI.parentDocumentData !== null) {
                    linkDocumentNumber = this.getDefaultValue(dataFromAPI.parentDocumentData.documentCode)
                    linkDocumentType = this.getDefaultValue(dataFromAPI.parentDocumentData.documentType)
                }
            }
            templateDataToReturn.refNumber = fulfillmentCode
            templateDataToReturn.linkDocumentNumber = linkDocumentNumber
            templateDataToReturn.linkDocumentType = linkDocumentType
            templateDataToReturn.showLinkDocumentNumber = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.linkDocumentNumber.isVisible
        }
        else if (getIsGoodsReceipt(dataFromAPI.type)) {
            var goodsReceiptCode = ''
            if (dataFromAPI.goodsReceipt !== undefined && dataFromAPI.goodsReceipt !== null) {
                goodsReceiptCode = this.getDefaultValue(dataFromAPI.goodsReceipt.goods_receipt_code)
                if (dataFromAPI.parentDocumentData !== undefined && dataFromAPI.parentDocumentData !== null) {
                    linkDocumentNumber = this.getDefaultValue(dataFromAPI.parentDocumentData.documentCode)
                    linkDocumentType = this.getDefaultValue(dataFromAPI.parentDocumentData.documentType)
                }
            }
            templateDataToReturn.refNumber = goodsReceiptCode
            templateDataToReturn.linkDocumentNumber = linkDocumentNumber
            templateDataToReturn.linkDocumentType = linkDocumentType
            templateDataToReturn.showLinkDocumentNumber = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.linkDocumentNumber.isVisible
        }
        else if (getIsJournalEntry(dataFromAPI.type)) {
            var journalEntryNumber = ''

            if (dataFromAPI.journalEntry.jeCode !== undefined && dataFromAPI.journalEntry.jeCode !== null) {
                journalEntryNumber = dataFromAPI.journalEntry.jeCode
            }
            if (dataFromAPI.parentDocument !== undefined && dataFromAPI.parentDocument !== null) {
                linkDocumentNumber = this.getDefaultValue(dataFromAPI.parentDocument.documentCode)
                linkDocumentType = this.getDefaultValue(dataFromAPI.parentDocument.documentType)
            }

            templateDataToReturn.refNumber = journalEntryNumber
            templateDataToReturn.linkDocumentNumber = linkDocumentNumber
            templateDataToReturn.linkDocumentType = linkDocumentType
            templateDataToReturn.showLinkDocumentNumber = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.linkDocumentNumber.isVisible
        }
        else if (getIsPickList(dataFromAPI.type)) {
            refNumber = ''
            if(this.getPayloadData(dataFromAPI) !== undefined) {
                if (this.getPayloadData(dataFromAPI).pickListNo !== undefined && this.getPayloadData(dataFromAPI).pickListNo !== null) {
                    refNumber = this.getPayloadData(dataFromAPI).pickListNo
                }
            }
            templateDataToReturn.refNumber = refNumber
        }
        else if (getIsPackList(dataFromAPI.type)) {
            refNumber = ''
            if (this.getPayloadData(dataFromAPI) !== undefined) {
                if (this.getPayloadData(dataFromAPI).orderNumber !== undefined && this.getPayloadData(dataFromAPI).orderNumber !== null) {
                    refNumber = this.getPayloadData(dataFromAPI).orderNumber
                }
                if (this.getPayloadData(dataFromAPI).cartonNo !== undefined && this.getPayloadData(dataFromAPI).cartonNo !== null) {
                    linkDocumentNumber = this.getPayloadData(dataFromAPI).cartonNo
                }
            }
            templateDataToReturn.refNumber = refNumber
            templateDataToReturn.linkDocumentNumber = linkDocumentNumber
            templateDataToReturn.linkDocumentType = getLocalisedText('number_of_carton')
            templateDataToReturn.showLinkDocumentNumber = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.linkDocumentNumber.isVisible

        }
        else if (getIsShipList(dataFromAPI.type)) {
            if(this.getPayloadData(dataFromAPI) !== undefined) {
                if (this.getPayloadData(dataFromAPI).orderNo !== undefined && this.getPayloadData(dataFromAPI).orderNo !== null) {
                    linkDocumentNumber = this.getPayloadData(dataFromAPI).orderNo
                }
            }
            templateDataToReturn.linkDocumentNumber = linkDocumentNumber
            templateDataToReturn.linkDocumentType = getLocalisedText('order_number')
            templateDataToReturn.showLinkDocumentNumber = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.linkDocumentNumber.isVisible

            var count = 1
            var total = 1
            var isFragile = ''

            if (this.getPayloadData(dataFromAPI) !== undefined) {
                if (this.getPayloadData(dataFromAPI).i !== undefined && this.getPayloadData(dataFromAPI).i !== null) {
                    count = this.getPayloadData(dataFromAPI).i
                }
                if (this.getPayloadData(dataFromAPI).n !== undefined && this.getPayloadData(dataFromAPI).n !== null) {
                    total = this.getPayloadData(dataFromAPI).n
                }
                if (this.getPayloadData(dataFromAPI).isFragile !== undefined && this.getPayloadData(dataFromAPI).isFragile !== null ) {
                    isFragile = this.getPayloadData(dataFromAPI).isFragile
                }
            }
            templateDataToReturn.isFragile = isFragile
            templateDataToReturn.packageCount = count + ' / ' + total
        }
        else {
            templateDataToReturn.refNumber = dataFromAPI.refNumber
        }
        templateDataToReturn.showRefNumber = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.referenceNumber.isVisible

        //Date Format
        let dateFormat = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.format
        if (TemplateSettingsManager.getRemoteTemplateUID() === null || TemplateSettingsManager.getRemoteTemplateUID() === undefined) {
            if (dataFromAPI.tenantInfo !== undefined && dataFromAPI.tenantInfo !== null) {
                if (dataFromAPI.tenantInfo.dateFormat !== undefined && dataFromAPI.tenantInfo.dateFormat !== null) {
                    if (dataFromAPI.tenantInfo.dateFormat !== '') {
                        dateFormat = dataFromAPI.tenantInfo.dateFormat
                    }
                }
            }
        }
        templateDataToReturn.dateFormat = dateFormat

        //Start Date
        if(getIsFulfillment(templateDataToReturn.type)) {
            var fulfillmentDate = ''
            if (dataFromAPI.fulfillment !== undefined && dataFromAPI.fulfillment !== null) {
                fulfillmentDate = this.getDefaultValue(dataFromAPI.fulfillment.fulfillmentDate)
            }
            templateDataToReturn.date = getConvertedDate(convertDateFromERPFormatToDocFormat(fulfillmentDate), dateFormat)
        }
        else if (getIsGoodsReceipt(templateDataToReturn.type)) {
            var goodsReceiptDate = ''
            if (dataFromAPI.goodsReceipt !== undefined && dataFromAPI.goodsReceipt !== null) {
                goodsReceiptDate = this.getDefaultValue(dataFromAPI.goodsReceipt.receiptDate)
            }
            templateDataToReturn.date = getConvertedDate(convertDateFromERPFormatToDocFormat(goodsReceiptDate), dateFormat)
        }
        else if (getIsJournalEntry(templateDataToReturn.type)) {
            var journalEntryDate = ''
            if (dataFromAPI.journalEntry.jeDate !== undefined && dataFromAPI.journalEntry.jeDate !== null) {
                journalEntryDate = dataFromAPI.journalEntry.jeDate
            }
            templateDataToReturn.date = getConvertedDate(convertDateFromERPFormatToDocFormat(journalEntryDate), dateFormat)
        }
        else if (getIsPickList(templateDataToReturn.type) || getIsPackList(templateDataToReturn.type)) {
            var date = ''
            if (this.getPayloadData(dataFromAPI) !== undefined) {
                if (this.getPayloadData(dataFromAPI).date !== undefined && this.getPayloadData(dataFromAPI).date !== null) {
                    date = this.getPayloadData(dataFromAPI).date
                }
            }
            if(date !== '') {
                var rawDate = getDateString(getDateFrom(date, this.getPickPackShipDateFormat(dataFromAPI, dateFormat), templateDataToReturn.dateFormat))
                templateDataToReturn.date = getConvertedDate(convertDateFromERPFormatToDocFormat(rawDate), templateDataToReturn.dateFormat)
            }
            else {
                templateDataToReturn.date = date
            }
        }
        else if (getIsShipList(templateDataToReturn.type)) {
            templateDataToReturn.date = ''
            templateDataToReturn.showStartDate = false
        }
        else {
            templateDataToReturn.date = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.date), dateFormat)
        }
        templateDataToReturn.showStartDate = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.startDate.isVisible
        //End Date
        if (getIsFulfillment(templateDataToReturn.type) || getIsGoodsReceipt(templateDataToReturn.type)) {
            templateDataToReturn.dueDate = ''
            templateDataToReturn.showDueDate = false
        }
        else if (getIsAccountModule(null, templateDataToReturn.type) || getIsMakePayment(templateDataToReturn.type) || getIsReceivePayment(templateDataToReturn.type)) {
            if (dataFromAPI.referenceDate !== undefined && dataFromAPI.referenceDate !== null && dataFromAPI.referenceDate !== '') {
                templateDataToReturn.dueDate = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.referenceDate), dateFormat)
                templateDataToReturn.showDueDate = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.dueDate.isVisible
            }
        }
        else if (getIsJournalEntry(templateDataToReturn.type)
            || getIsPickList(templateDataToReturn.type)
            || getIsPackList(templateDataToReturn.type)
            || getIsShipList(templateDataToReturn.type)) {
            templateDataToReturn.dueDate = ''
            templateDataToReturn.showDueDate = false
        }
        else {
            templateDataToReturn.dueDate = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.dueDate), dateFormat)
            templateDataToReturn.showDueDate = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.dueDate.isVisible
        }

        //Line Items
        if (getIsFulfillment(templateDataToReturn.type)) {
            templateDataToReturn.lineItems = this.getFulfillmentItem(dataFromAPI, dateFormat)
        }
        else if (getIsGoodsReceipt(templateDataToReturn.type)) {
            templateDataToReturn.lineItems = this.getGoodsReceiptItem(dataFromAPI)
        }
        else if (getIsAccountModule(null, templateDataToReturn.type)) {
            templateDataToReturn.lineItems = this.getAccountItem(dataFromAPI)
        }
        else if (getIsReceivePayment(templateDataToReturn.type) || getIsMakePayment(templateDataToReturn.type)) {
            templateDataToReturn.lineItems = this.getPaymentItem(dataFromAPI, templateDataToReturn.type)
        }
        else if (getIsJournalEntry(templateDataToReturn.type)) {
            templateDataToReturn.lineItems = this.getJournalEntryItem(dataFromAPI, dateFormat)
        }
        else if (getIsPickList(templateDataToReturn.type)) {
            templateDataToReturn.lineItems = this.getPickItem(dataFromAPI)
        }
        else if (getIsPackList(templateDataToReturn.type)) {
            templateDataToReturn.lineItems = this.getPackItem(dataFromAPI)
        }
        else if (getIsShipList(templateDataToReturn.type)) {
            templateDataToReturn.lineItems = this.getShipItem(dataFromAPI)
        }
        else {
            templateDataToReturn.lineItems = dataFromAPI.lineItems.map((product, index) => {
                let modifiedProduct = product
                if (product.hsnOrSacCode !== null && product.hsnOrSacCode !== undefined && !isGeneratedData) {
                    modifiedProduct.hsnOrSacCodeString = '<br><b>HSN/SAC : </b>' + product.hsnOrSacCode
                }
                modifiedProduct.lineNumber = (product.lineNumber !== undefined && product.lineNumber !== null) ? product.lineNumber : index + 1
                modifiedProduct.code = product.code
                if (product.tax !== null && product.quantity !== null && product.unitPrice !== null) {
                    let total_amount = product.quantity * product.unitPrice
                    let total_discount = product.discount
                    if (product.discountInPercent && product.discountInPercent === true && !isGeneratedData) {
                        if(total_discount !== undefined && total_discount !== null) {
                            if (parseFloat(total_discount) > 0) {
                                modifiedProduct.discountPercent = modifiedProduct.discount
                                modifiedProduct.isDiscountInPercent = product.discountInPercent
                            }
                        }
                        if((product.taxInfo !== null) && dataFromAPI.priceInclusiveOfTax) {
                            total_discount = (ComponentManager.getTaxInclusiveSubtotalItemPrice(product) * product.discount) / 100
                        }else {
                            total_discount = (total_amount * product.discount) / 100
                        }
                        modifiedProduct.discount = total_discount
                    }
                    modifiedProduct.priceInclusiveOfTax = (product.taxInfo !== null) && dataFromAPI.priceInclusiveOfTax
                    modifiedProduct.discount = Number(product.discount)
                    modifiedProduct.tax = Number(product.tax)
                    if(product.uom !== undefined) {
                        modifiedProduct.uom = product.uom
                    }
                    else {
                        modifiedProduct.uom = 'N/A'
                    }
                }
                return modifiedProduct
            })
        }

        //additional Date (receive from / ship by)
        var showAdditionalDate = undefined
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.additionalDate !== undefined) {
            showAdditionalDate = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.additionalDate.isVisible
        }

        if (getIsQuotation(templateDataToReturn.type)
            || getIsSalesOrder(templateDataToReturn.type)
            || getIsInvoice(templateDataToReturn.type)) {
            if (dataFromAPI.shipByDate !== undefined && dataFromAPI.shipByDate !== null) {
                templateDataToReturn.additionalDate = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.shipByDate), dateFormat)
            }
            templateDataToReturn.showAdditionalDate = showAdditionalDate
        }
        if (getIsOrder(templateDataToReturn.type)) {
            if (dataFromAPI.receiveByDate !== undefined && dataFromAPI.receiveByDate !== null) {
                templateDataToReturn.additionalDate = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.receiveByDate), dateFormat)
            }
            templateDataToReturn.showAdditionalDate = showAdditionalDate
        }
        else if (getIsBill(templateDataToReturn.type) && !getIsExpenseBill(templateDataToReturn.type, templateDataToReturn.lineItems)) {
            if (dataFromAPI.receiveByDate !== undefined && dataFromAPI.receiveByDate !== null) {
                templateDataToReturn.additionalDate = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.receiveByDate), dateFormat)
            }
            templateDataToReturn.showAdditionalDate = showAdditionalDate
        }


        //Stock Items
        // templateDataToReturn.stockItems = [{
        //     "productCode": "P-0000023",
        //     "productName": "NetGear",
        //     "productDesc": "NetGear",
        //     "serialBatchNumber": "ng01",
        //     "sequence": 1,
        //     "subSequence": 1,
        //     "expiryDate": "1 Jan 2022",
        //     "manufacturingDate": "1 Jan 2021",
        //     "quantity": "1.0"
        // }]

        //stock items

        if (!getIsAccountModule(undefined, templateDataToReturn.type)
            && !getIsFulfillment(templateDataToReturn.type)
            && !getIsGoodsReceipt(templateDataToReturn.type)
            && !getIsMakePayment(templateDataToReturn.type)
            && !getIsReceivePayment(templateDataToReturn.type)
            && !getIsJournalEntry(templateDataToReturn.type)
            && !getIsPickList(templateDataToReturn.type)
            && !getIsPackList(templateDataToReturn.type)
            && !getIsShipList(templateDataToReturn.type)) {
            templateDataToReturn.stockItems = dataFromAPI.productTrackingInfo

            if (isReadOnlyMode) {
                var isContainStockItem = templateDataToReturn.stockItems.length > 0
                if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.stockTable === undefined) {
                    TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails['stockTable'] = { 'isVisible': true }
                }
                TemplateSettingsManager.updateStockTableVisibility(isContainStockItem)
                templateDataToReturn.showStockTable = isContainStockItem
            }
            else {
                templateDataToReturn.showStockTable = true
                TemplateSettingsManager.updateStockTableVisibility(true)
            }
        }
        //payment
        if (dataFromAPI.paymentLink === null || dataFromAPI.paymentLink === undefined) {
            templateDataToReturn.paymentItems = []
        }
        else if (dataFromAPI.paymentLink === "") {
            templateDataToReturn.paymentItems = []
        } else {
            templateDataToReturn.paymentItems = [dataFromAPI.paymentLink]
        }

        if (isReadOnlyMode) {
            var isContainPaymentItem = templateDataToReturn.paymentItems.length > 0
            if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.paymentTable === undefined) {
                TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails['paymentTable'] = { 'isVisible': true }
            }

            TemplateSettingsManager.updatePaymentTableVisibility(isContainPaymentItem)
            templateDataToReturn.showPaymentTable = isContainPaymentItem
        }
        else {
            if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.paymentTable === undefined) {
                TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails['paymentTable'] = { 'isVisible': true }
            }
            else {
                templateDataToReturn.showPaymentTable = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.paymentTable.isVisible
            }
        }

        //Custom fields
        if (dataFromAPI.tableColumnPopup !== undefined) {
            templateDataToReturn.tableColumnPopup = dataFromAPI.tableColumnPopup
        } else {
            if(dataFromAPI.appCustomizations && dataFromAPI.appCustomizations.customLabels && dataFromAPI.appCustomizations.customLabels.customLabelPair) {
                let columnData = dataFromAPI.appCustomizations.customLabels.customLabelPair
                let currentLangCode = templateDataToReturn.languageInfo.code
                if(currentLangCode === "id"){
                    currentLangCode = "bh"
                }
                columnData = columnData[currentLangCode] ? columnData[currentLangCode] : columnData['en']
                if(columnData) {
                    let docType = "BUY"
                    if(type === "invoice" || type === "quote"){
                        docType = "SELL"
                    }

                    let json_columnData = {};
                    if (getIsAccountModule(null, templateDataToReturn.type)) {
                        json_columnData[TableColumnPopupType.accountName] = columnData["ACCOUNTS.TITLE." + docType]
                        json_columnData[TableColumnPopupType.description] = columnData["ACCOUNTS.DESCRIPTIONS." + docType]
                        json_columnData[TableColumnPopupType.amount] = columnData["ACCOUNTS.AMOUNT." + docType]
                        json_columnData[TableColumnPopupType.tax] = columnData["ACCOUNTS.TAX." + docType]
                        json_columnData[TableColumnPopupType.taxAmount] = columnData["ACCOUNTS.TAX_AMOUNT." + docType]
                        json_columnData[TableColumnPopupType.paymentAmount] = columnData["ACCOUNTS.PAYMENT_AMOUNT." + docType]
                        json_columnData[TableColumnPopupType.totalTaxAmount] = columnData["AUTOCOMPLETE.TAX." + docType]
                    }
                    else {
                        json_columnData[TableColumnPopupType.code] = columnData["PRODUCTS.CODE." + docType] ? columnData["PRODUCTS.CODE." + docType] : 'sn_number'
                        json_columnData[TableColumnPopupType.productName] = columnData["PRODUCTS.TITLE." + docType]
                        json_columnData[TableColumnPopupType.description] = columnData["PRODUCTS.DESCRIPTIONS." + docType]
                        json_columnData[TableColumnPopupType.uom] = columnData["PRODUCTS.UOM." + docType] ? columnData["PRODUCTS.UOM." + docType] : TableColumnPopupType.uom
                        json_columnData[TableColumnPopupType.quantity] = columnData["PRODUCTS.QUANTITY." + docType]
                        json_columnData[TableColumnPopupType.unitPrice] = columnData["PRODUCTS.UNITPRICE." + docType]
                        json_columnData[TableColumnPopupType.productTax] = columnData["PRODUCTS.PRODUCT_TAX." + docType] ? columnData["PRODUCTS.PRODUCT_TAX." + docType] : "tax"
                        json_columnData[TableColumnPopupType.productDiscount] = columnData["PRODUCTS.PRODUCT_DISCOUNT." + docType] ? columnData["PRODUCTS.PRODUCT_DISCOUNT." + docType] : "discount"
                        json_columnData[TableColumnPopupType.discount] = columnData["PRODUCTS.DISCOUNT." + docType]
                        json_columnData[TableColumnPopupType.tax] = columnData["AUTOCOMPLETE.TAX." + docType]
                    }
                    if (TemplateSettingsManager.getRemoteTemplateUID() === null
                    || TemplateSettingsManager.getRemoteTemplateUID() === undefined ||
                    TemplateSettingsManager.getRemoteTemplateUID() === '' ) {
                        TemplateSettingsManager.updateTableInfo(json_columnData)
                    }

                }
            }

            if (getIsAccountModule(null, templateDataToReturn.type) || getIsExpenseBill(dataFromAPI.type, dataFromAPI.lineItems) || getIsMakePayment(templateDataToReturn.type) || getIsReceivePayment(templateDataToReturn.type)) {
                //remove existing non account option
                DataParser.removeTableColumnForAccount(templateDataToReturn)
                DataParser.updateAmountInWordsTableColumn(templateDataToReturn)
            }
            else if (getIsFulfillment(templateDataToReturn.type) || getIsGoodsReceipt(templateDataToReturn.type)) {
                if (getIsFulfillment(templateDataToReturn.type)) {
                    DataParser.updateMissingFulfillmentTableColumn(templateDataToReturn)
                }
                else {
                    DataParser.removeTableColumnForGoodReceipt(templateDataToReturn)
                }
                DataParser.updateMissingSignatureColumn()
            }
            else if (getIsJournalEntry(templateDataToReturn.type)
                || getIsPickList(templateDataToReturn.type)
                || getIsPackList(templateDataToReturn.type)
                || getIsShipList(templateDataToReturn.type)) {
            }
            else {
                DataParser.updateMissingTableColumn(templateDataToReturn)
                DataParser.updateAmountInWordsTableColumn(templateDataToReturn)
                DataParser.reorderTableColumnForProduct(templateDataToReturn)
            }

            templateDataToReturn.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo
        }

        //Signature
        templateDataToReturn.showSignature = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.signature.isVisible
        templateDataToReturn.signature = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.signature.image

        //Terms and Conditions
        templateDataToReturn.showTermsAndCondition = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.termsAndConditions.isVisible
        if (dataFromAPI.termsAndCondition) {
            templateDataToReturn.termsAndCondition = dataFromAPI.termsAndCondition
        } else {
            if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.termsAndConditions.text !== 'terms_and_condition_placeholder_text') {
                templateDataToReturn.termsAndCondition = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.termsAndConditions.text
            } else {
                templateDataToReturn.termsAndCondition = null
            }
        }

        //Notes
        templateDataToReturn.showNotes = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.notes.isVisible
        if (dataFromAPI.notes) {
            templateDataToReturn.notes = dataFromAPI.notes
        }
        else if (getIsJournalEntry(templateDataToReturn.type)) {
            if(dataFromAPI.journalEntry !== undefined && dataFromAPI.journalEntry !== null) {
                if (dataFromAPI.journalEntry.memo !== undefined && dataFromAPI.journalEntry.memo !== null) {
                    templateDataToReturn.notes = dataFromAPI.journalEntry.memo
                }
            }
        }
        else {
            if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.notes.text !== 'notes_placeholder_text') {
                templateDataToReturn.notes = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.notes.text
            } else {
                templateDataToReturn.notes = null
            }

        }
        //Component List
        templateDataToReturn.componentList = TemplateSettingsManager.defaultTemplateSettings.documentInfo.componentList
        //assign value to component list
        if (getIsFulfillment(templateDataToReturn.type)) {
            templateDataToReturn.customFields = dataFromAPI.fulfillment.customField
            CustomFieldManager.setCustomFields(dataFromAPI.fulfillment.customField)
        }
        else if (getIsGoodsReceipt(templateDataToReturn.type)) {
            templateDataToReturn.customFields = dataFromAPI.goodsReceipt.customField
            CustomFieldManager.setCustomFields(dataFromAPI.goodsReceipt.customField)
        }
        else if (getIsJournalEntry(templateDataToReturn.type)) {
            templateDataToReturn.customFields = dataFromAPI.journalEntry.customField
            CustomFieldManager.setCustomFields(dataFromAPI.journalEntry.customField)
        }
        else {
            templateDataToReturn.customFields = dataFromAPI.customFields
            CustomFieldManager.setCustomFields(dataFromAPI.customFields)
        }
        templateDataToReturn = this.assignCustomFieldValue(templateDataToReturn)

        //Client Email Ids
        templateDataToReturn.clientEmailIDs = dataFromAPI.contactInfo && dataFromAPI.contactInfo.emailId !== null ? [dataFromAPI.contactInfo.emailId]: []

        // templateDataToReturn.taxBreakdown =  [
        //     {
        //         "taxId": "164843",
        //         "taxName": "GST (5%) S",
        //         "taxAmount": "12.10"
        //     },
        //     {
        //         "taxId": "164846",
        //         "taxName": "QST-QC (9.975%) S",
        //         "taxAmount": "24.14"
        //     }
        // ]

        // templateDataToReturn.taxBreakdown =  []

        templateDataToReturn.taxBreakdown = dataFromAPI.taxBreakdown

        //get tax breakdown for IN
        var inTaxBreakdown = this.getIndiaTaxBreakdown(dataFromAPI)
        if (inTaxBreakdown.length > 0) {
            templateDataToReturn.taxBreakdown = inTaxBreakdown
        }

        //added TCS and TDS if those value exist
        templateDataToReturn.taxBreakdown = this.getTcsNTds(dataFromAPI, templateDataToReturn.taxBreakdown)

        templateDataToReturn.taxBreakdown = this.getRoundingOff(dataFromAPI, templateDataToReturn.taxBreakdown)

        //Missing Informations
        templateDataToReturn.billToName = dataFromAPI.billToName
        templateDataToReturn.shipToName = dataFromAPI.shipToName
        templateDataToReturn.billTo = dataFromAPI.billTo
        templateDataToReturn.shipTo = dataFromAPI.shipTo
        templateDataToReturn.contactInfo = dataFromAPI.contactInfo
        templateDataToReturn.decimalScale = dataFromAPI.decimalScale
        templateDataToReturn.billToAddress = dataFromAPI.billToAddress
        templateDataToReturn.shipToAddress = dataFromAPI.shipToAddress
        templateDataToReturn.shipFromAddress = dataFromAPI.shipFromAddress
        templateDataToReturn.appCustomizations = dataFromAPI.appCustomizations
        templateDataToReturn.priceInclusiveOfTax = dataFromAPI.priceInclusiveOfTax
        templateDataToReturn.paymentLink = dataFromAPI.paymentLink

        templateDataToReturn.accountName = dataFromAPI.accountName
        templateDataToReturn.referenceDate = dataFromAPI.referenceDate
        templateDataToReturn.paymentType = dataFromAPI.paymentType
        templateDataToReturn.payments = dataFromAPI.payments
        templateDataToReturn.referenceNumber = dataFromAPI.referenceNumber

        templateDataToReturn.isReadOnlyMode = false

        //add E invoice data
        templateDataToReturn.irn = dataFromAPI.irn
        templateDataToReturn.ackDt = dataFromAPI.ackDt
        templateDataToReturn.ackNo = dataFromAPI.ackNo
        templateDataToReturn.signedQRCode = dataFromAPI.signedQRCode

        //total and due amount
        templateDataToReturn.total = dataFromAPI.total
        templateDataToReturn.due = dataFromAPI.due
        templateDataToReturn.amountInWords = dataFromAPI.amountInWords
        templateDataToReturn.subTotal = dataFromAPI.subTotal
        templateDataToReturn.discount = dataFromAPI.discount
        templateDataToReturn.taxAmount = dataFromAPI.taxAmount
        templateDataToReturn.amountBeforeTax = dataFromAPI.amountBeforeTax


        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.eInvoiceSection === undefined) {
            TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails['eInvoiceSection'] = { 'isVisible': false }
        }
        else {
            templateDataToReturn.showEInvoiceSection = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.eInvoiceSection.isVisible
            if (templateDataToReturn.signedQRCode === undefined || templateDataToReturn.signedQRCode === null || templateDataToReturn.signedQRCode === '') {
                templateDataToReturn.showEInvoiceSection = false
            }
        }

        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.customFieldList === undefined) {
            this.updateMissingCustomFieldList()
        }
        else {
            this.updateDeletedCustomFieldList(templateDataToReturn.customFields)
        }

        var repeatableTableHeader = TemplateSettingsManager.defaultTemplateSettings.repeatableTableHeader
        if (repeatableTableHeader !== undefined && repeatableTableHeader !== null) {
            if (repeatableTableHeader.isVisible !== undefined && repeatableTableHeader.isVisible !== null) {
                TemplateSettingsManager.updateRepeatableTableHeader(repeatableTableHeader.isVisible)
            }
            else {
                TemplateSettingsManager.updateRepeatableTableHeader(false)
            }
        }
        else {
            TemplateSettingsManager.updateRepeatableTableHeader(false)
        }

        var repeatableHeaderFooter = TemplateSettingsManager.defaultTemplateSettings.repeatHeaderFooter
        if (repeatableHeaderFooter !== undefined && repeatableHeaderFooter !== null) {
            if (repeatableHeaderFooter.isVisible !== undefined && repeatableHeaderFooter.isVisible !== null) {
                TemplateSettingsManager.updateRepeatableHeaderFooterVisibility(repeatableHeaderFooter.isVisible)
            }
            else {
                TemplateSettingsManager.updateRepeatableHeaderFooterVisibility(false)
            }
            if (repeatableHeaderFooter.isFooterVisible !== undefined && repeatableHeaderFooter.isFooterVisible !== null) {
                TemplateSettingsManager.updateRepeatableFooterVisibility(repeatableHeaderFooter.isFooterVisible)
            }
            else {
                TemplateSettingsManager.updateRepeatableFooterVisibility(false)
            }
        }
        else {
            TemplateSettingsManager.updateRepeatableHeaderFooterVisibility(false)
            TemplateSettingsManager.updateRepeatableFooterVisibility(false)
        }

        if(Utility.getIsIndiaCountry(templateDataToReturn)) {
            if (getIsQuotation(templateDataToReturn.type)
                || getIsSalesOrder(templateDataToReturn.type)
                || getIsInvoice(templateDataToReturn.type)
                || getIsBill(templateDataToReturn.type)
                || getIsOrder(templateDataToReturn.type)) {
                templateDataToReturn = IndiaTaxParser.getTaxBreakdown(dataFromAPI, templateDataToReturn)
            }
        }

        return templateDataToReturn
    }

    static updateMissingCustomFieldList() {
        var draggableComponentList = []
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.componentList !== undefined) {
            if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.componentList.length > 0) {
                TemplateSettingsManager.defaultTemplateSettings.documentInfo.componentList.forEach(element => {
                    if (element.systemField !== null && element.systemField !== undefined) {
                        if (element.systemField !== '') {
                            draggableComponentList.push(element.systemField)
                        }
                    }
                });
            }
        }

        if(draggableComponentList.length === 0) {
            TemplateSettingsManager.updateCustomFieldList([])
        }
        else {
            var rowItemList = []
            CustomFieldManager.getCustomFields().forEach((element, index) => {
                var newRowItem = {}
                newRowItem.isSelected = draggableComponentList.includes(element.code)
                newRowItem.code = element.code
                newRowItem.index = index
                newRowItem.label = element.label
                newRowItem.value = element.value
                rowItemList.push(newRowItem)
            });

            TemplateSettingsManager.updateCustomFieldList(rowItemList)
        }
    }

    static updateDeletedCustomFieldList(customFields) {
        var newList = []
        var customFieldList = TemplateSettingsManager.defaultTemplateSettings.documentInfo.customFieldList
        var masterCustomFieldCodes = CustomFieldManager.getMaterCustomFieldCodes()

        if (masterCustomFieldCodes.length > 0) {
            //solve custom field is deleted from master
            customFieldList.forEach(element => {
                if (masterCustomFieldCodes.includes(element.code)) {
                    newList.push(element)
                }
            });
        }
        else {
            newList = customFieldList
        }
        //solve hidden custom field
        if (customFields !== undefined && customFields !== null ) {
            if (customFields.length > 0) {
                var apiCustomFieldCodes = customFields.map(x => x.code)
                if (newList.length > 0) {
                    newList.forEach(element => {
                        if (apiCustomFieldCodes.length === 0) {
                            element.isSelected = false
                        }
                        else if (element.isSelected) {
                            element.isSelected = apiCustomFieldCodes.includes(element.code)
                        }
                    });
                }
            }
        }
        TemplateSettingsManager.updateCustomFieldList(newList)
    }

    static getRoundingOff(dataFromAPI, taxBreakdown) {
        var newTaxBreakdown = []

        if (taxBreakdown !== undefined && taxBreakdown !== null) {
            if (taxBreakdown.length > 0) {
                newTaxBreakdown = taxBreakdown
            }
        }
        if (dataFromAPI.roundingOff !== undefined && dataFromAPI.roundingOff !== null) {
            if (Math.abs(Number(dataFromAPI.roundingOff)) > 0) {
                newTaxBreakdown.push(
                    {
                        "taxId": "Rounding Off",
                        "taxName": "Rounding Off",
                        "taxAmount": Number(dataFromAPI.roundingOff)
                    }
                );
            }
        }
        return newTaxBreakdown
    }

    static updateMissingTableColumn(templateDataToReturn) {
        templateDataToReturn.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        var columnDataList = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.lineNumber)) {
            var lineNumber = { "type": TableColumnPopupType.lineNumber, "isSelected": false, "name": "#" };
            columnDataList.splice(0, 0, lineNumber);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.code)) {
            var code = { "type": TableColumnPopupType.code, "isSelected": false, "name": "sn_number" };
            columnDataList.splice(0, 0, code);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.image)) {
            var image = { "type": TableColumnPopupType.image, "isSelected": false, "name": "image" };
            columnDataList.splice(0, 0, image);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.productNameWithDescription)) {
            var nameWithDescription = { "type": TableColumnPopupType.productNameWithDescription, "isSelected": false, "name": "name_with_description" };
            columnDataList.splice(2, 0, nameWithDescription);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.uom)) {
            var uom = { "type": TableColumnPopupType.uom, "isSelected": false, "name": "uom" };
            columnDataList.splice(3, 0, uom);
        }

        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.productTax)) {
            var tax = { "type": TableColumnPopupType.productTax, "isSelected": false, "name": "tax" };
            columnDataList.splice(6, 0, tax);
        }
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.productDiscount
        )) {
            var discount = { "type": TableColumnPopupType.productDiscount, "isSelected": false, "name": "discount" };
            columnDataList.splice(7, 0, discount);
        }

        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.amountBeforeTax
        )) {
            var amountBeforeTax = { "type": TableColumnPopupType.amountBeforeTax, "isSelected": false, "name": "amount_before_tax" };
            columnDataList.splice(8, 0, amountBeforeTax);
        }

        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.beforeTax
        )) {
            var beforeTax = { "type": TableColumnPopupType.beforeTax, "isSelected": false, "name": "before_tax" };
            columnDataList.splice(12, 0, beforeTax);
        }
    }

    static updateMissingFulfillmentTableColumn(templateDataToReturn) {
        templateDataToReturn.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        var columnDataList = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.serialBatch)) {
            var serialBatch = { "type": TableColumnPopupType.serialBatch, "isSelected": false, "name": "serial_batch" };
            columnDataList.splice(4, 0, serialBatch);
        }
    }

    static updateAmountInWordsTableColumn(templateDataToReturn) {
        templateDataToReturn.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        var columnDataList = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        if (!TemplateSettingsManager.defaultTemplateSettings.tableInfo.map(x => x.type).includes(TableColumnPopupType.amountInWords)) {
            var amountInWords = { "type": TableColumnPopupType.amountInWords, "isSelected": false, "name": "amount_in_words" };
            if (columnDataList.length > 0) {
                columnDataList.splice(columnDataList.length, 0, amountInWords);
            }
            else {
                columnDataList.splice(0, 0, amountInWords);
            }
        }
    }

    static updateMissingSignatureColumn() {
        var signatureColumn = TemplateSettingsManager.defaultTemplateSettings.signatureInfo;

        if(signatureColumn === undefined) {
            TemplateSettingsManager.setSignatureInfo(TemplateSettingsManager.getDefaultSignatureInfo())
        }
    }

    static reorderTableColumnForProduct(templateDataToReturn) {
        templateDataToReturn.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        var columnDataList = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        if (columnDataList !== undefined && columnDataList !== null) {
            if (columnDataList.length > 0) {
                var orderList = [
                    TableColumnPopupType.lineNumber,
                    TableColumnPopupType.code,
                    TableColumnPopupType.image,
                    TableColumnPopupType.productName,
                    TableColumnPopupType.description,
                    TableColumnPopupType.productNameWithDescription,
                    TableColumnPopupType.quantity,
                    TableColumnPopupType.uom,
                    TableColumnPopupType.unitPrice,
                    TableColumnPopupType.productDiscount,
                    TableColumnPopupType.productTax,
                    TableColumnPopupType.amountBeforeTax,
                    TableColumnPopupType.amount,
                    TableColumnPopupType.discount,
                    TableColumnPopupType.beforeTax,
                    TableColumnPopupType.tax,
                    TableColumnPopupType.amountInWords
                ]

                var newList = []
                orderList.forEach(element => {
                    var items = columnDataList.filter(x => x.type === element)
                    if(items.length > 0) {
                        let item = items[0]
                        if (item.type === TableColumnPopupType.productNameWithDescription && item.index === undefined) {
                            item.index = 11
                        }
                        if (item.type === TableColumnPopupType.amountInWords && item.index === undefined) {
                            item.index = 15
                        }
                        newList.push(item)
                    }
                });
                TemplateSettingsManager.defaultTemplateSettings.tableInfo = newList
                // columnDataList = newList
            }
        }
    }



    static removeTableColumnForAccount(templateDataToReturn) {
        templateDataToReturn.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        var columnDataList = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        if(columnDataList !== undefined && columnDataList !== null) {
            if(columnDataList.length > 0) {
                var newList = []
                columnDataList.forEach(element => {
                    if (element.type !== TableColumnPopupType.code &&
                        element.type !== TableColumnPopupType.uom &&
                        element.type !== TableColumnPopupType.productTax &&
                        element.type !== TableColumnPopupType.productDiscount &&
                        element.type !== TableColumnPopupType.amountBeforeTax ) {
                            newList.push(element)
                        }
                });
                columnDataList = newList
            }
        }
    }

    static removeTableColumnForGoodReceipt(templateDataToReturn) {
        templateDataToReturn.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        var columnDataList = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
        if (columnDataList !== undefined && columnDataList !== null) {
            if (columnDataList.length > 0) {
                var newList = []
                columnDataList.forEach(element => {
                    if (element.type !== TableColumnPopupType.serialBatch) {
                        newList.push(element)
                    }
                });
                columnDataList = newList
                TemplateSettingsManager.updateTablePopupInfo(newList)
            }
        }
    }

    static getAccountItem(data) {
        if(data.lineItems === undefined || data.lineItems.lineItems === null) {
            return []
        }

        var accountLineItems = []
        data.lineItems.forEach((element, index) => {
            var accountLineItem = element
            accountLineItem.lineNumber = index + 1
            accountLineItem.name = (element.name !== null) ? element.name : ''
            accountLineItem.description = (element.description !== null) ? element.description : ''
            accountLineItem.amount = (element.amount !== null) ? element.amount : 0.00

            if (element.tax !== null && element.tax !== undefined) {
                accountLineItem.taxAmount = element.tax
            }
            else {
                accountLineItem.tax = 0
                if (element.taxInfo !== null && element.taxInfo !== undefined) {
                    accountLineItem.tax = element.taxInfo.percent ? element.taxInfo.percent : 0
                }
                accountLineItem.taxAmount = Number(accountLineItem.amount) * (Number(accountLineItem.tax) / 100)
            }

            accountLineItem.paymentAmount = Number(accountLineItem.amount) + Number(accountLineItem.taxAmount)
            accountLineItems.push(accountLineItem)
        });

        return accountLineItems
    }

    static getPaymentItem(data, type) {
        if (data.lineItems === undefined || data.lineItems.lineItems === null) {
            return []
        }

        var paymentLineItems = []
        data.lineItems.forEach((element, index) => {
            var paymentLineItem = element
            paymentLineItem.lineNumber = index + 1
            paymentLineItem.snNumber = data.refNumber
            var parentDocumentTotalAmount = 0
            var parentDocumentDueAmount = 0
            if (data.parentDocument !== undefined && data.parentDocument !== null) {
                if (this.getDefaultValue(data.parentDocument.totalAmount) !== '') {
                    parentDocumentTotalAmount = this.getDefaultValue(data.parentDocument.totalAmount)
                }
                if (this.getDefaultValue(data.parentDocument.dueAmount) !== '') {
                    parentDocumentDueAmount = this.getDefaultValue(data.parentDocument.dueAmount)
                }
            }
            if(getIsMakePayment(type)) {
                paymentLineItem.billNumber = this.getDefaultValue(element.documentSequenceCode)
                paymentLineItem.totalBillAmount = parentDocumentTotalAmount
                paymentLineItem.currentBalance = parentDocumentDueAmount
            }
            if(getIsReceivePayment(type)) {
                paymentLineItem.invoiceNumber = this.getDefaultValue(element.documentSequenceCode)
                paymentLineItem.totalInvoiceAmount = parentDocumentTotalAmount
                paymentLineItem.currentDueAmount = parentDocumentDueAmount
            }
            paymentLineItem.paymentMade = element.amount
            paymentLineItem.paymentMedium = getCapitalized(this.getDefaultValue(data.paymentType).toLowerCase())
            paymentLineItems.push(paymentLineItem)
        });
        return paymentLineItems
    }

    static getFulfillmentItem(data, dateFormat) {
        if (data.fulfillment === undefined || data.fulfillment === null) {
            return []
        }

        if (data.fulfillment.fulfillmentItems === undefined || data.fulfillment.fulfillmentItems === null) {
            return []
        }

        var fulfillmentItems = []
        data.fulfillment.fulfillmentItems.forEach((element, index) => {
            var fulfillmentItem = {}
            fulfillmentItem.lineNumber = index + 1
            fulfillmentItem.code = this.getDefaultValue(element.documentSequenceCode)
            fulfillmentItem.name = this.getDefaultValue(element.productName)
            fulfillmentItem.description = this.getDefaultValue(element.productDescription)
            fulfillmentItem.uom = this.getDefaultValue(element.uomName) !== '' ? this.getDefaultValue(element.uomName) : 'N/A'
            fulfillmentItem.committedQuantity = this.getDefaultValue(element.uomFulfilledQuantity)
            fulfillmentItem.requiredQuantity = element.uomQuantityRequired !== null ? this.getDefaultValue(element.uomQuantityRequired) : this.getDefaultValue(element.uomFulfilledQuantity)
            fulfillmentItem.warehouseCode = this.getDefaultValue(element.warehouseCode)
            if (element.trackingInformation !== undefined && element.trackingInformation !== null) {
                if(element.trackingInformation.length > 0) {
                    var batchValue = []
                    element.trackingInformation.forEach(item => {
                        var value = item.serialBatchNumber
                        if (item.expiryDate !== null && item.expiryDate !== undefined) {
                            value = item.serialBatchNumber + ' (Exp dt: ' + getConvertedDate(convertDateFromERPFormatToDocFormat(item.expiryDate), dateFormat) + ')'
                        }
                        batchValue.push(value)
                    });
                    fulfillmentItem.serialBatch = batchValue.join('\n')
                }
            }
            fulfillmentItems.push(fulfillmentItem)
        });

        return fulfillmentItems
    }

    static getGoodsReceiptItem(data) {
        if (data.goodsReceipt === undefined || data.goodsReceipt === null) {
            return []
        }

        if (data.goodsReceipt.items === undefined || data.goodsReceipt.items === null) {
            return []
        }

        var goodsReceiptItems = []
        data.goodsReceipt.items.forEach((element, index) => {
            var goodsReceiptItem = {}
            goodsReceiptItem.lineNumber = index + 1
            goodsReceiptItem.code = this.getDefaultValue(element.documentSequenceCode)
            goodsReceiptItem.name = this.getDefaultValue(element.productName)
            if (data.parentDocumentData !== undefined && data.parentDocumentData !== null) {
                goodsReceiptItem.orderNo = this.getDefaultValue(data.parentDocumentData.documentCode)
            }
            goodsReceiptItem.description = this.getDefaultValue(element.productDescription)
            goodsReceiptItem.uom = this.getDefaultValue(element.uomName) !== '' ? this.getDefaultValue(element.uomName) : 'N/A'
            goodsReceiptItem.receivedQuantity = this.getDefaultValue(element.uomReceiptQuantity)
            goodsReceiptItem.requiredQuantity = element.uomQuantityRequired !== null ? this.getDefaultValue(element.uomQuantityRequired) : this.getDefaultValue(element.uomReceiptQuantity)
            goodsReceiptItems.push(goodsReceiptItem)
        });

        return goodsReceiptItems
    }

    static getJournalEntryItem(data, dateFormat) {
        if (data.journalEntry === undefined || data.journalEntry === null) {
            return []
        }

        if (data.journalEntry.lineItems === undefined || data.journalEntry.lineItems === null) {
            return []
        }

        var journalEntryNumber = ''
        var journalEntryDate = ''

        if (data.journalEntry.jeCode !== undefined && data.journalEntry.jeCode !== null ) {
            journalEntryNumber = data.journalEntry.jeCode
        }

        if (data.journalEntry.jeDate !== undefined && data.journalEntry.jeDate !== null) {
            journalEntryDate = data.journalEntry.jeDate
        }

        var sortedJournalEntryList = data.journalEntry.lineItems

        var journalEntryList_debit = []
        var journalEntryList_credit = []

        data.journalEntry.lineItems.forEach(element => {
            if (element.cdType === 'CREDIT') {
                journalEntryList_credit.push(element)
            }
            else if (element.cdType === 'DEBIT') {
                journalEntryList_debit.push(element)
            }
        });

        if(journalEntryList_credit.length > 0 || journalEntryList_debit.length > 0) {
            sortedJournalEntryList = []
            if(journalEntryList_debit.length > 0) {
                journalEntryList_debit.forEach(element => {
                    sortedJournalEntryList.push(element)
                });
            }
            if (journalEntryList_credit.length > 0) {
                journalEntryList_credit.forEach(element => {
                    sortedJournalEntryList.push(element)
                });
            }
        }

        var journalEntryItems = []
        sortedJournalEntryList.forEach((element, index) => {
            var journalEntryItem = {}
            journalEntryItem.lineNumber = index + 1
            journalEntryItem.date = journalEntryDate
            journalEntryItem.journalEntryNumber = journalEntryNumber
            journalEntryItem.accountCode = this.getDefaultValue(element.code)
            journalEntryItem.accountName = this.getDefaultValue(element.accountName)
            journalEntryItem.description = this.getDefaultValue(element.description)
            journalEntryItem.credit = 0
            journalEntryItem.debit = 0
            if (element.cdType !== undefined && element.cdType !== null
                & element.amount !== undefined && element.amount !== null) {
                if (element.cdType === 'CREDIT') {
                    journalEntryItem.credit = element.amount
                }
                else if (element.cdType === 'DEBIT') {
                    journalEntryItem.debit = element.amount
                }
            }

            journalEntryItems.push(journalEntryItem)
        });


        return journalEntryItems
    }

    static getPickItem(data) {

        if (this.getPayloadData(data) === undefined) {
            return []
        }

        if (this.getPayloadData(data).items === undefined || this.getPayloadData(data).items === null) {
            return []
        }

        var pickingItems = []
        this.getPayloadData(data).items.forEach((element, index) => {
            var pickingItem = {}
            pickingItem.lineNumber = index + 1
            pickingItem.invoiceOrQuotation = element.orderNo
            pickingItem.code = element.productCode
            pickingItem.name = this.getDefaultValue(element.name)
            pickingItem.barcode = this.getDefaultValue(element.barcode)
            pickingItem.quantityToPick = element.lq
            pickingItem.quantityForOrder = element.quantity
            pickingItem.picked = ''
            if (element.isPicked !== undefined && element.isPicked !== null) {
                pickingItem.picked = element.isPicked.toLowerCase() === 'yes' ? true : false
            }

            pickingItems.push(pickingItem)
        });

        return pickingItems
    }

    static getPackItem(data) {

        if (this.getPayloadData(data) === undefined) {
            return []
        }

        if (this.getPayloadData(data).items === undefined || this.getPayloadData(data).items === null) {
            return []
        }

        var packingItems = []
        this.getPayloadData(data).items.forEach((element, index) => {
            var packingItem = {}
            packingItem.lineNumber = index + 1
            packingItem.id = element.id
            packingItem.code = element.productCode
            packingItem.name = this.getDefaultValue(element.name)
            packingItem.barcode = this.getDefaultValue(element.barcode)
            packingItem.unit = element.uom
            packingItem.packedQuantity = element.paq

            packingItems.push(packingItem)
        });

        return packingItems
    }

    static getShipItem(data) {

        if (this.getPayloadData(data) === undefined) {
            return []
        }

        var item = this.getPayloadData(data)
        var shipItem = {
            delivery: item.delivery,
            carrier: item.carrier,
            numberOfCarton: item.n,
            weight: item.weight,
            trackingNumber: item.trackingNo
        }
        return [shipItem]
    }

    static get_CN_DN_Payment_Detail(data, isShared) {
        // if (isShared) {
        //     return data.clientShipToAddress
        // } else {
        if(data.accountName !== undefined && data.accountName !== null) {
            var detailString = ''
            detailString = data.accountName + '\n'
            if (data.paymentType !== undefined && data.accountName.toLowerCase() !== data.paymentType.toLowerCase()) {
                detailString = detailString + Utility.getPaymentMethod(data.paymentType)
            }
            if (data.referenceNumber !== undefined && data.referenceNumber !== null && data.referenceNumber !== '') {
                detailString = detailString + ' (' + data.referenceNumber + ') \n'
            }
            // if (data.due !== undefined && data.referenceNumber !== '') {
            //     detailString = detailString + ' (' + data.referenceNumber + ')' + '\n'
            // }
            return detailString
        }
        // }
    }

    static getTotalTaxAmount(accountLineItems) {
        if (accountLineItems === undefined || accountLineItems === null) {
            return 0
        }

        if (accountLineItems.length === 0) {
            return 0
        }

        var totalTaxAmount = 0

        accountLineItems.forEach(element => {
            totalTaxAmount += Number(element.taxAmount)
        });

        return totalTaxAmount
    }
    static IsOrderOrBill(type) {
        if (type === 'order' || type === 'bill') {
            return true
        }
        return false
    }

    static getApiType(type) {
        if(type !== undefined && type !== null) {
            switch (type.toLowerCase()) {
                case 'pick list':
                    return 'pick_list'
                case 'pack list':
                    return 'pack_list'
                case 'ship list':
                    return 'ship_list'
                default:
                    return type
            }
        }
        return ''
    }

    static getTemplateType(type) {
        if (type !== undefined && type !== null) {
            switch (type.toLowerCase()) {
                case 'quote':
                    return 'quotation'
                case 'debit name':
                    return 'debit_note'
                case 'credit note':
                    return 'credit_note'
                case 'receipt':
                    return 'receive_payment'
                case 'payment':
                    return 'make_payment'
                case 'pick list':
                    return 'pick_list'
                case 'pack list':
                    return 'pack_list'
                case 'ship list':
                    return 'ship_list'
                default:
                    return type.toLowerCase()
            }
        }
        return ''
    }


    static getTemplateDocumentType(defaultValue) {
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.type !== undefined && TemplateSettingsManager.defaultTemplateSettings.documentInfo.type !== null) {
            var type = TemplateSettingsManager.defaultTemplateSettings.documentInfo.type
            if(type.labelText !== undefined && type.labelText !== null ) {
                return type.labelText
            }
        }
        return defaultValue
    }

    static assignCustomFieldValue(data) {
        if(data.customFields === undefined || data.customFields === null) {
            return data
        }

        if (data.customFields.length === 0) {
            return data
        }

        data.componentList.forEach(component => {
            if (component.systemField !== undefined) {
                var customField = data.customFields.filter(x => x.code === component.systemField)
                if(customField.length > 0) {
                    component.text = customField[0].value
                    component.newText = customField[0].value
                }
            }
        });

        return data
    }


    static getIndiaTaxBreakdown(dataFromAPI) {
        var inTaxBreakdown = []

        if (this.getTaxValue(dataFromAPI.cgst) !== undefined) {
            inTaxBreakdown.push(
                {
                    "taxId": "CGST",
                    "taxName": "CGST",
                    "taxAmount": dataFromAPI.cgst
                }
            )
        }

        if (this.getTaxValue(dataFromAPI.igst) !== undefined) {
            inTaxBreakdown.push(
                {
                    "taxId": "IGST",
                    "taxName": "IGST",
                    "taxAmount": dataFromAPI.igst
                }
            )
        }

        if (this.getTaxValue(dataFromAPI.sgst) !== undefined) {
            inTaxBreakdown.push(
                {
                    "taxId": "SGST",
                    "taxName": "SGST",
                    "taxAmount": dataFromAPI.sgst
                }
            )
        }

        if (this.getTaxValue(dataFromAPI.cess) !== undefined) {
            inTaxBreakdown.push(
                {
                    "taxId": "CESS",
                    "taxName": "CESS",
                    "taxAmount": dataFromAPI.cess
                }
            )
        }

        return inTaxBreakdown
    }

    static getTcsNTds(dataFromAPI, taxBreakdown) {
        var newTaxBreakdown = []

        if (taxBreakdown !== undefined && taxBreakdown !== null ) {
            if (taxBreakdown.length > 0) {
                newTaxBreakdown = taxBreakdown
            }
        }

        if (this.getTaxValue(dataFromAPI.tcs) !== undefined) {
            newTaxBreakdown.push(
                {
                    "taxId": "TCS",
                    "taxName": "TCS",
                    "taxAmount": dataFromAPI.tcs
                }
            )
        }

        if (this.getTaxValue(dataFromAPI.tds) !== undefined) {
            newTaxBreakdown.push(
                {
                    "taxId": "TDS",
                    "taxName": "TDS",
                    "taxAmount": dataFromAPI.tds
                }
            )
        }

        return newTaxBreakdown
    }

    static getTaxValue(value) {
        if(value !== undefined && value !== null) {
            if(value.trim().length > 0) {
                var num = value.split(' ').join('').split(',').join('').split('.').join('')
                if (Number(num) > 0) {
                    return value
                }
            }
        }
        return undefined
    }

    static getDefaultValue(value) {
        if (value !== undefined && value !== null) {
            return value
        }
        return ''
    }

    static getRequiredQuantity(quantity, pending) {
        if (quantity !== undefined && quantity !== null && quantity !== undefined) {
            if (pending !== undefined && pending !== null) {
                return (quantity + pending)
            }
            return quantity
        }
        if (pending !== undefined && pending !== null && pending !== '') {
            return pending
        }
        return 0
    }

    static getPayloadData(data) {
        if(data.payload === undefined || data.payload === null) {
            return undefined
        }

        if(data.payload.length === 0) {
            return undefined
        }

        return data.payload[0]
    }

    static getPickPackShipDateFormat(data, dateFormat) {
        var defaultDateFormat = dateFormat
        if(data.tenantInfo !== undefined && data.tenantInfo !== null) {
            if (data.tenantInfo.dateFormat !== undefined && data.tenantInfo.dateFormat !== null) {
                defaultDateFormat = data.tenantInfo.dateFormat.toLowerCase()
                if (defaultDateFormat === 'd mmm yyyy') {
                    defaultDateFormat = DateFormat.DDMMMYYYY
                }
            }
        }
        return defaultDateFormat
    }

    static getSaudiArabiaAddress(dataFromAPI, templateDataToReturn) {
        if (dataFromAPI.country === 'SA') {
            //Company Name
            var name = templateDataToReturn.companyName
            if (this.IsOrderOrBill(templateDataToReturn.type)) {
                if (dataFromAPI.contactNameInArabic !== undefined && dataFromAPI.contactNameInArabic !== null) {
                    name = dataFromAPI.contactNameInArabic
                }
            }
            else {
                if (dataFromAPI.companyNameInArabic !== undefined && dataFromAPI.companyNameInArabic !== null) {
                    name = dataFromAPI.companyNameInArabic
                }
            }

            templateDataToReturn.companyNameInArabic = templateDataToReturn.companyName
            if (name !== templateDataToReturn.companyName) {
                if (name.length > 0) {
                    templateDataToReturn.companyNameInArabic = name
                }
            }

            //contact address
            var contactNameInArabic = ''
            if (this.IsOrderOrBill(templateDataToReturn.type)) {
                if (dataFromAPI.companyNameInArabic !== undefined && dataFromAPI.companyNameInArabic !== null) {
                    contactNameInArabic = dataFromAPI.companyNameInArabic
                }
            }
            else {
                if (dataFromAPI.contactNameInArabic !== undefined && dataFromAPI.contactNameInArabic !== null) {
                    contactNameInArabic = dataFromAPI.contactNameInArabic
                }
            }

            if (contactNameInArabic !== '') {
                templateDataToReturn.contactNameInArabic = contactNameInArabic
            }
            //Company Address
            var fromAddressInArabicObj = undefined
            var preferAddress = undefined
            if (getIsAccountModule(null, templateDataToReturn.type) || getIsMakePayment(templateDataToReturn.type) || getIsReceivePayment(templateDataToReturn.type)) {
                if (dataFromAPI.tenantInfoInArabic !== undefined && dataFromAPI.tenantInfoInArabic !== null) {
                    if (dataFromAPI.tenantInfoInArabic.billingAddresses !== undefined && dataFromAPI.tenantInfoInArabic.billingAddresses !== null) {
                        preferAddress = AddressParser.getPreferAddress(dataFromAPI.tenantInfoInArabic.billingAddresses)
                        if (preferAddress !== undefined) {
                            fromAddressInArabicObj = new Address(undefined, preferAddress, dataFromAPI.pan, dataFromAPI.gstin)
                        }
                    }
                }
            }
            else if (getIsFulfillment(templateDataToReturn.type) || getIsGoodsReceipt(templateDataToReturn.type) || getIsJournalEntry(templateDataToReturn.type)) {
                if (dataFromAPI.tenantInfoInArabic !== undefined && dataFromAPI.tenantInfoInArabic !== null) {
                    if (dataFromAPI.tenantInfoInArabic.address !== undefined && dataFromAPI.tenantInfoInArabic.address !== null) {
                        fromAddressInArabicObj = new Address(undefined, dataFromAPI.tenantInfoInArabic.address, undefined, undefined)
                    }
                }
            }
            else {
                if (this.IsOrderOrBill(templateDataToReturn.type)) {
                    fromAddressInArabicObj = new Address(undefined, dataFromAPI.shipFromAddressInArabic, dataFromAPI.contactInfo.pan, dataFromAPI.contactInfo.gstin)
                }
                else {
                    fromAddressInArabicObj = new Address(undefined, dataFromAPI.shipFromAddressInArabic, dataFromAPI.pan, dataFromAPI.gstin)
                    if (dataFromAPI.tenantInfoInArabic !== undefined && dataFromAPI.tenantInfoInArabic !== null) {
                        if (dataFromAPI.tenantInfoInArabic.billingAddresses !== undefined && dataFromAPI.tenantInfoInArabic.billingAddresses !== null) {
                            preferAddress = AddressParser.getPreferAddress(dataFromAPI.tenantInfoInArabic.billingAddresses)
                            if (preferAddress !== undefined) {
                                fromAddressInArabicObj = new Address(undefined, preferAddress, dataFromAPI.pan, dataFromAPI.gstin)
                            }
                        }
                    }
                }
            }

            templateDataToReturn.fromInArabicObj = fromAddressInArabicObj
            if (fromAddressInArabicObj !== undefined && fromAddressInArabicObj !== null) {
                templateDataToReturn = AddressParser.assignAddressObjToStringForArabic(undefined, AddressType.from, templateDataToReturn)
            }
            else {
                templateDataToReturn.fromInArabicObj = templateDataToReturn.fromObj
                templateDataToReturn.fromInArabic = templateDataToReturn.from
            }

            //billing address
            var clientBillToAddressObj = undefined
            if (getIsAccountModule(null, templateDataToReturn.type) || getIsMakePayment(templateDataToReturn.type) || getIsReceivePayment(templateDataToReturn.type)) {
                if (dataFromAPI.contactInfoInArabic !== undefined && dataFromAPI.contactInfoInArabic !== null) {
                    if (dataFromAPI.contactInfoInArabic.billingAddress !== undefined && dataFromAPI.contactInfoInArabic.billingAddress !== null) {
                        clientBillToAddressObj = new Address(dataFromAPI.clientName, dataFromAPI.contactInfoInArabic.billingAddress, undefined, undefined)
                    }
                }
            }
            else if (getIsFulfillment(templateDataToReturn.type) || getIsGoodsReceipt(templateDataToReturn.type)) {
                if (dataFromAPI.parentDocumentData !== undefined || dataFromAPI.parentDocumentData !== null) {
                    if (dataFromAPI.parentDocumentData.shipTo !== undefined || dataFromAPI.parentDocumentData.shipTo !== null) {
                        clientBillToAddressObj = new Address(undefined, dataFromAPI.parentDocumentData.shipTo, dataFromAPI.pan, dataFromAPI.gstin)
                    }
                }
            }
            else if (getIsJournalEntry(templateDataToReturn.type)) {
                //remain empty
            }
            else {
                clientBillToAddressObj = this.IsOrderOrBill(templateDataToReturn.type) === true ?
                    new Address(templateDataToReturn.contactNameInArabic, dataFromAPI.billToAddressInArabic, dataFromAPI.pan, dataFromAPI.gstin) :
                    new Address(templateDataToReturn.contactNameInArabic, dataFromAPI.billToAddressInArabic, dataFromAPI.contactInfo.pan, dataFromAPI.contactInfo.gstin)
            }

            templateDataToReturn.clientBillToAddressInArabicObj = clientBillToAddressObj
            if (clientBillToAddressObj !== undefined && clientBillToAddressObj !== null) {
                templateDataToReturn = AddressParser.assignAddressObjToStringForArabic(undefined, AddressType.billTo, templateDataToReturn)
            }
            else {
                templateDataToReturn.billToInArabic = templateDataToReturn.billTo
                templateDataToReturn.clientBillToAddressInArabic = templateDataToReturn.clientBillToAddress
            }

            //Ship To Address
            var clientShipToAddressObj = undefined
            if (getIsExpenseOrDeposit(dataFromAPI.type) || getIsMakePayment(templateDataToReturn.type) || getIsReceivePayment(templateDataToReturn.type)) {
                //using same method as non arabic
            }
            else if (getIsFulfillment(templateDataToReturn.type) || getIsGoodsReceipt(templateDataToReturn.type)) {
                if (dataFromAPI.parentDocumentData !== undefined || dataFromAPI.parentDocumentData !== null) {
                    if (dataFromAPI.parentDocumentData.shipFromInArabic !== undefined || dataFromAPI.parentDocumentData.shipFromInArabic !== null) {
                        clientShipToAddressObj = new Address(undefined, dataFromAPI.parentDocumentData.shipFromInArabic, dataFromAPI.pan, dataFromAPI.gstin)
                    }
                }
            }
            else if (getIsJournalEntry(templateDataToReturn.type)) {
                //remain empty
            }
            else {
                clientShipToAddressObj = this.IsOrderOrBill(templateDataToReturn.type) === true ?
                    new Address(templateDataToReturn.contactNameInArabic, dataFromAPI.shipToAddressInArabic, dataFromAPI.pan, dataFromAPI.gstin) :
                    new Address(templateDataToReturn.contactNameInArabic, dataFromAPI.shipToAddressInArabic, dataFromAPI.contactInfo.pan, dataFromAPI.contactInfo.gstin)
            }


            templateDataToReturn.clientShipToAddressInArabicObj = clientShipToAddressObj
            if (clientShipToAddressObj !== undefined && clientShipToAddressObj !== null) {
                templateDataToReturn = AddressParser.assignAddressObjToStringForArabic(undefined, AddressType.shipTo, templateDataToReturn)
            }
            else {
                templateDataToReturn.shipToInArabic = templateDataToReturn.shipTo
                templateDataToReturn.clientShipToAddressInArabic = templateDataToReturn.clientShipToAddress
            }
        }
        return templateDataToReturn
    }
}
