import { useState, useEffect, Fragment } from 'react';
import { DKSidebar, DKIcons } from 'deskera-ui-library';
import ic_quotation from '../../assets/menu/ic_quotation.png';
import ic_order from '../../assets/menu/ic_order.svg';
import ic_sales_order from '../../assets/menu/ic_sales_order.svg';
import ic_paymentRecord from '../../assets/icons/payment_records.png';
import ic_invoice from '../../assets/menu/ic_invoice.png';
import SideBarService from '../../services/common/sidebar';
import { isViewportMobile } from '../../utility/ViewportSize';
import { useHistory } from 'react-router-dom';
import {
  COUNTRY_CODES,
  DEFAULT_THEME_COLOR,
  PAGE_ROUTES
} from '../../constants/Constant';
import { useAppSelector } from '../../store/hooks';
import {
  selectTenantSettings,
  selectUserInfo
} from '../../store/slices/authSlice';
import { selectIsSOUnable } from '../../store/slices/booksSlice';

export default function LeftMenu() {
  const [isMenuExpanded, setIsMenuExpanded] = useState(true);
  const history = useHistory();
  const settings = useAppSelector(selectTenantSettings);
  const userInfo = useAppSelector(selectUserInfo);
  const isSOEnable = useAppSelector(selectIsSOUnable);

  useEffect(() => {
    window.addEventListener('onSideBarChange', handleSidebarToggle);
    window.addEventListener('resize', handleSidebarOnResize);

    return () => {
      window.removeEventListener('onSideBarChange', handleSidebarToggle);
      window.removeEventListener('resize', handleSidebarOnResize);
    };
  }, []);

  useEffect(() => {
    const appBgColor = settings?.theme || DEFAULT_THEME_COLOR;
    const rootElement = document.querySelector(':root') as any;
    rootElement?.style.setProperty('--app-theme', appBgColor);
  }, [settings]);

  const handleSidebarToggle = () => {
    setIsMenuExpanded(SideBarService.isSideBarExpanded);
  };

  const handleSidebarOnResize = () => {
    SideBarService.setIsSideBarExpanded();
    SideBarService.triggerSidebarChangeEvent();
  };

  const onMenuItemClick = (route: any) => {
    if (isViewportMobile()) {
      SideBarService.toggleSideBar();
      handleSidebarToggle();
    }

    history.push(route);
  };

  const getMenuItems = () => {
    const items = [];
    items.push({
      name: 'Invoices',
      icon: ic_invoice,
      route: PAGE_ROUTES.INVOICES,
      onClick: onMenuItemClick
    });
    items.push({
      name: userInfo?.country === COUNTRY_CODES.US ? 'Estimate' : 'Quotes',
      icon: ic_quotation,
      route: PAGE_ROUTES.QUOTATIONS,
      onClick: onMenuItemClick
    });

    // items.push({
    //   name: 'Purchase Order',
    //   icon: ic_order,
    //   route: PAGE_ROUTES.PURCHASE_ORDER,
    //   onClick: onMenuItemClick
    // });

    if (isSOEnable) {
      items.push({
        name: 'My Orders',
        icon: ic_sales_order,
        route: PAGE_ROUTES.SALES_ORDER,
        onClick: onMenuItemClick
      });

      items.push({
        name: 'Products',
        icon: DKIcons.white.ic_product,
        route: PAGE_ROUTES.PRODUCTS,
        onClick: onMenuItemClick
      });
    }

    items.push({
      name: 'Attachments',
      icon: DKIcons.white.ic_attachment,
      route: PAGE_ROUTES.ATTACHMENTS,
      onClick: onMenuItemClick
    });

    items.push({
      name: 'Payment Receipts',
      icon: ic_paymentRecord,
      route: PAGE_ROUTES.PAYMENT_RECEIPTS,
      onClick: onMenuItemClick
    });

    return items;
  };

  return (
    <Fragment>
      <DKSidebar
        className="dk-client-portal-sidebar"
        customHeaderRenderer={() => {}}
        style={{
          backgroundColor: `${settings?.theme || DEFAULT_THEME_COLOR}`
        }}
        allowTenantSelection={false}
        collapsedWidth={70}
        expandedWidth={230}
        menuItemList={getMenuItems()}
        isMenuExpanded={isMenuExpanded}
        tenantList={[]}
        selectedTenantDetails={{
          tenantName: settings?.companyName || 'Company Name'
        }}
        onSaveNewTenantTapped={() => {}}
        onTenantSelect={() => {}}
        onExpandCollapse={() => setIsMenuExpanded(!isMenuExpanded)}
      />
    </Fragment>
  );
}
