import { isViewportLarge } from '../../utility/ViewportSize';
import {
  getItemFromLocalStorage,
  setItemInLocalStorage
} from '../../utility/LocalStorage';
import { LOCAL_STORAGE_KEYS } from '../../constants/Enum';

class SideBarService {
  static sideBarExpandedWidth = 230;
  static sideBarCollapsedWidth = 0;
  static isSideBarExpanded = true;

  static setIsSideBarExpanded() {
    if (isViewportLarge()) {
      SideBarService.isSideBarExpanded = true;
    } else {
      SideBarService.isSideBarExpanded =
        getItemFromLocalStorage(
          LOCAL_STORAGE_KEYS.IS_SIDE_BAR_EXPANDED
        )?.toString() === 'true' || false;
    }
  }

  static triggerSidebarChangeEvent() {
    dispatchEvent(
      new CustomEvent('onSideBarChange', {
        detail: { isExpanded: SideBarService.isSideBarExpanded }
      })
    );
  }

  static toggleSideBar = () => {
    SideBarService.isSideBarExpanded = !SideBarService.isSideBarExpanded;
    SideBarService.triggerSidebarChangeEvent();
    setItemInLocalStorage(
      LOCAL_STORAGE_KEYS.IS_SIDE_BAR_EXPANDED,
      SideBarService.isSideBarExpanded
    );
  };

  static getContainerWidth = (): number => {
    if (isViewportLarge()) {
      return window.innerWidth - SideBarService.sideBarExpandedWidth - 48;
    } else {
      return (
        window.innerWidth * 0.92
      ); /* as 4% padding present on mainContainer */
    }
  };

  static getCurrentMenuWidth = (): number => {
    return SideBarService.isSideBarExpanded
      ? SideBarService.sideBarExpandedWidth
      : SideBarService.sideBarCollapsedWidth;
  };
}

export default SideBarService;
