import { TableColumnPopupType } from "../../../Components/Popup/TableColumnPopup";
import AppManager from "../../../Manager/AppManager";
import { getLocalisedText } from "../../../Translate/LanguageManager";
import TableFooterUtility from "../../../Utilities/TableFooterUtility";
import Utility, { getIsBill, getIsExpenseOrDeposit } from "../../../Utilities/Utility";
import { getSortedHeaderType } from "../TableUtility";

export class AccountTableItem {
    name: string;
    description: string;
    tax: string;
    taxAmount: string;
    paymentAmount: string;
    amount: string;
    currency: string;
    rawItemValue: any;
    constructor(accountItem: any, currency: string) {
        this.name = accountItem.name
        this.description = accountItem.description
        this.tax = accountItem.tax
        this.taxAmount = accountItem.taxAmount ? accountItem.taxAmount : '0.00'
        this.paymentAmount = accountItem.paymentAmount ? accountItem.paymentAmount : '0.00'
        this.amount = accountItem.amount ? accountItem.amount : '0.00'
        this.currency = currency
        this.rawItemValue = accountItem
    }


    getTaxName(): string {
        if (this.rawItemValue.taxInfo !== undefined && this.rawItemValue.taxInfo !== null) {
            if (this.rawItemValue.taxInfo.name !== undefined && this.rawItemValue.taxInfo.name !== null) {
                return this.rawItemValue.taxInfo.name
            }
        }
        return ''
    }
    toString(documentType: string, tableConfig?: any) {
        var newList = []
        var sortedHeader = getSortedHeaderType(tableConfig);
        if (sortedHeader.length > 0) {
            var sortedValue: string[] = []
            sortedHeader.forEach(type => {
                var docType = 'account'
                if (AppManager.getDocumentType() !== undefined) {
                    if (getIsBill(AppManager.getDocumentType())) {
                        docType = 'expenseBill'
                    }
                }
                var footerList = TableFooterUtility.getFooterListByType(docType)
                if (!footerList.includes(type)) {
                    sortedValue.push(this.getValueByType(type))
                }
            });
            return sortedValue
        }

        if (getIsExpenseOrDeposit(documentType)) {
            newList = this.getDefaultDepositAndExpenseList()
        }
        else {
            newList = this.getDefaultCNAndDNList()
        }

        return newList
    }

    getValueByType(type: string) {
        switch (type) {
            case TableColumnPopupType.accountName:
                return getLocalisedText(this.name)
            case TableColumnPopupType.description:
                return getLocalisedText(this.description)
            case TableColumnPopupType.amount:
                return Utility.toCurrencyFormat(this.amount, this.currency)
            case TableColumnPopupType.tax:
                return this.getTaxName()
            case TableColumnPopupType.taxAmount:
                return Utility.toCurrencyFormat(this.taxAmount, this.currency)
            case TableColumnPopupType.paymentAmount:
                return Utility.toCurrencyFormat(this.paymentAmount, this.currency)
            default:
                return ''
        }
    }

    getDefaultDepositAndExpenseList(){
        return [
            getLocalisedText(this.name),
            getLocalisedText(this.description),
            Utility.toCurrencyFormat(this.amount, this.currency),
            this.getTaxName(),
            Utility.toCurrencyFormat(this.taxAmount, this.currency),
            Utility.toCurrencyFormat(this.paymentAmount, this.currency),
        ]
    }

    getDefaultCNAndDNList() {
        return [
            getLocalisedText(this.name),
            Utility.toCurrencyFormat(this.amount, this.currency),
            this.getTaxName(),
            Utility.toCurrencyFormat(this.taxAmount, this.currency),
            Utility.toCurrencyFormat(this.paymentAmount, this.currency),
        ]
    }
}
