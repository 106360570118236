import { TableColumnPopupType } from "../../../Components/Popup/TableColumnPopup";
import { getIsShowTableColumn } from "../../../Utilities/Utility";

export class StockTransferTableWidth {
    isLineNumberVisible: boolean;
    isCodeVisible: boolean;
    isNameVisible: boolean;
    isDescriptionVisible: boolean;
    isNameWithDescriptionVisible: boolean;
    isSourceWarehouseNameVisible: boolean;
    isSourceWarehouseCodeVisible: boolean;
    isDestinationWarehouseNameVisible: boolean;
    isDestinationWarehouseCodeVisible: boolean;
    isHsnVisible: boolean;
    isProductCustomFieldVisible: boolean;
    isQuantityVisible: boolean;
    isUomVisible: boolean;
    isQuantityWithUomVisible: boolean;
    isSerialBatchVisible: boolean;
    isSerialNumberVisible: boolean;
    isBatchNumberVisible: boolean;
    isManufacturingDateVisible: boolean;
    isExpiryDateVisible: boolean;

    lineNumber: number;
    code: number;
    name: number;
    description: number;
    nameWithDescription: number;
    sourceWarehouseName: number;
    sourceWarehouseCode: number;
    destinationWarehouseName: number;
    destinationWarehouseCode: number;
    hsn: number;
    productCustomField: number;
    quantity: number;
    uom: number;
    quantityWithUom: number;
    serialBatch: number;
    serialNumber: number;
    batchNumber: number;
    manufacturingDate: number;
    expiryDate: number;

    originalLineNumber: number;
    originalCode: number;
    originalName: number;
    originalDescription: number;
    originalNameWithDescription: number;
    originalSourceWarehouseName: number;
    originalSourceWarehouseCode: number;
    originalDestinationWarehouseName: number;
    originalDestinationWarehouseCode: number;
    originalHsn: number;
    originalProductCustomField: number;
    originalQuantity: number;
    originalUom: number;
    originalQuantityWithUom: number;
    originalSerialBatch: number;
    originalSerialNumber: number;
    originalBatchNumber: number;
    originalManufacturingDate: number;
    originalExpiryDate: number;

    productCustomFieldList: any;

    constructor() {
        this.isCodeVisible = true;
        this.isNameVisible = true;
        this.isDescriptionVisible = true;
        this.isNameWithDescriptionVisible = true;
        this.isSourceWarehouseNameVisible = true;
        this.isSourceWarehouseCodeVisible = true;
        this.isDestinationWarehouseNameVisible = true;
        this.isDestinationWarehouseCodeVisible = true;
        this.isHsnVisible = false;
        this.isProductCustomFieldVisible = true;
        this.isQuantityVisible = true;
        this.isUomVisible = true;
        this.isQuantityWithUomVisible = true;
        this.isSerialBatchVisible = true;
        this.isSerialNumberVisible = true;
        this.isBatchNumberVisible = true;
        this.isManufacturingDateVisible = true;
        this.isExpiryDateVisible = true;
        this.isLineNumberVisible = true;

        this.lineNumber = 3;
        this.code = 6;
        this.name = 11.5;
        this.description = 16;
        this.nameWithDescription = this.name + this.description;
        this.hsn = 5;
        this.sourceWarehouseName = 5;
        this.sourceWarehouseCode = 5;
        this.destinationWarehouseName = 5;
        this.destinationWarehouseCode = 5;
        this.productCustomField = 5;
        this.quantity = 5.5;
        this.uom = 4.5;
        this.quantityWithUom = this.quantity + this.uom;
        this.serialBatch = 14;
        this.serialNumber = 3;
        this.batchNumber = 3;
        this.manufacturingDate = 4;
        this.expiryDate = 4;

        this.originalLineNumber = 3;
        this.originalCode = 6;
        this.originalName = 11.5;
        this.originalDescription = 16;
        this.originalNameWithDescription =
            this.originalName + this.originalDescription;
        this.originalSourceWarehouseName = 5;
        this.originalSourceWarehouseCode = 5;
        this.originalDestinationWarehouseName = 5;
        this.originalDestinationWarehouseCode = 5;
        this.originalHsn = 5;
        this.originalProductCustomField = 5;
        this.originalQuantity = 5.5;
        this.originalUom = 4.5;
        this.originalQuantityWithUom = this.originalQuantity + this.originalUom;
        this.originalSerialBatch = 14;
        this.originalSerialNumber = 3;
        this.originalBatchNumber = 3;
        this.originalManufacturingDate = 4;
        this.originalExpiryDate = 4;

        this.productCustomFieldList = undefined;
    }

    setIsShowWidth(columnData: any) {
        this.isLineNumberVisible = getIsShowTableColumn(
            columnData,
            TableColumnPopupType.lineNumber
        );
        this.isCodeVisible = getIsShowTableColumn(
            columnData,
            TableColumnPopupType.code
        );
        this.isNameVisible = getIsShowTableColumn(
            columnData,
            TableColumnPopupType.productName
        );
        this.isDescriptionVisible = getIsShowTableColumn(
            columnData,
            TableColumnPopupType.description
        );
        this.isNameWithDescriptionVisible = getIsShowTableColumn(
            columnData,
            TableColumnPopupType.productNameWithDescription
        );
        this.isHsnVisible = getIsShowTableColumn(
            columnData,
            TableColumnPopupType.hsn
        );
        this.isSourceWarehouseNameVisible = getIsShowTableColumn(
            columnData,
            TableColumnPopupType.sourceWarehouseName
        );
        this.isSourceWarehouseCodeVisible = getIsShowTableColumn(
            columnData,
            TableColumnPopupType.sourceWarehouseCode
        );
        this.isDestinationWarehouseNameVisible = getIsShowTableColumn(
            columnData,
            TableColumnPopupType.destinationWarehouseName
        );
        this.isDestinationWarehouseCodeVisible = getIsShowTableColumn(
            columnData,
            TableColumnPopupType.destinationWarehouseCode
        );
        this.isProductCustomFieldVisible = getIsShowTableColumn(
            columnData,
            TableColumnPopupType.productCustomField
        );
        this.isQuantityVisible = getIsShowTableColumn(
            columnData,
            TableColumnPopupType.quantity
        );
        this.isUomVisible = getIsShowTableColumn(
            columnData,
            TableColumnPopupType.uom
        );
        this.isQuantityWithUomVisible = getIsShowTableColumn(
            columnData,
            TableColumnPopupType.quantityWithUom
        );
        this.isSerialBatchVisible = getIsShowTableColumn(
            columnData,
            TableColumnPopupType.serialBatch
        );
        this.isSerialNumberVisible = getIsShowTableColumn(
            columnData,
            TableColumnPopupType.serialNumber
        );
        this.isBatchNumberVisible = getIsShowTableColumn(
            columnData,
            TableColumnPopupType.batchNumber
        );
        this.isManufacturingDateVisible = getIsShowTableColumn(
            columnData,
            TableColumnPopupType.manufacturingDate
        );
        this.isExpiryDateVisible = getIsShowTableColumn(
            columnData,
            TableColumnPopupType.expiryDate
        );

        var productCustomFieldList = columnData.filter(
            (x: any) => x.code !== undefined
        );
        if (productCustomFieldList.length > 0) {
            this.isProductCustomFieldVisible = false;
            if (this.productCustomFieldList === undefined) {
                //assign product custom list with corresponding flag
                this.productCustomFieldList = [];
                var width = 5;
                var maxWidth = 20;
                if (productCustomFieldList.length > 4) {
                    width = maxWidth / productCustomFieldList.length;
                }
                productCustomFieldList.forEach((element: any) => {
                    var item = {} as any;
                    item.code = element.code;
                    item.isVisible = element.columnOption.isShowColumn;
                    item.originalWidth = width;
                    item.width = width;
                    item.type = element.name;
                    item.label = element.label;
                    this.productCustomFieldList.push(item);
                });
            } else {
                this.productCustomFieldList.forEach((element: any) => {
                    var item = columnData.find((x: any) => x.code === element.code);
                    if (item !== undefined) {
                        element.isVisible = item.columnOption.isShowColumn;
                    }
                });
            }
        }
    }
    getTableHeaderWidths() {
        var totalWidth = 0;
        totalWidth = this.getTotalWidth(totalWidth);
        if (totalWidth === 100) {
            this.resetElementOriginalWidth();
        } else if (totalWidth === 0) {
            this.resetElementWidth();
        } else {
            var totalSelectedElement = this.getTotalSelectedElement();
            if (totalSelectedElement !== 0) {
                var totalOffset = (100 - totalWidth) / totalSelectedElement;
                this.updateElementWidth(totalOffset);
            }
        }
    }

    private resetElementOriginalWidth() {
        this.code = this.originalCode;
        this.name = this.originalName;
        this.description = this.originalDescription;
        this.nameWithDescription = this.originalNameWithDescription;
        this.sourceWarehouseName = this.originalSourceWarehouseName;
        this.sourceWarehouseCode = this.originalSourceWarehouseCode;
        this.destinationWarehouseName = this.originalDestinationWarehouseName;
        this.destinationWarehouseCode = this.originalDestinationWarehouseCode;
        this.hsn = this.originalHsn;
        this.productCustomField = this.originalProductCustomField;
        this.quantity = this.originalQuantity;
        this.uom = this.originalUom;
        this.quantityWithUom = this.originalQuantityWithUom;
        this.serialBatch = this.originalSerialBatch;
        this.serialNumber = this.originalSerialNumber;
        this.batchNumber = this.originalBatchNumber;
        this.manufacturingDate = this.originalManufacturingDate;
        this.expiryDate = this.originalExpiryDate;

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                element.width = element.originalWidth;
            });
        }
    }

    private resetElementWidth() {
        this.code = 0;
        this.name = 0;
        this.description = 0;
        this.nameWithDescription = 0;
        this.sourceWarehouseName = 0;
        this.sourceWarehouseCode = 0;
        this.destinationWarehouseName = 0;
        this.destinationWarehouseCode = 0;
        this.hsn = 0;
        this.quantity = 0;
        this.uom = 0;
        this.quantityWithUom = 0;
        this.serialBatch = 0;
        this.serialNumber = 0;
        this.batchNumber = 0;
        this.manufacturingDate = 0;
        this.expiryDate = 0;
        this.lineNumber = 0;

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                element.width = 0;
            });
        }
    }

    private getTotalWidth(totalWidth: number) {
        if (this.isLineNumberVisible) {
            totalWidth += this.lineNumber;
        }
        if (this.isCodeVisible) {
            totalWidth += this.code;
        }
        if (this.isNameVisible) {
            totalWidth += this.name;
        }
        if (this.isDescriptionVisible) {
            totalWidth += this.description;
        }
        if (this.isNameWithDescriptionVisible) {
            totalWidth += this.nameWithDescription;
        }
        if (this.isSourceWarehouseNameVisible) {
            totalWidth += this.sourceWarehouseName;
        }
        if (this.isSourceWarehouseCodeVisible) {
            totalWidth += this.sourceWarehouseCode;
        }
        if (this.isDestinationWarehouseNameVisible) {
            totalWidth += this.destinationWarehouseName;
        }
        if (this.isDestinationWarehouseCodeVisible) {
            totalWidth += this.destinationWarehouseCode;
        }
        if (this.isHsnVisible) {
            totalWidth += this.hsn;
        }
        if (this.isProductCustomFieldVisible) {
            totalWidth += this.productCustomField;
        }
        if (this.isQuantityVisible) {
            totalWidth += this.quantity;
        }
        if (this.isUomVisible) {
            totalWidth += this.uom;
        }
        if (this.isQuantityWithUomVisible) {
            totalWidth += this.quantityWithUom;
        }
        if (this.isSerialBatchVisible) {
            totalWidth += this.serialBatch;
        }
        if (this.isSerialNumberVisible) {
            totalWidth += this.serialNumber;
        }
        if (this.isBatchNumberVisible) {
            totalWidth += this.batchNumber;
        }
        if (this.isManufacturingDateVisible) {
            totalWidth += this.manufacturingDate;
        }
        if (this.isExpiryDateVisible) {
            totalWidth += this.expiryDate;
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    totalWidth += element.width;
                }
            });
        }

        return totalWidth;
    }

    private getTotalSelectedElement() {
        var totalSelectedElement = 0;
        if (this.isLineNumberVisible) {
            totalSelectedElement += 1;
        }
        if (this.isCodeVisible) {
            totalSelectedElement += 1;
        }
        if (this.isNameVisible) {
            totalSelectedElement += 1;
        }
        if (this.isDescriptionVisible) {
            totalSelectedElement += 1;
        }
        if (this.isNameWithDescriptionVisible) {
            totalSelectedElement += 1;
        }
        if (this.isSourceWarehouseNameVisible) {
            totalSelectedElement += 1;
        }
        if (this.isSourceWarehouseCodeVisible) {
            totalSelectedElement += 1;
        }
        if (this.isDestinationWarehouseNameVisible) {
            totalSelectedElement += 1;
        }
        if (this.isDestinationWarehouseCodeVisible) {
            totalSelectedElement += 1;
        }
        if (this.isHsnVisible) {
            totalSelectedElement += 1;
        }
        if (this.isProductCustomFieldVisible) {
            totalSelectedElement += 1;
        }
        if (this.isQuantityVisible) {
            totalSelectedElement += 1;
        }
        if (this.isUomVisible) {
            totalSelectedElement += 1;
        }
        if (this.isQuantityWithUomVisible) {
            totalSelectedElement += 1;
        }
        if (this.isSerialBatchVisible) {
            totalSelectedElement += 1;
        }
        if (this.isSerialNumberVisible) {
            totalSelectedElement += 1;
        }
        if (this.isBatchNumberVisible) {
            totalSelectedElement += 1;
        }
        if (this.isManufacturingDateVisible) {
            totalSelectedElement += 1;
        }
        if (this.isExpiryDateVisible) {
            totalSelectedElement += 1;
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    totalSelectedElement += 1;
                }
            });
        }

        return totalSelectedElement;
    }

    private updateElementWidth(additionalWidth: number) {
        if (this.isLineNumberVisible) {
            this.lineNumber = this.originalLineNumber + additionalWidth;
        }
        if (this.isCodeVisible) {
            this.code = this.originalCode + additionalWidth;
        }
        if (this.isNameVisible) {
            this.name = this.originalName + additionalWidth;
        }
        if (this.isDescriptionVisible) {
            this.description = this.originalDescription + additionalWidth;
        }
        if (this.isNameWithDescriptionVisible) {
            this.nameWithDescription =
                this.originalNameWithDescription + additionalWidth;
        }
        if (this.isSourceWarehouseNameVisible) {
            this.sourceWarehouseName = this.originalSourceWarehouseName + additionalWidth
        }
        if (this.isSourceWarehouseCodeVisible) {
            this.sourceWarehouseCode = this.originalSourceWarehouseCode + additionalWidth
        }
        if (this.isDestinationWarehouseNameVisible) {
            this.destinationWarehouseName = this.originalDestinationWarehouseName + additionalWidth
        }
        if (this.isDestinationWarehouseCodeVisible) {
            this.destinationWarehouseCode = this.originalDestinationWarehouseCode + additionalWidth
        }
        if (this.isHsnVisible) {
            this.hsn = this.originalHsn + additionalWidth;
        }
        if (this.isProductCustomFieldVisible) {
            this.productCustomField =
                this.originalProductCustomField + additionalWidth;
        }
        if (this.isQuantityVisible) {
            this.quantity = this.originalQuantity + additionalWidth;
        }
        if (this.isUomVisible) {
            this.uom = this.originalUom + additionalWidth;
        }
        if (this.isQuantityWithUomVisible) {
            this.quantityWithUom = this.originalQuantityWithUom + additionalWidth;
        }
        if (this.isSerialBatchVisible) {
            this.serialBatch = this.originalSerialBatch + additionalWidth;
        }
        if (this.isSerialNumberVisible) {
            this.serialNumber = this.originalSerialNumber + additionalWidth;
        }
        if (this.isBatchNumberVisible) {
            this.batchNumber = this.originalBatchNumber + additionalWidth;
        }
        if (this.isManufacturingDateVisible) {
            this.manufacturingDate = this.originalManufacturingDate + additionalWidth;
        }
        if (this.isExpiryDateVisible) {
            this.expiryDate = this.originalExpiryDate + additionalWidth;
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    element.width = element.originalWidth + additionalWidth;
                }
            });
        }
    }

    getProductCustomFieldWidthByCode(code: string) {
        var item = this.productCustomFieldList.find((x: any) => x.code === code);
        if (item !== undefined) {
            return item.width;
        }
        return undefined;
    }
}
