import React, { Component } from "react";
import "../../DocPreview.css";
import "../../CommonStyles/FontStyle.css";
import { COLOR_LIGHTGRAY, FONT_SIZE, LINE_ITEM_HEIGHT, LINE_ITEM_HEIGHT_2 } from '../../Constants/Constants';
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import DKEInvoiceSection, { eInvoiceSectionPosition } from "../../DKUILibrary/DKEInvoiceSection";
import DKSignatureSection from "../../DKUILibrary/DKSignatureSection";
import { AddressType } from "../../Helper/AddressParser";
import ComponentManager from "../../Manager/ComponentManager";
import DocumentManager from '../../Manager/DocumentManager';
import { getLocalisedText } from '../../Translate/LanguageManager';
import { HTMLTag } from "../../Utilities/HTMLTag";
import Utility, { getCapitalized, getFontSizeClass, getIsFulfillment, getIsGoodsReceipt, getIsJournalEntry, getPageHeight, getVW } from '../../Utilities/Utility';
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";

export default class DocumentTemplate28 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    getDocumentTypeLabel() {
        return (
            <div className="RowDiv"
                style={{
                    justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'center',
                    top: 0
                }}>
                <text
                    style={{
                        fontWeight: "bold",
                        textAlign: "center",
                        color: '#ffffff',
                        paddingRight: Utility.getIsArabicLang() ? getVW(10) : 0,
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xLarge }, this.state.data.printScale),
                    }}
                >
                    {getLocalisedText(this.state.data.documentType)}
                </text>
            </div>
        )
    }

    addHeaderSection() {
        return (
            <div className="ColumnDiv" style={{ width: '100%', justifyContent: 'flex-end', backgroundColor: this.state.data.themeColor, height: getVW(Utility.convertToPrintScale(120, this.state.data.printScale)) }}>
                {this.getDocumentTypeLabel()}
                {ComponentManager.addVerticalSpace(1)}
                {ComponentManager.addDivider({ height: getVW(Utility.convertToPrintScale(15, this.state.data.printScale)), backgroundColor: '#3E93EA', width: '100%' })}
            </div>
        )
    }

    getCompanyNameLabel() {
        return (
            <div className="RowDiv" style={{ align: 'flex-start' }}>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        paddingBottom: getVW(5),
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale),
                    }}>
                        {getLocalisedText(Utility.getCompanyName(this.state.data))}
                </text>
            </div>
        )
    }

    getCompanyInformationView() {
        return (
            <div className="ColumnDiv"
                style={{
                    width: Utility.getIsArabicLang() ? '100%' : getVW('30vw', true),
                    alignItems: Utility.getIsArabicLang() ? 'flex-end' : 'center',
                }}>
                <div className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        whiteSpace: 'pre-wrap',
                        color: '#ffffff',
                        textAlign: Utility.getIsArabicLang() ? 'right' : 'center',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.from) }, this.state.data.printScale),
                    }} dangerouslySetInnerHTML={{__html: getLocalisedText(Utility.getCompanyAddress(this.state.data))}}>

                </div>
            </div>
        )
    }

    getRowValueSection(title, value, titleColor = "black") {
        return (
            <div
                className="RowDiv"
                style={{
                    justifyContent: "space-evenly",
                    flex: 1,
                }}
            >
                <text className={"RowReverseDiv docFontStyleBold" + getFontSizeClass()} style={{ color: titleColor, width: '50%', justifyContent: 'end', paddingRight: getVW(20)}}>
                    {title.toUpperCase()}
                </text>
                <div
                    className="RowDiv"
                    style={{
                        flexDirection: 'row',
                        border: 'solid',
                        borderColor: 'lightgray',
                        borderWidth: getVW(1),
                        width: '50%'
                    }}>
                    <text className={"RowDiv docFontStyle" + getFontSizeClass()}
                        style={{
                            paddingLeft: getVW(10),
                            height: getVW(LINE_ITEM_HEIGHT_2, true),
                            justifyContent: 'center'
                        }}>
                        {value}
                    </text>
                </div>
            </div>
        );
    }


    getTableSection() {
        return (
            <div className="ColumnDiv" style={{ width: '100%' }}>
                {
                    TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                    <>
                        <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                            dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                        {ComponentManager.addVerticalSpace(5)}
                    </>
                }
                {
                    Utility.getIsShowAccountProductTable(this.state.data) && 
                        <div className="RowDiv">
                            {this.getProductTable()}
                        </div>
                }
                {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(20)}
                <div className="RowDiv">
                    {this.getEditableDocumentTable()}
                </div>
                <div className="RowReverseDiv" id={HTMLTag.SIGNATURE}>
                    <div className="ColumnDiv">
                        {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(30, this.state.data.printScale))}
                        {this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature, this.state.data.printScale)}
                    </div>
                </div>
            </div>
        )
    }

    getBillToAddressDetailView(title, value, addressType) {
        return (
            <div className="ColumnDiv" style={{ width: (this.state.data.showRefNumber || this.state.data.showStartDate || this.state.data.showDueDate) ? '50%' : '100%' }}>
                <div className="RowDiv"
                    style={{
                        backgroundColor: COLOR_LIGHTGRAY,
                        height: getVW(`${Utility.convertToPrintScale(Number(LINE_ITEM_HEIGHT.replace('vw', '')), this.state.data.printScale)}vw`, true),
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                    }}>
                    <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            color: this.state.data.themeColor,
                            fontWeight: 'bold',
                            marginLeft: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale),
                        }}>
                        {getLocalisedText(title).toUpperCase()}
                    </text>
                </div>

                <div className={"RowDiv docFontStyle" + getFontSizeClass()}
                    style={{
                        display: 'block',
                        marginTop: getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                        marginLeft: Utility.getIsArabicLang() ? 0 : getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                        justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, addressType) }, this.state.data.printScale),
                    }}
                    dangerouslySetInnerHTML={{__html: getLocalisedText(value)}}>
                </div>

            </div>
        )
    }

    addInvoiceDetails() {
        return (
            <div
                className="ColumnDiv"
                style={{
                    width: "100%",
                    alignItems: 'center',
                    paddingLeft: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                }}
            >
                {ComponentManager.addVerticalSpace(10)}
                <div className='parent-width' style={{
                    paddingRight: Utility.getIsArabicLang() ? getVW(Utility.convertToPrintScale(35, this.state.data.printScale)) : 0,
                }}>
                    {this.getDocumentDetailsSection()}
                </div>
            </div>
        )
    }

    getDocumentDetailsSection() {
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        return documentDetailList.map(element => {
            var value = ''
            if (element.key !== undefined) {
                value = this.state.data[element.key]
            }
            else if (element.customFieldValue !== undefined) {
                value = element.customFieldValue
            }
            return <>
                {ComponentManager.getRowValueSection(
                    element.label,
                    value,
                    'black',
                    'gray',
                    'bold',
                    true,
                    this.state.data.printScale
                )}
                {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(10, this.state.data.printScale))}
            </>
        });
    }

    getInvoiceDetailView() {
        return (
            <div className="ColumnDiv" style={{ width: this.state.data.showClientBillToAddress ? '50%' : '100%' }}>
                <div className="RowDiv" style={{
                    backgroundColor: COLOR_LIGHTGRAY,
                    height: getVW(`${Utility.convertToPrintScale(Number(LINE_ITEM_HEIGHT.replace('vw', '')), this.state.data.printScale)}vw`, true),
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                }}>
                    <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            color: this.state.data.themeColor,
                            fontWeight: 'bold',
                            marginLeft: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                            paddingRight: Utility.getIsArabicLang() ? getVW(Utility.convertToPrintScale(10, this.state.data.printScale)) : 0,
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale),
                        }}>
                        {getCapitalized(getLocalisedText(this.state.data.documentType) + " " + getLocalisedText("details"))}
                    </text>
                </div>
                {this.addInvoiceDetails()}
            </div>
        )
    }

    addAddresses() {
        return (
            <div className="RowDiv" style={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                {this.state.data.showClientBillToAddress && this.getBillToAddressDetailView(this.state.data.billToTitle, Utility.getClientBillToAddress(this.state.data), AddressType.billTo)}
                {(this.state.data.showRefNumber || this.state.data.showStartDate || this.state.data.showDueDate) && this.getInvoiceDetailView()}
            </div>
        )
    }

    addFooter() {
        return (
            <div style={{
                bottom: 0,
                width: '100%',
            }}>
                {(this.state.data.showNotes && this.state.data.notes) && this.getNotes()}
                {this.state.data.showFrom && this.getCompanyAddress()}
            </div>
        )
    }

    getCompanyAddress() {
        return <div className="ColumnDiv" style={{
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            padding: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
            backgroundColor: this.state.data.themeColor
        }}>
            {this.getCompanyInformationView()}
        </div>;
    }

    getNotes() {
        return <>
            <div id={HTMLTag.TERMS_NOTE_PREFIX + HTMLTag.NOTES}  className={"RowDiv docFontStyle" + getFontSizeClass()}
                style={{
                    marginTop: getVW(6),
                    color: 'gray',
                    justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'center',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale),
                }}>
                <div>
                    {Utility.getNotesTitle(getIsJournalEntry(this.state.data.type) ? getLocalisedText('memo') : getLocalisedText('notes')) + ': '}
                </div>
                <div>
                    {getLocalisedText(this.state.data.notes)}
                </div>
            </div>
            {ComponentManager.addVerticalSpace(20)}
        </>;
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ justifyContent: 'space-between', minHeight: getPageHeight(this.state.data.printScale) }}>
                <div className='parent-width' style={{ width: '100%', paddingBottom: getVW(30)  }}>
                    <DKEInvoiceSection
                        data={this.state.data}
                        position={eInvoiceSectionPosition.top}
                    />
                    {this.addHeaderSection()}
                    {ComponentManager.addVerticalSpace(10)}
                    {this.addAddresses()}

                    {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.addVerticalSpace(10)}
                    {Utility.getIsShowPlaceOfSupply(this.state.data) && this.getPlaceOfSupplyInfoSection()}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && ComponentManager.addVerticalSpace(20)}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && this.getSourceOfSupplyInfoSection()}
                    {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(25)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'top'} />
                    {ComponentManager.addVerticalSpace(25)}
                    {this.getTableSection()}
                    {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(25)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'bottom'} />
                </div>
                <div className='parent-width' style={{ width: '100%' }}>
                    {(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) && <DKSignatureSection data={this.state.data} onSignatureSectionClicked={this.props.tableClicked} />}
                    {this.addFooter()}
                </div>
                <DKEInvoiceSection
                    data={this.state.data}
                    position={eInvoiceSectionPosition.bottom}
                />
            </div>
        );
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getProductTable() {
        if(Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKProductTable(
                Utility.getTableColumnForAccountProduct(),
                this.getTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerBackgroundColor = COLOR_LIGHTGRAY;
        style.headerTextColor = "#000000";
        style.headerBorderColor = '#ffffff';
        style.itemBorderColor = '#ffffff';
        style.itemBackgroundColor = '#ffffff';
        style.itemBackgroundColorIsAlternate = false;
        style.footerBackgroundColor = COLOR_LIGHTGRAY
        style.footerTextColor = "black";
        style.footerBorderColor = "#ffffff";
        style.subTotalBorderColor = 'transparent';
        return style;
    }

    getCustomFieldTableStyle() {
        let style = {}
        style.columnCount = 2
        style.columnWidths = ['50%', '50%']
        style.marginLeft = getVW(10)
        return style;
    }

    getPlaceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getPlaceOfSupply()}

            </div>
        )
    }
    getPlaceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '40%',marginLeft:'10px'
            }}>
                {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('place_of_supply'),
                    this.state.data.placeOfSupply,
                    "100%",
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>

        </div>;

    }

    getSourceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getSourceOfSupply()}

            </div>
        )
    }

     getSourceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%',marginLeft:'10px' }}>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowSourceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('source_of_supply'),
                    this.state.data.sourceOfSupply,
                    "100%",
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowDestinationOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('destination_of_supply'),
                    this.state.data.destinationOfSupply,
                    "100%",
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
          </div>

        </div>;
    }
}

