
import React, { Component } from "react";
import TemplateSettingsManager from '../../Manager/TemplateSettingsManager';
import { AdditionalTableType } from '../../Components/Popup/AdditionalTablePopup';
import Utility, { getVW } from '../../Utilities/Utility';
import { FONT_SIZE } from '../../Constants/Constants';
import { getLocalisedText } from '../../Translate/LanguageManager';
import { getBottomBorderColor, getBottomBorderWidth, getTableFontStyleProp, getTablePadding } from './TableUtility';
import QRCode from "react-qr-code";
import { TableDimensionType } from "../../Components/TemplateEditors/DocumentEditor";
import NumberUtility from "../../Utilities/NumberUtility";
import ComponentManager from "../../Manager/ComponentManager";


export default class DKAdditionalTable extends Component {

    render() {
        if (this.isShowTable()) {
            return (
                <div
                    className='parent-width ColumnDiv'
                    onClick={this.props.onAdditionalTableClicked}
                >
                    {/* {this.renderHeader()} */}
                    <table className="container" style={{
                        // pageBreakAfter: 'always',
                        // pageBreakInside: 'auto'
                    }}>
                        {this.renderAdditionalTableList()}
                    </table>
                </div>
            )
        }
        return <></>
    }

    renderHeader() {
        return <div className='RowDiv' style={{
            backgroundColor: this.props.tableStyle.headerBackgroundColor,
            borderWidth: this.isShowBorder() ? getVW(1) : undefined,
            borderColor: this.isShowBorder() ? this.props.tableStyle.headerBorderColor : undefined,
            borderStyle: this.isShowBorder() ? this.props.tableStyle.borderStyle : undefined,
            borderBottomColor: this.isShowBorder() ? getBottomBorderColor(this.props.tableStyle) : undefined,
            borderBottomWidth: this.isShowBorder() ? getBottomBorderWidth(this.props.tableStyle) : undefined,
            borderCollapse: 'collapse',
            paddingTop: getVW(11),
            paddingBottom: getVW(11),
            // ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium, color: this.props.tableStyle.headerTextColor, }),
            ...getTableFontStyleProp({ fontSize: FONT_SIZE.medium, color: this.props.tableStyle.headerTextColor, }, this.props.rawData.printScale)
        }}>
            <div className='RowDiv pl-s pr-s'>
                {this.getTitle()}
            </div>
        </div>
    }

    renderAdditionalTableList() {
        var list = this.getTableConfigurationElements()
        var containerList = undefined
        if(list.length > 0) {
            containerList = list.map((element) => {
                return this.renderAdditionalTableItem(element)
            });
        }

        return containerList
    }

    renderAdditionalTableItem(element) {
        return <>
            <tr className='row-cell' style={{
                pageBreakInside: 'avoid'
            }}>
                <td className="cell" style={{
                    pageBreakInside: 'avoid',
                    borderStyle: 'solid',
                    borderWidth: getVW(1),
                    borderColor: this.props.tableStyle.itemBorderColor,
                }}>
                    {this.getElement(element)}
                </td>
            </tr>
        </>
    }

    renderTitle(element) {
        return <div
            className='parent-width ColumnDiv'
            style={{
                textAlign: 'left',
                overflowWrap: 'normal',
                // ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }),
                ...getTableFontStyleProp({ fontSize: FONT_SIZE.regular, fontWeight: 'bold' }, this.props.rawData.printScale),
            }}>
            <div className="parent-width RowDiv"
                style={{
                    fontWeight: 'bold',
                }}
                dangerouslySetInnerHTML={{ __html: element.title }} />
        </div>
    }

    getElement(element) {
        switch (element.type) {
            case AdditionalTableType.qrCode:
                return this.renderQRCodeContainer(element)
            case AdditionalTableType.qrCodeWithText:
                return this.renderQRCodeWithCustomTextContainer(element)
            case AdditionalTableType.image:
                return this.renderImageContainer(element)
            case AdditionalTableType.imageWithText:
                return this.renderImageWithCustomTextContainer(element)
            case AdditionalTableType.amountInWords:
                return this.renderAmountInWordsContainer(element)
            case AdditionalTableType.total:
                return this.renderTotalContainer(element)
            case AdditionalTableType.outstandingBalance:
                return this.renderOutstandingBalanceContainer(element)
            case AdditionalTableType.outstandingBalanceIncludesCurrent:
                return this.renderOutstandingBalanceIncludesCurrentContainer(element)
            case AdditionalTableType.customText:
                return this.renderCustomTextContainer(element)
            case AdditionalTableType.customField:
                return this.renderCustomTextContainer(element)
            default:
                return undefined
        }
    }

    getTitle() {
        var elements = this.getTableConfigurationElements()
        var title = 'additional_info'

        if(elements.length > 0 ) {
            if(elements.length === 1) {
                switch (elements[0].type) {
                    case AdditionalTableType.qrCode:
                        title = 'qr_information'
                        break
                    case AdditionalTableType.qrCodeWithText:
                        title = 'qr_information'
                        break
                    case AdditionalTableType.image:
                        title = 'additional_info'
                        break
                    case AdditionalTableType.imageWithText:
                        title = 'additional_info'
                        break
                    case AdditionalTableType.amountInWords:
                        title = 'amount_in_words'
                        break
                    case AdditionalTableType.total:
                        title = 'total'
                        break
                    case AdditionalTableType.outstandingBalance:
                        title = 'outstanding_balance'
                        break
                    case AdditionalTableType.outstandingBalanceIncludesCurrent:
                        title = 'outstanding_balance_includes_current'
                        break
                    case AdditionalTableType.customText:
                        title = 'additional_info'
                        break
                    default:
                        break
                }
            }
        }
        return getLocalisedText(title)
    }

    renderQRCodeContainer(element) {
        return <div
            className='parent-width ColumnDiv pt-s pb-s'
            style={{
                textAlign: 'left',
                // ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }),
                ...getTableFontStyleProp({ fontSize: FONT_SIZE.regular }, this.props.rawData.printScale),
                ...this.getRowPadding(),
            }}>
            <div className="parent-width ColumnDiv pl-s pr-s">
                {this.renderTitle(element)}
            </div>
            <div className="ColumnDiv pl-s pr-s">
                {this.renderQRCode_svg(element.qrCode)}
            </div>
        </div>
    }

    renderQRCodeWithCustomTextContainer(element) {
        return <div
            className='parent-width ColumnDiv pt-s pb-s'
            style={{
                textAlign: 'left',
                overflowWrap: 'normal',
                // ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }),
                ...getTableFontStyleProp({ fontSize: FONT_SIZE.regular }, this.props.rawData.printScale),
                ...this.getRowPadding(),
            }}>
            <div className="parent-width ColumnDiv pl-s pr-s">
                {this.renderTitle(element)}
            </div>
            <div className="parent-width RowDiv pl-s pr-s"
                style={{
                    width: '95%',
                    ...getTableFontStyleProp({ fontSize: FONT_SIZE.regular }, this.props.rawData.printScale)
                }}>
                <div className="ColumnDiv ">
                    {this.renderQRCode_svg(element.qrCode)}
                </div>
                <div className="ColumnDiv parent-width "
                    style={{
                        marginLeft: 8,
                        marginRight: 8,
                    }}
                    dangerouslySetInnerHTML={{ __html: this.getMultiLineText(element.text) }} />
            </div>
        </div>
    }

    renderQRCode_svg(value) {
        if (this.getIsContainQRCode(value)) {
            return <QRCode
                value={value}
                size={Utility.convertToPrintScale(128, this.props.rawData.printScale)}
            />
        }
        return null
    }

    renderQRCode_img(value) {
        if (this.getIsContainQRCode(value)) {
            return <img src={value}
                alt=""
                style={{
                    ...{
                        height: Utility.convertToPrintScale(128, this.props.rawData.printScale),
                    },
                }}
            />
        }
        return null
    }

    getIsContainQRCode(value) {
        if (value !== undefined && value !== null && value.trim().length > 0) {
            return true
        }
        return false
    }


    renderImageContainer(element) {
        return <div
            className='parent-width ColumnDiv pt-s pb-s'
            style={{
                textAlign: 'left',
                overflowWrap: 'normal',
                // ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }),
                ...getTableFontStyleProp({ fontSize: FONT_SIZE.regular }, this.props.rawData.printScale),
                ...this.getRowPadding(),
            }}>
            <div className="parent-width ColumnDiv pl-s pr-s">
                {this.renderTitle(element)}
            </div>
            <div className="ColumnDiv pl-s pr-s">
                {this.renderImage(element.image)}
            </div>
        </div>
    }

    renderImageWithCustomTextContainer(element) {
        return <div
            className='parent-width ColumnDiv pt-s pb-s'
            style={{
                textAlign: 'left',
                overflowWrap: 'normal',
                // ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }),
                ...getTableFontStyleProp({ fontSize: FONT_SIZE.medium }, this.props.rawData.printScale),
                ...this.getRowPadding(),
            }}>
            <div className="parent-width ColumnDiv pl-s pr-s">
                {this.renderTitle(element)}
            </div>
            <div className="parent-width RowDiv pl-s pr-s"
                style={{
                    width: '95%',
                }}>
                <div className="ColumnDiv parent-width ">
                    {this.renderImage(element.image)}
                </div>

                <div className="ColumnDiv parent-width "
                    style={{
                        marginLeft: 8,
                        marginRight: 8,
                    }}
                    dangerouslySetInnerHTML={{ __html: this.getMultiLineText(element.text) }} />
            </div>
        </div>
    }

    renderImage(value) {
        if (this.getIsContainQRCode(value)) {
            return <img src={value}
                alt=""
                style={{
                    ...{
                        height: Utility.convertToPrintScale(150, this.props.rawData.printScale),
                        borderRadius: '5%',
                        objectFit: "contain",
                        border: "solid",
                        borderWidth: 1,
                        borderColor: "rgb(230, 230, 230)",
                    },
                }}
            />
        }
        return null
    }

    getIsContainImage(value) {
        if (value !== undefined && value !== null && value.trim().length > 0) {
            return true
        }
        return false
    }


    renderAmountInWordsContainer(element) {
        return this.renderTextContainer(this.props.rawData.amountInWords, element)
    }

    renderTotalContainer(element) {
        var total = Utility.toCurrencyFormat(ComponentManager.getTotalOfLineItems(this.props.rawData.lineItems, false, false, 0), NumberUtility.getCurrency(this.props.rawData))
        if (this.props.rawData.total !== undefined) {
            total = Utility.toCurrencyFormat(this.props.rawData.total, NumberUtility.getCurrency(this.props.rawData))
        }

        return this.renderTextContainer(total, element)
    }

    renderOutstandingBalanceContainer(element) {
        var outstandingBalance = ''
        if (this.props.rawData.outstandingBalance !== undefined) {
            outstandingBalance = Utility.toCurrencyFormat(this.props.rawData.outstandingBalance, NumberUtility.getCurrency(this.props.rawData))
        }

        return this.renderTextContainer(outstandingBalance, element)
    }

    renderOutstandingBalanceIncludesCurrentContainer(element) {
        var outstandingBalance = ''
        if (this.props.rawData.outstandingBalanceIncludesCurrent !== undefined) {
            outstandingBalance = Utility.toCurrencyFormat(this.props.rawData.outstandingBalanceIncludesCurrent, NumberUtility.getCurrency(this.props.rawData))
        }

        // var total = ComponentManager.getTotalOfLineItems(this.props.rawData.lineItems, false, false, 0)
        // if (this.props.rawData.total !== undefined) {
        //     total = this.props.rawData.total
        // }

        // if (this.props.rawData.outstandingBalance !== undefined) {
        //     outstandingBalance = outstandingBalance + total
        // }

        // if(outstandingBalance !== '') {
        //     outstandingBalance = Utility.toCurrencyFormat(outstandingBalance, NumberUtility.getCurrency(this.props.rawData))
        // }
        // else if (this.props.rawData.total !== undefined) {
        //     outstandingBalance = Utility.toCurrencyFormat(total, NumberUtility.getCurrency(this.props.rawData))
        // }

        return this.renderTextContainer(outstandingBalance, element)
    }

    renderCustomTextContainer(element) {
        var text = ''
        if (element.text !== undefined && element.text !== null ) {
            text = element.text
        }
        if (element.customFieldValue !== undefined && element.customFieldValue !== null) {
            text = element.customFieldValue
        }
        return this.renderTextContainer(this.getMultiLineText(text), element)
    }

    renderTextContainer(text, element) {
        return <div
            className='parent-width ColumnDiv pt-s pb-s'
            style={{
                textAlign: 'left',
                overflowWrap: 'normal',
                // ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }),
                ...getTableFontStyleProp({ fontSize: FONT_SIZE.regular }, this.props.rawData.printScale),
                ...this.getRowPadding(),
            }}>
            <div className="parent-width RowDiv pl-s pr-s"
                style={{
                    width: '95%',
                    ...getTableFontStyleProp({ fontSize: FONT_SIZE.regular }, this.props.rawData.printScale),
                }}>
                {this.renderTitle(element)}
            </div>
            <div className="parent-width RowDiv pl-s pr-s"
                style={{
                    marginRight: 8,
                    width: '95%',
                    ...getTableFontStyleProp({ fontSize: FONT_SIZE.regular }, this.props.rawData.printScale)
                }}
                dangerouslySetInnerHTML={{__html: text}} />
        </div>
    }

    getRowPadding() {
        var paddingTop = getTablePadding(getVW(0), TableDimensionType.PADDING_VERTICAL)
        var paddingBottom = getTablePadding(getVW(0), TableDimensionType.PADDING_VERTICAL)
        var paddingLeft = getTablePadding(getVW(0), TableDimensionType.PADDING_HORIZONTAL)
        var paddingRight = getTablePadding(getVW(0), TableDimensionType.PADDING_HORIZONTAL)

        return {
            paddingTop: paddingTop === '0vw' ? undefined : paddingTop,
            paddingBottom: paddingBottom === '0vw' ? undefined : paddingBottom,
            paddingLeft: paddingLeft === '0vw' ? undefined : paddingLeft,
            paddingRight: paddingRight === '0vw' ? undefined : paddingRight,
        }
    }

    getMultiLineText(text) {
        var newText = ''
        if (text !== undefined && text !== null) {
            if (text.includes('\n')) {
                newText = text.split('\n').join('<br/>')
            }
            else {
                newText = text
            }
        }

        return newText
    }

    getTableConfigurationElements() {
        var additionalTableConfiguration = this.getTableConfiguration()
        if (additionalTableConfiguration !== undefined) {
            if (additionalTableConfiguration.elements !== undefined && additionalTableConfiguration.elements !== null) {
                if (additionalTableConfiguration.elements.length > 0) {
                    var elements = additionalTableConfiguration.elements
                    elements = Utility.getCustomFieldValue(this.props.rawData, elements)
                    return elements
                }
            }
        }

        return []
    }

    getTableConfiguration() {
        var additionalTableConfiguration = TemplateSettingsManager.defaultTemplateSettings.additionalTable

        if (additionalTableConfiguration !== undefined) {
            return additionalTableConfiguration
        }

        return undefined
    }

    getIsContainsTitle(element) {
        if(element.title !== undefined && element.title !== null) {
            if(element.title !== '') {
                return true
            }
        }
        return false
    }

    isShowTable() {
        if(Utility.getIsReceiptPaperSize()) {
            return false
        }

        var additionalTableConfiguration = this.getTableConfiguration()
        if (additionalTableConfiguration !== undefined) {
            if (additionalTableConfiguration.isVisible !== undefined && additionalTableConfiguration.isVisible !== null) {
                if (additionalTableConfiguration.elements !== undefined && additionalTableConfiguration.elements !== null) {
                    if (additionalTableConfiguration.elements.length > 0) {
                        return additionalTableConfiguration.isVisible
                    }
                }
            }
        }
        return false
    }

    isShowBorder() {
        return this.props.tableOption.isShowBorder ? this.props.tableOption.isShowBorder : false
    }

}
