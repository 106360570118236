import React, { Component } from "react";
import "../../../DocPreview.css";
import { getLocalisedText } from "../../../Translate/LanguageManager";
import { getPageHeight } from "../../../Utilities/Utility";
import NumberUtility from "../../../Utilities/NumberUtility";
import PayslipUtility from "../PayslipUtility";
import "./../css/Templates.css";
import PayslipTableUtility, { PayslipTableType } from "../PayslipTableUtility";
import PayslipFieldUtility from "../PayslipFieldUtility";
import ComponentManager from "../../../Manager/ComponentManager";

export default class INPayslipTemplate2 extends Component {
    currency = null

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data.payslip, //require('../../../MockData/IN_Payslip.json'),
            isFromERP: true,
            globalData: this.props.data,
        };
        this.currency = NumberUtility.getCurrency(this.props.data)
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data.payslip,
            isFromERP: true,
            globalData: nextProp.data,
        });
        this.currency = NumberUtility.getCurrency(nextProp.data)
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ minHeight: getPageHeight() }}>
                <div className='parent-width' style={{ width: '100%', paddingBottom: 30 }}>
                    {this.renderHeader()}
                    {PayslipFieldUtility.renderPayslipFields(this.state.globalData.templateId, this.state.globalData, this.state.data, this.currency, this.state.isFromERP)}
                    {this.renderTable()}
                    {PayslipUtility.renderPayslipAutoGeneratorSection()}
                </div>
            </div>
        );
    }

    renderHeader() {
        return <>
            <div className='RowDiv pb-s'
                style={{
                    alignItems: "center"
                }}>
                {ComponentManager.getLogoVisibility(this.state.globalData.showCompanyLogo, this.state.globalData.isReadOnlyMode, this.state.globalData.logo) &&
                    ComponentManager.getLogo(this.state.globalData.logo, 60, { position: 'relative' })}
                {this.state.globalData.showCompanyName &&
                    <div className='ColumnDiv pl-s'
                        style={{
                            fontSize: 12,
                            fontWeight: 300,
                            width: '40%',
                        }}>
                        <div className='RowDiv'
                            style={{
                                paddingTop: 10,
                                fontWeight: 700,
                                color: 'rgb(0, 0, 0)',
                                fontSize: 24
                            }}>
                            {getLocalisedText(PayslipUtility.getCompanyName(this.state.data, this.state.isFromERP))}
                        </div>
                    </div>
                }
                {this.state.globalData.showFrom &&
                    <div className='ColumnDiv'
                        style={{
                            fontSize: 12,
                            fontWeight: 300,
                            width: this.state.globalData.showCompanyName ? '60%' : '100%'
                        }}>
                        <div className='RowReverseDiv'
                            style={{
                                color: 'rgb(0, 0, 0)',
                                textAlign: 'right',
                                paddingTop: 22,
                                paddingRight: 2,
                                paddingLeft: 2,
                            }} dangerouslySetInnerHTML={{ __html: getLocalisedText(PayslipUtility.getCompanyAddress(this.state.data, this.state.isFromERP)) }}>
                        </div>
                    </div>
                }
            </div>
            <div style={{
                    backgroundColor: this.state.globalData.themeColor,
                    height: 4,
                    // marginTop: getVW(-4),
                }}>
                <div style={{
                    height: 1,
                    paddingTop: 1,
                    paddingBottom: 1,
                }}>
                </div>
            </div>
        </>
    }

    renderTable() {
        //this will be dynamic based on user selection
        var payslipTable = PayslipTableUtility.getPayslipTable(this.state.globalData)
        if(payslipTable.length > 0) {
            var employeeTaxesWithhold = payslipTable.find(x => x.type === PayslipTableType.employeeTaxesWithhold)
            var employerTaxes = payslipTable.find(x => x.type === PayslipTableType.employerTaxes)
            var index = 0
            if (employeeTaxesWithhold !== undefined && employerTaxes !== undefined) {
                if (employeeTaxesWithhold.isSelected && employerTaxes.isSelected) {
                    index = Math.abs(employeeTaxesWithhold.index - employerTaxes.index)
                }
            }

            var firstType = undefined
            if(index === 1) {
                payslipTable.forEach(element => {
                    if (element.type === PayslipTableType.employeeTaxesWithhold || element.type === PayslipTableType.employerTaxes) {
                        if (firstType === undefined) {
                            firstType = element.type
                            element.isCombined = true
                        }
                    }
                });
            }

            var employeeTaxesWithhold_EmployerTaxes = []
            if(firstType !== undefined) {
                if (firstType === PayslipTableType.employeeTaxesWithhold) {
                    employeeTaxesWithhold_EmployerTaxes = [employeeTaxesWithhold, employerTaxes ]
                }
                else {
                    employeeTaxesWithhold_EmployerTaxes = [employerTaxes, employeeTaxesWithhold]
                }
            }
            return payslipTable.map(element => {
                if(element.isSelected) {
                    if (employeeTaxesWithhold_EmployerTaxes.length > 0) {
                        if (element.type !== employeeTaxesWithhold_EmployerTaxes[1].type){
                            return this.renderTableByType(element, employeeTaxesWithhold_EmployerTaxes)
                        }
                    }
                    else {
                        return this.renderTableByType(element, employeeTaxesWithhold_EmployerTaxes)
                    }
                }
                return undefined
            });
        }
    }

    renderTableByType(item, items) {
        var div = undefined
        switch (item.type) {
            case PayslipTableType.employeeEarnings:
                div = this.renderEmployeeEarning(item.name)
                break;
            case PayslipTableType.employeeDeductions:
                div = this.renderDeduction(item.name)
                break;
            case PayslipTableType.summary:
                div = this.renderSummary(item.name)
                break;
            case PayslipTableType.employeeTaxesWithhold:
                div = this.renderTaxesWithholdOrEmployerTaxes(item, true)
                if(item.isCombined !== undefined) {
                    if(item.isCombined) {
                        div = this.renderTaxesWithholdAndEmployerTaxes(items)
                    }
                }
                break;
            case PayslipTableType.employerTaxes:
                div = this.renderTaxesWithholdOrEmployerTaxes(item, true)
                if (item.isCombined !== undefined) {
                    if (item.isCombined) {
                        div = this.renderTaxesWithholdAndEmployerTaxes(items)
                    }
                }
                break;
            default:
                break;
        }

        return div
    }

    renderEmployeeEarning(headerText) {
        var title = {
            value: getLocalisedText(headerText),
            textAlign: 'left'
        }
        var headers = [ getLocalisedText('description'), getLocalisedText('rate'), getLocalisedText('duration'), getLocalisedText('current'),  getLocalisedText('year_to_date')]

        var data = [
            ['#ComponentName#', '#ComponentAmount#', '#PayPeriod#', '#ComponentAmount#', '#ComponentYTD#'],
            [getLocalisedText('gross_earnings'), '', '', '#EarningsTotal#', '#EarningsTotalYTD#']
        ]

        var headerStyle = [
            { index: 1, width: '25%', },
            { index: 2, width: '15%', },
            { index: 3, width: '30%', },
            { index: 4, width: '15%', },
            { index: 5, width: '15%', },
        ]

        var tableStyle = this.getCommonTableStyle()

        if(this.state.isFromERP) {
            data = PayslipUtility.getEmployeeEarningTable(this.state.data, this.state.isFromERP, this.currency)
        }

        return PayslipUtility.renderTableContainer(title, headers, data, tableStyle, headerStyle)
    }

    renderTaxesWithholdAndEmployerTaxes(items) {
        return <div className='RowDiv' style={{
            alignItems: 'start'
        }}>
            {items.map((element, index) => {
                return this.renderTaxesWithholdOrEmployerTaxes(element, false, index)
            })}
        </div>
    }

    renderTaxesWithholdOrEmployerTaxes(item, isSingleDiv, index) {
        var div = undefined
        switch (item.type) {
            case PayslipTableType.employeeTaxesWithhold:
                div = <div className='RowDiv parent-width'>
                    {this.renderTaxesWithhold(item.name, isSingleDiv, index)}
                </div>
                break;
            case PayslipTableType.employerTaxes:
                div = <div className='RowDiv parent-width'>
                    {this.renderEmployerTaxes(item.name, isSingleDiv, index)}
                </div>
                break;
            default:
                break;
        }

        return div
    }

    renderTaxesWithhold(headerText, isParentDiv = true, index = 0) {
        var title = {
            value: getLocalisedText(headerText) + ':',
            textAlign: 'left'
        }
        var headers = [getLocalisedText('tax'), getLocalisedText('current'), getLocalisedText('year_to_date')]

        var data = [
            ['#ComponentName', '#ComponentAmount#', '#ComponentYTD#']
        ]

        var paddingRight = index === 0 ? 5 : 0
        var paddingLeft = index === 0 ? 0 : 5
        var style = {
            paddingRight: isParentDiv ? 0 : paddingRight,
            paddingLeft: isParentDiv ? 0 : paddingLeft,
        }

        var headerStyle = [
            { index: 1, width: '40%', },
            { index: 2, width: '30%', },
            { index: 3, width: '30%', },
        ]

        var tableStyle = this.getCommonTableStyle()

        if(this.state.isFromERP) {
            data = PayslipUtility.getTaxesTable(this.state.data, this.state.isFromERP, this.currency, 'EMPLOYEE')
        }

        return PayslipUtility.renderTableContainer(title, headers, data, tableStyle, headerStyle, style)
    }

    renderEmployerTaxes(headerText, isParentDiv = true, index = 1) {
        var title = {
            value: getLocalisedText(headerText) + ':',
            textAlign: 'left'
        }
        var headers = [getLocalisedText('tax'), getLocalisedText('current'), getLocalisedText('year_to_date')]

        var data = [
            ['#ComponentName', '#ComponentAmount#', '#ComponentYTD#']
        ]

        var paddingLeft = index === 0 ? 0 : 5
        var paddingRight = index === 0 ? 5 : 0
        var style = {
            paddingRight: isParentDiv ? 0 : paddingRight,
            paddingLeft: isParentDiv ? 0 : paddingLeft,
        }

        var headerStyle = [
            { index: 1, width: '40%', },
            { index: 2, width: '30%', },
            { index: 3, width: '30%', },
        ]

        var tableStyle = this.getCommonTableStyle()

        if (this.state.isFromERP) {
            data = PayslipUtility.getTaxesTable(this.state.data, this.state.isFromERP, this.currency, 'EMPLOYER')
        }

        return PayslipUtility.renderTableContainer(title, headers, data, tableStyle, headerStyle, style)
    }

    renderDeduction(headerText) {
        var title = {
            value: getLocalisedText(headerText) + ':',
            textAlign: 'left'
        }
        var headers = [getLocalisedText('description'), getLocalisedText('type'), getLocalisedText('current'), getLocalisedText('year_to_date')]

        var headerStyle = [
            { index: 1, textAlign: 'left', },
            { index: 2, textAlign: 'left', },
            { index: 3, textAlign: 'right', },
            { index: 4, textAlign: 'right', },
        ]

        var tableStyle = this.getCommonTableStyle()

        var data = [
            ['#ComponentName#', '#ComponentType#', '#ComponentAmount#', '#ComponentYTD#']
        ]

        if (this.state.isFromERP) {
            data = PayslipUtility.getDeductionsTable(this.state.data, this.state.isFromERP, this.currency)
        }

        return PayslipUtility.renderTableContainer(title, headers, data, tableStyle, headerStyle)
    }


    renderSummary(headerText) {
        var title = {
            value: getLocalisedText(headerText) + ':',
            textAlign: 'left'
        }
        var headers = [getLocalisedText('description'), getLocalisedText('current'), getLocalisedText('year_to_date')]

        var data = [
            [getLocalisedText('gross_earnings'), '#GrossEarning#', '#GrossEarningYTD#'],
            [getLocalisedText('pre_tax_deductions_contributions'), '#PreTaxDeduction#', '#PreTaxDeductionYTD#'],
            [getLocalisedText('taxes'), '#Taxes#', '#TaxesYTD#'],
            [getLocalisedText('post_tax_deductions_contributions'), '#PostTaxDeduction#', '#PostTaxDeductionYTD#'],
            [getLocalisedText('net_pay_contributions'), '#NetPay#', '#NetPayYTD#'],
            [getLocalisedText('check_amount'), '#CheckAmount#', '#CheckAmountYTD#']
        ]


        if (this.state.isFromERP) {
            data = PayslipUtility.getSummaryTable(this.state.data, this.state.isFromERP, this.currency, false)
        }

        var headerStyle = [
            { index: 1, width: '60%', },
            { index: 2, width: '20%', },
            { index: 3, width: '20%', },
        ]

        var tableStyle = {
            LastRowTopBorderColor: this.state.globalData.themeColor,
            LastRowBottomBorderColor: this.state.globalData.themeColor,
            LastRowBottomBorderWidth: 3,
            headerBorderColor: this.state.globalData.themeColor,
            headerBorderColorOpacity: 0.3,
            borderColor: this.state.globalData.themeColor,
            borderColorOpacity: 0.3,
            // headerColor: 'red',
            // titleColor: 'red',
        }

        return PayslipUtility.renderTableContainer(title, headers, data, tableStyle, headerStyle)
    }

    getCommonTableStyle() {
        return {
            borderColor: this.state.globalData.themeColor,
            borderColorOpacity: 0.3,
            // LastRowTopBorderColor: this.state.globalData.themeColor,
            LastRowBottomBorderColor: this.state.globalData.themeColor,
            LastRowBottomBorderColorOpacity: 0.3,
            headerBorderColor: this.state.globalData.themeColor,
            headerBorderColorOpacity: 0.3,
            // LastRowBottomBorderWidth: 3,
            // headerColor: 'red',
            // titleColor: 'red',}
        }
    }
}
