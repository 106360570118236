import { ENDPOINTS } from '../constants/EndPoints';
import { ILoginPayload } from '../models/Auth';
import { getItemFromLocalStorage } from '../utility/LocalStorage';
import httpClient from './';
export const login = (payload: ILoginPayload): Promise<any> => {
  const subDomain = getItemFromLocalStorage('subdomain');
  return httpClient.post(ENDPOINTS.AUTH.LOGIN(subDomain as string), payload);
};
export const checkUserStatus = (): Promise<any> => {
  const subDomain = getItemFromLocalStorage('subdomain');
  return httpClient.get(ENDPOINTS.AUTH.STATUS(subDomain as string));
};
export const fetchSettings = () => {
  const subDomain = getItemFromLocalStorage('subdomain');
  return httpClient.get(ENDPOINTS.SETTINGS.GET(subDomain as string));
};
