import { TableColumnPopupType } from "../../../Components/Popup/TableColumnPopup";
import CustomFieldManager from "../../../Manager/CustomFieldManager";
import TemplateSettingsManager from "../../../Manager/TemplateSettingsManager";
import { getLocalisedText } from "../../../Translate/LanguageManager";
import Utility, { convertDateFromERPFormatToDocFormat, getConvertedDate } from "../../../Utilities/Utility";
import { getSortedHeader } from "../TableUtility";

export class JournalEntryTableItem {
    date: string;
    accountName: string;
    accountCode: string;
    description: string;
    journalEntryNumber: string;
    credit: string;
    debit: string;
    accountCustomField: string;
    currency: string;
    rawItemValue: any;
    constructor(journalEntryItem: any, currency: string, dateFormat: any) {
        this.date = journalEntryItem.date ? getConvertedDate(convertDateFromERPFormatToDocFormat(journalEntryItem.date), dateFormat) : ''
        this.accountCode = journalEntryItem.accountCode ? journalEntryItem.accountCode : ''
        this.accountName = journalEntryItem.accountName ? journalEntryItem.accountName : ''
        this.description = journalEntryItem.description ? journalEntryItem.description : ''
        this.journalEntryNumber = journalEntryItem.journalEntryNumber ? journalEntryItem.journalEntryNumber : ''
        this.debit = journalEntryItem.debit !== undefined ? Utility.toCurrencyFormat(journalEntryItem.debit, currency) : ''
        this.credit = journalEntryItem.credit !== undefined ? Utility.toCurrencyFormat(journalEntryItem.credit, currency) : ''
        this.accountCustomField = ''
        this.currency = currency
        this.rawItemValue = journalEntryItem
    }

    getAccountCustomFieldByCode(code: string) {
        var customFields = this.rawItemValue.customField
        if (customFields === undefined || customFields === null) {
            return undefined
        }

        if (customFields.length === 0) {
            return undefined
        }

        return customFields.find((x: any) => x.code === code)
    }

    getCombineAccountCustomField() {
        var val = ''
        var accountTableConfiguration = TemplateSettingsManager.defaultTemplateSettings.accountTableConfiguration
        if (accountTableConfiguration !== undefined) {
            if (accountTableConfiguration.isCombinesAccountCustomField) {
                var accountCustomFields = CustomFieldManager.getActiveAccountCustomFields()
                if (accountCustomFields !== undefined) {
                    if (accountCustomFields.length > 0) {
                        accountCustomFields.forEach(element => {
                            if (element.isSelected && element.code !== undefined) {
                                var field = this.getAccountCustomFieldByCode(element.code)
                                if (field !== undefined && field.value !== '') {
                                    val += element.label + ': <b>' + field.value + '</b>\n'
                                }
                            }
                        });
                    }
                    else {
                        if (accountTableConfiguration.combinedAccountCustomField !== undefined
                            && accountTableConfiguration.combinedAccountCustomField !== null) {
                            if (accountTableConfiguration.combinedAccountCustomField.length > 0) {
                                accountTableConfiguration.combinedAccountCustomField.forEach((element: any) => {
                                    if (element.isSelected && element.code !== undefined) {
                                        var field = this.getAccountCustomFieldByCode(element.code)
                                        if (field !== undefined && field.value !== '') {
                                            val += element.label + ': <b>' + field.value + '</b>\n'
                                        }
                                    }
                                });
                            }
                        }
                    }
                }
            }
        }

        return val
    }

    toString() {
        var sortedHeader = getSortedHeader()
        if (sortedHeader.length > 0) {
            var sortedValue: string[] = []
            sortedHeader.forEach(type => {
                sortedValue.push(this.getValueByType(type))
            });
            return sortedValue
        }
        return this.getDefaultList()
    }

    getValueByType(element: any) {
        const type = element.type
        switch (type) {
            case TableColumnPopupType.date:
                return this.date
            case TableColumnPopupType.journalEntryNumber:
                return getLocalisedText(this.journalEntryNumber)
            case TableColumnPopupType.accountName:
                return getLocalisedText(this.accountName)
            case TableColumnPopupType.accountCode:
                return getLocalisedText(this.accountCode)
            case TableColumnPopupType.description:
                return getLocalisedText(this.description)
            case TableColumnPopupType.credit:
                return this.credit
            case TableColumnPopupType.debit:
                return this.debit
            case TableColumnPopupType.accountCustomField:
                if (element.code !== undefined) {
                    var value = ''
                    var field = this.getAccountCustomFieldByCode(element.code)
                    if (field !== undefined) {
                        value = field.value
                    }
                    return value
                }
                return this.getCombineAccountCustomField()
            default:
                return ''
        }
    }

    getDefaultList() {
        return [
            this.date,
            getLocalisedText(this.journalEntryNumber),
            getLocalisedText(this.accountCode),
            getLocalisedText(this.accountName),
            getLocalisedText(this.description),
            Utility.toCurrencyFormat(this.credit, this.currency),
            Utility.toCurrencyFormat(this.debit, this.currency),
        ]
    }
}
