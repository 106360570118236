import { DKIcons } from 'deskera-ui-library';
import {
  COUNTRIES_WITH_CURRENCIES,
  CURRENCY_SYMBOLS
} from '../constants/Constant';

export const getCookies = (name: string) => {
  const cookies = document.cookie
    .split('; ')
    .find((row) => row.startsWith(`${name}=`))
    ?.split('=')[1];
  return cookies;
};
export const setCookie = (name: string, value: any) => {
  document.cookie = name + '=' + value + '; Path=/;';
};
export const deleteCookie = (name: string) => {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};
export function deepClone<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}
export const isEmptyObject = (objectToCheck: any) => {
  if (
    objectToCheck === null ||
    objectToCheck === undefined ||
    objectToCheck.length === 0
  )
    return true;
  if (Array.isArray(objectToCheck)) return !objectToCheck.length;
  if (typeof objectToCheck === 'string') return !objectToCheck.trim().length;
  return Object.keys(objectToCheck).length === 0;
};
export const isNotEmpty = (objectToCheck: any) => !isEmptyObject(objectToCheck);

/**
 *
 * @param currencyCode - currency code
 * @returns - currency symbol for given currency code otherwise empty string
 */
export const getCurrencySymbolFromCode = (currencyCode: string) => {
  const country = COUNTRIES_WITH_CURRENCIES.find(
    (currency) => currency.currencyCode === currencyCode
  );
  let symbol = '';
  if (country?.currencyCode) {
    symbol = CURRENCY_SYMBOLS[country.currencyCode];
  }
  return symbol;
};
export function toCurrencyFormat(
  number: number,
  currencySymbol = CURRENCY_SYMBOLS['USD']
) {
  return (
    currencySymbol +
    ' ' +
    parseFloat('' + number)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  );
}
export const debounce = (func: any, wait: number) => {
  let timeout: any;
  return (...args: any) => {
    const context = this;
    const later = () => {
      timeout = null;
      func.apply(context, args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};
export const parseJwt = (token: string) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};
export const getFileExtensionByName = (fileName: string) => {
  let fileType = fileName?.split('.');
  return fileType.pop();
}
export const getFileIcon = (fileName: string) => {
  switch (getFileExtensionByName(fileName)) {
    case 'txt':
      return DKIcons.doc_type.ic_txt;
    case 'pdf':
      return DKIcons.doc_type.ic_pdf;
    case 'jpg':
    case 'jpeg':
      return DKIcons.doc_type.ic_jpg;
    case 'png':
    case 'svg':
    case 'bmp':
      return DKIcons.doc_type.ic_png;
    case 'doc':
    case 'docx':
      return DKIcons.doc_type.ic_doc;
    case 'xlsx':
    case 'xls':
      return DKIcons.doc_type.ic_xls;
    case 'csv':
      return DKIcons.doc_type.ic_csv;
    case 'pptx':
    case 'ppt':
      return DKIcons.doc_type.ic_ppt;
    default:
      return DKIcons.ic_attachment;
  }
};
export const openInNewTab = (url: string) => {
  window.open(url, '_blank');
};
export function getCapitalized(name: string) {
  return name?.charAt(0).toUpperCase() + name?.slice(1);
}
//Check for in-app webview.
//Do not use this method.
export function isMobileAppWebView() {
  let standalone =
      'standalone' in window.navigator && window.navigator['standalone'],
    userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent);

  if (ios && !standalone && !safari) {
    return true; // iOS Webview
  } else if (userAgent.includes('wv')) {
    return true; // android Webview
  }

  return false;
}

export const encodeJSON = (json: any) => {
  let temp = encodeURI(JSON.stringify(json));
  return btoa(temp);
};
export const decodeJSON = (base64String: string) => {
  return JSON.parse(decodeURI(atob(base64String)));
};
export function triggerDownload(
  blobContent: any,
  fileName: string,
  altSrc: string
) {
  const downloadAnchor = document.createElement('a');
  downloadAnchor.setAttribute('download', fileName);
  /* Checking If download feature supported in browser */
  if (downloadAnchor.download !== undefined) {
    downloadAnchor.href = blobContent
      ? URL.createObjectURL(blobContent)
      : altSrc;
    document.body.appendChild(downloadAnchor);
    downloadAnchor.click();
    document.body.removeChild(downloadAnchor);
  }
}
export const isString = (value: any) => {
  return (
    typeof value === 'string' ||
    value instanceof String ||
    Object.prototype.toString.call(value) === '[object String]'
  );
};
