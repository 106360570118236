let es = {
    translations: {
        "component": "Componente",
        "add": "Añadir",
        "document_type": "Tipo de Documento",
        "select_theme_color": "Seleccione el color del tema",
        "company_logo": "Logo de la compañía",
        "choose_file": "Elija archivo",
        "your_company_name": "El nombre de tu compañía",
        "company_address": "Dirección de la empresa",
        "currency": "De moneda",
        "no": "No",
        "terms_&_condition": "Términos y Condiciones",
        "notes": "Notas",
        "date_format": "Formato de fecha",
        "line_items": "Artículos de línea",
        "item_name": "Nombre del árticulo",
        "bill_to": "Cobrar a",
        "ship_to": "Envie a",
        "valid_till": "Válida hasta",
        "due_date": "Fecha de vencimiento",
        "date": " Fecha",
        "item_description": "Descripción del Artículo",
        "discount": "Descuento",
        "tax": "Impuesto",
        "delete": "Eliminar",
        "add_new_item": "Agregar ítem nuevo",
        "signature": "Firma",
        "show_discount": "Mostrar descuento",
        "show_tax": "Mostrar impuestos",
        "company_name": "nombre de empresa",
        "company_billing_address": "Dirección de facturación de la empresa",
        "billing_address_placeholder_text_value": "Nombre del cliente \nDirección de facturación de su cliente",
        "shipping_address_placeholder_text_value": "Nombre del cliente \nDirección de envío de su cliente",
        "terms_and_condition_placeholder_text": "El pago vence en 30 días",
        "notes_placeholder_text": "Pague el saldo adeudado a tiempo",
        "hide": "Esconder",
        "show": "mostrar",
        "estimate": "Estimar",
        "quotation": "Cotización",
        "invoice": "Factura",
        "purchase_order": "Orden de compra",
        "bills": "Facturas",
        "total": "Total",
        "number": "Número",
        "edit":"Edit",
        "products": "Productos",
        "description": "Descripción",
        "qty": "Cant.",
        "unit_price": "Precio unitario",
        "unit price": "Precio unitario",
        "price": "Precio",
        "amount": "Cantidad",
        "subtotal": "Total parcial",
        "choose_a_template": "Elige una plantilla",
        "go_to_deskera": "Ir a Deskera",
        "print_this_document": "Imprime este documento",
        "send_email_to_clients": "Enviar correo electrónico a clientes",
        "document_generator": "Generador de documentos",
        "please_wait": "por favor espera...",
        "download_as_pdf": "Descargar como (.pdf)",
        "download": "Descargar",
        "print": "Impresión",
        "share": "Compartir",
        "try_deskera": "Prueba Deskera",
        "copy_link": "Copiar link",
        "share_on_the_web": "Comparte en la Web",
        "anyone_with_the_below_link_can_view": "Cualquiera con el siguiente enlace puede ver",
        "send_your": "Envía tu",
        "quickly": "Con rapidez",
        "please_sign_up_with_deskera_to_send_your": "Regístrese con Deskera para enviar su",
        "dont_worry_your": "No te preocupes, tu",
        "will_still_be_here": "todavía estará aquí.",
        "sign_up_for_free": "Registrate gratis",
        "already_a_user_Sign_in": "¿Ya eres usuario? Registrarse",
        "share_on_mail": "Compartir por correo electrónico",
        "to": "A",
        "subject": "Tema",
        "enter_recipient_email_address": "Ingrese la dirección de correo electrónico del destinatario",
        "enter_subject_here": "Ingrese el asunto aquí",
        "send": "Enviar",
        "cancel": "Cancelar",
        "edit_table": "Editar tabla",
        "save": "Salvar",
        "buyer": "Comprador",
        "seller": "Vendedor",
        "powered_by_deskera": "Desarrollado por Deskera",
        "details": "Detalles",
        "other_comments": "Otros comentarios",
        "bill": "Cuenta",
        "order": "Orden",

        "email": "Email",

        "items": "Artículos",
        "services": "Servicios",
        "hours": "Horas",
        "rate": "Velocidad",
        "other": "Otro",
        "sales_tax": "Impuesto de venta",
        "tax_rate": "Tasa de impuesto",
        "sales tax": "Impuesto de venta",
        "tax rate": "Tasa de impuesto",

        "num": " número",
        "template_update_failure_message": "No se pudo actualizar la configuración. ¡Inténtalo de nuevo!",
        "template_save_failure_message": "No se pudo guardar la configuración. ¡Inténtalo de nuevo!",
        "template_update_success_message": "Plantilla guardada correctamente",
        "template_save_success_message": "Plantilla guardada correctamente",
        "additional_information": "Información Adicional",
        "other_options": "Otras opciones",

        "image": "Imagen",
        "text": "Texto",
        "watermark": "Filigrana",
        "banner": "Bandera",
        "custom_field": "Campos personalizado",
        "component_list": "Lista de componentes",

        "email_address": "Dirección de correo electrónico",
        "is_not_valid": "no es válido.",

        "deskera_suites": "Suites Deskera",
        "deskera_books": "Libros Deskera",
        "deskera_people": "Gente Deskera",
        "deskera_sales": "Ventas Deskera",
        "try_now": "Probar ahora",


        "text_color": "Color de texto",
        "image_ratio": "Proporción de imagen (%)",
        "text_size": "Tamano del texto",
        "opacity": "Opacidad",
        "font_style": "Estilo de fuente",
        "font_weight": "Peso de fuente",
        "text_alignment": "Alineación del texto",
        "image_alignment": "Alineación de imagen",
        "image_preview": "Vista previa de imagen",
        "preview_banner": "Banner de vista previa",
        "banner_selection": "Selección de banner",
        "duplicate": "Duplicar",
        "settings": "Configuraciones",
        "expiry": "Caducidad",
        "batch_no": "Número de lote",

        "saving": "Ahorro...",
        "save_template": "Guardar plantilla",

        "name": "Nombre",

        "stock_items": "Artículos en stock",

        "sending": "Enviando...",
        "set_as_default": "Establecer predeterminado",

        "no_email_subject": "¡No se encontró ningún asunto personalizado! Escriba su asunto personalizado y presione guardar plantilla",

        "expense": "Expense",
        "deposit": "Deposit",
        "credit_note": "Credit Note",
        "debit_note": "Debit Note",
        "accounts": "Cuentas",
        "customer_address_placeholder_text_value": "Nombre del cliente \nDirección de su cliente",
        "account_name_placeholder_text_value": "Código de cuenta \nNombre de cuenta",
        "payment_to": "Pago a",
        "from_account": "Desde la cuenta",
        "payment_from": "Pago desde",
        "to_account": "A la cuenta",
        "payment_date": "Fecha de pago",
        "document_date": "Fecha del documento",
        "account_name": "Nombre de la cuenta",
        "account_description": "Descripción de la cuenta",
        "tax_amount": "Monto de impuestos",
        "payment_amount": "Importe del pago",
        "line_amount": "Cantidad de línea",
        "total_tax_amount": "Impuesto Monto Total",
        "contact": "Contacto",
        "contact_address_placeholder_text_value": "Nombre de contacto\nDirección de su contacto",

        "printing": "Imprimiendo..",
        "downloading": "Descargando..",

        "incorrect_format": "¡Formato incorrecto!",
        "few_formats_allowed": "Solo se permiten archivos jpg, jpeg, png",
        "image_missing": "¡Falta la imagen!",
        "data_missing": "¡Faltan datos!",
        "select_image_to_proceed": "Seleccione la imagen para continuar",
        "no_data_to_export": "No hay datos para exportar",

        "session_expired_title": "¡Sesión expirada!",
        "session_expired_message": "Tu sesión ha caducado. Ingrese nuevamente.",
        "login": "Acceso",
        "generate_link_to_share": "Obtenga el enlace",
        "burn_after_read": "Quemar después de leer",
        "generate_link": "Generar",
        "no_never": "Nunca",
        "1_min": "1 minuto",
        "1_hr": "1 hora",
        "1_day": "1 día",
        "1_year": "1 año",

        "document_expired_title": "¡Documento expirado!",
        "document_expired_message": "Este documento ha caducado. Por favor contacte al remitente",

        "loading": "Cargando...",
        "reference_date": "Fecha de referencia",
        "cheque_number": "Número de cheque",
        "deposit_to": "Depositar a",

        "password": "Contraseña",
        "password_empty_title": "¡Contraseña vacía!",
        "password_empty_message": "La contraseña no puede estar vacía. Ingrese una contraseña y vuelva a intentarlo.",
        "document_not_found_title": "¡Documento no encontrado!",
        "document_incorrect_password_title": "¡Contraseña incorrecta!",
        "document_incorrect_password_message": "Ingrese la contraseña correcta y vuelva a intentarlo.",
        "proceed": "Continuar",
        "document_enter_password_title": "Documento protegido con contraseña.",
        "document_enter_password_message": "Porfavor ingrese una contraseña.",

        "preparing_document_email": "Preparando documento para enviar por correo electrónico…",
        "preparing_document_print": "Preparando documento para imprimir…",
        "loading_the_page": "Cargando la pagina",
        "sender": "Remitente",
        "reply_to": "Responder a",
        "enter_sender_name": "Ingrese el nombre del remitente",
        "enter_reply_to_email": "Ingresar respuesta al correo electrónico",
        "send_a_copy_to_myself_at": "Enviarme una copia a mí mismo a",

        "payment": "Pago",
        "paper_format": "Formato de papel",
        "please_provide_paper_format": "Indique el formato de papel",
        "paper_format_popup_note": "Aún puede actualizar el formato de papel desde el panel de configuración",
        "item_number": "Número de artículo",
        "item_code": "Código del objeto",
        "received_from ": " Formulario recibido ",
        "payment_details": "Detalles del pago",
        "sn_number": "Número de serie",
        "document_details": "detalles del documento",
        "template_saved": "¡Plantilla guardada!",
        "you_design_template_has_been_saved": "La plantilla se ha guardado, puede cerrar esta ventana y volver a la aplicación Books.",
        "you_design_template_has_been_saved_people": "La plantilla se ha guardado, puede cerrar esta ventana y volver a la aplicación PEOPLE.",
        "go_to_books": "Ir a libros",
        "item_tax": "Impuesto sobre el producto",
        "item_discount": "Descuento de producto",
        "uom": "UOM",

        "please_select_custom_field": "Seleccione un campo personalizado.",


        "preview_pdf": "Vista previa del PDF",

        "template_name": "Nombre de la plantilla",
        "please_enter_template_name_to_save": "Introduzca el nombre de la plantilla para guardar",
        "custom_template": "Plantilla personalizada",
        "add_new_template": "Agregar nueva plantilla personalizada",
        "vendor_name": "Nombre del vendedor",

        "decimal_point": "Punto decimal",
        "footer": "Pie de página",

        "save_as": "Guardar como",
        "save_as_new_template": "Guardar como plantilla nueva",
        "e_invoice_section": "sección de factura electrónica",
        "amount_before_tax": "Monto antes de impuestos",

        "order_no": "Order No",
        "PURCHASE_INVOICE": 'Cuenta',
        "PURCHASE_ORDER": 'Orden',
        "SALES_INVOICE": "Factura",
        "QUOTATION": "Cotización",
        "fulfillment": "Cumplimiento",
        "ship_from": "Enviar desde",
        "company_address_placeholder_text_value": "Nombre de la empresa\nDirección de su empresa",
        "goods_receipt": "Entrada de mercancías",
        "required_qty": "Cantidad requerida",
        "received_qty": "Cantidad recibida",
        "committed_qty": "Cantidad comprometida",
        "warehouse_code": "Código de almacén",
        "warehouse_name": "nombre del almacén",

        "payment_footer": "Pago",
        "make_payment": "Realizar pago",
        "receive_payment": "Recibir pago",
        "deposit_advpayment": "Depósito por adelantado",
        "expense_prepayment": "Prepago",

        "delete_template": "Eliminar plantilla",
        "delete_template_message": "¿Está seguro de que desea eliminar la plantilla? Una vez eliminada, la plantilla no se puede recuperar.",
        "delete_template_ok_label": "Sí. Eliminarlo.",
        "delete_template_success_message": "Plantilla eliminada correctamente.",
        "delete_template_failure_message": "No se pudo eliminar la configuración. ¡Vuelve a intentarlo!",
        "before_tax": "Antes de impuestos",
        "outstanding": "Cantidad pendiente",

        "signature_section": "Sección de firmas",
        "prepared_by": "Preparado por",
        "approved_by": "Aprobado por",

        "no.": 'No.',
        "#": "#",
        "serial_batch": "Serial/Batch",
        "amount_in_words": "Importe en palabras",
        "name_with_description": "Nombre",

        "invoice_number": "Número de factura",
        "bill_number": "Número de factura",
        "total_invoice_amount": "Importe total de la factura",
        "total_bill_amount": "Importe total de la factura",
        "payment_made": "Pago realizado",
        "current_due_amount": "Importe adeudado actual",
        "current_balance": "Saldo actual",
        "payment_medium": "Medio de pago",
        "cash": "Efectivo",
        "prepayment": "prepago",
        "payment_label": "Pago",
        "receipt_label": "Recibo",
        "total_row": "Fila total",

        "journal_entry": "Entrada de diario",
        "journal_entry_number": "JE no.",
        "account_code": "Código de cuenta",
        "credit": "Crédito",
        "debit": "Débito",
        "GOOD_RECEIPT": "Entrada de mercancías",
        "MAKE_PAYMENT": "Pago",
        "RECEIVE_PAYMENT": "Pago",
        "CREDIT_NOTE": "Credit Note",
        "DEBIT_NOTE": "Debit Note",
        "FULFILLMENT": "Cumplimiento",
        "SALES_RETURN": "Devolución de ventas",
        "PURCHASE_RETURN": "Devolución de compra",
        "SALES_CONTRA": "Contra de ventas",
        "PURCHASE_CONTRA": "Contratar compra",
        "BANK_TRANSFER": "Transferencia bancaria",
        "TDS_JE": "TDS JE",
        "ADJUSTMENT": "Ajuste",
        "memo": "Memorándum",

        "pick_list": "Lista de selección",
        "pack_list": "Lista de paquete",
        "ship_list": "Lista de envíoo",
        "invoice_or_quotation": "Factura / Cotización",
        "product_code": "Código de producto",
        "product_name": "nombre del producto",
        "barcode": "Código de barras",
        "quantity_to_pick": "Cantidad a elegir",
        "quantity_required_for_order": "Cantidad requerida para la orden",
        "picked": "Escogido",
        "item_no": "Artículo No.",
        "unit": "Unidad",
        "packed_quantity": "Cantidad empaquetada",
        "warehouse_address": "Dirección del almacén",
        "delivery": "Entrega",
        "carrier": "Transportador",
        "number_of_carton": "Número de cartón",
        "weight": "Peso",
        "tracking_number": "El número de rastreo",
        "package": "Paquete",
        "order_number": "Número de orden",

        "custom_field_is_missing_title": "Falta el campo personalizado",
        "custom_field_is_missing_message": "Falta el campo personalizado en este documento. Por favor, agregue un campo personalizado del Libro o intente con otro documento.",

        "repeatable_header": "Encabezado de tabla repetible",
        "margin": "Margen",
        "default": "Defecto",
        "none": "Ninguno",
        "minimum": "Mínimo",
        "large": "Grande",

        "receive_by": "Recibir por fecha",
        "ship_by": "Enviar por fecha",
        "additional_date": "Fecha adicional",

        "header_footer_section": "Sección de encabezado y pie de página",
        "header": "Encabezamiento",
        "date_only": "Solo fecha",
        "title_only": "Sólo titulo",
        "page_count_only": "Solo recuento de páginas",
        "date_title": "Fecha y título",
        "date_page_count": "Fecha y recuento de páginas",
        "title_page_count": "Título y recuento de páginas",
        "date_title_space": "Fecha y título con espacio",
        "date_page_count_space": "Fecha y recuento de páginas con espacio",
        "title_page_count_space": "Título y recuento de páginas con espacio",
        "date_title_page_count": "Fecha, título, número de páginas",
        "custom": "Personalizado",
        "left": "Lado izquierdo",
        "center": "Centrar",
        "right": "Lado derecho",
        "title": "Título",
        "page_count": "Recuento de páginas",
        "current_page_count": "Recuento de páginas actual",
        "total_page_count": "Recuento total de páginas",
        "custom_text": "Texto personalizado",
        "page": "Página",

        "company_phone_number": "Número de teléfono de la empresa",
        "company_gstin": "Empresa GSTIN",
        "company_state_name_and_code": "Nombre y código del estado de la empresa",
        "payment_terms": "Términos de pago",
        "customer_phone_number": "Número de teléfono del cliente",
        "customer_gstin": "Cliente GSTIN",
        "customer_state_name_and_code": "Nombre y código del estado del cliente",
        "print_only_message": "Esta es una computadora generada",
        "hsn_or_sac": "HSN / SAC",
        "taxable_value": "Valor imponible",
        "integrated_tax": "Impuesto Integrado",
        "central_tax": "Impuesto central",
        "state_tax": "Impuesto estatal",
        "cess": "IMPUESTO",
        "state_name": "Nombre del Estado",
        "state_code": "Código",

        "address_format": "Formato de dirección",
        "address_1": "Dirección 1",
        "address_2": "Dirección 2",
        "city": "Ciudad",
        "state": "Estado",
        "postal_code": "Código postal",
        "country": "País",
        "is_apply_address_format_to_other_addresses": "Aplicar el formato de dirección actual a todas las direcciones.",
        "product_grouping": "Agrupación de productos",
        "cheque": "Comprobar",
        "bank_transfer": "Transferencia bancaria",
        "card": "Tarjeta",

        "payslip": "Nómina",
        "earning_statement": "Declaración de ganancias",
        "pay_period": "Período de pago",
        "pay_date": "Fecha de pago",
        "ssn": "SSN",
        "taxable_marital_status": "Estado civil imponible",
        "exemptions": "Exenciones",
        "date_of_joining": "Fecha de inscripción",
        "pf_a_c_NUMBER": "Número PF A / C",
        "esi_number": "Número ESI",
        "uan_number": "Número UAN",
        "employee_id": "ID de empleado",
        "designation": "Designacion",
        "employee_net_pay": "Pago neto del empleado",
        "employee_earnings": "Ingresos de los empleados",
        "allowances": "Asignaciones",
        "deductions": "Deducciones",
        "employer_contribution": "Contribución del empleador",
        "employer_cpf": "CPF del empleador",
        "net_salary_paid": "Salario neto pagado",
        "employee_taxes_withhold": "Retención de impuestos a los empleados",
        "employer_taxes": "Impuestos del empleador",
        "employee_deductions": "Deducciones de empleados",
        "summary": "Resumen",
        "basic_salary": "Salario base",
        "net_pay": "Salario neto",
        "additional_earnings": "Ganancias adicionales",
        "gross_earnings": "Ingresos completos",
        "duration": "Duración",
        "current": "Actual",
        "year_to_date": "El año hasta la fecha",
        "type": "Escribe",
        "pre_tax_deductions_contributions": "Contribuciones / deducciones antes de impuestos",
        "taxes": "Impuestos",
        "post_tax_deductions_contributions": "Contribuciones / deducciones después de impuestos",
        "net_pay_contributions": "Pago neto / contribuciones",
        "check_amount": "Monto del cheque",
        "payslip_auto_message": "Este es un recibo de pago generado por el sistema que no requiere sello y firma",
        "employee_address": "dirección del empleado",
        "field_configuration": "Configuración de campo",
        "table_configuration": "Configuración de la mesa",
        "employee_name_address": "Nombre y dirección del empleado",
        "header_title": "Título del encabezado",
        "product_custom_field": "Campos personalizados del producto",
        "quantity_and_uom": "Cantidad y unidad de medida",
        "includes_product_custom_field": "Incluye campos personalizados de productos",
        "combines_product_custom_field": "Combina campos personalizados de productos",
        "serial_number": "Número de serie",
        "batch_number": "Número de lote",
        "manufacturing_date": "Fecha de fabricación",
        "expiry_date": "Fecha de caducidad",
        "override_theme_color": "Anular el color del tema",
        "override_global_color": "Anular color global",
        "contact_number": "Número de teléfono",
        "contact_code": "Código de contacto",
        "blank": "Vacía",
        "pay_to": "Pagar a",
        "payroll_check": "Cheque de nómina",
        "pay_to_the": "PAGAR AL",
        "order_of": "ORDEN DE",
        "dollars": "DÓLARES",
        "payroll_period": "Nómina para el período de",
        "repeatable_header_footer": "Pie de página de encabezado repetible",
        "repeatable_footer": "Pie de página repetible",
        "includes_hsn_or_sac_code": "Incluye Código HSN/SAC",
        "eway_bill_no":  "Eway factura no",
        "eway_bill_date": "Fecha de factura electrónica",
        "place_of_supply":'Lugar de suministro',
        "destination_of_supply":"Destino del suministro",
        "source_of_supply":"Fuente de suministro",
        "subTable_configuration": "Configuración de subtabla",
        "additional_table_configuration": "Configuración de mesa adicional",
        "select_new_type": "Seleccionar nuevo tipo",
        "qr_code": "Código QR",
        "qr_string": "Cadena QR",
        "generated_qr": "QR generado",
        "qr_code_with_text": "Código QR con texto",
        "image_with_text": "Imagen con texto",
        "qr_information": "Información QR",
        "additional_info": "Información adicional",
        "global_discount": "Descuento mundial",
        "pre_tax": "antes de impuestos",
        "post_tax": "después de impuestos",
        "includes_uom_schema": "Incluye esquema UOM",
        "table": "Mesa",
        "font": "Fuente",
        "table_row_height": "Altura de la fila de la tabla",
        "padding_horizontal": "Acolchado Horizontal",
        "padding_vertical": "Acolchado Vertical",
        "original": "Original",
        "original_for_recipient": "Original para destinatario",
        "duplicate_supply_of_goods": "Duplicado (Suministro De Bienes)",
        "duplicate_for_transporter": "Duplicado para transportador",
        "duplicate_supply_of_service": "Duplicado (Suministro De Servicio)",
        "duplicate_for_supplier": "Duplicado para proveedor",
        "triplicate": "Triplicado",
        "triplicate_for_supplier": "Triplicado para proveedor",
        "position": "Posición",
        "middle": "Medio",
        "sample_watermark": "Muestra de marca de agua",
        "extra_copy": "Copia adicional",
        "quadruplicate": "Cuadruplicado",
        "MAX_IMG_UPLOAD_ALERT": "El tamaño de la imagen no puede ser superior a 500 KB",
        "system_default": "Sistema por defecto",
        "qr_resize": "Redimensionar QR",
        "top": "Parte superior",
        "bottom": "Abajo",
        "click_here_to_pay": "Haga clic aquí para pagar",
        "generated_message": "Mensaje generado",
        "sales_order": "Órdenes de venta",
        "help": "Ayudar",
        "includes_account_custom_field": "Incluye campos personalizados de productos",
        "combines_account_custom_field": "Combina campos personalizados de productos",
        "account_custom_field": "Campos personalizados de la cuenta",
        "selection": "Selección",
        "alias_name_address": "Nombre y dirección de alias",
        "nric_fin_wpn": "NRIC/FIN/WPN",
        "paid_days": "Días pagados",
        "unpaid_days": "Días no remunerados",
        "payslip_overtime": "Con el tiempo",
        "payslip_LOP": "Pérdida de pago",
        "outstanding_balance": "Saldo pendiente",
        "outstanding_balance_includes_current": "Saldo pendiente (incluido el actual)",
        "top_section_above_table": "Sección superior sobre la mesa",
        "is_group_additional_charges_tax": "Si el impuesto de recargo de grupo",
        "supplier_invoice_no": "Número de factura del proveedor",
        "default_template": "Plantilla predeterminada",
        "contact_name": "Nombre de contacto",
        "current_date": "Fecha actual",
        "e_way_bill_summary": "Resumen de la factura de Eway",
        "e_way_bill_detail": "Detalle de factura electrónica",
        "table_column_alignment": "Alineación de columnas de tabla",
        "table_column_vertical_alignment": "alineación vertical",
        "earnings_configuration": "Configuración de ganancias",
        "label_empty_field_message": "La etiqueta del campo no puede estar vacía, ingrese algún valor para continuar.",
        "product_table": "Tabla de productos",
        "rounding_off": "Redondeando",
        "work_order": "Orden de Trabajo",
        "order_prep_completed": "Preparación de la Orden Completada",
        "barcode_settings": "Configuración de Código de Barras",
        "barcode_resize": "Cambiar el Tamaño del Código de Barras",
        "comments": "Comentarios",
        "delivery_date": "Fecha de entrega",
        "estimated_time": "Tiempo estimado",
        "actual_quantity": "Cantidad real",
        "manufacture_quantity": "Cantidad de fabricación",
        "parent_work_order": "Orden de trabajo principal",
        "stock_transfer": "Transferencia de stock",
        "source": "Fuente",
        "destination": "Destino",
        "source_address": "Dirección de origen",
        "destination_address": "Dirección de destino",
        "transfer_date": "Fecha de transferencia",
        "verify_date": "Fecha de verificación",
        "reason": "Motivo",
        "warehouse": "Almacén",
        "stock_adjustment": "Ajuste de stock",
        "source_warehouse_name": "Nombre del almacén de origen",
        "source_warehouse_code": "Código del almacén de origen",
        "destination_warehouse_name": "Nombre del almacén de destino",
        "destination_warehouse_code": "Código del almacén de destino",
        "product": "Producto",
        "show_product": "Mostrar Producto",
        "hide_product_with_substitute": "Ocultar Producto con Sustitutos",
        "substitute": "Sustituto",
        "show_all_substitute": "Mostrar Todos los Sustitutos",
        "show_non_zero_substitute": "Mostrar Sustitutos Asignados",
        "hide_substitute": "Ocultar Sustituto",
        "combines_line_item_global_discount": "Combina el descuento global del elemento de línea",
        "unit_price_before_discount": "Precio unitario antes del descuento",
        "unit_price_after_discount": "Precio unitario después del descuento",
        "discount_per_unit": "Descuento por unidad",
        "show_all": "Mostrar Todos",
        "percentage": "Porcentaje",
        "cogs": "Costo de los bienes vendidos",
        "na": "NA",
        "con_number": "Número de Pedido del Cliente",
        "document_label": "Etiqueta de código de barras",
        "expected_delivery_date": "Fecha de Entrega Esperada",
    }
};

export default es
