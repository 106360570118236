import { addMonths } from 'date-fns';
import { DATE_FORMATS } from '../../constants/Enum';
import { ISaleOrder, SalesOrderItem } from '../../models/Order';
import { getDateStrFromDate } from '../../utility/Date';
import { ICartResponse } from '../cart';
import store from '../../store';

export const prepareSalesOrderPayload = (
  products: any[],
  contact: any
): ISaleOrder => {
  const items: SalesOrderItem[] = products.map((product: ICartResponse) => ({
    advancedTracking: product.productInfo?.advancedTracking,
    amount: product.salesPrice,
    currency: '',
    discount: 0,
    discountAmount: 0,
    discountInPercent: false,
    documentSequenceCode: product.productInfo?.documentSequenceCode,
    documentUom: product.productInfo?.stockUom,
    gstType: 0,
    hsnOrSacCode: product.productInfo?.hsnOrSacCode,
    productCode: product.productInfo?.pid,
    productDescription: product.description,
    productName: product.name,
    productQuantity: product.cartQuantity,
    stockUom: product.productInfo?.stockUom,
    subTotal: (product.cartQuantity ?? 0) * (product.salesPrice ?? 0),
    taxAmount: 0,
    taxSystem: '',
    total: (product.cartQuantity ?? 0) * (product.salesPrice ?? 0),
    totalAmount: (product.cartQuantity ?? 0) * (product.salesPrice ?? 0),
    totalDiscountAmount:
      (product.cartQuantity ?? 0) * (product.salesPrice ?? 0),
    totalWithDiscount: (product.cartQuantity ?? 0) * (product.salesPrice ?? 0),
    type: product.productInfo?.type,
    unitPrice: product.salesPrice ?? 0,
    uomQuantity: product.cartQuantity ?? 0,
    uomUnitPrice: product.salesPrice ?? 0
  }));
  const totalAmount = items.reduce(
    (acc: number, curr: SalesOrderItem) => (acc || 0) + (curr.amount || 0),
    0
  );
  const tenantDetails = store.getState().books.tenantDetails;

  const dateFormat: keyof typeof DATE_FORMATS = 'DD-MM-YYYY';
  return {
    contact: {
      code: contact.code,
      name: contact.name
    },
    billTo: contact?.billingAddress?.find((address: any) => address.preferred),
    shipTo: contact?.shippingAddress?.find((address: any) => address.preferred),
    shipFrom: tenantDetails?.shippingAddresses?.find(
      (address: any) => address.preferred
    ),
    documentDate: getDateStrFromDate(new Date(), DATE_FORMATS[dateFormat]),
    documentType: 'SALES_ORDER',
    currencyCode: contact.currencyCode,
    currency: contact.currencyCode,
    salesOrderDate: getDateStrFromDate(new Date(), DATE_FORMATS[dateFormat]),
    fulfillmentDate: getDateStrFromDate(new Date(), DATE_FORMATS[dateFormat]),
    validTillDate: getDateStrFromDate(
      addMonths(new Date(), 1),
      DATE_FORMATS[dateFormat]
    ),
    contactCode: contact.code,
    salesOrderItems: items,
    dueAmount: totalAmount,
    totalAmount,
    status: 'OPEN',
    customField: [],
    salesOrderDueDate: getDateStrFromDate(
      addMonths(new Date(), 1),
      DATE_FORMATS[dateFormat]
    )
  };
};
