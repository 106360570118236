import {
  createAsyncThunk,
  createSelector,
  createSlice
} from '@reduxjs/toolkit';
import { getCart } from '../../services/cart';
import { RootState } from '..';

interface ICartState {
  data: any;
}
const initialState: ICartState = {
  data: {}
};
export const fetchCartItems = createAsyncThunk(
  'cart/fetchCartItems',
  async (state, action) => {
    const response = await getCart();
    return response;
  }
);
export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCartItems.fulfilled, (state, action: any) => {
      state.data = action.payload;
    });
  }
});
const selectSelf = (state: RootState) => state;
export const selectCartItems = createSelector(
  selectSelf,
  (state) => state.cart.data
);
