import React, { useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';
import {
  DKLabel,
  DKButton,
  DKIcons,
  DKSpinner,
  INPUT_VIEW_DIRECTION,
  DKInput,
  INPUT_TYPE
} from 'deskera-ui-library';
import printJS from 'print-js';
import DocPreview from '../DocTemplate/DocPreview';
import {
  APP_NAME,
  DESIGNER_TEMPLATE,
} from '../../constants/Constant';
import { isEmptyObject, isNotEmpty } from '../../utility/Helper';
import {
  getPrintDesignInfoById,
  getPrintTemplateInfo
} from '../../services/books';

interface IPrintPreviewProps {
  category: string;
  document: any;
  event: string;
  onClose?: () => void;
}

const PrintPreview: React.FC<IPrintPreviewProps> = (props) => {
  const [templatePrintInfo, setTemplatePrintInfo] = useState<any>();
  const [availableForDownload, setAvailableForDownload] =
    useState<boolean>(false);
  const [isPreviewAvailable, setPreviewAvailable] = useState<boolean>(false);
  const [pdfBlob, setPdfBlob] = useState<any>();
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
    const fetchTemplatePrintInfo = () => {
      const payload = {
        category: props.category,
        event: props.event,
        contactCode: props.document?.contactCode,
        documentCode: props.document?.documentCode,
        documentType: props.document?.documentType,
        appName: APP_NAME
      };
      getPrintTemplateInfo(payload).then((res: any) => {
        if (isEmptyObject(templatePrintInfo)) {
          const defaultTemplate = res?.documentTemplates?.find(
            (template: any) => template?.defaultTemplate
          );
          setSelectedTemplate(defaultTemplate || DESIGNER_TEMPLATE);
          setTemplatePrintInfo(res);
          setPreviewAvailable(true);
        }
      });
    };
    fetchTemplatePrintInfo();
  }, []);

  useEffect(() => {
    const fetchDocumentDataToPrint = () => {
      if (isNotEmpty(templatePrintInfo?.code)) {
        setAvailableForDownload(false);
        try {
          const params = {
            tid: selectedTemplate?.id,
            preview: false,
            thermalPrint: null,
            skipDocumentTemplateMapping: true
          };
          getPrintDesignInfoById(templatePrintInfo.code, params)
            .then((responseBody: any) => {
              const responseBlob = new File([responseBody], 'preview.pdf');
              const fileReader: FileReader = new FileReader();
              fileReader.onloadend = (loadEvent: any) => {
                const bufferValue = new Uint8Array(loadEvent.target.result);
                setPdfBlob(bufferValue);
                setAvailableForDownload(true);
              };
              fileReader.readAsArrayBuffer(responseBlob);
            })
            .catch(() => {})
            .finally(() => {});
        } catch (error) {}
      }
    };
    fetchDocumentDataToPrint();
  }, [selectedTemplate]);

  const getUpdatedOptions = () => {
    const templates: any = [DESIGNER_TEMPLATE];
    if (templatePrintInfo?.documentTemplates?.length) {
      templates.push(...templatePrintInfo.documentTemplates);
    }
    return templates;
  };
  const downloadPDF = () => {
    if (pdfBlob !== '') {
      const responseBlob = new Blob([pdfBlob], {
        type: 'application/pdf'
      });
      const fileURL = URL.createObjectURL(responseBlob);
      let docName =
        props.document?.documentSequenceCode ?? props.document?.documentCode;
      docName = docName ?? 'Document';

      const downloadAnchor = document.createElement('a');
      downloadAnchor.setAttribute(
        'download',
        `${props.document?.documentType}-${docName}.pdf`
      );

      downloadAnchor.href = fileURL;
      document.body.appendChild(downloadAnchor);
      downloadAnchor.click();
      document.body.removeChild(downloadAnchor);
    }
  };
  const handlePrint = () => {
    if (pdfBlob && pdfBlob !== '') {
      const responseBlob = new Blob([pdfBlob], {
        type: 'application/pdf'
      });
      const fileURL = URL.createObjectURL(responseBlob);
      printJS({
        type: 'pdf',
        printable: fileURL,
        onPrintDialogClose: () => {}
      });
    }
  };
  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-s p-v-s bg-gray1">
        <div className="row width-auto">
          <DKLabel text="Preview" className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Close'}
            className="bg-white border-m"
            onClick={props?.onClose}
          />

          <div
            className={`row border-radius-m dk-button-hover border-m ml-r ${
              !availableForDownload ? 'bg-white' : 'bg-button text-white'
            }`}
          >
            <DKButton
              icon={
                !availableForDownload
                  ? DKIcons.ic_download
                  : DKIcons.white.ic_download
              }
              title={'Download'}
              disabled={!availableForDownload}
              style={{ paddingRight: false ? '6px' : '12px' }}
              onClick={() => {
                downloadPDF();
              }}
            />
            {!availableForDownload && (
              <DKSpinner iconClassName="ic-s" className="mr-m" />
            )}
          </div>

          <div
            className={`row border-radius-m dk-button-hover border-m ml-r ${
              !availableForDownload ? 'bg-white' : 'bg-button text-white'
            }`}
          >
            <DKButton
              icon={
                !availableForDownload
                  ? DKIcons.ic_printer
                  : DKIcons.white.ic_printer
              }
              title={'Print'}
              disabled={!availableForDownload}
              style={{ paddingRight: false ? '6px' : '12px' }}
              onClick={async () => {
                handlePrint();
              }}
            />
            {!availableForDownload && (
              <DKSpinner iconClassName="ic-s" className="mr-m" />
            )}
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="transparent-background">
        <div
          className="popup-window overflow-hidden"
          style={{
            maxHeight: '95%',
            height: '95%',
            width: '55%',
            maxWidth: '90%',
            padding: 0,
            paddingBottom: 0
          }}
        >
          <div className="column parent-width parent-height overflow-hidden">
            {getHeader()}
            <div
              className="column parent-width"
              style={{ height: 'calc(100% - 53px)' }}
            >
              {/* <div className="row row-responsive">
                <div
                  className="row ml-l web-wm-mr-r mt-l mb-r"
                  style={{
                    width: 220,
                    height: 45,
                    paddingTop: 5
                  }}
                >
                  {templatePrintInfo && (
                    <DKInput
                      className={''}
                      readOnly={
                        isEmptyObject(selectedTemplate) || !availableForDownload
                      }
                      value={selectedTemplate}
                      formatter={(obj: any) => {
                        return obj.templateName;
                      }}
                      title="Template"
                      titleStyle={{
                        color: 'gray'
                      }}
                      type={INPUT_TYPE.DROPDOWN}
                      required={false}
                      canValidate={false}
                      direction={INPUT_VIEW_DIRECTION.VERTICAL}
                      onChange={(template: any) => {
                        setSelectedTemplate(template);
                      }}
                      dropdownConfig={{
                        className: '',
                        style: {},
                        allowSearch: false,
                        searchableKey: 'templateName',
                        canEdit: false,
                        canDelete: false,
                        data: getUpdatedOptions(),
                        renderer: (index: number, obj: any) => {
                          return <DKLabel text={`${obj.templateName}`} />;
                        }
                      }}
                    />
                  )}
                </div>
              </div> */}
              <div
                className={`row parent-height mt-3 overflow-y-auto overflow-x-hidden p-l align-items-center`}
              >
                <div
                  className={`row flex-wrap PrintPreviewHolder`}
                  style={{ gap: 10, height: '100%' }}
                >
                  {isPreviewAvailable && isNotEmpty(templatePrintInfo) && (
                    <DocPreview
                      printScale={0.85}
                      templatePrintInfo={templatePrintInfo}
                      selectedTemplate={selectedTemplate}
                      wmark={'none'}
                    />
                  )}
                  {!isPreviewAvailable && isEmptyObject(templatePrintInfo) && (
                    <div className="row parent-width parent-height align-items-center justify-content-center font-semibold">
                      <DKSpinner
                        iconClassName="ic-s-2"
                        className="ml-m"
                        title="loading document..."
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintPreview;
