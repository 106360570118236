import { TableColumnPopupType } from "../../../Components/Popup/TableColumnPopup";
import { getIsShowTableColumn } from "../../../Utilities/Utility";

export class PackingListTableWidth {

    isIdVisible: boolean;
    isCodeVisible: boolean;
    isNameVisible: boolean;
    isBarcodeVisible: boolean;
    isUnitVisible: boolean;
    isSerialBatchVisible: boolean;
    isPackedQuantityVisible: boolean;

    id: number;
    code: number;
    name: number;
    barcode: number;
    unit: number;
    serialBatch: number;
    packedQuantity: number;


    originalId: number;
    originalCode: number;
    originalName: number;
    originalBarcode: number;
    originalUnit: number;
    originalSerialBatch: number;
    originalPackedQuantity: number;

    constructor() {
        this.isIdVisible = true
        this.isCodeVisible = true
        this.isNameVisible = true
        this.isBarcodeVisible = true
        this.isUnitVisible = true
        this.isPackedQuantityVisible = true
        this.isSerialBatchVisible = true

        this.id = 10
        this.code = 20
        this.name = 20
        this.barcode = 10
        this.unit = 10
        this.serialBatch = 15
        this.packedQuantity = 15

        this.originalId = 10
        this.originalCode = 20
        this.originalName = 20
        this.originalBarcode = 10
        this.originalUnit = 10
        this.originalSerialBatch = 15
        this.originalPackedQuantity = 15

    }

    setIsShowWidth(columnData: any) {
        this.isIdVisible = getIsShowTableColumn(columnData, TableColumnPopupType.id)
        this.isCodeVisible = getIsShowTableColumn(columnData, TableColumnPopupType.code)
        this.isNameVisible = getIsShowTableColumn(columnData, TableColumnPopupType.productName)
        this.isBarcodeVisible = getIsShowTableColumn(columnData, TableColumnPopupType.barcode)
        this.isUnitVisible = getIsShowTableColumn(columnData, TableColumnPopupType.unit)
        this.isSerialBatchVisible = getIsShowTableColumn(columnData, TableColumnPopupType.serialBatch)
        this.isPackedQuantityVisible = getIsShowTableColumn(columnData, TableColumnPopupType.packedQuantity)
    }
    getTableHeaderWidths() {
        var totalWidth = 0
        totalWidth = this.getTotalWidth(totalWidth);

        if (totalWidth === 100) {
            this.resetElementOriginalWidth();
        }
        else if (totalWidth === 0) {
            this.resetElementWidth();
        }
        else {
            var totalSelectedElement = this.getTotalSelectedElement()
            if (totalSelectedElement !== 0) {
                var totalOffset = (100 - totalWidth) / totalSelectedElement
                this.updateElementWidth(totalOffset)
            }
        }
    }
    private resetElementOriginalWidth() {
        this.id = this.originalId
        this.code = this.originalCode
        this.name = this.originalName
        this.barcode = this.originalBarcode
        this.unit = this.originalUnit
        this.serialBatch = this.originalSerialBatch
        this.packedQuantity = this.originalPackedQuantity
    }

    private resetElementWidth() {
        this.id = 0
        this.code = 0
        this.name = 0
        this.barcode = 0
        this.unit = 0
        this.originalSerialBatch = 0
        this.packedQuantity = 0
    }

    private getTotalWidth(totalWidth: number) {
        if (this.isIdVisible) {
            totalWidth += this.id;
        }
        if (this.isCodeVisible) {
            totalWidth += this.code;
        }
        if (this.isNameVisible) {
            totalWidth += this.name;
        }
        if (this.isBarcodeVisible) {
            totalWidth += this.barcode;
        }
        if (this.isUnitVisible) {
            totalWidth += this.unit;
        }
        if (this.isSerialBatchVisible) {
            totalWidth += this.serialBatch;
        }
        if (this.isPackedQuantityVisible) {
            totalWidth += this.packedQuantity;
        }
        return totalWidth;
    }

    private getTotalSelectedElement() {
        var totalSelectedElement = 0
        if (this.isIdVisible) {
            totalSelectedElement += 1
        }
        if (this.isCodeVisible) {
            totalSelectedElement += 1
        }
        if (this.isNameVisible) {
            totalSelectedElement += 1
        }
        if (this.isBarcodeVisible) {
            totalSelectedElement += 1
        }
        if (this.isUnitVisible) {
            totalSelectedElement += 1
        }
        if (this.isSerialBatchVisible) {
            totalSelectedElement += 1
        }
        if (this.isPackedQuantityVisible) {
            totalSelectedElement += 1
        }
        return totalSelectedElement
    }

    private updateElementWidth(additionalWidth: number) {
        if (this.isIdVisible) {
            this.id = this.originalId + additionalWidth
        }
        if (this.isCodeVisible) {
            this.code = this.originalCode + additionalWidth
        }
        if (this.isNameVisible) {
            this.name = this.originalName + additionalWidth
        }
        if (this.isBarcodeVisible) {
            this.barcode = this.originalBarcode + additionalWidth
        }
        if (this.isUnitVisible) {
            this.unit = this.originalUnit + additionalWidth
        }
        if (this.isSerialBatchVisible) {
            this.serialBatch = this.originalSerialBatch + additionalWidth
        }
        if (this.isPackedQuantityVisible) {
            this.packedQuantity = this.originalPackedQuantity + additionalWidth
        }
    }
}