import { TableColumnPopupType } from "../../../Components/Popup/TableColumnPopup";
import CustomFieldManager from "../../../Manager/CustomFieldManager";
import TemplateSettingsManager from "../../../Manager/TemplateSettingsManager";
import { getLocalisedText } from "../../../Translate/LanguageManager";
import { getSortedHeader } from "../TableUtility";

export class WorkOrderTableItem {
    lineNumber: number;
    code: string;
    image:string;
    name: string;
    description: string;
    orderPrepCompleted: string;
    productCustomField: string;
    currency: string;
    rawItemValue: any;
    data: any;
    
    constructor(lineItem: any, data:any, currency: string) {
        this.lineNumber = lineItem.lineNumber
        this.image = lineItem.image
        this.code = this.getProductCode(lineItem)
        this.name = this.getProductName(data, lineItem)
        this.orderPrepCompleted = ''
        this.productCustomField = ''
        this.description = this.getProductDescription(lineItem)
        this.rawItemValue = lineItem
        this.currency = currency
        this.data = data
    }

    getProductCode(lineItem: any): string {
        var code = lineItem.code
        var workOrderSettings = TemplateSettingsManager.getWorkOrderSettings()

        if (workOrderSettings !== undefined && workOrderSettings !== null) {
            if (workOrderSettings['product'] !== undefined && workOrderSettings['product'] !== null) {
                if (workOrderSettings['product'] === 'SHOW_PRODUCT') {
                    if (workOrderSettings['substitute'] === 'SHOW_ALL_SUBSTITUTE') {
                        code = code + '<br/>' + lineItem.allProductSubstitutesCode.join('<br/>')
                    }
                    else if (workOrderSettings['substitute'] === 'SHOW_NON_ZERO_SUBSTITUTE') {
                        code = code + '<br/>' + lineItem.productSubstitutesCode.join('<br/>')
                    }
                }
                else if (workOrderSettings['product'] === 'HIDE_PRODUCT_WITH_SUBSTITUTE') {
                    if (lineItem.canHideProductName) {
                        if (workOrderSettings['substitute'] === 'SHOW_ALL_SUBSTITUTE') {
                            code = lineItem.allProductSubstitutesCode.join('<br/>')
                        }
                        else if (workOrderSettings['substitute'] === 'SHOW_NON_ZERO_SUBSTITUTE') {
                            code = lineItem.productSubstitutesCode.join('<br/>')
                        }
                    }
                    else {
                        if (workOrderSettings['substitute'] === 'SHOW_ALL_SUBSTITUTE') {
                            code = code + '<br/>' + lineItem.allProductSubstitutesCode.join('<br/>')
                        }
                        else if (workOrderSettings['substitute'] === 'SHOW_NON_ZERO_SUBSTITUTE') {
                            code = code + '<br/>' + lineItem.productSubstitutesCode.join('<br/>')
                        }
                    }
                }
            }
        }
        if (code === '') {
            code = lineItem.code
        }
        return code
    }

    getIsContainsScrapOrCoproduct(data: any) {
        if (data.isContainsScrapProduct !== undefined && data.isContainsCoproduct !== undefined) {
            if (data.isContainsScrapProduct || data.isContainsCoproduct) {
                return true 
            }
        }
        return false
    }

    productLabel() {
        var label = ''
        var showProductLabel = false
        let workOrderSettings = TemplateSettingsManager.getWorkOrderSettings()
        if (workOrderSettings !== undefined && workOrderSettings !== null) {
            if (workOrderSettings['showProductLabel'] !== undefined && workOrderSettings['showProductLabel'] !== null) {
                showProductLabel = workOrderSettings['showProductLabel']
            }
            if(showProductLabel) {
                if (workOrderSettings['productLabel'] !== undefined && workOrderSettings['productLabel'] !== null) {
                    label = workOrderSettings['productLabel']
                }
            }
        }
        return label
    }

    SubstituteLabel() {
        var label = ''
        var showProductLabel = false
        let workOrderSettings = TemplateSettingsManager.getWorkOrderSettings()
        if (workOrderSettings !== undefined && workOrderSettings !== null) {
            if (workOrderSettings['showSubstituteLabel'] !== undefined && workOrderSettings['showSubstituteLabel'] !== null) {
                showProductLabel = workOrderSettings['showSubstituteLabel']
            }
            if (showProductLabel) {
                if (workOrderSettings['substituteLabel'] !== undefined && workOrderSettings['substituteLabel'] !== null) {
                    label = workOrderSettings['substituteLabel']
                }
            }
        }
        return label
    }

    getProductName(data: any, lineItem: any): string {
        var name = lineItem.name
        var workOrderSettings = TemplateSettingsManager.getWorkOrderSettings()
        var allProductSubstitutes: string[] = []
        var productSubstitutes: string[] = []
        
        if (data.country === 'SA') {
            if (lineItem.nameInArabic !== undefined && lineItem.nameInArabic !== null) {
                if (lineItem.nameInArabic !== '') {
                    name = getLocalisedText(lineItem.name) + '\n' + getLocalisedText(lineItem.nameInArabic)
                }
            }
        }

        if (this.getIsContainsScrapOrCoproduct(data)) {
            if (lineItem.produceProductType === 'SCRAP') {
                name = 'Scrap <br/>' + name
            }
            else if (lineItem.produceProductType === 'COPRODUCT') {
                name = 'By Product <br/>' + name
            }
            else {
                if (this.productLabel() !== '') {
                    name = this.productLabel() + ' <br/>' + name
                }
                else {
                    name = 'Product <br/>' + name
                }
                
            }
        }
        else {
            if (this.productLabel() !== '') {
                name = this.productLabel() + name
            }
        }


        var substituteLabel = this.SubstituteLabel()
        if(substituteLabel !== '') {
            if (lineItem.allProductSubstitutes.length > 0) {
                lineItem.allProductSubstitutes.forEach((element: string) => {
                    allProductSubstitutes.push(substituteLabel + element)
                });
            }
            if (lineItem.productSubstitutes.length > 0) {
                lineItem.productSubstitutes.forEach((element: string) => {
                    productSubstitutes.push(substituteLabel + element)
                });
            }
        }
        else {
            allProductSubstitutes = lineItem.allProductSubstitutes
            productSubstitutes = lineItem.productSubstitutes
        }

        if (workOrderSettings !== undefined && workOrderSettings !== null) {
            if (workOrderSettings['product'] !== undefined && workOrderSettings['product'] !== null) {
                if (workOrderSettings['product'] === 'SHOW_PRODUCT') {
                    if (workOrderSettings['substitute'] === 'SHOW_ALL_SUBSTITUTE') {
                        name = name + '<br/>' + allProductSubstitutes.join('<br/>')
                    }
                    else if (workOrderSettings['substitute'] === 'SHOW_NON_ZERO_SUBSTITUTE') {
                        name = name + '<br/>' + productSubstitutes.join('<br/>')
                    }
                }
                else if (workOrderSettings['product'] === 'HIDE_PRODUCT_WITH_SUBSTITUTE') {
                    if (lineItem.canHideProductName) {
                        if (workOrderSettings['substitute'] === 'SHOW_ALL_SUBSTITUTE') {
                            name = allProductSubstitutes.join('<br/>')
                        }
                        else if (workOrderSettings['substitute'] === 'SHOW_NON_ZERO_SUBSTITUTE') {
                            name = productSubstitutes.join('<br/>')
                        }
                    }
                    else {
                        if (workOrderSettings['substitute'] === 'SHOW_ALL_SUBSTITUTE') {
                            name = name + '<br/>' + allProductSubstitutes.join('<br/>')
                        }
                        else if (workOrderSettings['substitute'] === 'SHOW_NON_ZERO_SUBSTITUTE') {
                            name = name + '<br/>' + productSubstitutes.join('<br/>')
                        }
                    }
                }
            }
        }
        if (name === '') {
            name = lineItem.name
        }
        return name
    }

    getProductDescription(lineItem: any): string {
        if (lineItem.description !== undefined && lineItem.description !== null) {
            return getLocalisedText(lineItem.description)
        }
        return ''
    }

    getCombineProductCustomField() {
        var val = ''
        var productTableConfiguration = TemplateSettingsManager.defaultTemplateSettings.productTableConfiguration
        if (productTableConfiguration !== undefined) {
            if (productTableConfiguration.isCombinesProductCustomField) {
                var productCustomFields = CustomFieldManager.getActiveProductCustomFields()
                if (productCustomFields !== undefined) {
                    if (productCustomFields.length > 0) {
                        productCustomFields.forEach(element => {
                            if (element.isSelected && element.code !== undefined) {
                                var field = this.getProductCustomFieldByCode(element.code)
                                if (field !== undefined) {
                                    val += element.label + ': <b>' + field.value + '</b>\n'
                                }
                            }
                        });
                    }
                    else {
                        if (productTableConfiguration.combinedProductCustomField !== undefined
                            && productTableConfiguration.combinedProductCustomField !== null) {
                            if (productTableConfiguration.combinedProductCustomField.length > 0) {
                                productTableConfiguration.combinedProductCustomField.forEach((element: any) => {
                                    if (element.isSelected && element.code !== undefined) {
                                        var field = this.getProductCustomFieldByCode(element.code)
                                        if (field !== undefined) {
                                            val += element.label + ': <b>' + field.value + '</b>\n'
                                        }
                                    }
                                });
                            }
                        }
                    }
                }
            }
        }

        return val
    }

    getProductCustomFieldByCode(code: string) {
        var customFields = this.rawItemValue.customFields
        if (customFields === undefined || customFields === null) {
            return undefined
        }

        if (customFields.length === 0) {
            return undefined
        }

        return customFields.find((x: any) => x.code === code)
    }
    toString() {
        var sortedHeader = getSortedHeader()
        if (sortedHeader.length > 0) {
            var sortedValue: string[] = []
            sortedHeader.forEach(element => {
                sortedValue.push(this.getValueByType(element))
            });
            return sortedValue
        }
        return this.getDefaultList()
    }

    getValueByType(element: any) {
        const type = element.type
        switch (type) {
            case TableColumnPopupType.lineNumber:
                return this.lineNumber
            case TableColumnPopupType.code:
                return getLocalisedText(this.code)
            case TableColumnPopupType.image:
                return this.image
            case TableColumnPopupType.productName:
                return getLocalisedText(this.name)
            case TableColumnPopupType.description:
                return getLocalisedText(this.description)
            case TableColumnPopupType.orderPrepCompleted: 
                return this.orderPrepCompleted
            case TableColumnPopupType.productCustomField:
                if (element.code !== undefined) {
                    var value = ''
                    var field = this.getProductCustomFieldByCode(element.code)
                    if (field !== undefined) {
                        value = field.value
                    }
                    return value
                }
                return this.getCombineProductCustomField()
            default:
                return ''
        }
    }

    getDefaultList() {
        return [
            this.lineNumber,
            getLocalisedText(this.code),
            this.image,
            getLocalisedText(this.name),
            getLocalisedText(this.description),
            this.orderPrepCompleted,
            this.productCustomField,
        ]
    }
}
