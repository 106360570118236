import AppManager from "../Manager/AppManager"
import ComponentManager from "../Manager/ComponentManager"
import TemplateSettingsManager from "../Manager/TemplateSettingsManager"
import { getLocalisedText } from "../Translate/LanguageManager"
import Utility, { getCapitalized, getIsBill, getIsDebitOrCreditNote, getIsExpenseOrDeposit, getIsFulfillment, getIsGoodsReceipt, getIsInvoice, getIsJournalEntry, getIsMakePayment, getIsOrder, getIsPackList, getIsPickList, getIsProductDocumentForBuySell, getIsQuotation, getIsReceivePayment, getIsSalesOrder, getIsShipList, getIsStockAdjustment, getIsStockTransfer, getIsWorkOrder } from "../Utilities/Utility"

export const DocumentDetailType = {
    refNumber: 'refNumber',
    customerOrderNumber: 'customerOrderNumber',
    startDate: 'startDate',
    dueDate: "dueDate",
    additionalDate: "additionalDate",
    ewayBillNo: "ewayBillNo",
    ewayBillDate: "ewayBillDate",
    linkDocumentNumber: "linkDocumentNumber",
    parentLinkDocumentNumber: "parentLinkDocumentNumber",
    customField: "customField",
    supplierInvoiceNo: "supplierInvoiceNo",
    workOrder: {
        productName: "productName",
        productCode: "productCode",
        deliveryDate: "deliveryDate",
        estimatedTime: "estimatedTime",
        actualQuantity: "actualQuantity",
        manufactureQuantity: "manufactureQuantity",
        parentWorkOrder: 'parentWorkOrder',
    },
    stock: {
        status: 'status',
        type: 'type',
        reason: 'reason',
        warehouse: 'warehouse'
    }
}
export default class DocumentDetailsParser {

    static getDocumentDetailsList(data) {
        var documentDetailList = this.getDocumentDetailsByType(data)
        return documentDetailList
    }

    static getDocumentDetailsByType(data) {
        if (data === undefined || data === null) {
            return []
        }

        if (data.type === undefined || data.type === null) {
            return []
        }
        if (getIsQuotation(data.type)
            || getIsInvoice(data.type)
            || getIsSalesOrder(data.type)
            || getIsOrder(data.type)
        ) {
            return ProductDocumentDetailsMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsBill(data.type)) {
            if (getIsProductDocumentForBuySell(data)) {
                return ProductDocumentDetailsMapper.getDefaultDocumentDetail(data)
            }
            return AccountDocumentDetailsMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsExpenseOrDeposit(data.type) ) {
            return AccountDocumentDetailsMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsDebitOrCreditNote(data.type) ) {
            return CreditAndDebitNoteDocumentDetailsMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsFulfillment(data.type)
            || getIsGoodsReceipt(data.type)
        ) {
            return FulfllmentAndGoodsReceiptDocumentDetailsMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsMakePayment(data.type)
            || getIsReceivePayment(data.type)
        ) {
            return PaymentDocumentDetailsMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsJournalEntry(data.type)) {
            return JournalEntryDocumentDetailsMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsPickList(data.type)) {
            return PickDocumentDetailsMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsPackList(data.type)) {
            return PackDocumentDetailsMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsShipList(data.type)) {
            return ShipDocumentDetailsMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsWorkOrder(data.type)) {
            return WorkOrderDetailsMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsStockTransfer(data.type)) {
            return StockTransferMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsStockAdjustment(data.type)) {
            return StockAdjustmentMapper.getDefaultDocumentDetail(data)
        }
        return []
    }

    static removeLinkDocument(data, documentDetailList) {
        if (data !== undefined && data !== null) {
            if (getIsInvoice(data.type) || getIsSalesOrder(data.type)) {
                if (documentDetailList !== undefined && documentDetailList !== null) {
                    if (TemplateSettingsManager.getPrintInfo() !== undefined) {
                        var newDocType = documentDetailList
                        if (documentDetailList.length > 0) {
                            if (data.linkDocumentNumber === undefined || data.linkDocumentNumber === null || data.linkDocumentNumber === '') {
                                newDocType = newDocType.filter(x => x.type !== DocumentDetailType.linkDocumentNumber)
                            }
                            if (data.parentLinkDocumentNumber === undefined || data.parentLinkDocumentNumber === null || data.parentLinkDocumentNumber === '') {
                                newDocType = newDocType.filter(x => x.type !== DocumentDetailType.parentLinkDocumentNumber)
                            }

                            newDocType.forEach((element, index) => {
                                element.index = index
                            });
                        }

                        return newDocType
                    }
                }
            }
        }

        return documentDetailList
    }


    static addNewDocumentDetailType(data, documentDetailList) {
        var parent = documentDetailList.find(x => x.type === DocumentDetailType.parentLinkDocumentNumber)
        if (data !== undefined && data !== null) {
            if (getIsBill(data.type) || getIsOrder(data.type)) {
                if (documentDetailList !== undefined && documentDetailList !== null) {
                    if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.supplierInvoiceNo)) {
                        var supplierInvoiceNoType = {
                            isSelected: false,
                            type: DocumentDetailType.supplierInvoiceNo,
                            index: documentDetailList.length,
                            label: getLocalisedText('supplier_invoice_no'),
                            key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.supplierInvoiceNo, data),
                        }
                        documentDetailList.push(supplierInvoiceNoType)
                    }
                }
            }
            else if (getIsWorkOrder(data.type)) {
                if (documentDetailList !== undefined && documentDetailList !== null) {
                    if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.linkDocumentNumber)) {
                        var linkDocumentNumberType = {
                            isSelected: false,
                            type: DocumentDetailType.linkDocumentNumber,
                            index: documentDetailList.length,
                            label: AppManager.getTypeNoString(data.linkDocumentType),
                            key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.linkDocumentNumber, data),
                        }
                        documentDetailList.push(linkDocumentNumberType)
                    }
                    if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.workOrder.parentWorkOrder)) {
                        var parentWorkOrderType = {
                            isSelected: false,
                            type: DocumentDetailType.workOrder.parentWorkOrder,
                            index: documentDetailList.length,
                            label: getLocalisedText('parent_work_order'),
                            key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.workOrder.parentWorkOrder, data),
                        }
                        documentDetailList.push(parentWorkOrderType)
                    }
                    if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.customerOrderNumber)) {
                        var customerOrderNumber = {
                            isSelected: false,
                            type: DocumentDetailType.customerOrderNumber,
                            index: documentDetailList.length,
                            label: getLocalisedText('con_number'),
                            key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.customerOrderNumber, data),
                        }
                        documentDetailList.push(customerOrderNumber)
                    }
                }
            }
            else if (getIsDebitOrCreditNote(data.type) || getIsSalesOrder(data.type)) {
                if (documentDetailList !== undefined && documentDetailList !== null) {
                    if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.linkDocumentNumber)) {
                        var linkDocumentNumber = {
                            isSelected: false,
                            type: DocumentDetailType.linkDocumentNumber,
                            index: documentDetailList.length,
                            label: AppManager.getTypeNoString(data.linkDocumentType),
                            key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.linkDocumentNumber, data),
                        }
                        documentDetailList.push(linkDocumentNumber)
                    }
                }
            }
            else if (Utility.getLinkDocumentList(data).length > 0) {
                var linkedDocuments = Utility.getLinkDocumentList(data)
                if (data.parentLinkDocumentNumber !== undefined && parent === undefined) {
                    const parentLinkDocumentType = AppManager.getTypeNoString(linkedDocuments[0].documentType ? linkedDocuments[0].documentType.toLowerCase() : '')
                    let linkDocumentType = ''
                    
                    if (linkedDocuments.length >= 2) {
                        linkDocumentType = AppManager.getTypeNoString(linkedDocuments[1].documentType ? linkedDocuments[1].documentType.toLowerCase() : '')
                    }

                    var linkDocument = documentDetailList.find(x => x.type === DocumentDetailType.linkDocumentNumber)
                    if(linkDocument === undefined) {
                        var newParentLinkDocument = {
                            isSelected: false,
                            type: DocumentDetailType.parentLinkDocumentNumber,
                            index: documentDetailList.length,
                            label: parentLinkDocumentType,
                            documentType: linkedDocuments[0].documentType ? linkedDocuments[0].documentType : '',
                            key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.parentLinkDocumentNumber, data),
                        }
                        documentDetailList.push(newParentLinkDocument)
                    }
                    else {
                        documentDetailList.forEach((element, index) => {
                            if (element.type === DocumentDetailType.linkDocumentNumber) {
                                element.label = parentLinkDocumentType
                                element.index = index
                                element.type = DocumentDetailType.parentLinkDocumentNumber
                                element.documentType = linkedDocuments[0].documentType ? linkedDocuments[0].documentType : ''
                                element.key = DocumentLabelParser.parseDocumentKey(DocumentDetailType.parentLinkDocumentNumber, data)
                            }
                        });
                    }

                    var newLinkDocument = {
                        isSelected: false,
                        type: DocumentDetailType.linkDocumentNumber,
                        index: documentDetailList.length,
                        label: linkDocumentType,
                        documentType: linkDocumentType,
                        key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.linkDocumentNumber, data),
                    }
                    documentDetailList.push(newLinkDocument)
                }
            }
            else if (data.parentLinkDocumentNumber === undefined && parent !== undefined) {
                documentDetailList = documentDetailList.filter(x => x.type !== DocumentDetailType.parentLinkDocumentNumber)
                documentDetailList.forEach((element, index) => {
                    if (element.type === DocumentDetailType.linkDocumentNumber) {
                        element.label = AppManager.getTypeNoString(data.linkDocumentType ? data.linkDocumentType.toLowerCase() : '')
                        element.index = index
                    }
                });
            }
        }

        return documentDetailList
    }
}

// use by quotation, invoice, sales order, bill and order
class ProductDocumentDetailsMapper {

    static getDefaultDocumentDetail(data) {
        var documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.refNumber },
            { index: 1, isSelected: true, type: DocumentDetailType.startDate },
            { index: 2, isSelected: true, type: DocumentDetailType.dueDate },
            { index: 3, isSelected: true, type: DocumentDetailType.additionalDate },
        ]

        if (data === undefined || data === null) {
            return undefined
        }

        if (data.templateId === undefined || data.templateId === null) {
            return undefined
        }

        if (data.templateId === 19
            || data.templateId === 21
            || data.templateId === 22
            || data.templateId === 23
            || data.templateId === 32
        ) {
            documentDetails = [
                { index: 0, isSelected: true, type: DocumentDetailType.refNumber },
                { index: 1, isSelected: true, type: DocumentDetailType.startDate },
                { index: 2, isSelected: true, type: DocumentDetailType.additionalDate },
                { index: 3, isSelected: true, type: DocumentDetailType.dueDate },
            ]
        }

        if (data.ewayGenerated !== undefined && data.ewayGenerated !== null) {
            var ewayDetails = [
                { index: 4, isSelected: true, type: DocumentDetailType.ewayBillNo },
                { index: 5, isSelected: true, type: DocumentDetailType.ewayBillDate },
            ]
            ewayDetails.forEach(element => {
                documentDetails.push(element)
            });
        }

        if (getIsInvoice(data.type)) {
            documentDetails.push({ index: documentDetails.length - 1, isSelected: false, type: DocumentDetailType.linkDocumentNumber })
            documentDetails.push({ index: documentDetails.length, isSelected: true, type: DocumentDetailType.customerOrderNumber })
        }

        if (getIsSalesOrder(data.type) || getIsQuotation(data.type) || getIsOrder(data.type)) {
            documentDetails.push({ index: documentDetails.length, isSelected: true, type: DocumentDetailType.customerOrderNumber })
        }
        return DocumentLabelParser.parseDocumentLabel(data, documentDetails)
    }
}


// use by expense, deposit, credit note, debit note
class AccountDocumentDetailsMapper {

    static getDefaultDocumentDetail(data) {
        var documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.refNumber },
            { index: 1, isSelected: true, type: DocumentDetailType.startDate },
            { index: 2, isSelected: true, type: DocumentDetailType.additionalDate },
        ]
        return DocumentLabelParser.parseDocumentLabel(data, documentDetails)
    }
}

class CreditAndDebitNoteDocumentDetailsMapper {

    static getDefaultDocumentDetail(data) {
        var documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.refNumber },
            { index: 1, isSelected: true, type: DocumentDetailType.startDate },
            { index: 2, isSelected: true, type: DocumentDetailType.additionalDate },
            { index: 3, isSelected: true, type: DocumentDetailType.linkDocumentNumber }
        ]
        return DocumentLabelParser.parseDocumentLabel(data, documentDetails)
    }
}


// use by fulfilment, good receipt
class FulfllmentAndGoodsReceiptDocumentDetailsMapper {

    static getDefaultDocumentDetail(data) {
        var documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.refNumber },
            { index: 1, isSelected: true, type: DocumentDetailType.startDate },
            { index: 2, isSelected: true, type: DocumentDetailType.linkDocumentNumber },
            { index: 3, isSelected: true, type: DocumentDetailType.customerOrderNumber },
        ]
        return DocumentLabelParser.parseDocumentLabel(data, documentDetails)
    }
}

// use by make payment and receipt payment
class PaymentDocumentDetailsMapper {

    static getDefaultDocumentDetail(data) {
        var documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.refNumber },
            { index: 1, isSelected: true, type: DocumentDetailType.startDate },
            { index: 2, isSelected: true, type: DocumentDetailType.dueDate },
            { index: 3, isSelected: true, type: DocumentDetailType.customerOrderNumber },
        ]
        return DocumentLabelParser.parseDocumentLabel(data, documentDetails)
    }
}

// use by journal entry
class JournalEntryDocumentDetailsMapper {
    static getDefaultDocumentDetail(data) {
        var documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.refNumber },
            { index: 1, isSelected: true, type: DocumentDetailType.startDate },
            { index: 2, isSelected: true, type: DocumentDetailType.linkDocumentNumber },
        ]
        return DocumentLabelParser.parseDocumentLabel(data, documentDetails)
    }
}

// use by pick list
class PickDocumentDetailsMapper {
    static getDefaultDocumentDetail(data) {
        var documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.refNumber },
            { index: 1, isSelected: true, type: DocumentDetailType.startDate },
        ]
        return DocumentLabelParser.parseDocumentLabel(data, documentDetails)
    }
}

// use by pack list
class PackDocumentDetailsMapper {
    static getDefaultDocumentDetail(data) {
        var documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.refNumber },
            { index: 1, isSelected: true, type: DocumentDetailType.startDate },
            { index: 1, isSelected: true, type: DocumentDetailType.linkDocumentNumber },
        ]
        return DocumentLabelParser.parseDocumentLabel(data, documentDetails)
    }
}

// use by pack list
class ShipDocumentDetailsMapper {
    static getDefaultDocumentDetail(data) {
        var documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.linkDocumentNumber },
        ]
        return DocumentLabelParser.parseDocumentLabel(data, documentDetails)
    }
}

class WorkOrderDetailsMapper {
    static getDefaultDocumentDetail(data) {
        var documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.refNumber },
            // { index: 1, isSelected: true, type: DocumentDetailType.linkDocumentNumber },
            { index: 1, isSelected: true, type: DocumentDetailType.startDate },
            { index: 2, isSelected: false, type: DocumentDetailType.dueDate },
            { index: 3, isSelected: true, type: DocumentDetailType.workOrder.productName },
            { index: 4, isSelected: false, type: DocumentDetailType.workOrder.productCode },
            { index: 5, isSelected: false, type: DocumentDetailType.workOrder.deliveryDate },
            { index: 6, isSelected: false, type: DocumentDetailType.workOrder.estimatedTime },
            { index: 7, isSelected: false, type: DocumentDetailType.workOrder.actualQuantity },
            { index: 8, isSelected: false, type: DocumentDetailType.workOrder.manufactureQuantity },
            { index: 9, isSelected: true, type: DocumentDetailType.linkDocumentNumber },
            { index: 10, isSelected: true, type: DocumentDetailType.workOrder.parentWorkOrder },
        ]
        return DocumentLabelParser.parseWorkOrderLabel(data, documentDetails)
    }
}

class StockTransferMapper {
    static getDefaultDocumentDetail(data) {
        var documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.refNumber },
            { index: 1, isSelected: true, type: DocumentDetailType.startDate },
            // { index: 2, isSelected: true, type: DocumentDetailType.stock.status },
            // { index: 3, isSelected: true, type: DocumentDetailType.dueDate },
            // { index: 4, isSelected: true, type: DocumentDetailType.stock.type },
            // { index: 5, isSelected: true, type: DocumentDetailType.stock.reason },
            // { index: 6, isSelected: true, type: DocumentDetailType.stock.warehouse },
        ]
        return DocumentLabelParser.parseStockTransfer(data, documentDetails)
    }
}

class StockAdjustmentMapper {
    static getDefaultDocumentDetail(data) {
        var documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.refNumber },
            { index: 1, isSelected: true, type: DocumentDetailType.startDate },
            { index: 2, isSelected: true, type: DocumentDetailType.stock.type },
            { index: 3, isSelected: true, type: DocumentDetailType.stock.reason },
            { index: 4, isSelected: true, type: DocumentDetailType.stock.warehouse },
        ]
        return DocumentLabelParser.parseStockAdjustment(data, documentDetails)
    }
}

class DocumentLabelParser {

    static parseDocumentIsSelect(data, documentDetailsType) {
        switch (documentDetailsType) {
            case DocumentDetailType.refNumber:
                return data.showRefNumber
            case DocumentDetailType.startDate:
                return data.showStartDate
            case DocumentDetailType.dueDate:
                return data.showDueDate
            case DocumentDetailType.additionalDate:
                return data.showAdditionalDate
            case DocumentDetailType.ewayBillNo:
                return (data.ewayGenerated && data.ewayBillNo !== undefined)
            case DocumentDetailType.ewayBillDate:
                return (data.ewayGenerated && data.ewayBillDate !== undefined)
            case DocumentDetailType.linkDocumentNumber:
                return Utility.isShowLinkDocument(data)
            case DocumentDetailType.parentLinkDocumentNumber:
                return Utility.isShowLinkDocument(data)
            case DocumentDetailType.workOrder.productName:
                return true
            case DocumentDetailType.workOrder.productCode:
                return false
            case DocumentDetailType.workOrder.deliveryDate:
                return false
            case DocumentDetailType.workOrder.estimatedTime:
                return false
            case DocumentDetailType.workOrder.actualQuantity:
                return false
            case DocumentDetailType.workOrder.manufactureQuantity:
                return false
            case DocumentDetailType.customField:
                return true
            case DocumentDetailType.workOrder.parentWorkOrder:
                return false
            case DocumentDetailType.stock.status:
                return true
            case DocumentDetailType.stock.type:
                return true
            case DocumentDetailType.stock.reason:
                return true
            case DocumentDetailType.stock.warehouse:
                return true
            case DocumentDetailType.customerOrderNumber:
                return Utility.isShowCustomerOrderNumber(data)
            default:
                return false
        }
    }

    static parseDocumentKey(documentDetailsType, data) {
        switch (documentDetailsType) {
            case DocumentDetailType.refNumber:
                return 'refNumber'
            case DocumentDetailType.customerOrderNumber:
                return 'customerOrderNumber'
            case DocumentDetailType.startDate:
                return 'date'
            case DocumentDetailType.dueDate:
                return 'dueDate'
            case DocumentDetailType.additionalDate:
                return 'additionalDate'
            case DocumentDetailType.ewayBillNo:
                return 'ewayBillNo'
            case DocumentDetailType.ewayBillDate:
                return 'ewayBillDate'
            case DocumentDetailType.linkDocumentNumber:
                return 'linkDocumentNumber'
            case DocumentDetailType.customField:
                return undefined
            case DocumentDetailType.supplierInvoiceNo:
                return 'supplierInvoiceNo'
            case DocumentDetailType.workOrder.productName:
                return 'productName'
            case DocumentDetailType.workOrder.productCode:
                return 'productDocSeqCode'
            case DocumentDetailType.workOrder.deliveryDate:
                return 'deliveryDate'
            case DocumentDetailType.workOrder.estimatedTime:
                return 'estimatedTime'
            case DocumentDetailType.workOrder.actualQuantity:
                return 'actualQuantity'
            case DocumentDetailType.workOrder.manufactureQuantity:
                return 'manufactureQuantity'
            case DocumentDetailType.workOrder.parentWorkOrder:
                return 'parentWorkOrder'
            case DocumentDetailType.parentLinkDocumentNumber:
                return 'parentLinkDocumentNumber'
            case DocumentDetailType.stock.type:
                return 'adjustmentType'
            case DocumentDetailType.stock.reason:
                return 'adjustmentReason'
            case DocumentDetailType.stock.warehouse:
                return 'warehouse'
            default:
                return undefined
        }
    }

    static parseDocumentLabel(data, defaultList) {
        var templateLabel = this.getDocumentLabelByTemplate(data)
        if (defaultList.length > 0) {
            if (templateLabel !== undefined) {
                defaultList.forEach(element => {
                    var label = templateLabel.find(x => x.type === element.type)
                    if (label !== undefined) {
                        element.label = label.label
                        element.isSelected = this.parseDocumentIsSelect(data, element.type)
                        element.key = this.parseDocumentKey(element.type, data)
                    }
                });
                return defaultList
            }
        }

        return []
    }

    static getDocumentLabelByTemplate(data) {
        if (data === undefined || data === null) {
            return undefined
        }

        if (data.templateId === undefined || data.templateId === null) {
            return undefined
        }
        var defaultLabelSetting1 = [1, 2, 36, 41, 42, 43, 44, 45, 46, 48, 49]
        var defaultLabelSetting2 = [3, 4, 5, 6, 7, 8, 9, 10, 14, 16, 17, 18, 19, 20, 21, 22, 23, 24, 26, 27, 28, 29, 31, 32, 37, 38, 39, 40, 47, 50]
        var defaultLabelSetting3 = [11, 12, 15, 25, 30, 33, 34, 35]
        var defaultLabelSetting4 = [13]

        if (defaultLabelSetting1.includes(Number(data.templateId))) {
            return [
                { type: DocumentDetailType.refNumber, label: AppManager.getTypeNoString(data.documentType) },
                { type: DocumentDetailType.startDate, label: AppManager.getTypeDateString(data.documentType) },
                { type: DocumentDetailType.dueDate, label: ComponentManager.getEndDateLabel(data.documentType) },
                { type: DocumentDetailType.additionalDate, label: ComponentManager.getAdditionalDateLabel(data.documentType) },
                { type: DocumentDetailType.ewayBillNo, label: getLocalisedText('eway_bill_no') },
                { type: DocumentDetailType.ewayBillDate, label: getLocalisedText('eway_bill_date') },
                { type: DocumentDetailType.linkDocumentNumber, label: AppManager.getTypeNoString(data.linkDocumentType) },
                { type: DocumentDetailType.customerOrderNumber, label: getLocalisedText("con_number") },
            ]
        }
        else if (defaultLabelSetting2.includes(Number(data.templateId))) {
            return [
                { type: DocumentDetailType.refNumber, label: AppManager.getTypeNoString(data.documentType) },
                { type: DocumentDetailType.startDate, label: ComponentManager.getStartDateLabel(data.documentType) },
                { type: DocumentDetailType.dueDate, label: ComponentManager.getEndDateLabel(data.documentType) },
                { type: DocumentDetailType.additionalDate, label: ComponentManager.getAdditionalDateLabel(data.documentType) },
                { type: DocumentDetailType.ewayBillNo, label: getLocalisedText('eway_bill_no') },
                { type: DocumentDetailType.ewayBillDate, label: getLocalisedText('eway_bill_date') },
                { type: DocumentDetailType.linkDocumentNumber, label: AppManager.getTypeNoString(data.linkDocumentType) },
                { type: DocumentDetailType.customerOrderNumber, label: getLocalisedText("con_number") },
            ]
        }
        else if (defaultLabelSetting3.includes(Number(data.templateId))) {
            return [
                { type: DocumentDetailType.refNumber, label: AppManager.getTypeNoString(data.documentType) },
                { type: DocumentDetailType.startDate, label: getLocalisedText('date') },
                { type: DocumentDetailType.dueDate, label: ComponentManager.getEndDateLabel(data.documentType) },
                { type: DocumentDetailType.additionalDate, label: ComponentManager.getAdditionalDateLabel(data.documentType) },
                { type: DocumentDetailType.ewayBillNo, label: getLocalisedText('eway_bill_no') },
                { type: DocumentDetailType.ewayBillDate, label: getLocalisedText('eway_bill_date') },
                { type: DocumentDetailType.linkDocumentNumber, label: AppManager.getTypeNoString(data.linkDocumentType) },
                { type: DocumentDetailType.customerOrderNumber, label: getLocalisedText("con_number") },
            ]
        }
        else if (defaultLabelSetting4.includes(Number(data.templateId))) {
            return [
                { type: DocumentDetailType.refNumber, label: getCapitalized(getLocalisedText(data.documentType)) + " " + getCapitalized(getLocalisedText('number')) + " :", },
                { type: DocumentDetailType.startDate, label: ComponentManager.getStartDateLabel(data.documentType) },
                { type: DocumentDetailType.dueDate, label: ComponentManager.getEndDateLabel(data.documentType) },
                { type: DocumentDetailType.additionalDate, label: ComponentManager.getAdditionalDateLabel(data.documentType) },
                { type: DocumentDetailType.ewayBillNo, label: getLocalisedText('eway_bill_no') },
                { type: DocumentDetailType.ewayBillDate, label: getLocalisedText('eway_bill_date') },
                { type: DocumentDetailType.linkDocumentNumber, label: AppManager.getTypeNoString(data.linkDocumentType) },
                { type: DocumentDetailType.customerOrderNumber, label: getLocalisedText("con_number") },
            ]
        }
        else {
            return undefined
        }
        // switch (Number(data.templateId)) {
        //     case 1 || 2 || 36:
        //         return [
        //             { type: DocumentDetailType.refNumber, label: AppManager.getTypeNoString(data.documentType) },
        //             { type: DocumentDetailType.startDate, label: AppManager.getTypeDateString(data.documentType) },
        //             { type: DocumentDetailType.dueDate, label: ComponentManager.getEndDateLabel(data.documentType) },
        //             { type: DocumentDetailType.additionalDate, label: ComponentManager.getAdditionalDateLabel(data.documentType) },
        //             { type: DocumentDetailType.ewayBillNo, label: getLocalisedText('eway_bill_no') },
        //             { type: DocumentDetailType.ewayBillDate, label: getLocalisedText('eway_bill_date') },
        //             { type: DocumentDetailType.linkDocumentNumber, label: AppManager.getTypeNoString(data.linkDocumentType)},
        //         ]
        //     case 3 || 4 || 5 || 6 || 7 || 8 || 9 || 10 || 14 || 16 || 17 || 18 || 19 || 20 || 21 || 22 || 23 || 24 || 26 || 27 || 28 || 29 || 31 || 32 || 37 || 38 || 39 || 40:
        //         return [
        //             { type: DocumentDetailType.refNumber, label: AppManager.getTypeNoString(data.documentType) },
        //             { type: DocumentDetailType.startDate, label: ComponentManager.getStartDateLabel(data.documentType) },
        //             { type: DocumentDetailType.dueDate, label: ComponentManager.getEndDateLabel(data.documentType) },
        //             { type: DocumentDetailType.additionalDate, label: ComponentManager.getAdditionalDateLabel(data.documentType) },
        //             { type: DocumentDetailType.ewayBillNo, label: getLocalisedText('eway_bill_no') },
        //             { type: DocumentDetailType.ewayBillDate, label: getLocalisedText('eway_bill_date') },
        //             { type: DocumentDetailType.linkDocumentNumber, label: AppManager.getTypeNoString(data.linkDocumentType) },
        //         ]
        //     case 11 || 12 || 15 || 25 || 30 || 33 || 34 || 35 :
        //         return [
        //             { type: DocumentDetailType.refNumber, label: AppManager.getTypeNoString(data.documentType) },
        //             { type: DocumentDetailType.startDate, label: getLocalisedText('date')},
        //             { type: DocumentDetailType.dueDate, label: ComponentManager.getEndDateLabel(data.documentType) },
        //             { type: DocumentDetailType.additionalDate, label: ComponentManager.getAdditionalDateLabel(data.documentType) },
        //             { type: DocumentDetailType.ewayBillNo, label: getLocalisedText('eway_bill_no') },
        //             { type: DocumentDetailType.ewayBillDate, label: getLocalisedText('eway_bill_date') },
        //             { type: DocumentDetailType.linkDocumentNumber, label: AppManager.getTypeNoString(data.linkDocumentType) },
        //         ]
        //     case 13:
        //         return [
        //             { type: DocumentDetailType.refNumber, label: getCapitalized(getLocalisedText(data.documentType)) + " " + getCapitalized(getLocalisedText('number')) + " :", },
        //             { type: DocumentDetailType.startDate, label: ComponentManager.getStartDateLabel(data.documentType) },
        //             { type: DocumentDetailType.dueDate, label: ComponentManager.getEndDateLabel(data.documentType) },
        //             { type: DocumentDetailType.additionalDate, label: ComponentManager.getAdditionalDateLabel(data.documentType) },
        //             { type: DocumentDetailType.ewayBillNo, label: getLocalisedText('eway_bill_no') },
        //             { type: DocumentDetailType.ewayBillDate, label: getLocalisedText('eway_bill_date') },
        //             { type: DocumentDetailType.linkDocumentNumber, label: AppManager.getTypeNoString(data.linkDocumentType) },
        //         ]
        //     default:
        //         console.log(data.templateId)
        //         return undefined
        // }
    }


    static parseWorkOrderLabel(data, defaultList) {
        var templateLabel = this.getDocumentLabelByWorkOrder(data)
        if (defaultList.length > 0) {
            if (templateLabel !== undefined) {
                defaultList.forEach(element => {
                    var label = templateLabel.find(x => x.type === element.type)
                    if (label !== undefined) {
                        element.label = label.label
                        element.isSelected = this.parseDocumentIsSelect(data, element.type)
                        element.key = this.parseDocumentKey(element.type, data)
                    }
                });
                return defaultList
            }
        }

        return []
    }

    static getDocumentLabelByWorkOrder(data) {
        if (data === undefined || data === null) {
            return undefined
        }

        if (data.templateId === undefined || data.templateId === null) {
            return undefined
        }
        return [
                { type: DocumentDetailType.refNumber, label: AppManager.getTypeNoString(data.documentType) },
                { type: DocumentDetailType.linkDocumentNumber, label: AppManager.getTypeNoString(data.linkDocumentType) },
                { type: DocumentDetailType.startDate, label: AppManager.getTypeDateString(data.documentType) },
                { type: DocumentDetailType.dueDate, label: ComponentManager.getEndDateLabel(data.documentType) },
                { type: DocumentDetailType.workOrder.productName, label: getLocalisedText('product_name') },
                { type: DocumentDetailType.workOrder.productCode, label: getLocalisedText('product_code') },
                { type: DocumentDetailType.workOrder.deliveryDate, label: getLocalisedText('delivery_date') },
                { type: DocumentDetailType.workOrder.estimatedTime, label: getLocalisedText('estimated_time') },
                { type: DocumentDetailType.workOrder.actualQuantity, label: getLocalisedText('actual_quantity') },
                { type: DocumentDetailType.workOrder.manufactureQuantity, label: getLocalisedText('manufacture_quantity') },
                { type: DocumentDetailType.workOrder.parentWorkOrder, label: getLocalisedText('parent_work_order') },
                { type: DocumentDetailType.linkDocumentNumber, label: getLocalisedText('sales_order') },
                { type: DocumentDetailType.customerOrderNumber, label: getLocalisedText('con_number') },
            ]
    }

    static parseStockTransfer(data, defaultList) {
        var templateLabel = this.getDocumentLabelByStockTransfer(data)
        if (defaultList.length > 0) {
            if (templateLabel !== undefined) {
                defaultList.forEach(element => {
                    var label = templateLabel.find(x => x.type === element.type)
                    if (label !== undefined) {
                        element.label = label.label
                        element.isSelected = this.parseDocumentIsSelect(data, element.type)
                        element.key = this.parseDocumentKey(element.type)
                    }
                });
                return defaultList
            }
        }

        return []
    }

    static getDocumentLabelByStockTransfer(data) {
        if (data === undefined || data === null) {
            return undefined
        }
        if (data.templateId === undefined || data.templateId === null) {
            return undefined
        }
        return [
                { type: DocumentDetailType.refNumber, label: AppManager.getTypeNoString(data.documentType) },
                { type: DocumentDetailType.startDate, label: getLocalisedText('transfer_date') },
                { type: DocumentDetailType.dueDate, label: getLocalisedText('verify_date') },
                { type: DocumentDetailType.stock.status, label: getLocalisedText('status') },
                { type: DocumentDetailType.stock.type, label: getLocalisedText('type') },
                { type: DocumentDetailType.stock.warehouse, label: getLocalisedText('warehouse') },
                { type: DocumentDetailType.stock.reason, label: getLocalisedText('reason') },
            ]
    }

    static parseStockAdjustment(data, defaultList) {
        var templateLabel = this.getDocumentLabelByStockAdjustment(data)
        if (defaultList.length > 0) {
            if (templateLabel !== undefined) {
                defaultList.forEach(element => {
                    var label = templateLabel.find(x => x.type === element.type)
                    if (label !== undefined) {
                        element.label = label.label
                        element.isSelected = this.parseDocumentIsSelect(data, element.type)
                        element.key = this.parseDocumentKey(element.type)
                    }
                });
                return defaultList
            }
        }

        return []
    }

    static getDocumentLabelByStockAdjustment(data) {
        if (data === undefined || data === null) {
            return undefined
        }
        if (data.templateId === undefined || data.templateId === null) {
            return undefined
        }
        return [
                { type: DocumentDetailType.refNumber, label: AppManager.getTypeNoString(data.documentType) },
                { type: DocumentDetailType.startDate, label: getLocalisedText('transfer_date') },
                { type: DocumentDetailType.stock.status, label: getLocalisedText('status') },
                { type: DocumentDetailType.stock.type, label: getLocalisedText('type') },
                { type: DocumentDetailType.stock.warehouse, label: getLocalisedText('warehouse') },
                { type: DocumentDetailType.stock.reason, label: getLocalisedText('reason') },
            ]
    }
}

