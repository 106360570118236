import CustomFieldManager from "../../Manager/CustomFieldManager"
import TemplateSettingsManager from "../../Manager/TemplateSettingsManager"
import { convertDateFromERPFormatToDocFormat, getCapitalized, getConvertedDate } from "../../Utilities/Utility"
import Address from "../Address"
import AddressParser, { AddressType, OwnerType } from "../AddressParser"
import DataParser from "../DataParser"
import LineItemsParser from "../LineItemsParser"
import NewDataParser from "../NewDataParser"
import TenantParser from "../TenantParser"

export default class GoodsReceiptParser {
    static parseData(dataFromAPI, templateDataToReturn) {

        //custom field
        templateDataToReturn.customFields = dataFromAPI.goodsReceipt.customField
        CustomFieldManager.setCustomFields(dataFromAPI.goodsReceipt.customField)
        templateDataToReturn = DataParser.assignCustomFieldValue(templateDataToReturn)

         //company name
        var name = ''
        if (dataFromAPI.tenantInfo !== undefined && dataFromAPI.tenantInfo !== null) {
            if (dataFromAPI.tenantInfo.name !== undefined && dataFromAPI.tenantInfo.name !== null) {
                name = getCapitalized(dataFromAPI.tenantInfo.name)
            }
        }
        templateDataToReturn.companyName = name
        templateDataToReturn.tenantInfo = dataFromAPI.tenantInfo;

        //company address
        var fromAddressObj = undefined
        if (dataFromAPI.tenantInfo !== undefined && dataFromAPI.tenantInfo !== null) {
            if (dataFromAPI.tenantInfo.address !== undefined && dataFromAPI.tenantInfo.address !== null) {
                fromAddressObj = new Address(undefined, dataFromAPI.tenantInfo.address, dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
            }
        }
        templateDataToReturn.fromObj = fromAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.from, templateDataToReturn)

        // billing to address
        var clientBillToAddressObj = undefined
        if (dataFromAPI.parentDocumentData !== undefined || dataFromAPI.parentDocumentData !== null) {
            if (dataFromAPI.parentDocumentData.shipTo !== undefined || dataFromAPI.parentDocumentData.shipTo !== null) {
                clientBillToAddressObj = new Address(undefined, dataFromAPI.parentDocumentData.shipTo, dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
            }
        }
        templateDataToReturn.clientBillToAddressObj = clientBillToAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.billTo, templateDataToReturn)

        // shipping to address
        var clientShipToAddressObj = undefined
        var clientName = undefined
        if (dataFromAPI.parentDocumentData !== undefined || dataFromAPI.parentDocumentData !== null) {
            if (dataFromAPI.parentDocumentData.contactInformation !== undefined && dataFromAPI.parentDocumentData.contactInformation !== null) {
                if (dataFromAPI.parentDocumentData.contactInformation.name !== null) {
                    clientName = dataFromAPI.parentDocumentData.contactInformation.name
                }
            }
            if (dataFromAPI.parentDocumentData.shipFrom !== undefined || dataFromAPI.parentDocumentData.shipFrom !== null) {
                clientShipToAddressObj = new Address(clientName, dataFromAPI.parentDocumentData.shipFrom, dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.parentDocumentData.contactInformation.contactNumber, dataFromAPI.parentDocumentData.contactInformation.documentSequenceCode, OwnerType.contact)
            }
        }
        templateDataToReturn.clientName = clientName
        templateDataToReturn.clientShipToAddressObj = clientShipToAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.shipTo, templateDataToReturn)

        //ref number
        var linkDocumentNumber = ''
        var linkDocumentType = ''
        var goodsReceiptCode = ''
        if (dataFromAPI.goodsReceipt !== undefined && dataFromAPI.goodsReceipt !== null) {
            goodsReceiptCode = NewDataParser.getDefaultValue(dataFromAPI.goodsReceipt.goods_receipt_code)
            if (dataFromAPI.parentDocumentData !== undefined && dataFromAPI.parentDocumentData !== null) {
                linkDocumentNumber = NewDataParser.getDefaultValue(dataFromAPI.parentDocumentData.documentCode)
                linkDocumentType = NewDataParser.getDefaultValue(dataFromAPI.parentDocumentData.documentType)
            }
        }
        templateDataToReturn.refNumber = goodsReceiptCode
        templateDataToReturn.linkDocumentNumber = linkDocumentNumber
        templateDataToReturn.linkDocumentType = linkDocumentType
        templateDataToReturn.showLinkDocumentNumber = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.linkDocumentNumber.isVisible

        //start date
        var goodsReceiptDate = ''
        if (dataFromAPI.goodsReceipt !== undefined && dataFromAPI.goodsReceipt !== null) {
            goodsReceiptDate = NewDataParser.getDefaultValue(dataFromAPI.goodsReceipt.receiptDate)
        }
        templateDataToReturn.date = getConvertedDate(convertDateFromERPFormatToDocFormat(goodsReceiptDate), templateDataToReturn.dateFormat)

        //due date
        templateDataToReturn.dueDate = ''
        templateDataToReturn.showDueDate = false

        //line item
        templateDataToReturn.lineItems = LineItemsParser.getGoodsReceiptItem(dataFromAPI)

        //location
        templateDataToReturn = TenantParser.parseLocationDetail(dataFromAPI, templateDataToReturn)

        return templateDataToReturn
    }
}
