import { configureStore } from '@reduxjs/toolkit';
import { authSlice } from './slices/authSlice';
import { booksSlice } from './slices/booksSlice';
import { cartSlice } from './slices/cartSlice';

const store = configureStore({
  reducer: {
    books: booksSlice.reducer,
    cart: cartSlice.reducer,
    auth: authSlice.reducer
  },
  devTools: process.env.NODE_ENV === 'development'
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
