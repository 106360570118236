export class AccountTableWidth {

    isCodeVisible: boolean;
    isNameVisible: boolean;
    isDescriptionVisible: boolean;
    isAmountVisible: boolean;
    isTaxVisible: boolean;
    isTaxAmountVisible: boolean;
    isPaymentAmountVisible: boolean

    code: number;
    name: number;
    description: number;
    tax: number;
    taxAmount: number;
    paymentAmount: number;
    amount: number;

    originalCode: number;
    originalName: number;
    originalDescription: number;
    originalTax: number;
    originalTaxAmount: number;
    originalPaymentAmount: number;
    originalAmount: number;

    constructor() {
        this.isCodeVisible = true
        this.isNameVisible = true
        this.isDescriptionVisible = true
        this.isAmountVisible = true
        this.isTaxVisible = true
        this.isTaxAmountVisible = true
        this.isPaymentAmountVisible = true

        this.code = 0
        this.name = 20
        this.description = 30
        this.tax = 10
        this.taxAmount = 10
        this.paymentAmount = 15
        this.amount = 15

        this.originalCode = 0
        this.originalName = 20
        this.originalDescription = 30
        this.originalTax = 10
        this.originalTaxAmount = 10
        this.originalPaymentAmount = 15
        this.originalAmount = 15
    }

    setIsShowWidth(isCodeVisible: boolean,
                    isNameVisible: boolean,
                    isDescriptionVisible: boolean,
                    isAmountVisible: boolean,
                    isTaxVisible: boolean,
                    isTaxAmountVisible: boolean,
                    isPaymentAmountVisible: boolean) {
            this.isCodeVisible = isCodeVisible
            this.isNameVisible = isNameVisible
            this.isDescriptionVisible = isDescriptionVisible
            this.isAmountVisible = isAmountVisible
            this.isTaxVisible = isTaxVisible
            this.isTaxAmountVisible = isTaxAmountVisible
            this.isPaymentAmountVisible = isPaymentAmountVisible
    }
    getTableHeaderWidths() {
        var totalWidth = 0
        totalWidth = this.getTotalWidth(totalWidth);

        if (totalWidth === 100) {
            this.resetElementOriginalWidth();
        }
        else if (totalWidth === 0) {
            this.resetElementWidth();
        }
        else {
            var totalSelectedElement = this.getTotalSelectedElement()
            if (totalSelectedElement !== 0) {
                var totalOffset = (100 - totalWidth) / totalSelectedElement
                this.updateElementWidth(totalOffset)
            }
        }
    }
    private resetElementOriginalWidth() {
        this.code = this.originalCode
        this.name = this.originalName
        this.description = this.originalDescription
        this.tax = this.originalTax
        this.taxAmount = this.originalTaxAmount
        this.paymentAmount = this.originalPaymentAmount
        this.amount = this.originalAmount
    }

    private resetElementWidth() {
        this.code = 0
        this.name = 0
        this.description = 0
        this.tax = 0
        this.taxAmount = 0
        this.paymentAmount = 0
        this.amount = 0
    }

    private getTotalWidth(totalWidth: number) {
        if (this.isCodeVisible) {
            totalWidth += this.code;
        }
        if (this.isNameVisible) {
            totalWidth += this.name;
        }
        if (this.isDescriptionVisible) {
            totalWidth += this.description;
        }
        if (this.isTaxVisible) {
            totalWidth += this.tax;
        }
        if (this.isTaxAmountVisible) {
            totalWidth += this.taxAmount;
        }
        if (this.isPaymentAmountVisible) {
            totalWidth += this.paymentAmount;
        }
        if (this.isAmountVisible) {
            totalWidth += this.amount;
        }
        return totalWidth;
    }

    private getTotalSelectedElement() {
        var totalSelectedElement = 0
        if (this.isCodeVisible) {
            totalSelectedElement += 1
        }
        if (this.isNameVisible) {
            totalSelectedElement += 1
        }
        if (this.isDescriptionVisible) {
            totalSelectedElement += 1
        }
        if (this.isTaxVisible) {
            totalSelectedElement += 1
        }
        if (this.isTaxAmountVisible) {
            totalSelectedElement += 1
        }
        if (this.isPaymentAmountVisible) {
            totalSelectedElement += 1
        }
        if (this.isAmountVisible) {
            totalSelectedElement += 1
        }
        return totalSelectedElement
    }

    private updateElementWidth(additionalWidth: number) {
        if (this.isCodeVisible) {
            this.code = this.originalCode + additionalWidth
        }
        if (this.isNameVisible) {
            this.name = this.originalName + additionalWidth
        }
        if (this.isDescriptionVisible) {
            this.description = this.originalDescription + additionalWidth
        }
        if (this.isTaxVisible) {
            this.tax = this.tax + additionalWidth
        }
        if (this.isTaxAmountVisible) {
            this.taxAmount = this.taxAmount + additionalWidth
        }
        if (this.isPaymentAmountVisible) {
            this.paymentAmount = this.originalPaymentAmount + additionalWidth
        }
        if (this.isAmountVisible) {
            this.amount = this.originalAmount + additionalWidth
        }
    }
}