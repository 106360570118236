import CustomFieldManager from "../../Manager/CustomFieldManager"
import TemplateSettingsManager from "../../Manager/TemplateSettingsManager"
import { getLocalisedText } from "../../Translate/LanguageManager"
import Utility, { convertDateFromERPFormatToDocFormat, getCapitalized, getConvertedDate, getIsCreditNote, getIsDebitNote, getIsDebitOrCreditNote, getIsExpenseOrDeposit } from "../../Utilities/Utility"
import Address from "../Address"
import AddressParser, { AddressType, OwnerType } from "../AddressParser"
import DataParser from "../DataParser"
import LineItemsParser from "../LineItemsParser"
import TenantParser from "../TenantParser"

export default class AccountParser {
    static parseData(dataFromAPI, templateDataToReturn) {

        //custom field
        templateDataToReturn.customFields = dataFromAPI.customFields
        CustomFieldManager.setCustomFields(dataFromAPI.customFields)
        templateDataToReturn = DataParser.assignCustomFieldValue(templateDataToReturn)

         //company name
        templateDataToReturn.companyName = getCapitalized(dataFromAPI.companyName)
        //handling debit note name
        if (getIsDebitNote(dataFromAPI.type)) {
            templateDataToReturn.companyName = getCapitalized(dataFromAPI.clientName)
        }

        templateDataToReturn.tenantInfo = dataFromAPI.tenantInfo;
        //company address
        var fromAddressObj = undefined
        var preferAddress = AddressParser.getTenantAddressFromApi(undefined, dataFromAPI.tenantInfo)
        if (preferAddress !== undefined) {
            fromAddressObj = new Address(undefined, preferAddress, dataFromAPI.pan, dataFromAPI.tenantInfo.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
        }

        if (getIsDebitOrCreditNote(templateDataToReturn.type)) {
            if(dataFromAPI.fromAddress !== undefined && dataFromAPI.fromAddress !== null) {
                fromAddressObj = dataFromAPI.fromAddress 
            }
        }
        templateDataToReturn.fromObj = fromAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.from, templateDataToReturn)

        // billing to address
        var clientBillToAddressObj = undefined
        if (dataFromAPI.contactInfo !== undefined && dataFromAPI.contactInfo !== null) {
            if (dataFromAPI.contactInfo.billingAddress !== undefined && dataFromAPI.contactInfo.billingAddress !== null) {
                clientBillToAddressObj = new Address(dataFromAPI.clientName, dataFromAPI.contactInfo.billingAddress, dataFromAPI.contactInfo.pan, dataFromAPI.contactInfo.gstin, dataFromAPI.contactInfo.contactNumber, dataFromAPI.contactInfo.documentSequenceCode, OwnerType.contact)
            }
        }
        templateDataToReturn.clientBillToAddressObj = clientBillToAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.billTo, templateDataToReturn)

        // shipping to address
        templateDataToReturn.clientShipToAddressObj = undefined
        if (getIsExpenseOrDeposit(dataFromAPI.type)) {
            templateDataToReturn.clientShipToAddress = this.get_CN_DN_Payment_Detail(dataFromAPI)
        }

        //ref number
        templateDataToReturn.refNumber = dataFromAPI.refNumber

        //start date
        templateDataToReturn.date = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.date), templateDataToReturn.dateFormat)

        //due date
        if (dataFromAPI.referenceDate !== undefined && dataFromAPI.referenceDate !== null && dataFromAPI.referenceDate !== '') {
            templateDataToReturn.dueDate = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.referenceDate), templateDataToReturn.dateFormat)
            templateDataToReturn.showDueDate = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.dueDate.isVisible
        }

        //line item
        templateDataToReturn.lineItems = LineItemsParser.getAccountItem(dataFromAPI, templateDataToReturn.type)

        //location
        templateDataToReturn = TenantParser.parseLocationDetail(dataFromAPI, templateDataToReturn)

        if (getIsDebitOrCreditNote(templateDataToReturn.type)) {
            templateDataToReturn = this.getEInvoiceDetails(dataFromAPI, templateDataToReturn)

            //add additional product table line item if exist
            templateDataToReturn.productLineItems = LineItemsParser.getProductLineItem(dataFromAPI, true, dataFromAPI.dateFormat, dataFromAPI.productLineItems)

            var linkDocumentNumber = undefined
            var linkDocumentType = undefined
            if(dataFromAPI.salesInvoiceCode !== undefined && dataFromAPI.salesInvoiceCode !== null) {
                linkDocumentNumber = dataFromAPI.salesInvoiceCode
                linkDocumentType = 'invoice'
            }
            else if(dataFromAPI.purchaseInvoiceCode !== undefined && dataFromAPI.purchaseInvoiceCode !== null) {
                linkDocumentNumber = dataFromAPI.purchaseInvoiceCode
                linkDocumentType = 'order'
            }

            templateDataToReturn.linkDocumentNumber = linkDocumentNumber
            templateDataToReturn.linkDocumentType = linkDocumentType

            if(getIsCreditNote(templateDataToReturn.type)) {
                templateDataToReturn = this.getCreditNoteBillToAndShipToAddress(dataFromAPI, templateDataToReturn)
            }
            else if(getIsDebitNote(templateDataToReturn.type)) {
                templateDataToReturn = this.getDebitNoteBillToAndShipToAddress(dataFromAPI, templateDataToReturn)
            }

        }
        return templateDataToReturn
    }

    static get_CN_DN_Payment_Detail(data) {
        if (data.accountName !== undefined && data.accountName !== null) {
            var detailString = ''
            detailString = data.accountName + '\n'
            if (data.paymentType !== undefined && data.accountName.toLowerCase() !== data.paymentType.toLowerCase()) {
                detailString = detailString + Utility.getPaymentMethod(data.paymentType, data.country)
            }
            if (data.referenceNumber !== undefined && data.referenceNumber !== null && data.referenceNumber !== '') {
                detailString = detailString + ' (' + data.referenceNumber + ') \n'
            }
            return detailString
        }
    }

    static getEInvoiceDetails(dataFromAPI, templateDataToReturn) {
        //add E invoice data
        templateDataToReturn.irn = dataFromAPI.irn
        templateDataToReturn.ackDt = dataFromAPI.ackDt
        templateDataToReturn.ackNo = dataFromAPI.ackNo
        templateDataToReturn.signedQRCode = dataFromAPI.signedQRCode
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.eInvoiceSection === undefined) {
            TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails['eInvoiceSection'] = { 'isVisible': false }
        }
        else {
            templateDataToReturn.showEInvoiceSection = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.eInvoiceSection.isVisible
            if (templateDataToReturn.signedQRCode === undefined || templateDataToReturn.signedQRCode === null || templateDataToReturn.signedQRCode === '') {
                templateDataToReturn.showEInvoiceSection = false
            }
        }
        return templateDataToReturn
    }

    static getCreditNoteBillToAndShipToAddress(dataFromAPI, templateDataToReturn) {
        if(dataFromAPI.billToAddress !== undefined && dataFromAPI.billToAddress !== null) {
            var clientBillToAddressObj = new Address(dataFromAPI.contactInfo.name, dataFromAPI.billToAddress, dataFromAPI.contactInfo.pan, dataFromAPI.contactInfo.gstin, dataFromAPI.contactInfo.contactNumber, dataFromAPI.contactInfo.documentSequenceCode, OwnerType.contact)
            templateDataToReturn.clientBillToAddressObj = clientBillToAddressObj
            templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.billTo, templateDataToReturn)
        }
        
        if(dataFromAPI.shipToAddress !== undefined && dataFromAPI.shipToAddress !== null) {
            var clientShipToAddressObj = new Address(dataFromAPI.contactInfo.name, dataFromAPI.shipToAddress, dataFromAPI.contactInfo.pan, dataFromAPI.contactInfo.gstin, dataFromAPI.contactInfo.contactNumber, dataFromAPI.contactInfo.documentSequenceCode, OwnerType.contact)
            templateDataToReturn.clientShipToAddressObj = clientShipToAddressObj
            templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.shipTo, templateDataToReturn)
        }
        templateDataToReturn = this.updateCreditAndDebitNoteTemplateSetting(templateDataToReturn)
        return templateDataToReturn
    }

    static getDebitNoteBillToAndShipToAddress(dataFromAPI, templateDataToReturn) {
        if(dataFromAPI.billToAddress !== undefined && dataFromAPI.billToAddress !== null) {
            var clientBillToAddressObj = new Address(dataFromAPI.companyName, dataFromAPI.billToAddress, dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
            templateDataToReturn.clientBillToAddressObj = clientBillToAddressObj
            templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.billTo, templateDataToReturn)
        }
        
        if(dataFromAPI.shipToAddress !== undefined && dataFromAPI.shipToAddress !== null) {
            var clientShipToAddressObj = new Address(dataFromAPI.companyName, dataFromAPI.shipToAddress, dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
            templateDataToReturn.clientShipToAddressObj = clientShipToAddressObj
            templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.shipTo, templateDataToReturn)
        }
        templateDataToReturn = this.updateCreditAndDebitNoteTemplateSetting(templateDataToReturn)
        return templateDataToReturn
    } 

    static updateCreditAndDebitNoteTemplateSetting(templateDataToReturn) {
        if(templateDataToReturn.clientShipToAddressObj !== undefined) {
            let shipToTitle = TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.shipToDetails.title
            let showClientShipToAddress = TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.shipToDetails.isVisible

            if(shipToTitle === '' && showClientShipToAddress === false) {
                //show ship to 
                templateDataToReturn.showClientShipToAddress = true
                templateDataToReturn.shipToTitle = getLocalisedText('ship_to')
                templateDataToReturn.billToTitle = getLocalisedText('bill_to')
            }
        }

        return templateDataToReturn
    }
}
