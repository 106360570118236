import { DKLabel, DKIcon, DKIcons, DKButton } from 'deskera-ui-library';
import { isMobileAppWebView } from '../../utility/ViewportSize';
import SideBarService from '../../services/common/sidebar';
import { useAppSelector } from '../../store/hooks';
import { selectUserInfo } from '../../store/slices/authSlice';
import AppManager from '../../managers/AppManager';
import { selectCartItems } from '../../store/slices/cartSlice';
import OrderSummary from '../OrderSummary';
import { useState } from 'react';

export default function TopMenu() {
  const userInfo = useAppSelector(selectUserInfo);
  const cartData = useAppSelector(selectCartItems);
  const [showOrderSummary, showHideOrderSummary] = useState<boolean>(false);

  return (
    <div
      className="row bg-white p-h-r p-v-s shadow-m z-index-3"
      style={{
        minHeight: 45
      }}
    >
      <div
        className="dk-sidebar-toggle p-v-s expandable-button cursor-hand z-index-4 column justify-content-center display-only-mobile"
        style={{
          visibility: 'visible'
        }}
        onClick={() => SideBarService.toggleSideBar()}
      >
        <div className="row justify-content-between">
          <DKIcon src={DKIcons.ic_menu} className={`ic-s shadow-s-2`} />
          {isMobileAppWebView() ? (
            <DKLabel
              className="fw-m fs-l p-h-m"
              style={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                maxWidth: '160px'
              }}
              text={
                // tenantInfo?.name ||
                ''
              }
            />
          ) : null}
        </div>
      </div>
      <div className="row display-only-web">
        <DKLabel text={'👋'} className="fs-l" />
        <DKLabel text={' Welcome '} />
        <DKLabel text={userInfo?.name || 'User'} className="fw-b" />
      </div>
      <div className="row ml-auto width-auto">
        {cartData?.data?.length ? (
          <>
            <div className="position-relative">
              <div
                className="position-absolute bg-red p-xs text-white circle d-flex align-items-center justify-content-center"
                style={{ width: 10, height: 10, left: 20, top: -4 }}
              >
                {cartData?.data?.length ?? 0}
              </div>
              <DKButton
                title={''}
                icon={DKIcons.white.ic_cart}
                className="mr-s border-m bg-button "
                onClick={() => showHideOrderSummary(true)}
              />
            </div>
          </>
        ) : null}
        <DKButton
          title={'Log Out'}
          className="mr-s border-m bg-gray1"
          onClick={() => AppManager.showLogoutConfirmation()}
        />
      </div>
      {showOrderSummary && (
        <OrderSummary
          onSave={() => showHideOrderSummary(false)}
          onClose={() => showHideOrderSummary(false)}
        />
      )}
    </div>
  );
}
