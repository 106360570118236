import { TableColumnPopupType, TableTotalColumnList } from "../../../Components/Popup/TableColumnPopup";
import AppManager from "../../../Manager/AppManager";
import ComponentManager from "../../../Manager/ComponentManager";
import CustomFieldManager from "../../../Manager/CustomFieldManager";
import TemplateSettingsManager from "../../../Manager/TemplateSettingsManager";
import { getLocalisedText } from "../../../Translate/LanguageManager";
import NumberUtility from "../../../Utilities/NumberUtility";
import TableFooterUtility from "../../../Utilities/TableFooterUtility";
import Utility from "../../../Utilities/Utility";
import { getFirstTableColumnType, getIsSelectTotalColumnByType, getSortedHeader } from "../TableUtility";
export class ProductTableTotalColumn {
    lineItems: any;
    priceInclusiveOfTax: boolean;
    currency: string;
    totalTax: string;
    totalQuantities: string;
    totalDiscount: string;
    totalAmount: string;
    totalAmountBeforeTax: string;
    taxNameCount: number
    productCustomFields: any

    constructor(lineItems: any, data: any, currency: string) {
        this.lineItems = lineItems
        this.priceInclusiveOfTax = data.priceInclusiveOfTax
        this.currency = currency
        this.totalTax = ''
        this.totalQuantities = ''
        this.totalDiscount = ''
        this.totalAmount = ''
        this.totalAmountBeforeTax = ''
        this.taxNameCount = 0
        this.productCustomFields = []
        this.getTotalValue(lineItems)
    }

    getTotalValue(lineItems: any) {
        if (lineItems === undefined || lineItems === null) {
            return
        }

        if (lineItems.length === 0) {
            return
        }

        var discount = 0
        var amount = 0
        var amountBeforeTax = 0
        var tax = 0
        var quantity = 0
        var isPercentage = false

        var customFieldList = CustomFieldManager.getNumericProductCustomField(TemplateSettingsManager.defaultTemplateSettings.tableInfo)
        if(customFieldList.length > 0) {
            customFieldList.forEach(element => {
                var item = {
                    code: element.code,
                    sum: 0,
                }
                this.productCustomFields.push(item)
            });
        }
        lineItems.forEach((element: any) => {
            if(!isNaN(element.quantity)) {
                quantity = quantity + Number(element.quantity)
            }
            if (!isNaN(element.discount)) {
                discount = discount + Number(element.discount)
            }
            if (!isNaN(this.getTax(element))) {
                tax = tax + Number(this.getTax(element))
            }
            if (!isNaN(this.getAmountBeforeTax(element))) {
                amountBeforeTax = amountBeforeTax + Number(this.getAmountBeforeTax(element))
            }
            if (!isNaN(this.getAmount(element))) {
                amount = amount + Number(this.getAmount(element))
            }

            if (element.discountInPercent !== undefined && element.discountInPercents !== null) {
                if (element.discountInPercent) {
                    isPercentage = true
                }
            }

            if(this.productCustomFields !== undefined && this.productCustomFields !== null) {
                if(element.customFields !== undefined && element.customFields !== null) {
                    this.productCustomFields.forEach((item: any) => {
                        var val = element.customFields.find((x: any) => x.code === item.code)
                        if (val !== undefined) {
                            if (val.value !== undefined) {
                                item.sum = item.sum + parseFloat(val.value)
                            }
                        }
                    });
                }
            }
        });

        this.totalQuantities = Utility.toDecimalFormat(quantity, TemplateSettingsManager.getQuantityDecimalScale())
        this.totalTax = getIsSelectTotalColumnByType(TableColumnPopupType.productTax) ? Utility.toCurrencyFormat(tax, this.currency) : ''
        this.totalDiscount = getIsSelectTotalColumnByType(TableColumnPopupType.productDiscount) ? Utility.toCurrencyFormat(discount, this.currency) : ''
        this.totalAmountBeforeTax = getIsSelectTotalColumnByType(TableColumnPopupType.amountBeforeTax) ?  Utility.toCurrencyFormat(amountBeforeTax, this.currency) : ''
        this.totalAmount = getIsSelectTotalColumnByType(TableColumnPopupType.amount) ? Utility.toCurrencyFormat(amount, this.currency) : ''

        if (isPercentage) {
            this.totalDiscount = ''
        }
        return
    }

    getAmountBeforeTax(item: any): number {
        var amountBeforeTax = 0
        if (item.amountBeforeTax !== undefined && item.amountBeforeTax !== null) {
            amountBeforeTax = NumberUtility.getFormattedValue(item.amountBeforeTax, AppManager.getNumberFormat(), AppManager.getDecimalScale())
        }

        return amountBeforeTax
    }

    getAmount(item: any): number {
        var amount = 0

        if(this.priceInclusiveOfTax) {
            amount = ComponentManager.getProductTaxInclusivePrice(item)
        }
        else {
            if (item.amount !== undefined && item.amount !== null) {
                amount = Number(item.amount)
            }
            else {
                amount = parseFloat(item.quantity) * parseFloat(item.unitPrice)
            }
        }

        return amount
    }
    getTax(item: any): number {
        var tax = 0
        if (item.taxInfo !== undefined && item.taxInfo !== null) {
            if (item.taxInfo.name !== undefined && item.taxInfo.name !== null) {
                this.taxNameCount = this.taxNameCount + 1
            }
        }
        if (item.tax !== undefined && item.tax !== null) {
            tax = Number(item.tax)
        }
        return tax
    }
    toString() {
        var sortedHeader = getSortedHeader()
        if(sortedHeader.length > 0) {
            var sortedValue: string[] = []
            var footerList = TableFooterUtility.getFooterListByType('product')
            var firstColumnType = getFirstTableColumnType(footerList, undefined)
            sortedHeader.forEach((element: any) => {
                if (footerList.includes(element.type)) {
                    return
                }
                sortedValue.push(this.getDefaultValue(element, firstColumnType))
            });
            return sortedValue
        }
        return this.getDefaultList()
    }

    getDefaultValue(element: any, firstColumn: string | undefined) {
        if (firstColumn !== undefined) {
            if (firstColumn === element.type) {
                if (!TableTotalColumnList.includes(element.type)) {
                    return '<b>' + getLocalisedText('total') + '</b>'
                }
            }
        }
        return this.getValueByType(element)
    }

    getValueByType(element: any) {
        var type = element.type
        switch (type) {
            case TableColumnPopupType.lineNumber:
                return ''
            case TableColumnPopupType.code:
                return ''
            case TableColumnPopupType.productName:
                return ''
            case TableColumnPopupType.description:
                return ''
            case TableColumnPopupType.quantity:
                return this.totalQuantities
            case TableColumnPopupType.uom:
                return ''
            case TableColumnPopupType.unitPrice:
                return ''
            case TableColumnPopupType.productDiscount:
                return this.totalDiscount
            case TableColumnPopupType.productTax:
                return this.taxNameCount === 0 ? this.totalTax : ''
            case TableColumnPopupType.amountBeforeTax:
                return this.totalAmountBeforeTax
            case TableColumnPopupType.amount:
                return this.totalAmount
            case TableColumnPopupType.productNameWithDescription:
                return ''
            case TableColumnPopupType.productCustomField:
                if (element.code !== undefined) {
                    if (getIsSelectTotalColumnByType(TableColumnPopupType.productCustomField, element.code)) {
                        var item = this.productCustomFields.find((x: any) => x.code === element.code)
                        if (item !== undefined) {
                            return item.sum
                        }
                    }
                }
                return ''
            default:
                return ''
        }
    }



    getDefaultList() {
        return [
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            ''
        ]
    }
}
