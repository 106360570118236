import { TableColumnPopupType } from "../../../Components/Popup/TableColumnPopup";
import { getLocalisedText } from "../../../Translate/LanguageManager";
import { getSortedHeaderType } from "../TableUtility";

export class PackingListTableItem {
    lineNumber: number;
    id: number;
    code: string;
    name: string;
    barcode: string;
    unit: string;
    serialBatch: string;
    packedQuantity: string;
    rawItemValue: any;
    constructor(packingListItem: any, data:any, currency: string) {
        this.lineNumber = packingListItem.lineNumber
        this.id = packingListItem.id
        this.code = packingListItem.code ? packingListItem.code : ''
        this.name = this.getProductName(data, packingListItem)
        this.barcode = packingListItem.barcode
        this.unit = packingListItem.unit ? packingListItem.unit : ''
        this.serialBatch = packingListItem.serialBatch
        this.packedQuantity = packingListItem.packedQuantity !== undefined ? packingListItem.packedQuantity.toString() : '0'
        this.rawItemValue = packingListItem
    }
    getProductName(data: any, lineItem: any): string {
        if (data.country === 'SA') {
            if (lineItem.nameInArabic !== undefined && lineItem.nameInArabic !== null) {
                if (lineItem.nameInArabic !== '') {
                    return getLocalisedText(lineItem.name) + '\n' + getLocalisedText(lineItem.nameInArabic)
                }
            }
        }
        return getLocalisedText(lineItem.name)
    }
    toString() {
        var sortedHeader = getSortedHeaderType()
        if (sortedHeader.length > 0) {
            var sortedValue: string[] = []
            sortedHeader.forEach(type => {
                sortedValue.push(this.getValueByType(type))
            });
            return sortedValue
        }
        return this.getDefaultList()
    }

    getValueByType(type: string) {
        switch (type) {
            case TableColumnPopupType.id:
                return this.id
            case TableColumnPopupType.code:
                return getLocalisedText(this.code)
            case TableColumnPopupType.productName:
                return getLocalisedText(this.name)
            case TableColumnPopupType.barcode:
                return this.barcode
            case TableColumnPopupType.unit:
                return this.unit
            case TableColumnPopupType.serialBatch:
                return this.serialBatch ? this.serialBatch : ''
            case TableColumnPopupType.packedQuantity:
                return this.packedQuantity !== undefined ? this.packedQuantity : 'asda'
            default:
                return ''
        }
    }

    getDefaultList() {
        return [
            this.id,
            getLocalisedText(this.code),
            getLocalisedText(this.name),
            this.barcode,
            this.unit,
            this.serialBatch,
            this.packedQuantity,
        ]
    }
}
