import { format, parse } from 'date-fns';
import { DATE_FUNCTION_DATE_FORMATS } from '../constants/Constant';
import { DATE_FORMATS } from '../constants/Enum';

export const getDateString = (date: Date): string => {
  return (
    date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
  );
};
/**
 * @param dateString - date string in the format specified for date-fns
 * @param format - format of the date string from DATE_FORMATS
 * @returns - date object
 */
export const getDateFromString = (
  dateString: string,
  format: DATE_FORMATS = DATE_FORMATS['DD-MM-YYYY']
): Date => {
  return parse(
    dateString,
    convertCRMDateFormatToUILibraryFormat(format),
    new Date()
  );
};

/**
 * Convert date string from one format to another
 * @param dateStr Date string to be formatted
 * @param fromFormat Any one of the book's date formats
 * @param toFormat (Optional) Any one of the book's date formats
 * @returns formatted date string as per tenant's date format, or, a provided book's date format
 */
export const getFormattedDateString = (
  dateStr: string,
  fromFormat: DATE_FORMATS,
  toFormat: DATE_FORMATS = DATE_FORMATS['DD-MM-YYYY']
): string => {
  try {
    return format(
      getDateFromString(dateStr, fromFormat),
      getDateFormatForDateFns(toFormat)
    );
  } catch (e: any) {
    console.error('Error formatting Date: ', dateStr);
    return '';
  }
};
/**
 *
 * @param format - format of the date string from DATE_FORMATS
 * @returns - date string in the format specified for date-fns
 */
export const getDateFormatForDateFns = (format: string): string => {
  return DATE_FUNCTION_DATE_FORMATS[format];
};

export const convertCRMDateFormatToUILibraryFormat = (
  dateFormat: DATE_FORMATS
): string => {
  const formatsMap: { [key: string]: string } = {
    [DATE_FORMATS['DD-MM-YYYY']]: 'dd-MM-yyyy',
    [DATE_FORMATS['MM-DD-YYYY']]: 'MM-dd-yyyy',
    [DATE_FORMATS['YYYY-MM-DD']]: 'yyyy-MM-dd',
    [DATE_FORMATS['DD/MM/YYYY']]: 'dd/MM/yyyy',
    [DATE_FORMATS['MM/DD/YYYY']]: 'MM/dd/yyyy',
    [DATE_FORMATS['DD-MMM-YYYY']]: 'dd-MMM-yyyy',
    [DATE_FORMATS['D MMM YYYY']]: 'd MMM yyyy'
  };

  return (
    formatsMap[dateFormat?.toUpperCase()] ||
    formatsMap[DATE_FORMATS['DD-MM-YYYY']]
  );
};
export const getFullDateString = (date: Date): string => {
  let day = date.getDate() >= 10 ? date.getDate() : `0${date.getDate()}`;
  let month =
    date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
  return date.getFullYear() + '-' + month + '-' + day;
};
export const getDateStrFromDate = (
  date: Date,
  toFormat: DATE_FORMATS = DATE_FORMATS['DD-MM-YYYY']
): string => {
  try {
    return format(
      new Date(date),
      convertCRMDateFormatToUILibraryFormat(toFormat)
    );
  } catch (e: any) {
    console.error('Error formatting Date: ', date);
    return '';
  }
};
