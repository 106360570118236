import React, { Component } from "react";
import "../../DocPreview.css";
import { FONT_SIZE } from '../../Constants/Constants';
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import DKEInvoiceSection, { eInvoiceSectionPosition } from "../../DKUILibrary/DKEInvoiceSection";
import DKSignatureSection from "../../DKUILibrary/DKSignatureSection";
import { AddressType } from "../../Helper/AddressParser";
import ComponentManager from "../../Manager/ComponentManager";
import DocumentManager from "../../Manager/DocumentManager";
import { getLocalisedText } from '../../Translate/LanguageManager';
import { HTMLTag } from "../../Utilities/HTMLTag";
import Utility, { getFontSizeClass, getPageHeight, getVW, getIsFulfillment, getIsGoodsReceipt, getIsJournalEntry } from "../../Utilities/Utility";
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";

export default class DocumentTemplate13 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    addInvoiceDetailsView() {
        return (
            <div
                className="ColumnDiv"
                style={{
                    justifyContent: "space-between",
                    width: "60%",
                    height: "100%",
                    alignSelf: Utility.getIsArabicLang() ? 'flex-end' : 'auto'
                }}
            >
                {ComponentManager.addVerticalSpace(10)}
                {this.getDocumentDetailsSection()}
            </div>
        )
    }

    get1stDocumentDetailsSection() {
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        var value = ''
        if (documentDetailList[0].key !== undefined) {
            value = this.state.data[documentDetailList[0].key]
        }
        else if (documentDetailList[0].customFieldValue !== undefined) {
            value = documentDetailList[0].customFieldValue
        }
        return ComponentManager.getTitleAndValueColumn(
            documentDetailList[0].label + ':',
            value,
            'white',
            "white",
            Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
            '1.04895vw',
            '2.0979vw',
            undefined,
            undefined,
            undefined,
            this.state.data.printScale
        )
    }

    getDocumentDetailsSection() {
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        return documentDetailList.map((element, index) => {
            if(index === 0) {
                return <></>
            }
            var value = ''
            if (element.key !== undefined) {
                value = this.state.data[element.key]
            }
            else if (element.customFieldValue !== undefined) {
                value = element.customFieldValue
            }

            return ComponentManager.getRowValueSection(
                element.label,
                value,
                'black',
                undefined,
                undefined,
                undefined,
                this.state.data.printScale
            )
        });
    }

    addBuyerDetails() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    width: Utility.getIsArabicLang() ? '100%': '50%',
                }}
            >
                {this.state.data.showClientBillToAddress && ComponentManager.getDescriptionSection(
                    getLocalisedText("buyer").toUpperCase(),
                    Utility.getClientBillToAddress(this.state.data),
                    "100%",
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6),
                    Utility.getOwnerType(this.state.data, AddressType.billTo),
                    this.state.data.printScale
                )}
            </div>
        )
    }

     getSourceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getSourceOfSupply()}

            </div>
        )
    }

    getSourceOfSupply() {
        return <div className='RowDiv' style={{ width: '100%' }}>
            <div className='ColumnDiv' style={{
                width: '50%'
            }}>
                {Utility.getIsShowSourceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('source_of_supply'),
                    this.state.data.sourceOfSupply,
                    "100%",
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>
            <div className='ColumnDiv' style={{
                width: '50%'
            }}>
                {Utility.getIsShowDestinationOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('destination_of_supply'),
                    this.state.data.destinationOfSupply,
                    "100%",
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
          </div>

        </div>;
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ justifyContent: 'space-between', minHeight: getPageHeight(this.state.data.printScale)}}>
                <div className='parent-width' style={{ width: '100%', paddingBottom: getVW(30) }}>
                    <DKEInvoiceSection
                        data={this.state.data}
                        position={eInvoiceSectionPosition.top}
                    />
                    <div className="RowDiv" style={{ justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        <div className="ColumnDiv" style={{ width: '100%', justifyContent: 'flex-start' }}>
                            {ComponentManager.addDivider({ height: getVW(1), width: '100%', backgroundColor: 'gray' })}
                            {ComponentManager.addVerticalSpace(15)}
                            <div className="RowDiv"
                                style={{
                                    alignItems: 'flex-start'
                                }}>
                                <div>
                                    {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) && ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(80, this.state.data.printScale), { position: 'static' })}
                                </div>
                                <div className="ColumnDiv"
                                    style={{
                                        justifyContent: 'flex-start',
                                        marginLeft: getVW(15),
                                        alignItems: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                                        width: Utility.getIsArabicLang() ? '100%' : undefined,
                                    }}>
                                    <text className={"docFontStyleBold" + getFontSizeClass()}
                                        style={{
                                            color: this.state.data.themeColor,
                                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                                        }}>
                                        {getLocalisedText("seller").toUpperCase()}
                                    </text>
                                    {ComponentManager.addVerticalSpace(10)}
                                    {this.state.data.showCompanyName && <text className={"docFontStyleBold" + getFontSizeClass()}
                                        style={{
                                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }, this.state.data.printScale)
                                        }}
                                    >
                                        {getLocalisedText(Utility.getCompanyName(this.state.data))}
                                    </text>}
                                    {this.state.data.showFrom &&
                                        <div className={"docFontStyle" + getFontSizeClass()}
                                        style={{
                                            marginTop: getVW(6),
                                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.from) }, this.state.data.printScale),
                                        }}
                                        dangerouslySetInnerHTML={{__html: getLocalisedText(Utility.getCompanyAddress(this.state.data))}}>
                                    </div>}
                                </div>
                            </div>
                        </div>
                        {ComponentManager.addHorizontalSpace(50)}
                        <div className="ColumnDiv" style={{ width: '100%' }}>
                            {<div className="ColumnDiv"
                                style={{
                                    width: '100%',
                                    backgroundColor: this.state.data.themeColor,
                                    height: getVW(Utility.convertToPrintScale(100, this.state.data.printScale)),
                                    padding: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                                    justifyContent: 'flex-start'
                                }}>
                                {this.get1stDocumentDetailsSection()}
                            </div>}
                            {this.addInvoiceDetailsView()}
                            {ComponentManager.addVerticalSpace(10)}
                            {ComponentManager.addDivider({ height: getVW(1), width: '100%', backgroundColor: 'gray' })}
                            {ComponentManager.addVerticalSpace(10)}
                            {this.addBuyerDetails()}
                            {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.addVerticalSpace(10)}
                            {Utility.getIsShowPlaceOfSupply(this.state.data) && this.getPlaceOfSupplyInfoSection()}
                            {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && ComponentManager.addVerticalSpace(20)}
                            {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && this.getSourceOfSupplyInfoSection()}
                        </div>
                    </div>
                    {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(25)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} customFieldPosition={'top'} />
                    {ComponentManager.addVerticalSpace(25)}
                    {
                        TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                        <>
                            <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                                dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                            {ComponentManager.addVerticalSpace(5)}
                        </>
                    }
                    <div className="ColumnDiv" style={{ width: '100%' }}>
                        {this.getProductTable()}
                        {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(25)}
                        {this.getEditableDocumentTable()}
                    </div>
                    {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(25)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} customFieldPosition={'bottom'} />
                    <div id={HTMLTag.SIGNATURE}>
                        {ComponentManager.addVerticalSpace(30)}
                        {this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature, this.state.data.printScale)}
                    </div>
                </div>
                <div className='parent-width' style={{ width: '100%' }}>
                    {(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) && <DKSignatureSection data={this.state.data} onSignatureSectionClicked={this.props.tableClicked} />}
                    <div id={HTMLTag.TERMS_NOTE_PREFIX} style={{ bottom: 0, justifyContent: 'center' }}>
                        {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) &&
                            ComponentManager.getDescriptionSection(
                                Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition')),
                                this.state.data.termsAndCondition,
                                "100%",
                                undefined,
                                undefined,
                                undefined,
                                getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                                undefined,
                                this.state.data.printScale
                            )}
                        {((this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && (this.state.data.showNotes && this.state.data.notes)) && ComponentManager.addVerticalSpace(Utility.convertToPrintScale(20, this.state.data.printScale))}
                        {(this.state.data.showNotes && this.state.data.notes) &&
                            ComponentManager.getDescriptionSection(
                                Utility.getNotesTitle(getIsJournalEntry(this.state.data.type) ? getLocalisedText('memo') : getLocalisedText('notes')),
                                this.state.data.notes,
                                "100%",
                                undefined,
                                undefined,
                                undefined,
                                getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                                undefined,
                                this.state.data.printScale
                            )}
                    </div>
                    <DKEInvoiceSection
                        data={this.state.data}
                        position={eInvoiceSectionPosition.bottom}
                    />
                </div>
            </div>
        );
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getProductTable() {
        if(Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKProductTable(
                Utility.getTableColumnForAccountProduct(),
                this.getTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerBackgroundColor = '#899EA3'
        style.headerTextColor = 'white';
        style.itemBorderColor = "transparent";
        style.footerBackgroundColor = this.state.data.themeColor;
        style.footerTextColor = "white";
        style.footerBorderColor = "transparent";
        return style;
    }

    getPlaceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getPlaceOfSupply()}

            </div>
        )
    }
    getPlaceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '100%'
            }}>
                {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('place_of_supply'),
                    this.state.data.placeOfSupply,
                    "100%",
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>

        </div>;

    }
}













