import { INPUT_TYPE } from 'deskera-ui-library';
import { COLUMN_CODE, FILTER_OPERATORS } from '../constants/Constant';
import { ENDPOINTS } from '../constants/EndPoints';
import { BOOKS_DOCS_TYPES } from '../constants/Enum';
import { getFullDateString } from '../utility/Date';
import { isNotEmpty } from '../utility/Helper';
import httpClient from './';

export const getTables = (params = {}) => {
  return httpClient.get(ENDPOINTS.TABLES.GET, { params });
};
export const sortAndUpdateColumns = (columns: any[]) => {
  const updatedColumns: any[] = [];
  columns?.forEach((column) => {
    if (column.systemField) {
      updatedColumns.push({ ...column, key: column.columnCode });
    }
  });
  return updatedColumns.sort((a: any, b: any) => a.index - b.index);
};

export const getQueryFromFilter = (
  columns: any[],
  filterConditions: any,
  dateFilters: any,
  documentType: BOOKS_DOCS_TYPES
) => {
  let query = [];
  if (isNotEmpty(filterConditions)) {
    query = filterConditions?.map((filter: any) => {
      let colName = columns.find((col) => col.id === filter.key);
      let value = filter.value;
      if (colName.type === INPUT_TYPE.DATE) {
        value = getFullDateString(filter.value);
        if (filter.condition === FILTER_OPERATORS.GREATER_THAN) {
          return `${colName?.columnCode}>=${value}`;
        } else if (filter.condition === FILTER_OPERATORS.LESS_THAN) {
          return `${colName?.columnCode}<=${value}`;
        }
      }
      if (colName?.columnCode === 'recurring') {
        value = filter.value[0] === 'RECURRING' ? true : false;
      }
      return `${colName?.columnCode}=${value}`;
    });
  }
  if (isNotEmpty(dateFilters)) {
    let dateKey = '';
    switch (documentType) {
      case BOOKS_DOCS_TYPES.BOOKS_INVOICE:
        dateKey = COLUMN_CODE.INVOICE.InvoiceDate;
        break;
      case BOOKS_DOCS_TYPES.BOOKS_QUOTE:
      case BOOKS_DOCS_TYPES.BOOKS_PAYMENT:
        dateKey = COLUMN_CODE.QUOTE.QUOTE_DATE;
        break;
      case BOOKS_DOCS_TYPES.BOOKS_SALES_ORDER:
        dateKey = COLUMN_CODE.SALES_ORDER.SALE_ORDER_DATE;
        break;
      case BOOKS_DOCS_TYPES.BOOKS_PURCHASE_ORDER:
        dateKey = COLUMN_CODE.PURCHASE_ORDER.ORDER_DATE;
        break;
      default:
        break;
    }

    query?.push(
      `${dateKey}>=${getFullDateString(dateFilters?.financialStartDate)}`
    );
    query?.push(
      `${dateKey}<=${getFullDateString(dateFilters?.financialEndDate)}`
    );
  }
  return query;
};
