import React from 'react';
import './DraggableImage.css';
import Draggable, { DraggableEventHandler } from 'react-draggable';
import { TemplateItem } from '../model/TemplateItem';
import {
  CoordinateUtility,
  Coordinate
} from '../../utilities/CoordinateUtility';
import { ScreenState } from '../model/Enum';
import Utility, { getVW } from '../../../../Utilities/Utility';
import { HTMLTag } from '../../../../Utilities/HTMLTag';
import { PREVIEW_SCALE, PRINT_SCALE } from '../../../../Constants/Constants';

interface Props {
  templateItem: TemplateItem;
  imgDefaultSize: number;
  componentButtonsRequired: boolean;
  scale: number;
  handleStart: DraggableEventHandler;
  handleDrag: DraggableEventHandler;
  handleStop: DraggableEventHandler;
  onMouseDown: (e: MouseEvent) => void;
  grid: [number, number];
  coordinateUtility: CoordinateUtility;
  disable: boolean;
  screenState: ScreenState;
  selectedTemplateId: number;
  printScale: number;
}

interface States {}
export default class DraggableImage extends React.Component<Props, States> {
  getRelativeImagePath(image: string) {
    if (image?.startsWith('data:')) {
      return image;
    } else if (image?.includes('signature')) {
      return require('../../assets/signature.png').default;
    } else if (image?.includes('watermark')) {
      return require('../../assets/watermark.png').default;
    } else {
      return image;
    }
  }

  render() {
    var coordinate = new Coordinate(
      0,
      0,
      this.props.templateItem.x,
      this.props.templateItem.y,
      this.props.templateItem.originalWindowInnerWidth
    );
    var id = HTMLTag.DRAGGABLE_COMPONENT_PREFIX + this.props.templateItem.id;
    var image = this.getRelativeImagePath(this.props.templateItem.image);
    var position = this.props.coordinateUtility.toPosition(
      coordinate,
      this.props.printScale === PREVIEW_SCALE
    );

    return (
      <Draggable
        axis="both"
        handle=".handle"
        disabled={this.props.disable}
        // defaultPosition={{
        //     x: this.props.coordinateUtility.toPosition(coordinate).x,
        //     y: this.props.coordinateUtility.toPosition(coordinate).y
        // }}
        position={{
          x: position.x,
          y: position.y
        }}
        grid={this.props.grid}
        scale={this.props.scale}
        defaultClassName={
          this.isShowBorder() ? 'draggableBorderDiv' : 'draggableDiv'
        }
        onStart={(e: any, data: any) => this.props.handleStart(e, data)}
        onDrag={(e: any, data: any) => this.props.handleDrag(e, data)}
        onStop={(e: any, data: any) => this.props.handleStop(e, data)}
        onMouseDown={(e: any) => this.props.onMouseDown(e)}
      >
        <div
          id={id}
          className="handle"
          style={{
            zIndex: 4,
            opacity: this.props.templateItem.style.opacity
              ? this.props.templateItem.style.opacity
              : 1,
            padding: 0,
            width: this.props.templateItem.width
              ? getVW(
                  Utility.convertToPrintScale(
                    this.props.templateItem.width,
                    this.props.printScale
                  ) *
                    (this.props.templateItem.imageRatio / 100)
                )
              : undefined,
            height: this.props.templateItem.height
              ? getVW(
                  Utility.convertToPrintScale(
                    this.props.templateItem.height,
                    this.props.printScale
                  ) *
                    (this.props.templateItem.imageRatio / 100)
                )
              : undefined
          }}
        >
          <img
            crossOrigin="anonymous"
            style={{
              opacity: this.props.templateItem.style.opacity
                ? this.props.templateItem.style.opacity
                : 1,
              width: '100%',
              height: '100%',
              zIndex: 4
            }}
            src={image}
            draggable={false}
            alt={''}
          />
        </div>
      </Draggable>
    );
  }

  isShowBorder() {
    if (this.props.screenState === ScreenState.VIEW) {
      return false;
    }
    if (this.props.templateItem.id === this.props.selectedTemplateId) {
      return true;
    } else {
      return false;
    }
  }
}
