import { DKIcons, showToast, TOAST_TYPE, DKLabel } from 'deskera-ui-library';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
  fetchProducts,
  selectProducts,
  selectTablesData
} from '../store/slices/booksSlice';
import { PRODUCTS_COLUMNS } from '../constants/ColumnConfigs';
import { useEffect, useMemo, useState } from 'react';
import { debounce, deepClone, isEmptyObject } from '../utility/Helper';
import {
  COLUMN_CODE,
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  PRODUCT_TYPE
} from '../constants/Constant';
import DocumentGrid from '../components/common/DocumentGrid';
import { addToCart, preparePayloadForCart, updateCart } from '../services/cart';
import { fetchCartItems, selectCartItems } from '../store/slices/cartSlice';
import { sortAndUpdateColumns } from '../services/tables';
import { BOOKS_DOCS_TYPES } from '../constants/Enum';

interface IProductsProps {
  onSave: () => void;
  onClose: () => void;
  width?: number;
}
const VISIBLE_COLUMN = [
  COLUMN_CODE.PRODUCT.NUMBER,
  COLUMN_CODE.PRODUCT.NAME,
  COLUMN_CODE.PRODUCT.ON_GOING_QTY,
  COLUMN_CODE.PRODUCT.DESCRIPTION
];
const Products: React.FC<IProductsProps> = (props) => {
  const productsData = useAppSelector(selectProducts);
  const dispatch = useAppDispatch();

  const tableData = useAppSelector(
    selectTablesData(BOOKS_DOCS_TYPES.BOOKS_PRODUCT)
  );
  const [columns, setColumns] = useState<any[]>([]);
  const [updating, toggleUpdating] = useState<boolean>(false);
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [pagination, setPagination] = useState<any>({
    page: DEFAULT_PAGE_NO - 1,
    limit: DEFAULT_PAGE_SIZE,
    sortDir: 'DESC',
    sort: 'documentSequenceCode'
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [apiCallInProgress, updateApiCallFlag] = useState(false);
  const cartItems = useAppSelector(selectCartItems);

  useEffect(() => {
    getProducts();
  }, [searchTerm, pagination]);

  useEffect(() => {
    const mappedRows = productsData?.content?.map((product: any) => ({
      ...product,
      selected: isProductSelected(product.productId),
      quantity: 1
    }));

    setRows(mappedRows ?? []);
  }, [productsData, selectedProducts]);

  useMemo(() => {
    let updatedColumns = sortAndUpdateColumns(tableData.columnsMetaData || []);
    updatedColumns = updatedColumns.filter((item) =>
      VISIBLE_COLUMN.includes(item.columnCode)
    );
    updatedColumns.forEach((column) => {
      switch (column.columnCode) {
        case COLUMN_CODE.PRODUCT.STATUS:
          column.renderer = ({ rowData }: any) => {
            return (
              <div
                className={`p-xs fs-r border-radius-s ${
                  rowData.active
                    ? 'data-grid-badge-color-6'
                    : 'data-grid-badge-color-4'
                }`}
              >
                {rowData.active ? 'Active' : 'Inactive'}
              </div>
            );
          };
          break;
        case COLUMN_CODE.PRODUCT.OUTGOING:
          column.renderer = (obj: any) => {
            return (
              <>
                <div className="row justify-content-end">
                  {obj.rowData?.incomingQty || 0}/
                  <span className="text-red">
                    {obj.rowData?.outgoingQty || 0}
                  </span>
                </div>
              </>
            );
          };
          break;
        case COLUMN_CODE.PRODUCT.TYPE:
          column.renderer = (obj: any) => {
            return <div className="row">{PRODUCT_TYPE[obj.rowData.type]}</div>;
          };
          break;
        default:
          break;
      }
    });
    setColumns([...updatedColumns]);
  }, [tableData]);

  const getProducts = () => {
    if (!updating) {
      toggleUpdating(true);
      dispatch(
        fetchProducts({
          limit: pagination.limit,
          page: pagination.page,
          search: searchTerm,
          sortDir: pagination.sortDir || 'DESC',
          sort: pagination.sort,
          productTransactionType: 'BOTH,SALES',
          query: `hasVariants=false,active=true`
        })
      ).finally(() => {
        toggleUpdating(false);
      });
    }
  };

  const isProductSelected = (productId: string) => {
    return !!selectedProducts?.find(
      (product) => product.productId === productId
    );
  };

  const onPageChange = (page: number) => {
    setPagination({ ...pagination, page: page });
  };

  const onRowSelect = ({ rowIndex, rowData }: any) => {
    updateSelectedRows({ rowData });
  };

  const updateSelectedRows = ({ rowData }: any) => {
    const indexOfExisting = selectedProducts?.findIndex(
      (product: any) => product?.productId === rowData?.productId
    );
    const copyOfSelectedProducts = deepClone(selectedProducts);
    if (indexOfExisting === -1) {
      copyOfSelectedProducts.push(rowData);
    } else {
      copyOfSelectedProducts.splice(indexOfExisting, 1);
    }

    setSelectedProducts(copyOfSelectedProducts);
  };

  const handleAddToCard = (productsToAdd: any[] = []) => {
    if (productsToAdd.length && !apiCallInProgress) {
      updateApiCallFlag(true);
      const payload = preparePayloadForCart(
        productsToAdd,
        cartItems?.data ?? []
      );
      const newItems = payload.filter((item) => item.cartQuantity === 1);
      const existingItems = payload.filter((item) => item.cartQuantity > 1);
      const cartAPIArray = [];
      if (newItems.length) {
        cartAPIArray.push(addToCart(newItems));
      }
      if (existingItems.length) {
        cartAPIArray.push(updateCart(existingItems));
      }
      Promise.all(cartAPIArray)
        .then((res) => {
          showToast('Items Added to Cart.', TOAST_TYPE.SUCCESS);
          dispatch(fetchCartItems());
        })
        .catch((err) => {
          showToast(
            err?.errorMessage ?? 'Error occurred while adding product to cart.',
            TOAST_TYPE.FAILURE
          );
        })
        .finally(() => {
          setSelectedProducts([]);
          updateApiCallFlag(false);
        });
    }
  };

  const onSearch = debounce((searchQuery: string) => {
    setSearchTerm(searchQuery);
  }, 500);

  const getHeaderButtons = () => {
    if (isEmptyObject(selectedProducts)) return [];
    return [
      {
        title: 'Add to Cart',
        icon: DKIcons.white.ic_cart,
        className: 'bg-button text-white',
        onClick: () => handleAddToCard(selectedProducts)
      }
    ];
  };

  const getAvailableStock = (product: any) => {
    let quantity = product['inventory']
      ? product['inventory']['availableQuantity']
      : 0;
    return isNaN(quantity) ? 0 : quantity;
  };

  const parseRows = () => {
    const copyOfRows = deepClone(rows);
    return copyOfRows.map((row) => ({
      ...row,
      [COLUMN_CODE.PRODUCT.ON_GOING_QTY]: getAvailableStock(row),
      rowContextMenu: [
        {
          title: 'Add to Cart',
          icon: DKIcons.ic_cart,
          className: 'border-m',
          onClick: ({ rowData }: any) => handleAddToCard([rowData])
        }
      ]
    }));
  };

  const onAllRowSelect = ({ selected }: any) => {
    if (selected) {
      setSelectedProducts([...deepClone(rows)]);
    } else {
      setSelectedProducts([]);
    }
  };

  return (
    <>
      <div className="column parent-width mb-s position-relative flex-1">
        <DocumentGrid
          title="📦 Products"
          documentType="Products"
          columns={columns}
          rows={parseRows()}
          needTrailingColumn={true}
          allowSearch={true}
          onSearch={onSearch}
          updating={updating}
          needBoldTheme={true}
          onRowSelect={onRowSelect}
          currentPage={pagination.page}
          totalPageCount={productsData?.totalPages}
          onPageChange={onPageChange}
          headerButtons={getHeaderButtons()}
          allowBulkOperation={true}
          width={props.width}
          onAllRowSelect={onAllRowSelect}
          isAllRowSelected={
            selectedProducts?.length !== 0 &&
            rows?.length === selectedProducts?.length
          }
        />
      </div>
    </>
  );
};

export default Products;
