let en = {
    translations: {
        "component": "Component",
        "add": "Add",
        "document_type": "Document Type",
        "select_theme_color": "Select Theme Color",
        "company_logo": "Company Logo",
        "choose_file": "Choose file",
        "your_company_name": "Your Company Name",
        "company_address": "Company Address",
        "currency": "Currency",
        "no": "No",
        "terms_&_condition": "Terms & Conditions",
        "notes": "Notes",
        "date_format": "Date Format",
        "line_items": "Line Items",
        "item_name": "Item name",
        "bill_to": "Bill To",
        "ship_to": "Ship To",
        "valid_till": "Valid Till",
        "due_date": "Due Date",
        "date": " Date",
        "item_description": "Item description",
        "discount": "Discount",
        "tax": "Tax",
        "delete": "Delete",
        "add_new_item": "Add New Item",
        "signature": "Signature",
        "show_discount": "Show Discount",
        "show_tax": "Show Tax",
        "company_name": "Company Name",
        "company_billing_address": "Company Billing Address",
        "billing_address_placeholder_text_value": "Customer Name\nYour Customer's Billing Address",
        "shipping_address_placeholder_text_value": "Customer Name\nYour Customer's Shipping Address",
        "terms_and_condition_placeholder_text": "Payment is due within 30 days",
        "notes_placeholder_text": "Please pay the balance due within time",
        "hide": "Hide",
        "show": "Show",
        "estimate": "Estimate",
        "quotation": "Quotation",
        "invoice": "Invoice",
        "purchase_order": "Purchase Order",
        "bills": "Bills",
        "total": "Total",
        "number": "Number",
        "edit": "Edit",
        "products": "Products",
        "description": "Description",
        "qty": "Qty",
        "unit_price": "Unit Price",
        "unit price": "Unit Price",
        "price": "Price",
        "amount": "Amount",
        "subtotal": "Subtotal",
        "choose_a_template": "Choose a Template",
        "go_to_deskera": "Goto Deskera",
        "print_this_document": "Print this Document",
        "send_email_to_clients": "Send E-Mail to Clients",
        "document_generator": "Document Generator",
        "please_wait": "Please wait...",
        "download_as_pdf": "Download as (.pdf)",
        "download": "Download",
        "print": "Print",
        "share": "Share",
        "try_deskera": "Try Deskera",
        "copy_link": "Copy Link",
        "share_on_the_web": "Share on the Web",
        "anyone_with_the_below_link_can_view": "Anyone with the below link can view",
        "send_your": "Send Your",
        "quickly": "Quickly",
        "please_sign_up_with_deskera_to_send_your": "Please sign up with Deskera to send your",
        "dont_worry_your": "Don't worry, your",
        "will_still_be_here": "will still be here.",
        "sign_up_for_free": "Sign Up For Free",
        "already_a_user_Sign_in": "Already a User? Sign In",
        "share_on_mail": "Share on Email",
        "to": "To",
        "subject": "Subject",
        "enter_recipient_email_address": "Enter recipient's email address",
        "enter_subject_here": "Enter subject here",
        "send": "Send",
        "cancel": "Cancel",
        "edit_table": "Edit Table",
        "save": "Save",
        "buyer": "Buyer",
        "seller": "Seller",
        "powered_by_deskera": "Powered by Deskera",
        "details": "Details",
        "other_comments": "Other Comments",
        "bill": "Bill",
        "order": "Order",

        "email": "Email",

        "items": "Items",
        "services": "Services",
        "hours": "Hours",
        "rate": "Rate",
        "other": "Other",
        "sales_tax": "Sales tax",
        "tax_rate": "Tax rate",
        "sales tax": "Sales tax",
        "tax rate": "Tax rate",

        "num": " No",

        "template_update_failure_message": "Could not update settings. Please try again!",
        "template_save_failure_message": "Could not save settings. Please try again!",
        "template_update_success_message": "Template saved successfully",
        "template_save_success_message": "Template saved successfully",
        "additional_information": "Additional Information",
        "other_options": "Other Options",

        "image": "Image",
        "text": "Text",
        "watermark": "Watermark",
        "banner": "Banner",
        "custom_field": "Custom Field",
        "component_list": "Component List",

        "email_address": "Email address",
        "is_not_valid": "is not valid.",

        "deskera_suites": "Deskera Suites",
        "deskera_books": "Deskera Books",
        "deskera_people": "Deskera People",
        "deskera_sales": "Deskera Sales",
        "try_now": "Try now",

        "text_color": "Text Color",
        "image_ratio": "Image Ratio (%)",
        "text_size": "Text Size",
        "opacity": "Opacity",
        "font_style": "Font Style",
        "font_weight": "Font Weight",
        "text_alignment": "Text Alignment",
        "image_alignment": "Image Alignment",
        "image_preview": "Image Preview",
        "preview_banner": "Preview Banner",
        "banner_selection": "Banner Selection",
        "duplicate": "Duplicate",
        "settings": "Settings",
        "expiry": "Expiry",
        "batch_no": "Batch No.",

        "saving": "Saving...",
        "save_template": "Save Template",

        "name": "Name",

        "stock_items": "Stock Items",

        "sending": "Sending...",
        "set_as_default": "Set Default",

        "no_email_subject": "No Custom Subject found!! Please type your custom subject and press on save template",

        "expense": "Expense",
        "deposit": "Deposit",
        "credit_note": "Credit Note",
        "debit_note": "Debit Note",
        "accounts": "Accounts",
        "customer_address_placeholder_text_value": "Customer Name\nYour Customer's Address",
        "account_name_placeholder_text_value": "Account Code\nAccount Name",
        "payment_to": "Payment To",
        "from_account": "From Account",
        "payment_from": "Payment From",
        "to_account": "To Account",
        "payment_date": "Payment Date",
        "document_date": "Document Date",
        "account_name": "Account name",
        "account_description": "Account Description",
        "tax_amount": "Tax Amount",
        "payment_amount": "Payment Amount",
        "line_amount": "Line Amount",
        "total_tax_amount": "Total Tax Amount",
        "contact": "Contact",
        "contact_address_placeholder_text_value": "Contact Name\nYour Contact's Address",

        "printing": "Printing..",
        "downloading": "Downloading..",

        "incorrect_format": "Incorrect Format!",
        "few_formats_allowed": "Only jpg, jpeg, png files are allowed",
        "image_missing": "Image missing!",
        "data_missing": "Data Missing!",
        "select_image_to_proceed": "Please select image to proceed",
        "no_data_to_export": "No data to export",

        "session_expired_title": "Session Expired!",
        "session_expired_message": "Your session is expired. Please login again.",
        "login": "Login",
        "generate_link_to_share": "Share document with people",
        "burn_after_read": "Burn after read",
        "generate_link": "Generate link to share",
        "no_never": "Never",
        "1_min": "1 Minute",
        "1_hr": "1 Hour",
        "1_day": "1 Day",
        "1_year": "1 Year",

        "document_expired_title": "Document Expired!",
        "document_expired_message": "This Document has expired. Please contact the sender",

        "loading": "Loading...",
        "reference_date": "Reference Date",
        "cheque_number": "Cheque Number",
        "deposit_to": "Deposit To",

        "password": "Password protected",
        "password_empty_title": "Password Empty!",
        "password_empty_message": "Password cannot be empty. Please enter a password and try again!",
        "document_not_found_title": "Document Not Found!",
        "document_incorrect_password_title": "Incorrect Password!",
        "document_incorrect_password_message": "Please Enter Correct Password and Try Again.",
        "proceed": "Proceed",
        "document_enter_password_title": "Password required!",
        "document_enter_password_message": "Please Enter a password to open this document and proceed.",

        "preparing_document_email": "Preparing document to email…",
        "preparing_document_print": "Preparing document to print…",
        "loading_the_page": "Loading the page",
        "sender": "Sender",
        "reply_to": "Reply To",
        "enter_sender_name": "Enter Sender Name",
        "enter_reply_to_email": "Enter Reply To Email",
        "send_a_copy_to_myself_at": "Send a copy to myself at",

        "payment": "Make Payment",
        "paper_format": "Paper Format",
        "please_provide_paper_format": "Please provide paper format",
        "paper_format_popup_note": "You still can update the Paper format from setting panel",
        "item_number": "Product Number",
        "item_code": "Product Code",
        "received_from": "Received Form",
        "payment_details": "Payment Details",
        "sn_number": "SN. Number",
        "document_details": "Document Details",
        "template_saved": "Template Saved!",
        "you_design_template_has_been_saved": "The template has been saved, you can close this window and go back to the Books application.",
        "you_design_template_has_been_saved_people": "The template has been saved, you can close this window and go back to the People application.",
        "go_to_books": "Goto Books",
        "item_tax": "Product Tax",
        "item_discount": "Product Discount",
        "uom": "UOM",

        "please_select_custom_field": "Please select custom field.",

        "preview_pdf": "Preview Pdf",

        "template_name": "Template Name",
        "please_enter_template_name_to_save": "Please enter template name to save.",
        "custom_template": "Custom Template",
        "add_new_template": "Add new custom template",
        "vendor_name": "Vendor Name",

        "decimal_point": "Decimal Point",
        "footer": "Footer",
        "save_as": "Save as",
        "save_as_new_template": "Save as new template",
        "e_invoice_section": "e-Invoice Section",
        "amount_before_tax": "Amount Before Tax",

        "order_no": "Order No",
        "PURCHASE_INVOICE": 'Bill',
        "PURCHASE_ORDER": 'Order',
        "SALES_INVOICE": "Invoice",
        "QUOTATION": "Quotation",
        "fulfillment": "Fulfillment",
        "ship_from": "Ship From",
        "company_address_placeholder_text_value": "Company Name\nYour Company's Address",
        "goods_receipt": "Goods Receipt",
        "required_qty": "Required Quantity",
        "received_qty": "Received Quantity",
        "committed_qty": "Committed Quantity",
        "warehouse_code": "Warehouse Code",
        "warehouse_name": "Warehouse Name",

        "payment_footer": "Payment",
        "make_payment": "Make Payment",
        "receive_payment": "Receive Payment",
        "deposit_advpayment": "Advance deposit",
        "expense_prepayment": "Prepayment",

        "delete_template": "Delete Template",
        "delete_template_message": "Are you sure you want to delete the template? Once it deleted, the template is not retrievable.",
        "delete_template_ok_label": "Yes. Delete It.",
        "delete_template_success_message": "Template deleted successfully.",
        "delete_template_failure_message": "Could not delete settings. Please try again!",
        "before_tax": "Before Tax",
        "outstanding": "Outstanding",

        "signature_section": "Signature Section",
        "prepared_by": "Prepared By",
        "approved_by": "Approved By",

        "no.": 'No.',
        "#": "#",
        "serial_batch": "Serial/Batch",
        "amount_in_words": "Amount In Words",
        "name_with_description": "Name",

        "invoice_number": "Invoice Number",
        "bill_number": "Bill Number",
        "total_invoice_amount": "Total Invoice Amount",
        "total_bill_amount": "Total Bill Amount",
        "payment_made": "Payment Made",
        "current_due_amount": "Current Due Amount",
        "current_balance": "Current Balance",
        "payment_medium": "Payment Medium",
        "cash": "Cash",
        "prepayment": "Prepayment",
        "payment_label": "Payment",
        "receipt_label": "Receipt",
        "total_row": "Total Row",

        "journal_entry": "Journal Entry",
        "journal_entry_number": "JE no.",
        "account_code": "Account Code",
        "credit": "Credit",
        "debit": "Debit",
        "GOOD_RECEIPT": "Goods Receipt",
        "MAKE_PAYMENT": "Payment",
        "RECEIVE_PAYMENT": "Payment",
        "CREDIT_NOTE": "Credit Note",
        "DEBIT_NOTE": "Debit Note",
        "FULFILLMENT": "Fulfillment",
        "SALES_RETURN": "Sales Return",
        "PURCHASE_RETURN": "Purchase Return",
        "SALES_CONTRA": "Sales Contra",
        "PURCHASE_CONTRA": "Purchase Contra",
        "BANK_TRANSFER": "Bank Transfer",
        "TDS_JE": "TDS JE",
        "ADJUSTMENT": "Adjustment",
        "memo": "Memo",

        "pick_list": "Pick List",
        "pack_list": "Pack List",
        "ship_list": "Ship List",
        "invoice_or_quotation": "Invoice/Quotation",
        "product_code": "Product Code",
        "product_name": "Product Name",
        "barcode": "Barcode",
        "quantity_to_pick": "Quantity To Pick",
        "quantity_required_for_order": "Quantity Required For Order",
        "picked": "Picked",
        "item_no": "Item No.",
        "unit": "Unit",
        "packed_quantity": "Packed Quantity",
        "warehouse_address": "Warehouse Address",
        "delivery": "Delivery",
        "carrier": "Carrier",
        "number_of_carton": "Number Of Carton",
        "weight": "Weight",
        "tracking_number": "Tracking Number",
        "package": "Package",
        "order_number": "Order Number",

        "custom_field_is_missing_title": "Custom Field is missing",
        "custom_field_is_missing_message": "The custom field is missing from this document. Kindly add custom field from Book or try with other document.",

        "repeatable_header": "Repeatable Table Header",
        "margin": "Margin",
        "default": "Default",
        "none": "None",
        "minimum": "Minimum",
        "large": "Large",

        "receive_by": "Receive By",
        "ship_by": "Ship By",
        "additional_date": "Additional Date",

        "header_footer_section": "Header Footer Section",
        "header": "Header",
        "date_only": "Date Only",
        "title_only": "Title Only",
        "page_count_only": "Page Count Only",
        "date_title": "Date And Title",
        "date_page_count": "Date And Page Count",
        "title_page_count": "Title And Page Count",
        "date_title_space": "Date And Title With Space",
        "date_page_count_space": "Date And Page Count With Space",
        "title_page_count_space": "Title And Page Count With Space",
        "date_title_page_count": "Date, Title, Page Count",
        "custom": "Custom",
        "left": "Left",
        "center": "Center",
        "right": "Right",
        "title": "Title",
        "page_count": "Page Count",
        "current_page_count": "Current Page Count",
        "total_page_count": "Total Page Count",
        "custom_text": "Custom Text",
        "page": "Page",

        "company_phone_number": "Company Phone Number",
        "company_gstin": "Company GSTIN",
        "company_state_name_and_code": "Company State Name And Code",
        "payment_terms": "Payment Terms",
        "customer_phone_number": "Customer Phone Number",
        "customer_gstin": "Customer GSTIN",
        "customer_state_name_and_code": "Customer State Name And Code",
        "print_only_message": "This is a Computer Generated",
        "hsn_or_sac": "HSN/SAC",
        "taxable_value": "Taxable Value",
        "integrated_tax": "Integrated Tax",
        "central_tax": "Central Tax",
        "state_tax": "State Tax",
        "cess": "CESS",
        "state_name": "State Name",
        "state_code": "Code",

        "address_format": "Address Format",
        "address_1": "Address 1",
        "address_2": "Address 2",
        "city": "City",
        "state": "State",
        "postal_code": "Postal Code",
        "country": "Country",
        "is_apply_address_format_to_other_addresses": "Apply current address format to all addresses.",
        "product_grouping": "Product Grouping",
        "cheque": "Cheque",
        "bank_transfer": "Bank Transfer",
        "card": "Card",

        "payslip": "Payslip",
        "earning_statement": "Earning Statement",
        "pay_period": "Pay Period",
        "pay_date": "Pay Date",
        "ssn": "SSN",
        "taxable_marital_status": "Taxable Marital Status",
        "exemptions": "Exemptions",
        "date_of_joining": "Date Of Joining",
        "pf_a_c_NUMBER": "PF A/C Number",
        "esi_number": "ESI Number",
        "uan_number": "UAN Number",
        "employee_id": "Employee ID",
        "designation": "Designation",
        "employee_net_pay": "Employee Net Pay",
        "employee_earnings": "Employee Earnings",
        "allowances": "Allowances",
        "deductions": "Deductions",
        "employer_contribution": "Employer's Contribution",
        "employer_cpf": "Employer's CPF",
        "net_salary_paid": "Net Salary Paid",
        "employee_taxes_withhold": "Employee Taxes Withhold",
        "employer_taxes": "Employer Taxes",
        "employee_deductions": "Employee Deductions",
        "summary": "Summary",
        "basic_salary": "Basic Salary",
        "net_pay": "Net Pay",
        "additional_earnings": "Additional Earnings",
        "gross_earnings": "Gross Earnings",
        "duration": "Duration",
        "current": "Current",
        "year_to_date": "Year To Date",
        "type": "Type",
        "pre_tax_deductions_contributions": "Pre-tax deductions / contributions",
        "taxes": "Taxes",
        "post_tax_deductions_contributions": "Post-tax deductions / contributions",
        "net_pay_contributions": "Net Pay / contributions",
        "check_amount": "Check Amount",
        "payslip_auto_message": "This is a system generated payslip does not required seal and signature",
        "employee_address": "Employee Address",
        "field_configuration": "Field Configuration",
        "table_configuration": "Table Configuration",
        "employee_name_address": "Employee Name And Address",
        "header_title": "Header Title",
        "product_custom_field": "Product Custom Fields",
        "quantity_and_uom": "Quantity And UOM",
        "includes_product_custom_field": "Includes Product Custom Fields",
        "combines_product_custom_field": "Combines Product Custom Fields",
        "serial_number": "Serial Number",
        "batch_number": "Batch Number",
        "manufacturing_date": "Manufacturing Date",
        "expiry_date": "Expiry Date",
        "override_theme_color": "Override Theme Color",
        "override_global_color": "Override Global Color",
        "contact_number": "Phone Number",
        "contact_code": "Contact Code",
        "blank": "Blank",
        "pay_to": "Pay To",
        "payroll_check": "Payroll Check",
        "pay_to_the": "PAY TO THE",
        "order_of": "ORDER OF",
        "dollars": "DOLLARS",
        "payroll_period": "Payroll for the period of",
        "repeatable_header_footer": "Repeatable Header Footer",
        "repeatable_footer": "Repeatable Footer",
        "includes_hsn_or_sac_code": "Includes HSN/SAC Code",
        "eway_bill_no": "Eway Bill No",
        "eway_bill_date": "Eway Bill Date",
        "place_of_supply": 'Place Of Supply',
        "destination_of_supply": 'Destination Of Supply',
        "source_of_supply": 'Source Of Supply',
        "subTable_configuration": "Sub-table Configuration",
        "additional_table_configuration": "Additional Table Configuration",
        "select_new_type": "Select New Type",
        "qr_code": "QR Code",
        "qr_string": "QR String",
        "generated_qr": "Generated QR",
        "qr_code_with_text": "QR Code With Text",
        "image_with_text": "Image With Text",
        "qr_information": "QR Information",
        "additional_info": "Additional Info",
        "global_discount": "Global Discount",
        "pre_tax": "Pre-Tax",
        "post_tax": "Post-Tax",
        "includes_uom_schema": "Includes UOM Schema",
        "table": "Table",
        "font": "Font",
        "table_row_height": "Table Row Height",
        "padding_horizontal": "Padding Horizontal",
        "padding_vertical": "Padding Vertical",
        "original": "Original",
        "original_for_recipient": "Original For Recipient",
        "duplicate_supply_of_goods": "Duplicate (Supply Of Goods)",
        "duplicate_for_transporter": "Duplicate For Transporter",
        "duplicate_supply_of_service": "Duplicate (Supply Of Service)",
        "duplicate_for_supplier": "Duplicate For Supplier",
        "triplicate": "Triplicate",
        "triplicate_for_supplier": "Triplicate For Supplier",
        "position": "Position",
        "middle": "Middle",
        "sample_watermark": "Sample Watermark",
        "extra_copy": "Extra Copy",
        "quadruplicate": "Quadruplicate",
        "MAX_IMG_UPLOAD_ALERT": "Imgae size can not be greater than 500 KB",
        "system_default": "System Default",
        "qr_resize": "QR Resize",
        "top": "Top",
        "bottom": "Bottom",
        "click_here_to_pay": "Click Here To Pay",
        "generated_message": "Generated Message",
        "sales_order": "Sales Order",
        "help": "Help",
        "includes_account_custom_field": "Includes Product Custom Fields",
        "combines_account_custom_field": "Combines Product Custom Fields",
        "account_custom_field": "Account Custom Fields",
        "selection": "Selection",
        "alias_name_address": "Alias Name And Address",
        "nric_fin_wpn": "NRIC/FIN/WPN",
        "paid_days": "Paid Days",
        "unpaid_days": "Unpaid Days",
        "payslip_overtime": "Overtime",
        "payslip_LOP": "Loss of Pay",
        "outstanding_balance": "Outstanding Balance",
        "outstanding_balance_includes_current": "Outstanding Balance (including current)",
        "top_section_above_table": "Top Section Above Table",
        "is_group_additional_charges_tax": "Is Group Additional Charge Tax",
        "supplier_invoice_no": "Supplier Invoice No",
        "default_template": "Default Template",
        "contact_name": "Contact Name",
        "current_date": "Current Date",
        "e_way_bill_summary": "Eway Bill Summary ",
        "e_way_bill_detail": "Eway Bill Detail",
        "table_column_alignment": "Table Column Alignment",
        "table_column_vertical_alignment": "Vertical Align",
        "earnings_configuration": "Earnings Configuration",
        "label_empty_field_message": "Field label cannot be empty, please enter some value in it to proceed.",
        "product_table": "Product Table",
        "rounding_off": "Rounding Off",
        "work_order": "Work Order",
        "order_prep_completed": "Order Prep Completed",
        "barcode_settings": "Barcode Settings",
        "barcode_resize": "Barcode Resize",
        "comments": "Comments",
        "delivery_date": "Delivery Date",
        "estimated_time": "Estimated Time",
        "actual_quantity": "Actual Quantity",
        "manufacture_quantity": "Manufacture Quantity",
        "parent_work_order": "Parent Work Order",
        "stock_transfer": "Stock Transfer",
        "source": "Source",
        "destination": "Destination",
        "source_address": "Source Address",
        "destination_address": "Destination Address",
        "transfer_date": "Transfer Date",
        "verify_date": "Verify Date",
        "reason": "Reason",
        "warehouse": "Warehouse",
        "stock_adjustment": "Stock Adjustment",
        "source_warehouse_name": "Source Warehouse Name",
        "source_warehouse_code": "Source Warehouse Code",
        "destination_warehouse_name": "Destination Warehouse Name",
        "destination_warehouse_code": "Destination Warehouse Code",
        "product": "Product",
        "show_product": "Show Product",
        "hide_product_with_substitute": "Hide Product With Substitutes",
        "substitute": "Substitute",
        "show_all_substitute": "Show All Substitutes",
        "show_non_zero_substitute": "Show Allocated Substitutes",
        "hide_substitute": "Hide Substitute",
        "combines_line_item_global_discount": "Combine Line Item Global Discount",
        "unit_price_before_discount": "Unit Price Before Discount",
        "unit_price_after_discount": "Unit Price After Discount",
        "discount_per_unit": "Discount Per Unit",
        "show_all": "Show All",
        "percentage": "Percentage",
        "cogs": "Cost of Goods Sold",
        'na': 'NA',
        "con_number": "Customer Order No",
        "document_label": "Barcode Label",
        "expected_delivery_date": "Expected Delivery Date",
    }

};

export default en
