import { Property } from 'csstype';
import { ColumnStyle } from './ColumnStyle';
import TemplateSettingsManager from '../../Manager/TemplateSettingsManager';
import { HeaderColumn } from './HeaderColumn';
import { ItemColumn } from './ItemColumn';
import { TableColumnPopupType } from '../../Components/Popup/TableColumnPopup';
import { DocumentSectionType } from '../../Components/Popup/HeaderFooterPopup';
import Utility from '../../Utilities/Utility';
import { TableDimensionType } from '../../Components/TemplateEditors/DocumentEditor';
import { OwnerType } from '../../Helper/AddressParser';
import TableFooterUtility from '../../Utilities/TableFooterUtility';
export function getNameToId (value: string) {
    if(value === undefined || value === null || value === '') {
        return ''
    }
    return value.toLowerCase().split(' ').join('_')
}

export function getSortedHeaderList(headerList: HeaderColumn[], tableColumnOptions: any) {
    var tableInfo = tableColumnOptions ? tableColumnOptions : TemplateSettingsManager.defaultTemplateSettings.tableInfo
    if (tableInfo.length > 0) {
        var firstIndex = tableInfo[0].index
        if (firstIndex !== undefined && firstIndex !== null) {
            var newHeaderList: HeaderColumn[] = []
            var sortedTableList = tableInfo.filter((x: any) => x.index !== undefined).sort((a: any, b: any) => a.index - b.index)
            sortedTableList.forEach((element: any) => {
                var item = headerList.filter(x => x.name === element.type)
                if (element.type === TableColumnPopupType.productCustomField || element.type === TableColumnPopupType.accountCustomField) {
                    if (element.code !== undefined) {
                        item = headerList.filter(x => x.name === element.type && x.label === element.name)
                    }
                    else {
                        item = headerList.filter(x => x.name === element.type)
                    }
                }
                if (element.type === TableColumnPopupType.productGlobalDiscount) {
                    if (element.code !== undefined) {
                        item = headerList.filter(x => x.name === element.type && x.label === element.name)
                    }
                    else {
                        item = headerList.filter(x => x.name === element.type)
                    }
                }
                if (element.type === TableColumnPopupType.productGlobalDiscount) {
                    if (element.code !== undefined) {
                        item = headerList.filter(x => x.name === element.type && x.label === element.name)
                    }
                    else {
                        item = headerList.filter(x => x.name === element.type)
                    }
                }
                if (item !== undefined && item !== null) {
                    if (item.length > 0) {
                        newHeaderList.push(item[0])
                    }
                }
            });
            headerList = newHeaderList
        }
    }
    return headerList
}

export function getSortedLineItems(items: any) {
    if(items === undefined || items === null) {
        return items
    }

    if(items.length <= 0) {
        return items
    }

    var item = items[0].lineNumber

    if (item === undefined || item === null) {
        return items
    }

    return items.sort((a: any, b: any) => a.lineNumber - b.lineNumber)
}

export function getSortedHeaderType(tableHeader?: any) {
    var headerTypeList: string[] = []
    var tableInfo = TemplateSettingsManager.defaultTemplateSettings.tableInfo
    if(tableHeader !== undefined && tableHeader !== null) {
        tableInfo = tableHeader
    }
    if (tableInfo.length > 0) {
        var firstIndex = tableInfo[0].index
        if (firstIndex !== undefined && firstIndex !== null) {
            headerTypeList = tableInfo.filter((x: any) => x.index !== undefined).sort((a: any, b: any) => a.index - b.index).map((x: any) => x.type)
        }
        else {
            headerTypeList = tableInfo.map(((x: any) => x.type))
        }
    }
    return headerTypeList
}

export function getSortedHeader(tableHeader?: any) {
    var headerTypeList: any[] = []
    var tableInfo = TemplateSettingsManager.defaultTemplateSettings.tableInfo
    if(tableHeader !== undefined && tableHeader !== null) {
        tableInfo = tableHeader
    }
    if (tableInfo.length > 0) {
        var firstIndex = tableInfo[0].index
        if (firstIndex !== undefined && firstIndex !== null) {
            headerTypeList = tableInfo.filter((x: any) => x.index !== undefined).sort((a: any, b: any) => a.index - b.index)
        }
        else {
            headerTypeList = tableInfo
        }
    }
    return headerTypeList
}

export function getFirstTableColumnType(footerList: any, tableType: any) {
    var type = undefined

    var tableInfo = Utility.getTableConfig(tableType)
    if(Utility.getIsReceiptPaperSize()) {
        tableInfo = Utility.getTableConfig(undefined);
    }
    var data = getSortedHeader(tableInfo);

    if (data.length > 0) {
        var isFound = false
        data.forEach(element => {
            if (!footerList.includes(element.type) && !isFound && element.isSelected) {
                isFound = true
                type = element.type
            }
        });
    }
    return type
}

export function getHeaderColumnIndex(data: any, rowIndex: number): number | undefined {
    var columnList: any[] = []
    data[rowIndex].forEach((element : ItemColumn | string) => {
        if(typeof element === 'object') {
            if (element.isHeaderColumn) {
                columnList.push(element)
            }
        }
    });
    if (columnList.length > 0) {
        return data[rowIndex].indexOf(columnList[0])
    }
    return undefined
}

export function getFirstHeaderColumnIndex(data: any): number | undefined {
    var startIndex: number | undefined = undefined
    data.forEach((element: any, index: number) => {
        if (startIndex === undefined) {
            var headerColumn = getHeaderColumnIndex(data, index)
            if (headerColumn !== undefined) {
                startIndex = index
            }
        }
    });
    return startIndex
}
export function getLastHeaderColumnIndex(data: any): number {
    var endIndex: number = 0
    data.forEach((element: any, index: number) => {
        var headerColumn = getHeaderColumnIndex(data, index)
        if (headerColumn !== undefined && index > endIndex) {
            endIndex = index
        }
    });
    return endIndex
}

export function getIsExcludedFooter(data: any, rowIndex: number): boolean {
    var isExcludedFooter = false
    var columnList = data[rowIndex].filter((x: ItemColumn) => x.isHeaderColumn)
    if (columnList.length > 0) {
        var item = columnList[0] as HeaderColumn
        var excludedList = [
            TableColumnPopupType.paymentFooter,
            TableColumnPopupType.outstandingAmount,
            TableColumnPopupType.amountInWords,
            TableColumnPopupType.roundingOff
        ]
        if(item !== undefined) {
            isExcludedFooter = excludedList.includes(item.name)
        }
    }
    return isExcludedFooter
}


export function getExcludedFooterCount(data: any): number {
    var count: number = 0
    data.forEach((element: any, index: number) => {
        var headerColumn = getHeaderColumnIndex(data, index)
        if (headerColumn !== undefined) {
            var item = element[headerColumn]
            if (item !== undefined && item !== null) {
                if (item.name === TableColumnPopupType.paymentFooter) {
                    count = count + 1
                }
                else if(item.name === TableColumnPopupType.outstandingAmount) {
                    count = count + 1
                }
                else if (item.name === TableColumnPopupType.roundingOff) {
                    count = count + 1
                }
                else if (item.name === TableColumnPopupType.amountInWords) {
                    count = count + 1
                }
            }
        }
    });
    return count
}

export function getIsTotalRow(data: any): number {
    var count: number = 0
    data.forEach((element: any, index: number) => {
        if(element[0].isTotalColumn) {
            count = count + 1
        }
    });
    return count
}


export function getIsSelectTotalColumn() {
    var totalColumnSection = TemplateSettingsManager.defaultTemplateSettings.totalColumnSection
    if (totalColumnSection !== undefined) {
        if (totalColumnSection.isVisible !== undefined && totalColumnSection.isVisible !== null) {
            return totalColumnSection.isVisible
        }
    }
    return false
}

export function getIsSelectTotalColumnByType(type: string, code?: string) {
    var totalColumnSection = TemplateSettingsManager.defaultTemplateSettings.totalColumnSection
    if (totalColumnSection !== undefined) {
        if (totalColumnSection.isVisible !== undefined && totalColumnSection.isVisible !== null) {
            if (totalColumnSection.isVisible) {
                if(totalColumnSection.items !== undefined && totalColumnSection.items !== null) {
                    if (totalColumnSection.items.length > 0) {
                        var columnList = totalColumnSection.items
                        var column = columnList[0]
                        var newList = []
                        if (typeof column === 'string' || column instanceof String) {
                            columnList.forEach((element: string) => {
                                var newItem = {
                                    type: element,
                                    code: undefined
                                }
                                newList.push(newItem)
                            });
                        }
                        else {
                            newList = columnList
                        }

                        var item = undefined
                        if(code === undefined || code === null) {
                            item = newList.find((x : any) => x.type === type)
                        }
                        else {
                            item = newList.find((x: any) => x.type === type && x.code === code)
                        }
                        return item !== undefined
                    }
                }
            }
        }
    }
    return false
}
export function getTextAlign(align?: string): Property.TextAlign {
    switch (align) {
        case 'center':
            return 'center'
        case 'end':
            return 'end'
        case 'justify':
            return 'justify'
        case 'left':
            return 'left'
        case 'match-parent':
            return 'match-parent'
        case 'right':
            return 'right'
        default:
            return 'center'
    }
}

export function getBottomBorderColor(style: ColumnStyle) {
    var color = style.borderBottomColor
    if(style.borderColor !== undefined) {
        if (style.borderColor === 'transparent' || style.borderColor === 'white') {
            if(style.borderBottomColor === undefined) {
                color = style.borderColor
            }
            else {
                color = style.borderBottomColor
            }
        }
        else {
            color = style.borderColor
        }
    }
    return color
}

export function getBottomBorderWidth(style: ColumnStyle) {
    var width = style.borderBottomWidth
    if (style.borderColor !== undefined) {
        if (style.borderColor === 'transparent' || style.borderColor === 'white') {
            if (style.borderBottomColor === undefined) {
                width = style.borderWidth
            }
            else {
                width = style.borderBottomWidth
            }
        }
        else {
            width = style.borderWidth
        }
    }
    return width
}

export function getTopBorder(style: ColumnStyle, isLastRow: boolean) {
    var border = style.borderWidth + ' ' + style.borderColor + ' ' + style.borderStyle
    if (style.borderColor !== undefined) {
        if (style.borderColor === 'transparent' || style.borderColor === 'white' || style.borderColor === '#ffffff') {
            if (style.borderTopColor !== undefined && isLastRow) {
                if (style.borderTopWidth !== undefined) {
                    border = style.borderTopWidth + ' ' + style.borderTopColor + ' solid'
                }
                else {
                    border = style.borderTopColor + ' solid'
                }
            }
        }
    }

    return border
}

export function getBottomBorder(style: ColumnStyle, isLastRow: boolean) {
    var border = style.borderWidth + ' ' + style.borderColor + ' ' + style.borderStyle
    if (style.borderColor !== undefined) {
        if (style.borderColor === 'transparent' || style.borderColor === 'white' || style.borderColor === '#ffffff') {
            if (style.borderBottomColor !== undefined && isLastRow) {
                if (style.borderBottomWidth !== undefined) {
                    border = style.borderBottomWidth + ' ' + style.borderBottomColor + ' solid'
                }
                else {
                    border = style.borderBottomColor + ' solid'
                }
            }
        }
    }

    return border
}

export function isLastTableRow(index: number, data: any, dataType: string) {
    var isLastRow = false
    if (data !== undefined && data !== null && dataType === DocumentSectionType.footer) {
        if (data.length > 0) {
            return (data.length - 1) === index
        }
    }
    return isLastRow
}

export function getFontWeight(fontWeight?: string): Property.FontWeight {
    switch (fontWeight) {
        case "normal":
            return "normal";
        case "bold":
            return "bold";
        case "bolder":
            return "bolder";
        case "lighter":
            return "lighter";
        case "100":
            return 100;
        case "200":
            return 200;
        case "300":
            return 300;
        case "400":
            return 400;
        case "500":
            return 500;
        case "600":
            return 600;
        case "700":
            return 700;
        case "800":
            return 800;
        case "900":
            return 900;
        default:
            return "normal";
    }
}


export function getRepeatableHeaderClass() {
    var repeatableTableHeader = TemplateSettingsManager.defaultTemplateSettings.repeatableTableHeader
    if (repeatableTableHeader !== undefined && repeatableTableHeader !== null) {
        if (repeatableTableHeader.isVisible !== undefined && repeatableTableHeader.isVisible !== null ) {
            if(repeatableTableHeader.isVisible) {
                return ' break-inside-avoid'
            }
        }
    }
    return ' break-inside-auto'
}

export function getIsNoProductGrouping() {
    var grouping = TemplateSettingsManager.getProductGrouping()
    return grouping.selection === 'none'
}

export function getGroupHeaderColSpan(isGroupHeaderColumn: boolean, tableColumnOptions: any) {
    if (!getIsNoProductGrouping() && isGroupHeaderColumn) {
        return getColSpanCount(tableColumnOptions);
    }
    return undefined
}

function getColSpanCount(tableColumnOptions: any) {
    var colSpan: any = undefined

    var footerList = TableFooterUtility.getFooterListByType('product')
    var tableInfo = tableColumnOptions ? tableColumnOptions : TemplateSettingsManager.defaultTemplateSettings.tableInfo;
    if (tableInfo.length > 0) {
        var sortedTableList = tableInfo.filter((x: any) => x.index !== undefined).sort((a: any, b: any) => a.index - b.index);
        if (sortedTableList !== undefined && sortedTableList !== null && sortedTableList.length > 0) {
            sortedTableList.forEach((element: any) => {
                if (!footerList.includes(element.type) && element.isSelected) {
                    if (colSpan === undefined) {
                        colSpan = 1;
                    }
                    else {
                        colSpan = colSpan + 1;
                    }
                }
            });
        }
        else {
            tableInfo.forEach((element: any) => {
                if (!footerList.includes(element.type) && element.isSelected) {
                    if (colSpan === undefined) {
                        colSpan = 1;
                    }
                    else {
                        colSpan = colSpan + 1;
                    }
                }
            });
        }
    }
    return colSpan;
}

export function getReceiptFooterData(footerData: any, headerList: HeaderColumn[], tableType: any): any {
    var footerList = TableFooterUtility.getFooterListByType('product')
    if(footerData !== undefined && footerData !== null) {
        if(footerData.length > 0) {
            var firstColumn = undefined
            var firstColumnIndex = -1
            var footerHeaderFirstIndex = -1
            var lastColumnIndex = -1
            var firstColumnType = getFirstTableColumnType(footerList, undefined)
            firstColumn = headerList.find(x => x.name === firstColumnType)
            if (firstColumn !== undefined) {
                firstColumnIndex = headerList.indexOf(firstColumn)
            }
            var isFound = false
            footerData[0].forEach((element: ItemColumn | string, index: number) => {
                if(typeof element ==='object') {
                    if (element.isHeaderColumn !== undefined) {
                        if (element.isHeaderColumn && !isFound) {
                            footerHeaderFirstIndex = index
                            isFound = true
                        }
                    }
                }
            });
            //swapping for last 2nd column to 1st column
            if (footerHeaderFirstIndex !== -1 && firstColumnIndex !== -1 && footerData.length > 0) {
                var defaultNextCounter = 1
                var isFoundNextCounter = false
                var tableInfoList = getSortedHeader(undefined)
                for (let index = firstColumnIndex + 1; index < headerList.length; index++) {
                    if(!isFoundNextCounter) {
                        var header = tableInfoList.find(x => x.type === headerList[index].name)
                        if (header !== undefined) {
                            if (header.isSelected) {
                                isFoundNextCounter = true
                            }
                            else {
                                defaultNextCounter = defaultNextCounter + 1
                            }
                        }
                    }
                }

                var footerDataLastIndex = firstColumnIndex + defaultNextCounter
                footerData[0].forEach((element: ItemColumn | string, index : number) => {
                    if(typeof element === 'string') {
                        lastColumnIndex = index
                    }
                });
                footerData.forEach((element: any, index: number) => {
                    var sourceHeader = element[footerHeaderFirstIndex]
                    var targetHeader = element[firstColumnIndex]
                    element[footerHeaderFirstIndex] = targetHeader
                    element[firstColumnIndex] = sourceHeader


                    var sourceValue = element[lastColumnIndex]
                    var targetValue = element[footerDataLastIndex]
                    element[lastColumnIndex] = targetValue
                    element[footerDataLastIndex] = sourceValue

                    for (let index = footerDataLastIndex + 1; index < footerData[0].length ; index++) {
                        if(element[index] !== undefined) {
                            element[index] = undefined
                        }
                    }
                });

                var newFooterData: (string | ItemColumn)[][] = []
                footerData.forEach((row : any) => {
                    var newRow: (string | ItemColumn)[] = []
                    row.forEach((element: ItemColumn | string | undefined) => {
                        if(element !== undefined) {
                            newRow.push(element)
                        }
                    });
                    newFooterData.push(newRow)
                });
                footerData = newFooterData
            }
        }
    }

    return footerData
}

export function getReceiptFooterColumnSpan() {
    var totalColSpan = getColSpanCount(undefined)
    if (totalColSpan !== 0) {
        return { left: totalColSpan, right: totalColSpan }
    }

    return { left: 0, right: 0 }
}

export function getTableDataHeight() {
    let isTableRowHeightEnable = false
    if (Utility.getGlobalTableFontStyleWithProperty('isFixRowHeightEnable') !== undefined && Utility.getGlobalTableFontStyleWithProperty('isFixRowHeightEnable') !== null) {
        isTableRowHeightEnable = Utility.getGlobalTableFontStyleWithProperty('isFixRowHeightEnable')
    }

    if(isTableRowHeightEnable) {
        let style = Utility.getGlobalTableFontStyle()
        if (style !== undefined) {
            return style.table.font.rowHeight
        }
    }

    return undefined
}

export function getTablePadding(defaultPadding: any, tableDimensionType: string) {
    var isPaddingEnable = false
    var fieldName = ''

    if(tableDimensionType === TableDimensionType.PADDING_HORIZONTAL) {
        fieldName = 'isPaddingHorizontalEnable'
    }
    else if (tableDimensionType === TableDimensionType.PADDING_VERTICAL) {
        fieldName = 'isPaddingVerticalEnable'
    }

    if(fieldName !== '') {
        if (Utility.getGlobalTableFontStyleWithProperty(fieldName) !== undefined
            && Utility.getGlobalTableFontStyleWithProperty(fieldName) !== null) {
            isPaddingEnable = Utility.getGlobalTableFontStyleWithProperty(fieldName)
        }
    }

    if (isPaddingEnable) {
        let style = Utility.getGlobalTableFontStyle()
        if (style !== undefined && defaultPadding !== undefined) {
            if(defaultPadding.includes('vw')) {
                var value = Number(defaultPadding.replace('vw',''))
                if(!isNaN(value)) {
                    if (tableDimensionType === TableDimensionType.PADDING_HORIZONTAL) {
                        value = value + style.table.font.paddingHorizontal
                    }
                    else if (tableDimensionType === TableDimensionType.PADDING_VERTICAL) {
                        value = value + style.table.font.paddingVertical
                    }
                }
                return value + 'vw'
            }
        }
    }

    //overriwte with less padding for some templates
    var tablePadding = Utility.getReduceSpacingTablePadding(isPaddingEnable)
    if(tablePadding !== undefined) {
        var tablePaddingValue = Number(defaultPadding.replace('vw', ''))
        if (!isNaN(tablePaddingValue)) {
            tablePaddingValue = tablePaddingValue + tablePadding
        }
        return tablePaddingValue + 'vw'
    }

    return defaultPadding
}



export function getTableFontStyleProp(style: any, printScale?: number) {
    var newStyle = Utility.getFontStyleProp(style, printScale)
    if (Utility.getGlobalTableFontStyleWithProperty('isEnable') !== undefined && Utility.getGlobalTableFontStyleWithProperty('isEnable') !== null) {
        if(Utility.getGlobalTableFontStyleWithProperty('isEnable')) {
            if (style.fontSize !== undefined) {
                newStyle['fontSize'] = style.fontSize
                var newFontSize = Utility.getTableFontSize(style.fontSize, printScale)
                if(newFontSize !== undefined) {
                    newStyle['fontSize'] = newFontSize
                }
            }

            if (style.color !== undefined) {
                newStyle['color'] = style.color
            }

            if (Utility.getGlobalTableFontStyleWithProperty('style') !== undefined) {
                newStyle['font-style'] = Utility.getGlobalTableFontStyleWithProperty('style')
            }

            if (style.fontWeight !== undefined) {
                newStyle['font-weight'] = style.fontWeight
            }
            else if (Utility.getGlobalTableFontStyleWithProperty('weight') !== undefined) {
                newStyle['font-weight'] = Utility.getGlobalTableFontStyleWithProperty('weight')
            }

            if (Utility.getGlobalFontStyle('isOverrideThemeColor', OwnerType.tenant) !== undefined && Utility.getGlobalFontStyle('isOverrideThemeColor', OwnerType.tenant) !== null) {
                if (Utility.getGlobalFontStyle('isOverrideThemeColor', OwnerType.tenant)) {
                    if (Utility.getGlobalFontStyle('color', OwnerType.tenant) !== undefined) {
                        newStyle['color'] = Utility.getGlobalFontStyle('color', OwnerType.tenant)
                    }
                }
            }

        }
    }

    return newStyle
}

export function getLastTableColumnType(footerList: any, tableType: any) {
    var data = getSortedHeader(Utility.getTableConfig(tableType))

    if (data.length > 0) {
        data = data.filter(x => x.isSelected)
        data = data.filter(x => !footerList.includes(x.type))
        if (data.length > 0) {
            if (data[0].index !== undefined && data[0].index !== null) {
                data = data.sort((a, b) => b.index - a.index)
                return data[0].type
            }
            else {
                var lastIndex = data.length - 1
                if(data[lastIndex] !== undefined) {
                    return data[lastIndex].type
                }
            }
        }
    }
    return undefined
}

export function setLastColumnHeaderRightAlign(headerColumnList: any, footerList: any, tableType: any) {
    if(headerColumnList !== undefined && headerColumnList !== null) {
        if(headerColumnList.length > 0 && footerList.length > 0) {
            var lastColumnType = getLastTableColumnType(footerList, tableType)
            if(lastColumnType !== undefined) {
                headerColumnList.forEach((element: any) => {
                    if(element.name === lastColumnType) {
                        element.style.alignment = 'right'
                    }
                });
            }
        }
    }

    return headerColumnList
}