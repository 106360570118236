import React from "react";
import { DocumentSectionType } from "../Components/Popup/HeaderFooterPopup";
import { getLocalisedText } from "../Translate/LanguageManager";

export default class HeaderFooterUtility {

  static getTitle(documentData) {
    var title = getLocalisedText('sample_title')
    if (documentData.type !== undefined && documentData.type !== null) {
      title = documentData.type.toUpperCase()
      if (documentData.refNumber !== undefined && documentData.refNumber !== null) {
        title = title + ' - ' + documentData.refNumber
      }
    }
    return title
  }

  static getContainerByType(headerFooter, type, documentData) {
    var container = undefined

    var value = {}
    value.containerStyle = HeaderFooterUtility.getFooterHeaderValueByKey(headerFooter, 'containerStyle')
    value.type = HeaderFooterUtility.getFooterHeaderValueByKey(headerFooter, 'type')
    value.customField = HeaderFooterUtility.getFooterHeaderValueByKey(headerFooter, 'customField')

    if (type === DocumentSectionType.header) {
      if (value.type[DocumentSectionType.header] !== undefined && value.type[DocumentSectionType.header] !== null) {
        container = HeaderFooterUtility.getPreviewHeaderFooterContainer(DocumentSectionType.header, value.type[DocumentSectionType.header], value.containerStyle[DocumentSectionType.header], value.customField[DocumentSectionType.header], documentData)
      }
    }
    else if (type === DocumentSectionType.footer) {
      if (value.type[DocumentSectionType.footer] !== undefined && value.type[DocumentSectionType.footer] !== null) {
        container = HeaderFooterUtility.getPreviewHeaderFooterContainer(DocumentSectionType.footer, value.type[DocumentSectionType.footer], value.containerStyle[DocumentSectionType.footer], value.customField[DocumentSectionType.footer], documentData)
      }
    }

    return container
  }

  static getFooterHeaderValueByKey(headerFooter, key) {
    var value = []
    if (headerFooter !== undefined && headerFooter !== null) {
      if (headerFooter.header !== undefined && headerFooter.header !== null) {
        value[DocumentSectionType.header] = headerFooter.header[key]
      }
      if (headerFooter.footer !== undefined && headerFooter.footer !== null) {
        value[DocumentSectionType.footer] = headerFooter.footer[key]
      }
    }
    return value
  }

  static getPreviewHeaderFooterContainer(val, type, containerStyle, customFields, documentData) {
    switch (type) {
      case 'none':
        return ''
      case 'date_only':
        return <div id={this.getTemplateId(val)} style={{ ...containerStyle }}><span className="date">{new Date().toLocaleDateString()}</span></div>
      case 'title_only':
        return <div id={this.getTemplateId(val)} style={{ ...containerStyle }}>{this.getTitle(documentData)}</div>
      case 'page_count_only':
        return <div id={this.getTemplateId(val)} style={{ ...containerStyle }}> {getLocalisedText('page')}&nbsp;1/1</div>
      case 'date_title':
        return <div id={this.getTemplateId(val)} style={{ ...containerStyle }}>
          <span className="date">{new Date().toLocaleDateString()}</span>
          &nbsp;&nbsp;
          <span className="title">{this.getTitle(documentData)}</span>
        </div>
      case 'date_page_count':
        return <div id={this.getTemplateId(val)} style={{ ...containerStyle }}>
          <span className="date">{new Date().toLocaleDateString()}</span>
          &nbsp;&nbsp;
          {getLocalisedText('page')}&nbsp;<span className="pageNumber">1</span> / <span className="totalPages">1</span>
        </div>
      case 'title_page_count':
        return <div id={this.getTemplateId(val)} style={{ ...containerStyle }}>
          <span className="title">{this.getTitle(documentData)}</span>
          &nbsp;&nbsp;
          {getLocalisedText('page')}&nbsp;<span className="pageNumber">1</span> / <span className="totalPages">1</span>
        </div>
      case 'date_title_space':
        return <div id={this.getTemplateId(val)} style={{
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'row',
          ...containerStyle
        }}>
          <span className="date" style={{
            display: 'flex',
            flexDirection: 'row',
          }}>{new Date().toLocaleDateString()}</span>
          &nbsp;&nbsp;
          <span className="title" style={{
            display: 'flex',
            flexDirection: 'row',
          }}> {this.getTitle(documentData)}</span>
        </div>
      case 'date_page_count_space':
        return <div id={this.getTemplateId(val)} style={{
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'row',
          ...containerStyle
        }}>
          <span className="date" style={{
            display: 'flex',
            flexDirection: 'row',
          }}>{new Date().toLocaleDateString()}</span>
          &nbsp;&nbsp;
          <div style={{
            display: 'flex',
            flexDirection: 'row',
          }}>
            {getLocalisedText('page')}&nbsp;<span className="pageNumber">1</span> / <span className="totalPages">1</span>
          </div>
        </div>
      case 'title_page_count_space':
        return <div id={this.getTemplateId(val)} style={{
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'row',
          ...containerStyle
        }}>
          <span className="title" style={{
            display: 'flex',
            flexDirection: 'row',
          }}> {this.getTitle(documentData)}</span>
          &nbsp;&nbsp;
          <div style={{
            display: 'flex',
            flexDirection: 'row',
          }}>
            {getLocalisedText('page')}&nbsp;<span className="pageNumber">1</span> / <span className="totalPages">1</span>
          </div>
        </div>
      case 'date_title_page_count':
        return <div id={this.getTemplateId(val)} style={{
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'row',
          ...containerStyle
        }}>
          <span className="date" style={{
            display: 'flex',
            flexDirection: 'row',
          }}>{new Date().toLocaleDateString()}</span>
          &nbsp;&nbsp;
          <span className="title" style={{
            display: 'flex',
            flexDirection: 'row',
          }}> {this.getTitle(documentData)}</span>
          &nbsp;&nbsp;
          <div style={{
            display: 'flex',
            flexDirection: 'row',
          }}>
            {getLocalisedText('page')}&nbsp;<span className="pageNumber">1</span> / <span className="totalPages">1</span>
          </div>
        </div>
      case 'custom':
        return <div id={this.getTemplateId(val)} style={{ ...containerStyle }}>
          {this.getPreviewCustomField(documentData, customFields)}
        </div>
      default:
        break;
    }
  }

  static getPreviewCustomField(documentData, customFields) {
    var list = customFields
    if (list !== undefined && list !== null) {
      if (list.length > 0) {
        return list.map((element, index) => {
          return this.getCustomField(documentData, element.type, element.text, list.length - 1 === index)
        });
      }
    }
    return <></>
  }

  static getCustomField(documentData, type, value, isLastIndex) {
    if (!isLastIndex) {
      return <>
        {this.getSelectedFieldValue(type, value, documentData)}
        &nbsp;&nbsp;
      </>
    }
    return this.getSelectedFieldValue(type, value, documentData)
  }

  static getSelectedFieldValue(type, value, documentData) {
    switch (type) {
      case 'title':
        return <span className="title"> {HeaderFooterUtility.getTitle(documentData)}</span>
      case 'date':
        return <span className="date">{new Date().toLocaleDateString()}</span>
      case 'pageCount':
        return <span>
          {getLocalisedText('page')}&nbsp;<span className="pageNumber">1</span> / <span className="totalPages">1</span>
        </span>
      case 'currentPageCount':
        return <span className="pageNumber">1</span>
      case 'totalPageCount':
        return <span className="totalPages">1</span>
      case 'customText':
        return <span>{value}</span>
      default:
        break;
    }
  }

  static getTemplateId(val) {
    switch (val) {
      case DocumentSectionType.header:
        return 'header-template'
      case DocumentSectionType.footer:
        return 'footer-template'
      default:
        return ''
    }
  }
}
