import React from 'react';
import Draggable, { DraggableEventHandler } from 'react-draggable';
import './DraggableLabel.css';
import { TemplateItem } from '../model/TemplateItem';
import { Property } from 'csstype';
import { CoordinateUtility, Coordinate } from '../../utilities/CoordinateUtility';
import { ScreenState } from '../model/Enum';
import { DKContentEditableDivWithRef } from '../../../../DKUILibrary/DKContentEditableDivWithRef';
import Utility, { getVW } from '../../../../Utilities/Utility';
import { HTMLTag } from '../../../../Utilities/HTMLTag';
import { PREVIEW_SCALE, PRINT_SCALE } from '../../../../Constants/Constants';
interface Props {
    templateItem: TemplateItem
    xDrag: number
    yDrag: number
    scale: number
    handleStart: DraggableEventHandler
    handleDrag: DraggableEventHandler
    handleStop: DraggableEventHandler
    onMouseDown: (e: MouseEvent) => void,
    grid: [number, number]
    coordinateUtility: CoordinateUtility
    screenState: ScreenState
    isDragging: boolean
    disable: boolean
    selectedTemplateId: number
    isEdit: boolean
    onComponentChange: (templateItem: TemplateItem) => void
    printScale: number
}

interface States {
    isEdit: boolean
}
export default class DraggableLabel extends React.Component<Props, States> {
    constructor(props: any) {
        super(props)
        this.state = {
            isEdit: this.props.isEdit
        }
    }
    render() {
        var coordinate = new Coordinate(0, 0, this.props.templateItem.x, this.props.templateItem.y, this.props.templateItem.originalWindowInnerWidth)
        var id = HTMLTag.DRAGGABLE_COMPONENT_PREFIX + this.props.templateItem.id
        var position = this.props.coordinateUtility.toPosition(coordinate, this.props.printScale === PREVIEW_SCALE);

        return (
            <Draggable
                axis="both"
                handle=".handle"
                disabled={this.props.disable}
                // defaultPosition={{
                //     x: this.props.coordinateUtility.toPosition(new Coordinate(0, 0, this.props.templateItem.x, this.props.templateItem.y)).x,
                //     y: this.props.coordinateUtility.toPosition(new Coordinate(0, 0, this.props.templateItem.x, this.props.templateItem.y)).y
                // }}
                position={{
                    x: position.x,
                    y: position.y
                }}
                grid={this.props.grid}
                scale={this.props.scale}
                defaultClassName={this.isShowBorder() ? "draggableBorderDiv" : "draggableDiv"}
                onStart={(e, data) => this.props.handleStart(e, data)}
                onDrag={(e, data) => this.props.handleDrag(e, data)}
                onStop={(e, data) => this.props.handleStop(e, data)}
                onMouseDown={(e) => this.props.onMouseDown(e)}
            >
                <div id={id} className="handle" style={{
                    zIndex: 4,
                }}>
                    <div
                        style={{
                            textAlign: this.getTextAlign(),
                        }}
                        onBlur={() => this.setState({ isEdit: false })}
                        onMouseEnter={() => this.setState({ isEdit: true })}
                    >
                        {this.renderTextDiv()}
                    </div>
                </div>
            </Draggable>
        );
    }

    renderTextDiv() {
        if (this.state.isEdit && this.props.templateItem.systemField === undefined) {
            return (
                <DKContentEditableDivWithRef
                    valueChangeCallback={(event: any) => this.onTextChange(event)}
                    value={this.props.templateItem.newText}
                    valueStr={''}
                    className={''}
                    style={{
                        color: this.props.templateItem.style.fontColor ? this.props.templateItem.style.fontColor : 'black',
                        alignItems: this.props.templateItem.style.alignment ? this.props.templateItem.style.alignment : 'center',
                        fontSize: this.getFontSize(this.props.printScale),
                        opacity: this.props.templateItem.style.opacity ? this.props.templateItem.style.opacity : 1,
                        fontFamily: this.props.templateItem.style.fontFamily ? this.props.templateItem.style.fontFamily : undefined,
                        fontStyle: this.props.templateItem.style.fontStyle ? this.props.templateItem.style.fontStyle : undefined,
                        fontWeight: this.getFontWeight(),
                        zIndex: 4,
                        width: '100%',
                        height: '100%',
                        textAlign: this.getTextAlign(),
                        border: this.props.isEdit ? '2px solid #3773e1' : undefined,
                    }}
                    disabled={this.props.templateItem.systemField !== undefined}
                />

                // <textarea name="body"
                // id={'draggable_component_text_input_' + this.props.templateItem.id}
                //     onChange={(event: any) => this.onTextChange(event)}
                //     className='draggableTextInput'
                //     value={this.props.templateItem.newText}
                //     style={{
                //         color: this.props.templateItem.style.fontColor ? this.props.templateItem.style.fontColor : 'black',
                //         alignItems: this.props.templateItem.style.alignment ? this.props.templateItem.style.alignment : 'center',
                //         fontSize: this.getFontSize(),
                //         opacity: this.props.templateItem.style.opacity ? this.props.templateItem.style.opacity : 1,
                //         fontFamily: this.props.templateItem.style.fontFamily ? this.props.templateItem.style.fontFamily : undefined,
                //         fontStyle: this.props.templateItem.style.fontStyle ? this.props.templateItem.style.fontStyle : undefined,
                //         fontWeight: this.getFontWeight(),
                //         zIndex: 9999,
                //         width: '100%',
                //         height: '100%',
                //         textAlign: this.getTextAlign(),
                //     }}
                //     />
                // <input type="text"
                //     id={'draggable_component_text_input_' + this.props.templateItem.id}
                //     value={this.props.templateItem.newText}
                //     onChange={(event: any) => this.onTextChange(event)}
                //     className='draggableTextInput'
                //     style={{
                //         color: this.props.templateItem.style.fontColor ? this.props.templateItem.style.fontColor : 'black',
                //         alignItems: this.props.templateItem.style.alignment ? this.props.templateItem.style.alignment : 'center',
                //         fontSize: this.getFontSize(),
                //         opacity: this.props.templateItem.style.opacity ? this.props.templateItem.style.opacity : 1,
                //         fontFamily: this.props.templateItem.style.fontFamily ? this.props.templateItem.style.fontFamily : undefined,
                //         fontStyle: this.props.templateItem.style.fontStyle ? this.props.templateItem.style.fontStyle : undefined,
                //         fontWeight: this.getFontWeight(),
                //         zIndex: 9999,
                //         width: '100%',
                //         height: '100%',
                //     }} />
            )
        }
        else {
            return (
                // <text style={{
                //     color: this.props.templateItem.style.fontColor ? this.props.templateItem.style.fontColor : 'black',
                //     alignItems: this.props.templateItem.style.alignment ? this.props.templateItem.style.alignment : 'center',
                //     fontSize: this.getFontSize(),
                //     opacity: this.props.templateItem.style.opacity ? this.props.templateItem.style.opacity : 1,
                //     fontFamily: this.props.templateItem.style.fontFamily ? this.props.templateItem.style.fontFamily : undefined,
                //     fontStyle: this.props.templateItem.style.fontStyle ? this.props.templateItem.style.fontStyle : undefined,
                //     fontWeight: this.getFontWeight(),
                //     zIndex: this.props.templateItem.style.zIndex
                // }}>
                //     {this.props.templateItem.newText}
                // </text>

                <div
                    style={{
                        color: this.props.templateItem.style.fontColor ? this.props.templateItem.style.fontColor : 'black',
                        alignItems: this.props.templateItem.style.alignment ? this.props.templateItem.style.alignment : 'center',
                        fontSize: this.getFontSize(this.props.printScale),
                        opacity: this.props.templateItem.style.opacity ? this.props.templateItem.style.opacity : 1,
                        fontFamily: this.props.templateItem.style.fontFamily ? this.props.templateItem.style.fontFamily : undefined,
                        fontStyle: this.props.templateItem.style.fontStyle ? this.props.templateItem.style.fontStyle : undefined,
                        fontWeight: this.getFontWeight(),
                        zIndex: 4,
                        border: this.props.isEdit ? '2px solid #3773e1' : undefined,
                    }}
                    dangerouslySetInnerHTML={{ __html: this.props.templateItem.newText ? this.props.templateItem.newText : '' }}
                />

            )
        }
    }

    onTextChange(event: any) {
        var data = this.props.templateItem;
        var innerHTML = event.target.innerHTML;
        data.newText = innerHTML.split('<br>').join('\n').split('<div>').join('\n').split('</div>').join('')
        this.props.onComponentChange(data)
    }
    isShowBorder() {
        if (this.props.screenState === ScreenState.VIEW) {
            return false
        }
        if (this.props.templateItem.id === this.props.selectedTemplateId) {
            return true
        }
        else {
            return false
        }
        // if(this.props.templateItem.systemField === undefined || this.props.templateItem.systemField === null) {
        //     return false
        // }
        // if(this.props.templateItem.systemField === '' || this.props.templateItem.systemField === ' ') {
        //     return false
        // }
        // return this.props.isDragging
    }

    getTextAlign(): Property.TextAlign {
        switch (this.props.templateItem.style.alignment) {
            case 'center':
                return 'center'
            case 'end':
                return 'end'
            case 'justify':
                return 'justify'
            case 'left':
                return 'left'
            case 'match-parent':
                return 'match-parent'
            case 'right':
                return 'right'
            default:
                return 'center'
        }
    }
    getFontWeight(): Property.FontWeight {
        switch (this.props.templateItem.style.fontWeight) {
            case 'normal':
                return 'normal'
            case 'bold':
                return 'bold'
            case 'bolder':
                return 'bolder'
            case 'lighter':
                return 'lighter'
            case '100':
                return 100
            case '200':
                return 200
            case '300':
                return 300
            case '400':
                return 400
            case '500':
                return 500
            case '600':
                return 600
            case '700':
                return 700
            case '800':
                return 800
            case '900':
                return 900
            default:
                return 'normal'
        }
    }

    getFontSize(printScale: number = PRINT_SCALE) {
        if (this.props.templateItem.style.fontSize === undefined) {
            return undefined
        }
        var size = Number(this.props.templateItem.style.fontSize.replace('px', ''))
        if(isNaN(size)) {
            return undefined
        }
        return getVW(Utility.convertToPrintScale(size, printScale))
    }
}
