import CustomFieldManager from "../../Manager/CustomFieldManager"
import TemplateSettingsManager from "../../Manager/TemplateSettingsManager"
import { getLocalisedText } from "../../Translate/LanguageManager"
import { getCapitalized } from "../../Utilities/Utility"
import Address from "../Address"
import AddressParser, { AddressType, OwnerType } from "../AddressParser"
import DataParser from "../DataParser"
import LineItemsParser from "../LineItemsParser"

export default class ShipListParser {
    static parseData(dataFromAPI, templateDataToReturn) {

        //company name
        var name = ''
        if (this.getPayloadData(dataFromAPI) !== undefined) {
            if (this.getPayloadData(dataFromAPI).companyName !== undefined && this.getPayloadData(dataFromAPI).companyName !== null) {
                name = getCapitalized(this.getPayloadData(dataFromAPI).companyName)
            }
        }
        templateDataToReturn.companyName = name

        //company address
        var fromAddressObj = new Address(undefined, dataFromAPI.shipFromAddress, dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
        templateDataToReturn.fromObj = fromAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.from, templateDataToReturn)

        // billing to address
        var customer = this.getPayloadData(dataFromAPI)
        var address = {
            address1: customer.custAdd1,
            address2: customer.custAdd2,
            postalCode: customer.custZip,
            country: customer.custCountry,
        }

        var contactName = ''
        var contactNumber = ''
        var contactCode = ''

        if (customer.custName !== undefined && customer.custName !== null) {
            contactName = customer.custName
        }

        if (customer.cPhone !== undefined && customer.cPhone !== null) {
            contactNumber = customer.cPhone
        }

        var contactInfo = customer.contactInfo
        if (contactInfo !== undefined && contactInfo !== null) {
            if (contactInfo.name !== undefined && contactInfo.name !== null) {
                contactName = contactInfo.name
            }
            if (contactInfo.contactNumber !== undefined && contactInfo.contactNumber !== null) {
                contactNumber = contactInfo.contactNumber
            }
            if (contactInfo.documentSequenceCode !== undefined && contactInfo.documentSequenceCode !== null) {
                contactCode = contactInfo.documentSequenceCode
            }
        }

        var clientBillToAddressObj = new Address(contactName, address, dataFromAPI.pan, dataFromAPI.gstin, contactNumber, contactCode, OwnerType.contact)
        templateDataToReturn.clientBillToAddressObj = clientBillToAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.billTo, templateDataToReturn)

        // shipping to address
        var company = this.getPayloadData(dataFromAPI)
        address = {
            address1: company.comAdd1,
            address2: company.comAdd2,
            postalCode: company.comZip,
            country: company.comCountry,
        }

        var companyName = ''
        if (company.companyName !== undefined && company.companyName !== null) {
            companyName = company.companyName
        }
        var clientShipToAddressObj = new Address(companyName, address, dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
        templateDataToReturn.clientShipToAddressObj = clientShipToAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.shipTo, templateDataToReturn)

        //ref number
        var linkDocumentNumber = ''
        if (this.getPayloadData(dataFromAPI) !== undefined) {
            if (this.getPayloadData(dataFromAPI).orderNo !== undefined && this.getPayloadData(dataFromAPI).orderNo !== null) {
                linkDocumentNumber = this.getPayloadData(dataFromAPI).orderNo
            }
        }
        templateDataToReturn.linkDocumentNumber = linkDocumentNumber
        templateDataToReturn.linkDocumentType = getLocalisedText('order_number')
        templateDataToReturn.showLinkDocumentNumber = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.linkDocumentNumber.isVisible

        var count = 1
        var total = 1
        var isFragile = ''

        if (this.getPayloadData(dataFromAPI) !== undefined) {
            if (this.getPayloadData(dataFromAPI).i !== undefined && this.getPayloadData(dataFromAPI).i !== null) {
                count = this.getPayloadData(dataFromAPI).i
            }
            if (this.getPayloadData(dataFromAPI).n !== undefined && this.getPayloadData(dataFromAPI).n !== null) {
                total = this.getPayloadData(dataFromAPI).n
            }
            if (this.getPayloadData(dataFromAPI).isFragile !== undefined && this.getPayloadData(dataFromAPI).isFragile !== null) {
                isFragile = this.getPayloadData(dataFromAPI).isFragile
            }
        }
        templateDataToReturn.isFragile = isFragile
        templateDataToReturn.packageCount = count + ' / ' + total

        //start date
        templateDataToReturn.date = ''
        templateDataToReturn.showStartDate = false

        //due date
        templateDataToReturn.dueDate = ''
        templateDataToReturn.showDueDate = false

        //line item
        templateDataToReturn.lineItems = LineItemsParser.getShipItem(dataFromAPI)

        //custom field
        templateDataToReturn.customFields = dataFromAPI.customFields
        CustomFieldManager.setCustomFields(dataFromAPI.customFields)
        templateDataToReturn = DataParser.assignCustomFieldValue(templateDataToReturn)

        return templateDataToReturn
    }

    static getPayloadData(data) {
        if (data.payload === undefined || data.payload === null) {
            return undefined
        }

        if (data.payload.length === 0) {
            return undefined
        }

        return data.payload[0]
    }
}
