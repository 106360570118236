import { DKIcons, DKButton } from 'deskera-ui-library';
import { getCurrencySymbolFromCode, toCurrencyFormat } from './Helper';

export const amountRenderer = ({ rowData }, columnKey) => {
  const currencyCode = getCurrencySymbolFromCode(rowData?.currency);
  return (
    <div className="row justify-content-end">
      {toCurrencyFormat(rowData[columnKey] || 0, currencyCode)}
    </div>
  );
};

export const attachmentRenderer = ({ rowData }, onView) => {
  if (rowData?.attachmentsWithLink?.length) {
    return (
      <div className="row">
        <DKButton
          title="View"
          icon={DKIcons.ic_attachment}
          className="p-v-0"
          onClick={onView}
        />
      </div>
    );
  } else {
    return <></>;
  }
};
