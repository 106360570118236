import React, { Component } from 'react';
import '../../DocPreview.css';
import '../../CommonStyles/FontStyle.css';
import { FONT_SIZE, PAGE_PADDING } from '../../Constants/Constants';
import DKCustomFieldSection from '../../DKUILibrary/DKCustomFieldSection';
import DKEInvoiceSection, { eInvoiceSectionPosition } from '../../DKUILibrary/DKEInvoiceSection';
import DKSignatureSection from '../../DKUILibrary/DKSignatureSection';
import { AddressType } from '../../Helper/AddressParser';
import ComponentManager from '../../Manager/ComponentManager';
import DocumentManager from '../../Manager/DocumentManager';
import { getLocalisedText } from '../../Translate/LanguageManager';
import { HTMLTag } from '../../Utilities/HTMLTag';
import Utility, { getFontSizeClass, getIsFulfillment, getIsGoodsReceipt, getIsJournalEntry, getPageHeight, getVW } from "../../Utilities/Utility";
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";

export default class DocumentTemplate33 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ justifyContent: 'space-between', minHeight: getPageHeight(this.state.data.printScale) }}>
                <div className='parent-width' style={{ width: '100%', paddingBottom: getVW(30)  }}>
                    <DKEInvoiceSection
                        data={this.state.data}
                        position={eInvoiceSectionPosition.top}
                    />
                    {this.getHeaderSection()}
                    {ComponentManager.addVerticalSpace(25)}
                    {this.state.data.showClientBillToAddress && this.getBillingAddress()}
                    {this.state.data.showClientShipToAddress && ComponentManager.addVerticalSpace(12.5)}
                    {this.state.data.showClientShipToAddress && this.getShippingAddress()}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data) || Utility.getIsShowPlaceOfSupply(this.state.data)) && ComponentManager.addVerticalSpace(10)}
                    {Utility.getIsShowPlaceOfSupply(this.state.data) && this.getPlaceOfSupplyInfoSection()}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && this.getSourceOfSupply()}
                    {Utility.getIsContainsCustomField('top') &&
                        <>
                            {ComponentManager.addVerticalSpace(12.5)}
                            <div className="RowDiv" style={{
                                backgroundColor: '#F6F6F6',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                paddingLeft: `${Utility.convertToPrintScale(Number(PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`,
                                paddingRight: `${Utility.convertToPrintScale(Number(PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`,
                                paddingTop: getVW(Utility.convertToPrintScale(15, this.state.data.printScale)),
                                paddingBottom: getVW(Utility.convertToPrintScale(15, this.state.data.printScale)),
                                // marginLeft: "-" + PAGE_PADDING,
                                width: '100%'
                            }}>
                            <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'top'} />
                            </div>
                            {ComponentManager.addVerticalSpace(12.5)}
                        </>
                    }
                    {ComponentManager.addVerticalSpace(12.5)}
                    {
                        TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                        <>
                            <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                                dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                            {ComponentManager.addVerticalSpace(5)}
                        </>
                    }
                    {Utility.getIsShowAccountProductTable(this.state.data) && this.getProductTableSection()}
                    {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(12.5)}
                    {this.getTableSection()}
                    {Utility.getIsContainsCustomField('bottom') &&
                        <>
                            {ComponentManager.addVerticalSpace(12.5)}
                            <div className="RowDiv" style={{
                                backgroundColor: '#F6F6F6',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                paddingLeft: `${Utility.convertToPrintScale(Number(PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`,
                                paddingRight: `${Utility.convertToPrintScale(Number(PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`,
                                paddingTop: getVW(Utility.convertToPrintScale(15, this.state.data.printScale)),
                                paddingBottom: getVW(Utility.convertToPrintScale(15, this.state.data.printScale)),
                                // marginLeft: "-" + PAGE_PADDING,
                                width: '100%'
                            }}>
                                <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'bottom'} />
                            </div>
                            {ComponentManager.addVerticalSpace(12.5)}
                        </>
                    }
                    <div id={HTMLTag.SIGNATURE}>
                        {ComponentManager.addVerticalSpace(30)}
                        {this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature, this.state.data.printScale)}
                    </div>
                </div>
                <div className='parent-width' style={{ width: '100%' }}>
                    {(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) &&
                        <>
                            <div className="RowDiv" style={{
                                backgroundColor: '#F6F6F6',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                paddingLeft: `${Utility.convertToPrintScale(Number(PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`,
                                paddingRight: `${Utility.convertToPrintScale(Number(PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`,
                                paddingTop: getVW(Utility.convertToPrintScale(15, this.state.data.printScale)),
                                paddingBottom: getVW(Utility.convertToPrintScale(15, this.state.data.printScale)),
                                width: '100%'
                            }}>
                                <DKSignatureSection data={this.state.data} onSignatureSectionClicked={this.props.tableClicked} />
                            </div>
                        {ComponentManager.addVerticalSpace(12.5)}
                        </>
                    }
                    {((this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) || (this.state.data.showNotes && this.state.data.notes)) && this.getFooterSection()}
                </div>
                <DKEInvoiceSection
                    data={this.state.data}
                    position={eInvoiceSectionPosition.bottom}
                />
            </div>
        );
    }

    getShippingAddress() {
        return <>
            {this.getShipToAddressSection()}
            {ComponentManager.addVerticalSpace(12.5)}
        </>;
    }

    getBillingAddress() {
        return <>
            {this.getBillToAddressSection()}
            {ComponentManager.addVerticalSpace(12.5)}
        </>;
    }

    /////// Header Section ///////////
    getHeaderSection() {
        return (
            <div className="RowDiv" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <div className="ColumnDiv" style={{ width: '50%' }}>
                    {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) && ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(60, this.state.data.printScale), {position: 'relative'})}
                    <text className={"RowDiv docFontStyleBold" + getFontSizeClass()}
                        style={{
                            color: this.state.data.themeColor,
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xxxLarge }, this.state.data.printScale),
                        }}>
                        {getLocalisedText(this.state.data.documentType)}
                    </text>
                    <div className='ColumnDiv parent-width'>
                        {this.state.data.showCompanyName && this.getCompanyName()}
                        {this.state.data.showFrom && this.getCompanyAddress()}
                    </div>
                </div>
                <div className="ColumnDiv" style={{ width: '50%', alignItems: 'flex-end' }}>
                    <div className="ColumnDiv" style={{ width: '60%' }}>
                        {this.getDocumentDetailsSection()}
                    </div>
                </div>
            </div>
        )
    }

    getDocumentDetailsSection() {
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        return documentDetailList.map(element => {
            var value = ''
            if (element.key !== undefined) {
                value = this.state.data[element.key]
            }
            else if (element.customFieldValue !== undefined) {
                value = element.customFieldValue
            }
            return ComponentManager.getRowValueSection(
                element.label + " : ",
                value,
                undefined,
                undefined,
                undefined,
                undefined,
                this.state.data.printScale
            )
        });
    }

    getCompanyAddress() {
        return <div className={"RowDiv docFontStyle" + getFontSizeClass()}
                    style={{
                        overflow: 'hidden',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.from) }, this.state.data.printScale),
                        display: 'block'
                        // flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row'
                    }}
                    dangerouslySetInnerHTML={{__html: getLocalisedText(Utility.getCompanyAddress(this.state.data))}}
        ></div>;
    }

    getCompanyName() {
        return <div className={"RowDiv docFontStyleBold" + getFontSizeClass()}
            style={{
                    paddingBottom: getVW(5),
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }, this.state.data.printScale),
                    // flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row'
                }}>
            {getLocalisedText(Utility.getCompanyName(this.state.data))}
        </div>;
    }

    /////// Bill to address Section ///////////
    getBillToAddressSection() {
        return (
            <div className="RowDiv" style={{
                backgroundColor: '#F6F6F6',
                justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                alignItems: 'flex-start',
                paddingLeft: `${Utility.convertToPrintScale(Number(PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`,
                paddingRight: `${Utility.convertToPrintScale(Number(PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`,
                paddingTop: getVW(Utility.convertToPrintScale(15, this.state.data.printScale)),
                paddingBottom: getVW(Utility.convertToPrintScale(15, this.state.data.printScale)),
                // marginLeft: "-" + PAGE_PADDING,
                width: '100%'
            }}>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        width: Utility.getIsArabicLang() ? undefined : '10%',
                        textAlign: 'left',
                        paddingRight: Utility.getIsArabicLang() ? getVW(Utility.convertToPrintScale(10, this.state.data.printScale)) : 0,
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.billTo) }, this.state.data.printScale),
                    }}>
                        {getLocalisedText(this.state.data.billToTitle).toUpperCase()}</text>
                <div className="ColumnDiv"
                    style={{
                        width: Utility.getIsArabicLang() ? undefined : '50%'
                    }}>
                    <div className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        overflow: 'hidden',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.billTo) }, this.state.data.printScale),
                    }}
                        dangerouslySetInnerHTML={{__html: getLocalisedText(Utility.getClientBillToAddress(this.state.data))}}></div>
                </div>
            </div>
        )
    }

    /////// Ship to address Section ///////////
    getShipToAddressSection() {
        return (
            <div className="RowDiv" style={{
                backgroundColor: '#F6F6F6',
                justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                alignItems: 'flex-start',
                paddingLeft: `${Utility.convertToPrintScale(Number(PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`,
                paddingRight: `${Utility.convertToPrintScale(Number(PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`,
                paddingTop: getVW(Utility.convertToPrintScale(15, this.state.data.printScale)),
                paddingBottom: getVW(Utility.convertToPrintScale(15, this.state.data.printScale)),
                // marginLeft: "-" + PAGE_PADDING,
                width: '100%'
            }}>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        width: Utility.getIsArabicLang() ? undefined : '10%',
                        textAlign: 'left',
                        paddingRight: Utility.getIsArabicLang() ? getVW(Utility.convertToPrintScale(10, this.state.data.printScale)) : 0,
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.shipTo) }, this.state.data.printScale)
                    }}>
                        {getLocalisedText(this.state.data.shipToTitle).toUpperCase()}</text>
                <div className="ColumnDiv" style={{ width: Utility.getIsArabicLang() ? undefined : '50%' }}>
                    <div className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            overflow: 'hidden',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.shipTo) }, this.state.data.printScale)
                        }}
                        dangerouslySetInnerHTML={{__html: getLocalisedText(Utility.getClientShipToAddress(this.state.data))}}></div>
                </div>
            </div>
        )
    }



    /////// Table Section ///////////
    getTableSection() {
        return (
            <div className="RowDiv" style={{
                // backgroundColor: this.state.data.themeColor,
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                // height: LINE_ITEM_HEIGHT,
                paddingLeft: `${Utility.convertToPrintScale(Number(PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`,
                paddingRight: `${Utility.convertToPrintScale(Number(PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`,
                // marginLeft: "-" + PAGE_PADDING,
                width: '100%'
            }}>
                <div style={{ width: '100%' }}>
                    <div style={{ position: "relative", width: "100%", height: getVW(2), backgroundColor: this.state.data.themeColor }} />
                    <div>
                        {this.getEditableDocumentTable()}
                    </div>
                </div>
            </div>
        )
    }

    getProductTableSection() {
        return (
            <div className="RowDiv" style={{
                // backgroundColor: this.state.data.themeColor,
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                // height: LINE_ITEM_HEIGHT,
                paddingLeft: PAGE_PADDING,
                paddingRight: PAGE_PADDING,
                marginLeft: "-" + PAGE_PADDING,
                width: '100%'
            }}>
                <div style={{ width: '100%' }}>
                    <div style={{ position: "relative", width: "100%", height: getVW(2), backgroundColor: this.state.data.themeColor }} />
                    <div>
                        {this.getProductTable()}
                    </div>
                </div>
            </div>
        )
    }
    /////// Footer Section ///////////
    getFooterSection() {
        return (
            <div id={HTMLTag.TERMS_NOTE_PREFIX}  className="ColumnDiv" style={{ borderRadius: getVW(2), backgroundColor: '#F6F6F6', bottom: getVW(0), width: '35%', justifyContent: 'center', alignItems: 'flex-start', padding: getVW(10) }}>
                {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && ComponentManager.getDescriptionSection(
                    Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition')),
                    this.state.data.termsAndCondition,
                    "100%",
                    undefined,
                    undefined,
                    undefined,
                    getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                    undefined,
                    this.state.data.printScale
                )}
                {((this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && (this.state.data.showNotes && this.state.data.notes)) && ComponentManager.addVerticalSpace(Utility.convertToPrintScale(20, this.state.data.printScale))}
                {(this.state.data.showNotes && this.state.data.notes) && ComponentManager.getDescriptionSection(
                    Utility.getNotesTitle(getIsJournalEntry(this.state.data.type) ? getLocalisedText('memo') : getLocalisedText('notes')),
                    this.state.data.notes,
                    "100%",
                    undefined,
                    undefined,
                    undefined,
                    getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                    undefined,
                    this.state.data.printScale
                )}
            </div>
        )
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getProductTable() {
        if(Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKProductTable(
                Utility.getTableColumnForAccountProduct(),
                this.getTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerTextColor = "white";
        style.footerTextColor = 'white';
        style.headerBackgroundColor = this.state.data.themeColor;
        style.headerBorderColor = this.state.data.themeColor;
        style.itemBorderColor = "transparent";
        style.footerBackgroundColor = "black";
        style.footerBorderColor = "transparent";
        return style;
    }

    getCustomFieldTableStyle() {
        let style = {}
        return style;
    }

    getPlaceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getPlaceOfSupply()}

            </div>
        )
    }
    getPlaceOfSupply() {
        // return <div className='RowDiv' style={{ width: '70%' }}>
        //     <div className='ColumnDiv' style={{
        //         width: '40%'
        //     }}>
        //         {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
        //             getLocalisedText('place_of_supply'),
        //             this.state.data.placeOfSupply,
        //             "100%",
        //             this.state.data.themeColor,
        //             false,
        //             '#000000',
        //             getVW(6),
        //             true
        //         )}
        //     </div>

        // </div>;

        return (
            <div className="RowDiv" style={{
                backgroundColor: '#F6F6F6',
                justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                alignItems: 'flex-start',
                paddingLeft: PAGE_PADDING,
                paddingRight: PAGE_PADDING,
                paddingTop: getVW(15),
                paddingBottom: getVW(15),
                marginLeft: "-" + PAGE_PADDING,
                width: '100%'
            }}>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        width: Utility.getIsArabicLang() ? undefined : '20%',
                        textAlign: 'left',
                        paddingRight: Utility.getIsArabicLang() ? getVW(10) : 0,
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: true }, this.state.data.printScale)
                    }}>
                        {getLocalisedText('place_of_supply').toUpperCase()}</text>
                <div className="ColumnDiv" style={{ width: Utility.getIsArabicLang() ? undefined : '50%' }}>
                    <div className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            overflow: 'hidden',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: true }, this.state.data.printScale)
                        }}
                        dangerouslySetInnerHTML={{__html: this.state.data.placeOfSupply}}></div>
                </div>
            </div>
        )

    }

    getSourceOfSupply() {
        return (
            <div>
                {Utility.getIsShowSourceOfSupply(this.state.data) &&
                    <>
                    <div className="RowDiv" style={{
                        backgroundColor: '#F6F6F6',
                        justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                        alignItems: 'flex-start',
                        paddingLeft: PAGE_PADDING,
                        paddingRight: PAGE_PADDING,
                        paddingTop: getVW(15),
                        paddingBottom: getVW(15),
                        marginLeft: "-" + PAGE_PADDING,
                        width: '100%'
                    }}>
                        <text className={"docFontStyleBold" + getFontSizeClass()}
                            style={{
                                width: Utility.getIsArabicLang() ? undefined : '23%',
                                textAlign: 'left',
                                paddingRight: Utility.getIsArabicLang() ? getVW(10) : 0,
                                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: true }, this.state.data.printScale)
                            }}>
                            {getLocalisedText('source_of_supply').toUpperCase()}</text>
                        <div className="ColumnDiv" style={{ width: Utility.getIsArabicLang() ? undefined : '50%' }}>
                            <div className={"docFontStyle" + getFontSizeClass()}
                                style={{
                                    overflow: 'hidden',
                                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: true }, this.state.data.printScale)
                                }}
                                dangerouslySetInnerHTML={{ __html: this.state.data.sourceOfSupply }}></div>
                        </div>
                    </div>
                    </>
                }
                {Utility.getIsShowDestinationOfSupply(this.state.data) &&
                    <>
                    <div className="RowDiv" style={{
                        backgroundColor: '#F6F6F6',
                        justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                        alignItems: 'flex-start',
                        paddingLeft: PAGE_PADDING,
                        paddingRight: PAGE_PADDING,
                        paddingTop: getVW(10),
                        paddingBottom: getVW(15),
                        marginLeft: "-" + PAGE_PADDING,
                        width: '100%'
                    }}>
                        <text className={"docFontStyleBold" + getFontSizeClass()}
                            style={{
                                width: Utility.getIsArabicLang() ? undefined : '23%',
                                textAlign: 'left',
                                paddingRight: Utility.getIsArabicLang() ? getVW(10) : 0,
                                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: true }, this.state.data.printScale)
                            }}>
                            {getLocalisedText('destination_of_supply').toUpperCase()}</text>
                        <div className="ColumnDiv" style={{ width: Utility.getIsArabicLang() ? undefined : '50%' }}>
                            <div className={"docFontStyle" + getFontSizeClass()}
                                style={{
                                    overflow: 'hidden',
                                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: true }, this.state.data.printScale)
                                }}
                                dangerouslySetInnerHTML={{ __html: this.state.data.destinationOfSupply }}></div>
                        </div>
                    </div>
                    </>
                }
            </div>
        )
    }
}
