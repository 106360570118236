import React, { Component } from 'react';
import './DocPreview.css';
import DocumentManager from './Manager/DocumentManager';
import PdfUtility from './Utilities/PdfUtility';
import Utility, {
  getFontSize,
  getIsBill,
  getIsInvoice,
  getIsOrder,
  getIsPayslip,
  getIsCheque,
  getIsQuotation,
  getPageHeight,
  getPageWidth,
  getVW,
  getIsPayrollCheck,
  getIsSalesOrder,
  getIsEwayBillDetails,
  getIsEwayBillSummary,
  getIsWorkOrder,
  getIsJobCard,
  getIsPurchaseRequisition,
  getIsQcTemplate
} from './Utilities/Utility';
import AppManager from './Manager/AppManager';
import ApiManager from './API/ApiManager';
import ComponentManager from './Manager/ComponentManager';
import TemplateManager from './Manager/TemplateManager';
import { ComponentType } from './Components/DocumentDesigner/DocumentDesignerScreen/model/Enum';
import { getLocalisedText } from './Translate/LanguageManager';
import TemplateSettingsManager from './Manager/TemplateSettingsManager';
import { TemplateRepository } from './API/TemplateRepository';
import { ShareLinkRepository } from './API/ShareLinkRepository';
import { AlertType } from './Components/AlertComponent/Alert';
import { showAlert } from 'deskera-ui-library';
import { PrintInfoRepository } from './API/PrintInfoRepository';
import MultiPageManager from './Manager/MulitpageManager';
import HeaderFooterManager from './Manager/HeaderFooterManager';
import NewDataParser from './Helper/NewDataParser';
import Cookies from 'universal-cookie';
import { HTMLTag } from './Utilities/HTMLTag';
import MultiPageUtility from './Utilities/MultiPageUtility';
import WatermarkManager from './Manager/WatermarkManager';
import { isEqual } from 'lodash';
import {
  DESIGNER_TEMPLATE,
  DESIGNER_THERMAL_TEMPLATE,
  FONT_SIZE,
  NEGATIVE_PAGE_PADDING,
  PAGE_PADDING
} from '../../constants/Constant';
import DocumentDesignerManager from './Manager/DocumentDesignerManager';
import { TableColumnPopupType } from './Components/Popup/TableColumnPopup';
import DocumentLabelManager from './Manager/DocumentLabelManager';

export const ExportType = {
  PRINT: 'Print',
  EMAIL: 'Email',
  DOWNLOAD_PDF: 'Download Pdf',
  VIEW_PDF: 'View Pdf',
  PREVIEW_PDF: 'Preview Pdf'
};

export default class DocPreview extends Component {
  templateRepo = new TemplateRepository();

  fromERPData = null;
  settingsData = null;
  documentCode = null;
  documentType = null;
  isLoaded = false;
  view = '';

  constructor(props) {
    //initialize initial/default Template Settings JSON
    TemplateSettingsManager.initializeDefaultTemplate();

    super(props);

    this.state = {
      documentData: null,
      documentDataList: null,
      templateId: null,
      templateIdFromParam: null,
      templateType: null,
      canShowPage: false,
      isTappedOutside: false,
      isReadOnlyMode: false,
      isFromERP: false,
      sharableURL: '',
      needTemplateSelectionPopup: true,
      needDeskeraSuitesPopup: true,
      needSignInPopup: false,
      showMailComposer: false,
      pdfURL: '',
      userLoggedIn: false,
      generatingFile: false,
      isSavingTemplate: false,
      isDownloadingPDF: false,
      isViewPdf: false,
      isPrintingPDF: false,
      isEmailPDF: false,
      isPreviewingPdf: false,
      showEditorView: false,
      showThemeSelectionPopUp: false,
      isAddComponentPopupRequired: false,
      isViewComponentPopupRequired: false,
      componentList:
        TemplateSettingsManager.defaultTemplateSettings.documentInfo
          .componentList,
      selectedComponent: undefined,
      globalSetting: {
        zoomRatio: 1,
        showGrid: false
      },
      showGrid: false,
      isDragging: false,
      id: TemplateSettingsManager.getComponentMaxId(),
      isImagePopupRequired: false,
      doubleClickComponent: undefined,
      isTableColumnPopupRequired: false,
      isAfterSaveTemplatePopupRequired: false,
      isSaveTemplatePopupRequired: false,
      isDeleteTemplateRequired: false,
      isEmptyCustomFieldPopupRequired: false,
      deleteTemplateItem: undefined,
      tableColumnData:
        TemplateSettingsManager.defaultTemplateSettings.tableInfo,
      currentLanguage:
        TemplateSettingsManager.defaultTemplateSettings.languageInfo,
      isGeneratingShareableLink: false,
      bypassUserLoginCheck: false,
      isPaperFormatPopupRequired: false,
      exportType: '',
      isCustomFieldPopupRequired: false,
      isPreviewMode: false,
      printInfo: undefined,
      isSharingMode: false,
      isHeaderFooterPopupRequired: false,
      isAddressFormatRequired: false,
      addressType: undefined,
      isPayslipFieldPopupRequired: false,
      isPayslipTablePopupRequired: false,
      documentUpdateCount: 0,
      isAdditionalTablePopupRequired: false,
      isWatermarkRequiredPopupRequired: false,
      isDocumentPopupRequired: false,
      customFieldPosition: undefined,
      isTopSectionAboveTablePopupRequired: false,
      wmark: this.props?.wmark,
      isTableColumnAlignmentPopupRequired: false,
      isTableColumnVerticalAlignmentPopupRequired: false,
      isPayslipEarningsPopupRequired: false,
      isDocumentLabelPopupRequired: false
    };
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.setUpDataForPreview();
  }
  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.selectedTemplate, prevProps.selectedTemplate)) {
      this.onTemplateChange();
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps?.wmark) {
      this.setState(
        {
          wmark: nextProps.wmark
        },
        this.forceUpdate()
      );
    }
  }
  onTemplateChange = () => {
    TemplateSettingsManager.initializeDefaultTemplate();
    TemplateSettingsManager.setPageFormat('A4');
    TemplateSettingsManager.updatePageFormat('A4');

    if (this.props?.selectedTemplate?.id === DESIGNER_THERMAL_TEMPLATE.id) {
      if (
        this.props?.selectedTemplate?.pageFormat === 'three_five' ||
        this.props?.selectedTemplate?.pageFormat === 'four_six'
      ) {
        TemplateSettingsManager.setPageFormat(
          this.props?.selectedTemplate?.pageFormat
        );
        TemplateSettingsManager.updatePageFormat(
          this.props?.selectedTemplate?.pageFormat
        );
        TemplateSettingsManager.setPrintInfo(undefined);
        TemplateSettingsManager.updateRemoteTemplateUID(
          DESIGNER_THERMAL_TEMPLATE.id
        );
        ApiManager.getDocumentDetails(this.props?.templatePrintInfo?.code, {
          documentResponseFailure: () => {},
          documentResponseSuccess: (dataFromAPI) => {
            this.templateSettingsResponse(null, dataFromAPI.payload);
          }
        });
      }
    } else {
      const printInfo = {
        tId: this.props?.selectedTemplate?.id,
        id: this.props?.templatePrintInfo?.code,
        wmark: this.props?.wmark
      };

      TemplateSettingsManager.setPageFormat(
        this.props?.selectedTemplate?.pageFormat
      );
      TemplateSettingsManager.updatePageFormat(
        this.props?.selectedTemplate?.pageFormat
      );
      if (this.props?.selectedTemplate?.id !== 0) {
        TemplateSettingsManager.updateRemoteTemplateUID(
          this.props?.selectedTemplate?.id
        );
        this.getDocumentDetails(
          this.props?.templatePrintInfo?.code,
          false,
          false,
          printInfo
        );
      } else {
        TemplateSettingsManager.setPrintInfo(printInfo);
        TemplateSettingsManager.updateRemoteTemplateUID(undefined);
        ApiManager.getDocumentDetails(this.props?.templatePrintInfo?.code, {
          documentResponseFailure: () => {},
          documentResponseSuccess: (dataFromAPI) => {
            this.templateSettingsResponse(null, dataFromAPI.payload);
          }
        });
      }
    }
    this.setState({});
  };
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    TemplateSettingsManager.initializeDefaultTemplate();
  }
  updateDimensions = () => {
    if (this.state.isSharingMode) {
      if (Utility.getIsMobileView()) {
        var printInfo = { id: '1' };
        this.setState({ printInfo: printInfo });
        TemplateSettingsManager.setPrintInfo(printInfo);
      } else {
        this.setState({ printInfo: undefined });
        TemplateSettingsManager.setPrintInfo(undefined);
      }
    } else {
      this.setState({});
    }
  };
  render() {
    return (
      <div
        className="DocPreview border-m shadow-s pagebreak"
        id={HTMLTag.MAIN_HOLDER_ID}
      >
        {this.getDocumentTemplate()}
      </div>
    );
  }
  getDocumentTemplate() {
    return (
      <div
        id={HTMLTag.MAIN_TEMPLATE}
        style={{
          overflowY: 'scroll',
          justifyContent: 'center',
          // backgroundColor: 'rgb(240, 240, 240)',
          boxSizing: 'border-box'
        }}
        onClick={() => {
          this.setState({ isTappedOutside: true });
          setTimeout(() => {
            this.setState({
              isTappedOutside: false,
              needTemplateSelectionPopup: true,
              needDeskeraSuitesPopup: true
            });
          }, 100);
        }}
      >
        <iframe
          title=" "
          id={HTMLTag.PRINT_HTML}
          style={{
            height: 0,
            width: 0,
            position: 'absolute',
            opacity: 0,
            backgroundColor: 'white'
          }}
        />
        {this.renderDocumentData()}
      </div>
    );
  }
  renderDocumentData() {
    if (TemplateSettingsManager.getIsMultipleDocument()) {
      if (
        this.state.documentDataList !== undefined &&
        this.state.documentDataList !== null
      ) {
        if (this.state.documentDataList.length > 0) {
          return this.state.documentDataList.map((element) => {
            return this.getOnlyTemplate(element);
          });
        }
      }
    }

    return this.getOnlyTemplate();
  }
  getOnlyTemplate(documentData) {
    var data = this.state.documentData;

    if (documentData !== undefined) {
      data = documentData;
    }

    if (data && this.props?.printScale) {
      data.printScale = this.props.printScale;
    }

    return (
      <div
        className={this.state.printInfo === undefined ? 'PageHolder' : ''}
        id={HTMLTag.TEMPLATE}
        style={{
          position: 'relative',
          borderRadius: 4,
          backgroundColor: 'white',
          padding: getVW(
            `${Utility.convertToPrintScale(2.8, this.props?.printScale)}vw`,
            true
          ),
          overflow: 'hidden',
          marginBottom: this.getMarginBottom(),
          minHeight:
            this.state.printInfo === undefined
              ? getPageHeight(this.props.printScale)
              : undefined
        }}
      >
        <div>{this.renderDocumentDesignerManager()}</div>
        <div
          id={HTMLTag.ONLY_TEMPLATE}
          style={{
            width: '100%',
            minHeight: getPageHeight(this.props.printScale)
          }}
          onClick={() => {
            this.setState({
              selectedComponent: undefined,
              doubleClickComponent: undefined,
              isAddComponentPopupRequired: false,
              isViewComponentPopupRequired: false
            });
          }}
        >
          <HeaderFooterManager
            mainContent={this.getWatermarkSection(data)}
            isReadOnlyMode={this.state.printInfo !== undefined}
            documentData={data}
            onHeaderFooterClick={(type) => {
              this.setState({
                isHeaderFooterPopupRequired: true
              });
            }}
          />
        </div>
      </div>
    );
  }
  getWatermarkSection(documentData) {
    return (
      <WatermarkManager
        mainContent={this.getDocumentLabelSection(documentData)}
        onWatermarkClick={(type) => {
          this.setState({
            isWatermarkRequiredPopupRequired: true
          });
        }}
      />
    );
  }
  getDocumentLabelSection(documentData) {
    return (
      <DocumentLabelManager
        mainContent={this.getDocumentOrLabelTemplate(documentData)}
        documentData={documentData}
        onWatermarkClick={(type) => {
          this.setState({
            isDocumentLabelPopupRequired: true
          });
        }}
      />
    );
  }
  getPaddingTop() {
    if (this.state.printInfo !== undefined) {
      return undefined;
    } else {
      return getVW(
        `${Utility.convertToPrintScale(2.8, this.props?.printScale)}vw`,
        true
      );
    }
  }
  getPaddingBottom() {
    if (this.state.printInfo !== undefined) {
      return MultiPageManager.getPaddingBottom(
        this.isLoaded,
        this.state.printInfo
      );
      // if (this.state.printInfo.tId !== undefined) {
      //  return MultiPageManager.getPaddingBottom(this.isLoaded, this.state.printInfo)
      // }
      // else {
      //     return getVW(PAGE_PADDING, true)
      // }
    } else {
      return getVW(
        `${Utility.convertToPrintScale(2.8, this.props?.printScale)}vw`,
        true
      );
    }
  }
  getMarginBottom() {
    if (this.state.printInfo !== undefined) {
      if (this.state.printInfo.tId !== undefined) {
        return 0;
      } else {
        var isMultiplePage = AppManager.getIsMultiplePage();
        if (isMultiplePage !== undefined && isMultiplePage !== null) {
          return 0;
        }
        return getVW(NEGATIVE_PAGE_PADDING, true);
      }
    } else {
      return 0;
    }
  }
  getDocumentOrLabelTemplate(documentData) {
    let type = documentData ? documentData.type : 'invoice';
    if (TemplateManager.getIsLabelType(type)) {
      return DocumentManager.getLabelTemplateWith(
        this.state.templateId,
        documentData,
        this.state.tableColumnData,
        () => {
          this.tableClicked();
        },
        () => {
          this.stockTableClicked();
        },
        (data) => {
          this.setState({ documentData: data });
        },
        () => {
          this.paymentTableClicked();
        },
        (position) => {
          this.setState({
            isCustomFieldPopupRequired: true,
            customFieldPosition: position
          });
        }
      );
    } else if (getIsPayslip(type)) {
      return DocumentManager.getPayslipTemplateWith(
        this.state.templateId,
        documentData,
        this.state.tableColumnData,
        () => {
          this.tableClicked();
        },
        () => {
          this.stockTableClicked();
        },
        (data) => {
          this.setState({ documentData: data });
        },
        () => {
          this.paymentTableClicked();
        },
        (position) => {
          this.setState({
            isCustomFieldPopupRequired: true,
            customFieldPosition: position
          });
        }
      );
    } else if (getIsCheque(type)) {
      return DocumentManager.getChequeTemplateWith(
        this.state.templateId,
        documentData
      );
    } else if (Utility.getIsReceiptPaperSize()) {
      return DocumentManager.getReceiptTemplateWith(
        this.state.templateId,
        documentData,
        this.state.tableColumnData,
        () => {
          this.tableClicked();
        },
        () => {
          this.stockTableClicked();
        },
        (data) => {
          this.setState({ documentData: data });
        },
        () => {
          this.paymentTableClicked();
        },
        (data) => {
          this.setState({ isAdditionalTablePopupRequired: true });
        },
        (position) => {
          this.setState({
            isCustomFieldPopupRequired: true,
            customFieldPosition: position
          });
        }
      );
    } else if (getIsPayrollCheck(type)) {
      return DocumentManager.getPayrollCheckTemplateWith(
        this.state.templateId,
        documentData,
        this.state.tableColumnData,
        () => {
          this.tableClicked();
        },
        () => {
          this.stockTableClicked();
        },
        (data) => {
          this.setState({ documentData: data });
        },
        () => {
          this.paymentTableClicked();
        },
        (position) => {
          this.setState({
            isCustomFieldPopupRequired: true,
            customFieldPosition: position
          });
        }
      );
    } else if (getIsEwayBillDetails(type)) {
      return DocumentManager.getEWayBillDetailsTemplateWith(
        this.state.templateId,
        documentData,
        this.state.tableColumnData,
        () => {
          this.tableClicked();
        },
        () => {
          this.stockTableClicked();
        },
        (data) => {
          this.setState({ documentData: data });
        },
        () => {
          this.paymentTableClicked();
        },
        (position) => {
          this.setState({
            isCustomFieldPopupRequired: true,
            customFieldPosition: position
          });
        }
      );
    } else if (getIsEwayBillSummary(type)) {
      return DocumentManager.getEWayBillSummaryTemplateWith(
        this.state.templateId,
        documentData,
        this.state.tableColumnData,
        () => {
          this.tableClicked();
        },
        () => {
          this.stockTableClicked();
        },
        (data) => {
          this.setState({ documentData: data });
        },
        () => {
          this.paymentTableClicked();
        },
        (position) => {
          this.setState({
            isCustomFieldPopupRequired: true,
            customFieldPosition: position
          });
        }
      );
    } else if (getIsWorkOrder(type)) {
      return DocumentManager.getWorkOrderTemplateWith(
        this.state.templateId,
        documentData,
        this.state.tableColumnData,
        () => {
          this.tableClicked();
        },
        () => {
          this.stockTableClicked();
        },
        (data) => {
          this.setState({ documentData: data });
        },
        () => {
          this.paymentTableClicked();
        },
        (position) => {
          this.setState({
            isCustomFieldPopupRequired: true,
            customFieldPosition: position
          });
        }
      );
    } else if (getIsJobCard(type)) {
      return DocumentManager.getJobCardTemplateWith(
        this.state.templateId,
        documentData,
        this.state.tableColumnData,
        () => {
          this.tableClicked();
        },
        () => {
          this.stockTableClicked();
        },
        (data) => {
          this.setState({ documentData: data });
        },
        () => {
          this.paymentTableClicked();
        },
        (position) => {
          this.setState({
            isCustomFieldPopupRequired: true,
            customFieldPosition: position
          });
        }
      );
    } else if (getIsPurchaseRequisition(type)) {
      return DocumentManager.getPurchaseRequisitionTemplateWith(
        this.state.templateId,
        documentData,
        this.state.tableColumnData,
        () => {
          this.tableClicked();
        },
        () => {
          this.stockTableClicked();
        },
        (data) => {
          this.setState({ documentData: data });
        },
        () => {
          this.paymentTableClicked();
        },
        (position) => {
          this.setState({
            isCustomFieldPopupRequired: true,
            customFieldPosition: position
          });
        }
      );
    } else if (getIsQcTemplate(type)) {
      return DocumentManager.getQCTemplateWith(
        this.state.templateId,
        documentData,
        this.state.tableColumnData,
        () => {
          this.tableClicked();
        },
        () => {
          this.stockTableClicked();
        },
        (data) => {
          this.setState({ documentData: data });
        },
        () => {
          this.paymentTableClicked();
        },
        (position) => {
          this.setState({
            isCustomFieldPopupRequired: true,
            customFieldPosition: position
          });
        }
      );
    }
    return DocumentManager.getTemplateWith(
      this.state.templateId,
      documentData,
      this.state.tableColumnData,
      (tableType) => {
        this.tableClicked(tableType);
      },
      () => {
        this.stockTableClicked();
      },
      (data) => {
        this.setState({ documentData: data });
      },
      () => {
        this.paymentTableClicked();
      },
      (data) => {
        this.setState({ isAdditionalTablePopupRequired: true });
      },
      (position) => {
        this.setState({
          isCustomFieldPopupRequired: true,
          customFieldPosition: position
        });
      }
    );
  }
  renderDocumentDesignerManager() {
    return (
      <DocumentDesignerManager
        printScale={this.props.printScale}
        componentList={this.state.componentList}
        selectedComponent={this.state.selectedComponent}
        globalSetting={this.state.globalSetting}
        showGrid={this.state.showGrid}
        isDragging={this.state.isDragging}
        id={this.state.id}
        doubleClickComponent={this.state.doubleClickComponent}
        onDoubleClickComponent={(selected) => {
          this.setState(
            {
              doubleClickComponent: selected
            },
            () => {
              this.onDoubleClickComponent();
            }
          );
        }}
        onUpdateComponent={(selected, componentList) => {
          this.setState(
            {
              selectedComponent: selected,
              componentList: componentList
            },
            () => {
              TemplateSettingsManager.updateComponentList(
                this.state.componentList
              );
            }
          );
        }}
      />
    );
  }
  getLoadingMsg(message = null) {
    return (
      <div
        className="ColumnDiv"
        style={{
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          backgroundColor: 'rgb(240, 240, 240)',
          top: 0,
          left: 0,
          zIndex: 9999
        }}
      >
        <text
          style={{ fontSize: getFontSize(FONT_SIZE.medium), fontWeight: '600' }}
        >
          {getLocalisedText('loading_the_page')}
        </text>
        {ComponentManager.addVerticalSpace(6)}
        <text
          style={{
            fontSize: getFontSize(FONT_SIZE.regular),
            color: 'rgb(100, 100, 100)'
          }}
        >
          {getLocalisedText(message ? message : 'please_wait')}
        </text>
      </div>
    );
  }
  getIsContainPrintParamFromUrl() {
    let parts = window.location.search.substr(1).split('&'); //window.location.search
    let $_GET = {};
    for (let i = 0; i < parts.length; i++) {
      let temp = parts[i].split('=');
      $_GET[decodeURIComponent(temp[0])] = decodeURIComponent(temp[1]);
    }
    let param_pid = $_GET['pid'];
    if (param_pid !== undefined && param_pid !== null && param_pid !== '') {
      return true;
    }

    return false;
  }
  setUpDataForPreview = () => {
    let isSharingMode = this.props?.isSharingMode;
    let isShareMode = this.props?.isShareMode;
    let isDownloadMode = this.props?.isDownloadMode;
    let isViewMode = this.props?.isViewMode;
    let isPrintMode = this.props?.isPrintMode;
    let isEmailMode = this.props?.isEmailMode;
    let isPreviewMode = this.props?.isPreviewMode;
    let param_paperSize = this.props?.paperSize;
    let param_isMultiplePage = this.props?.isMultiplePage;

    AppManager.setIsMultiplePage(param_isMultiplePage);
    if (this.props?.selectedTemplate?.id === DESIGNER_THERMAL_TEMPLATE.id) {
      if (
        this.props?.selectedTemplate?.pageFormat === 'three_five' ||
        this.props?.selectedTemplate?.pageFormat === 'four_six'
      ) {
        TemplateSettingsManager.setPageFormat(
          this.props?.selectedTemplate?.pageFormat
        );
      }
    }

    this.setState(
      {
        isFromERP: true,
        isReadOnlyMode: true,
        isDownloadingPDF: isDownloadMode,
        isViewPdf: true,
        isPrintingPDF: isPrintMode,
        isEmailPDF: isEmailMode,
        generatingFile: isEmailMode,
        bypassUserLoginCheck:
          isDownloadMode || isPrintMode || isEmailMode || isViewMode,
        isPreviewMode: true,
        printInfo: undefined,
        isSharingMode: isSharingMode
      },
      () => {
        this.onTemplateChange();
        AppManager.setDocumentId(this.props?.templatePrintInfo?.code);
      }
    );
  };

  setData(templateId, templateType, documentData, documentDataList) {
    this.setState(
      {
        templateId: parseInt(templateId),
        templateType: templateType,
        documentData: documentData,
        tableColumnData: documentData.tableColumnPopup,
        componentList: documentData.componentList,
        currentLanguage: documentData.languageInfo,
        canShowPage: true,
        id: TemplateSettingsManager.getComponentMaxId(),
        documentDataList: documentDataList
      },
      () => {
        DocumentManager.setCurrentData(this.state.documentData);
        // Add document type specific table info
        this.updateTableInfo(templateType);
      }
    );
  }

  getTemplateData(type, id) {
    return DocumentManager.getTemplateData(type, id);
  }

  showSignInPopup = () => {
    this.setState({
      sharableURL: Utility.getSharableURL(this.state.documentData),
      needSignInPopup: true
    });
  };

  hideSignInPopup = () => {
    this.setState({
      needSignInPopup: false
    });
  };

  onSignInPopupClosed = () => {
    // ApiManager.checkIfUserLoggedIn(this)
    this.hideSignInPopup();
  };
  onDataChanged = (data) => {
    if (data.event === undefined) {
      this.resetDefaultData(data);
    } else if (data.event === 'onPaperSizeChanged') {
      data.event = undefined;
      TemplateSettingsManager.updateTablePopupInfo(data.tableColumnPopup);
      this.setState(
        {
          tableColumnData: data.tableColumnPopup
        },
        () => {
          this.resetDefaultData(data);
        }
      );
    }
  };
  onPrintOrExportPdf(type) {
    this.setState(
      {
        selectedComponent: undefined,
        doubleClickComponent: undefined
      },
      () => {
        this.checkIfUserLoggedInBeforeExport(type);
      }
    );
  }
  onDoubleClickComponent() {
    if (
      this.state.doubleClickComponent === undefined ||
      this.state.doubleClickComponent === null
    ) {
      return;
    }
    if (this.state.doubleClickComponent.type === ComponentType.DKImage) {
      this.setState({
        isImagePopupRequired: !this.state.isImagePopupRequired
      });
    } else if (this.state.doubleClickComponent.type === ComponentType.DKLabel) {
    }
    return;
  }
  onUpdateComponent(component) {
    let newComponentList = this.state.componentList;
    let list = this.state.componentList.filter((x) => x.id === component.id);
    if (list.length > 0) {
      let item = list[0];
      let index = this.state.componentList.indexOf(item);
      newComponentList[index] = component;
      this.setState(
        {
          componentList: newComponentList
        },
        () => {
          TemplateSettingsManager.updateComponentList(this.state.componentList);
        }
      );
    }
  }

  onRemoveComponent(component, view) {
    this.view = view;
    let newComponentList = this.state.componentList.filter(
      (x) => x.id !== component.id
    );
    var isViewComponentPopupRequired = this.state.isViewComponentPopupRequired;
    if (view === 'ViewComponentListPopup') {
      isViewComponentPopupRequired = newComponentList.length > 0;
    }
    this.setState(
      {
        componentList: newComponentList,
        isViewComponentPopupRequired: isViewComponentPopupRequired,
        selectedComponent: undefined,
        doubleClickComponent: undefined
      },
      () => {
        TemplateSettingsManager.updateComponentList(this.state.componentList);
      }
    );
  }

  onDuplicateComponent(component) {
    let newComponentList = this.state.componentList;
    let newId = this.state.id + 1;
    let newComponent = {};
    Object.assign(newComponent, component);
    newComponent.id = newId;
    newComponentList.push(newComponent);
    this.setState({
      componentList: newComponentList,
      selectedComponent: newComponent,
      doubleClickComponent: undefined,
      id: newId
    });
  }
  onTableColumnPopupClose = () => {
    this.setState({
      isTableColumnPopupRequired: false
    });
  };
  onTableColumnPopupSave = (data) => {
    let dataToUpdate = this.state.documentData;
    dataToUpdate['tableColumnPopup'] = data;
    TemplateSettingsManager.updateTablePopupInfo(data);
    this.setState(
      {
        tableColumnData: data,
        isTableColumnPopupRequired: false
      },
      () => {
        this.onDataChanged(dataToUpdate);
      }
    );
  };
  getDocumentDetails(result, isShareMode, isSharingMode, printInfo) {
    if (isShareMode) {
      showAlert(
        AlertType.user_input,
        getLocalisedText('document_enter_password_title'),
        getLocalisedText('document_enter_password_message'),
        [
          {
            title: getLocalisedText('proceed'),
            className: 'bg-blue text-white',
            onClick: (passwordReceived) => {
              this.fetchDocumentDetails(result, passwordReceived);
            }
          }
        ]
      );
    } else if (isSharingMode) {
      this.fetchDocumentDetails(result, null);
    } else if (printInfo !== undefined) {
      TemplateSettingsManager.setPrintInfo(printInfo);
      this.fetchPrintInfoDetails(printInfo);
    } else {
      ApiManager.getDocumentDetails(result, this);
    }
  }
  fetchDocumentDetails(result, passwordReceived) {
    let id = result.split('?')[0];
    let shareLinkRepo = new ShareLinkRepository();
    shareLinkRepo.getPublicDocumentDetails(
      id,
      passwordReceived,
      (dataFromAPI) => {
        let payload_decoded = Utility.decodeStringForTemplate(
          dataFromAPI.payload
        );
        this.templateSettingsResponse(
          payload_decoded.settings,
          payload_decoded,
          true,
          true
        );
        NewDataParser.updateMissingTableColumn(payload_decoded);
        this.updateDimensions();
      },
      (error) => {
        if (error.status === 404 && error.message !== null) {
          showAlert(
            AlertType.standard,
            getLocalisedText('document_not_found_title'),
            error.message,
            []
          );
        }
        if (error.status === 403 && error.message !== null) {
          showAlert(
            AlertType.user_input,
            getLocalisedText('document_incorrect_password_title'),
            getLocalisedText('document_incorrect_password_message'),
            [
              {
                title: getLocalisedText('proceed'),
                className: 'bg-blue text-white',
                onClick: (passwordReceived) => {
                  this.fetchDocumentDetails(result, passwordReceived);
                }
              }
            ]
          );
        } else {
          showAlert(
            AlertType.standard,
            getLocalisedText('document_expired_title'),
            getLocalisedText('document_expired_message'),
            []
          );
        }
      }
    );
  }
  fetchPrintInfoDetails(printInfo) {
    let printInfoRepo = new PrintInfoRepository();
    printInfoRepo.getPrintInfoDetails(
      printInfo,
      (response) => {
        if (response.pageFormat !== undefined && response.pageFormat !== null) {
          if (response.pageFormat !== '') {
            TemplateSettingsManager.setPageFormat(response.pageFormat);
          }
        }
        var payload = TemplateSettingsManager.getIsMultipleDocument()
          ? response.printInformations
          : response.printInformation.payload;
        this.templateSettingsResponse(
          JSON.parse(response.templateData),
          payload,
          true,
          false
        );
      },
      (error) => {
        if (error.status === 404 && error.message !== null) {
          showAlert(
            AlertType.standard,
            getLocalisedText('document_not_found_title'),
            error.message,
            []
          );
        } else {
          showAlert(
            AlertType.standard,
            getLocalisedText('document_expired_title'),
            getLocalisedText('document_expired_message'),
            []
          );
        }
      }
    );
  }
  checkIfCookieIsPresentAndUpdate(domain) {
    let cookie = new Cookies();
    let isCookiePresent = false;
    let initialValue = cookie.get('documentUpdateCount');
    if (initialValue !== '' && initialValue !== undefined) {
      cookie.set('documentUpdateCount', this.state.documentUpdateCount, {
        path: '/',
        domain: domain
      });
      isCookiePresent = true;
      this.setState({
        documentUpdateCount: this.state.documentUpdateCount + 1
      });
    }
    return isCookiePresent;
  }
  documentResponseSuccess(dataFromAPI) {
    if (dataFromAPI !== undefined && dataFromAPI !== null) {
      this.fromERPData = dataFromAPI.payload;
      this.documentCode = dataFromAPI.documentCode;

      this.documentType = dataFromAPI.payload.type;
      if (
        dataFromAPI.documentType !== undefined &&
        dataFromAPI.documentType !== null
      ) {
        if (getIsPayslip(dataFromAPI.documentType.toLowerCase())) {
          this.documentType = dataFromAPI.documentType.toLowerCase();
        }
      }
    }

    ApiManager.getTemplateSettingsFromRemote(
      TemplateSettingsManager.getTemplateNameType(
        this.documentType.toLowerCase()
      ),
      this,
      this.fromERPData
    );
  }
  documentResponseFailure() {}
  templateSettingsResponse(
    settingsData,
    dataFromAPI,
    isSharing = false,
    isComputedData = false
  ) {
    if (
      settingsData &&
      ![DESIGNER_THERMAL_TEMPLATE.id, DESIGNER_TEMPLATE.id].includes(
        this.props?.selectedTemplate?.id
      )
    ) {
      let templateDataFromRemote;
      if (isSharing === true) {
        templateDataFromRemote = settingsData;
        if (this.state.printInfo !== undefined && settingsData !== undefined) {
          if (this.state.printInfo.tId !== undefined) {
            TemplateSettingsManager.updateRemoteTemplateUID(
              this.state.printInfo.tId
            );
          }
        }
      } else {
        TemplateSettingsManager.updateRemoteTemplateUID(settingsData.id);
        TemplateSettingsManager.setPageFormat(settingsData.pageFormat);
        TemplateSettingsManager.setCustomTemplateName(
          settingsData.templateName
        );
        templateDataFromRemote = JSON.parse(settingsData.templateData);

        let templateID =
          this.state.templateIdFromParam === null ||
          this.state.templateIdFromParam === undefined
            ? templateDataFromRemote.templateInfo.id
            : this.state.templateIdFromParam;
        let themeColor =
          this.state.templateIdFromParam === null ||
          this.state.templateIdFromParam === undefined
            ? templateDataFromRemote.templateInfo.themeColor
            : DocumentManager.getThemeColor(
                undefined,
                this.state.templateIdFromParam
              );
        templateDataFromRemote.templateInfo.id = templateID;
        templateDataFromRemote.templateInfo.themeColor = themeColor;

        //Update missing JSON Keys
        if (templateDataFromRemote.documentInfo.type === undefined) {
          templateDataFromRemote.documentInfo['type'] = { isVisible: true };
        }
        if (
          templateDataFromRemote.documentInfo.documentDetails.stockTable ===
          undefined
        ) {
          templateDataFromRemote.documentInfo.documentDetails['stockTable'] = {
            isVisible: true
          };
        }
        if (
          templateDataFromRemote.documentInfo.documentDetails.paymentTable ===
          undefined
        ) {
          templateDataFromRemote.documentInfo.documentDetails['paymentTable'] =
            { isVisible: true };
        }
        this.settingsData = templateDataFromRemote;

        var decimal = JSON.parse(settingsData.templateData).documentInfo
          .documentDetails.decimalScale;

        if (decimal === undefined || decimal === null) {
          var defaultDecimal = this.fromERPData.decimalScale
            ? this.fromERPData.decimalScale
            : 2;
          TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails[
            'decimalScale'
          ] = { value: defaultDecimal };
          AppManager.setDecimalScale(defaultDecimal);
        }
      }
      let a = this.updateLocalStorageUpdated(templateDataFromRemote);
      const that = this;
      a.then(
        function () {
          that.modifyDataToShow(dataFromAPI, isComputedData);
        },
        function (err) {}
      );
    } else {
      this.modifyDataToShow(dataFromAPI, isComputedData);
    }
  }
  async updateLocalStorageUpdated(templateDataFromRemote) {
    return new Promise(function (resolve) {
      TemplateSettingsManager.updateTemplateSettings(templateDataFromRemote);
      resolve();
    });
  }

  // Method to Parse ERP Data into DocBuilder Data
  modifyDataToShow(dataFromAPI, isComputedData) {
    if (TemplateSettingsManager.getIsMultipleDocument()) {
      var newDocumentDataList = [];
      var templateId = 1;
      if (dataFromAPI.length > 0) {
        dataFromAPI.forEach((element, index) => {
          const { templateID, templateToShow } = this.setPrintData(
            element.payload,
            isComputedData
          );
          templateToShow.documentIndex = index;
          newDocumentDataList.push(templateToShow);
          templateId = templateID;
        });
        this.setData(
          templateId,
          newDocumentDataList[0].type,
          newDocumentDataList[0],
          newDocumentDataList
        );
        MultiPageUtility.renderForMultiDocumentPrint(
          newDocumentDataList,
          this.state.printInfo,
          this.props.printScale
        );
      }
    } else {
      const { templateID, templateToShow } = this.setPrintData(
        dataFromAPI,
        isComputedData
      );
      this.setData(templateID, templateToShow.type, templateToShow);
      MultiPageUtility.renderForPrint(
        templateToShow,
        this.state.printInfo,
        this.props.printScale
      );
    }
  }

  setPrintData(data, isComputedData) {
    let templateToShow = NewDataParser.getTemplateDataFromERP(
      data,
      this.state.isReadOnlyMode,
      isComputedData
    );
    templateToShow['isReadOnlyMode'] = this.state.isReadOnlyMode;
    this.setState({
      isFromERP: true,
      tableColumnData: TemplateSettingsManager.defaultTemplateSettings.tableInfo
    });
    let templateID =
      this.state.templateIdFromParam === null ||
      this.state.templateIdFromParam === undefined
        ? templateToShow.templateId
        : this.state.templateIdFromParam;

    if (
      TemplateSettingsManager.getRemoteTemplateUID() === undefined ||
      TemplateSettingsManager.getRemoteTemplateUID() === null
    ) {
      if (Utility.getIsIndiaCountry(templateToShow)) {
        if (
          getIsQuotation(templateToShow.type) ||
          getIsSalesOrder(templateToShow.type) ||
          getIsInvoice(templateToShow.type) ||
          getIsBill(templateToShow.type) ||
          getIsOrder(templateToShow.type)
        ) {
          templateID = 36;
          templateToShow.templateId = 36;
          TemplateSettingsManager.updateTemplateID(36);
        }
      } else if (getIsPayslip(templateToShow.type)) {
        var id = TemplateManager.getPeopleInitTemplate(templateToShow.country);
        templateID = id;
        templateToShow.templateId = id;
        TemplateSettingsManager.updateTemplateID(id);
      }

      if (Utility.getIsReceiptPaperSize()) {
        templateID = 1;
        templateToShow.templateId = 1;
        TemplateSettingsManager.updateTemplateID(1);
        templateToShow.themeColor = DocumentManager.getReceiptThemeColor(1);
        TemplateSettingsManager.resetDefaultDataForReceipt(templateToShow);
      }
    }

    return { templateID, templateToShow };
  }
  stockTableClicked = () => {
    var data = this.state.documentData;
    data['showStockTable'] = false;
    TemplateSettingsManager.updateStockTableVisibility(false);
    this.onDataChanged(data);
  };
  paymentTableClicked = () => {
    var data = this.state.documentData;
    data['showPaymentTable'] = false;
    TemplateSettingsManager.updatePaymentTableVisibility(false);
    this.onDataChanged(data);
  };
  checkIfUserLoggedInBeforeExport(type) {
    if (!this.state.bypassUserLoginCheck) {
      if (!AppManager.isUserLoggedIn()) {
        this.showSignInPopup();
        return;
      }
    }

    if (type === ExportType.PRINT) {
      this.setState({ isPrintingPDF: true }, () => {
        PdfUtility.generatePrint(this);
      });
    } else if (type === ExportType.DOWNLOAD_PDF) {
      this.setState({ isDownloadingPDF: true }, () => {
        PdfUtility.generatePDF(
          this.state.documentData.type,
          this.state.documentData.refNumber,
          this
        );
      });
    } else if (type === ExportType.EMAIL) {
      this.setState({ generatingFile: true });
      PdfUtility.getPDFPath(this.state.documentData.refNumber).then(
        (response) => {
          this.setState({
            sharableURL: Utility.getSharableURL(this.state.documentData),
            showMailComposer: true,
            pdfURL: response,
            generatingFile: false,
            isEmailPDF: false
          });
        }
      );
    } else if (type === ExportType.VIEW_PDF) {
      this.setState({ isViewPdf: true }, () => {
        PdfUtility.generatePDF(
          this.state.documentData.type,
          this.state.documentData.refNumber,
          this,
          ExportType.VIEW_PDF
        );
      });
    } else if (type === ExportType.PREVIEW_PDF) {
      this.setState({ isPreviewingPdf: true }, () => {
        PdfUtility.generatePDF(
          this.state.documentData.type,
          this.state.documentData.refNumber,
          this,
          ExportType.PREVIEW_PDF
        );
      });
    }
  }
  resetDefaultData(data) {
    var templateId = this.state.templateId;
    if (this.state.templateType !== undefined) {
      if (data.type !== this.state.templateType) {
        TemplateSettingsManager.resetDefaultData(data, this.state.templateType);
        DocumentManager.setCurrentData(null);
        if (this.state.isFromERP) {
          data.tableColumnPopup =
            TemplateSettingsManager.defaultTemplateSettings.tableInfo;
          this.setData(this.state.templateId, data.type, data);
        } else {
          templateId = this.state.templateId;
          if (
            getIsPayslip(this.state.templateType) ||
            getIsPayslip(data.type)
          ) {
            templateId = TemplateManager.getInitTemplate(
              data.type.toLowerCase()
            );
          }
          var newData = DocumentManager.getDefaultData(templateId, data.type);
          newData.tableColumnPopup =
            TemplateSettingsManager.defaultTemplateSettings.tableInfo;

          this.setData(templateId, data.type, newData);
          if (
            this.state.userLoggedIn &&
            AppManager.getDocumentId() === undefined
          ) {
            TemplateSettingsManager.setCustomTemplateList([]);
            ApiManager.getTemplateSettings(
              TemplateSettingsManager.getTemplateNameType(
                data.type.toLowerCase()
              ),
              this
            );
          }
        }
      } else {
        if (
          data.templateId !== this.state.templateId &&
          Utility.getIsReceiptPaperSize()
        ) {
          templateId = data.templateId;
        }

        this.setState({
          documentData: data,
          templateType: data.type,
          templateId: templateId
        });
      }
    }
  }

  updateTableInfo = (type) => {
    if (getIsInvoice(type) || getIsSalesOrder(type)) {
      if (
        !this.state.tableColumnData
          .map((x) => x.type)
          .includes(TableColumnPopupType.cogs)
      ) {
        const cogs = {
          type: TableColumnPopupType.cogs,
          isSelected: false,
          name: 'cogs'
        };

        const updateTableColumnData = [...this.state.tableColumnData];
        const totalIndex = updateTableColumnData
          .map((x) => x.type)
          .indexOf(TableColumnPopupType.totalAmount);
        if (totalIndex !== -1) {
          updateTableColumnData.splice(totalIndex + 1, 0, cogs);
        } else {
          updateTableColumnData.splice(30, 0, cogs);
        }

        this.setState({
          tableColumnData: updateTableColumnData
        });
      }
    } else {
      if (
        this.state.tableColumnData
          .map((x) => x.type)
          .includes(TableColumnPopupType.cogs)
      ) {
        const updateTableColumnData = [...this.state.tableColumnData];
        const index = updateTableColumnData
          .map((x) => x.type)
          .indexOf(TableColumnPopupType.cogs);
        updateTableColumnData.splice(index, 1);

        this.setState({
          tableColumnData: updateTableColumnData
        });
      }
    }
  };
}
