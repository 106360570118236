import { TableColumnPopupType } from "../../../Components/Popup/TableColumnPopup";
import { getIsShowTableColumn } from "../../../Utilities/Utility";

export class GoodsReceiptTableWidth {

    isCodeVisible: boolean;
    isOrderNoVisible: boolean;
    isNameVisible: boolean;
    isDescriptionVisible: boolean;
    isUomVisible: boolean;
    isRequiredQuantityVisible: boolean;
    isReceivedQuantityVisible: boolean;
    isWarehouseCodeVisible: boolean;
    isWarehouseNameVisible: boolean;
    isProductCustomFieldVisible: boolean;

    code: number;
    orderNo: number;
    name: number;
    description: number;
    uom: number;
    requiredQuantity: number;
    receivedQuantity: number;
    warehouseCode: number;
    warehouseName: number;
    productCustomField: number;

    originalCode: number;
    originalOrderNo: number;
    originalName: number;
    originalDescription: number;
    originalUom: number;
    originalRequiredQuantity: number;
    originalReceivedQuantity: number;
    originalWarehouseCode: number;
    originalWarehouseName: number;
    originalProductCustomField: number;
    productCustomFieldList: any;

    constructor() {
        this.isCodeVisible = true
        this.isOrderNoVisible = true
        this.isNameVisible = true
        this.isDescriptionVisible = true
        this.isUomVisible = true
        this.isRequiredQuantityVisible = true
        this.isReceivedQuantityVisible = true
        this.isWarehouseCodeVisible = true
        this.isWarehouseNameVisible = true
        this.isProductCustomFieldVisible = true

        this.code = 6
        this.orderNo = 6
        this.name = 13
        this.description = 23
        this.uom = 6
        this.requiredQuantity = 10
        this.receivedQuantity = 10
        this.warehouseCode = 10
        this.warehouseName = 7
        this.productCustomField = 9

        this.originalCode = 6
        this.originalOrderNo = 6
        this.originalName = 13
        this.originalDescription = 23
        this.originalUom = 6
        this.originalReceivedQuantity = 10
        this.originalRequiredQuantity = 10
        this.originalWarehouseCode = 10
        this.originalWarehouseName = 7
        this.originalProductCustomField = 9

    }

    setIsShowWidth(columnData: any) {
        this.isCodeVisible = getIsShowTableColumn(columnData, TableColumnPopupType.code)
        this.isOrderNoVisible = getIsShowTableColumn(columnData, TableColumnPopupType.orderNo)
        this.isNameVisible = getIsShowTableColumn(columnData, TableColumnPopupType.productName)
        this.isDescriptionVisible = getIsShowTableColumn(columnData, TableColumnPopupType.description)
        this.isUomVisible = getIsShowTableColumn(columnData, TableColumnPopupType.uom)
        this.isRequiredQuantityVisible = getIsShowTableColumn(columnData, TableColumnPopupType.requiredQuantity)
        this.isReceivedQuantityVisible = getIsShowTableColumn(columnData, TableColumnPopupType.receivedQuantity)
        this.isWarehouseCodeVisible = getIsShowTableColumn(columnData, TableColumnPopupType.warehouseCode)
        this.isWarehouseNameVisible = getIsShowTableColumn(columnData, TableColumnPopupType.warehouseName)

        var productCustomFieldList = columnData.filter((x: any) => x.code !== undefined)
        if (productCustomFieldList.length > 0) {
            this.isProductCustomFieldVisible = false
            if (this.productCustomFieldList === undefined) {
                //assign product custom list with corresponding flag
                this.productCustomFieldList = []
                var width = 5
                var maxWidth = 20
                if (productCustomFieldList.length > 4) {
                    width = maxWidth / productCustomFieldList.length
                }
                productCustomFieldList.forEach((element: any) => {
                    var item = {} as any
                    item.code = element.code
                    item.isVisible = element.columnOption.isShowColumn
                    item.originalWidth = width
                    item.width = width
                    item.type = element.name
                    item.label = element.label
                    this.productCustomFieldList.push(item)
                });
            }
            else {
                this.productCustomFieldList.forEach((element: any) => {
                    var item = columnData.find((x: any) => x.code === element.code)
                    if (item !== undefined) {
                        element.isVisible = item.columnOption.isShowColumn
                    }
                });
            }
        }
    }
    getTableHeaderWidths() {
        var totalWidth = 0
        totalWidth = this.getTotalWidth(totalWidth);

        if (totalWidth === 100) {
            this.resetElementOriginalWidth();
        }
        else if (totalWidth === 0) {
            this.resetElementWidth();
        }
        else {
            var totalSelectedElement = this.getTotalSelectedElement()
            if (totalSelectedElement !== 0) {
                var totalOffset = (100 - totalWidth) / totalSelectedElement
                this.updateElementWidth(totalOffset)
            }
        }
    }
    private resetElementOriginalWidth() {
        this.code = this.originalCode
        this.orderNo = this.originalOrderNo
        this.name = this.originalName
        this.description = this.originalDescription
        this.uom = this.originalUom
        this.receivedQuantity = this.originalReceivedQuantity
        this.requiredQuantity = this.originalRequiredQuantity
        this.warehouseCode = this.originalWarehouseCode
        this.warehouseName = this.originalWarehouseName
        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                element.width = element.originalWidth
            });
        }
    }

    private resetElementWidth() {
        this.code = 0
        this.orderNo = 0
        this.name = 0
        this.description = 0
        this.originalUom = 0
        this.receivedQuantity = 0
        this.requiredQuantity = 0
        this.warehouseCode = 0
        this.warehouseName = 0
        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                element.width = 0
            });
        }
    }

    private getTotalWidth(totalWidth: number) {
        if (this.isCodeVisible) {
            totalWidth += this.code;
        }
        if (this.isOrderNoVisible) {
            totalWidth += this.orderNo;
        }
        if (this.isNameVisible) {
            totalWidth += this.name;
        }
        if (this.isDescriptionVisible) {
            totalWidth += this.description;
        }
        if (this.isUomVisible) {
            totalWidth += this.uom;
        }
        if (this.isReceivedQuantityVisible) {
            totalWidth += this.receivedQuantity;
        }
        if (this.isRequiredQuantityVisible) {
            totalWidth += this.requiredQuantity;
        }
        if (this.isWarehouseCodeVisible) {
            totalWidth += this.warehouseCode;
        }
        if (this.isWarehouseNameVisible) {
            totalWidth += this.warehouseName;
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    totalWidth += element.width;
                }
            });
        }

        return totalWidth;
    }

    private getTotalSelectedElement() {
        var totalSelectedElement = 0
        if (this.isCodeVisible) {
            totalSelectedElement += 1
        }
        if (this.isOrderNoVisible) {
            totalSelectedElement += 1
        }
        if (this.isNameVisible) {
            totalSelectedElement += 1
        }
        if (this.isDescriptionVisible) {
            totalSelectedElement += 1
        }
        if (this.isUomVisible) {
            totalSelectedElement += 1
        }
        if (this.isRequiredQuantityVisible) {
            totalSelectedElement += 1
        }
        if (this.isReceivedQuantityVisible) {
            totalSelectedElement += 1
        }
        if (this.isWarehouseCodeVisible) {
            totalSelectedElement += 1
        }
        if (this.isWarehouseNameVisible) {
            totalSelectedElement += 1
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    totalSelectedElement += 1
                }
            });
        }

        return totalSelectedElement
    }

    private updateElementWidth(additionalWidth: number) {
        if (this.isCodeVisible) {
            this.code = this.originalCode + additionalWidth
        }
        if (this.isOrderNoVisible) {
            this.orderNo = this.originalOrderNo + additionalWidth
        }
        if (this.isNameVisible) {
            this.name = this.originalName + additionalWidth
        }
        if (this.isDescriptionVisible) {
            this.description = this.originalDescription + additionalWidth
        }
        if (this.isUomVisible) {
            this.uom = this.originalUom + additionalWidth
        }
        if (this.isRequiredQuantityVisible) {
            this.requiredQuantity = this.originalRequiredQuantity + additionalWidth
        }
        if (this.isReceivedQuantityVisible) {
            this.receivedQuantity = this.originalReceivedQuantity + additionalWidth
        }
        if (this.isWarehouseCodeVisible) {
            this.warehouseCode = this.originalWarehouseCode + additionalWidth
        }
        if (this.isWarehouseNameVisible) {
            this.warehouseName = this.originalWarehouseName + additionalWidth
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    element.width = element.originalWidth + additionalWidth
                }
            });
        }
    }

    getProductCustomFieldWidthByCode(code: string) {
        var item = this.productCustomFieldList.find((x: any) => x.code === code)
        if (item !== undefined) {
            return item.width
        }
        return undefined
    }
}