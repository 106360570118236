import AppManager from "../Manager/AppManager"
import TemplateSettingsManager from "../Manager/TemplateSettingsManager"
import { getLocalisedText } from "../Translate/LanguageManager"
import { getIsInvoice, getIsQuotation, getIsSalesOrder, getIsWorkOrder } from "./Utility"

export const DocumentLabelType = {
    documentNumber: 'documentNumber',
    customerOrderNumber: 'customerOrderNumber',
    linkDocumentNumber: 'linkDocumentNumber'
}


export const documentLabelPosition = {
    topLeft: 'topLeft',
    topMiddle: 'topMiddle',
    topRight: 'topRight',
    bottomLeft: 'bottomLeft',
    bottomRight: 'bottomRight',
    bottomMiddle: 'bottomMiddle',
    top: 'top',
    bottom: 'bottom',
}

export default class DocumentLabelUtility {

    static getDefaultDocumentLabelList(documentType) {
        if (getIsWorkOrder(documentType)) {
            return [
                {
                    type: DocumentLabelType.linkDocumentNumber,
                    label: AppManager.getTypeNoString('sales_order'),
                    value: 'linkDocumentNumber',
                    isSelected: false,
                },
                {
                    type: DocumentLabelType.customerOrderNumber,
                    label: getLocalisedText('con_number'),
                    value: 'customerOrderNumber',
                    isSelected: false,
                },
            ]
        }
        return [
            {
                type: DocumentLabelType.documentNumber,
                label: AppManager.getTypeNoString(documentType),
                value: 'refNumber',
                isSelected: false,
            },
            {
                type: DocumentLabelType.customerOrderNumber,
                label: getLocalisedText('con_number'),
                value: 'customerOrderNumber',
                isSelected: false,
            },
        ]
    }

    static getDocumentList(type) {
        var documentLabelList = TemplateSettingsManager.getDocumentLabel().elements
        var defaultDocumentList = this.getDefaultDocumentLabelList(type)
        var list = []

        if (documentLabelList.length === 0) {
            list = defaultDocumentList
        }
        else {
            var newList = documentLabelList
            var types = documentLabelList.map(x => x.type)

            defaultDocumentList.forEach(element => {
                if (!types.includes(element.type)) {
                    newList.push(element)
                }
            });

            list = newList
        }

        if (list.length > 0) {
            list.forEach((element, index) => {
                element.index = index
            });
        }
        return list
    }

    static getDefaultPositionList() {
        return [
            {
                index: 0,
                type: documentLabelPosition.topLeft,
                label: 'left',
                portion: documentLabelPosition.top,
            },
            {
                index: 2,
                type: documentLabelPosition.topMiddle,
                label: 'middle',
                portion: documentLabelPosition.top,
            },
            {
                index: 3,
                type: documentLabelPosition.topRight,
                label: 'right',
                portion: documentLabelPosition.top,
            },
            {
                index: 4,
                type: documentLabelPosition.bottomLeft,
                label: 'left',
                portion: documentLabelPosition.bottom,
            },
            {
                index: 5,
                type: documentLabelPosition.bottomMiddle,
                label: 'middle',
                portion: documentLabelPosition.bottom,
            },
            {
                index: 6,
                type: documentLabelPosition.bottomRight,
                label: 'right',
                portion: documentLabelPosition.bottom,
            },
        ]
    }

    static getShowHideBarcode(data, documentLabelList) {
        if(data !== undefined && data !== null) {
            if (data.tenantBarcodeSettings !== undefined && data.tenantBarcodeSettings !== null) {
                let tenantBarcodeSettings = data.tenantBarcodeSettings
                if (getIsInvoice(data.type) || getIsQuotation(data.type) || getIsSalesOrder(data.type)) {
                    documentLabelList.forEach(element => {
                        element.isSelected = false
                        if (element.type === DocumentLabelType.documentNumber) {
                            if (tenantBarcodeSettings.generateBarcodeforDocNoSD !== null && tenantBarcodeSettings.generateBarcodeforDocNoSD !== undefined) {
                                element.isSelected = (tenantBarcodeSettings.generateBarcodeforDocNoSD === true || tenantBarcodeSettings.generateBarcodeforDocNoSD === "true") ? true : false
                            }
                        }
                        if (element.type === DocumentLabelType.customerOrderNumber) {
                            if (tenantBarcodeSettings.generateBarcodeforCustOrderNoSD !== null && tenantBarcodeSettings.generateBarcodeforCustOrderNoSD !== undefined) {
                                element.isSelected = (tenantBarcodeSettings.generateBarcodeforCustOrderNoSD === true || tenantBarcodeSettings.generateBarcodeforCustOrderNoSD === 'true') ? true : false
                            }
                        }
                    });
                }
                else if (getIsWorkOrder(data.type)) {
                    documentLabelList.forEach(element => {
                        element.isSelected = false
                        if (element.type === DocumentLabelType.linkDocumentNumber) {
                            if (tenantBarcodeSettings.generateBarcodeforDocNoforWorkOrder !== null && tenantBarcodeSettings.generateBarcodeforDocNoforWorkOrder !== undefined) {
                                element.isSelected = (tenantBarcodeSettings.generateBarcodeforDocNoforWorkOrder === true || tenantBarcodeSettings.generateBarcodeforDocNoforWorkOrder === "true") ? true : false
                            }
                        }
                        if (element.type === DocumentLabelType.customerOrderNumber) {
                            if (tenantBarcodeSettings.generateBarcodeforCustOrderNoforWorkOrder !== null && tenantBarcodeSettings.generateBarcodeforCustOrderNoforWorkOrder !== undefined) {
                                element.isSelected = (tenantBarcodeSettings.generateBarcodeforCustOrderNoforWorkOrder === true || tenantBarcodeSettings.generateBarcodeforCustOrderNoforWorkOrder === 'true') ? true : false
                            }
                        }
                    });
                }
            }
        }
        return documentLabelList
    }

}