import { PAGE_ROUTES } from '../constants/Constant';
import { COMMON_EVENTS } from '../constants/Enum';
import { commonCustomEvent } from '../services/common/events';
import { getItemFromLocalStorage } from '../utility/LocalStorage';
import AppManager from './AppManager';

export default class RouteManager {
  static presenter: any = null;
  static prefix: string = ''

  static initiateRoutes() {
    const subDomain = getItemFromLocalStorage('subdomain');
    const prefix = subDomain ? `/${subDomain}` : '';
    Object.keys(PAGE_ROUTES).map((route: any) => {
      (PAGE_ROUTES as any)[route] = `${prefix}${(PAGE_ROUTES as any)[route]}`
    })
  }

  static setPresenter(presenter: any) {
    this.presenter = presenter;
  }

  static navigateToPage(pageRoute: string, param = '') {
    RouteManager?.presenter?.props?.history?.push(
      pageRoute + (param ? '?' + param : '')
    );
    AppManager.scrollToTop();
    commonCustomEvent.dispatch(COMMON_EVENTS.NAVIGATION_TRIGGERED, {});
  }

  static navigateToHome() {
    RouteManager.navigateToPage(PAGE_ROUTES.INVOICES);
  }
}
