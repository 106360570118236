import React from 'react';
import Draggable from 'react-draggable';
import { HTMLTag } from '../../../../Utilities/HTMLTag';
import { TemplateItem } from '../model/TemplateItem';
import './DraggableBanner.css';
interface Props {
    templateItem: TemplateItem
    scale: number
    disable: boolean
    printScale: number
}

interface States {
}
export default class DraggableBanner extends React.Component<Props, States> {
    render() {
        var id = HTMLTag.DRAGGABLE_COMPONENT_PREFIX + this.props.templateItem.id
        return (
            <Draggable
                axis="both"
                handle=".handle"
                disabled={this.props.disable}
                defaultPosition={{
                    x: 0,
                    y: 0
                }}
                grid={[1, 1]}
                scale={this.props.scale}
                onStart={() => { }}
                onDrag={() => { }}
                onStop={() => { }}
            >
                <div id={id} className="handle" style={{
                    // zIndex: 1,
                    position:'relative',
                    opacity: this.props.templateItem.style.opacity ? this.props.templateItem.style.opacity : 1 ,
                }}>
                    <img src={this.props.templateItem.image}
                        crossOrigin='anonymous'
                        style={{
                            width: '100%',
                            opacity: this.props.templateItem.style.opacity ? this.props.templateItem.style.opacity : 1,
                        }}
                        alt=''
                    >
                    </img>
                </div>
            </Draggable>
        );
    }

}
