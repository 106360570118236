import React, { Component } from "react";
import "../../DocPreview.css";
import ComponentManager from "../../Manager/ComponentManager";
import {
    COLOR_LIGHTGRAY,
    COLOR_LIGHTGRAY2,
    FONT_SIZE,
    NEGATIVE_PAGE_PADDING,
} from '../../Constants/Constants';
import DocumentManager from '../../Manager/DocumentManager';
import "../../CommonStyles/FontStyle.css"
import Utility, { getFontSizeClass, getIsFulfillment, getIsGoodsReceipt, getPageHeight, getVW } from "../../Utilities/Utility";
import { getLocalisedText } from '../../Translate/LanguageManager';
import DKEInvoiceSection, { eInvoiceSectionPosition } from "../../DKUILibrary/DKEInvoiceSection";
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import DKSignatureSection from "../../DKUILibrary/DKSignatureSection";
import { AddressType } from "../../Helper/AddressParser";
import { HTMLTag } from "../../Utilities/HTMLTag";
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";

export default class DocumentTemplate27 extends Component {

    styleTemp4 = {
        mainContainer: {
            position: "relative",
            width: "100%",
            height: "100%",
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    addDocumentTypeLabel() {
        return (
            <div className="RowDiv" style={{ justifyContent: 'center' }}>
                <text
                    style={{
                        fontWeight: "normal",
                        color: 'white',
                        textAlign: 'center',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xLarge }, this.state.data.printScale),
                    }}
                >
                    {getLocalisedText(this.state.data.documentType)}
                </text>
            </div>
        )
    }

    getCompanyNameLabel() {
        return (
            <div className="RowDiv" style={{ justifyContent: 'center' }}>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        textAlign: 'center',
                        width: '90%',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale),
                        }}>
                    {getLocalisedText(Utility.getCompanyName(this.state.data))}
                </text>
            </div>
        )
    }

    getCompanyInformationView() {
        return (
            <div className="RowDiv" style={{ justifyContent: 'center' }}>
                <div className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        textAlign: 'center',
                        width: '90%',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.from) }, this.state.data.printScale),
                    }}
                dangerouslySetInnerHTML={{__html: getLocalisedText(Utility.getCompanyAddress(this.state.data))}}>
                </div>
            </div>
        )
    }

    addLeftView() {
        return (
            <div className="ColumnDiv" style={{
                width: getVW(Utility.convertToPrintScale(180, this.state.data.printScale)),
                height: getPageHeight(this.state.data.printScale),
                // marginLeft: getVW(NEGATIVE_PAGE_PADDING, true),
                // marginTop: 0,
                backgroundColor: this.state.data.themeColor + '4d',
            }}>
                <div className="ColumnDiv" style={{ width: '100%', backgroundColor: this.state.data.themeColor, height: getVW(Utility.convertToPrintScale(100, this.state.data.printScale)), justifyContent: "center" }}>
                    {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(30, this.state.data.printScale))}
                    {this.addDocumentTypeLabel()}

                </div>
                {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(20, this.state.data.printScale))}
                {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) && ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(30, this.state.data.printScale), {position: 'static', alignSelf: 'center'})}
                {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(20, this.state.data.printScale))}
                {this.state.data.showCompanyName && this.getCompanyNameLabel()}
                {this.state.data.showFrom && this.getCompanyInformationView()}
            </div>
        )
    }

    addInvoiceDetails() {
        return (
            <div className="ColumnDiv" style={{ width: "35%", alignItems: 'center'}}>
                {ComponentManager.addVerticalSpace(10)}
                {this.getDocumentDetailsSection()}
            </div>
        )
    }

    getDocumentDetailsSection() {
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        return documentDetailList.map((element, index) => {
            var value = ''
            if (element.key !== undefined) {
                value = this.state.data[element.key]
            }
            else if (element.customFieldValue !== undefined) {
                value = element.customFieldValue
            }

            return <>
                {index !== 0 && ComponentManager.addDivider({ backgroundColor: 'lightgray', height: getVW(1), width: '100%' })}
                {index !== 0 && ComponentManager.addVerticalSpace(Utility.convertToPrintScale(10, this.state.data.printScale))}
                {ComponentManager.getRowValueSection(
                    element.label,
                    value,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    this.state.data.printScale
                )}
                {index === documentDetailList.length - 1 && ComponentManager.addDivider({ backgroundColor: 'lightgray', height: getVW(1), width: '100%' })}
            </>
        });
    }

    addAddressesField() {
        return (
            <div className="RowDiv" style={{ alignItems: "flex-start", width: '100%' }}>
                <div className="RowDiv" style={{ justifyContent: 'space-evenly', marginLeft: getVW(Utility.convertToPrintScale(35, this.state.data.printScale)) }}>
                    {this.state.data.showClientBillToAddress && ComponentManager.getDescriptionSection(
                        this.state.data.billToTitle,
                        Utility.getClientBillToAddress(this.state.data),
                        "100%",
                        'black',
                        true,
                        '#000000',
                        getVW(6),
                        Utility.getOwnerType(this.state.data, AddressType.billTo),
                        this.state.data.printScale
                    )}
                    {(this.state.data.showClientBillToAddress && this.state.data.showClientShipToAddress) && <div style={{ height: getVW(Utility.convertToPrintScale(50, this.state.data.printScale)), width: "10%" }} />}
                    {this.state.data.showClientShipToAddress && ComponentManager.getDescriptionSection(
                        this.state.data.shipToTitle,
                        Utility.getClientShipToAddress(this.state.data),
                        "100%",
                        'black',
                        true,
                        '#000000',
                        getVW(6),
                        Utility.getOwnerType(this.state.data, AddressType.shipTo),
                        this.state.data.printScale
                    )}


                </div>
            </div>
        )
    }

    createItemsDetailsView() {
        return (
            <div className="ColumnDiv"
                style={{
                    width: '95%',
                }}>
                {
                    TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                    <>
                        <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                            dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                        {ComponentManager.addVerticalSpace(5)}
                    </>
                }
                {this.getProductTable()}
                {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(20)}
                {this.getEditableDocumentTable()}
            </div>
        )
    }

    getNotesAndSignatureSection() {
        return <div className="ColumnDiv parent-width" style={{alignItems: 'flex-start', marginTop: getVW(10)}}>
            {(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) &&
                <div className="RowDiv parent-width" style={{ zIndex: 100, marginLeft: getVW(30) }}>
                    <DKSignatureSection data={this.state.data} onSignatureSectionClicked={this.props.tableClicked} />
                </div>
            }
            <div className='RowDiv' style={{ alignItems: 'flex-start'}}>
                <div id={HTMLTag.TERMS_NOTE_PREFIX + HTMLTag.TERMS} style={{ width: '100%' }}>
                    {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && this.addFooterView()}
                </div>
                <div id={HTMLTag.SIGNATURE} className="RowDiv" style={{ width: '67%' }}>
                    {this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature, this.state.data.printScale)}
                </div>
            </div>
        </div>;
    }

    addFooterView() {
        return (
            <div className="RowReverseDiv" style={{ zIndex: 100, marginLeft: getVW(30)}}>
                {this.state.data.termsAndCondition &&
                    ComponentManager.getDescriptionSection(
                        Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition')),
                        this.state.data.termsAndCondition,
                        "100%",
                        '#000000',
                        undefined,
                        undefined,
                        getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                        undefined,
                        this.state.data.printScale
                    )}
            </div>
        )
    }

    addRightView() {
        return (
            <div className="ColumnDiv" style={{ justifyContent: 'flex-start', alignItems: 'flex-end', width: '100%', height: '100%' }}>
                {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(40, this.state.data.printScale))}
                {this.addInvoiceDetails()}
                {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(35, this.state.data.printScale))}
                {this.addAddressesField()}
                {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && ComponentManager.addVerticalSpace(10)}
                {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && this.getSourceOfSupplyInfoSection()}
                {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.addVerticalSpace(17.5)}
                {Utility.getIsShowPlaceOfSupply(this.state.data) && this.getPlaceOfSupplyInfoSection()}
                {ComponentManager.addVerticalSpace(17.5)}
                {Utility.getIsContainsCustomField('top') &&
                    <div className="RowDiv" style={{ alignItems: "flex-start", width: '100%' }}>
                        <div className="RowDiv" style={{ justifyContent: 'space-evenly', marginLeft: getVW(35) }}>
                            {ComponentManager.addDivider({ backgroundColor: 'lightgray', height: getVW(1), width: '100%'})}
                        </div>
                    </div>
                }
                <div className="RowDiv" style={{ alignItems: "flex-start", width: '100%' }}>
                    <div className="RowDiv" style={{ justifyContent: 'space-evenly', marginLeft: getVW(35) }}>
                        <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'top'} />
                    </div>
                </div>
                {ComponentManager.addVerticalSpace(17.5)}
                {this.createItemsDetailsView()}
                <div className="RowDiv" style={{ alignItems: "flex-start", width: '100%' }}>
                    <div className="RowDiv" style={{ justifyContent: 'space-evenly', marginLeft: getVW(35) }}>
                        <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'bottom'} />
                    </div>
                </div>
                {this.getNotesAndSignatureSection()}
            </div>
        )
    }

    render() {
        return (
            <div className="ColumnDiv" style={{ alignItems: 'flex-start' }}>
                {this.state.data.showEInvoiceSection &&
                    <div className='RowDiv'>
                        <DKEInvoiceSection
                            data={this.state.data}
                            position={eInvoiceSectionPosition.top}
                        />
                    </div>
                }
                <div className='RowDiv'>
                    <div>
                        {this.addLeftView()}
                    </div>
                    <div className='DocumentHolder ColumnDiv' style={{ justifyContent: 'space-between', minHeight: getPageHeight(this.state.data.printScale) }}>
                        <div className='parent-width' style={{ width: '100%', paddingBottom: getVW(30) }}>

                            {this.addRightView()}
                        </div>
                    </div>
                </div>
                <DKEInvoiceSection
                    data={this.state.data}
                    position={eInvoiceSectionPosition.bottom}
                />
            </div>
        );
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getProductTable() {
        if(Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKProductTable(
                Utility.getTableColumnForAccountProduct(),
                this.getTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerBackgroundColor = this.state.data.themeColor;
        style.headerTextColor = "#ffffff";
        style.headerBorderColor = this.state.data.themeColor;
        style.itemBorderColor = COLOR_LIGHTGRAY2;
        style.itemBackgroundColor = COLOR_LIGHTGRAY;
        style.itemBackgroundColorIsAlternate = true;
        style.footerBackgroundColor = this.state.data.themeColor + '4d';
        style.footerTextColor = "#000000";
        style.footerBorderColor = "transparent";
        style.subTotalBorderColor = 'transparent';
        return style;
    }

    getCustomFieldTableStyle() {
        let style = {}
        style.columnCount = 2
        style.columnWidths = Utility.getIsArabicLang() ? ['47.5%', '52.5%'] : ['52.5%', '47.5%']
        return style;
    }

    getPlaceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getPlaceOfSupply()}

            </div>
        )
    }
    getPlaceOfSupply() {
        return (
            <div className="RowDiv" style={{ alignItems: "flex-start", width: '100%' }}>
                <div className="RowDiv" style={{ justifyContent: 'space-evenly', marginLeft: getVW(35) }}>
                    { ComponentManager.getDescriptionSection(
                        getLocalisedText('place_of_supply'),
                       this.state.data.placeOfSupply,
                        "100%",
                        'black',
                        true,
                        '#000000',
                        getVW(6),
                        true,
                        this.state.data.printScale
                    )}
                </div>
            </div>
        )



    }

    getSourceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getSourceOfSupply()}

            </div>
        )
    }

    getSourceOfSupply() {
        return <div className='RowDiv' style={{ width: '100%',marginLeft:'40px'}}>
            <div className='ColumnDiv' style={{
                width: '52%'
            }}>
                {Utility.getIsShowSourceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('source_of_supply'),
                    this.state.data.sourceOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowDestinationOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('destination_of_supply'),
                    this.state.data.destinationOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
          </div>

        </div>;
    }
}

