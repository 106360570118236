import React, { Component } from 'react'
import { BARCODE_FORMAT, BARCODE_FONT_SIZE } from '../Constants/Constants';
import { HTMLTag } from '../Utilities/HTMLTag';
import DocumentLabelUtility, { documentLabelPosition } from '../Utilities/DocumentLabelUtility';
import TemplateSettingsManager from './TemplateSettingsManager';
import Barcode from 'react-barcode';
import { getVW } from '../Utilities/Utility';

export default class DocumentLabelManager extends Component {

    barcodeInitialState = {
        format: BARCODE_FORMAT.CODE_128,
        renderer: 'svg',
        width: 2,
        height: 100,
        displayValue: true,
        fontOptions: '',
        font: 'ui-sans-serif',
        textAlign: 'center',
        textPosition: 'bottom',
        textMargin: 2,
        fontSize: BARCODE_FONT_SIZE,
        background: '#ffffff',
        lineColor: '#000000',
        margin: 10,
        value: '',
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0
    }

    getBarcodeSetting(data) {
        var barcodeSettings = this.barcodeInitialState

        if (data.tenantBarcodeSettings !== undefined && data.tenantBarcodeSettings !== null) {
            let tenantBarcodeSettings = data.tenantBarcodeSettings

            if (tenantBarcodeSettings.height !== null && tenantBarcodeSettings.height !== undefined) {
                barcodeSettings.height = Number(tenantBarcodeSettings.height)
            }
            if (tenantBarcodeSettings.barcodeType !== null && tenantBarcodeSettings.barcodeType !== undefined) {
                barcodeSettings.format = tenantBarcodeSettings.barcodeType
            }
            if (tenantBarcodeSettings.barcodeType !== null && tenantBarcodeSettings.barcodeType !== undefined) {
                barcodeSettings.format = tenantBarcodeSettings.barcodeType
            }
            if (tenantBarcodeSettings.barcodeTopMargin !== null && tenantBarcodeSettings.barcodeTopMargin !== undefined) {
                barcodeSettings.marginTop = tenantBarcodeSettings.barcodeTopMargin
            }
            if (tenantBarcodeSettings.barcodeLeftMargin !== null && tenantBarcodeSettings.barcodeLeftMargin !== undefined) {
                barcodeSettings.marginLeft = tenantBarcodeSettings.barcodeLeftMargin
            }
            if (tenantBarcodeSettings.barcodeLabelHeight !== null && tenantBarcodeSettings.barcodeLabelHeight !== undefined) {
                barcodeSettings.barcodeLabelHeight = Number(tenantBarcodeSettings.barcodeLabelHeight)
            }
        }

        return barcodeSettings
    }

    render() {
        return (
            <div id={HTMLTag.DOCUMENT_LABEL_MANAGER}
            >
                {this.getIsShowTopSection() && this.getDocumentLabelDiv()}
                {this.props.mainContent}
                {this.getIsShowBottomSection() && this.getDocumentLabelDiv()}
            </div>
        )
    }

    getDocumentLabelDiv() {
        var position = 'center'
        var documentLabel = this.getSelectedPosition()
        if (documentLabel !== undefined) {
            if (documentLabel.label === 'left') {
                position = 'flex-start'
            }
            else if (documentLabel.label === 'right') {
                position = 'flex-end'
            }
        }
        return <div
            id={HTMLTag.DOCUMENT_LABEL_TEXT}
            className="RowDiv"
            style={{
                justifyContent: position,
            }}>
            {this.getLabelList()}
        </div>
    }


    getLabelList() {
        var documentLabel = TemplateSettingsManager.getDocumentLabel()
        if (documentLabel !== undefined) {
            if (documentLabel.elements !== undefined && documentLabel.elements !== null) {
                if (documentLabel.elements.length > 0) {
                    documentLabel.elements.forEach(element => {
                        element.mappedValue = this.getDocumentDataByKey(element.value)
                    });

                    documentLabel.elements = DocumentLabelUtility.getShowHideBarcode(this.props.documentData, documentLabel.elements)
                    
                    var elements = documentLabel.elements.filter(x => x.mappedValue !== undefined && x.isSelected)
                    if (elements.length > 0) {
                        return elements.map(element => {
                            return this.renderBarcode(element)
                        });
                    }
                }
            }
        }
        return <></>
    }

    getDocumentDataByKey = (key) => {
        if (this.props.documentData !== undefined && this.props.documentData !== null) {
            if (this.props.documentData[key] !== undefined && this.props.documentData[key] !== null) {
                if (this.props.documentData[key] !== '') {
                    return this.props.documentData[key]
                }
            }
        }

        return undefined
    }

    renderBarcode = (item) => {
        let setting = this.getBarcodeSetting(this.props.documentData)
        return (
            <div
                id="barcode-svg"
                // className="position-absolute"
                style={{ top: setting.marginTop }}
            >
                <Barcode
                    value={item.mappedValue}
                    width={1.2}
                    // height={getVW(setting.height)}
                    height={getVW(this.getBarcodeSize())}
                    format={BARCODE_FORMAT[setting.format]}
                    displayValue={setting.displayValue}
                    fontOptions={setting.fontOptions}
                    font={setting.font}
                    textAlign={setting.textAlign}
                    textPosition={setting.textPosition}
                    textMargin={setting.textMargin}
                    fontSize={setting.fontSize}
                    background={setting.background}
                    lineColor={setting.lineColor}
                    margin={setting.margin}
                    marginBottom={setting.marginBottom}
                    marginRight={setting.marginRight}
                />
            </div>
        )
    }

    getBarcodeSize() {
        var height = 350
        let resizeValue = TemplateSettingsManager.getBarcodeSettings('resizeValue', 0)
        let isResize = TemplateSettingsManager.getBarcodeSettings('isResizeBarcode', false)

        if (isResize) {
            if (resizeValue !== undefined && resizeValue !== null) {
                height += resizeValue
            }
        }
        return height
    }


    getIsShowDocumentLabel() {
        return TemplateSettingsManager.getDocumentLabelVisible()
    }

    getDocumentLabelPosition() {
        var documentLabel = TemplateSettingsManager.getDocumentLabel()
        if (documentLabel !== undefined) {
            if (documentLabel.position !== undefined && documentLabel.position !== null) {
                return documentLabel.position
            }
        }

        return DocumentLabelUtility.topLeft
    }

    getSelectedPosition() {
        var documentLabel = this.getDocumentLabelPosition()
        if (documentLabel !== undefined) {
            return DocumentLabelUtility.getDefaultPositionList().find(x => x.type === documentLabel)
        }

        return undefined
    }

    getIsShowTopSection() {
        if (this.getIsShowDocumentLabel()) {
            var position = this.getDocumentLabelPosition()
            if (position !== undefined) {
                return (position === documentLabelPosition.topLeft
                    || position === documentLabelPosition.topMiddle
                    || position === documentLabelPosition.topRight)
            }
        }
        return false
    }

    getIsShowBottomSection() {
        if (this.getIsShowDocumentLabel()) {
            var position = this.getDocumentLabelPosition()
            if (position !== undefined) {
                return (position === documentLabelPosition.bottomLeft
                    || position === documentLabelPosition.bottomMiddle
                    || position === documentLabelPosition.bottomRight)
            }
        }
        return false
    }
}
