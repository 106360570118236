import { DateFormat } from "../Components/TemplateEditors/DocumentEditor"
import ComponentManager from "../Manager/ComponentManager"
import Utility, { convertDateFromERPFormatToDocFormat, getConvertedDate, getDateFrom, getDateString, getIsMakePayment, getIsReceivePayment } from "../Utilities/Utility"
import NewDataParser from "./NewDataParser"

export default class LineItemsParser {
    static getProductLineItem(dataFromAPI, isGeneratedData, dateFormat, productLineItem = undefined) {
        var productTrackingInfo = dataFromAPI.productTrackingInfo
        var advancedTracking = undefined

        var items =  dataFromAPI.lineItems
        if(productLineItem !== undefined) {
            items = productLineItem
        }

        var lineItems = items.map((product, index) => {
            let modifiedProduct = product
            if(modifiedProduct.product !== undefined && modifiedProduct.product !== null) {
                if (modifiedProduct.product.hsnOrSacCode !== null && modifiedProduct.product.hsnOrSacCode !== undefined && !isGeneratedData) {
                    modifiedProduct.hsnOrSacCodeString = '<br><b>HSN/SAC : </b>' + modifiedProduct.product.hsnOrSacCode
                    modifiedProduct.hsnOrSacCode = modifiedProduct.product.hsnOrSacCode
                }

                if(product.name === undefined || product.name === null) {
                    product.name = modifiedProduct.product.name
                }
            }
            if (product.hsnOrSacCode !== null && product.hsnOrSacCode !== undefined && !isGeneratedData) {
                modifiedProduct.hsnOrSacCodeString = '<br><b>HSN/SAC : </b>' + product.hsnOrSacCode
                modifiedProduct.hsnOrSacCode = product.hsnOrSacCode
            }
            modifiedProduct.lineNumber = (product.lineNumber !== undefined && product.lineNumber !== null) ? product.lineNumber : index + 1
            modifiedProduct.code = product.code
            if (product.tax !== null && product.quantity !== null && product.unitPrice !== null) {
                let total_amount = product.quantity * product.unitPrice
                let total_discount = product.discount
                if (product.discountInPercent && product.discountInPercent === true && !isGeneratedData) {
                    if (total_discount !== undefined && total_discount !== null) {
                        if (parseFloat(total_discount) > 0) {
                            modifiedProduct.discountPercent = modifiedProduct.discount
                            modifiedProduct.isDiscountInPercent = product.discountInPercent
                        }
                    }
                    if ((product.taxInfo !== null) && dataFromAPI.priceInclusiveOfTax) {
                        total_discount = (ComponentManager.getTaxInclusiveSubtotalItemPrice(product) * product.discount) / 100
                    } else {
                        total_discount = (total_amount * product.discount) / 100
                    }
                    if (modifiedProduct.isDiscountInPercent === undefined) {
                        modifiedProduct.discount = Number(product.discount)
                    }
                }
                modifiedProduct.priceInclusiveOfTax = (product.taxInfo !== null) && dataFromAPI.priceInclusiveOfTax
                if (modifiedProduct.isDiscountInPercent === undefined) {
                    modifiedProduct.discount = Number(product.discount)
                }
                modifiedProduct.tax = Number(product.tax)
                if (product.uom !== undefined) {
                    modifiedProduct.uom = product.uom
                }
                else {
                    modifiedProduct.uom = 'N/A'
                }
            }
            else { //expense invoice
                if(modifiedProduct.tax !== undefined) {
                    let taxAmount = Number(modifiedProduct.tax)
                    if (taxAmount !== null && taxAmount !== undefined) {
                        modifiedProduct.taxAmount = taxAmount
                    }
                    else {
                        modifiedProduct.tax = 0
                        if (modifiedProduct.taxInfo !== null && modifiedProduct.taxInfo !== undefined) {
                            modifiedProduct.tax = modifiedProduct.taxInfo.percent ? modifiedProduct.taxInfo.percent : 0
                        }
                        modifiedProduct.taxAmount = Number(modifiedProduct.amount) * (Number(modifiedProduct.tax) / 100)
                    }
                }

            }
            if (modifiedProduct.product !== undefined && modifiedProduct.product !== null) {
                if (modifiedProduct.product.images !== undefined && modifiedProduct.product.images !== null) {
                    if (modifiedProduct.product.images.length > 0) {
                        modifiedProduct.image = modifiedProduct.product.images[0]
                    }
                }
                if (modifiedProduct.product.advancedTracking !== undefined && modifiedProduct.product.advancedTracking !== null) {
                    advancedTracking = modifiedProduct.product.advancedTracking
                }
            }
            var referenceCode = undefined
            if (modifiedProduct.purchaseInvoiceItemCode !== undefined && modifiedProduct.purchaseInvoiceItemCode !== null) {
                referenceCode = modifiedProduct.purchaseInvoiceItemCode
            }
            else if (modifiedProduct.salesInvoiceItemCode !== undefined && modifiedProduct.salesInvoiceItemCode !== null) {
                referenceCode = modifiedProduct.salesInvoiceItemCode
            }

            if (modifiedProduct.documentUOMSchemaDefinitionPrint !== undefined && modifiedProduct.documentUOMSchemaDefinitionPrint !== null) {
                if (modifiedProduct.documentUOMSchemaDefinitionPrint.sourceConversionValue !== undefined &&
                    modifiedProduct.documentUOMSchemaDefinitionPrint.sourceUOMName !== undefined) {
                    modifiedProduct.alterUOM = '(' + modifiedProduct.documentUOMSchemaDefinitionPrint.sourceConversionValue + " " + modifiedProduct.documentUOMSchemaDefinitionPrint.sourceUOMName + ')'
                }
            }

            if(referenceCode !== undefined) {
                if (productTrackingInfo !== undefined && productTrackingInfo !== null) {
                    if (productTrackingInfo.length > 0) {
                        var serialBatch = []
                        var serialNumber = []
                        var batchNumber = []
                        var manufacturingDate = []
                        var expiryDate = []
                        productTrackingInfo.forEach(trackingItem => {
                            if (trackingItem.documentItemCode === referenceCode) {
                                if(advancedTracking !== undefined) {
                                    if (advancedTracking === 'BATCH') {
                                        batchNumber.push(trackingItem.serialBatchNumber)
                                    }
                                    else if (advancedTracking === 'SERIAL') {
                                        serialNumber.push(trackingItem.serialBatchNumber)
                                    }
                                }
                                if (trackingItem.manufacturingDate !== null && trackingItem.manufacturingDate !== undefined && trackingItem.manufacturingDate !== "") {
                                    manufacturingDate.push(getConvertedDate(convertDateFromERPFormatToDocFormat(trackingItem.manufacturingDate), dateFormat))
                                }
                                if (trackingItem.expiryDate !== null && trackingItem.expiryDate !== undefined && trackingItem.expiryDate !== "") {
                                    serialBatch.push(trackingItem.serialBatchNumber + ' (Exp dt: ' + getConvertedDate(convertDateFromERPFormatToDocFormat(trackingItem.expiryDate), dateFormat) + ')')
                                    expiryDate.push(getConvertedDate(convertDateFromERPFormatToDocFormat(trackingItem.expiryDate), dateFormat))
                                }
                                else {
                                    serialBatch.push(trackingItem.serialBatchNumber)
                                }
                            }
                        });

                        modifiedProduct.serialBatch = ''
                        modifiedProduct.serialNumber = ''
                        modifiedProduct.batchNumber = ''
                        modifiedProduct.manufacturingDate = ''
                        modifiedProduct.expiryDate = ''

                        if (serialBatch.length > 0) {
                            modifiedProduct.serialBatch = serialBatch.join('\n')
                        }
                        if (serialNumber.length > 0) {
                            modifiedProduct.serialNumber = serialNumber.join('\n')
                        }
                        if (batchNumber.length > 0) {
                            modifiedProduct.batchNumber = batchNumber.join('\n')
                        }
                        if (manufacturingDate.length > 0) {
                            modifiedProduct.manufacturingDate = manufacturingDate.join('\n')
                        }
                        if (expiryDate.length > 0) {
                            modifiedProduct.expiryDate = expiryDate.join('\n')
                        }
                    }
                }
            }
            return modifiedProduct
        })
        return lineItems
    }

    static getAccountItem(data) {
        if (data.lineItems === undefined || data.lineItems.lineItems === null) {
            return []
        }

        var accountLineItems = []
        data.lineItems.forEach((element, index) => {
            var accountLineItem = element
            accountLineItem.lineNumber = index + 1
            accountLineItem.name = (element.name !== null) ? element.name : ''
            accountLineItem.description = (element.description !== null) ? element.description : ''
            accountLineItem.amount = (element.amount !== null) ? element.amount : 0.00

            if (element.tax !== null && element.tax !== undefined) {
                accountLineItem.taxAmount = element.tax
            }
            else {
                accountLineItem.tax = 0
                if (element.taxInfo !== null && element.taxInfo !== undefined) {
                    accountLineItem.tax = element.taxInfo.percent ? element.taxInfo.percent : 0
                }
                accountLineItem.taxAmount = Number(accountLineItem.amount) * (Number(accountLineItem.tax) / 100)
            }

            var priceInclusiveOfTax = false
            if (data.priceInclusiveOfTax !== undefined && data.priceInclusiveOfTax !== null) {
                if(data.priceInclusiveOfTax) {
                    priceInclusiveOfTax = data.priceInclusiveOfTax
                }
            }

            accountLineItem.paymentAmount = Number(accountLineItem.amount) + Number(accountLineItem.taxAmount)
            if (priceInclusiveOfTax) {
                accountLineItem.paymentAmount = Number(accountLineItem.amount)
            }
            accountLineItems.push(accountLineItem)
        });

        return accountLineItems
    }

    static getPaymentItem(data, type) {
        if (data.lineItems === undefined || data.lineItems.lineItems === null) {
            return []
        }

        var country = ''
        if (data.tenantInfo !== undefined && data.tenantInfo !== null) {
            if (data.tenantInfo.country !== undefined && data.tenantInfo.country !== null) {
                country = data.tenantInfo.country
            }
        }

        var paymentLineItems = []
        data.lineItems.forEach((element, index) => {
            var paymentLineItem = element
            paymentLineItem.lineNumber = index + 1
            paymentLineItem.snNumber = data.refNumber
            var parentDocumentTotalAmount = 0
            var parentDocumentDueAmount = 0
            var parentDocumentCurrency = undefined
            if (data.parentDocument !== undefined && data.parentDocument !== null) {
                if (NewDataParser.getDefaultValue(data.parentDocument.totalAmount) !== '') {
                    parentDocumentTotalAmount = NewDataParser.getDefaultValue(data.parentDocument.totalAmount)
                }
                if (NewDataParser.getDefaultValue(data.parentDocument.dueAmount) !== '') {
                    parentDocumentDueAmount = NewDataParser.getDefaultValue(data.parentDocument.dueAmount)
                }
                parentDocumentCurrency = data.parentDocument.currency
            }
            if (getIsMakePayment(type)) {
                paymentLineItem.billNumber = NewDataParser.getDefaultValue(element.documentSequenceCode)
                paymentLineItem.totalBillAmount = parentDocumentTotalAmount
                paymentLineItem.currentBalance = parentDocumentDueAmount
            }
            if (getIsReceivePayment(type)) {
                paymentLineItem.invoiceNumber = NewDataParser.getDefaultValue(element.documentSequenceCode)
                paymentLineItem.totalInvoiceAmount = parentDocumentTotalAmount
                paymentLineItem.currentDueAmount = parentDocumentDueAmount
            }
            paymentLineItem.parentDocumentCurrency = parentDocumentCurrency
            paymentLineItem.paymentMade = element.amount
            paymentLineItem.paymentMedium = Utility.getPaymentMethod(NewDataParser.getDefaultValue(data.paymentType), country)
            paymentLineItems.push(paymentLineItem)
        });
        return paymentLineItems
    }

    static getFulfillmentItem(data, dateFormat) {
        if (data.fulfillment === undefined || data.fulfillment === null) {
            return []
        }

        if (data.fulfillment.fulfillmentItems === undefined || data.fulfillment.fulfillmentItems === null) {
            return []
        }

        var fulfillmentItems = []
        data.fulfillment.fulfillmentItems.forEach((element, index) => {
            var fulfillmentItem = {}
            fulfillmentItem.lineNumber = index + 1
            fulfillmentItem.code = NewDataParser.getDefaultValue(element.documentSequenceCode)
            fulfillmentItem.name = NewDataParser.getDefaultValue(element.productName)
            fulfillmentItem.description = NewDataParser.getDefaultValue(element.productDescription)
            fulfillmentItem.uom = NewDataParser.getDefaultValue(element.uomName) !== '' ? NewDataParser.getDefaultValue(element.uomName) : 'N/A'
            fulfillmentItem.committedQuantity = NewDataParser.getDefaultValue(element.uomFulfilledQuantity)
            if (element.uomQuantityRequired !== undefined && element.uomQuantityRequired !== null) {
                fulfillmentItem.requiredQuantity = element.uomQuantityRequired
            }
            else {
                var requiredQuantity = 0
                if (element.uomFulfilledQuantity !== undefined && element.uomFulfilledQuantity !== null) {
                    requiredQuantity = requiredQuantity + parseFloat(element.uomFulfilledQuantity)
                }
                if (element.uomPendingQuantity !== undefined && element.uomPendingQuantity !== null) {
                    requiredQuantity = requiredQuantity + parseFloat(element.uomPendingQuantity)
                }
                fulfillmentItem.requiredQuantity = requiredQuantity
            }
            // fulfillmentItem.requiredQuantity = element.uomQuantityRequired !== null ? NewDataParser.getDefaultValue(element.uomQuantityRequired) : NewDataParser.getDefaultValue(element.uomFulfilledQuantity)
            fulfillmentItem.warehouseCode = this.getWareHouseCode(element)
            fulfillmentItem.warehouseName = this.getWareHouseName(element)
            fulfillmentItem.customFields = element.customField
            if (element.trackingInformation !== undefined && element.trackingInformation !== null) {
                if (element.trackingInformation.length > 0) {
                    var serialBatch = []
                    var serialNumber = []
                    var batchNumber = []
                    var manufacturingDate = []
                    var expiryDate = []

                    element.trackingInformation.forEach(item => {
                        var serialBatchNumber = item.serialBatchNumber
                        if (item.expiryDate !== null && item.expiryDate !== undefined) {
                            serialBatchNumber = item.serialBatchNumber + ' (Exp dt: ' + getConvertedDate(convertDateFromERPFormatToDocFormat(item.expiryDate), dateFormat) + ')'
                            expiryDate.push(getConvertedDate(convertDateFromERPFormatToDocFormat(item.expiryDate), dateFormat))
                        }
                        serialBatch.push(serialBatchNumber)

                        if (element.advancedTrackingType === 'BATCH') {
                            batchNumber.push(item.serialBatchNumber)
                        }
                        else if (element.advancedTrackingType === 'SERIAL') {
                            serialNumber.push(item.serialBatchNumber)
                        }

                        if (item.manufacturingDate !== null && item.manufacturingDate !== undefined && item.manufacturingDate !== "") {
                            manufacturingDate.push(getConvertedDate(convertDateFromERPFormatToDocFormat(item.manufacturingDate), dateFormat))
                        }
                    });

                    fulfillmentItem.advancedTrackingType = element.advancedTrackingType

                    if (serialBatch.length > 0) {
                        fulfillmentItem.serialBatch = serialBatch.join('\n')
                    }
                    if (serialNumber.length > 0) {
                        fulfillmentItem.serialNumber = serialNumber.join('\n')
                    }
                    if (batchNumber.length > 0) {
                        fulfillmentItem.batchNumber = batchNumber.join('\n')
                    }
                    if (manufacturingDate.length > 0) {
                        fulfillmentItem.manufacturingDate = manufacturingDate.join('\n')
                    }
                    if (expiryDate.length > 0) {
                        fulfillmentItem.expiryDate = expiryDate.join('\n')
                    }
                }
            }
            fulfillmentItems.push(fulfillmentItem)
        });

        return fulfillmentItems
    }

    static getGoodsReceiptItem(data) {
        if (data.goodsReceipt === undefined || data.goodsReceipt === null) {
            return []
        }

        if (data.goodsReceipt.items === undefined || data.goodsReceipt.items === null) {
            return []
        }

        var goodsReceiptItems = []
        data.goodsReceipt.items.forEach((element, index) => {
            var goodsReceiptItem = {}
            goodsReceiptItem.lineNumber = index + 1
            goodsReceiptItem.code = NewDataParser.getDefaultValue(element.documentSequenceCode)
            goodsReceiptItem.name = NewDataParser.getDefaultValue(element.productName)
            if (data.parentDocumentData !== undefined && data.parentDocumentData !== null) {
                goodsReceiptItem.orderNo = NewDataParser.getDefaultValue(data.parentDocumentData.documentCode)
            }
            goodsReceiptItem.description = NewDataParser.getDefaultValue(element.productDescription)
            goodsReceiptItem.warehouseCode = this.getWareHouseCode(element)
            goodsReceiptItem.warehouseName = this.getWareHouseName(element)
            goodsReceiptItem.uom = NewDataParser.getDefaultValue(element.uomName) !== '' ? NewDataParser.getDefaultValue(element.uomName) : 'N/A'
            goodsReceiptItem.receivedQuantity = NewDataParser.getDefaultValue(element.uomReceiptQuantity)
            if (element.uomQuantityRequired !== undefined && element.uomQuantityRequired !== null) {
                goodsReceiptItem.requiredQuantity = element.uomQuantityRequired
            }
            else {
                var requiredQuantity = 0
                if (element.uomReceiptQuantity !== undefined && element.uomReceiptQuantity !== null) {
                    requiredQuantity = requiredQuantity + parseFloat(element.uomReceiptQuantity)
                }
                if (element.uomPendingQuantity !== undefined && element.uomPendingQuantity !== null) {
                    requiredQuantity = requiredQuantity + parseFloat(element.uomPendingQuantity)
                }
                goodsReceiptItem.requiredQuantity = requiredQuantity
            }
            // goodsReceiptItem.requiredQuantity = element.uomQuantityRequired !== null ? NewDataParser.getDefaultValue(element.uomQuantityRequired) : NewDataParser.getDefaultValue(element.uomReceiptQuantity)
            goodsReceiptItem.customFields = element.customField
            goodsReceiptItems.push(goodsReceiptItem)
        });

        return goodsReceiptItems
    }

    static getWareHouseCode(item) {
        if (item.warehouseCode !== undefined && item.warehouseCode !== null) {
            return item.warehouseCode
        }
        if (item.warehouseInventoryData !== undefined && item.warehouseInventoryData !== null ) {
            if (item.warehouseInventoryData.length > 0) {
                return item.warehouseInventoryData[0]['warehouseCode']
            }
        }
        return ''
    }

    static getWareHouseName(item) {
        if (item.warehouseInventoryData !== undefined && item.warehouseInventoryData !== null) {
            if (item.warehouseInventoryData.length > 0) {
                if (item.warehouseInventoryData[0]['warehouseName'] !== undefined) {
                    return item.warehouseInventoryData[0]['warehouseName']
                }
            }
        }
        return ''
    }

    static getJournalEntryItem(data, dateFormat) {
        if (data.journalEntry === undefined || data.journalEntry === null) {
            return []
        }

        if (data.journalEntry.lineItems === undefined || data.journalEntry.lineItems === null) {
            return []
        }

        var journalEntryNumber = ''
        var journalEntryDate = ''

        if (data.journalEntry.jeCode !== undefined && data.journalEntry.jeCode !== null) {
            journalEntryNumber = data.journalEntry.jeCode
        }

        if (data.journalEntry.jeDate !== undefined && data.journalEntry.jeDate !== null) {
            journalEntryDate = data.journalEntry.jeDate
        }

        var sortedJournalEntryList = data.journalEntry.lineItems

        var journalEntryList_debit = []
        var journalEntryList_credit = []

        data.journalEntry.lineItems.forEach(element => {
            if (element.cdType === 'CREDIT') {
                journalEntryList_credit.push(element)
            }
            else if (element.cdType === 'DEBIT') {
                journalEntryList_debit.push(element)
            }
        });

        if (journalEntryList_credit.length > 0 || journalEntryList_debit.length > 0) {
            sortedJournalEntryList = []
            if (journalEntryList_debit.length > 0) {
                journalEntryList_debit.forEach(element => {
                    sortedJournalEntryList.push(element)
                });
            }
            if (journalEntryList_credit.length > 0) {
                journalEntryList_credit.forEach(element => {
                    sortedJournalEntryList.push(element)
                });
            }
        }

        var journalEntryItems = []
        sortedJournalEntryList.forEach((element, index) => {
            var journalEntryItem = {}
            journalEntryItem.lineNumber = index + 1
            journalEntryItem.date = journalEntryDate
            journalEntryItem.journalEntryNumber = journalEntryNumber
            journalEntryItem.accountCode = NewDataParser.getDefaultValue(element.code)
            journalEntryItem.accountName = NewDataParser.getDefaultValue(element.accountName)
            journalEntryItem.description = NewDataParser.getDefaultValue(element.description)
            journalEntryItem.credit = 0
            journalEntryItem.debit = 0
            journalEntryItem.customField = element.customField
            if (element.cdType !== undefined && element.cdType !== null
                & element.amount !== undefined && element.amount !== null) {
                if (element.cdType === 'CREDIT') {
                    journalEntryItem.credit = element.amount
                }
                else if (element.cdType === 'DEBIT') {
                    journalEntryItem.debit = element.amount
                }
            }

            journalEntryItems.push(journalEntryItem)
        });


        return journalEntryItems
    }

    static getPickItem(data) {
        if (this.getPayloadData(data) === undefined) {
            return []
        }

        if (this.getPayloadData(data).items === undefined || this.getPayloadData(data).items === null) {
            return []
        }

        var pickingItems = []
        this.getPayloadData(data).items.forEach((element, index) => {
            var pickingItem = {}
            pickingItem.lineNumber = index + 1
            pickingItem.invoiceOrQuotation = element.orderNo
            pickingItem.code = element.productCode
            pickingItem.name = NewDataParser.getDefaultValue(element.name)
            pickingItem.barcode = NewDataParser.getDefaultValue(element.barcode)
            pickingItem.quantityToPick = element.lq
            pickingItem.quantityForOrder = element.quantity
            pickingItem.picked = ''
            if (element.isPicked !== undefined && element.isPicked !== null) {
                pickingItem.picked = element.isPicked.toLowerCase() === 'yes' ? true : false
            }

            pickingItems.push(pickingItem)
        });

        return pickingItems
    }

    static getPackItem(data) {
        if (this.getPayloadData(data) === undefined) {
            return []
        }

        if (this.getPayloadData(data).items === undefined || this.getPayloadData(data).items === null) {
            return []
        }

        var packingItems = []
        this.getPayloadData(data).items.forEach((element, index) => {
            var packingItem = {}
            packingItem.lineNumber = index + 1
            packingItem.id = element.id
            packingItem.code = element.productCode
            packingItem.name = NewDataParser.getDefaultValue(element.name)
            packingItem.barcode = NewDataParser.getDefaultValue(element.barcode)
            packingItem.unit = element.uom
            packingItem.packedQuantity = element.paq
            packingItem.serialBatch = this.getSerialBatchNo(element)
            packingItems.push(packingItem)
        });

        return packingItems
    }

    static getSerialBatchNo(element) {
        return element?.advancedTracking?.map(advTrk=>advTrk.serialBatchNumber)?.join('\n') || ''
    }
    
    static getShipItem(data) {
        if (this.getPayloadData(data) === undefined) {
            return []
        }

        var item = this.getPayloadData(data)
        var shipItem = {
            delivery: item.delivery,
            carrier: item.carrier,
            numberOfCarton: item.n,
            weight: item.weight,
            trackingNumber: item.trackingNo
        }
        return [shipItem]
    }

    static getChequeItem(data, dateFormat) {
        var items = []
        if(data.chequePayload === undefined || data.chequePayload === null) {
            return items
        }

        if(data.chequePayload.length === 0) {
            return items
        }

        data.chequePayload.forEach((element, index) => {
            var item = {}
            item.lineNumber = index
            item.payTo = element.clientName ? element.clientName  : ''
            // item.date = getConvertedDate(element.date, dateFormat)
            item.dateFormat = element.dateFormat.toLowerCase()
            // item.date = getDateString(getDateFrom(element.date, element.dateFormat.toLowerCase()), dateFormat)
            item.date = getDateString(getDateFrom(element.date, DateFormat.DD_MM_YYYY_DASH), element.dateFormat.toLowerCase())
            item.amountInWords = element.amountInWords
            item.amount = element.amount
            item.signature = item.payTo // + '<br/>' + element.companyName
            items.push(item)
        });
        return items
    }

    static getPayloadData(data) {
        if (data.payload === undefined || data.payload === null) {
            return undefined
        }

        if (data.payload.length === 0) {
            return undefined
        }

        return data.payload[0]
    }

    static getWorkOrderItems(data, dateFormat) {
        var items = []
        var rawItems = []
        if(data.workOrderResponse !== undefined && data.workOrderResponse !== null) {
            if(data.workOrderResponse.workOrderItems !== undefined && data.workOrderResponse.workOrderItems !== null) {
                rawItems = data.workOrderResponse.workOrderItems
            }
        }

        if(rawItems.length > 0) {
            rawItems.forEach((element, index) => {
                var item = {}
                var productName = element.productName
                var documentSequenceCode = element.productDocSeqCode
                item.canHideProductName = false
                var productSubstitutes = []
                var productSubstitutesCode = []
                var allProductSubstitutes = []
                var allProductSubstitutesCode = []
                if (element.productSubstitutesDetails !== undefined && element.productSubstitutesDetails !== null) {
                    if (element.productSubstitutesDetails.length > 0) {
                        element.productSubstitutesDetails.forEach(element => {
                            if (element.warehouseInventoryData !== undefined && element.warehouseInventoryData !== null) {
                                if (element.warehouseInventoryData.length > 0) {
                                    var sum = 0
                                    element.warehouseInventoryData.forEach(element => {
                                        sum = element.quantity + sum
                                    });

                                    if(sum > 0) {
                                        productSubstitutes.push(element.productName)
                                        productSubstitutesCode.push(element.productDocSeqCode)
                                    }
                                }
                            } 
                            allProductSubstitutes.push(element.productName)
                            allProductSubstitutesCode.push(element.productDocSeqCode)
                        });
                    }

                    if (allProductSubstitutes.length > 0) {
                        item.canHideProductName = true
                        // if(this.getIsContainsWOWarehouseInventoryData(element)) {
                        //     productName = productName + '<br/>' + productSubstitutes.join('<br/>') 
                        //     documentSequenceCode = documentSequenceCode + '<br/>' + productSubstitutesCode.join('<br/>') 
                        // }
                        // else {
                        //     productName = productSubstitutes.join('<br/>') 
                        //     documentSequenceCode = productSubstitutesCode.join('<br/>') 
                        // }
                        
                    }
                    else if (this.getIsContainsWOWarehouseInventoryData(element)) {
                        item.canHideProductName = true
                    }
                    
                }

                item.name = productName
                item.code = documentSequenceCode
                item.lineNumber = index + 1
                item.customFields = element.productCustomField
                item.productSubstitutes = productSubstitutes
                item.productSubstitutesCode = productSubstitutesCode
                item.allProductSubstitutes = allProductSubstitutes
                item.allProductSubstitutesCode = allProductSubstitutesCode
                item.produceProductType = element.produceProductType
                items.push(item)
            });
        }

        return items
    }

    static getIsContainsWOWarehouseInventoryData(item) {
        var isContain = false

        if (item.warehouseInventoryData !== undefined && item.warehouseInventoryData !== null) {
            isContain = item.warehouseInventoryData.length > 0
        }
        return isContain
    }

    static getStockAdjustmentItems(data, dateFormat) {
        var items = []
        var rawItems = []
        if (data.stockAdjustmentInfo !== undefined && data.stockAdjustmentInfo !== null) {
            if (data.stockAdjustmentInfo.stockAdjustmentItems !== undefined && data.stockAdjustmentInfo.stockAdjustmentItems !== null) {
                rawItems = data.stockAdjustmentInfo.stockAdjustmentItems
            }
        }
        if (rawItems.length > 0) {
            rawItems.forEach((element, index) => {
                var item = element
                item.name = element.productName
                item.code = element.productVariantCode
                item.description = (element.productDescription !== undefined && element.productDescription !== null) ? element.productDescription : ''
                item.lineNumber = index + 1
                item.customFields = element.productCustomField
                item.quantity = element.quantity
                if (element.uomQuantity !== undefined && element.uomQuantity !== null) {
                    if (element.uomQuantity > 0) {
                        item.quantity = element.uomQuantity
                    }
                }
                if (element.documentUOMSchemaDefinition?.name !== undefined) {
                    item.uom = element.documentUOMSchemaDefinition?.name 
                }
                else {
                    item.uom = 'N/A'
                }
                
                item.accountName = ''
                if (element.stockAdjustmentAccountName !== undefined && element.stockAdjustmentAccountName !== null) {
                    item.accountName = element.stockAdjustmentAccountName
                }

                item.accountCode = ''
                if (element.stockAdjustmentAccountCode !== undefined && element.stockAdjustmentAccountCode !== null) {
                    item.accountCode = element.stockAdjustmentAccountCode
                }
                item = this.getStockAdjustmentSerialBatchInfo(data, item, dateFormat)
                items.push(item)
            });
        }

        return items
    }

    static getStockAdjustmentSerialBatchInfo(data, item, dateFormat) {
        
        let advancedTrackingMetaDtoList = []

        if (data.stockAdjustmentInfo !== undefined && data.stockAdjustmentInfo !== null) {
            if (data.stockAdjustmentInfo.advancedTrackingMetaDtoList !== undefined && data.stockAdjustmentInfo.advancedTrackingMetaDtoList !== null) {
                advancedTrackingMetaDtoList = data.stockAdjustmentInfo.advancedTrackingMetaDtoList
            }
        }
        if (advancedTrackingMetaDtoList.length > 0) {
            var advancedTracking = advancedTrackingMetaDtoList.filter(x => x.productVariantCode === item.productVariantCode)
            if (advancedTracking.length > 0) {
                var serialBatch = []
                var serialNumber = []
                var batchNumber = []
                var manufacturingDate = []
                var expiryDate = []

                advancedTracking.forEach(element => {
                    batchNumber.push(element.serialBatchNumber)
                    serialNumber.push(element.serialBatchNumber)

                    if (element.manufacturingDate !== null && element.manufacturingDate !== undefined && element.manufacturingDate !== "") {
                        manufacturingDate.push(getConvertedDate(convertDateFromERPFormatToDocFormat(element.manufacturingDate), dateFormat))
                    }
                    if (element.expiryDate !== null && element.expiryDate !== undefined && element.expiryDate !== "") {
                        serialBatch.push(element.serialBatchNumber + ' (Exp dt: ' + getConvertedDate(convertDateFromERPFormatToDocFormat(element.expiryDate), dateFormat) + ')')
                        expiryDate.push(getConvertedDate(convertDateFromERPFormatToDocFormat(element.expiryDate), dateFormat))
                    }
                    else {
                        serialBatch.push(element.serialBatchNumber)
                    }
                });

                item.serialBatch = ''
                item.serialNumber = ''
                item.batchNumber = ''
                item.manufacturingDate = ''
                item.expiryDate = ''

                if (serialBatch.length > 0) {
                    item.serialBatch = serialBatch.join('\n')
                }
                if (serialNumber.length > 0) {
                    item.serialNumber = serialNumber.join('\n')
                }
                if (batchNumber.length > 0) {
                    item.batchNumber = batchNumber.join('\n')
                }
                if (manufacturingDate.length > 0) {
                    item.manufacturingDate = manufacturingDate.join('\n')
                }
                if (expiryDate.length > 0) {
                    item.expiryDate = expiryDate.join('\n')
                }
            }
        }
        return item
    }

    static getStockTransferItems(data, dateFormat) {
        var items = []
        var rawItems = []
        if (data.stockTransferInfo !== undefined && data.stockTransferInfo !== null) {
            if (data.stockTransferInfo.stockTransferItems !== undefined && data.stockTransferInfo.stockTransferItems !== null) {
                rawItems = data.stockTransferInfo.stockTransferItems
            }
        }
        if (rawItems.length > 0) {
            rawItems.forEach((element, index) => {
                var item = element
                item.name = element.productName
                item.code = element.productVariantCode
                item.description = (element.productDescription !== undefined && element.productDescription !== null) ? element.productDescription : ''
                item.lineNumber = index + 1
                item.customFields = element.productCustomField
                item.quantity = element.quantity
                if (element.documentUOMSchemaDefinition?.name !== undefined) {
                    item.uom = element.documentUOMSchemaDefinition?.name
                }
                else {
                    item.uom = 'N/A'
                }

                item.accountName = ''
                if (element.stockAdjustmentAccountName !== undefined && element.stockAdjustmentAccountName !== null) {
                    item.accountName = element.stockAdjustmentAccountName
                }

                item.accountCode = ''
                if (element.stockAdjustmentAccountCode !== undefined && element.stockAdjustmentAccountCode !== null) {
                    item.accountCode = element.stockAdjustmentAccountCode
                }

                item = this.getStockTransferSerialBatchInfo(item, dateFormat)
                items.push(item)
            });
        }

        return items
    }

    static getStockTransferSerialBatchInfo(item, dateFormat) {
        if (item.stockTransferWarehouseInventoryData !== undefined && item.stockTransferWarehouseInventoryData !== null ) {
            var serialBatch = []
            var serialNumber = []
            var batchNumber = []
            var manufacturingDate = []
            var expiryDate = []
            let advancedTrackingType = item.stockTransferWarehouseInventoryData.advancedTrackingType
            if(advancedTrackingType !== undefined && advancedTrackingType !== null) {
                let advancedTrackingData = item.stockTransferWarehouseInventoryData.advancedTrackingData
                if (advancedTrackingData !== undefined && advancedTrackingData !== null) {
                    if (advancedTrackingData.length > 0) {
                        advancedTrackingData.forEach(element => {
                            if (advancedTrackingType !== undefined && advancedTrackingType !== null) {
                                if (advancedTrackingType === 'BATCH') {
                                    batchNumber.push(element.destSerialBatchNumber)
                                }
                                else if (advancedTrackingType === 'SERIAL') {
                                    serialNumber.push(element.destSerialBatchNumber)
                                }
                            }
                            if (element.manufacturingDate !== null && element.manufacturingDate !== undefined && element.manufacturingDate !== "") {
                                manufacturingDate.push(getConvertedDate(convertDateFromERPFormatToDocFormat(element.manufacturingDate), dateFormat))
                            }
                            if (element.expiryDate !== null && element.expiryDate !== undefined && element.expiryDate !== "") {
                                serialBatch.push(element.destSerialBatchNumber + ' (Exp dt: ' + getConvertedDate(convertDateFromERPFormatToDocFormat(element.expiryDate), dateFormat) + ')')
                                expiryDate.push(getConvertedDate(convertDateFromERPFormatToDocFormat(element.expiryDate), dateFormat))
                            }
                            else {
                                serialBatch.push(element.destSerialBatchNumber)
                            }
                        });
                    }
                }
            }
            item.serialBatch = ''
            item.serialNumber = ''
            item.batchNumber = ''
            item.manufacturingDate = ''
            item.expiryDate = ''

            if (serialBatch.length > 0) {
                item.serialBatch = serialBatch.join('\n')
            }
            if (serialNumber.length > 0) {
                item.serialNumber = serialNumber.join('\n')
            }
            if (batchNumber.length > 0) {
                item.batchNumber = batchNumber.join('\n')
            }
            if (manufacturingDate.length > 0) {
                item.manufacturingDate = manufacturingDate.join('\n')
            }
            if (expiryDate.length > 0) {
                item.expiryDate = expiryDate.join('\n')
            }
        }

        return item
    }
}
