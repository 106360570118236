import {
  COUNTRY_CODES,
  COUNTRY_SPECIFIC_URL_CODE
} from '../constants/Constant';
import { ENDPOINTS } from '../constants/EndPoints';
import { ISaleOrder } from '../models/Order';
import store from '../store';
import httpClient from './';
export const getQuotes = (params: {}) => {
  return httpClient.get(ENDPOINTS.QUOTES.GET, { params });
};

export const getInvoices = (params: {}) => {
  return httpClient.get(ENDPOINTS.INVOICES.GET, { params });
};

export const getInvoiceSummary = (params: {}) => {
  return httpClient.get(ENDPOINTS.INVOICES.SUMMARY, { params });
};

export const getPaymentReceipt = (params: {}) => {
  return httpClient.get(ENDPOINTS.PAYMENTS.GET, { params });
};
export const getPrintTemplateInfo = (payload: any) => {
  return httpClient.post(ENDPOINTS.PRINT.GET_PRINT_INFO, payload);
};

export const getPrintTemplateInfoById = (params: any) => {
  return httpClient.get(ENDPOINTS.PRINT.GET_PRINT_INFO, { params });
};

export const getPrintDesignInfoById = (id: string, params: any) => {
  return httpClient.get(ENDPOINTS.PRINT.PRINT_DESIGN_DOC_BY_ID(id), {
    params,
    responseType: 'arraybuffer'
  });
};

export const getSalesOrders = (params: any = {}) => {
  return httpClient.get(ENDPOINTS.SALES_ORDERS.GET('in'), { params });
};

export const getPurchaseOrders = (params: any = {}) => {
  return httpClient.get(ENDPOINTS.PURCHASE_ORDERS.GET, { params });
};

export const getProducts = (params = {}) => {
  return httpClient.get(ENDPOINTS.PRODUCT.GET, { params });
};
export const fetchCurrentContactInfo = (params = {}) => {
  const tenantSpecificURL = getTenantSpecificApiCode('CONTACT');
  return httpClient.get(
    ENDPOINTS.CONTACT.GET_LOGGED_IN_USER(tenantSpecificURL),
    { params }
  );
};

export const createSalesOrder = (payload: ISaleOrder) => {
  const tenantSpecificURL = getTenantSpecificApiCode('SALES_ORDER');
  return httpClient.post(
    ENDPOINTS.SALES_ORDERS.CREATE(tenantSpecificURL),
    payload
  );
};

export const getTenantDetails = () => {
  return httpClient.get(ENDPOINTS.TENANT.DETAILS);
};

export const getTenantSpecificApiCode = (
  moduleName: 'CONTACT' | 'SALES_ORDER' | 'PRODUCT'
): string => {
  let countrySpecificURL = '';

  const countryCode = store.getState().books.tenantDetails?.country;

  switch (countryCode) {
    case COUNTRY_CODES.IN:
      if (
        moduleName === 'CONTACT' ||
        moduleName === 'PRODUCT' ||
        moduleName === 'SALES_ORDER'
      ) {
        countrySpecificURL = COUNTRY_SPECIFIC_URL_CODE.IN;
      }
      break;
    case COUNTRY_CODES.IL:
      if (moduleName === 'CONTACT') {
        countrySpecificURL = COUNTRY_SPECIFIC_URL_CODE.IL;
      }
      break;
    case COUNTRY_CODES.US:
      break;

    case COUNTRY_CODES.SG:
      break;

    case COUNTRY_CODES.ID:
      if (moduleName === 'CONTACT') {
        countrySpecificURL = COUNTRY_SPECIFIC_URL_CODE.INDONESIA;
      }
      break;
    case COUNTRY_CODES.MY:
      if (moduleName === 'CONTACT' || moduleName === 'PRODUCT') {
        countrySpecificURL = COUNTRY_SPECIFIC_URL_CODE.MALAYSIA;
      }
      break;

    case COUNTRY_CODES.PH:
      break;

    case COUNTRY_CODES.NZ:
      break;

    case COUNTRY_CODES.DE:
      break;

    case COUNTRY_CODES.AE:
      if (moduleName === 'CONTACT') {
        countrySpecificURL = COUNTRY_SPECIFIC_URL_CODE.UAE;
      }
      break;
    case COUNTRY_CODES.UK:
      if (moduleName === 'CONTACT' || moduleName === 'PRODUCT') {
        countrySpecificURL = COUNTRY_SPECIFIC_URL_CODE.UK;
      }
      break;
    case COUNTRY_CODES.BE:
      break;
    case COUNTRY_CODES.AU:
      break;
    case COUNTRY_CODES.CA:
      break;

    default:
      break;
  }
  return countrySpecificURL;
};
