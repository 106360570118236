import CustomFieldManager from "../../Manager/CustomFieldManager";
import { getCapitalized, getDateString } from "../../Utilities/Utility";
import Address from "../Address";
import AddressParser, { AddressType, OwnerType } from "../AddressParser";
import DataParser from "../DataParser";
import LineItemsParser from "../LineItemsParser";

export default class StockTransferParser {
    static parseData(dataFromAPI, templateDataToReturn) {

        //custom field
        templateDataToReturn.customFields = dataFromAPI.stockTransferInfo?.customField ? dataFromAPI.stockTransferInfo?.customField : []
        CustomFieldManager.setCustomFields(templateDataToReturn.customFields)
        templateDataToReturn = DataParser.assignCustomFieldValue(templateDataToReturn)

        //company name
        templateDataToReturn.companyName = getCapitalized(dataFromAPI.tenantInfo?.name)
        templateDataToReturn.tenantInfo = dataFromAPI.tenantInfo;

        //company address
        var fromAddressObj = new Address(undefined, AddressParser.getTenantAddressFromApi(dataFromAPI.shipFromAddress, dataFromAPI.tenantInfo), dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
        templateDataToReturn.fromObj = fromAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.from, templateDataToReturn)


        templateDataToReturn = this.getWarehouse(dataFromAPI, templateDataToReturn)
        //ref number
        templateDataToReturn.refNumber = dataFromAPI.stockTransferInfo?.code

        let startDate = ''
        let startDateTimeStamp = dataFromAPI.stockTransferInfo?.transferDate ? dataFromAPI.stockTransferInfo?.transferDate : ''
        if (startDateTimeStamp !== '') {
            startDate = getDateString(new Date(startDateTimeStamp), templateDataToReturn.dateFormat)
        }
        templateDataToReturn.date = startDate

        templateDataToReturn.warehouse = dataFromAPI.stockTransferInfo?.warehouseCode

        //line items
        templateDataToReturn.lineItems = LineItemsParser.getStockTransferItems(dataFromAPI, templateDataToReturn.dateFormat)

        templateDataToReturn.notes = dataFromAPI.stockTransferInfo?.notes
        return templateDataToReturn
    }

    static getWarehouse(dataFromAPI, templateDataToReturn) {
        var item = undefined
        if (dataFromAPI.stockTransferInfo !== undefined && dataFromAPI.stockTransferInfo !== null) {
            if (dataFromAPI.stockTransferInfo.stockTransferItems !== undefined && dataFromAPI.stockTransferInfo.stockTransferItems !== null) {
                if(dataFromAPI.stockTransferInfo.stockTransferItems.length > 0) {
                    item = dataFromAPI.stockTransferInfo.stockTransferItems[0]
                }
            }
        }

        if(item !== undefined) {
            if (item.stockTransferWarehouseInventoryData !== undefined && item.stockTransferWarehouseInventoryData !== null) {
                const srcWarehouseName = item.stockTransferWarehouseInventoryData.srcWarehouseName

                if(srcWarehouseName !== undefined) {
                    templateDataToReturn.clientBillToAddress = srcWarehouseName
                }

                const destWarehouseName = item.stockTransferWarehouseInventoryData.destWarehouseName

                if (srcWarehouseName !== undefined) {
                    templateDataToReturn.clientShipToAddress = destWarehouseName
                }
            }
        }
        return templateDataToReturn
    }
}
