import { TableColumnPopupType } from "../../../Components/Popup/TableColumnPopup";
import CustomFieldManager from "../../../Manager/CustomFieldManager";
import TemplateSettingsManager from "../../../Manager/TemplateSettingsManager";
import { getLocalisedText } from "../../../Translate/LanguageManager";
import TableFooterUtility from "../../../Utilities/TableFooterUtility";
import Utility from "../../../Utilities/Utility";
import { getSortedHeader } from "../TableUtility";

export class StockTransferTableItem {
    lineNumber: number;
    code: string;
    name: string;
    quantity: string;
    description: string;
    hsn: string;
    productCustomField: string;
    uom: string;
    quantityWithUom: string;
    serialBatch: string;
    serialNumber: string;
    batchNumber: string;
    manufacturingDate: string;
    expiryDate: string;
    srcWarehouseCode: string;
    srcWarehouseName: string;
    destWarehouseCode: string;
    destWarehouseName: string;
    currency: string;
    rawItemValue: any;
    data: any;
    constructor(lineItem: any, data: any, currency: string) {
        this.lineNumber = lineItem.lineNumber;
        this.code = lineItem.code;
        this.name = this.getProductName(data, lineItem);
        this.hsn = lineItem.hsnOrSacCode;
        this.quantity = lineItem.quantity;
        this.productCustomField = "";
        this.uom = this.getProductUOM(lineItem);
        this.quantityWithUom =
            lineItem.quantity + " x " + this.getProductUOM(lineItem);
        this.serialBatch = lineItem.serialBatch ? lineItem.serialBatch : "";
        this.serialNumber = lineItem.serialNumber ? lineItem.serialNumber : "";
        this.batchNumber = lineItem.batchNumber ? lineItem.batchNumber : "";
        this.manufacturingDate = lineItem.manufacturingDate
            ? lineItem.manufacturingDate
            : "";
        this.expiryDate = lineItem.expiryDate ? lineItem.expiryDate : "";
        this.description = this.getProductDescription(lineItem);
        // this.nameWithDescription = '<b>' + this.getProductName(data, lineItem) + '</b> \n' + this.getProductDescription(lineItem)
        this.rawItemValue = lineItem;
        this.srcWarehouseCode = this.getWarehouseData("srcWarehouseCode");
        this.srcWarehouseName = this.getWarehouseData("srcWarehouseName");
        this.destWarehouseCode = this.getWarehouseData("destWarehouseCode");
        this.destWarehouseName = this.getWarehouseData("destWarehouseName");
        this.currency = currency;
        this.data = data;
    }

    getProductName(data: any, lineItem: any): string {
        if (data.country === "SA") {
            if (
                lineItem.nameInArabic !== undefined &&
                lineItem.nameInArabic !== null
            ) {
                if (lineItem.nameInArabic !== "") {
                    return (
                        getLocalisedText(lineItem.name) +
                        "\n" +
                        getLocalisedText(lineItem.nameInArabic)
                    );
                }
            }
        }
        if (lineItem.name !== undefined && lineItem.name !== null) {
            return getLocalisedText(lineItem.name);
        }
        return "";
    }

    getProductDescription(lineItem: any): string {
        if (lineItem.description !== undefined && lineItem.description !== null) {
            return getLocalisedText(lineItem.description);
        }
        return "";
    }

    getProductUOM(lineItem: any): string {
        var uom = lineItem.uom ? lineItem.uom : "";

        if (TemplateSettingsManager.getIsAlterUOMSelected(undefined)) {
            if (lineItem.alterUOM !== undefined && lineItem.alterUOM !== null) {
                return uom + " " + lineItem.alterUOM;
            }
        }
        return uom;
    }

    getProductHSN(lineItem: any): string {
        if (TemplateSettingsManager.getIsHSNSelected(undefined)) {
            if (
                lineItem.hsnOrSacCodeString !== undefined &&
                lineItem.hsnOrSacCodeString !== null
            ) {
                return lineItem.hsnOrSacCodeString;
            }
        }
        return "";
    }

    getCombineProductCustomField() {
        var val = "";
        var productTableConfiguration = TemplateSettingsManager.defaultTemplateSettings.productTableConfiguration;
        
        if (productTableConfiguration !== undefined) {
            if (productTableConfiguration.isCombinesProductCustomField) {
                var productCustomFields =
                    CustomFieldManager.getActiveProductCustomFields();
                if (productCustomFields !== undefined) {
                    if (productCustomFields.length > 0) {
                        productCustomFields.forEach((element) => {
                            if (element.isSelected && element.code !== undefined) {
                                var field = this.getProductCustomFieldByCode(element.code);
                                if (field !== undefined) {
                                    val += element.label + ": <b>" + field.value + "</b>\n";
                                }
                            }
                        });
                    } else {
                        if (
                            productTableConfiguration.combinedProductCustomField !==
                            undefined &&
                            productTableConfiguration.combinedProductCustomField !== null
                        ) {
                            if (
                                productTableConfiguration.combinedProductCustomField.length > 0
                            ) {
                                productTableConfiguration.combinedProductCustomField.forEach(
                                    (element: any) => {
                                        if (element.isSelected && element.code !== undefined) {
                                            var field = this.getProductCustomFieldByCode(
                                                element.code
                                            );
                                            if (field !== undefined) {
                                                val += element.label + ": <b>" + field.value + "</b>\n";
                                            }
                                        }
                                    }
                                );
                            }
                        }
                    }
                }
            }
        }

        return val;
    }

    getProductCustomFieldByCode(code: string) {
        var customFields = this.rawItemValue.customFields;
        if (customFields === undefined || customFields === null) {
            return undefined;
        }

        if (customFields.length === 0) {
            return undefined;
        }

        return customFields.find((x: any) => x.code === code);
    }

    getWarehouseData(key: string) {
        if(this.rawItemValue.stockTransferWarehouseInventoryData !== undefined && this.rawItemValue.stockTransferWarehouseInventoryData !== null) {
            const warehouseData = this.rawItemValue.stockTransferWarehouseInventoryData

            if(warehouseData[key] !== undefined && warehouseData[key] !== null) {
                return warehouseData[key]
            }
        }

        return ''
    }

    toString(tableConfig?: any) {
        var sortedHeader = getSortedHeader(tableConfig);
        if (sortedHeader.length > 0) {
            var sortedValue: string[] = [];
            sortedHeader.forEach((element) => {
                var footerList = TableFooterUtility.getFooterListByType("product");
                if (!footerList.includes(element.type)) {
                    sortedValue.push(this.getValueByType(element));
                }
            });
            return sortedValue;
        }
        return this.getDefaultList();
    }

    getValueByType(element: any) {
        const type = element.type;
        switch (type) {
            case TableColumnPopupType.lineNumber:
                return this.lineNumber;
            case TableColumnPopupType.code:
                return getLocalisedText(this.code);
            case TableColumnPopupType.productName:
                return (
                    getLocalisedText(this.name) + this.getProductHSN(this.rawItemValue)
                );
            case TableColumnPopupType.description:
                return getLocalisedText(this.description);
            case TableColumnPopupType.productNameWithDescription:
                return (
                    "<b>" +
                    getLocalisedText(this.name) +
                    "</b> \n" +
                    getLocalisedText(this.description) +
                    this.getProductHSN(this.rawItemValue)
                );
            case TableColumnPopupType.hsn:
                return this.hsn ? this.hsn : "";
            case TableColumnPopupType.productCustomField:
                if (element.code !== undefined) {
                    var value = "";
                    var field = this.getProductCustomFieldByCode(element.code);
                    if (field !== undefined) {
                        value = field.value;
                    }
                    return value;
                }
                return this.getCombineProductCustomField();
            case TableColumnPopupType.quantity:
                return Utility.toDecimalFormat(
                    parseFloat(this.quantity),
                    TemplateSettingsManager.getQuantityDecimalScale()
                );
            case TableColumnPopupType.uom:
                return this.uom ? this.uom : "";
            case TableColumnPopupType.quantityWithUom:
                return this.quantityWithUom ? this.quantityWithUom : "";
            case TableColumnPopupType.serialBatch:
                return this.serialBatch ? this.serialBatch : "";
            case TableColumnPopupType.serialNumber:
                return this.serialNumber ? this.serialNumber : "";
            case TableColumnPopupType.batchNumber:
                return this.batchNumber ? this.batchNumber : "";
            case TableColumnPopupType.manufacturingDate:
                return this.manufacturingDate ? this.manufacturingDate : "";
            case TableColumnPopupType.expiryDate:
                return this.expiryDate ? this.expiryDate : "";
            case TableColumnPopupType.sourceWarehouseName:
                return this.srcWarehouseName ? this.srcWarehouseName : "";
            case TableColumnPopupType.sourceWarehouseCode:
                return this.srcWarehouseCode ? this.srcWarehouseCode : "";
            case TableColumnPopupType.destinationWarehouseName:
                return this.destWarehouseName ? this.destWarehouseName : "";
            case TableColumnPopupType.destinationWarehouseCode:
                return this.destWarehouseCode ? this.destWarehouseCode : "";
            default:
                return "";
        }
    }

    getDefaultList() {
        return [
            this.lineNumber,
            getLocalisedText(this.code),
            getLocalisedText(this.name),
            getLocalisedText(this.description),
            "<b>" +
            getLocalisedText(this.name) +
            "</b> \n" +
            getLocalisedText(this.description) +
            this.getProductHSN(this.rawItemValue),
            this.srcWarehouseName,
            this.srcWarehouseCode,
            this.destWarehouseName,
            this.destWarehouseCode,
            this.hsn,
            this.productCustomField,
            Utility.toDecimalFormat(
                parseFloat(this.quantity),
                TemplateSettingsManager.getQuantityDecimalScale()
            ),
            this.uom ? this.uom : "",
            this.quantityWithUom,
            this.serialBatch,
            this.serialNumber,
            this.batchNumber,
            this.manufacturingDate,
            this.expiryDate,
        ];
    }
}
