import React, { Component } from "react";
import "../../DocPreview.css";
import { getPageHeight, getVW } from "../../Utilities/Utility";
import "./css/Templates.css";

export default class PurchaseRequisitionTemplate1 extends Component {

    arabicHeaderWidth = '85%'

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ justifyContent: 'center', minHeight: getPageHeight(this.state.data.printScale)}}>
                <div className='parent-width' style={{ width: '100%', paddingBottom: getVW(30) }}>
                    Purchase Requisition Template 1
                </div>
            </div>
        );
    }
}
