export const PRODUCTS_COLUMNS = [
  {
    name: 'Product Code',
    type: 'text',
    index: 0,
    options: null,
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'documentSequenceCode',
    key: 'documentSequenceCode',
    allowFilter: false
  },
  {
    name: 'Product Name',
    type: 'text',
    index: 1,
    options: null,
    required: false,
    width: 250,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'name',
    key: 'name',
    allowFilter: false
  },
  {
    name: 'Description',
    type: 'text',
    index: 2,
    options: null,
    required: false,
    width: 250,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'description',
    key: 'description',
    allowFilter: false
  },
  {
    name: 'Price',
    type: 'number',
    index: 3,
    options: null,
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'salesPrice',
    key: 'salesPrice',
    allowFilter: false,
  },
  {
    name: 'Quantity',
    type: 'number',
    index: 4,
    options: null,
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'quantity',
    key: 'quantity',
    allowFilter: false
  }
];
