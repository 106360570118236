import React, { useEffect, useMemo, useRef, useState } from 'react';
import DocumentGrid from '../components/common/DocumentGrid';
import { endOfYear, startOfYear } from 'date-fns';
import {
  DKLabel,
  INPUT_TYPE,
  DKIcons,
  DKDateRangePicker
} from 'deskera-ui-library';
import { getQueryFromFilter, sortAndUpdateColumns } from '../services/tables';
import { getSalesOrders } from '../services/books';
import {
  debounce,
  deepClone,
  getCurrencySymbolFromCode,
  isNotEmpty,
  toCurrencyFormat
} from '../utility/Helper';
import {
  COLUMN_CODE,
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  DEFAULT_THEME_COLOR
} from '../constants/Constant';
import { getDateFromString, getDateStrFromDate } from '../utility/Date';
import PrintPreview from '../components/common/PrintPreview';
import {
  BOOKS_DOCS_TYPES,
  DATE_FORMATS,
  TEMPLATE_CATEGORY
} from '../constants/Enum';
import { useAppSelector } from '../store/hooks';
import { selectTenantSettings } from '../store/slices/authSlice';
import { selectTablesData } from '../store/slices/booksSlice';
import CreateOrder from '../components/CreateOrder';
import { attachmentRenderer } from '../utility/renderer';
import AttachmentPopup from '../components/common/AttachmentPopup';
interface ISalesOrderProps {}
const VISIBLE_COLUMNS_CODES = [
  COLUMN_CODE.SALES_ORDER.NUMBER,
  COLUMN_CODE.SALES_ORDER.DUE_DATE,
  COLUMN_CODE.SALES_ORDER.SALE_ORDER_DATE,
  COLUMN_CODE.SALES_ORDER.FULFILLMENT,
  COLUMN_CODE.SALES_ORDER.STATUS,
  COLUMN_CODE.SALES_ORDER.TOTAL_AMOUNT
];
const SalesOrders: React.FC<ISalesOrderProps> = (props: any) => {
  const [rowData, setRowData] = useState<any>([]);
  const tableData = useAppSelector(
    selectTablesData(BOOKS_DOCS_TYPES.BOOKS_SALES_ORDER)
  );
  const [columns, setColumns] = useState<any[]>([]);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [apiCallInProgress, updatedApiCallFlag] = useState<boolean>(false);
  const [filters, setFilters] = useState<any>();
  const [dateFilters, setDateFilters] = useState<any>({
    financialStartDate: startOfYear(new Date()),
    financialEndDate: endOfYear(new Date())
  });
  const [showHideCalendar, setShowHideCalendar] = useState<boolean>(false);
  const [showHidePrintPopup, setShowHidePrintPopup] = useState<boolean>(false);
  const [showOrderPopup, setShowHideOrderPopup] = useState<boolean>(false);

  const [selectedOrder, setSelectedOrders] = useState<any>();
  const settings = useAppSelector(selectTenantSettings);

  const [pagination, setPagination] = useState<any>({
    page: DEFAULT_PAGE_NO - 1,
    limit: DEFAULT_PAGE_SIZE,
    sortDir: 'DESC',
    sort: COLUMN_CODE.SALES_ORDER.NUMBER
  });
  const [showAttachmentPopup, setShowHideAttachmentPopup] =
    useState<boolean>(false);
  const orderSelectedFor = useRef<'PRINT' | 'ATTACHMENTS' | undefined>(
    undefined
  );

  const getActionButtonsColumn = () => {
    return {
      key: 'actions',
      name: 'Actions',
      type: INPUT_TYPE.BUTTON,
      width: 110,
      options: []
    };
  };

  useMemo(() => {
    let updatedColumns = sortAndUpdateColumns(tableData.columnsMetaData || []);
    updatedColumns = updatedColumns.filter((item) =>
      VISIBLE_COLUMNS_CODES.includes(item.columnCode)
    );
    updatedColumns.forEach((column) => {
      switch (column.columnCode) {
        case COLUMN_CODE.SALES_ORDER.TOTAL_AMOUNT:
          column.renderer = ({ rowData }: any) => {
            const currencyCode = getCurrencySymbolFromCode(rowData?.currency);
            return (
              <div className="row justify-content-end">
                {toCurrencyFormat(rowData.balanceDue || 0, currencyCode)}
              </div>
            );
          };
          break;
        default:
          break;
      }
    });
    updatedColumns.push({
      name: `Amount Paid`,
      type: 'number',
      index: updatedColumns?.length - 1,
      options: null,
      required: false,
      width: 130,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'linkedDocuments',
      key: 'linkedDocuments',
      allowFilter: false,
      renderer: ({ rowData }: any) => {
        const currencyCode = getCurrencySymbolFromCode(rowData?.currency);
        return (
          <div className="row justify-content-end">
            {toCurrencyFormat(rowData.amountPaid || 0, currencyCode)}
          </div>
        );
      }
    });
    updatedColumns.push({
      name: `Balance Due`,
      type: 'number',
      index: updatedColumns?.length - 1,
      options: null,
      required: false,
      width: 130,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'linkedDocuments',
      key: 'linkedDocuments',
      allowFilter: false,
      renderer: ({ rowData }: any) => {
        const currencyCode = getCurrencySymbolFromCode(rowData?.currency);
        return (
          <div className="row justify-content-end">
            {toCurrencyFormat(rowData.balanceDue || 0, currencyCode)}
          </div>
        );
      }
    });

    updatedColumns.push({
      name: 'Attachments',
      type: 'text',
      index: 12,
      options: [],
      required: true,
      width: 153,
      editable: false,
      hidden: false,
      uiVisible: true,
      columnCode: 'attachments',
      dataSource: [],
      key: 'attachments',
      allowAddOption: false,
      allowFilter: false,
      id: '4rXh',
      datasource: null,
      formula: null,
      renderer: (obj: any) =>
        attachmentRenderer(obj, () => {
          orderSelectedFor.current = 'ATTACHMENTS';
          setSelectedOrders(obj.rowData);
        }),
      currency: null
    });
    setColumns([...updatedColumns, getActionButtonsColumn()]);
  }, [tableData]);

  useEffect(() => {
    if (orderSelectedFor.current === 'PRINT') {
      setShowHidePrintPopup(isNotEmpty(selectedOrder));
    }
    if (orderSelectedFor.current === 'ATTACHMENTS') {
      setShowHideAttachmentPopup(isNotEmpty(selectedOrder));
    }
  }, [selectedOrder]);

  useEffect(() => {
    loadSalesOrders();
  }, [filters, dateFilters, pagination, searchTerm]);

  const onPageChange = (page: number) => {
    setPagination({ ...pagination, page: page });
  };

  const loadSalesOrders = () => {
    if (!apiCallInProgress) {
      updatedApiCallFlag(true);
      getSalesOrders(getPayload())
        .then((res: any) => {
          setRowData(res.content);
          setTotalPageCount(res.totalPages);
        })
        .catch((err: any) => {
          console.error(err);
        })
        .finally(() => {
          updatedApiCallFlag(false);
        });
    }
  };

  const getPayload = () => ({
    limit: pagination.limit,
    page: pagination.page,
    search: searchTerm,
    fetchAttachmentDetails: true,
    query: getQueryFromFilter(
      columns,
      filters?.query,
      dateFilters,
      BOOKS_DOCS_TYPES.BOOKS_SALES_ORDER
    )?.join(','),
    sortDir: pagination.sortDir || 'DESC',
    sort: pagination.sort
  });

  const parseRowData = (paymentList: any[]) => {
    let updatedRows: any = deepClone(paymentList);
    updatedRows.forEach((row: any) => {
      row[COLUMN_CODE.SALES_ORDER.SALE_ORDER_DATE] = getDateFromString(
        row[COLUMN_CODE.SALES_ORDER.SALE_ORDER_DATE],
        DATE_FORMATS['DD-MM-YYYY']
      );
      row[COLUMN_CODE.SALES_ORDER.DUE_DATE] = getDateFromString(
        row[COLUMN_CODE.SALES_ORDER.DUE_DATE],
        DATE_FORMATS['DD-MM-YYYY']
      );
      row[COLUMN_CODE.SALES_ORDER.FULFILLMENT] = [
        row[COLUMN_CODE.SALES_ORDER.FULFILLMENT]
      ];
      row[COLUMN_CODE.SALES_ORDER.STATUS] = [
        row[COLUMN_CODE.SALES_ORDER.STATUS]
      ];
      row.rowContextMenu = getContextMenuForRow(row);
    });

    return updatedRows;
  };

  const setOrderForPrint = (invoice: any) => {
    orderSelectedFor.current = 'PRINT';
    setSelectedOrders(invoice);
  };

  const getDateRangePicker = () => {
    return (
      <div className="position-absolute shadow-m bg-white z-index-6 top-12 right-20">
        <DKDateRangePicker
          className="border shadow "
          onClose={() => setShowHideCalendar(false)}
          color={settings?.theme || DEFAULT_THEME_COLOR}
          showPresetList={true}
          startDate={dateFilters.financialStartDate}
          selectedStartDate={dateFilters.financialStartDate}
          selectedEndDate={dateFilters.financialEndDate}
          onSelectDateRange={(startDate: Date, endDate: Date) => {
            if (startDate && endDate) {
              setShowHideCalendar(false);
              setDateFilters({
                financialStartDate: startDate,
                financialEndDate: endDate
              });
            }
          }}
        />
      </div>
    );
  };

  const getDateRangeString = () => {
    if (isNotEmpty(dateFilters)) {
      return (
        getDateStrFromDate(dateFilters.financialStartDate) +
        ' to ' +
        getDateStrFromDate(dateFilters.financialEndDate)
      );
    } else {
      return '';
    }
  };

  const getDocumentGridHeaderButtons = () => {
    let buttons = [];

    buttons.push({
      title: 'Create Order',
      className: 'bg-button text-white ml-s',
      icon: DKIcons.white.ic_add,
      onClick: () => setShowHideOrderPopup(true)
    });

    buttons.push({
      title: getDateRangeString(),
      className: 'border-m bg-white ml-r position-relative',
      icon: DKIcons.ic_calendar,
      onClick: () => setShowHideCalendar(true)
    });

    return buttons;
  };

  const getContextMenuForRow = (invoice: any) => {
    const menuItems = [];
    menuItems.push({
      title: 'View',
      icon: DKIcons.ic_view,
      onClick: ({ rowIndex, rowData }: any) => setOrderForPrint(invoice)
    });
    return menuItems;
  };

  const onFilter = (data: any) => {
    setFilters(data);
  };

  const onSearch = debounce((searchQuery: string) => {
    setSearchTerm(searchQuery);
  }, 500);

  const rows = parseRowData(rowData);

  return (
    <div className="column parent-width mb-s position-relative flex-1">
      <div className="parent-width row position-relative">
        <div
          className="position-absolute z-index-3 mobile-left-0"
          style={{ right: 630, top: 45 }}
        >
          {showHideCalendar && getDateRangePicker()}
        </div>
      </div>
      <div className="column parent-width mb-s position-relative flex-1">
        <DocumentGrid
          title={'📋 My Orders'}
          documentType={'Sales_Order'}
          rows={rows}
          columns={columns}
          needBoldTheme={true}
          allowSearch={true}
          allowFilter={true}
          needTrailingColumn={true}
          currentPage={pagination.page}
          totalPageCount={totalPageCount}
          filterData={filters?.query}
          onFilter={onFilter}
          onSearch={onSearch}
          allowRowEdit={true}
          onPageChange={onPageChange}
          updating={apiCallInProgress}
          headerButtons={getDocumentGridHeaderButtons()}
          onRowOpenClick={({ rowData }: any) => setOrderForPrint(rowData)}
        />
      </div>
      {showHidePrintPopup && (
        <PrintPreview
          category={TEMPLATE_CATEGORY.SALES_ORDER}
          document={{
            ...selectedOrder,
            documentCode: selectedOrder?.salesOrderCode,
            documentType: TEMPLATE_CATEGORY.SALES_ORDER
          }}
          event="PRINT"
          onClose={() => {
            setShowHidePrintPopup(false);
            setSelectedOrders(null);
          }}
        />
      )}
      {showOrderPopup && (
        <CreateOrder
          onClose={() => setShowHideOrderPopup(false)}
          onSave={() => {}}
        />
      )}
      {showAttachmentPopup && (
        <AttachmentPopup
          document={selectedOrder}
          entityType="SALES_ORDER"
          attachments={selectedOrder?.attachmentsWithLink || []}
          onClose={() => {
            setShowHideAttachmentPopup(false);
            setSelectedOrders(null);
          }}
          onUpload={() => {
            loadSalesOrders();
            setShowHideAttachmentPopup(false);
            setSelectedOrders(null);
          }}
        />
      )}
    </div>
  );
};

export default SalesOrders;
