import { DKLabel, MultiBar } from 'deskera-ui-library';

function SummaryBarGraph(props: any) {
  return (
    <div className="row">
      {' '}
      <div
        className="border-radius-m shadow-s border-s p-xl bg-white border-box column justify-content-between summary-section parent-width"
        style={{ height: 150 }}
      >
        <div className="column parent-width">
          <DKLabel text={props.title} className="fw-m fs-m" />
        </div>
        <div className="column parent-width">
          <div className="row justify-content-between mb-s">
            <div className="row">
              <DKLabel
                text={`<b>${props.leftTitle.title}</b>`}
                className={'100'}
                style={{
                  whiteSpace: 'normal'
                }}
              />
              <DKLabel
                className="whitespace-nowrap ml-s"
                text={props.leftTitle.value}
              />
            </div>
            <div className="row justify-content-end">
              <DKLabel
                text={`<b>${props.rightTitle.title}</b>`}
                className={''}
                style={{
                  whiteSpace: 'normal'
                }}
              />
              <DKLabel
                className="whitespace-nowrap ml-s"
                text={props.rightTitle.value}
              />
            </div>
          </div>
          {<MultiBar data={props.data} showLegend={true} />}
        </div>
      </div>
    </div>
  );
}

export default SummaryBarGraph;
