import { TableColumnPopupType } from "../Components/Popup/TableColumnPopup"
import { getIsAccountModule, getIsExpenseBill, getIsInvoice, getIsMakePayment, getIsReceivePayment, getIsSalesOrder } from "./Utility"

export default class TableFooterUtility {

    static getDefaultFooterList() {
        return [{
            type: 'product',
            items: [
                TableColumnPopupType.subTotalAmount,
                TableColumnPopupType.discount,
                TableColumnPopupType.tax,
                TableColumnPopupType.beforeTax,
                TableColumnPopupType.totalAmount,
                TableColumnPopupType.outstandingBalance,
                TableColumnPopupType.outstandingBalanceIncludesCurrent,
                TableColumnPopupType.roundingOff,
                TableColumnPopupType.globalDiscount,
                TableColumnPopupType.amountInWords
            ]
        },
        {
            type: 'account',
            items: [
                TableColumnPopupType.subTotalAmount,
                TableColumnPopupType.totalTaxAmount,
                TableColumnPopupType.totalAmount,
                TableColumnPopupType.roundingOff,
                TableColumnPopupType.amountInWords
            ]
        },
        {
            type: 'expenseBill',
            items: [
                TableColumnPopupType.subTotalAmount,
                TableColumnPopupType.totalTaxAmount,
                TableColumnPopupType.beforeTax,
                TableColumnPopupType.totalAmount,
                TableColumnPopupType.outstandingBalance,
                TableColumnPopupType.outstandingBalanceIncludesCurrent,
                TableColumnPopupType.amountInWords
            ]
        },
        {
            type: 'payment',
            items: [
                TableColumnPopupType.subTotalAmount,
                TableColumnPopupType.totalAmount,
                TableColumnPopupType.amountInWords
            ]
        },
        ]
    }

    static getFooterListByDocument(documentData) {
        var footerList = this.getFooterListByType('product')
        if (getIsAccountModule(undefined, documentData.type)) {
            footerList = this.getFooterListByType('account')
        }
        else if (getIsExpenseBill(documentData.type, documentData.lineItems)) {
            footerList = this.getFooterListByType('expenseBill')
        }
        else if (getIsMakePayment(documentData.type) || getIsReceivePayment(documentData.type)) {
            footerList = this.getFooterListByType('payment')
        }
        else if (getIsInvoice(documentData.type) || getIsSalesOrder(documentData.type)) {
            var index = footerList.indexOf(TableColumnPopupType.totalAmount);
            footerList.splice(index !== -1 ? index + 1 : 5, 0, TableColumnPopupType.cogs);
        }
        return footerList
    }

    static getFooterListByType(type) {
        var footer = this.getDefaultFooterList().find(x => x.type === type)
        if (footer !== undefined) {
            return footer.items
        }
        return []
    }

    static getFooterListSize(documentData) {
        var footer = this.getFooterListByDocument(documentData)
        if (footer !== undefined) {
            return footer.length
        }
        return 0
    }
}