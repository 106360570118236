import React, { Component } from 'react'
import { FONT_SIZE } from '../Constants/Constants'
import CustomFieldManager from '../Manager/CustomFieldManager'
import { DateFormat } from "../Components/TemplateEditors/DocumentEditor";
import Utility, { getDateFrom, getFontSize, getFontSizeClass, getVW, getDateString } from '../Utilities/Utility'
import { HTMLTag } from '../Utilities/HTMLTag';
export default class DKCustomFieldSection extends Component {

    render() {
        let customFieldList = CustomFieldManager.getSelectedGlobalCustomFieldList(this.props.data, this.props.customFieldPosition)
        if(customFieldList === undefined) {
            return <></>
        }
        else if (customFieldList.length === 0) {
            return <></>
        }
        else {
            return this.renderCustomFieldList(customFieldList)
        }
    }

    renderCustomFieldList(customFieldList) {
        return this.getCustomFieldList(customFieldList)
        // return (
        //     <div

        //         className='ColumnDiv parent-width'
        //         onClick={() => this.props.onCustomFieldClick(this.props.customFieldPosition)}
        //         style={{
        //             position: 'relative',
        //             // paddingTop: getVW(20),
        //             // paddingBottom: getVW(20),
        //         }}>
        //         {/* {!this.props.data.isReadOnlyMode && ComponentManager.renderTableColumnEditBlueButton(this.props.onCustomFieldClick)} */}
        //         {this.getCustomFieldList(customFieldList)}
        //     </div>
        // )
    }

    getCustomFieldList(customFieldList) {
        // split 4 column per row
        var newArraySize = 4
        var newCustomFieldList = []

        if(this.props.style !== undefined) {
            if (this.props.style.columnCount !== undefined) {
                if (Number(this.props.style.columnCount) > 0) {
                    newArraySize = Number(this.props.style.columnCount)
                }
            }
        }

        var rowCount = Math.ceil(customFieldList.length / newArraySize)
        for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
            var startIndex = rowIndex * newArraySize
            var columnList = []
            for (let index = startIndex; index < startIndex + newArraySize; index++) {
                var data = customFieldList[index]
                if (data !== undefined && data !== null) {
                    var item = CustomFieldManager.getCustomFieldByCodeFromApi(this.props.data, data.code)
                    if(item !== undefined) {
                        if (item.type === "DATE") {
                            data.value = '-'
                            if(item.value    !== undefined && item.value !== null) {
                                if(item.value !== '' ) {
                                    const dateFormat = CustomFieldManager.getDateFormat();
                                    data.value = getDateString(getDateFrom(item.value, DateFormat.MM_DD_YYYY_SLASH), dateFormat);
                                }
                            }
                        } else {
                            if (item !== undefined) {
                                data.value = item.value
                            }
                            else {
                                data.value = ''
                            }
                        }
                    }
                    columnList.push(data)
                }
                else {
                    columnList.push(undefined)
                }
            }
            newCustomFieldList.push(columnList)
        }

        var rowData = newCustomFieldList.map((row) => {
            var columns = row.map((item, index) => {
                return this.renderCustomField(item, index, row.length)
            });

            return <tr style={{
                border: this.getIsShowGrid() ? '1px solid' : undefined,
                width: '50%',
                verticalAlign: 'top',
                paddingTop: getVW(Utility.convertToPrintScale(10, this.props.data.printScale)),
                paddingBottom: getVW(Utility.convertToPrintScale(10, this.props.data.printScale)),
            }}>
                {columns}
            </tr>
        });

        return <table
            onClick={() => this.props.onCustomFieldClick(this.props.customFieldPosition)}
            id={HTMLTag.CUSTOM_FIELD_TABLE_PREFIX + this.props.customFieldPosition}
            style={{
                width: '100%',
                borderCollapse: 'collapse',
                margin: 'auto'
            }}>
            <tbody>
                {rowData}
            </tbody>
        </table>
        // return customFieldList.map(element => {
        //     return this.renderCustomField(element)
        // });
    }

    renderCustomField(item, index, size) {
        return this.renderCustomFieldSection(
            item !== undefined ? item.label : ' ',
            item !== undefined ? item.value : ' ',
            this.getColumnWidth(index, size),
            this.getHeaderColor(),
        )
    }

    getIsShowGrid() {
        if(this.props.isShowGrid !== undefined) {
            return this.props.isShowGrid
        }
        return Utility.getIsUsingTableGrid()
    }

    getColumnWidth(index, size) {
        var defaultSize = '100%'
        if(size > 0) {
            defaultSize = (100 / size) + '%'
        }
        if(this.props.style === undefined) {
            return defaultSize
        }
        if (this.props.style.columnWidths === undefined) {
            return defaultSize
        }

        if (this.props.style.columnWidths.length === 0) {
            return '100%'
        }

        if (this.props.style.columnWidths.length > index) {
            return this.props.style.columnWidths[index]
        }
    }

    getHeaderColor() {
        if(this.props.isThemeColor) {
            return this.props.data.themeColor
        }

        if (this.props.headerColor !== undefined) {
            return this.props.headerColor
        }

        return undefined
    }

    getMarginLeftValue() {
        if (this.props !== undefined) {
            if (this.props.style !== undefined) {
                if (this.props.style.marginLeft !== undefined) {
                    return this.props.style.marginLeft
                }
            }
        }
        return undefined
    }

    renderCustomFieldSection(
        title,
        description,
        width = "100%",
        titleColor = "black",
        textColor = "#000000"
    ) {
        return (
            <td
                style={{
                    border: this.getIsShowGrid() ? '1px solid' : undefined,
                    width: width,
                    verticalAlign: 'top',
                    paddingLeft: this.getIsShowGrid() ? getVW(Utility.convertToPrintScale(10, this.props.data.printScale)) : undefined,
                }}>
                {title && (
                    <div className="ColumnDiv"
                        style={{
                            width: '100%',
                            alignItems: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start'
                        }}>
                        <text className={"docFontStyleBold" + getFontSizeClass()}
                            style={{
                                color: titleColor,
                                marginLeft: Utility.getIsArabicLang() ? 0 : this.getMarginLeftValue(),
                                marginRight: Utility.getIsArabicLang() ? this.getMarginLeftValue() : 0,
                                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.props.data.printScale)
                            }}>
                                {title}
                        </text>
                    </div>
                )}
                <div
                    className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        marginTop: getVW(Utility.convertToPrintScale(6, this.props.data.printScale)),
                        minHeight: `${Utility.convertToPrintScale(Number(getFontSize(FONT_SIZE.regular).replace('vw', '')), this.props.data.printScale)}vw`,
                        color: textColor,
                        marginLeft: Utility.getIsArabicLang() ? 0 : this.getMarginLeftValue(),
                        marginRight: Utility.getIsArabicLang() ? this.getMarginLeftValue() : 0,
                        alignSelf: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.props.data.printScale)
                    }}
                    dangerouslySetInnerHTML={{ __html: description }}>
                </div>
            </td>
        );
    }
}
