import httpClient from '../index';
import { Attachment } from '../../pages/Attachment';
import { decodeJSON, triggerDownload } from '../../utility/Helper';
import { ENDPOINTS } from '../../constants/EndPoints';

export const downloadFileByRelativePath = (attachment: Attachment) => {
  const params = { attachmentId: attachment.attachmentId };
  httpClient.get(ENDPOINTS.FILES.DOWNLOAD, { params }).then((data: any) => {
    triggerDownload(null, attachment.attachmentFileName, data);
  });
};
/**
 *
 * @param base64FileListString - a string of base64 encode file list
 * @returns - array of strings of decode base64 file list
 */
export const decodeBase64FileList = (
  base64FileListString: string
): Attachment[] => {
  let fileList = [];
  try {
    fileList = decodeJSON(base64FileListString);
  } catch (error) {
    console.log('Error while decoding file list', error);
  }

  return fileList;
};
export const uploadFileToAWS = async (
  file: File,
  contactId: any,
  entity = 'CONTACT'
): Promise<string> => {
  let awsURL = '';
  let formData = new FormData();
  formData.append('file', file);
  formData.append('entityId', contactId);
  formData.append('entityName', entity);
  try {
    const response: any = await httpClient.post(
      ENDPOINTS.FILES.UPLOAD,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    awsURL = response?.publicUrl || '';
  } catch (error) {
    console.log('Error uploading file', error);
  }
  return Promise.resolve(awsURL);
};
export const openFileBrowser = (
  onFileSelected: (file: File[]) => void,
  inputAttribute: any = {}
) => {
  const fileInput = document.createElement('input');
  fileInput.type = 'file';
  Object.keys(inputAttribute).forEach((key) => {
    fileInput.setAttribute(key, inputAttribute[key]);
  });
  fileInput.onchange = (event: any) => {
    const files = event.target.files;
    onFileSelected(files);
  };
  fileInput.click();
};
export const getHumanReadableFileSize = (fileSize: number): string => {
  const fileSizeInMB = fileSize / (1024 * 1024);
  console.log(fileSizeInMB);
  if (fileSizeInMB >= 1) {
    return `${fileSizeInMB.toFixed(0)} MB`;
  } else {
    const fileSizeInKB = fileSize / 1024;
    return `${fileSizeInKB.toFixed(0)} KB`;
  }
};
export const fetchAttachments = (params: any = {}) => {
  return httpClient.get(ENDPOINTS.FILES.GET, { params });
};
export const deleteAttachment = (attachment: Attachment) => {
  const params = { attachmentId: attachment.attachmentId };
  return httpClient.get(ENDPOINTS.FILES.DELETE, { params });
};
export const MAX_FILE_SIZE = 10 * 1024 * 1024;

export const EXTENSIONS_FILE_ALLOWED_UPLOAD = {
  PDF: '.pdf',
  XLS: '.xls',
  XLSX: '.xlsx',
  CSV: '.csv',
  PNG: '.png',
  JPG: '.jpg',
  JPEG: '.jpeg',
  GIF: '.gif',
  DOC: '.doc',
  DOCX: '.docx',
  PPT: '.ppt',
  PPTX: '.pptx',
  TXT: '.txt'
};
