import React, { Component } from 'react'
import { FONT_SIZE } from '../Constants/Constants'
import IndiaTaxParser from '../Helper/IndiaTaxParser'
import { getLocalisedText } from '../Translate/LanguageManager'
import NumberUtility from '../Utilities/NumberUtility'
import Utility, { getFontSizeClass, getVW } from '../Utilities/Utility'
export default class DKIndiaTaxBreakdown extends Component {
    currency = null

    componentWillUnmount() {
        this.currency = NumberUtility.getCurrency(this.props.data)
    }

    componentWillReceiveProps() {
        this.currency = NumberUtility.getCurrency(this.props.data)
    }

    render() {
        return (
            <div className='ColumnDiv parent-width'
                style={{
                    paddingBottom: getVW(20),
                }}>
                    {this.renderTable()}
                {/* {this.renderTableByType('IGST')}

                <div style={{ height: 24, }}> </div>

                {this.renderTableByType('CGST_SGST')} */}
            </div>
        )
    }

    renderTable() {
        if (IndiaTaxParser.getIsIGST(this.props.data)) {
            return this.renderTableByType('IGST')
        }
        return this.renderTableByType('CGST_SGST')
    }

    renderTableByType(type) {
        return <table className='doc-container' id={'dk_tax_table_'}>
            <thead style={{
                border: '1px solid black',
            }}>
                <tr className='row-cell' id={'row_tax_header'}>
                    {this.renderHeaderList(type)}
                </tr>
            </thead>
            <tbody>
                {this.renderColumnList(type)}
                {this.renderTotalRow(type)}
            </tbody>
        </table>
    }

    defaultProp_IGST() {
        return {
            header: {
                hsn_or_sac: {
                    label: "hsn_or_sac",
                    width: undefined,
                    textAlign: 'left',
                    isColSpan: false
                },
                taxable_value: {
                    label: "taxable_value",
                    width: '15%',
                    textAlign: 'right',
                    isColSpan: false
                },
                integrated_tax: {
                    label: "integrated_tax",
                    width: '24%',
                    textAlign: 'right',
                    isColSpan: true
                },
                cess: {
                    label: "cess",
                    width: '12%',
                    textAlign: 'right',
                    isColSpan: false
                },
                total_tax_amount: {
                    label: "total_tax_amount",
                    width: '15%',
                    textAlign: 'right',
                    isColSpan: false
                },
            }
        }
    }

    defaultProp_CGST_SGST() {
        return {
            header: {
                hsn_or_sac: {
                    label: "hsn_or_sac",
                    width: undefined,
                    textAlign: 'left',
                    isColSpan: false
                },
                taxable_value: {
                    label: "taxable_value",
                    width: '12%',
                    textAlign: 'right',
                    isColSpan: false
                },
                central_tax: {
                    label: "central_tax",
                    width: '24%',
                    textAlign: 'right',
                    isColSpan: true
                },
                state_tax: {
                    label: "state_tax",
                    width: '24%',
                    textAlign: 'right',
                    isColSpan: true
                },
                cess: {
                    label: "cess",
                    width: '12%',
                    textAlign: 'right',
                    isColSpan: false
                },
                total_tax_amount: {
                    label: "total_tax_amount",
                    width: '12%',
                    textAlign: 'right',
                    isColSpan: false
                },
            }
        }
    }

    renderHeaderList(type) {
        var headerList = undefined
        if (type === 'IGST') {
            headerList = Object.entries(this.defaultProp_IGST().header)
            if(!this.getIsContainCess()) {
                headerList = headerList.filter(x => x[0] !== "cess")
            }
            return headerList.map(element => {
                return this.renderHeaderItem(element[1])
            });
        }
        else {
            headerList = Object.entries(this.defaultProp_CGST_SGST().header)
            if (!this.getIsContainCess()) {
                headerList = headerList.filter(x => x[0] !== "cess")
            }
            return headerList.map(element => {
                return this.renderHeaderItem(element[1])
            });
        }
    }

    renderHeaderItem(item) {
        if (item.isColSpan) {
            return <th className='docFontStyleBold fontSizeNormal'
                colSpan={2}
                style={{
                    width: item.width,
                    ...this.headerColumnStyle(),
                    ...Utility.getFontStyleProp({fontSize: FONT_SIZE.regular},this.props.data.printScale)
                }}>
                {this.renderRateAmountHeader(item.label)}
            </th>
        }

        return <th className='docFontStyleBold fontSizeNormal'
            style={{
                width: item.width,
                ...this.headerColumnStyle(),
                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular },this.props.data.printScale)
            }}>
            {getLocalisedText(item.label)}
        </th>
    }

    renderColumnList(type) {
        var records = this.props.data.indiaTax
        if (records === undefined || records === null) {
            return (<tr></tr>)
        }
        if (records.length === 0) {
            return (<tr></tr>)
        }
        else if (records.length > 0) {
            var newList = this.getFilterListByType(records, type)
            if (newList.length > 0) {
                var headerProp = this.defaultProp_CGST_SGST().header
                if (type === 'IGST') {
                    headerProp = this.defaultProp_IGST().header
                }
                var columnData = newList.map((element, rowIndex) => {
                    return this.renderColumnRow(element, rowIndex, headerProp)
                });
                return columnData
            }
        }
        return (<tr></tr>)
    }


    getFilterListByType(list, type) {
        var newList = []

        list.forEach(element => {
            if (element.integratedTax !== undefined && type === 'IGST') {
                newList.push(element)
            }
            else if (element.centralTax !== undefined && type === 'CGST_SGST') {
                newList.push(element)
            }
        });
        return newList
    }

    renderColumnRow(indiaTaxRow, rowIndex, headerProp) {
        if (indiaTaxRow === undefined || indiaTaxRow === null) {
            return
        }
        var rowData = []
        if (indiaTaxRow.integratedTax !== undefined) {
            rowData = this.getColumn_IGST(indiaTaxRow)
        }
        else {
            rowData = this.getColumn_CGST_SGST(indiaTaxRow)
        }
        var rowRecords = rowData.map((element, rowIndex) => {
            return this.renderColumnItem(element, rowIndex, headerProp)
        });

        return (<tr
            className='row-cell'
            id={'row_tax_cell'}
        >
            {rowRecords}
        </tr>)
    }

    renderColumnItem(element, rowIndex, headerProp) {
        return (
            <td
                className='cell'
                style={{
                    border: '1px solid black',
                    width: this.getWidthByHeaderProp(headerProp, element),
                }}
                id={'column_tax'}>
                <div
                    className='parent-width'
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        // alignItems: 'center',
                    }}>
                    <div
                        className={"parent-width fontStyle " + getFontSizeClass()}
                        style={{
                            direction: Utility.getIsSaudiArabiaCountry(this.props.data) ? 'rtl' : 'ltr',
                            textAlign: headerProp[element.key].textAlign,
                            paddingLeft: getVW(11),
                            paddingRight: getVW(11),
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.small },this.props.data.printScale)
                        }}>
                        {element.value}
                    </div>
                </div>
            </td>
        )
    }

    getWidthByHeaderProp(headerProp, element) {
        var colSpanList = ['integrated_tax', 'central_tax', 'state_tax']
        var additionalWidth = 0
        if(!this.getIsContainCess()) {
            additionalWidth = parseFloat(headerProp['cess'].width.replace('%', '')) / 4
        }

        var colWidth = undefined
        if(headerProp[element.key].width !== undefined) {
            colWidth = parseFloat(headerProp[element.key].width.replace('%', ''))
        }

        if(colSpanList.includes(element.key)) {
            var width = (colWidth + additionalWidth) / 2
            return width + '%'
        }

        if(colWidth === undefined) {
            return colWidth
        }
        return colWidth + '%'
    }

    getColumn_IGST(data) {
        if (data !== undefined && data !== null) {
            if(this.getIsContainCess()){
                return [
                    { key: 'hsn_or_sac', value: this.getDefaultValue(data.hsn_or_sac) },
                    { key: 'taxable_value', value: Utility.toCurrencyFormat(this.getDefaultValue(data.taxableValue), this.currency) },
                    { key: 'integrated_tax', value: this.getDefaultTaxValue(data, 'integratedTax', 'rate', this.currency) },
                    { key: 'integrated_tax', value: Utility.toCurrencyFormat(this.getDefaultTaxValue(data, 'integratedTax', 'amount'), this.currency) },
                    { key: 'cess', value: Utility.toCurrencyFormat(this.getDefaultValue(data.cess), this.currency) },
                    { key: 'total_tax_amount', value: Utility.toCurrencyFormat(this.getDefaultValue(data.totalTaxAmount), this.currency) },
                ]
            }
            return [
                { key: 'hsn_or_sac', value: this.getDefaultValue(data.hsn_or_sac) },
                { key: 'taxable_value', value: Utility.toCurrencyFormat(this.getDefaultValue(data.taxableValue), this.currency) },
                { key: 'integrated_tax', value: this.getDefaultTaxValue(data, 'integratedTax', 'rate', this.currency) },
                { key: 'integrated_tax', value: Utility.toCurrencyFormat(this.getDefaultTaxValue(data, 'integratedTax', 'amount'), this.currency) },
                { key: 'total_tax_amount', value: Utility.toCurrencyFormat(this.getDefaultValue(data.totalTaxAmount), this.currency) },
            ]
        }
        return [
            { key: 'hsn_or_sac', value: '' },
            { key: 'taxable_value', value: '' },
            { key: 'integrated_tax', value: '' },
            { key: 'integrated_tax', value: '' },
            { key: 'total_tax_amount', value: '' },
        ]
    }

    getColumn_CGST_SGST(data) {

        if (data !== undefined && data !== null) {
            if (this.getIsContainCess()) {
                return [
                    { key: 'hsn_or_sac', value: this.getDefaultValue(data.hsn_or_sac) },
                    { key: 'taxable_value', value: Utility.toCurrencyFormat(this.getDefaultValue(data.taxableValue), this.currency) },
                    { key: 'central_tax', value: this.getDefaultTaxValue(data, 'centralTax', 'rate') },
                    { key: 'central_tax', value: Utility.toCurrencyFormat(this.getDefaultTaxValue(data, 'centralTax', 'amount'), this.currency) },
                    { key: 'state_tax', value: this.getDefaultTaxValue(data, 'stateTax', 'rate') },
                    { key: 'state_tax', value: Utility.toCurrencyFormat(this.getDefaultTaxValue(data, 'stateTax', 'amount'), this.currency) },
                    { key: 'cess', value: Utility.toCurrencyFormat(this.getDefaultValue(data.cess), this.currency) },
                    { key: 'total_tax_amount', value: Utility.toCurrencyFormat(this.getDefaultValue(data.totalTaxAmount), this.currency) },
                ]
            }
            return [
                { key: 'hsn_or_sac', value: this.getDefaultValue(data.hsn_or_sac) },
                { key: 'taxable_value', value: Utility.toCurrencyFormat(this.getDefaultValue(data.taxableValue), this.currency) },
                { key: 'central_tax', value: this.getDefaultTaxValue(data, 'centralTax', 'rate') },
                { key: 'central_tax', value: Utility.toCurrencyFormat(this.getDefaultTaxValue(data, 'centralTax', 'amount'), this.currency) },
                { key: 'state_tax', value: this.getDefaultTaxValue(data, 'stateTax', 'rate') },
                { key: 'state_tax', value: Utility.toCurrencyFormat(this.getDefaultTaxValue(data, 'stateTax', 'amount'), this.currency) },
                { key: 'total_tax_amount', value: Utility.toCurrencyFormat(this.getDefaultValue(data.totalTaxAmount), this.currency) },
            ]
        }
        return [
            { key: 'hsn_or_sac', value: '' },
            { key: 'taxable_value', value: '' },
            { key: 'central_tax', value: '' },
            { key: 'central_tax', value: '' },
            { key: 'state_tax', value: '' },
            { key: 'state_tax', value: '' },
            { key: 'total_tax_amount', value: '' },
        ]
    }

    renderTotalRow(type) {
        var records = this.props.data.indiaTax
        if (records === undefined || records === null) {
            return (<tr></tr>)
        }
        if (records.length === 0) {
            return (<tr></tr>)
        }
        else if (records.length > 0) {
            var newList = this.getFilterListByType(records, type)
            if (newList.length > 0) {
                var headerProp = this.defaultProp_CGST_SGST().header
                var totalRowData = this.getTotalColumn_CGST_SGST(newList)
                if (type === 'IGST') {
                    headerProp = this.defaultProp_IGST().header
                    totalRowData = this.getTotalColumn_IGST(newList)
                }
                var columnData = totalRowData.map((element, rowIndex) => {
                    return this.renderColumnItem(element, rowIndex, headerProp)
                });
                return columnData
            }
        }
        return (<tr></tr>)
    }

    getTotalColumn_IGST(data) {
        if (data !== undefined && data !== null) {
            if (data.length > 0) {
                var totalTaxableValue = 0
                var totalIntegratedTax = 0
                var totalCess = 0
                var totalOfTotalTaxAmount = 0

                data.forEach(element => {
                    totalTaxableValue = totalTaxableValue + element.taxableValue
                    totalIntegratedTax = totalIntegratedTax + parseFloat(this.getDefaultTaxValue(element, 'integratedTax', 'amount'))
                    totalOfTotalTaxAmount = totalOfTotalTaxAmount + parseFloat(this.getDefaultValue(element.totalTaxAmount))
                    totalCess = totalCess + parseFloat(this.getDefaultValue(element.cess))
                });
                if (this.getIsContainCess()) {
                    return [
                        { key: 'hsn_or_sac', value: this.getDefaultValue(getLocalisedText('total')) },
                        { key: 'taxable_value', value: Utility.toCurrencyFormat(this.getDefaultValue(totalTaxableValue), this.currency) },
                        { key: 'integrated_tax', value: '' },
                        { key: 'integrated_tax', value: Utility.toCurrencyFormat(this.getDefaultValue(totalIntegratedTax), this.currency) },
                        { key: 'cess', value: Utility.toCurrencyFormat(this.getDefaultValue(totalCess), this.currency) },
                        { key: 'total_tax_amount', value: Utility.toCurrencyFormat(this.getDefaultValue(totalOfTotalTaxAmount), this.currency) },
                    ]
                }
                return [
                    { key: 'hsn_or_sac', value: this.getDefaultValue(getLocalisedText('total')) },
                    { key: 'taxable_value', value: Utility.toCurrencyFormat(this.getDefaultValue(totalTaxableValue), this.currency) },
                    { key: 'integrated_tax', value: '' },
                    { key: 'integrated_tax', value: Utility.toCurrencyFormat(this.getDefaultValue(totalIntegratedTax), this.currency) },
                    { key: 'total_tax_amount', value: Utility.toCurrencyFormat(this.getDefaultValue(totalOfTotalTaxAmount), this.currency) },
                ]
            }
        }
        return [
            { key: 'hsn_or_sac', value: getLocalisedText('total') },
            { key: 'taxable_value', value: '' },
            { key: 'integrated_tax', value: '' },
            { key: 'integrated_tax', value: '' },
            { key: 'total_tax_amount', value: '' },
        ]
    }

    getTotalColumn_CGST_SGST(data) {
        if (data !== undefined && data !== null) {
            if (data.length > 0) {
                var totalTaxableValue = 0
                var totalCentralTax = 0
                var totalStateTax = 0
                var totalCess = 0
                var totalOfTotalTaxAmount = 0

                data.forEach(element => {
                    totalTaxableValue = totalTaxableValue + element.taxableValue
                    totalCentralTax = totalCentralTax + parseFloat(this.getDefaultTaxValue(element, 'centralTax', 'amount'))
                    totalStateTax = totalStateTax + parseFloat(this.getDefaultTaxValue(element, 'stateTax', 'amount'))
                    totalCess = totalCess + parseFloat(this.getDefaultValue(element.cess))
                    totalOfTotalTaxAmount = parseFloat(totalOfTotalTaxAmount) + parseFloat(this.getDefaultValue(element.totalTaxAmount))
                });
                if(this.getIsContainCess()) {
                    return [
                        { key: 'hsn_or_sac', value: this.getDefaultValue(getLocalisedText('total')) },
                        { key: 'taxable_value', value: Utility.toCurrencyFormat(this.getDefaultValue(totalTaxableValue), this.currency) },
                        { key: 'central_tax', value: '' },
                        { key: 'central_tax', value: Utility.toCurrencyFormat(this.getDefaultValue(totalCentralTax), this.currency) },
                        { key: 'state_tax', value: '' },
                        { key: 'state_tax', value: Utility.toCurrencyFormat(this.getDefaultValue(totalStateTax), this.currency) },
                        { key: 'cess', value: Utility.toCurrencyFormat(this.getDefaultValue(totalCess), this.currency) },
                        { key: 'total_tax_amount', value: Utility.toCurrencyFormat(this.getDefaultValue(totalOfTotalTaxAmount), this.currency) },
                    ]
                }
                return [
                    { key: 'hsn_or_sac', value: this.getDefaultValue(getLocalisedText('total')) },
                    { key: 'taxable_value', value: Utility.toCurrencyFormat(this.getDefaultValue(totalTaxableValue), this.currency) },
                    { key: 'central_tax', value: '' },
                    { key: 'central_tax', value: Utility.toCurrencyFormat(this.getDefaultValue(totalCentralTax), this.currency) },
                    { key: 'state_tax', value: '' },
                    { key: 'state_tax', value: Utility.toCurrencyFormat(this.getDefaultValue(totalStateTax), this.currency) },
                    { key: 'total_tax_amount', value: Utility.toCurrencyFormat(this.getDefaultValue(totalOfTotalTaxAmount), this.currency) },
                ]
            }
        }
        return [
            { key: 'hsn_or_sac', value: getLocalisedText('total') },
            { key: 'taxable_value', value: '' },
            { key: 'central_tax', value: '' },
            { key: 'central_tax', value: '' },
            { key: 'state_tax', value: '' },
            { key: 'state_tax', value: '' },
            { key: 'total_tax_amount', value: '' },
        ]
    }

    renderRateAmountHeader(title) {
        return <div className='ColumnDiv'>
            <div className='RowDiv' style={{
                justifyContent: 'center',
                borderBottom: '1px solid black',
            }}>
                {getLocalisedText(title)}
            </div>
            <div className='RowDiv'>
                <div className='parent-width'>
                    {getLocalisedText('rate')}
                </div>
                <div className='parent-width'
                    style={{
                        ...this.amountHeaderColumnStyle()
                    }}>
                    {getLocalisedText('amount')}
                </div>
            </div>
        </div>
    }

    getIsContainCess() {
        var cessAmount = 0
        this.props.data.indiaTax.forEach(element => {
            if (element.cess !== undefined && element.cess !== null) {
                cessAmount = element.cess
            }
        });

        return cessAmount > 0
    }

    headerColumnStyle = () => {
        return {
            textAlign: 'center',
            border: '1px solid black',
        }
    }

    amountHeaderColumnStyle = () => {
        return {
            textAlign: 'center',
            borderLeft: '1px solid black',
        }
    }

    //copy from other utility
    getDefaultValue = (value) => {
        if (value === undefined || value === null) {
            return ''
        }
        return value
    }

    getDefaultTaxValue = (data, value, key) => {
        if (data === undefined || data === null) {
            return ''
        }
        if (data[value] === undefined || data[value] === null) {
            return ''
        }

        if (data[value][key] === undefined || data[value][key] === null) {
            return ''
        }
        // return ''
        return data[value][key]

    }
}
