import React, { Component } from 'react';
import { ComponentType } from '../Components/DocumentDesigner/DocumentDesignerScreen/model/Enum';
import DraggableLabel from '../Components/DocumentDesigner/DocumentDesignerScreen/ui/DraggableLabel'
import DraggableImage from '../Components/DocumentDesigner/DocumentDesignerScreen/ui/DraggableImage'
import DraggableBanner from '../Components/DocumentDesigner/DocumentDesignerScreen/ui/DraggableBanner'
import { Coordinate, CoordinateUtility } from '../Components/DocumentDesigner/utilities/CoordinateUtility'
import { ImageUtility } from '../Components/DocumentDesigner/utilities/ImageUtility';
import { AlertType } from "../Components/AlertComponent/Alert";
import { getLocalisedText } from "../Translate/LanguageManager";
import { HTMLTag } from '../Utilities/HTMLTag';
import { IMG_MAX_UPLOAD_SIZE } from '../Constants/Constants';
import {
    showAlert,
    showToast,
    TOAST_TYPE
} from 'deskera-ui-library';

export default class DocumentDesignerManager extends Component {
    coordinateUtility = new CoordinateUtility()
    defaultXPosition = this.coordinateUtility.toRatio(new Coordinate(200, 0, 0, 0)).xRatio
    defaultYPosition = this.coordinateUtility.toRatio(new Coordinate(0, 100, 0, 0)).yRatio
    constructor(props) {
        super(props);
        this.state = {
            isAddComponentPopupRequired: false,
            componentList: [],
            selectedComponent: undefined,
            globalSetting: {
                zoomRatio: 1,
                showGrid: false
            },
            showGrid: false,
            isDragging: false,
            id: 0,
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <div>
                {this.renderComponentList()}
            </div>
        );
    }

    renderComponentList() {
        if (this.props.componentList === undefined || this.props.componentList === null || this.props.componentList.length === 0) {
            return <div></div>
        }
        return this.props.componentList.map(element => {
            var id = HTMLTag.DRAGGABLE_DIV_COMPONENT_PREFIX + element.id

            if (element.type === ComponentType.DKLabel) {
                return <div id={id}
                    // onClick={() => this.onLabelClick(element, false)}
                    // onDoubleClick={() => this.onLabelClick(element, true)}
                    >
                    <DraggableLabel
                        disable={true}
                        key={element.id}
                        templateItem={element}
                        coordinateUtility={this.coordinateUtility}
                        grid={[5, 5]}
                        xDrag={0}
                        yDrag={0}
                        scale={this.state.globalSetting.zoomRatio}
                        handleStart={(e, data) => this.handleStart(element, e, data)}
                        handleDrag={(e, data) => this.handleDrag(element, e, data)}
                        handleStop={(e, data) => this.handleStop(element, e, data)}
                        onMouseDown={(e) => this.onMouseDown(e)}
                        screenState={this.state.screenState}
                        isDragging={this.state.isDragging}
                        selectedTemplateId={this.getSelectedTemplateId()}
                        isEdit={this.props.selectedComponent === element && (this.props.selectedComponent.systemField !== undefined)}
                        onComponentChange={(component) => {
                            this.props.onUpdateComponent(component, this.props.componentList)
                        }}
                        printScale={this.props.printScale}
                    />
                </div>
            }
            else if (element.type === ComponentType.DKImage) {
                return <div id={id} style={{ width: '10%' }}
                    // onClick={() => this.onLabelClick(element, false)}
                    // onDoubleClick={() => {
                    //     document.getElementById(HTMLTag.DRAGGABLE_COMPONENT_FILE_INPUT + element.id).click();
                    // }}
                >
                    <input
                        id={HTMLTag.DRAGGABLE_COMPONENT_FILE_INPUT + element.id}
                        // ref={id}
                        type="file"
                        accept="image/jpg,image/jpeg,image/png"
                        onChange={this.handleSelectedFile} hidden />
                    <DraggableImage
                        disable={true}
                        key={element.id}
                        templateItem={element}
                        coordinateUtility={this.coordinateUtility}
                        grid={[5, 5]}
                        imgDefaultSize={element.height ? element.height : 50}
                        componentButtonsRequired={false}
                        scale={this.state.globalSetting.zoomRatio}
                        handleStart={(e, data) => this.handleStart(element, e, data)}
                        handleDrag={(e, data) => this.handleDrag(element, e, data)}
                        handleStop={(e, data) => this.handleStop(element, e, data)}
                        onMouseDown={(e) => this.onMouseDown(e)}
                        screenState={this.state.screenState}
                        selectedTemplateId={this.getSelectedTemplateId()}
                        printScale={this.props.printScale}
                    />
                </div>
            }
            else if (element.type === ComponentType.DKBanner) {
                return <div
                    style={{
                        width: '100%',
                        height: '15%',
                    }}
                    id={id}
                    // onClick={() => this.onLabelClick(element, false)}
                    >
                    <DraggableBanner
                        scale={this.state.globalSetting.zoomRatio}
                        templateItem={element}
                        disable={true}
                        printScale={this.props.printScale}
                    />
                </div>
            }
            else {
                return <div></div>
            }
        });
    }

    getSelectedTemplateId() {
        if (this.props.selectedComponent === undefined) {
            return -1
        }
        else if (this.props.selectedComponent.id === undefined) {
            return -1
        }
        else {
            return this.props.selectedComponent.id
        }
    }

    onMouseDown(e) {
    }

    handleStop(element, e, data) {
        if (element.type === ComponentType.DKLabel) {
            this.onUpdateLocation(element, e, data)
        }
        else if (element.type === ComponentType.DKImage) {
            var event = e;
            if (event !== undefined) {
                if (event.button === 0) {
                    this.onUpdateLocation(element, e, data)
                }
            }
            else {
                this.onUpdateLocation(element, e, data)
            }

        }
    }

    handleDrag(element, e, data) {
    }

    handleStart(element, e, data) {
        this.props.onUpdateComponent(element, this.props.componentList)
        this.setState({
            showGrid: true,
            isDragging: true,
            // selectedComponent: element
        })
    }

    onUpdateLocation(element, e, data) {
        var newPosition = element
        var newList = [];
        var htmlElement = document.getElementById(HTMLTag.DRAGGABLE_COMPONENT_PREFIX + element.id)
        const newCoordinate = this.coordinateUtility.toRatio(new Coordinate(data.x, data.y, 0, 0, window.innerWidth), element, htmlElement)
        newPosition.x = newCoordinate.xRatio
        newPosition.y = newCoordinate.yRatio
        newPosition.originalWindowInnerWidth = newCoordinate.originalWindowInnerWidth = window.innerWidth
        this.props.componentList.forEach(item => {
            if (item.id === element.id) {
                newList.push(newPosition)
            }
            else {
                newList.push(item)
            }
        });
        this.props.onUpdateComponent(element, newList)
        this.setState({
            // componentList: newList,
            showGrid: false,
            isDragging: false
        })
        // this.updateDocumentDesigner(newList)
    }

    onLabelClick(element, isDoubleClick) {
        //set zIndex to 9999
        var newElement = element
        var newComponentList = this.state.componentList.filter(x => x.style.zIndex === 9999)
        newComponentList.forEach(item => {
            item.style.zIndex = 100
            this.onUpdateComponent(item, isDoubleClick)
        })

        if (newElement.type === ComponentType.DKLabel || newElement.type === ComponentType.DKImage) {
            newElement.style.zIndex = 9999
        }

        if (element.image !== undefined &&
            element.type === ComponentType.DKImage &&
            element.width === undefined) {
            this.updateImageSize(newElement, (data) => {
                //update list and set selected component
                this.onUpdateComponent(data, isDoubleClick)
                // this.props.onUpdateComponent(data, this.props.componentList)
                this.setState({
                    isAddComponentPopupRequired: false,
                })
            })
        }
        else {
            if (isDoubleClick) {
                this.props.onDoubleClickComponent(newElement)
            }
            else {
                this.props.onUpdateComponent(newElement, this.props.componentList)
                this.setState({
                    isAddComponentPopupRequired: false,
                })
            }
        }
    }

    onUpdateComponent(component, isDoubleClick) {
        var newComponentList = this.state.componentList
        var list = this.state.componentList.filter(x => x.id === component.id)
        if (list.length > 0) {
            var item = list[0]
            var index = this.state.componentList.indexOf(item)
            newComponentList[index] = component
            if (isDoubleClick) {
                this.props.onDoubleClickComponent(component)
            }
            else {
                this.props.onUpdateComponent(component, newComponentList)
            }

        }
    }


    updateImageSize(element, callback) {
        var data = element;
        const img = new Image();
        img.src = data.image
        img.onload = (result) => {
            data.width = result.path[0].naturalWidth
            data.height = result.path[0].naturalHeight
            callback(data)
        }
    }

    handleSelectedFile = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            let file = event.target.files[0];
            if (file.size > (IMG_MAX_UPLOAD_SIZE)) {
                showToast(getLocalisedText("MAX_IMG_UPLOAD_ALERT"), TOAST_TYPE.WARNING);
                return
            }
            //   let filePath = URL.createObjectURL(file);
            var idxDot = file.name.lastIndexOf(".") + 1;
            var extFile = file.name.substr(idxDot, file.name.length).toLowerCase();
            if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
                this.imagePathChanged(this.props.selectedComponent, file, (data) => this.props.onUpdateComponent(data, this.props.componentList))
            } else {
                showAlert(AlertType.standard,
                    getLocalisedText("incorrect_format"),
                    getLocalisedText("few_formats_allowed")
                );
            }
        }
    };

    imagePathChanged(selectedComponent, file, callback) {
        const reader = new FileReader()
        reader.readAsDataURL(file);
        reader.onload = function (e) {
            var data = selectedComponent;
            const img = new Image();
            data.imagePath = reader.result
            data.image = reader.result
            data.attachmentId = undefined
            img.src = reader.result
            data.imageRatio = 100
            img.onload = (result) => {
                const imageUtility = new ImageUtility()
                imageUtility.resize(result.path[0].naturalWidth, result.path[0].naturalHeight, 200)
                data.width = imageUtility.width
                data.height = imageUtility.height
                callback(data)
            }
        }
        reader.onerror = (e) => {
            callback(selectedComponent)
            console.error(e)
        }
    }
}
