import CustomFieldManager from "../Manager/CustomFieldManager"
import TemplateSettingsManager from "../Manager/TemplateSettingsManager"
import { getCapitalized } from "../Utilities/Utility"
import Address from "./Address"
import AddressParser, { AddressType, OwnerType } from "./AddressParser"

export default class TenantParser {

    static getLocationDetail(dataFromAPI) {
        var address = undefined
        var name = undefined
        if (dataFromAPI.locationInformation !== undefined && dataFromAPI.locationInformation !== null) {
            if (dataFromAPI.locationInformation.locationDetails !== undefined && dataFromAPI.locationInformation.locationDetails !== null) {
                if (dataFromAPI.locationInformation.locationDetails.address !== undefined && dataFromAPI.locationInformation.locationDetails.address !== null) {
                    address = dataFromAPI.locationInformation.locationDetails.address
                }
                if (dataFromAPI.locationInformation.locationDetails.title !== undefined && dataFromAPI.locationInformation.locationDetails.title !== null) {
                    name = dataFromAPI.locationInformation.locationDetails.title
                }
            }
        }
        return {address, name}
    }

    static parseLocationDetail(dataFromAPI, templateDataToReturn) {
        var locationDetails = this.getLocationDetail(dataFromAPI)
        if (locationDetails.address !== undefined) {
            var fromAddressObj = new Address(undefined, locationDetails.address, dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
            templateDataToReturn.fromObj = fromAddressObj
            templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.from, templateDataToReturn)
        }
        if (locationDetails.name !== undefined) {
            templateDataToReturn.companyName = getCapitalized(locationDetails.name)
        }
        return templateDataToReturn
    }

    static addIdForExistingCustomField() {
        var customFieldList = TemplateSettingsManager.defaultTemplateSettings.documentInfo.customFieldList
        if (customFieldList === undefined || customFieldList === null) {
            return
        }
        if (customFieldList.length === 0) {
            return
        }

        customFieldList = customFieldList.sort((a, b) => a.index - b.index)
        var masterCustomFieldOptions = CustomFieldManager.getMaterCustomFields()
        customFieldList.forEach((element, index) => {
            if (element.id === undefined) {
                var selected = undefined
                selected = masterCustomFieldOptions.find(x => x.code === element.code)
                if (selected) {
                    element.id = selected.id
                }
            }
            if (element.type === undefined) {
                selected = masterCustomFieldOptions.find(x => x.code === element.code)
                if (selected) {
                    element.type = selected.fieldType
                }
            }
        });
        TemplateSettingsManager.updateCustomFieldList(customFieldList)
    }

    static addTypeForExistingProductCustomField() {
        var productTableConfiguration = TemplateSettingsManager.defaultTemplateSettings.productTableConfiguration;
        if (productTableConfiguration !== undefined) {
            if (productTableConfiguration.isCombinesProductCustomField) {
                if (Array.isArray(productTableConfiguration.combinedProductCustomField)) {
                    productTableConfiguration.combinedProductCustomField.forEach((element) => {
                        if (element.isSelected && element.code !== undefined) {
                            var customField = CustomFieldManager.getProductCustomFields().find((cf) => cf.code === element.code);
                            if (customField) {
                                element.cfType = customField.fieldType;
                            }
                        }
                    });
                }

                TemplateSettingsManager.updateProductCombinedCustomField(productTableConfiguration);
            } else if (productTableConfiguration.isIncludesProductCustomField) {
                var tableInfo = TemplateSettingsManager.defaultTemplateSettings.tableInfo;
                if (Array.isArray(tableInfo)) {
                    var productCustomFields = CustomFieldManager.getProductCustomFields();
                    if (Array.isArray(productCustomFields)) {
                        productCustomFields.forEach((cf) => {
                            var productCf = tableInfo.find(x => x.code === cf.code);
                            if (productCf) {
                                productCf.cfType = cf.fieldType
                            }
                        });
                    }
                }

                TemplateSettingsManager.updateProductCustomField(tableInfo);
            }
        }
    }

    static parseTenantAdditionalSetting(dataFromAPI) {
        var customFieldList = TemplateSettingsManager.defaultTemplateSettings.documentInfo.customFieldList
        if (customFieldList === undefined || customFieldList === null) {
            return
        }
        if (customFieldList.length === 0) {
            return
        }
        var customFieldLabel = customFieldList.map(x => x.label)
        if(dataFromAPI.tenantInfo !== undefined && dataFromAPI.tenantInfo !== null) {
            var additionalSetting = dataFromAPI.tenantInfo.additionalSettings
            if(additionalSetting !== undefined && additionalSetting !== null) {
                if (additionalSetting.CLASS !== undefined && additionalSetting.CLASS !== null) {
                    if (additionalSetting.CLASS.classCFId !== undefined && additionalSetting.CLASS.classCFId !== null) {
                        customFieldList.forEach(element => {
                            if (element.id !== undefined) {
                                if (additionalSetting.CLASS.classCFId === element.id) {
                                    element.originalLabel = element.label
                                    element.label = additionalSetting.CLASS.assignClasses ? additionalSetting.CLASS.assignClasses : 'CLASS'
                                }
                            }
                            else {
                                var classFieldList = customFieldLabel.filter(x => x === 'CLASS')
                                if(classFieldList.length === 1) {
                                    if (element.label === 'CLASS') {
                                        element.label = additionalSetting.CLASS.assignClasses ? additionalSetting.CLASS.assignClasses : 'CLASS'
                                    }
                                }
                            }
                        });
                    }
                }

                if (additionalSetting.LOCATION !== undefined && additionalSetting.LOCATION !== null) {
                    if (additionalSetting.LOCATION.locationCFId !== undefined && additionalSetting.CLASS.locationCFId !== null) {
                        //update the value for custom field
                        customFieldList.forEach(element => {
                            if (element.id !== undefined) {
                                if (additionalSetting.LOCATION.locationCFId === element.id) {
                                    element.originalLabel = element.label
                                    element.label = additionalSetting.LOCATION.locationLabel ? additionalSetting.LOCATION.locationLabel : 'LOCATION'
                                }
                            }
                            var locationFieldList = customFieldLabel.filter(x => x === 'LOCATION')
                            if (locationFieldList.length === 1) {
                                if (element.label === 'LOCATION') {
                                    element.label = additionalSetting.LOCATION.locationLabel ? additionalSetting.LOCATION.locationLabel : 'LOCATION'
                                }
                            }
                        });
                    }
                }
            }
        }
    }
}
