import React, { Component } from "react";
import "../../DocPreview.css";
import "./css/Templates.css";
import ComponentManager from "../../Manager/ComponentManager";
import { FONT_SIZE, PAGE_PADDING } from '../../Constants/Constants';
import DocumentManager from "../../Manager/DocumentManager";
import Utility, { getCapitalized, getFontSizeClass, getPageHeight, getVW, getIsFulfillment, getIsGoodsReceipt, getIsJournalEntry, getIsOrder, getIsBill, getIsProductDocumentForBuySell } from "../../Utilities/Utility";
import { getLocalisedText } from '../../Translate/LanguageManager';
import DKEInvoiceSection, { eInvoiceSectionPosition } from "../../DKUILibrary/DKEInvoiceSection";
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import DKSignatureSection from "../../DKUILibrary/DKSignatureSection";
import { AddressType } from "../../Helper/AddressParser";
import { HTMLTag } from "../../Utilities/HTMLTag";
import DKIndiaTaxBreakdown from "../../DKUILibrary/DKIndiaTaxBreakdown";
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";

export default class IndiaTemplate9 extends Component {//based on tempalte 40 - India Template 3 for more spacing  https://deskera.atlassian.net/browse/BOOK-3182

    spacingRatio = 0.25
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    addTermsNConditionsView() {
        return (
            <div id={HTMLTag.TERMS_NOTE_PREFIX}>
                {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) &&
                    ComponentManager.getDescriptionSection(
                        Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition')),
                        getLocalisedText(this.state.data.termsAndCondition),
                        "100%",
                        this.state.data.themeColor,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        this.state.data.printScale
                    )}

                {((this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && (this.state.data.showNotes && this.state.data.notes)) && ComponentManager.addVerticalSpace(Utility.convertToPrintScale(20, this.state.data.printScale) * this.spacingRatio)}
                {(this.state.data.showNotes && this.state.data.notes) &&
                    ComponentManager.getDescriptionSection(
                        Utility.getNotesTitle(getIsJournalEntry(this.state.data.type) ? getLocalisedText('memo') : getLocalisedText('notes')),
                        getLocalisedText(this.state.data.notes),
                        "100%",
                        this.state.data.themeColor,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        this.state.data.printScale
                    )}
            </div>
        )
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ justifyContent: 'space-between', minHeight: getPageHeight(this.state.data.printScale) }}>
                <div className='parent-width' style={{ width: '100%', paddingBottom: getVW(30 * this.spacingRatio) }}>
                    <DKEInvoiceSection
                        data={this.state.data}
                        position={eInvoiceSectionPosition.top}
                    />
                    <div className="RowDiv"
                        style={{ justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        <div className="ColumnDiv" style={{
                            width: '30%'
                        }}>
                            {this.getDocumentDetailsLeftSection()}
                        </div>
                        <div className="ColumnDiv" style={{
                            width: '40%'
                        }}>
                            {this.getComapanySection()}
                        </div>
                        <div className="ColumnDiv" style={{
                            width: '30%'
                        }}>
                            {this.getDocumentDetailsRightSection()}
                        </div>
                    </div>
                    {ComponentManager.addVerticalSpace(30 * this.spacingRatio)}
                    {/* section 2 */}
                    <div
                        className="RowDiv"
                        style={{
                            alignItems: "flex-start",
                            backgroundColor: "white",
                        }}
                    >
                        <div className="RowDiv" style={{ width: '100%', padding: getVW(10 * this.spacingRatio), background: 'rgb(250,250,250)' }}>
                            {this.state.data.showClientBillToAddress && <div className="ColumnDiv" style={{ width: '40%' }}>
                                {ComponentManager.getDescriptionSection(
                                    this.state.data.billToTitle,
                                    Utility.getClientBillToAddress(this.state.data),
                                    "100%",
                                    this.state.data.themeColor,
                                    false,
                                    '#000000',
                                    getVW(6 * this.spacingRatio),
                                    Utility.getOwnerType(this.state.data, AddressType.billTo),
                                    this.state.data.printScale
                                )}
                                {ComponentManager.getDescriptionSection(
                                    "",
                                    this.state.data.billToAddressStateCode,
                                    "100%",
                                    this.state.data.themeColor,
                                    false,
                                    undefined,
                                    0,
                                    undefined,
                                    this.state.data.printScale
                                )}
                            </div>}
                            {(this.state.data.showClientBillToAddress && this.state.data.showClientShipToAddress) && ComponentManager.addHorizontalSpace(10)}
                            {this.state.data.showClientShipToAddress && <div className="ColumnDiv" style={{ width: '40%' }}>
                                {ComponentManager.getDescriptionSection(
                                    this.state.data.shipToTitle,
                                    Utility.getClientShipToAddress(this.state.data),
                                    "100%",
                                    this.state.data.themeColor,
                                    false,
                                    '#000000',
                                    getVW(6 * this.spacingRatio),
                                    Utility.getOwnerType(this.state.data, AddressType.shipTo),
                                    this.state.data.printScale
                                )}
                                {ComponentManager.getDescriptionSection(
                                    "",
                                    this.state.data.shipToAddressStateCode,
                                    "100%",
                                    this.state.data.themeColor,
                                    false,
                                    undefined,
                                    0,
                                    undefined,
                                    this.state.data.printScale
                                )}
                            </div>}
                        </div>
                    </div>
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data) || Utility.getIsShowPlaceOfSupply(this.state.data)) && ComponentManager.addVerticalSpace(20 * this.spacingRatio)}
                    {Utility.getIsShowPlaceOfSupply(this.state.data) && this.getPlaceOfSupplyInfoSection()}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && this.getSourceOfSupplyInfoSection()}
                    {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(10 * this.spacingRatio)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'top'} />
                    {ComponentManager.addVerticalSpace(20 * this.spacingRatio)}
                    {
                        TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                        <>
                            <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                                dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                            {ComponentManager.addVerticalSpace(5 * this.spacingRatio)}
                        </>
                    }
                    {this.getProductTable()}
                    {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(10 * this.spacingRatio)}
                    {this.getEditableDocumentTable()}
                    {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(10 * this.spacingRatio)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'bottom'} />
                    {ComponentManager.addVerticalSpace(15 * this.spacingRatio)}
                    {getIsProductDocumentForBuySell(this.state.data) && <DKIndiaTaxBreakdown
                        data={this.state.data}
                    />}
                    <div id={HTMLTag.SIGNATURE}>
                        {this.state.data.showSignature && ComponentManager.addVerticalSpace(Utility.convertToPrintScale(30, this.state.data.printScale) * this.spacingRatio)}
                        {this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature,this.state.data.printScale)}
                    </div>
                </div>
                <div className='parent-width' style={{ width: '100%' }}>
                    {(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) && <DKSignatureSection data={this.state.data} onSignatureSectionClicked={this.props.tableClicked} />}
                    {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && this.addTermsNConditionsView()}
                </div>
                <DKEInvoiceSection
                    data={this.state.data}
                    position={eInvoiceSectionPosition.bottom}
                />
                {Utility.getGeneratedVisibility() && this.getGenerateDocumentSection()}
            </div>
        );
    }

    getDocumentDetailsLeftSection() {
        return <div
            className="RowDiv"
            style={{
                alignItems: "flex-start",
                backgroundColor: "white",
            }}
        >
            <div
                className="ColumnDiv"
                style={{ width: '100%', padding: getVW(10 * this.spacingRatio) }}
            >
                {this.getDocumentDetailsSection('left')}
            </div>
        </div>
    }

    getDocumentDetailsRightSection() {
        return <div
            className="RowDiv"
            style={{
                alignItems: "flex-start",
                backgroundColor: "white",
            }}
        >
            <div
                className="ColumnDiv"
                style={{ width: '100%', padding: getVW(10 * this.spacingRatio) }}
            >
                {this.getDocumentDetailsSection('right')}
            </div>
        </div>
    }

    getDocumentDetailsSection(position) {
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        var leftCount = Math.floor(documentDetailList.length / 2)
        var newDocumentDetailList = []
        if (position === 'left') {
            documentDetailList.forEach((element, index) => {
                if (index < leftCount) {
                    newDocumentDetailList.push(element)
                }
            });
        }
        else {
            documentDetailList.forEach((element, index) => {
                if (index >= leftCount) {
                    newDocumentDetailList.push(element)
                }
            });
        }
        return newDocumentDetailList.map(element => {
            var value = ''
            if (element.key !== undefined) {
                value = this.state.data[element.key]
            }
            else if (element.customFieldValue !== undefined) {
                value = element.customFieldValue
            }
            return ComponentManager.getRowValueSection(
                element.label,
                value,
                this.state.data.themeColor,
                undefined,
                undefined,
                undefined,
                this.state.data.printScale
            )
        });
    }

    getDocumentDateSection() {
        return <div
            className="RowDiv"
            style={{
                alignItems: "flex-start",
                backgroundColor: "white",
            }}
        >
            <div
                className="ColumnDiv"
                style={{ width: '100%', padding: getVW(10 * this.spacingRatio) }}
            >
                {this.state.data.showStartDate && ComponentManager.getRowValueSection(
                    ComponentManager.getStartDateLabel(this.state.data.documentType),
                    this.state.data.date,
                    this.state.data.themeColor,
                    undefined,
                    undefined,
                    undefined,
                    this.state.data.printScale
                )}
                {this.state.data.showDueDate && ComponentManager.getRowValueSection(
                    ComponentManager.getEndDateLabel(this.state.data.type),
                    this.state.data.dueDate,
                    this.state.data.themeColor,
                    undefined,
                    undefined,
                    undefined,
                    this.state.data.printScale
                )}
                {this.state.data.showAdditionalDate && ComponentManager.getRowValueSection(
                    ComponentManager.getAdditionalDateLabel(this.state.data.type),
                    this.state.data.additionalDate,
                    this.state.data.themeColor,
                    undefined,
                    undefined,
                    undefined,
                    this.state.data.printScale
                )}
            </div>
        </div>
    }

    getComapanySection() {
        return <div className="RowDiv"
            style={{
                justifyContent: 'center'
            }}>
            <div className="ColumnDiv" style={{ alignItems: 'center' }}>
                {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) && ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(80, this.state.data.printScale), { position: 'relative' })}
                {ComponentManager.addVerticalSpace(10 * this.spacingRatio)}
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xLarge },this.state.data.printScale)
                    }}>
                    {getCapitalized(getLocalisedText(this.state.data.documentType))}
                </text>
                <div className="ColumnDiv" style={{ justifyContent: "center", alignItems: "flex-end", alignSelf: 'baseline' }}>
                    {this.state.data.showCompanyName && <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            textAlign: 'center',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xMedium },this.state.data.printScale)
                        }}>
                        {getLocalisedText(Utility.getCompanyName(this.state.data))}
                    </text>}
                    {this.state.data.showCompanyName && ComponentManager.addVerticalSpace(5 * this.spacingRatio)}
                    {this.state.data.showFrom && <div className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            color: '#00000080',
                            textAlign: 'center',
                            width: '100%',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.from) },this.state.data.printScale)
                        }}
                        dangerouslySetInnerHTML={{ __html: getLocalisedText(Utility.getCompanyAddress(this.state.data)) }}>
                    </div>}
                    {this.state.data.showFrom && ComponentManager.getDescriptionSection(
                        "",
                        (getIsOrder(this.state.data.type) || getIsBill(this.state.data.type)) ? this.state.data.billOrderCompanyStateNameCode : this.state.data.companyStateNameAndCode,
                        '100%',
                        this.state.data.themeColor,
                        false,
                        undefined,
                        0,
                        undefined,
                        this.state.data.printScale
                    )}
                </div>
            </div>
        </div>
    }

    getGenerateDocumentSection() {
        return <div style={{
            paddingBottom: getVW(PAGE_PADDING, true),// Utility.getGeneratedMessagePaddingBottom(this.spacingRatio),
            paddingTop: getVW(5 * this.spacingRatio),
            width: '100%'
        }}>
            <text className={'docFontStyleBold fontSizeNormal ' + getFontSizeClass()}
                style={{
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular },this.state.data.printScale)
                }}>
                {Utility.getGeneratedMessage(this.state.data.type)}
            </text>
        </div>
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getProductTable() {
        if(Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKProductTable(
                Utility.getTableColumnForAccountProduct(),
                this.getTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerTextColor = this.state.data.themeColor;
        style.footerTextColor = this.state.data.themeColor;
        style.headerBorderColor = "#BBBABA";
        style.itemBorderColor = "#BBBABA";
        style.footerBackgroundColor = "white";
        style.footerBorderColor = "#BBBABA";
        style.headerBackgroundColor = "transparent";
        return style;
    }


    getCustomFieldTableStyle() {
        let style = {}
        style.columnCount = 2
        style.columnWidths = Utility.getIsArabicLang() ? ['40%', '60%'] : ['40%', '60%']
        return style;
    }

    getPlaceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getPlaceOfSupply()}

            </div>
        )
    }
    getPlaceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('place_of_supply'),
                    this.state.data.placeOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6 * this.spacingRatio),
                    true,
                    this.state.data.printScale
                )}
            </div>

        </div>;

    }

    getSourceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getSourceOfSupply()}

            </div>
        )
    }

    getSourceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '41%'
            }}>
                {Utility.getIsShowSourceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('source_of_supply'),
                    this.state.data.sourceOfSupply,
                    "100%",
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6 * this.spacingRatio),
                    true,
                    this.state.data.printScale
                )}
            </div>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowDestinationOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('destination_of_supply'),
                    this.state.data.destinationOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6 * this.spacingRatio),
                    true,
                    this.state.data.printScale
                )}
            </div>
        </div>;
    }
}
