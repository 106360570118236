import {
  DKDataGrid,
  DKButton,
  DKIcons,
  DKIcon,
  DKLabel,
  DKIconText,
  INPUT_TYPE,
  showAlert
} from 'deskera-ui-library';
import { PRODUCTS_COLUMNS } from '../constants/ColumnConfigs';
import { useEffect, useRef, useState } from 'react';
import { deepClone, isEmptyObject } from '../utility/Helper';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
  fetchLoggedInContact,
  selectLoggedInContact
} from '../store/slices/booksSlice';
import { prepareSalesOrderPayload } from '../services/common/order';
import { createSalesOrder } from '../services/books';
import { fetchCartItems, selectCartItems } from '../store/slices/cartSlice';
import { amountRenderer } from '../utility/renderer';
import { deleteFromCart } from '../services/cart';
import ic_no_data from '../assets/icons/ic_no_data.png';

interface IOrderSummaryProps {
  onSave: () => void;
  onClose: () => void;
}

const OrderSummary: React.FC<IOrderSummaryProps> = (props) => {
  const [rows, setRows] = useState<any[]>([]);
  const cartItems = useAppSelector(selectCartItems);
  const contactInfo = useAppSelector(selectLoggedInContact);
  const dispatch = useAppDispatch();
  const [apiCallInProgress, updateApiCallFlag] = useState<boolean>(false);
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);

  useEffect(() => {
    if (isEmptyObject(contactInfo)) {
      dispatch(fetchLoggedInContact({}));
    }
    dispatch(fetchCartItems());
  }, []);

  useEffect(() => {
    setRows(deepClone(cartItems?.data ?? []));
  }, [cartItems]);

  const onOrderPlace = () => {
    if (!apiCallInProgress) {
      const payload = prepareSalesOrderPayload(rows, contactInfo);
      updateApiCallFlag(true);
      createSalesOrder(payload)
        .then((res: any) => {
          if (res) {
            console.log(res);

            emptyCart();
            showAlert(
              'Success!',
              `Order <b>${res.documentSequenceCode}</b> Placed Successfully.`
            );
            props.onSave?.();
          }
        })
        .catch((err: any) => {
          console.error('err', err);
          showAlert('Error!', 'Error occurred, Please try again!');
        })
        .finally(() => {
          updateApiCallFlag(false);
        });
    }
  };

  const getHeader = () => {
    return (
      <div className="row-reverse p-h-l p-v-s bg-gray1" style={{ gap: 10 }}>
        {(cartItems?.data?.length || 0) > 0 && <DKIconText
          text={'Place Order'}
          icon={apiCallInProgress ? DKIcons.ic_loading : null}
          className={`border-m row border-radius-m text-wrap-none p-s dk-button-hover ${
            apiCallInProgress
              ? 'bg-gray1 cursor-not-allowed '
              : 'bg-button text-white cursor-hand'
          }`}
          iconClassName={'Loading-logo'}
          onClick={onOrderPlace}
          disabled={apiCallInProgress}
        />}
        <DKButton
          title={'Close'}
          className="bg-white border-m"
          onClick={props?.onClose}
        />
      </div>
    );
  };

  const onRowUpdate = ({ rowData, rowIndex, columnData, value }: any) => {
    const copyOfRows = deepClone(rows);
    if (columnData.columnCode === 'cartQuantity') {
      rowData['cartQuantity'] = isNaN(rowData['cartQuantity'])
        ? 0
        : Number(rowData['cartQuantity']);
    }
    copyOfRows[rowIndex] = rowData;
    setRows(copyOfRows);
  };

  const onDelete = ({ rowData, rowIndex }: any) => {
    const copyOfRows = deepClone(rows);
    copyOfRows.splice(rowIndex, 1);
    setRows(copyOfRows);
    deleteItems([rowData.documentSequenceCode]);
  };
  const deleteItems = (productIds: string[]) => {
    deleteFromCart(productIds)
      .then((res) => {
        dispatch(fetchCartItems());
        setSelectedProducts([]);
      })
      .finally(() => {});
  };

  const getUpdatedColumns = () => {
    const columns: any = deepClone(PRODUCTS_COLUMNS);
    columns.push({
      id: 'action',
      key: 'action',
      name: '',
      type: INPUT_TYPE.BUTTON,
      width: 140,
      options: [
        {
          icon: DKIcons.ic_delete,
          className: ' p-0',
          onClick: onDelete
        }
      ]
    });
    return columns?.map((column: any) => {
      switch (column.columnCode) {
        case 'description':
          column.width = 120;
          break;
        case 'quantity':
          column.columnCode = 'cartQuantity';
          column.key = 'cartQuantity';
          column.editable = true;
          break;
        case 'salesPrice':
          column['renderer'] = (data: any) =>
            amountRenderer(data, 'salesPrice');
          break;
        default:
          break;
      }
      return column;
    });
  };

  const onRowSelect = ({ rowData }: any) => {
    const indexOfExisting = selectedProducts?.findIndex(
      (product: any) =>
        product?.documentSequenceCode === rowData?.documentSequenceCode
    );
    const copyOfSelectedProducts = deepClone(selectedProducts);
    if (indexOfExisting === -1) {
      copyOfSelectedProducts.push(rowData);
    } else {
      copyOfSelectedProducts.splice(indexOfExisting, 1);
    }

    setSelectedProducts(copyOfSelectedProducts);
  };
  const onAllRowSelect = ({ selected }: any) => {
    if (selected) {
      setSelectedProducts([...deepClone(rows)]);
    } else {
      setSelectedProducts([]);
    }
  };
  const emptyCart = () => {
    deleteItems(rows.map((product) => product?.documentSequenceCode));
  };
  const getNoDataView = () => {
    let noDataTitle = '';
    let noDataText = '';

    if (apiCallInProgress) {
      noDataTitle = 'Loading...';
      noDataText = 'Please wait';
    } else {
      if (!rows.length) {
        noDataTitle = 'No data found';
        noDataText = 'Once data is available, it will appear here';
      }
    }
    return rows.length ? null : (
      <div
        className="column justify-self-center align-self-center align-items-center position-absolute"
        style={{ top: 200, pointerEvents: 'none' }}
      >
        <DKIcon src={ic_no_data} className="ic-l opacity-20" />
        <DKLabel text={noDataTitle} className="fw-m mt-l" />
        <DKLabel text={noDataText} className="text-gray mt-s" />
      </div>
    );
  };

  const showContextMenu = !isEmptyObject(selectedProducts);

  return (
    <div className="transparent-background">
      <div
        className="popup-window overflow-hidden"
        style={{
          maxHeight: '95%',
          height: '40%',
          width: '55%',
          maxWidth: '90%',
          padding: 0
        }}
      >
        <>{getHeader()}</>
        {showContextMenu && (
          <DKButton
            title={'Delete'}
            icon={DKIcons.white.ic_delete}
            className="bg-red border-m text-white ml-l mt-l"
            onClick={() =>
              deleteItems(
                selectedProducts.map((product) => product?.documentSequenceCode)
              )
            }
          />
        )}
        {rows?.length !== 0 && (
          <div className="row p-h-l overflow-visible align-items-start">
            <DKDataGrid
              title="🛒 Order Summary"
              columns={getUpdatedColumns()}
              rows={rows}
              needTrailingColumn={true}
              allowSearch={false}
              needBoldTheme={true}
              onRowUpdate={onRowUpdate}
              allowBulkOperation={false}
              onRowSelect={onRowSelect}
              showHeader={!showContextMenu}
              onAllRowSelect={onAllRowSelect}
              isAllRowSelected={
                selectedProducts?.length !== 0 &&
                rows?.length === selectedProducts?.length
              }
              buttons={[
                {
                  title: 'Empty Cart',
                  icon: DKIcons.white.ic_delete,
                  className: 'bg-red border-m text-white ml-l mt-l',
                  onClick: emptyCart
                }
              ]}
            />
          </div>
        )}
        {rows?.length === 0 && getNoDataView()}
      </div>
    </div>
  );
};

export default OrderSummary;
