import React, { Component } from 'react'
import { FONT_SIZE } from '../Constants/Constants'
import Utility, { getVW } from '../Utilities/Utility'
import ApiManager from '../API/ApiManager';
import TemplateSettingsManager from '../Manager/TemplateSettingsManager';
import QRCode from "react-qr-code";
import { HTMLTag } from '../Utilities/HTMLTag';

export const eInvoiceSectionPosition = {
    top: 'top',
    bottom: 'bottom',
}
export default class DKEInvoiceSection extends Component {

    render() {
        if (Utility.getIsShowEInvoiceCorrectPosition(this.props.position)
            && Utility.getIsIndiaCountry(this.props.data)
            && this.props.data.showEInvoiceSection) {
            return this.renderContainer()
        }
        return <></>
    }

    renderContainer() {
        return (
            <div
                id={HTMLTag.E_INVOICE_PREFIX + this.props.position}
                className='ColumnDiv parent-width'
                style={{
                    // paddingTop: getVW(20),
                    paddingTop: Utility.getIsShowEInvoiceCorrectPosition(eInvoiceSectionPosition.bottom) ? getVW(Utility.convertToPrintScale(20, this.props.data.printScale)) : undefined,
                    paddingBottom: Utility.getIsShowEInvoiceCorrectPosition(eInvoiceSectionPosition.top) ? getVW(Utility.convertToPrintScale(20, this.props.data.printScale)) : undefined,
                }}>

                {/* <div className={'RowDiv parent-width justify-content-center docFontStyleBold'}
                    style={{
                        fontSize: FONT_SIZE.medium,
                        padding: getVW(4)
                    }}>
                    e-Invoice Print
                </div> */}
                <div className='RowDiv parent-width justify-content-between docFontStyleBold'
                    style={{
                        backgroundColor: 'rgb(244, 244, 244)',
                        // padding: getVW(4),
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xMedium }, this.props.data.printScale)
                    }}>
                    <div className='ColumnDiv'
                        style={{
                            flex:1,
                            paddingLeft: getVW(Utility.convertToPrintScale(20, this.props.data.printScale))
                        }}>
                        Government of India e-Invoice System
                    </div>
                    <div className='ColumnDiv' style={{
                        // width: getVW(128),
                        // height: getVW(128),
                        flex: 2.5,
                        alignItems: 'flex-end'
                    }}>
                        {this.getQRCode()}
                    </div>
                </div>
                <div className='ColumnDiv parent-width'
                    style={{
                        backgroundColor: 'white',
                        marginTop: getVW(Utility.convertToPrintScale(4, this.props.data.printScale)),
                        marginBottom: getVW(Utility.convertToPrintScale(4, this.props.data.printScale)),
                        paddingTop: getVW(Utility.convertToPrintScale(4, this.props.data.printScale)),
                        paddingBottom: getVW(Utility.convertToPrintScale(4, this.props.data.printScale)),
                    }}>
                    <div className='RowDiv parent-width docFontStyleBold' style={{
                        backgroundColor: 'rgb(244, 244, 244)',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.props.data.printScale)
                    }}>
                        <div className='RowDiv'
                            style={{
                                margin: getVW(Utility.convertToPrintScale(4, this.props.data.printScale)),
                            }}>
                                e-Invoice Details
                        </div>

                    </div>
                    <div className='RowDiv parent-width'>
                        {this.renderColumn('IRN', this.props.data.irn)}
                        {this.renderColumn('Ack. No', this.props.data.ackNo)}
                        {this.renderColumn('Ack. Date', this.props.data.ackDt)}
                    </div>
                </div>
            </div>
        )
    }

    renderColumn = (title, value) => {
        return <div className='RowDiv parent-width'
            style={{
                flex: 1,
                border: '1px solid',
                borderColor: 'rgb(244, 244, 244)',
                height: getVW(Utility.convertToPrintScale(36, this.props.data.printScale)),
            }}>
            <div className='ColumnDiv'
                style={{
                    padding: getVW(Utility.convertToPrintScale(4, this.props.data.printScale)),
                    fontWeight: 700,
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.props.data.printScale)
                }}>
                {title}
                            </div>
            <div className='ColumnDiv'
                style={{
                    padding: getVW(Utility.convertToPrintScale(4, this.props.data.printScale)),
                    textAlign: 'left',
                    wordBreak:'break-all',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.small }, this.props.data.printScale)
                }}>
                {this.getDefaultValue(value)}
            </div>
        </div>
    }

    getQRCode = () => {
        return <QRCode
                value={this.getDefaultValue(this.props.data.signedQRCode)}
                size={Utility.convertToPrintScale(this.getQRSize(), this.props.data.printScale)}/>
    }

    getQRSize = () => {
        var isQRResize = TemplateSettingsManager.getEInvoiceSectionIsQRResize()
        if (isQRResize) {
            return 200 + TemplateSettingsManager.getEInvoiceSectionQRResizeValue()
        }
        return 200
    }

    getDefaultValue = (value) => {
        if (value === undefined || value === null) {
            return ''
        }
        return value
    }

    getQRCodeValue = (value) => {
        if (value === undefined || value === null || value === '') {
            return ''
        }
        var data = JSON.stringify(ApiManager.parseJwt(value))
        return data
    }
}
