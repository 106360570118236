import React, { Component } from "react";
import "../../DocPreview.css";
import "../../CommonStyles/FontStyle.css";
import { FONT_SIZE, LINE_ITEM_HEIGHT, LINE_ITEM_HEIGHT_2 } from '../../Constants/Constants';
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import DKEInvoiceSection, { eInvoiceSectionPosition } from "../../DKUILibrary/DKEInvoiceSection";
import DKSignatureSection from "../../DKUILibrary/DKSignatureSection";
import { AddressType } from "../../Helper/AddressParser";
import ComponentManager from "../../Manager/ComponentManager";
import DocumentManager from '../../Manager/DocumentManager';
import { getLocalisedText } from '../../Translate/LanguageManager';
import { HTMLTag } from "../../Utilities/HTMLTag";
import Utility, { getFontSizeClass, getIsFulfillment, getIsGoodsReceipt, getIsJournalEntry, getPageHeight, getVW } from "../../Utilities/Utility";
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";

export default class DocumentTemplate21 extends Component {

    // styleTemp4 = {
    //     mainContainer: {
    //         position: "relative",
    //         width: "100%",
    //         height: "100%",
    //     }
    // };
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ justifyContent: 'space-between', minHeight: getPageHeight(this.state.data.printScale) }}>
                <div className='parent-width' style={{ width: '100%', paddingBottom: getVW(30)  }}>
                    <DKEInvoiceSection
                        data={this.state.data}
                        position={eInvoiceSectionPosition.top}
                    />
                    {this.addHeaderSection()}
                    {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(40, this.state.data.printScale))}
                    {ComponentManager.addDivider({backgroundColor: this.state.data.themeColor, height: getVW(1), width: '100%'})}
                    {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(40, this.state.data.printScale))}
                    {this.addBillToPlusInvoiceSection()}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data) || Utility.getIsShowPlaceOfSupply(this.state.data)) && ComponentManager.addVerticalSpace(20)}
                    {Utility.getIsShowPlaceOfSupply(this.state.data) && this.getPlaceOfSupplyInfoSection()}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && this.getSourceOfSupplyInfoSection()}
                    {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(20)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} customFieldPosition={'top'} />
                    {ComponentManager.addVerticalSpace(20)}
                    {
                        TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                        <>
                            <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                                dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                            {ComponentManager.addVerticalSpace(5)}
                        </>
                    }
                    {this.getProductTable()}
                    {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(20)}
                    {this.getTableSection()}
                    {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(20)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} customFieldPosition={'bottom'} />
                    <div className='parent-width' style={{
                        marginTop: getVW(10)
                    }}>
                        {(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) && <DKSignatureSection data={this.state.data} onSignatureSectionClicked={this.props.tableClicked} />}
                    </div>
                    {this.getTnCNotesPlusSignatureSection()}
                </div>
                <div className='parent-width' style={{ width: '100%' }}>
                    {this.addFooterSection()}
                </div>
                <DKEInvoiceSection
                    data={this.state.data}
                    position={eInvoiceSectionPosition.bottom}
                />
            </div>

        );
    }

    /////// Header Section ///////////
    addHeaderSection() {
        return (
            <div className="ColumnDiv"
                style={{
                    width: '100%',
                    alignItems: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start'
                }}>
                {this.state.data.showCompanyName && this.getCompanyNameLabel()}
                {this.state.data.showFrom && this.getCompanyInformationView()}
            </div>
        )
    }

    getCompanyNameLabel() {
        return(
            <div className="RowDiv"
                style={{
                    flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row'
                }}>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        textAlign: 'center',
                        paddingBottom: getVW(5),
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                    }}>
                        {getLocalisedText(Utility.getCompanyName(this.state.data))}
                </text>
            </div>
        )
    }

    getCompanyInformationView() {
        return(
            <div className="ColumnDiv">
                <div className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        whiteSpace: 'pre-wrap',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.from) }, this.state.data.printScale)
                    }}
                    dangerouslySetInnerHTML={{__html:getLocalisedText(Utility.getCompanyAddress(this.state.data))}}>
                </div>
            </div>
        )
    }

    /////// Bill To Plus Invoice Section ///////////
    addBillToPlusInvoiceSection() {
        return(
            <div className="RowDiv" style={{width: '100%', alignItems: 'flex-start'}}>
                <div className="ColumnDiv" style={{width: '100%'}}>
                    {this.state.data.showClientBillToAddress && this.getBillingAddress()}
                </div>
                <div className="ColumnDiv" style={{width: '100%'}}>
                    {this.getInvoiceDetailsView()}
                </div>

            </div>
        )
    }

    getBillingAddress() {
        return <>
            <div className='RowDiv'
            style={{
                flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                width: Utility.getIsArabicLang() ? '70%' : '100%'
            }}>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        paddingBottom: getVW(5),
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.billTo) }, this.state.data.printScale)
                    }}>
                        {getLocalisedText(this.state.data.billToTitle).toUpperCase()}
                </text>
            </div>

            <div className={"docFontStyle" + getFontSizeClass()}
                style={{
                    width: '70%',
                    overflow: 'hidden',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.billTo) }, this.state.data.printScale)
                }}
                dangerouslySetInnerHTML={{__html:getLocalisedText(Utility.getClientBillToAddress(this.state.data))}}>
            </div>
        </>;
    }

    getInvoiceDetailsView() {
        return(
            <div className="RowDiv" style={{ justifyContent: 'flex-end', width: '100%'}}>
                <div className="ColumnDiv" style={{ justifyContent: 'space-between',width: '70%'}}>
                    <div style={{width: '100%', justifyContent: 'space-around', }}>
                        {this.getDocumentDetailsSection()}
                        {
                            Utility.getIsContainsTotalSection(this.state.data) &&
                            <div style={{ backgroundColor: this.state.data.themeColor, width: '100%' }}>
                                <div style={{ width: '90%', marginLeft: '5%', direction: Utility.getIsSaudiArabiaCountry(this.state.data) ? 'rtl' : 'ltr' }}>
                                    {ComponentManager.getRowValueSection(
                                      getLocalisedText('total') + " " + getLocalisedText('amount') + " :",
                                      Utility.getTotalValue(this.state.data),
                                      "black",
                                      "black",
                                      "bold",
                                      undefined,
                                      this.state.data.printScale
                                    )}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }

    getDocumentInfoSection() {
        return <div
            className="ColumnDiv"
            style={{ width: "30%", backgroundColor: "white" }}
        >
            {this.getDocumentDetailsSection()}
        </div>;
    }

    getDocumentDetailsSection() {
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        return documentDetailList.map(element => {
            var value = ''
            if (element.key !== undefined) {
                value = this.state.data[element.key]
            }
            else if (element.customFieldValue !== undefined) {
                value = element.customFieldValue
            }
            return <div style={{ width: '90%', marginLeft: '5%' }}>
                {ComponentManager.getRowValueSection(
                    element.label,
                    value,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    this.state.data.printScale
                )}
            </div>

        });
    }

    getRowValueSection(title, value, titleColor = "black") {
        return (
            <div
                className="RowDiv"
                style={{
                    justifyContent: "space-evenly",
                    flex: 1,
                }}
            >
                <text className={"RowReverseDiv docFontStyleBold" + getFontSizeClass()} style={{ color: titleColor, width: '50%',  justifyContent: 'end', paddingRight:getVW(20)}}>
                    {title.toUpperCase()}
                </text>
                <div
                    className="RowDiv"
                    style={{
                        flexDirection: 'row',
                        border: 'solid',
                        borderColor: 'lightgray',
                        borderWidth: getVW(1),
                        width: '50%'}}>
                    <text className={"RowDiv docFontStyle" + getFontSizeClass()}
                            style={{
                                paddingLeft: getVW(10),
                                height: getVW(LINE_ITEM_HEIGHT_2, true),
                                justifyContent: 'center'
                            }}>
                        {value}
                    </text>
                </div>
            </div>
        );
    }

    /////// Table Section ///////////
    getTableSection() {
        return (
            <div className="ColumnDiv" style={{width: '100%'}}>
                {this.getEditableDocumentTable()}
            </div>
        )
    }

    getTnCNotesPlusSignatureSection() {
        return <div className="RowDiv"
            style={{
                alignItems: 'flex-start',
                marginTop: getVW(10)
            }}>
            <div style={{
                width: '100%',
                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                }}>
                {((this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) || (this.state.data.showNotes && this.state.data.notes)) && this.addTNCANdNotesSection()}
            </div>
            <div id={HTMLTag.SIGNATURE} className="RowDiv" style={{width: '30%'}}>
                {this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature, this.state.data.printScale)}
            </div>
        </div>;

    }

    addTNCANdNotesSection() {
        return(
            <div id={HTMLTag.TERMS_NOTE_PREFIX} className="ColumnDiv" style={{
                // marginTop: ((this.state.data.lineItems.length + 2.5) * 2.8)+'vw',
                width: '100%',
                // position:'absolute'
            }}>
                {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && this.getTermsAndConditions()}
                {(this.state.data.showNotes && this.state.data.notes) && this.getNotes()}
            </div>
        )
    }

    getTermsAndConditions() {
        return (
            <div id={HTMLTag.TERMS_NOTE_PREFIX + HTMLTag.TERMS} style={{width: '100%'}}>
                <div
                    className="RowDiv"
                    style =
                        {{
                            height: getVW(`${Utility.convertToPrintScale(Number(LINE_ITEM_HEIGHT.replace('vw', '')), this.state.data.printScale)}vw`, true),
                            justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                            alignItems: 'center',
                        }}>
                    <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            paddingLeft: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                            color: 'black',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                        }}>
                        {Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition'))}
                    </text>
                </div>

                <div
                    className="RowDiv"
                    style=
                        {{
                            width: '100%',
                            paddingBottom : getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                            justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                        }}>
                    <text className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            paddingLeft: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                        }}>
                        {getLocalisedText(this.state.data.termsAndCondition)}
                    </text>
                </div>

            </div>
        )
    }

    getNotes() {
        return (
            <div id={HTMLTag.TERMS_NOTE_PREFIX + HTMLTag.NOTES} style={{width: '100%'}}>
                <div
                    className="RowDiv"
                    style =
                        {{
                            height: getVW(`${Utility.convertToPrintScale(Number(LINE_ITEM_HEIGHT.replace('vw', '')), this.state.data.printScale)}vw`, true),
                            justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                            alignItems: 'center'
                        }}>
                    <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            paddingLeft: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                            color: 'black',
                            justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                        }}>
                        {Utility.getNotesTitle(getIsJournalEntry(this.state.data.type) ? getLocalisedText('memo') : getLocalisedText('notes'))}
                    </text>
                </div>

                <div
                    className="RowDiv"
                    style=
                        {{
                            width: '100%',
                            flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                        }}>
                    <text className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            paddingLeft: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                        }}>
                        {getLocalisedText(this.state.data.notes)}
                    </text>
                </div>
            </div>
        )
    }

    /////// Footer Section ///////////
    addFooterSection() {
        return (
            <div className="ColumnDiv" style={{width: '100%', bottom: 0, alignItems: 'center',justifyContent: 'center'}}>
                {ComponentManager.addDivider({backgroundColor: this.state.data.themeColor, height: getVW(1), width: '100%'})}
                {ComponentManager.addVerticalSpace(10)}
                {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) && ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(80, this.state.data.printScale), {position: 'relative'})}
                {ComponentManager.addVerticalSpace(10)}
                <text className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                    }}>
                        {getLocalisedText('powered_by_deskera')}
                </text>
            </div>
        )
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getProductTable() {
        if(Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKProductTable(
                Utility.getTableColumnForAccountProduct(),
                this.getTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerBackgroundColor = this.state.data.themeColor;
        style.headerTextColor = "#000000";
        style.headerBorderColor = "transparent";
        style.itemBorderColor = this.state.data.themeColor;
        style.footerBackgroundColor = this.state.data.themeColor;
        style.footerTextColor = "black";
        style.footerBorderColor = "transparent";
        style.subTotalBorderColor = "transparent";
        return style;
    }

    getPlaceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getPlaceOfSupply()}

            </div>
        )
    }
    getPlaceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('place_of_supply'),
                    this.state.data.placeOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>

        </div>;

    }


    getSourceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getSourceOfSupply()}

            </div>
        )
    }

     getSourceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowSourceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('source_of_supply'),
                    this.state.data.sourceOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowDestinationOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('destination_of_supply'),
                    this.state.data.destinationOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
          </div>

        </div>;
    }
}

