import { DateFormat } from "../../Components/TemplateEditors/DocumentEditor"
import ComponentManager from "../../Manager/ComponentManager"
import CustomFieldManager from "../../Manager/CustomFieldManager"
import TemplateSettingsManager from "../../Manager/TemplateSettingsManager"
import { convertDateFromERPFormatToDocFormat, getCapitalized, getConvertedDate, getDateFrom, getDateString, getIsInvoice, getIsSalesOrder } from "../../Utilities/Utility"
import AdditionalChargeParser from "../AdditionalChargeParser"
import Address from "../Address"
import AddressParser, { AddressType, OwnerType } from "../AddressParser"
import DataParser from "../DataParser"
import LineItemsParser from "../LineItemsParser"
import TenantParser from "../TenantParser"

export default class QuoteAndInvoiceParser {
    static parseData(dataFromAPI, templateDataToReturn, isGeneratedData, isReadOnlyMode) {

        //custom field
        templateDataToReturn.customFields = dataFromAPI.customFields
        CustomFieldManager.setCustomFields(dataFromAPI.customFields)
        templateDataToReturn = DataParser.assignCustomFieldValue(templateDataToReturn)

        //company name
        templateDataToReturn.companyName = getCapitalized(dataFromAPI.companyName)
        templateDataToReturn.tenantInfo = dataFromAPI.tenantInfo;

        //company address
        var fromAddressObj = new Address(undefined, AddressParser.getTenantAddressFromApi(dataFromAPI.shipFromAddress, dataFromAPI.tenantInfo), dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
        templateDataToReturn.fromObj = fromAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.from, templateDataToReturn)

        // billing to address
        var clientBillToAddressObj = new Address(dataFromAPI.billToName, dataFromAPI.billToAddress, dataFromAPI.contactInfo.pan, dataFromAPI.contactInfo.gstin, dataFromAPI.contactInfo.contactNumber, dataFromAPI.contactInfo.documentSequenceCode, OwnerType.contact)
        templateDataToReturn.clientBillToAddressObj = clientBillToAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.billTo, templateDataToReturn)

        // shipping to address
        var clientShipToAddressObj = new Address(dataFromAPI.shipToName, dataFromAPI.shipToAddress, dataFromAPI.contactInfo.pan, dataFromAPI.contactInfo.gstin, dataFromAPI.contactInfo.contactNumber, dataFromAPI.contactInfo.documentSequenceCode, OwnerType.contact)
        templateDataToReturn.clientShipToAddressObj = clientShipToAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.shipTo, templateDataToReturn)

        //SA Address
        templateDataToReturn = this.getSaudiArabiaAddress(dataFromAPI, templateDataToReturn)

        //ref number
        templateDataToReturn.refNumber = dataFromAPI.refNumber

        //start date
        templateDataToReturn.date = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.date), templateDataToReturn.dateFormat)

        templateDataToReturn.dueDate = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.dueDate), templateDataToReturn.dateFormat)
        templateDataToReturn.showDueDate = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.dueDate.isVisible
        if (dataFromAPI.isEwayGenerated === 'Yes') {
            templateDataToReturn.ewayGenerated = true;
            templateDataToReturn.ewayBillNo = dataFromAPI.ewayBillNo;
            try {
                templateDataToReturn.ewayBillDate = getDateString(getDateFrom(dataFromAPI.ewayBillDate, DateFormat.DD_MM_YYYY_HH_MM_SS_SLASH), templateDataToReturn.dateFormat);
            } catch (error) {}
        }
        if (dataFromAPI?.tenantInfo?.gstin && dataFromAPI?.tenantInfo?.country==='IN') {
            templateDataToReturn.placeOfSupply = dataFromAPI.shipToAddress?.placeOfSupply ? dataFromAPI.shipToAddress?.placeOfSupply  : dataFromAPI.shipToAddress.state ;
        }

        //line items
        templateDataToReturn.lineItems = LineItemsParser.getProductLineItem(dataFromAPI, isGeneratedData, templateDataToReturn.dateFormat)

        //stock items
        if (isReadOnlyMode) {
            var isContainStockItem = false
            if (templateDataToReturn.stockItems !== undefined && templateDataToReturn.stockItems !== null)  {
                isContainStockItem = templateDataToReturn.stockItems.length > 0
            }
            if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.stockTable === undefined) {
                TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails['stockTable'] = { 'isVisible': true }
            }
            TemplateSettingsManager.updateStockTableVisibility(isContainStockItem)
            templateDataToReturn.showStockTable = isContainStockItem
        }
        else {
            templateDataToReturn.showStockTable = true
            TemplateSettingsManager.updateStockTableVisibility(true)
        }

        //payment link
        if (dataFromAPI.paymentLink === null || dataFromAPI.paymentLink === undefined) {
            templateDataToReturn.paymentItems = []
        }
        else if (dataFromAPI.paymentLink === "") {
            templateDataToReturn.paymentItems = []
        } else {
            templateDataToReturn.paymentItems = [dataFromAPI.paymentLink]
        }

        //payment items
        if (isReadOnlyMode) {
            var isContainPaymentItem = templateDataToReturn.paymentItems.length > 0
            if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.paymentTable === undefined) {
                TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails['paymentTable'] = { 'isVisible': true }
            }

            TemplateSettingsManager.updatePaymentTableVisibility(isContainPaymentItem)
            templateDataToReturn.showPaymentTable = isContainPaymentItem
        }
        else {
            if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.paymentTable === undefined) {
                TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails['paymentTable'] = { 'isVisible': true }
            }
            else {
                templateDataToReturn.showPaymentTable = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.paymentTable.isVisible
            }
        }

        //additional date
        if (dataFromAPI.shipByDate !== undefined && dataFromAPI.shipByDate !== null) {
            templateDataToReturn.additionalDate = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.shipByDate), templateDataToReturn.dateFormat)
            if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.additionalDate !== undefined) {
                templateDataToReturn.showAdditionalDate = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.additionalDate.isVisible
            }
        }

        //add E invoice data
        templateDataToReturn.irn = dataFromAPI.irn
        templateDataToReturn.ackDt = dataFromAPI.ackDt
        templateDataToReturn.ackNo = dataFromAPI.ackNo
        templateDataToReturn.signedQRCode = dataFromAPI.signedQRCode
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.eInvoiceSection === undefined) {
            TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails['eInvoiceSection'] = { 'isVisible': false }
        }
        else {
            templateDataToReturn.showEInvoiceSection = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.eInvoiceSection.isVisible
            if (templateDataToReturn.signedQRCode === undefined || templateDataToReturn.signedQRCode === null || templateDataToReturn.signedQRCode === '') {
                templateDataToReturn.showEInvoiceSection = false
            }
        }

        //location
        templateDataToReturn = TenantParser.parseLocationDetail(dataFromAPI, templateDataToReturn)

        //Additional charge and global
        templateDataToReturn = AdditionalChargeParser.getAdditionalCharge(dataFromAPI, templateDataToReturn)

        templateDataToReturn.paymentsFooter = dataFromAPI.payments
        if (templateDataToReturn.showPaymentFooter) {
            templateDataToReturn.payments = templateDataToReturn.paymentsFooter
        }

        if (getIsInvoice(templateDataToReturn.type) || getIsSalesOrder(templateDataToReturn.type)) {
            templateDataToReturn.linkDocumentType = undefined
            templateDataToReturn.linkDocumentNumber = undefined
            if (dataFromAPI.linkedDocument !== undefined && dataFromAPI.linkedDocument !== null) {
                if (dataFromAPI.linkedDocument !== '') {
                    var linkDocumentType = dataFromAPI.linkDocumentType ? dataFromAPI.linkDocumentType.toLowerCase() : ''
                    templateDataToReturn.linkDocumentType = linkDocumentType
                    templateDataToReturn.linkDocumentNumber = dataFromAPI.linkedDocument
                }
            }
        }

        if (dataFromAPI.theyOweYou !== undefined && dataFromAPI.theyOweYou !== null) {
            if(dataFromAPI.total !== undefined && dataFromAPI.total !== null) {
                templateDataToReturn.outstandingBalance = dataFromAPI.theyOweYou - dataFromAPI.total
            }
            else {
                templateDataToReturn.outstandingBalance = dataFromAPI.theyOweYou - ComponentManager.getTotalOfLineItems(templateDataToReturn.lineItems, false, false, 0)
            }
            templateDataToReturn.outstandingBalanceIncludesCurrent = dataFromAPI.theyOweYou
        }

        templateDataToReturn.linkedDocuments = []
        if (dataFromAPI.linkedDocuments !== undefined && dataFromAPI.linkedDocuments !== null) {
            if (dataFromAPI.linkedDocuments.length > 0) {
                if (dataFromAPI.linkedDocuments.length === 1) {
                    templateDataToReturn.parentLinkDocumentType = dataFromAPI.linkedDocuments[0].documentType
                    templateDataToReturn.parentLinkDocumentNumber = dataFromAPI.linkedDocuments[0].documentSequenceCode
                    templateDataToReturn.linkDocumentType = ''
                    templateDataToReturn.linkDocumentNumber = ''
                }
                if (dataFromAPI.linkedDocuments.length === 2) {
                    templateDataToReturn.parentLinkDocumentType = dataFromAPI.linkedDocuments[0].documentType
                    templateDataToReturn.parentLinkDocumentNumber = dataFromAPI.linkedDocuments[0].documentSequenceCode
                    templateDataToReturn.linkDocumentType = dataFromAPI.linkedDocuments[1].documentType
                    templateDataToReturn.linkDocumentNumber = dataFromAPI.linkedDocuments[1].documentSequenceCode
                }
                templateDataToReturn.linkedDocuments = dataFromAPI.linkedDocuments
            }
        }

        return templateDataToReturn
    }

    static getSaudiArabiaAddress(dataFromAPI, templateDataToReturn) {
        if (dataFromAPI.country === 'SA') {
            //Company Name
            var name = templateDataToReturn.companyName
            if (dataFromAPI.companyNameInArabic !== undefined && dataFromAPI.companyNameInArabic !== null) {
                name = dataFromAPI.companyNameInArabic
            }

            templateDataToReturn.companyNameInArabic = templateDataToReturn.companyName
            if (name !== templateDataToReturn.companyName) {
                if (name.length > 0) {
                    templateDataToReturn.companyNameInArabic = name
                }
            }

            //contact address
            var contactNameInArabic = ''
            if (dataFromAPI.contactNameInArabic !== undefined && dataFromAPI.contactNameInArabic !== null) {
                contactNameInArabic = dataFromAPI.contactNameInArabic
            }

            if (contactNameInArabic !== '') {
                templateDataToReturn.contactNameInArabic = contactNameInArabic
            }

            //Company Address
            var fromAddressInArabicObj = new Address(undefined, dataFromAPI.shipFromAddressInArabic, dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
            if (dataFromAPI.tenantInfoInArabic !== undefined && dataFromAPI.tenantInfoInArabic !== null) {
                if (dataFromAPI.tenantInfoInArabic.billingAddresses !== undefined && dataFromAPI.tenantInfoInArabic.billingAddresses !== null) {
                    var preferAddress = AddressParser.getPreferAddress(dataFromAPI.tenantInfoInArabic.billingAddresses)
                    if (preferAddress !== undefined) {
                        fromAddressInArabicObj = new Address(undefined, preferAddress, dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
                    }
                }
            }

            templateDataToReturn.fromInArabicObj = fromAddressInArabicObj
            if (fromAddressInArabicObj !== undefined && fromAddressInArabicObj !== null) {
                templateDataToReturn = AddressParser.assignAddressObjToStringForArabic(undefined, AddressType.from, templateDataToReturn)
            }
            else {
                templateDataToReturn.fromInArabicObj = templateDataToReturn.fromObj
                templateDataToReturn.fromInArabic = templateDataToReturn.from
            }

            //billing address
            var clientBillToAddressObj = undefined
            clientBillToAddressObj = new Address(templateDataToReturn.contactNameInArabic, dataFromAPI.billToAddressInArabic, dataFromAPI.contactInfo.pan, dataFromAPI.contactInfo.gstin, dataFromAPI.contactInfo.contactNumber, dataFromAPI.contactInfo.documentSequenceCode, OwnerType.contact)

            templateDataToReturn.clientBillToAddressInArabicObj = clientBillToAddressObj
            if (clientBillToAddressObj !== undefined && clientBillToAddressObj !== null) {
                templateDataToReturn = AddressParser.assignAddressObjToStringForArabic(undefined, AddressType.billTo, templateDataToReturn)
            }
            else {
                templateDataToReturn.billToInArabic = templateDataToReturn.billTo
                templateDataToReturn.clientBillToAddressInArabic = templateDataToReturn.clientBillToAddress
            }

            //Ship To Address
            var clientShipToAddressObj = new Address(templateDataToReturn.contactNameInArabic, dataFromAPI.shipToAddressInArabic, dataFromAPI.contactInfo.pan, dataFromAPI.contactInfo.gstin, dataFromAPI.contactInfo.contactNumber, dataFromAPI.contactInfo.documentSequenceCode, OwnerType.contact)

            templateDataToReturn.clientShipToAddressInArabicObj = clientShipToAddressObj
            if (clientShipToAddressObj !== undefined && clientShipToAddressObj !== null) {
                templateDataToReturn = AddressParser.assignAddressObjToStringForArabic(undefined, AddressType.shipTo, templateDataToReturn)
            }
            else {
                templateDataToReturn.shipToInArabic = templateDataToReturn.shipTo
                templateDataToReturn.clientShipToAddressInArabic = templateDataToReturn.clientShipToAddress
            }
        }
        return templateDataToReturn
    }
}
