import React from 'react';
import { DKDataGrid, DKIcon, DKLabel } from 'deskera-ui-library';
import SideBarService from '../../services/common/sidebar';
import ic_no_data from '../../assets/icons/ic_no_data.png';
import { useAppSelector } from '../../store/hooks';
import { convertCRMDateFormatToUILibraryFormat } from '../../utility/Date';
import { DATE_FORMATS } from '../../constants/Enum';
import { selectTenantDetails } from '../../store/slices/booksSlice';

interface DocumentGridProps {
  documentType: string;
  columns: any[];
  rows: any[];
  title: string;
  needShadow?: boolean;
  needBorder?: boolean;
  needBoldTheme?: boolean;
  allowRowEdit?: boolean;
  allowColumnEdit?: boolean;
  allowRowAdd?: boolean;
  allowColumnAdd?: boolean;
  allowColumnDelete?: boolean;
  allowColumnShift?: boolean;
  allowColumnSort?: boolean;
  allowSearch?: boolean;
  allowFilter?: boolean;
  allowBulkOperation?: boolean;
  noDataTitle?: string;
  noDataText?: string;
  updating?: boolean;
  needTrailingColumn?: boolean;
  onSearch?: (searchTerm: any) => void;
  onPaginationClick?: (requestedPageNumber: any) => void;
  onFilter?: (data: any) => void;
  onRowOpenClick?: (data: any) => void;
  onRowSelect?: (data: any) => void;
  onAllRowSelect?: ({ selected }: any) => void;
  width?: number;
  onPageChange?: (page: any) => void;
  currentPage?: number;
  totalPageCount?: number;
  filterData?: any;
  headerButtons?: any;
  isAllRowSelected?: boolean;
}

const DocumentGrid: React.FC<DocumentGridProps> = (props) => {
  const tenantDetails = useAppSelector(selectTenantDetails);

  const onSearch = (searchTerm: string) => {
    if (props.onSearch) {
      props.onSearch(searchTerm);
    }
  };

  const getNoDataView = () => {
    let noDataTitle = '';
    let noDataText = '';

    if (props.updating) {
      noDataTitle = 'Loading...';
      noDataText = 'Please wait';
    } else {
      if (!props.rows.length) {
        noDataTitle = props.noDataTitle ? props.noDataTitle : 'No data found';
        noDataText = props.noDataText
          ? props.noDataText
          : 'Once data is available, it will appear here';
      }
    }

    return props.rows.length ? null : (
      <div
        className="column justify-self-center align-self-center align-items-center position-absolute"
        style={{ top: 200, pointerEvents: 'none' }}
      >
        <DKIcon src={ic_no_data} className="ic-l opacity-20" />
        <DKLabel text={noDataTitle} className="fw-m mt-l" />
        <DKLabel text={noDataText} className="text-gray mt-s" />
      </div>
    );
  };
  return (
    <>
      <div className="row">
        <DKDataGrid
          title={props.title}
          rows={props.rows}
          columns={props.columns}
          allowSearch={!!props.allowSearch}
          needShadow={!!props.needShadow}
          needBorder={!!props.needBorder}
          needBoldTheme={!!props.needBoldTheme}
          allowFilter={!!props.allowFilter}
          allowRowEdit={!!props.allowRowEdit}
          allowColumnEdit={!!props.allowColumnEdit}
          allowColumnSort={!!props.allowColumnSort}
          allowRowAdd={!!props.allowRowAdd}
          allowColumnAdd={!!props.allowColumnAdd}
          allowColumnDelete={!!props.allowColumnDelete}
          allowColumnShift={!!props.allowColumnShift}
          allowBulkOperation={!!props.allowBulkOperation}
          needTrailingColumn={!!props.needTrailingColumn}
          width={props?.width ?? SideBarService.getContainerWidth()}
          currentPage={(props?.currentPage || 0) + 1}
          totalPageCount={props.totalPageCount}
          onPrevPageTapped={() =>
            props?.onPageChange?.((props?.currentPage || 0) - 1)
          }
          onNextPageTapped={() =>
            props?.onPageChange?.((props?.currentPage || 0) + 1)
          }
          onSearch={(data: any) => onSearch(data)}
          onPageChange={(page: number) => props?.onPageChange?.(page - 1)}
          onFilter={props?.onFilter}
          filterData={props.filterData}
          buttons={props.headerButtons}
          onRowOpenClick={props?.onRowOpenClick}
          onAllRowSelect={props?.onAllRowSelect}
          isAllRowSelected={props?.isAllRowSelected}
          onRowSelect={props?.onRowSelect}
          updating={!!props?.updating}
          dateFormat={convertCRMDateFormatToUILibraryFormat(tenantDetails?.dateFormat || DATE_FORMATS['DD-MM-YYYY'])}
        />
      </div>
      {props.rows?.length === 0 && getNoDataView()}
    </>
  );
};

export default DocumentGrid;
