import {DateFormat, EditorInfoType} from "../Components/TemplateEditors/DocumentEditor";
import {SignatureColumnType, TableColumnPopupType} from "../Components/Popup/TableColumnPopup";
import {getLocalisedText} from "../Translate/LanguageManager";
import AppManager from "./AppManager";
import Utility, { getIsAccountModule, getIsBill, getIsCheque, getIsExpenseBill, getIsExpenseOrDeposit, getIsFulfillment, getIsGoodsReceipt, getIsJournalEntry, getIsMakePayment, getIsPackList, getIsPayslip, getIsPayrollCheck, getIsPickList, getIsReceivePayment, getIsShipList, getIsSalesOrder, getIsEwayBillSummary, getIsEwayBillDetails, getIsWorkOrder, getIsJobCard, getIsPurchaseRequisition, getIsQcTemplate, getIsStockTransfer, getIsStockAdjustment } from "../Utilities/Utility";
import ExpenseMapper from "../DocumentMapper/ExpenseMapper";
import DepositMapper from "../DocumentMapper/DepositMapper";
import CreditNoteMapper from "../DocumentMapper/CreditNoteMapper";
import FulfillmentMapper from "../DocumentMapper/FulfillmentMapper";
import GoodsReceiptMapper from "../DocumentMapper/GoodsReceiptMapper";
import PaymentReceiptMapper from "../DocumentMapper/PaymentReceiptMapper";
import { AddressFieldType } from "../Helper/AddressParser";
import DocumentDetailsParser from "../Helper/DocumentDetailsParser";

export default class TemplateSettingsManager {

    ///////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////
    static remoteTemplateUID = null
    static customTemplateList: any[] = []
    static customTemplateName = ''
    static printInfo: any = undefined
    static isSaveAsNewTemplate = false
    static pageFormat = undefined
    static isDefaultTempalte = null

    static updateRemoteTemplateUID(val: any) {
        TemplateSettingsManager.remoteTemplateUID = val
    }

    static getRemoteTemplateUID() {
        return TemplateSettingsManager.remoteTemplateUID
    }

    static setCustomTemplateList(val: any) {
        TemplateSettingsManager.customTemplateList = val
    }

    static getCustomTemplateList() {
        return TemplateSettingsManager.customTemplateList
    }

    static setCustomTemplateName(val: any) {
        TemplateSettingsManager.customTemplateName = val
    }

    static getCustomTemplateName() {
        return TemplateSettingsManager.customTemplateName
    }

    static setPrintInfo(val: any) {
        TemplateSettingsManager.printInfo = val
    }

    static getPrintInfo() {
        return TemplateSettingsManager.printInfo
    }

    static setIsDefaultTemplate(val: any) {
        this.isDefaultTempalte = val
    }

    static getIsDefaultTemplate() {
        if (this.getIsSaveAsNewTemplate()) {
            return this.isDefaultTempalte
        }
        else {
            var template = this.customTemplateList.find(x => x.id === this.remoteTemplateUID)
            if(template !== undefined) {
                return template.defaultTemplate
            }
            else {
                return this.isDefaultTempalte
            }
        }
    }

    static getIsMultipleDocument() {
        var printInfo = TemplateSettingsManager.printInfo
        if(printInfo !== undefined && printInfo !== null) {
            if (printInfo.id !== undefined && printInfo.id !== null) {
                if (printInfo.id !== '') {
                    return printInfo.id.includes(',')
                }
            }
        }
        return false
    }
    static setIsSaveAsNewTemplate(val: any) {
        TemplateSettingsManager.isSaveAsNewTemplate = val
    }

    static getIsSaveAsNewTemplate() {
        return TemplateSettingsManager.isSaveAsNewTemplate
    }

    static setPageFormat(val: any) {
        TemplateSettingsManager.pageFormat = val
    }

    static updatePageFormat(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.pageFormat = val
    }

    static getPageFormat() {
        return TemplateSettingsManager.pageFormat
    }

    ///////////////////////////////////////////////////////////////////////
    // Default Template Settings Changes Made Tracker
    //////////////////////////////////////////////////////////////////////

    static templateSettingTracker = false

    static userDidChangeTemplateSetting() {
        TemplateSettingsManager.templateSettingTracker = true
    }

    static revertTemplateSettingManagerToFactorySettings() {
        return TemplateSettingsManager.templateSettingTracker = false
    }

    static hasTemplateChanged() {
        return TemplateSettingsManager.templateSettingTracker
    }

    ///////////////////////////////////////////////////////////////////////
    // Default Template Settings
    //////////////////////////////////////////////////////////////////////
    static defaultTemplateSettings: any = null

    static initializeDefaultTemplate() {
        TemplateSettingsManager.defaultTemplateSettings = {
            "templateInfo": {
                "id": this.getInitialTemplateID(),
                "themeColor": this.getInitialTemplateThemeColor(),
                "style": this.getDefaultTemplateStyle(),
            },
            "documentInfo": {
                "type" : {
                    "isVisible": true,
                    "labelText": null,
                },
                "companyDetails" : {
                    "name": {
                        "isVisible": this.getInitialShowCompanyName()
                    },
                    "address": {
                        "isVisible": this.getInitialShowCompanyAddress()
                    },
                    "logo": {
                        "isVisible": true,
                        "image": this.getInitialCompanyLogo()
                    }
                },
                "clientAddresses" : {
                    "billToDetails" : {
                        "isVisible": this.getInitialShowBillToAddress(),
                        "title": "bill_to"
                    },
                    "shipToDetails" : {
                        "isVisible": this.getInitialShowShipToAddress(),
                        "title": "ship_to"
                    },
                    "shipFromDetails" : {
                        "isVisible": this.getInitialShowShipToAddress(),
                        "title": "ship_from"
                    },
                    "billToPlaceholder": {
                        "isVisible": true,
                        "title": "billing_address_placeholder_text_value"
                    },
                    "shipToPlaceholder": {
                        "isVisible": true,
                        "title": "shipping_address_placeholder_text_value"
                    }
                },
                "documentDetails" : {
                    "referenceNumber": {
                        "isVisible": this.getInitialShowDocumentNumber()
                    },
                    "linkDocumentNumber": {
                        "isVisible": true,
                    },
                    "date": {
                        "format": DateFormat.DD_MM_YYYY_SLASH,
                        "startDate": {
                            "isVisible": this.getInitialShowDocumentStartDate()
                        },
                        "dueDate": {
                            "isVisible": this.getInitialShowDocumentDueDate()
                        },
                        "additionalDate": {
                            "isVisible": false
                        },
                    },
                    "signature": {
                        "isVisible": this.getInitialShowSignature(),
                        "image": this.getInitialSignatureImage()
                    },
                    "termsAndConditions": {
                        "isVisible": this.getInitialShowTermsAndConditions(),
                        "text":  "terms_and_condition_placeholder_text",
                        "title": null,
                    },
                    "notes": {
                        "isVisible": this.getInitialShowNotes(),
                        "text":  "notes_placeholder_text",
                        "title": null,
                    },
                    "stockTable": {
                        "isVisible": true,
                    },
                    "paymentTable": {
                        "isVisible": true,
                    },
                    "decimalScale": {
                        "value": 2
                    },
                    "quantityDecimalScale": {
                        "value": 2
                    },
                    "currency": {
                        "isVisible": true,
                        "format": "symbol",
                        "customValue": undefined,
                    },
                    "eInvoiceSection": {
                        "isVisible": true,
                        // "isResizeQR": false,
                        // "position": "top",
                        // "resizeValue": 0,
                    },
                    "lineItemGrouping": {
                        "selection": "none",
                        "key": "none"
                    },
                    "paymentFooter": {
                        "isVisible": true,
                    },
                    "AdditionalCharges": {
                        "isGroup": false,
                    },
                    "accountProductTableConfigIsVisible": false
                },
                "componentList" : [],
                "customFieldList": [],
                "bottomCustomFieldList": [],
                "watermark" : this.getDefaultWatermark(),
                "documentLabel": undefined,
                "supplyConfiguration": undefined,
            },
            "languageInfo": this.getInitialCurrentLanguage(),
            "tableInfo" : [
                { "type": TableColumnPopupType.lineNumber, "isSelected": false, "name": '#' },
                { "type": TableColumnPopupType.code, "isSelected": false, "name": "sn_number" },
                { "type": TableColumnPopupType.image, "isSelected": false, "name": "image" },
                { "type": TableColumnPopupType.productName, "isSelected": true, "name": "products" },
                { "type": TableColumnPopupType.description, "isSelected": true, "name": "description" },
                { "type": TableColumnPopupType.productNameWithDescription, "isSelected": false, "name": "name_with_description" },
                { "type": TableColumnPopupType.productCustomField, "isSelected": false, "name": "product_custom_field" },
                { "type": TableColumnPopupType.quantity, "isSelected": true, "name": "qty" },
                { "type": TableColumnPopupType.uom, "isSelected": false, "name": "uom" },
                { "type": TableColumnPopupType.quantityWithUom, "isSelected": false, "name": "quantity_and_uom" },
                { "type": TableColumnPopupType.serialBatch, isSelected: false, name: "serial_batch" },
                { "type": TableColumnPopupType.serialNumber, isSelected: false, name: "serial_number" },
                { "type": TableColumnPopupType.batchNumber, isSelected: false, name: "batch_number" },
                { "type": TableColumnPopupType.manufacturingDate, isSelected: false, name: "manufacturing_date" },
                { "type": TableColumnPopupType.expiryDate, isSelected: false, name: "expiry_date" },
                { "type": TableColumnPopupType.unitPrice, "isSelected": true, "name": "unit price" },
                { "type": TableColumnPopupType.productDiscount, "isSelected": false, "name": "discount" },
                { "type": TableColumnPopupType.productGlobalDiscount, "isSelected": false, "name": "global_discount" },
                { "type": TableColumnPopupType.productUnitPriceAfterDiscount, "isSelected": false, "name": "unit_price_after_discount" },
                { "type": TableColumnPopupType.productDiscountPerUnit, "isSelected": false, "name": "discount_per_unit" },
                { "type": TableColumnPopupType.productTax, "isSelected": false, "name": "tax" },
                { "type": TableColumnPopupType.productTaxAmount, "isSelected": false, "name": "tax_amount" },
                { "type": TableColumnPopupType.amountBeforeTax, "isSelected": false, "name": "amount_before_tax" },
                { "type": TableColumnPopupType.amount, "isSelected": true, "name": "amount" },
                { "type": TableColumnPopupType.subTotalAmount, "isSelected": true, "name": "subtotal" },
                { "type": TableColumnPopupType.discount, "isSelected": true, "name": "discount" },
                { "type": TableColumnPopupType.beforeTax, "isSelected": false, "name": "before_tax" },
                { "type": TableColumnPopupType.tax, "isSelected": true, "name": "tax" },
                { "type": TableColumnPopupType.globalDiscount, "isSelected": true, "name": "global_discount" },
                { "type": TableColumnPopupType.totalAmount, "isSelected": true, "name": "total" },
                { "type": TableColumnPopupType.outstandingBalance, "isSelected": false, "name": "outstanding_balance" },
                { "type": TableColumnPopupType.outstandingBalanceIncludesCurrent, "isSelected": false, "name": "outstanding_balance_includes_current" },
                { "type": TableColumnPopupType.amountInWords, "isSelected": false, "name": "amount_in_words" },
            ],
            "signatureInfo": [
                { "type": "preparedBy", "isSelected": true, "name": "prepared_by", index: 0 },
                { "type": "signature", "isSelected": true, "name": "signature", index: 1 },
                { "type": "approvedBy", "isSelected": true, "name": "approved_by", index: 2 },
                { "type": "name", "isSelected": true, "name": "name", index: 3 },
            ],
            "totalColumnSection": {
                "isVisible": false,
                "items": []
            },
            "thumbnailInfo" : null,
            "emailInfo": {
                "defaultEmailSubject": null,
            },
            "repeatableTableHeader": {
                "isVisible": true,
            },
            "headerFooter" : {
                "isVisible": true,
            },
            "repeatHeaderFooter": {
                "isVisible": false,
            },
            "addressFormat": this.getDefaultAddressFormat(),
            "payslipFieldConfiguration": {},
            "payslipTableConfiguration": {},
            "payslipOvertime": {
                "isVisible": false,
            },
            "payslipLOP": {
                "isVisible": false,
            },
            "productTableConfiguration": this.getDefaultProductTableConfiguration(),
            "additionalTable": this.getDefaultAdditionalTableConfiguration()
        }
    }

    static removeAllLocalKeys() {
        AppManager.getLocalStorageParameters().forEach((item) => {
            localStorage.removeItem(item)
        })
    }

    ///////////////////////////////////////////////////////////////////////
    // Template Settings Visibility Related Methods
    //////////////////////////////////////////////////////////////////////
    static updateTemplateVisibility(forType: any, val: any) {
        switch (forType) {
            case 'showDocumentType':
                TemplateSettingsManager.updateDocumentTypeVisibility(val)
                break
            case 'showCompanyName':
                TemplateSettingsManager.updateCompanyNameVisibility(val)
                break
            case 'showCompanyLogo':
                TemplateSettingsManager.updateCompanyLogoVisibility(val)
                break
            case 'showFrom':
                TemplateSettingsManager.updateCompanyAddressVisibility(val)
                break
            case 'showClientBillToAddress':
                TemplateSettingsManager.updateBillToAddressVisibility(val)
                break
            case 'showClientShipToAddress':
                TemplateSettingsManager.updateShipToAddressVisibility(val)
                break
            case 'showClientShipFromAddress':
                TemplateSettingsManager.updateShipFromAddressVisibility(val)
                break
            case 'showRefNumber':
                TemplateSettingsManager.updateDocumentReferenceNumberVisibility(val)
                break
            case 'showStartDate':
                TemplateSettingsManager.updateDocumentDateVisibility(val)
                break
            case 'showDueDate':
                TemplateSettingsManager.updateDocumentDueDateVisibility(val)
                break
            case 'showAdditionalDate':
                TemplateSettingsManager.updateDocumentAdditionalDateVisibility(val)
                break
            case 'showSignature':
                TemplateSettingsManager.updateSignatureVisibility(val)
                break
            case 'showTermsAndCondition':
                TemplateSettingsManager.updateTermsAndConditionsVisibility(val)
                break
            case 'showNotes':
                TemplateSettingsManager.updateNotesVisibility(val)
                break
            case 'showCurrency':
                TemplateSettingsManager.updateCurrencySymbolVisibility(val)
                break
            case 'showEInvoiceSection':
                TemplateSettingsManager.updateEInvoiceSectionVisibility(val)
                break
            case 'showLinkDocumentNumber':
                TemplateSettingsManager.updateLinkDocumentVisibility(val)
                break
            case 'showRepeatableHeader':
                TemplateSettingsManager.updateRepeatableTableHeader(val)
                break
            case 'showRepeatableHeaderFooter':
                TemplateSettingsManager.updateRepeatableHeaderFooterVisibility(val)
                break
            case 'showRepeatableFooter':
                TemplateSettingsManager.updateRepeatableFooterVisibility(val)
                break
            case 'showHeaderFooter':
                TemplateSettingsManager.updateHeaderFooterVisibility(val)
                break
            case 'showAdditionalTable':
                TemplateSettingsManager.updateAdditionalTableVisibility(val)
                break
            case 'showWatermark':
                TemplateSettingsManager.updateWatermarkVisibility(val)
                break
            case 'showPlaceOfSupply':
                TemplateSettingsManager.updateSupplyConfigurationVisibility(val, 'place')
                break
            case 'showSourceOfSupply':
                TemplateSettingsManager.updateSupplyConfigurationVisibility(val, 'source')
                break
            case 'showDestinationOfSupply':
                TemplateSettingsManager.updateSupplyConfigurationVisibility(val, 'destination')
                break
            case 'showPaymentFooter':
                TemplateSettingsManager.updatePaymentFooterVisibility(val)
                break
            case 'showGeneratedMessage':
                TemplateSettingsManager.updateGeneratedMessageVisibility(val)
                break
            case 'showTopSectionAboveTable':
                TemplateSettingsManager.updateTopSectionAboveTableVisibility(val)
                break
            case 'showPayslipOvertime':
                TemplateSettingsManager.updatePayslipOvertimeVisibility(val)
                break
            case 'showPayslipLOP':
                TemplateSettingsManager.updatePayslipLOPVisibility(val)
                break
            case 'showAccountProduct':
                TemplateSettingsManager.updateTableColumnForAccountProductVisibility(val)
                break
            case 'showDocumentLabel':
                TemplateSettingsManager.updateDocumentLabelVisibility(val)
                break
            default :
                break
        }
    }

    static updateDocumentTypeVisibility(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.type.isVisible = val
    }

    static updateDocumentTypeLabelText(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.type.labelText = val
    }

    static updateCompanyNameVisibility(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.companyDetails.name.isVisible = val
    }

    static updateCompanyLogoVisibility(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.companyDetails.logo.isVisible = val
    }

    static updateCompanyAddressVisibility(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.companyDetails.address.isVisible = val
    }

    static updateBillToAddressVisibility(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.billToDetails.isVisible = val
    }

    static updateShipToAddressVisibility(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.shipToDetails.isVisible = val
    }

    static updateShipFromAddressVisibility(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()

        if(TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.shipFromDetails === undefined) {
            TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.shipFromDetails = {
                isVisible: true,
                title: 'ship_from'
            }
        }
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.shipFromDetails.isVisible = val
    }


    static updateDocumentReferenceNumberVisibility(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.referenceNumber.isVisible = val
    }

    static updateTermsAndConditionsVisibility(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.termsAndConditions.isVisible = val
    }

    static updateNotesVisibility(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.notes.isVisible = val
    }

    static updateDocumentDateVisibility(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.startDate.isVisible = val
    }

    static updateDocumentDueDateVisibility(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.dueDate.isVisible = val
    }

    static updateDocumentAdditionalDateVisibility(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.additionalDate === undefined) {
            TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date['additionalDate'] = { isVisible: false }
        }
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.additionalDate.isVisible = val
    }

    static updateSignatureVisibility(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.signature.isVisible = val
    }

    static updateStockTableVisibility(val: any) {
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.stockTable.isVisible = val
    }

    static updatePaymentTableVisibility(val: any) {
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.paymentTable.isVisible = val
    }

    static updateCurrencySymbolVisibility(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.currency.isVisible = val
    }

    static updateEInvoiceSectionVisibility(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.eInvoiceSection.isVisible = val
    }

    static updateLinkDocumentVisibility(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.linkDocumentNumber === undefined) {
            TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails['linkDocumentNumber'] = { 'isVisible': true }
        }
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.linkDocumentNumber.isVisible = val
    }

    static updateRepeatableTableHeader(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        if (TemplateSettingsManager.defaultTemplateSettings.repeatableTableHeader === undefined) {
            TemplateSettingsManager.defaultTemplateSettings['repeatableTableHeader'] = { 'isVisible': false }
        }
        TemplateSettingsManager.defaultTemplateSettings.repeatableTableHeader.isVisible = val
    }

    static updateHeaderFooterVisibility(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        if (TemplateSettingsManager.defaultTemplateSettings.headerFooter === undefined) {
            TemplateSettingsManager.defaultTemplateSettings['headerFooter'] = { 'isVisible': false }
        }
        TemplateSettingsManager.defaultTemplateSettings.headerFooter.isVisible = val
    }

    static updateRepeatableHeaderFooterVisibility(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        if (TemplateSettingsManager.defaultTemplateSettings.repeatHeaderFooter === undefined) {
            TemplateSettingsManager.defaultTemplateSettings['repeatHeaderFooter'] = { 'isVisible': false }
        }
        TemplateSettingsManager.defaultTemplateSettings.repeatHeaderFooter.isVisible = val
    }

    static updateRepeatableFooterVisibility(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        if (TemplateSettingsManager.defaultTemplateSettings.repeatHeaderFooter === undefined) {
            TemplateSettingsManager.defaultTemplateSettings['repeatHeaderFooter'] = { 'isFooterVisible': false }
        }
        TemplateSettingsManager.defaultTemplateSettings.repeatHeaderFooter.isFooterVisible = val
    }

    static updateHeaderFooter(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        if (TemplateSettingsManager.defaultTemplateSettings.headerFooter === undefined) {
            TemplateSettingsManager.defaultTemplateSettings['headerFooter'] = { }
        }
        TemplateSettingsManager.defaultTemplateSettings.headerFooter = val
    }

    static updateAdditionalTable(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        if (TemplateSettingsManager.defaultTemplateSettings.additionalTable === undefined) {
            TemplateSettingsManager.defaultTemplateSettings['additionalTable'] = this.getDefaultAdditionalTableConfiguration()
        }
        TemplateSettingsManager.defaultTemplateSettings.additionalTable = val
    }

    static updateAdditionalTableVisibility(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        if (TemplateSettingsManager.defaultTemplateSettings.additionalTable === undefined) {
            TemplateSettingsManager.defaultTemplateSettings['additionalTable'] = this.getDefaultAdditionalTableConfiguration()
        }
        TemplateSettingsManager.defaultTemplateSettings.additionalTable.isVisible = val
    }

    static updateWatermark(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.watermark === undefined) {
            TemplateSettingsManager.defaultTemplateSettings.documentInfo['watermark'] = this.getDefaultWatermark()
        }
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.watermark = val
    }

    static updateWatermarkVisibility(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.watermark === undefined) {
            TemplateSettingsManager.defaultTemplateSettings.documentInfo['watermark'] = this.getDefaultWatermark()
        }
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.watermark.isVisible = val
	}

    static updatePaymentFooterVisibility(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.paymentFooter === undefined) {
            TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails['paymentFooter'] = { 'isVisible': false }
        }
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.paymentFooter.isVisible = val
    }

    static updateSupplyConfigurationVisibility(val: any, type: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.supplyConfiguration === undefined) {
            TemplateSettingsManager.defaultTemplateSettings.documentInfo['supplyConfiguration'] = this.getDefaultSupplyConfiguration()
        }

        if(type === 'place') {
            TemplateSettingsManager.defaultTemplateSettings.documentInfo.supplyConfiguration.place.isVisible = val
        }
        else if (type === 'source') {
            TemplateSettingsManager.defaultTemplateSettings.documentInfo.supplyConfiguration.source.isVisible = val
        }
        else if (type === 'destination') {
            TemplateSettingsManager.defaultTemplateSettings.documentInfo.supplyConfiguration.destination.isVisible = val
        }
    }

    static getDefaultSupplyConfiguration() {
        return {
            place: {
                isVisible: true
            },
            source: {
                isVisible: true
            },
            destination: {
                isVisible: true
            },
        }
    }

    static getSupplyConfigurationVisibility(type: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.supplyConfiguration === undefined) {
            TemplateSettingsManager.defaultTemplateSettings.documentInfo['supplyConfiguration'] = this.getDefaultSupplyConfiguration()
        }
        var isVisible = true
        if (type === 'place') {
            isVisible = TemplateSettingsManager.defaultTemplateSettings.documentInfo.supplyConfiguration.place.isVisible
        }
        else if (type === 'source') {
            isVisible = TemplateSettingsManager.defaultTemplateSettings.documentInfo.supplyConfiguration.source.isVisible
        }
        else if (type === 'destination') {
            isVisible = TemplateSettingsManager.defaultTemplateSettings.documentInfo.supplyConfiguration.destination.isVisible
        }
        return isVisible
    }

    static updateGeneratedMessage(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.generatedMessage === undefined) {
            TemplateSettingsManager.defaultTemplateSettings.documentInfo['generatedMessage'] = this.getDefaultGenerateMessage()
        }
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.generatedMessage = val
    }

    static updateGeneratedMessageVisibility(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.generatedMessage === undefined) {
            TemplateSettingsManager.defaultTemplateSettings.documentInfo['generatedMessage'] = this.getDefaultGenerateMessage()
        }
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.generatedMessage.isVisible = val
    }

    static getGeneratedMessage() {
        var generatedMessage = TemplateSettingsManager.defaultTemplateSettings.documentInfo.generatedMessage
        if (generatedMessage === undefined || generatedMessage == null) {
            generatedMessage= this.getDefaultGenerateMessage()
        }
        return generatedMessage
    }

    static getGeneratedMessageVisibility() {
        return this.getGeneratedMessage().isVisible
    }

    static getDefaultGenerateMessage() {
        return {
            isVisible: true,
            customText: 'print_only_message',
        }
    }

    static getIsGroupAdditionalChargesTax() {
        var AdditionalCharges = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.AdditionalCharges
        if (AdditionalCharges === undefined || AdditionalCharges == null) {
            AdditionalCharges = {
                isGroup: false
            }
        }

        return AdditionalCharges.isGroup
    }

    static updateIsGroupAdditionalTax(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        var AdditionalCharges = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.AdditionalCharges
        if (AdditionalCharges === undefined || AdditionalCharges == null) {
            AdditionalCharges = {
                isGroup: false
            }
        }

        AdditionalCharges.isGroup = val

        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.AdditionalCharges = AdditionalCharges
    }


    ///////////////////////////////////////////////////////////////////////
    // Template Settings Template Info Related Methods
    //////////////////////////////////////////////////////////////////////
    static updateTemplateInfo(idVal: any, colorVal: any) {
        this.updateTemplateID(idVal)
        this.updateTemplateThemeColor(colorVal)
    }

    static updateTemplateThemeColor(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.templateInfo.themeColor = val
    }

    static updateTemplateID(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.templateInfo.id = val
    }

    static getTemplateID() {
        return TemplateSettingsManager.defaultTemplateSettings.templateInfo.id
    }

    ///////////////////////////////////////////////////////////////////////
    // Template Settings Image Related Methods
    //////////////////////////////////////////////////////////////////////
    static updateImage(isLogo: any, val: any) {
        if (isLogo) {
            this.updateCompanyLogoImage(val)
        } else {
            this.updateSignatureImage(val)
        }
    }

    static updateSignatureImage(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.signature.image = val
    }

    static updateCompanyLogoImage(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.companyDetails.logo.image = val
    }

    static updateSignatureImageSize(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.signature.size === undefined ||
            TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.signature.size === null) {
            TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.signature['size'] = 0
        }
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.signature.size = val
    }

    static updateCompanyLogoImageSize(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.companyDetails.logo.size === undefined ||
            TemplateSettingsManager.defaultTemplateSettings.documentInfo.companyDetails.logo.size === null) {
            TemplateSettingsManager.defaultTemplateSettings.documentInfo.companyDetails.logo['size'] = 0
        }
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.companyDetails.logo.size = val
    }

    ///////////////////////////////////////////////////////////////////////
    // Template Settings BillTo/ShipTo Title Related Methods
    //////////////////////////////////////////////////////////////////////
    static updateTypeTitle(type: any, val: any) {
        switch (type) {
            case EditorInfoType.billToTitle:
                this.updateBillToTitle(val)
                break;
            case EditorInfoType.shipToTitle:
                this.updateShipToTitle(val)
                break;
            case EditorInfoType.shipFromTitle:
                this.updateShipFromTitle(val)
                break;
            case EditorInfoType.termsAndConditionTitle:
                this.updateTermsAndConditionTitle(val)
                break;
            case EditorInfoType.notesTitle:
                this.updateNotesTitle(val)
                break;
            default:
                break;
        }
    }

    static updateBillToTitle(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.billToDetails.title = val
    }

    static updateShipToTitle(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.shipToDetails.title  = val
    }

    static updateShipFromTitle(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.shipFromDetails = {
                isVisible: true,
                title: 'ship_from'
            }
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.shipFromDetails.title  = val
    }

    static updateTermsAndConditionTitle(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.termsAndConditions['title'] = val
    }

    static updateNotesTitle(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.notes['title'] = val
    }


    static updateBillToPlaceholderTitle(val: any) {
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.billToPlaceholder !== undefined) {
            TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.billToPlaceholder.title = val
        }
    }

    static updateShipToPlaceholderTitle(val: any) {
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.shipToPlaceholder !== undefined) {
            TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.shipToPlaceholder.title = val
        }

    }

    ///////////////////////////////////////////////////////////////////////
    // Template Settings Date format Related Methods
    //////////////////////////////////////////////////////////////////////

    static updateDateFormat(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.format = val
    }

    static getDateFormatIsSystemDefault() {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        var dateFormat = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date
        if (dateFormat.isSystemDefault !== undefined && dateFormat.isSystemDefault !== null) {
            return dateFormat.isSystemDefault
        }
        return false
    }

    static updateDateFormatIsSystemDefault(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        var dateFormat = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date
        if (dateFormat.isSystemDefault === undefined || dateFormat.isSystemDefault === null) {
            dateFormat['isSystemDefault'] = false
        }
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.isSystemDefault = val
    }

    ///////////////////////////////////////////////////////////////////////
    // Template Settings Table Column Popup Related Methods
    //////////////////////////////////////////////////////////////////////
    static updateTablePopupInfo(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.tableInfo = val
    }

    ///////////////////////////////////////////////////////////////////////
    // Template Settings Table Column Popup Related Methods
    //////////////////////////////////////////////////////////////////////
    static setSignatureInfo(val: any) {
        TemplateSettingsManager.defaultTemplateSettings.signatureInfo = val
    }

    static updateSignatureInfo(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.signatureInfo = val
    }

    ///////////////////////////////////////////////////////////////////////
    // Template Settings Current Language Related Methods
    //////////////////////////////////////////////////////////////////////
    static updateCurrentLanguage(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.languageInfo = val
    }

    ///////////////////////////////////////////////////////////////////////
    // Template Settings Component List Related Methods
    //////////////////////////////////////////////////////////////////////
    static updateComponentList(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.componentList = val
    }

     ///////////////////////////////////////////////////////////////////////
    // Template Settings Custom Field List Related Methods
    //////////////////////////////////////////////////////////////////////

    static updateCustomFieldList(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.customFieldList = val
    }

    static updateProductCustomField(val: any) {
        TemplateSettingsManager.defaultTemplateSettings.tableInfo = val;
    }

    static updateProductCombinedCustomField(val: any) {
        TemplateSettingsManager.defaultTemplateSettings.productTableConfiguration = val;
    }

    static getCustomFieldListWithMultiDoc() {
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.customFieldListWithDocId !== undefined) {
            return TemplateSettingsManager.defaultTemplateSettings.documentInfo.customFieldListWithDocId
        }
        return []
    }

    static updateCustomFieldListWithMultiDoc(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.customFieldListWithDocId === undefined)  {
            TemplateSettingsManager.defaultTemplateSettings.documentInfo.customFieldListWithDocId = []
        }
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.customFieldListWithDocId = val
    }

    static updateBottomCustomFieldList(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.bottomCustomFieldList = val
    }


    static getBottomCustomFieldListWithMultiDoc() {
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.bottomCustomFieldListWithDocId !== undefined) {
            return TemplateSettingsManager.defaultTemplateSettings.documentInfo.bottomCustomFieldListWithDocId
        }
        return []
    }

    static updateBottomCustomFieldListWithMultiDoc(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.bottomCustomFieldListWithDocId === undefined) {
            TemplateSettingsManager.defaultTemplateSettings.documentInfo.bottomCustomFieldListWithDocId = []
        }
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.bottomCustomFieldListWithDocId = val
    }


    static updateMissingInnerWidthForComponentList() {
        var componentList = TemplateSettingsManager.defaultTemplateSettings.documentInfo.componentList
        if(componentList !== undefined && componentList !== null ) {
            if(componentList.length > 0) {
                componentList.forEach((element: any) => {
                    if (element.originalWindowInnerWidth === undefined || element.originalWindowInnerWidth === null) {
                        element.originalWindowInnerWidth = window.innerWidth
                    }
                });
                TemplateSettingsManager.defaultTemplateSettings.documentInfo.componentList = componentList
            }
        }
    }

    ///////////////////////////////////////////////////////////////////////
    // Template Settings Thumbnail Related Methods
    //////////////////////////////////////////////////////////////////////
    static updateThumbnailRelativePath(val: any) {
        TemplateSettingsManager.defaultTemplateSettings.thumbnailInfo = val
    }

    ///////////////////////////////////////////////////////////////////////
    // Template Settings TNC/Notes Text Related Methods
    //////////////////////////////////////////////////////////////////////
    static updateAdditionalDetailsText(isTNC: any, val: any) {
        if (isTNC) {
            this.updateTermsAndConditionsText(val)
        } else {
            this.updateNotesText(val)
        }
    }

    static updateTermsAndConditionsText(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.termsAndConditions.text = val
    }

    static updateNotesText(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.notes.text = val
    }

    static updateDefaultEmailSubjectText(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.emailInfo.defaultEmailSubject = val
    }

    static updateTableInfo(tableDataFromAPI: any) {
        TemplateSettingsManager.defaultTemplateSettings.tableInfo = TemplateSettingsManager.defaultTemplateSettings.tableInfo.map((obj: any) => {
            return {
                ...obj,
                name: (tableDataFromAPI[obj.type] !== undefined) ? TemplateSettingsManager.getUpdatedKeys(obj.type, tableDataFromAPI[obj.type]) : obj.name
            }
        })
    }

    static updateTemplateHeight(val: any, defaultHeight: any) {
        var defaultHeightValue = defaultHeight.includes('vw') ? Number(defaultHeight.replace('vw', '')) * 14.3 : Number(defaultHeight.replace('px', ''))
        var templateHeightValue = val.includes('vw') ? Number(val.replace('vw', '')) * 14.3 : Number(val.replace('px', ''))
        if (templateHeightValue > defaultHeightValue) {
            TemplateSettingsManager.defaultTemplateSettings.templateHeight = templateHeightValue + 'px'
        }
        else {
            TemplateSettingsManager.defaultTemplateSettings.templateHeight = defaultHeight
        }
    }

    static getDecimalScaleIsSystemDefault() {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        var decimalScale = TemplateSettingsManager.defaultTemplateSettings?.documentInfo?.documentDetails?.decimalScale
        if (decimalScale?.isSystemDefault !== undefined && decimalScale?.isSystemDefault !== null) {
            return decimalScale.isSystemDefault
        }
        return false
    }

    static updateDecimalScaleIsSystemDefault(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        var decimalScale = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.decimalScale
        if (decimalScale.isSystemDefault === undefined || decimalScale.isSystemDefault === null) {
            decimalScale['isSystemDefault'] = false
        }
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.decimalScale.isSystemDefault = val
    }


    static updateDecimalScale(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.decimalScale.value = val
    }

    static updateQuantityDecimalScale(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails['quantityDecimalScale'] = { 'value': val }
    }

    static getQuantityDecimalScale() {
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.quantityDecimalScale === undefined) {
            return 2
        }
        else {
            return TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.quantityDecimalScale.value
        }
    }

    static updateCurrencySymbol(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.currency = val
    }

    static updateTotalColumnSection(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.totalColumnSection = val
    }

    static resetTotalColumnSection() {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.totalColumnSection = {
            "isVisible": false,
            "items": []
        }
    }

    static updateProductGrouping(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.lineItemGrouping === undefined ||
            TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.lineItemGrouping === null) {
            TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails['lineItemGrouping'] = {
                    "selection": "none",
                    "key": "none"
                }
            }
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.lineItemGrouping = val
    }

    static getProductGrouping() {
        var productGrouping = {
            "selection": "none",
            "key": "none"
        }

        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.lineItemGrouping !== undefined &&
            TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.lineItemGrouping !== null) {
            productGrouping = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.lineItemGrouping
        }

        return productGrouping
    }

    static getDefaultProductTableConfiguration() {
        return {
            isIncludesProductCustomField: false,
            isCombinesProductCustomField: false,
            isCombinesLineItemGlobalDiscountField: true,
            combinedProductCustomField: [],
            globalDiscountType: 'SHOW_ALL'
        }
    }

    static updateProductTableConfiguration(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.productTableConfiguration = val
    }


    static getDefaultAccountTableConfiguration() {
        return {
            isIncludesAccountCustomField: false,
            isCombinesAccountCustomField: false,
            combinedAccountCustomField: []
        }
    }

    static updateAccountTableConfiguration(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.accountTableConfiguration = val
    }

    static getIsPayslipOvertimeVisible() {
        var payslipOvertime = TemplateSettingsManager.defaultTemplateSettings.payslipOvertime
        if (payslipOvertime !== undefined && payslipOvertime !== null) {
            if (payslipOvertime.isVisible !== undefined && payslipOvertime.isVisible !== null) {
                return payslipOvertime.isVisible
            }
        }
        return false
    }

    static updatePayslipOvertimeVisibility(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        if (TemplateSettingsManager.defaultTemplateSettings.payslipOvertime === undefined) {
            TemplateSettingsManager.defaultTemplateSettings.payslipOvertime = {
                isVisible: false
            }
        }
        TemplateSettingsManager.defaultTemplateSettings.payslipOvertime.isVisible = val
	}

    static getIsPayslipLOPVisible(data: any) {
        var payslipLOP = TemplateSettingsManager.defaultTemplateSettings.payslipLOP
        if (payslipLOP !== undefined && payslipLOP !== null) {
            if (payslipLOP.isVisible !== undefined && payslipLOP.isVisible !== null) {
                return payslipLOP.isVisible
            }
        }
        return false
    }

    static updatePayslipLOPVisibility(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        if (TemplateSettingsManager.defaultTemplateSettings.payslipLOP === undefined) {
            TemplateSettingsManager.defaultTemplateSettings.payslipLOP = {
                isVisible: false
            }
        }
        TemplateSettingsManager.defaultTemplateSettings.payslipLOP.isVisible = val
	}

    static getDefaultWatermark() {
        return {
            isVisible: false,
            elements: [],
            position: ''
        }
    }


    static updateDocumentLabel(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentLabel === undefined) {
            TemplateSettingsManager.defaultTemplateSettings.documentInfo['documentLabel'] = this.getDefaultDocumentLabel()
        }
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentLabel = val
    }

    static getDocumentLabel() {
        var documentLabel = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentLabel
        if (documentLabel === undefined) {
            documentLabel = this.getDefaultDocumentLabel()
        }
        return documentLabel
    }

    static updateDocumentLabelVisibility(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentLabel === undefined) {
            TemplateSettingsManager.defaultTemplateSettings.documentInfo['documentLabel'] = this.getDefaultDocumentLabel()
        }
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentLabel.isVisible = val
    }

    static getDocumentLabelVisible() {
        var documentLabel = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentLabel
        if (documentLabel === undefined) {
            documentLabel = this.getDefaultDocumentLabel()
        }
        if (documentLabel !== undefined && documentLabel !== null) {
            if (documentLabel.isVisible !== undefined && documentLabel.isVisible !== null) {
                return documentLabel.isVisible
            }
        }
        return false
    }

    static getDefaultDocumentLabel() {
        return {
            isVisible: false,
            elements: [],
            position: ''
        }
    }

    static getUpdatedKeys(itemKey: any, itemReceived: any) {
        let options: string[]
        switch (itemKey) {
            case TableColumnPopupType.code:
                options = ['item_number', 'item_code', 'sn_number']
                break;
            case TableColumnPopupType.image:
                options = ['image']
                break;
            case TableColumnPopupType.productName:
                options = ['products','services','items']
                break;
            case TableColumnPopupType.description:
                options = ['description', 'notes']
                break;
            case TableColumnPopupType.quantity:
                options = ['qty', 'hours']
                break;
            case TableColumnPopupType.unitPrice:
                options = ['unit price','price', 'rate']
                break;
            case TableColumnPopupType.amount:
                options = ['amount']
                break;
            case TableColumnPopupType.tax:
                options = ['tax', 'sales tax', 'tax rate']
                break;
            case TableColumnPopupType.discount:
                options = ['discount']
                break;
            case TableColumnPopupType.productGlobalDiscount:
                options = ['global_discount']
                break;
            case TableColumnPopupType.globalDiscount:
                options = ['global_discount']
                break;
            case TableColumnPopupType.productUnitPriceAfterDiscount:
                return ['unit_price_after_discount', 'other']
            case TableColumnPopupType.productDiscountPerUnit:
                return ['discount_per_unit', 'other']
            case TableColumnPopupType.amountBeforeTax:
                options = ['amount_before_tax']
                break;
            case TableColumnPopupType.taxAmount:
                options = ['tax_amount', 'tax']
                break;
            case TableColumnPopupType.paymentAmount:
                options = ['payment_amount', 'other']
                break;
            case TableColumnPopupType.productTax:
                options = ['tax', 'item_tax','other']
                break;
            case TableColumnPopupType.productTaxAmount:
                options = ['tax_amount', 'tax', 'other']
                break;
            case TableColumnPopupType.productDiscount:
                options = ['discount', 'item_discount', 'other']
                break;
            case TableColumnPopupType.uom:
                options = ['uom', 'other']
                break;
            case TableColumnPopupType.beforeTax:
                options = ['before_tax', 'other']
                break;
            case TableColumnPopupType.cogs:
                options = ['cogs']
                break;
            default:
                options = []
                break;
        }

        let keyToReturn = itemReceived;
        for (let item in options) {
            if (['es', 'zh', 'hi', 'pt', 'de', 'it'].includes(TemplateSettingsManager.defaultTemplateSettings.languageInfo.code)) {
                if (options[item].toLowerCase() === itemReceived.toLowerCase()) {
                    keyToReturn = options[item]
                    break;
                }
            } else {
                if (getLocalisedText(options[item]).toLowerCase() === itemReceived.toLowerCase()) {
                    keyToReturn = options[item]
                    break;
                }
            }

        }
        return keyToReturn
    }

    static updateTemplateSettings(val: any) {
        TemplateSettingsManager.defaultTemplateSettings = val
    }

    static getInitialTemplateID() {
        if (Utility.getPersistentValue('templateId') === null) {
            return 1
        } else {
            return Utility.getPersistentValue('templateId')
        }
    }

    static getInitialTemplateThemeColor() {
        if (Utility.getPersistentValue('themeColor') === null) {
            return "#000000"
        } else {
            return Utility.getPersistentValue('themeColor')
        }
    }

    static getDefaultTemplateStyle() {
        return {
            font: {
                style: 'normal',
                size: 0,
                color: null,
                weight: null,
                family: null,
                isOverrideThemeColor: false,
            },
            border: {
                top: '',
                left: '',
                right: '',
                bottom: '',
            },
            contact: {
                font: {
                    style: 'normal',
                    size: 0,
                    color: null,
                    weight: null,
                    family: null,
                    isOverrideThemeColor: false,
                    isEnable: false,
                },
            },
            table: {
                font: this.getDefaultTableStyle()
            },
            topSectionBeforeTable: {
                font: this.getDefaultTopSectionBeforeTableStyle(),
                isVisible: false,
            },
        }
    }

    static getInitialCompanyLogo() {
        if (Utility.getPersistentValue('logo') === null) {
            return null
        } else {
            return (Utility.getPersistentValue('logo') === "null") ? null : Utility.getPersistentValue('logo')
        }
    }

    static getInitialSignatureImage() {
        if (Utility.getPersistentValue('signature') === null) {
            return null
        } else {
            return (Utility.getPersistentValue('signature') === "null") ? null : Utility.getPersistentValue('signature')
        }
    }

    static getInitialCurrentLanguage() {
      if (Utility.getPersistentValue('langKey')) {
        return JSON.parse(Utility.getPersistentValue('langKey') || "")
      } else {
        return { "name": "English", "code": "en", "flag": "us" }
      }
    }

    static getInitialShowCompanyName() {
        if (Utility.getPersistentValue('showCompanyName') === null) {
            return true
        } else {
            return (Utility.getPersistentValue('showCompanyName') === "true") ? true : false
        }
    }

    static getInitialShowBillToAddress() {
        if (Utility.getPersistentValue('showClientBillToAddress') === null) {
            return true
        } else {
            return (Utility.getPersistentValue('showClientBillToAddress') === "true") ? true : false
        }
    }

    static getInitialShowShipToAddress() {
        if (Utility.getPersistentValue('showClientShipToAddress') === null) {
            return true
        } else {
            return (Utility.getPersistentValue('showClientShipToAddress') === "true") ? true : false
        }
    }

    static getInitialShowDocumentNumber() {
        if (Utility.getPersistentValue('showRefNumber') === null) {
            return true
        } else {
            return (Utility.getPersistentValue('showRefNumber') === "true") ? true : false
        }
    }


    static getInitialShowDocumentStartDate() {
        if (Utility.getPersistentValue('showStartDate') === null) {
            return true
        } else {
            return (Utility.getPersistentValue('showStartDate') === "true") ? true : false
        }
    }

    static getInitialShowDocumentDueDate() {
        if (Utility.getPersistentValue('showDueDate') === null) {
            return true
        } else {
            return (Utility.getPersistentValue('showDueDate') === "true") ? true : false
        }
    }

    static getInitialShowSignature() {
        if (Utility.getPersistentValue('showSignature') === null) {
            return true
        } else {
            return (Utility.getPersistentValue('showSignature') === "true") ? true : false
        }
    }

    static getInitialShowTermsAndConditions() {
        if (Utility.getPersistentValue('showTermsAndCondition') === null) {
            return true
        } else {
            return (Utility.getPersistentValue('showTermsAndCondition') === "true") ? true : false
        }
    }

    static getInitialShowNotes() {
        if (Utility.getPersistentValue('showNotes') === null) {
            return true
        } else {
            return (Utility.getPersistentValue('showNotes') === "true") ? true : false
        }
    }

    static getInitialShowCompanyAddress() {
        if (Utility.getPersistentValue('showFrom') === null) {
            return true
        } else {
            return (Utility.getPersistentValue('showFrom') === "true") ? true : false
        }
    }

    static getRepeatableTableHeader() {
        var repeatableTableHeader = TemplateSettingsManager.defaultTemplateSettings.repeatableTableHeader
        if (repeatableTableHeader !== undefined && repeatableTableHeader !== null) {
            if (repeatableTableHeader.isVisible !== undefined && repeatableTableHeader.isVisible !== null) {
                return repeatableTableHeader.isVisible
            }
        }
        return false
    }

    static getHeaderFooterIsVisible() {
        var headerFooter = TemplateSettingsManager.defaultTemplateSettings.headerFooter
        if (headerFooter !== undefined && headerFooter !== null) {
            if (headerFooter.isVisible !== undefined && headerFooter.isVisible !== null) {
                return headerFooter.isVisible
            }
        }
        return false
    }

    static getRepeatableHeaderFooterIsVisible() {
        var headerFooter = TemplateSettingsManager.defaultTemplateSettings.repeatHeaderFooter
        if (headerFooter !== undefined && headerFooter !== null) {
            if (headerFooter.isVisible !== undefined && headerFooter.isVisible !== null) {
                return headerFooter.isVisible
            }
        }
        return false
    }

    static getRepeatableFooterIsVisible() {
        var headerFooter = TemplateSettingsManager.defaultTemplateSettings.repeatHeaderFooter
        if (headerFooter !== undefined && headerFooter !== null) {
            if (headerFooter.isFooterVisible !== undefined && headerFooter.isFooterVisible !== null) {
                return headerFooter.isFooterVisible
            }
        }
        return false
    }

    static getTemplateNameType(type: any){
        if (type === 'quote' || type === 'quotation' || type === 'estimate' || type === 'quote') {
            return 'QUOTATION';
        } else if (type === 'invoice') {
            return 'SALES_INVOICE';
        }
        else if (type === 'order') {
            return 'PURCHASE_ORDER';
        } else if (type === 'bill') {
            return 'PURCHASE_INVOICE';
        } else if (type === 'credit_note' || type === 'credit note') {
            return 'CREDIT_NOTE';
        } else if (type === 'debit_note' || type === 'debit name') {
            return 'DEBIT_NOTE';
        } else if (type === 'expense') {
            return 'EXPENSE';
        } else if (type === 'deposit') {
            return 'DEPOSIT';
        }
        else if (type === 'deposit') {
            return 'DEPOSIT';
        }
        else if (type === 'goods_receipt' || type === 'good receipt') {
            return 'GOODS_RECEIPT';
        }
        else if (type === 'fulfillment') {
            return 'FULFILLMENT';
        }
        else if (type === 'receive_payment' || type === 'receipt') {
            return 'RECEIVE_PAYMENT'
        }
        else if (type === 'make_payment' || type === 'payment') {
            return 'MAKE_PAYMENT'
        }
        else if (type === 'journal_entry') {
            return 'JOURNAL_ENTRY'
        }
        else if (getIsPickList(type)) {
            return 'PICKLIST'
        }
        else if (getIsPackList(type)) {
            return 'PACKLIST'
        }
        else if (getIsShipList(type)) {
            return 'SHIPLIST'
        }
        else if (getIsPayslip(type)) {
            return 'PAYSLIP'
        }
        else if (getIsCheque(type)) {
            return 'CHEQUE'
        }
        else if (getIsPayrollCheck(type)) {
            return 'PAYROLL_CHECK'
        }
        else if (getIsSalesOrder(type)) {
            return 'SALES_ORDER'
        }
        else if (getIsEwayBillSummary(type)) {
            return 'E_WAY_BILL_SUMMARY'
        }
        else if (getIsEwayBillDetails(type)) {
            return 'E_WAY_BILL_DETAIL'
        }
        else if (getIsWorkOrder(type)) {
            return 'WORK_ORDER'
        }
        else if (getIsJobCard(type)) {
            return 'JOB_CARD'
        }
        else if (getIsPurchaseRequisition(type)) {
            return 'PURCHASE_REQUISTION'
        }
        else if (getIsQcTemplate(type)) {
            return 'QC_TEMPLATE'
        }
        else if (getIsStockTransfer(type)) {
            return 'STOCK_TRANSFER'
        }
        else if (getIsStockAdjustment(type)) {
            return 'STOCK_ADJUSTMENT'
        }
        // Do not remove To be used later

        // else if (type === 'invoice' ) {
        //     return 'SALES_INVOICE';
        // } else if (type === 'bill' ) {
        //     return 'PURCHASE_INVOICE';
        // }
    }

    static getTableColumnForProduct() {
        return [
            { type: TableColumnPopupType.lineNumber, isSelected: false, name: '#' },
            { type: TableColumnPopupType.code, isSelected: false, name: 'sn_number' },
            { type: TableColumnPopupType.image, isSelected: false, name: 'image' },
            { type: TableColumnPopupType.productName, isSelected: true, name: 'products' },
            { type: TableColumnPopupType.description, isSelected: true, name: 'description' },
            { type: TableColumnPopupType.productNameWithDescription, isSelected: false, name: 'name_with_description' },
            { type: TableColumnPopupType.productCustomField, isSelected: false, name: 'product_custom_field' },
            { type: TableColumnPopupType.quantity, isSelected: true, name: 'qty' },
            { type: TableColumnPopupType.uom, isSelected: false, name: "uom" },
            { type: TableColumnPopupType.quantityWithUom, isSelected: false, name: "quantity_and_uom" },
            { type: TableColumnPopupType.serialBatch, isSelected: false, name: "serial_batch" },
            { type: TableColumnPopupType.serialNumber, isSelected: false, name: "serial_number" },
            { type: TableColumnPopupType.batchNumber, isSelected: false, name: "batch_number" },
            { type: TableColumnPopupType.manufacturingDate, isSelected: false, name: "manufacturing_date" },
            { type: TableColumnPopupType.expiryDate, isSelected: false, name: "expiry_date" },
            { type: TableColumnPopupType.unitPrice, isSelected: true, name: 'unit_price' },
            { type: TableColumnPopupType.productDiscount, isSelected: false, name: "discount" },
            { type: TableColumnPopupType.productGlobalDiscount, isSelected: false, name: "global_discount" },
            { type: TableColumnPopupType.productTax, isSelected: false, name: "tax" },
            { type: TableColumnPopupType.productTaxAmount, isSelected: false, name: "tax_amount" },
            { type: TableColumnPopupType.amountBeforeTax, isSelected: false, name: 'amount_before_tax' },
            { type: TableColumnPopupType.amount, isSelected: true, name: 'amount' },
            { type: TableColumnPopupType.subTotalAmount, isSelected: true, name: 'subtotal' },
            { type: TableColumnPopupType.discount, isSelected: true, name: 'discount' },
            { type: TableColumnPopupType.beforeTax, isSelected: false, name: 'before_tax' },
            { type: TableColumnPopupType.tax, isSelected: true, name: 'tax' },
            { type: TableColumnPopupType.globalDiscount, isSelected: true, name: 'global_discount' },
            { type: TableColumnPopupType.totalAmount, isSelected: true, name: 'total' },
            { type: TableColumnPopupType.outstandingBalance, isSelected: false, name: 'outstanding_balance' },
            { type: TableColumnPopupType.outstandingBalanceIncludesCurrent, isSelected: false, name: 'outstanding_balance_includes_current' },
            { type: TableColumnPopupType.amountInWords, isSelected: false, name: 'amount_in_words' },
        ]
    }

    static getTableColumnForReceipt() {
        return [
            { type: TableColumnPopupType.lineNumber, isSelected: false, name: '#' },
            { type: TableColumnPopupType.code, isSelected: false, name: 'sn_number' },
            { type: TableColumnPopupType.image, isSelected: false, name: 'image' },
            { type: TableColumnPopupType.productName, isSelected: false, name: 'products' },
            { type: TableColumnPopupType.description, isSelected: false, name: 'description' },
            { type: TableColumnPopupType.productNameWithDescription, isSelected: true, name: 'name_with_description' },
            { type: TableColumnPopupType.productCustomField, isSelected: false, name: 'product_custom_field' },
            { type: TableColumnPopupType.quantity, isSelected: true, name: 'qty' },
            { type: TableColumnPopupType.uom, isSelected: false, name: "uom" },
            { type: TableColumnPopupType.quantityWithUom, isSelected: false, name: "quantity_and_uom" },
            { type: TableColumnPopupType.serialBatch, isSelected: false, name: "serial_batch" },
            { type: TableColumnPopupType.serialNumber, isSelected: false, name: "serial_number" },
            { type: TableColumnPopupType.batchNumber, isSelected: false, name: "batch_number" },
            { type: TableColumnPopupType.manufacturingDate, isSelected: false, name: "manufacturing_date" },
            { type: TableColumnPopupType.expiryDate, isSelected: false, name: "expiry_date" },
            { type: TableColumnPopupType.unitPrice, isSelected: true, name: 'unit_price' },
            { type: TableColumnPopupType.productDiscount, isSelected: false, name: "discount" },
            { type: TableColumnPopupType.productGlobalDiscount, isSelected: false, name: "global_discount" },
            { type: TableColumnPopupType.productTax, isSelected: false, name: "tax" },
            { type: TableColumnPopupType.productTaxAmount, isSelected: false, name: "tax_amount" },
            { type: TableColumnPopupType.amountBeforeTax, isSelected: false, name: 'amount_before_tax' },
            { type: TableColumnPopupType.amount, isSelected: true, name: 'amount' },
            { type: TableColumnPopupType.subTotalAmount, isSelected: true, name: 'subtotal' },
            { type: TableColumnPopupType.discount, isSelected: true, name: 'discount' },
            { type: TableColumnPopupType.beforeTax, isSelected: false, name: 'before_tax' },
            { type: TableColumnPopupType.tax, isSelected: true, name: 'tax' },
            { type: TableColumnPopupType.globalDiscount, isSelected: true, name: 'global_discount' },
            { type: TableColumnPopupType.totalAmount, isSelected: true, name: 'total' },
            { type: TableColumnPopupType.outstandingBalance, isSelected: false, name: 'outstanding_balance' },
            { type: TableColumnPopupType.outstandingBalanceIncludesCurrent, isSelected: false, name: 'outstanding_balance_includes_current' },
            { type: TableColumnPopupType.amountInWords, isSelected: false, name: 'amount_in_words' },
        ]
    }

    static getTableColumnOptionsForAccount(type: any) {
        if (getIsExpenseOrDeposit(type)) {
            return [
                { type: TableColumnPopupType.accountName, isSelected: true, name: ExpenseMapper.getLabel().accounts },
                { type: TableColumnPopupType.description, isSelected: true, name: 'description' },
                { type: TableColumnPopupType.amount, isSelected: true, name: 'amount' },
                { type: TableColumnPopupType.tax, isSelected: true, name: 'tax' },
                { type: TableColumnPopupType.taxAmount, isSelected: true, name: 'tax_amount' },
                { type: TableColumnPopupType.paymentAmount, isSelected: true, name: getIsExpenseOrDeposit(type) ? ExpenseMapper.getLabel().paymentAmount : CreditNoteMapper.getLabel().lineAmount },
                { type: TableColumnPopupType.subTotalAmount, isSelected: true, name: 'subtotal' },
                { type: TableColumnPopupType.totalTaxAmount, isSelected: true, name: 'total_tax_amount' },
                { type: TableColumnPopupType.totalAmount, isSelected: true, name: 'total' },
                { type: TableColumnPopupType.amountInWords, isSelected: false, name: 'amount_in_words' },
            ]
        } else {
            return [
                { type: TableColumnPopupType.accountName, isSelected: true, name: ExpenseMapper.getLabel().accounts },
                { type: TableColumnPopupType.amount, isSelected: true, name: 'amount' },
                { type: TableColumnPopupType.tax, isSelected: true, name: 'tax' },
                { type: TableColumnPopupType.taxAmount, isSelected: true, name: 'tax_amount' },
                { type: TableColumnPopupType.paymentAmount, isSelected: true, name: getIsExpenseOrDeposit(type) ? ExpenseMapper.getLabel().paymentAmount : CreditNoteMapper.getLabel().lineAmount },
                { type: TableColumnPopupType.subTotalAmount, isSelected: true, name: 'subtotal' },
                { type: TableColumnPopupType.totalTaxAmount, isSelected: true, name: 'total_tax_amount' },
                { type: TableColumnPopupType.totalAmount, isSelected: true, name: 'total' },
                { type: TableColumnPopupType.amountInWords, isSelected: false, name: 'amount_in_words' },
            ]
        }
    }

    static getTableColumnOptionsForExpenseBill() {
        return [
            { type: TableColumnPopupType.accountName, isSelected: true, name: ExpenseMapper.getLabel().accounts },
            { type: TableColumnPopupType.description, isSelected: true, name: 'description' },
            { type: TableColumnPopupType.tax, isSelected: true, name: 'tax' },
            { type: TableColumnPopupType.taxAmount, isSelected: true, name: 'tax_amount' },
            { type: TableColumnPopupType.amount, isSelected: true, name: 'amount' },
            { type: TableColumnPopupType.subTotalAmount, isSelected: true, name: 'subtotal' },
            { type: TableColumnPopupType.totalTaxAmount, isSelected: true, name: 'total_tax_amount' },
            { type: TableColumnPopupType.totalAmount, isSelected: true, name: 'total' },
            { type: TableColumnPopupType.outstandingBalance, isSelected: false, name: 'outstanding_balance' },
            { type: TableColumnPopupType.outstandingBalanceIncludesCurrent, isSelected: false, name: 'outstanding_balance_includes_current' },
            { type: TableColumnPopupType.amountInWords, isSelected: true, name: 'amount_in_words' },
        ]
    }

    static getTableColumnForFulfillment() {
        return [
            { type: TableColumnPopupType.code, isSelected: true, name: 'sn_number' },
            { type: TableColumnPopupType.productName, isSelected: true, name: 'products' },
            { type: TableColumnPopupType.description, isSelected: true, name: 'description' },
            { type: TableColumnPopupType.uom, isSelected: true, name: "uom" },
            { type: TableColumnPopupType.serialBatch, isSelected: true, name: "serial_batch" },
            { type: TableColumnPopupType.requiredQuantity, isSelected: true, name: FulfillmentMapper.getLabel().requiredQuantity },
            { type: TableColumnPopupType.committedQuantity, isSelected: true, name: FulfillmentMapper.getLabel().committedQuantity },
            { type: TableColumnPopupType.warehouseCode, isSelected: false, name: FulfillmentMapper.getLabel().warehouseCode },
            { type: TableColumnPopupType.warehouseName, isSelected: false, name: FulfillmentMapper.getLabel().warehouseName },
            { type: TableColumnPopupType.serialNumber, isSelected: false, name: "serial_number" },
            { type: TableColumnPopupType.batchNumber, isSelected: false, name: "batch_number" },
            { type: TableColumnPopupType.manufacturingDate, isSelected: false, name: "manufacturing_date" },
            { type: TableColumnPopupType.expiryDate, isSelected: false, name: "expiry_date" },
        ]
    }

    static getTableColumnForGoodsReceipt() {
        return [
            { type: TableColumnPopupType.code, isSelected: true, name: 'sn_number' },
            { type: TableColumnPopupType.orderNo, isSelected: false, name: GoodsReceiptMapper.getLabel().orderNo },
            { type: TableColumnPopupType.productName, isSelected: true, name: 'products' },
            { type: TableColumnPopupType.description, isSelected: true, name: 'description' },
            { type: TableColumnPopupType.uom, isSelected: true, name: "uom" },
            { type: TableColumnPopupType.requiredQuantity, isSelected: true, name: GoodsReceiptMapper.getLabel().requiredQuantity },
            { type: TableColumnPopupType.receivedQuantity, isSelected: true, name: GoodsReceiptMapper.getLabel().receivedQuantity },
            { type: TableColumnPopupType.warehouseCode, isSelected: false, name: GoodsReceiptMapper.getLabel().warehouseCode },
            { type: TableColumnPopupType.warehouseName, isSelected: false, name: GoodsReceiptMapper.getLabel().warehouseName },
        ]
    }

    static getDefaultSignatureInfo() {
        return [
            { "type": SignatureColumnType.preparedBy, "isSelected": true, "name": "prepared_by", index: 0 },
            { "type": SignatureColumnType.signature, "isSelected": true, "name": "signature", index: 1 },
            { "type": SignatureColumnType.approvedBy, "isSelected": true, "name": "approved_by", index: 2 },
            { "type": SignatureColumnType.name, "isSelected": true, "name": "name", index: 3 },
        ]
    }


    static getTableColumnOptionsForPayment(type: any) {
        if (getIsReceivePayment(type)) {
            return [
                { type: TableColumnPopupType.snNumber, isSelected: false, name: 'sn_number', index: 0 },
                { type: TableColumnPopupType.invoiceNumber, isSelected: true, name: 'invoice_number', index: 1 },
                { type: TableColumnPopupType.totalInvoiceAmount, isSelected: true, name: 'total_invoice_amount', index: 2 },
                { type: TableColumnPopupType.currentDueAmount, isSelected: true, name: 'current_due_amount', index: 3 },
                { type: TableColumnPopupType.paymentMedium, isSelected: true, name: 'payment_medium', index: 4 },
                { type: TableColumnPopupType.paymentMade, isSelected: true, name: 'payment_made', index: 5 },
                { type: TableColumnPopupType.subTotalAmount, isSelected: true, name: 'subtotal' },
                { type: TableColumnPopupType.totalAmount, isSelected: true, name: 'total' },
                { type: TableColumnPopupType.amountInWords, isSelected: false, name: 'amount_in_words' },
            ]
        }
        return [
            { type: TableColumnPopupType.snNumber, isSelected: false, name: 'sn_number', index: 0 },
            { type: TableColumnPopupType.billNumber, isSelected: true, name: 'bill_number', index: 1 },
            { type: TableColumnPopupType.totalBillAmount, isSelected: true, name: 'total_bill_amount', index: 2 },
            { type: TableColumnPopupType.currentBalance, isSelected: true, name: 'current_balance', index: 3 },
            { type: TableColumnPopupType.paymentMedium, isSelected: true, name: 'payment_medium', index: 4 },
            { type: TableColumnPopupType.paymentMade, isSelected: true, name: 'payment_made', index: 5 },
            { type: TableColumnPopupType.subTotalAmount, isSelected: true, name: 'subtotal' },
            { type: TableColumnPopupType.totalAmount, isSelected: true, name: 'total' },
            { type: TableColumnPopupType.amountInWords, isSelected: false, name: 'amount_in_words' },
        ]
    }

    static getTableColumnForJournalEntry() {
        return [
            { type: TableColumnPopupType.accountCode, isSelected: true, name: 'account_code', index: 0 },
            { type: TableColumnPopupType.accountName, isSelected: true, name: 'account_name', index: 1 },
            { type: TableColumnPopupType.description, isSelected: true, name: 'description', index: 2 },
            { type: TableColumnPopupType.debit, isSelected: true, name: "debit", index: 3 },
            { type: TableColumnPopupType.credit, isSelected: true, name: "credit", index: 4 },
        ]
    }

    static getTableColumnForPackingList() {
        return [
            { type: TableColumnPopupType.id, isSelected: true, name: 'item_no', index: 0 },
            { type: TableColumnPopupType.code, isSelected: true, name: 'product_code', index: 1 },
            { type: TableColumnPopupType.productName, isSelected: true, name: 'product_name', index: 2 },
            { type: TableColumnPopupType.barcode, isSelected: true, name: 'barcode', index: 3 },
            { type: TableColumnPopupType.unit, isSelected: true, name: "unit", index: 4 },
            { type: TableColumnPopupType.serialBatch, isSelected: false, name: "serial_batch", index: 5 },
            { type: TableColumnPopupType.packedQuantity, isSelected: true, name: "packed_quantity", index: 6 },
        ]
    }

    static getTableColumnForPickingList() {
        return [
            { type: TableColumnPopupType.invoiceOrQuotation, isSelected: true, name: 'invoice_or_quotation', index: 0 },
            { type: TableColumnPopupType.code, isSelected: true, name: 'product_code', index: 1 },
            { type: TableColumnPopupType.productName, isSelected: true, name: 'product_name', index: 2 },
            { type: TableColumnPopupType.barcode, isSelected: true, name: "barcode", index: 3 },
            { type: TableColumnPopupType.quantityToPick, isSelected: true, name: "quantity_to_pick", index: 4 },
            { type: TableColumnPopupType.quantityRequiredForOrder, isSelected: true, name: "quantity_required_for_order", index: 5 },
            { type: TableColumnPopupType.picked, isSelected: true, name: "picked", index: 6 },
        ]
    }

    static getTableColumnForShippingList() {
        return [
            { type: TableColumnPopupType.delivery, isSelected: true, name: 'delivery', index: 0 },
            { type: TableColumnPopupType.carrier, isSelected: true, name: 'carrier', index: 1 },
            { type: TableColumnPopupType.numberOfCarton, isSelected: true, name: 'number_of_carton', index: 2 },
            { type: TableColumnPopupType.weight, isSelected: true, name: "weight", index: 3 },
            { type: TableColumnPopupType.trackingNumber, isSelected: true, name: "tracking_number", index: 4 },
        ]
    }

    static getTableColumnForWorkOrder() {
        return [
            { "type": TableColumnPopupType.lineNumber, "isSelected": false, "name": '#',  index: 0 },
            { "type": TableColumnPopupType.code, "isSelected": false, "name": "sn_number", index: 1 },
            { "type": TableColumnPopupType.productName, "isSelected": true, "name": "products", index: 2 },
            { "type": TableColumnPopupType.orderPrepCompleted, "isSelected": true, "name": "order_prep_completed", index: 3 },
        ]
    }

    static getTableColumnForStockTransfer() {
        return [
            { type: TableColumnPopupType.lineNumber, isSelected: false, name: '#' },
            { type: TableColumnPopupType.code, isSelected: false, name: 'sn_number' },
            { type: TableColumnPopupType.productName, isSelected: true, name: 'products' },
            { type: TableColumnPopupType.description, isSelected: false, name: 'description' },
            { type: TableColumnPopupType.productNameWithDescription, isSelected: false, name: 'name_with_description' },
            { type: TableColumnPopupType.sourceWarehouseName, isSelected: true, name: 'source' },
            { type: TableColumnPopupType.sourceWarehouseCode, isSelected: false, name: 'source_warehouse_code' },
            { type: TableColumnPopupType.destinationWarehouseName, isSelected: true, name: 'destination' },
            { type: TableColumnPopupType.destinationWarehouseCode, isSelected: false, name: 'destination_warehouse_code' },
            { type: TableColumnPopupType.productCustomField, isSelected: false, name: 'product_custom_field' },
            { type: TableColumnPopupType.quantity, isSelected: true, name: 'qty' },
            { type: TableColumnPopupType.uom, isSelected: true, name: "uom" },
            { type: TableColumnPopupType.quantityWithUom, isSelected: false, name: "quantity_and_uom" },
            { type: TableColumnPopupType.serialBatch, isSelected: true, name: "serial_batch" },
            { type: TableColumnPopupType.serialNumber, isSelected: false, name: "serial_number" },
            { type: TableColumnPopupType.batchNumber, isSelected: false, name: "batch_number" },
            { type: TableColumnPopupType.manufacturingDate, isSelected: false, name: "manufacturing_date" },
            { type: TableColumnPopupType.expiryDate, isSelected: false, name: "expiry_date" },
        ]
    }

    static getTableColumnForStockAdjustment() {
        return [
            { type: TableColumnPopupType.lineNumber, isSelected: false, name: '#' },
            { type: TableColumnPopupType.code, isSelected: false, name: 'sn_number' },
            { type: TableColumnPopupType.productName, isSelected: true, name: 'products' },
            { type: TableColumnPopupType.description, isSelected: false, name: 'description' },
            { type: TableColumnPopupType.productNameWithDescription, isSelected: false, name: 'name_with_description' },
            { type: TableColumnPopupType.accountCode, isSelected: false, name: 'account_code' },
            { type: TableColumnPopupType.accountName, isSelected: true, name: 'account_name' },
            { type: TableColumnPopupType.productCustomField, isSelected: false, name: 'product_custom_field' },
            { type: TableColumnPopupType.quantity, isSelected: true, name: 'qty' },
            { type: TableColumnPopupType.uom, isSelected: true, name: "uom" },
            { type: TableColumnPopupType.quantityWithUom, isSelected: false, name: "quantity_and_uom" },
            { type: TableColumnPopupType.serialBatch, isSelected: true, name: "serial_batch" },
            { type: TableColumnPopupType.serialNumber, isSelected: false, name: "serial_number" },
            { type: TableColumnPopupType.batchNumber, isSelected: false, name: "batch_number" },
            { type: TableColumnPopupType.manufacturingDate, isSelected: false, name: "manufacturing_date" },
            { type: TableColumnPopupType.expiryDate, isSelected: false, name: "expiry_date" },
        ]
    }

    static getDefaultAddressFormat() {
        return {
            companyAddress: this.getDefaultAddressField(),
            billTo: this.getDefaultAddressField(),
            shipTo: this.getDefaultAddressField(),
            tenantAddress: this.getDefaultAddressField(),
            shipFrom: this.getDefaultAddressField(),
        }
    }

    static getDefaultAddressField() {
        return [
            { type: AddressFieldType.contactName, isSelected: true, name: 'contact_name', index: 0 },
            { type: AddressFieldType.address1, isSelected: true, name: 'address_1', index: 1 },
            { type: AddressFieldType.address2, isSelected: true, name: 'address_2', index: 2 },
            { type: AddressFieldType.city, isSelected: true, name: 'city', index: 3 },
            { type: AddressFieldType.state, isSelected: true, name: 'state', index: 4 },
            { type: AddressFieldType.country, isSelected: true, name: 'country', index: 5 },
            { type: AddressFieldType.postalCode, isSelected: true, name: 'postal_code', index: 6 },
            { type: AddressFieldType.contactNumber, isSelected: false, name: 'contact_number', index: 7 },
            { type: AddressFieldType.contactCode, isSelected: false, name: 'contact_code', index: 8 },
        ]
    }

    static updateAddressFormat(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        if (TemplateSettingsManager.defaultTemplateSettings.addressFormat === undefined) {
            TemplateSettingsManager.defaultTemplateSettings['addressFormat'] = this.getDefaultAddressField()
        }
        TemplateSettingsManager.defaultTemplateSettings.addressFormat = val
    }

    static updateTemplateStyle(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        if (TemplateSettingsManager.defaultTemplateSettings.templateInfo.style === undefined) {
            TemplateSettingsManager.defaultTemplateSettings.templateInfo['style'] = this.getDefaultTemplateStyle()
        }
        TemplateSettingsManager.defaultTemplateSettings.templateInfo.style = val
    }

    static getTopSectionAboveTableIsVisible() {
        var fontStyle = TemplateSettingsManager.defaultTemplateSettings.templateInfo.style
        if (fontStyle !== undefined && fontStyle !== null) {
            if (fontStyle.topSectionBeforeTable !== undefined && fontStyle.topSectionBeforeTable !== null) {
                if (fontStyle.topSectionBeforeTable.isVisible !== undefined && fontStyle.topSectionBeforeTable.isVisible !== null) {
                    return fontStyle.topSectionBeforeTable.isVisible
                }
            }
        }
        return false
    }

    static updateTopSectionAboveTableVisibility(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        if (TemplateSettingsManager.defaultTemplateSettings.templateInfo.style === undefined) {
            TemplateSettingsManager.defaultTemplateSettings.templateInfo['style'] = this.getDefaultTemplateStyle()
        }
        if (TemplateSettingsManager.defaultTemplateSettings.templateInfo.style.topSectionBeforeTable === undefined) {
            TemplateSettingsManager.defaultTemplateSettings.templateInfo.style.topSectionBeforeTable = this.getDefaultTopSectionBeforeTableStyle()
        }
        TemplateSettingsManager.defaultTemplateSettings.templateInfo.style.topSectionBeforeTable.isVisible = val
    }


    static updatePayslipFieldConfiguration(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        if (TemplateSettingsManager.defaultTemplateSettings.payslipFieldConfiguration === undefined) {
            TemplateSettingsManager.defaultTemplateSettings['payslipFieldConfiguration'] = {}
        }
        TemplateSettingsManager.defaultTemplateSettings.payslipFieldConfiguration = val
    }

    static updatePayslipEarningsFieldConfiguration(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        if (TemplateSettingsManager.defaultTemplateSettings.payslipEarningsFieldConfiguration === undefined) {
            TemplateSettingsManager.defaultTemplateSettings['payslipEarningsFieldConfiguration'] = {}
        }
        TemplateSettingsManager.defaultTemplateSettings.payslipEarningsFieldConfiguration = val
    }

    static updatePayslipTableConfiguration(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        if (TemplateSettingsManager.defaultTemplateSettings.payslipTableConfiguration === undefined) {
            TemplateSettingsManager.defaultTemplateSettings['payslipTableConfiguration'] = {}
        }
        TemplateSettingsManager.defaultTemplateSettings.payslipTableConfiguration = val
    }

    static resetDefaultData(data: any, templateType: any) {
        if (templateType !== undefined) {
            if (data.type !== templateType) {
                var type = data.type.toLowerCase()
                var newTableColumnData = this.getTableColumnForProduct()
                if(templateType !== '') {
                    if (getIsAccountModule(undefined, data.type.toLowerCase())) {
                        newTableColumnData = this.getTableColumnOptionsForAccount(data.type)
                    }
                    else if (getIsFulfillment(type)) {
                        newTableColumnData = this.getTableColumnForFulfillment()
                    }
                    else if (getIsGoodsReceipt(type)) {
                        newTableColumnData = this.getTableColumnForGoodsReceipt()
                    }
                    else if(getIsExpenseBill(type, data.lineItems)) {
                        newTableColumnData = this.getTableColumnOptionsForExpenseBill()
                    }
                    else if (getIsBill(type)) {
                        newTableColumnData = this.getTableColumnForProduct()
                    }
                    else if (getIsMakePayment(type) || getIsReceivePayment(type)) {
                        newTableColumnData = this.getTableColumnOptionsForPayment(type)
                    }
                    else if (getIsJournalEntry(type)) {
                        newTableColumnData = this.getTableColumnForJournalEntry()
                    }
                    else if (getIsPickList(type)) {
                        newTableColumnData = this.getTableColumnForPickingList()
                    }
                    else if (getIsPackList(type)) {
                        newTableColumnData = this.getTableColumnForPackingList()
                    }
                    else if (getIsShipList(type)) {
                        newTableColumnData = this.getTableColumnForShippingList()
                    }
                    else if (getIsWorkOrder(type)) {
                        newTableColumnData = this.getTableColumnForWorkOrder()
                        let productTableConfiguration = this.getDefaultProductTableConfiguration()
                        productTableConfiguration.isIncludesProductCustomField = true
                        TemplateSettingsManager.updateProductTableConfiguration(productTableConfiguration)
                    }
                    else if (getIsStockTransfer(type)) {
                        newTableColumnData = this.getTableColumnForStockTransfer()
                    }
                    else if (getIsStockAdjustment(type)) {
                        newTableColumnData = this.getTableColumnForStockAdjustment()
                    }
                    else {
                        TemplateSettingsManager.resetTotalColumnSection()
                    }

                    TemplateSettingsManager.updateTablePopupInfo(newTableColumnData)
                }
                else {
                    var columns = TemplateSettingsManager.defaultTemplateSettings.tableInfo.map((x: any) => x.type)
                    if (getIsAccountModule(undefined, type)) {
                        if (!columns.includes(TableColumnPopupType.accountName)) {
                            newTableColumnData = this.getTableColumnOptionsForAccount(data.type)
                            TemplateSettingsManager.updateTablePopupInfo(newTableColumnData)
                        }
                    }
                    else if (getIsFulfillment(type)) {
                        if (!columns.includes(TableColumnPopupType.committedQuantity)) {
                            newTableColumnData = this.getTableColumnForFulfillment()
                            TemplateSettingsManager.updateTablePopupInfo(newTableColumnData)
                        }
                    }
                    else if (getIsGoodsReceipt(type)) {
                        if (!columns.includes(TableColumnPopupType.receivedQuantity)) {
                            newTableColumnData = this.getTableColumnForGoodsReceipt()
                            TemplateSettingsManager.updateTablePopupInfo(newTableColumnData)
                        }
                    }
                    else if (getIsExpenseBill(type, data.lineItems)) {
                        if (!columns.includes(TableColumnPopupType.accountName)) {
                            newTableColumnData = this.getTableColumnOptionsForExpenseBill()
                            TemplateSettingsManager.updateTablePopupInfo(newTableColumnData)
                        }
                    }
                    else if (getIsBill(type)) {
                        if (columns.includes(TableColumnPopupType.accountName)) {
                            newTableColumnData = this.getTableColumnForProduct()
                            TemplateSettingsManager.updateTablePopupInfo(newTableColumnData)
                        }
                    }
					else if (getIsMakePayment(type) || getIsReceivePayment(type)) {
                        if (!columns.includes(TableColumnPopupType.paymentMedium)) {
                            newTableColumnData = this.getTableColumnOptionsForPayment(type)
                            TemplateSettingsManager.updateTablePopupInfo(newTableColumnData)
                        }
                    }
                    else if (getIsJournalEntry(type)) {
                        if (!columns.includes(TableColumnPopupType.credit)) {
                            newTableColumnData = this.getTableColumnForJournalEntry()
                            TemplateSettingsManager.updateTablePopupInfo(newTableColumnData)
                        }
                    }
                    else if (getIsPickList(type)) {
                        if (!columns.includes(TableColumnPopupType.quantityToPick)) {
                            newTableColumnData = this.getTableColumnForPickingList()
                            TemplateSettingsManager.updateTablePopupInfo(newTableColumnData)
                        }
                    }
                    else if (getIsPackList(type)) {
                        if (!columns.includes(TableColumnPopupType.packedQuantity)) {
                            newTableColumnData = this.getTableColumnForPackingList()
                            TemplateSettingsManager.updateTablePopupInfo(newTableColumnData)
                        }
                    }
                    else if (getIsShipList(type)) {
                        if (!columns.includes(TableColumnPopupType.delivery)) {
                            newTableColumnData = this.getTableColumnForShippingList()
                            TemplateSettingsManager.updateTablePopupInfo(newTableColumnData)
                        }
                    }
                    else if (getIsWorkOrder(type)) {
                        if (!columns.includes(TableColumnPopupType.orderPrepCompleted)) {
                            let productTableConfiguration = this.getDefaultProductTableConfiguration()
                            productTableConfiguration.isIncludesProductCustomField = true
                            TemplateSettingsManager.updateProductTableConfiguration(productTableConfiguration)
                            newTableColumnData = this.getTableColumnForWorkOrder()
                            TemplateSettingsManager.updateTablePopupInfo(newTableColumnData)
                        }
                    }
                    else if (getIsStockTransfer(type)) {
                        if (columns.includes(TableColumnPopupType.totalAmount)) {
                            newTableColumnData = this.getTableColumnForStockTransfer()
                            TemplateSettingsManager.updateTablePopupInfo(newTableColumnData)
                        }
                    }
                    else if (getIsStockAdjustment(type)) {
                        if (!columns.includes(TableColumnPopupType.accountName)) {
                            newTableColumnData = this.getTableColumnForStockAdjustment()
                            TemplateSettingsManager.updateTablePopupInfo(newTableColumnData)
                        }
                    }
                }

                if(this.remoteTemplateUID !== undefined && this.remoteTemplateUID !== null) {
                    if (type.toLowerCase() === 'expense') {
                        if (data.referenceDate === undefined || data.referenceDate === null) {
                            TemplateSettingsManager.updateDocumentDueDateVisibility(false)
                        }
                    }
                    else if (type === 'deposit') {
                        if (data.referenceDate === undefined || data.referenceDate === null) {
                            TemplateSettingsManager.updateDocumentDueDateVisibility(false)
                        }
                    }
                    return
                }

                if (getIsMakePayment(type) || getIsReceivePayment(type)) {
                    TemplateSettingsManager.updateBillToTitle(PaymentReceiptMapper.getLabel().receivedFrom)
                    TemplateSettingsManager.updateShipToTitle(PaymentReceiptMapper.getLabel().paymentDetails)
                    TemplateSettingsManager.updateBillToPlaceholderTitle(PaymentReceiptMapper.getLabel().customerAddressPlaceholder)
                    TemplateSettingsManager.updateShipToPlaceholderTitle(PaymentReceiptMapper.getLabel().paymentDetails)
                    TemplateSettingsManager.updateDocumentDueDateVisibility(true)
                    TemplateSettingsManager.updateTermsAndConditionsVisibility(false)
                    TemplateSettingsManager.updateNotesVisibility(false)
                }
                else if (type.toLowerCase() === 'expense') {
                    TemplateSettingsManager.updateBillToTitle(ExpenseMapper.getLabel().contact)
                    TemplateSettingsManager.updateShipToTitle(ExpenseMapper.getLabel().paymentFrom)
                    TemplateSettingsManager.updateBillToPlaceholderTitle(ExpenseMapper.getLabel().customerAddressPlaceholder)
                    TemplateSettingsManager.updateShipToPlaceholderTitle(ExpenseMapper.getLabel().accountNamePlaceholder)
                    TemplateSettingsManager.updateShipToAddressVisibility(true)
                        if (data.referenceDate === undefined || data.referenceDate === null) {
                            TemplateSettingsManager.updateDocumentDueDateVisibility(false)
                        }
                    TemplateSettingsManager.updateLinkDocumentVisibility(false)
                    TemplateSettingsManager.updateTermsAndConditionsVisibility(true)
                    TemplateSettingsManager.updateNotesVisibility(true)
                }
                else if (type === 'deposit') {
                    TemplateSettingsManager.updateBillToTitle(DepositMapper.getLabel().contact)
                    TemplateSettingsManager.updateShipToTitle(DepositMapper.getLabel().depositTo)
                    TemplateSettingsManager.updateBillToPlaceholderTitle(ExpenseMapper.getLabel().customerAddressPlaceholder)
                    TemplateSettingsManager.updateShipToPlaceholderTitle(ExpenseMapper.getLabel().accountNamePlaceholder)
                    TemplateSettingsManager.updateShipToAddressVisibility(true)
                        if (data.referenceDate === undefined || data.referenceDate === null) {
                            TemplateSettingsManager.updateDocumentDueDateVisibility(false)
                        }
                    TemplateSettingsManager.updateLinkDocumentVisibility(false)
                    TemplateSettingsManager.updateTermsAndConditionsVisibility(true)
                    TemplateSettingsManager.updateNotesVisibility(true)
                }
                else if (type === 'credit_note' || type === 'debit_note' || type === 'debit name' || type === 'credit note') {
                    TemplateSettingsManager.updateBillToTitle(CreditNoteMapper.getLabel().contact)
                    TemplateSettingsManager.updateBillToPlaceholderTitle(CreditNoteMapper.getLabel().contactAddressPlaceholder)
                    TemplateSettingsManager.updateShipToTitle('')
                    TemplateSettingsManager.updateShipToPlaceholderTitle('')
                    TemplateSettingsManager.updateShipToAddressVisibility(false)
                    TemplateSettingsManager.updateDocumentDueDateVisibility(false)
                    TemplateSettingsManager.updateTermsAndConditionsVisibility(true)
                    TemplateSettingsManager.updateNotesVisibility(true)
                    TemplateSettingsManager.updateLinkDocumentVisibility(false)
                }
                else if (type === 'fulfillment') {
                    TemplateSettingsManager.updateBillToTitle(FulfillmentMapper.getLabel().shipTo)
                    TemplateSettingsManager.updateShipToTitle(FulfillmentMapper.getLabel().shipFrom)
                    TemplateSettingsManager.updateBillToPlaceholderTitle(FulfillmentMapper.getLabel().shipToPlaceholder)
                    TemplateSettingsManager.updateShipToPlaceholderTitle(FulfillmentMapper.getLabel().shipFromPlaceholder)
                    TemplateSettingsManager.updateShipToAddressVisibility(true)
                    TemplateSettingsManager.updateDocumentDueDateVisibility(false)
                }
                else if (type === 'goods_receipt') {
                    TemplateSettingsManager.updateBillToTitle(GoodsReceiptMapper.getLabel().shipTo)
                    TemplateSettingsManager.updateShipToTitle(GoodsReceiptMapper.getLabel().shipFrom)
                    TemplateSettingsManager.updateBillToPlaceholderTitle(GoodsReceiptMapper.getLabel().shipToPlaceholder)
                    TemplateSettingsManager.updateShipToPlaceholderTitle(GoodsReceiptMapper.getLabel().shipFromPlaceholder)
                    TemplateSettingsManager.updateShipToAddressVisibility(true)
                    TemplateSettingsManager.updateDocumentDueDateVisibility(false)
                }
                else if (getIsJournalEntry(type)) {
                    TemplateSettingsManager.updateBillToAddressVisibility(false)
                    TemplateSettingsManager.updateShipToAddressVisibility(false)
                    TemplateSettingsManager.updateDocumentDueDateVisibility(false)
                    TemplateSettingsManager.updateTermsAndConditionsVisibility(false)
                    TemplateSettingsManager.updateNotesText('')
                }
                else if (getIsPickList(type)) {
                    // TemplateSettingsManager.updateBillToTitle('warehouse_name')
                    // TemplateSettingsManager.updateBillToPlaceholderTitle('warehouse_address')
                    TemplateSettingsManager.updateBillToAddressVisibility(false)
                    TemplateSettingsManager.updateShipToAddressVisibility(false)
                    TemplateSettingsManager.updateDocumentDueDateVisibility(false)
                    TemplateSettingsManager.updateNotesText('')
                    TemplateSettingsManager.updateTermsAndConditionsText('')
                }
                else if (getIsPackList(type)) {
                    TemplateSettingsManager.updateBillToTitle('contact')
                    TemplateSettingsManager.updateBillToPlaceholderTitle('customer_address_placeholder_text_value')
                    TemplateSettingsManager.updateBillToAddressVisibility(true)
                    TemplateSettingsManager.updateShipToAddressVisibility(false)
                    TemplateSettingsManager.updateDocumentDueDateVisibility(false)
                    TemplateSettingsManager.updateNotesText('')
                    TemplateSettingsManager.updateTermsAndConditionsText('')
                }
                else if (getIsShipList(type)) {
                    TemplateSettingsManager.updateBillToTitle(GoodsReceiptMapper.getLabel().shipTo)
                    TemplateSettingsManager.updateShipToTitle(GoodsReceiptMapper.getLabel().shipFrom)
                    TemplateSettingsManager.updateBillToPlaceholderTitle(GoodsReceiptMapper.getLabel().shipFromPlaceholder)
                    TemplateSettingsManager.updateShipToPlaceholderTitle(GoodsReceiptMapper.getLabel().shipToPlaceholder)
                    TemplateSettingsManager.updateDocumentDueDateVisibility(false)
                    TemplateSettingsManager.updateNotesText('')
                    TemplateSettingsManager.updateTermsAndConditionsText('')
                }
                else if (getIsStockTransfer(type)) {
                    TemplateSettingsManager.updateBillToTitle('source')
                    TemplateSettingsManager.updateBillToPlaceholderTitle('source_address')
                    TemplateSettingsManager.updateBillToAddressVisibility(false)
                    TemplateSettingsManager.updateShipToTitle('destination')
                    TemplateSettingsManager.updateShipToPlaceholderTitle('destination_address')
                    TemplateSettingsManager.updateShipToAddressVisibility(false)
                    TemplateSettingsManager.updateDocumentDueDateVisibility(true)
                    TemplateSettingsManager.updateNotesText('')
                    TemplateSettingsManager.updateTermsAndConditionsText('')
                }
                else if (getIsStockAdjustment(type)) {
                    TemplateSettingsManager.updateBillToTitle('source')
                    TemplateSettingsManager.updateBillToPlaceholderTitle('source_address')
                    TemplateSettingsManager.updateBillToAddressVisibility(false)
                    TemplateSettingsManager.updateShipToTitle('destination')
                    TemplateSettingsManager.updateShipToPlaceholderTitle('destination_address')
                    TemplateSettingsManager.updateShipToAddressVisibility(false)
                    TemplateSettingsManager.updateDocumentDueDateVisibility(false)
                    TemplateSettingsManager.updateNotesText('')
                    TemplateSettingsManager.updateTermsAndConditionsText('')
                }
                else {
                    TemplateSettingsManager.updateBillToTitle('bill_to')
                    TemplateSettingsManager.updateShipToTitle('ship_to')
                    TemplateSettingsManager.updateBillToPlaceholderTitle('billing_address_placeholder_text_value')
                    TemplateSettingsManager.updateShipToPlaceholderTitle('shipping_address_placeholder_text_value')
                    TemplateSettingsManager.updateShipToAddressVisibility(true)
                    TemplateSettingsManager.updateDocumentDueDateVisibility(true)
                    TemplateSettingsManager.updateLinkDocumentVisibility(false)
                    TemplateSettingsManager.updateTermsAndConditionsVisibility(true)
                    TemplateSettingsManager.updateNotesVisibility(true)
                }
            }
        }
    }

    static resetDefaultDataForReceipt(data: any) {
        data = this.getDefaultDocumentTemplate(data)
        if (Utility.getIsReceiptPaperSize() && data.templateId === 1){
            TemplateSettingsManager.updateCompanyLogoVisibility(false)
            TemplateSettingsManager.updateShipToAddressVisibility(false)
            TemplateSettingsManager.updateBillToAddressVisibility(false)
            TemplateSettingsManager.updateDocumentDueDateVisibility(false)
            TemplateSettingsManager.updateLinkDocumentVisibility(false)
            TemplateSettingsManager.updateSignatureVisibility(false)
            data.showCompanyLogo = false
            data.showClientBillToAddress = false
            data.showClientShipToAddress = false
            data.showDueDate = false
            data.showAdditionalDate = false
            data.showLinkDocumentNumber = false
            data.showSignature = false
            this.resetGlobalFontSize(0.3)
            data.tableColumnPopup = TemplateSettingsManager.getTableColumnForReceipt()
        }
        if (Utility.getIsReceiptPaperSize() && data.templateId === 2) {
            TemplateSettingsManager.updateSignatureVisibility(false)
            data.showSignature = false
            this.resetGlobalFontSize(0.3)
            data.tableColumnPopup = TemplateSettingsManager.getTableColumnForReceipt()
        }
        return data
    }

    static getDefaultDocumentTemplate(data: any) {
        TemplateSettingsManager.updateCompanyLogoVisibility(true)
        TemplateSettingsManager.updateShipToAddressVisibility(true)
        TemplateSettingsManager.updateBillToAddressVisibility(true)
        TemplateSettingsManager.updateDocumentDueDateVisibility(true)
        TemplateSettingsManager.updateLinkDocumentVisibility(false)
        TemplateSettingsManager.updateTermsAndConditionsVisibility(true)
        TemplateSettingsManager.updateNotesVisibility(true)
        TemplateSettingsManager.updateSignatureVisibility(true)
        data.showCompanyLogo = true
        data.showClientBillToAddress = true
        data.showClientShipToAddress = true
        data.showClientShipFromAddress = true
        data.showDueDate = true
        data.showAdditionalDate = true
        data.showLinkDocumentNumber = false
        data.showSignature = true
        data.showTermsAndCondition = true
        data.showNotes = true
        this.resetGlobalFontSize(0)
        data.tableColumnPopup = TemplateSettingsManager.getTableColumnForProduct()
        return data
    }

    static getIsHSNSelected(tableInfo: any) {
        if(tableInfo === undefined || tableInfo === null) {
            tableInfo = TemplateSettingsManager.defaultTemplateSettings.tableInfo
        }

        var isSelected = true
        var data = tableInfo
        var isProduct_HSN = true
        var isProductWithDescription_HSN = true

        if (data.length > 0) {
            let product = data.find((x: any) => x.type === TableColumnPopupType.productName)
            if (product !== undefined) {
                if (product.isShowHsn !== undefined && product.isShowHsn !== null) {
                    isProduct_HSN = product.isShowHsn
                }
            }

            let productWithDescription = data.find((x: any) => x.type === TableColumnPopupType.productNameWithDescription)
            if (productWithDescription !== undefined) {
                if (productWithDescription.isShowHsn !== undefined && productWithDescription.isShowHsn !== null) {
                    isProductWithDescription_HSN = productWithDescription.isShowHsn
                }
            }

            isSelected = (isProduct_HSN && isProductWithDescription_HSN)
        }
        return isSelected
    }

    static getIsAlterUOMSelected(tableInfo: any) {
        if (tableInfo === undefined || tableInfo === null) {
            tableInfo = TemplateSettingsManager.defaultTemplateSettings.tableInfo
        }

        var isSelected = true
        var data = tableInfo
        var isUOM_ALTERUOM = true
        var isQUANTITY_UOM_ALTERUOM = true

        if (data.length > 0) {
            let uom = data.find((x: any) => x.type === TableColumnPopupType.uom)
            if (uom !== undefined) {
                if (uom.isShowAlterUOM !== undefined && uom.isShowAlterUOM !== null) {
                    isUOM_ALTERUOM = uom.isShowAlterUOM
                }
            }

            let quantityWithUom = data.find((x: any) => x.type === TableColumnPopupType.quantityWithUom)
            if (quantityWithUom !== undefined) {
                if (quantityWithUom.isShowAlterUOM !== undefined && quantityWithUom.isShowAlterUOM !== null) {
                    isQUANTITY_UOM_ALTERUOM = quantityWithUom.isShowAlterUOM
                }
            }

            isSelected = (isUOM_ALTERUOM && isQUANTITY_UOM_ALTERUOM)
        }
        return isSelected
    }

    static resetGlobalFontSize(value: any) {
        var style = TemplateSettingsManager.defaultTemplateSettings.templateInfo.style
        if (style === undefined || style === null) {
            style = TemplateSettingsManager.getDefaultTemplateStyle()
        }
        style.font.size = value
        TemplateSettingsManager.updateTemplateStyle(style)
    }

    static getDefaultAdditionalTableConfiguration() {
        return {
            isVisible: true,
            elements: [],
        }
    }

    static getAdditionalTableIsVisible() {
        var additionalTable = TemplateSettingsManager.defaultTemplateSettings.additionalTable
        if (additionalTable !== undefined && additionalTable !== null) {
            if (additionalTable.isVisible !== undefined && additionalTable.isVisible !== null) {
                return additionalTable.isVisible
            }
        }
        return false
    }

    static getDefaultTableStyle() {
        return {
            style: 'normal',
            size: 0,
            color: null,
            weight: null,
            family: null,
            isEnable: false,
            isFixRowHeightEnable: false,
            rowHeight: 0,
            isPaddingHorizontalEnable: false,
            paddingHorizontal: 0,
            isPaddingVerticalEnable: false,
            paddingVertical: 0,
            text: undefined,
            tableColumnAlignmentList: undefined,
            tableColumnVerticalAlignmentList: undefined,
        }
    }


    static getDefaultTopSectionBeforeTableStyle() {
        return {
            style: 'normal',
            size: 0,
            color: '#000000',
            weight: null,
            family: null,
            isEnable: true,
            isPaddingHorizontalEnable: false,
            paddingHorizontal: undefined,
            isPaddingVerticalEnable: false,
            paddingVertical: undefined,
            border: undefined,
            borderColor: undefined,
            borderWidth: undefined,
            textDecoration: undefined,
        }
    }

    static getWatermarkVisible() {
        var watermark = TemplateSettingsManager.defaultTemplateSettings.documentInfo.watermark
        if (watermark !== undefined && watermark !== null) {
            if (watermark.isVisible !== undefined && watermark.isVisible !== null) {
                return watermark.isVisible
            }
        }
        return false
    }

    static updateEInvoiceSectionIsQRResize(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        var eInvoiceSection = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.eInvoiceSection
        if (eInvoiceSection === undefined || eInvoiceSection === null) {
            eInvoiceSection = this.getDefaultEInvoiceSection()
        }
        if (eInvoiceSection.isResizeQR === undefined || eInvoiceSection.isResizeQR === null) {
            eInvoiceSection['isResizeQR'] = false
        }
        eInvoiceSection.isResizeQR = val
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.eInvoiceSection = eInvoiceSection
    }

    static updateEInvoiceSectionQRResizeValue(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        var eInvoiceSection = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.eInvoiceSection
        if (eInvoiceSection === undefined || eInvoiceSection === null) {
            eInvoiceSection = this.getDefaultEInvoiceSection()
        }
        if (eInvoiceSection.resizeValue === undefined || eInvoiceSection.resizeValue === null) {
            eInvoiceSection['resizeValue'] = false
        }
        eInvoiceSection.resizeValue = val
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.eInvoiceSection = eInvoiceSection
    }

    static updateEInvoiceSectionPosition(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        var eInvoiceSection = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.eInvoiceSection
        if (eInvoiceSection === undefined || eInvoiceSection === null) {
            eInvoiceSection = this.getDefaultEInvoiceSection()
        }
        if (eInvoiceSection.position === undefined || eInvoiceSection.position === null) {
            eInvoiceSection['position'] = false
        }
        eInvoiceSection.position = val
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.eInvoiceSection = eInvoiceSection
    }

    static getEInvoiceSectionIsQRResize() {
        var eInvoiceSection = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.eInvoiceSection
        if (eInvoiceSection !== undefined && eInvoiceSection !== null) {
            if (eInvoiceSection.isResizeQR !== undefined && eInvoiceSection.isResizeQR !== null) {
                return eInvoiceSection.isResizeQR
            }
        }
        return false
    }

    static getEInvoiceSectionQRResizeValue() {
        var eInvoiceSection = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.eInvoiceSection
        if (eInvoiceSection !== undefined && eInvoiceSection !== null) {
            if (eInvoiceSection.resizeValue !== undefined && eInvoiceSection.resizeValue !== null) {
                return eInvoiceSection.resizeValue
            }
        }
        return 0
    }

    static getEInvoiceSectionPosition() {
        var eInvoiceSection = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.eInvoiceSection
        if (eInvoiceSection !== undefined && eInvoiceSection !== null) {
            if (eInvoiceSection.position !== undefined && eInvoiceSection.position !== null) {
                return eInvoiceSection.position
            }
        }
        return 'top'
    }

    static getDefaultEInvoiceSection() {
        return {
            isVisible: false,
            isResizeQR: false,
            position: "top",
            resizeValue: 0,
        }
    }

    static getBarcodeSettings(key: any, defaultValue: any) {
        var barcodeSettings = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.barcodeSettings
        if (barcodeSettings !== undefined && barcodeSettings !== null) {
            if (barcodeSettings[key] !== undefined && barcodeSettings[key] !== null) {
                return barcodeSettings[key]
            }
        }
        return defaultValue
    }

    static updateBarcodeSettings(key: any, defaultValue: any, val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        var barcodeSettings = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.barcodeSettings
        if (barcodeSettings === undefined || barcodeSettings === null) {
            barcodeSettings = this.getDefaultBarcodeSettings()
        }
        if (barcodeSettings[key] === undefined || barcodeSettings[key] === null) {
            barcodeSettings[key] = defaultValue
        }
        barcodeSettings[key] = val
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.barcodeSettings = barcodeSettings
    }

    static getDefaultBarcodeSettings() {
        return {
            isVisible: false,
            isResizeBarcode: false,
            resizeValue: 0,
        }
    }

    static getTableColumnForAccountProduct() {
        var accountProductTableConfig = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.accountProductTableConfig

        if(accountProductTableConfig === undefined || accountProductTableConfig === null) {
            accountProductTableConfig = this.getTableColumnForProduct()
        }

        return accountProductTableConfig
    }

    static updateAccountProductTable(tableInfo: any) {
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.accountProductTableConfig = tableInfo
    }

    static getTableColumnForAccountProductIsVisible() {
        var isVisible = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.accountProductTableConfigIsVisible

        if(isVisible === undefined || isVisible === null) {
            isVisible = false
        }

        return isVisible
    }

    static updateTableColumnForAccountProductVisibility(val: any) {
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails['accountProductTableConfigIsVisible'] = val
    }

    static getDocumentDetailsList(data: any) {
        var documentDetailsList = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.documentDetailsList

        if (AppManager.getDocumentId() === undefined) {
            documentDetailsList = DocumentDetailsParser.getDocumentDetailsList(data)
        }
        else {
            if (documentDetailsList === undefined || documentDetailsList === null) {
                documentDetailsList = DocumentDetailsParser.getDocumentDetailsList(data)
            }
        }

        documentDetailsList = DocumentDetailsParser.removeLinkDocument(data, documentDetailsList)
        documentDetailsList = DocumentDetailsParser.addNewDocumentDetailType(data, documentDetailsList)
        return documentDetailsList
    }

    static updateDocumentDetailsList(val: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        var documentDetailsList = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.documentDetailsList

        if(documentDetailsList === undefined || documentDetailsList === null) {
            TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.documentDetailsList = []
        }
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.documentDetailsList = val
    }

    static getWorkOrderSettings() {
        var workOrderSettings = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.workOrderSettings
        if (workOrderSettings === undefined || workOrderSettings === null) {
            workOrderSettings = this.getDefaultWorkOrderSettings()
        }
        return workOrderSettings
    }

    static updateWorkOrderSettings(workOrderSettings: any) {
        TemplateSettingsManager.userDidChangeTemplateSetting()
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.workOrderSettings = workOrderSettings
    }

    static getDefaultWorkOrderSettings() {
        return {
            product: 'SHOW_PRODUCT',
            substitute: 'SHOW_ALL_SUBSTITUTE',
            showProductLabel: false,
            showSubstituteLabel: false,
            productLabel: '',
            substituteLabel: '',
            productLabelPosition: 'left',
            substituteLabelPosition: 'left',
        }
    }

    static getDefaultTableColumnByType(type: any) {
        if (getIsAccountModule(undefined, type.toLowerCase())) {
            return this.getTableColumnOptionsForAccount(type)
        }
        else if (getIsFulfillment(type)) {
            return this.getTableColumnForFulfillment()
        }
        else if (getIsGoodsReceipt(type)) {
            return this.getTableColumnForGoodsReceipt()
        }
        else if (getIsMakePayment(type) || getIsReceivePayment(type)) {
            return this.getTableColumnOptionsForPayment(type)
        }
        else if (getIsJournalEntry(type)) {
            return this.getTableColumnForJournalEntry()
        }
        else if (getIsPickList(type)) {
            return this.getTableColumnForPickingList()
        }
        else if (getIsPackList(type)) {
            return this.getTableColumnForPackingList()
        }
        else if (getIsShipList(type)) {
            return this.getTableColumnForShippingList()
        }
        else if (getIsWorkOrder(type)) {
            return this.getTableColumnForWorkOrder()
        }
        else if (getIsStockTransfer(type)) {
            return this.getTableColumnForStockTransfer()
        }
        else if (getIsStockAdjustment(type)) {
            return this.getTableColumnForStockAdjustment()
        }

        return this.getTableColumnForProduct()
    }

    static getComponentMaxId() {
        var componentList = TemplateSettingsManager.defaultTemplateSettings.documentInfo.componentList

        var maxId = 0
        const componentIdList = componentList.map((x: any) => x.id).sort((n1: any, n2: any) => {
            return n2 - n1
        })
        if (componentIdList === undefined || componentIdList === null) {
            return maxId
        }
        if (componentIdList.length === 0) {
            return maxId
        }
        else {
            const value = componentIdList[0]
            if (value !== undefined) {
                maxId = value
            }
        }
        return maxId
    }
}

