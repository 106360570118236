import React, { useEffect, useRef, useState } from 'react';
import DocumentGrid from '../components/common/DocumentGrid';
import {
  DKLabel,
  DKIcon,
  DKIcons,
  DKDateRangePicker
} from 'deskera-ui-library';
import { getQueryFromFilter, sortAndUpdateColumns } from '../services/tables';
import { getPaymentReceipt } from '../services/books';
import {
  deepClone,
  getCurrencySymbolFromCode,
  isNotEmpty,
  toCurrencyFormat
} from '../utility/Helper';
import {
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  DEFAULT_THEME_COLOR,
  DEPOSIT_OPTIONS
} from '../constants/Constant';
import { endOfYear, startOfYear } from 'date-fns';
import { getDateStrFromDate } from '../utility/Date';
import PrintPreview from '../components/common/PrintPreview';
import { BOOKS_DOCS_TYPES } from '../constants/Enum';
import { useAppSelector } from '../store/hooks';
import { selectTenantSettings } from '../store/slices/authSlice';
const COLUMN_CONFIG = [
  {
    name: 'Code',
    type: 'text',
    index: 4,
    options: null,
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'code',
    key: 'code',
    allowFilter: false
  },
  {
    name: 'Total Amount',
    type: 'number',
    index: 5,
    options: null,
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'amount',
    key: 'amount',
    allowFilter: false
  },
  {
    name: 'Payment Type',
    type: 'select',
    index: 8,
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'paymentType',
    key: 'paymentType',
    id: 'paymentType',
    allowFilter: true,
    options: [
      {
        id: 'BANK_TRANSFER',
        name: 'Bank Transfer',
        color: 'data-grid-badge-color-6'
      },
      {
        id: 'CHEQUE',
        name: 'Cheque',
        color: 'data-grid-badge-color-3'
      },
      {
        id: 'CASH',
        name: 'Cash',
        color: 'data-grid-badge-color-2'
      },
      {
        id: 'OTHERS',
        name: 'Others',
        color: 'data-grid-badge-color-4'
      },
      {
        id: 'CARD',
        name: 'Card',
        color: 'data-grid-badge-color-1'
      },
      {
        id: 'CREDIT_NOTE',
        name: 'Credit Note',
        color: 'data-grid-badge-color-5'
      }
    ]
  },
  {
    name: 'Payment Date',
    type: 'date',
    index: 9,
    options: null,
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'referenceDate',
    key: 'referenceDate',
    allowFilter: false
  },
  {
    name: 'Document Type',
    type: 'text',
    index: 2,
    options: null,
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'documentType',
    key: 'documentType',
    allowFilter: false
  },
  {
    name: 'Document Number',
    type: 'text',
    index: 1,
    options: null,
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'documentSequenceCode',
    key: 'documentSequenceCode',
    allowFilter: false
  },
  {
    name: 'Amount Due',
    type: 'number',
    index: 6,
    options: null,
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'dueAmount',
    key: 'dueAmount',
    allowFilter: false
  },
  {
    name: 'Amount Paid',
    type: 'number',
    index: 7,
    options: null,
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'paidAmount',
    key: 'paidAmount',
    allowFilter: false
  },
  {
    name: 'Reference Number',
    type: 'number',
    index: 11,
    options: null,
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'referenceNumber',
    key: 'referenceNumber',
    allowFilter: false
  }
];
interface IPaymentReceiptsProps {}

const PaymentReceipts: React.FC<IPaymentReceiptsProps> = (props: any) => {
  const [rowData, setRowData] = useState<any>([]);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const apiCallInProgress = useRef<boolean>(false);
  const [filters, setFilters] = useState<any>();
  const [dateFilters, setDateFilters] = useState<any>({
    financialStartDate: startOfYear(new Date()),
    financialEndDate: endOfYear(new Date())
  });
  const [showHideCalendar, setShowHideCalendar] = useState<boolean>(false);
  const [showHidePrintPopup, setShowHidePrintPopup] = useState<boolean>(false);
  const [selectedReceipt, setSelectedReceipt] = useState<any>();
  const settings = useAppSelector(selectTenantSettings);

  const [pagination, setPagination] = useState<any>({
    page: DEFAULT_PAGE_NO - 1,
    limit: DEFAULT_PAGE_SIZE,
    sortDir: 'DESC',
    sort: 'documentDate'
  });
  useEffect(() => {
    loadPaymentReceipt(0);
  }, [searchTerm]);

  useEffect(() => {
    setShowHidePrintPopup(isNotEmpty(selectedReceipt));
  }, [selectedReceipt]);

  useEffect(() => {
    loadPaymentReceipt(0);
  }, [filters, dateFilters]);

  const onPageChange = (page: number) => {
    setPagination({ ...pagination, page: page });
  };
  const loadPaymentReceipt = (page?: any) => {
    if (!apiCallInProgress.current) {
      apiCallInProgress.current = true;
      getPaymentReceipt(getPayload())
        .then((res: any) => {
          setRowData(res.content);
          setTotalPageCount(res.totalPages);
        })
        .catch((err: any) => {
          console.error(err);
        })
        .finally(() => {
          apiCallInProgress.current = false;
        });
    }
  };
  const getPayload = () => ({
    limit: pagination.limit,
    page: pagination.page,
    search: searchTerm,
    query: getQueryFromFilter(
      COLUMN_CONFIG,
      filters?.query,
      dateFilters,
      BOOKS_DOCS_TYPES.BOOKS_PAYMENT
    )?.join(','),
    sortDir: pagination.sortDir || 'DESC',
    sort: pagination.sort
  });
  const parseRowData = (paymentList: any[]) => {
    let updatedRows = [];

    if (isNotEmpty(paymentList)) {
      updatedRows = deepClone(paymentList);
      updatedRows = updatedRows.map((row) => {
        let paidAmount = 0;
        row.receivePaymentItemDtoList.forEach((list: any) => {
          paidAmount += list.paymentAmount;
        });
        let dueAmount = row.amount - paidAmount;
        // row.rowButtons = this.getActionButtons(row);
        // row.rowContextMenu = this.getRowContextMenu(row);
        row.documentSequenceCode =
          row.receivePaymentItemDtoList[0].documentSequenceCode;
        row.paymentType = row.paymentType = [row.paymentType];
        row.documentType =
          DEPOSIT_OPTIONS[`${row.receivePaymentItemDtoList[0].documentType}`];
        row.amount = toCurrencyFormat(
          row.amount,
          getCurrencySymbolFromCode(row.currency)
        );
        row.dueAmount = toCurrencyFormat(
          dueAmount,
          getCurrencySymbolFromCode(row.currency)
        );
        row.paidAmount = toCurrencyFormat(
          paidAmount,
          getCurrencySymbolFromCode(row.currency)
        );
        row.allowRowEdit = true;
        row.rowContextMenu = getContextMenuForRow(row);
        return row;
      });
    }
    return updatedRows;
  };
  const setInvoiceForPrint = (invoice: any) => {
    setSelectedReceipt(invoice);
  };
  const getDateRangePicker = () => {
    return (
      <div className="position-absolute shadow-m bg-white z-index-6 top-12 right-20">
        <DKDateRangePicker
          className="border shadow "
          onClose={() => setShowHideCalendar(false)}
          color={settings?.theme || DEFAULT_THEME_COLOR}
          showPresetList={true}
          startDate={dateFilters.financialStartDate}
          selectedStartDate={dateFilters.financialStartDate}
          selectedEndDate={dateFilters.financialEndDate}
          onSelectDateRange={(startDate: Date, endDate: Date) => {
            if (startDate && endDate) {
              setShowHideCalendar(false);
              setDateFilters({
                financialStartDate: startDate,
                financialEndDate: endDate
              });
            }
          }}
        />
      </div>
    );
  };
  const getDateRangeString = () => {
    if (isNotEmpty(dateFilters)) {
      return (
        getDateStrFromDate(dateFilters.financialStartDate) +
        ' to ' +
        getDateStrFromDate(dateFilters.financialEndDate)
      );
    } else {
      return '';
    }
  };
  const getDocumentGridHeaderButtons = () => {
    let buttons = [];
    buttons.push({
      title: getDateRangeString(),
      className: 'border-m bg-white ml-r position-relative',
      icon: DKIcons.ic_calendar,
      onClick: () => setShowHideCalendar(true)
    });
    return buttons;
  };
  const getContextMenuForRow = (invoice: any) => {
    const menuItems = [];
    menuItems.push({
      title: 'View',
      icon: DKIcons.ic_view,
      onClick: ({ rowIndex, rowData }: any) => setInvoiceForPrint(rowData)
    });
    return menuItems;
  };

  const onFilter = (data: any) => {
    setFilters(data);
  };
  return (
    <div className="column parent-width mb-s position-relative flex-1">
      <div className="row justify-content-between p-l border-radius-m border-m bg-deskera-secondary">
        <DKLabel
          text="💼 Manage Payment receipts here! You can download, print payment receipts."
          className=""
        />
      </div>
      <div className="parent-width row position-relative">
        <div
          className="position-absolute z-index-3 mobile-left-0"
          style={{ right: 630, top: 45 }}
        >
          {showHideCalendar && getDateRangePicker()}
        </div>
      </div>
      <div className="column pt-xl parent-width mb-s position-relative flex-1">
        <DocumentGrid
          title={'💸 Payment Receipts'}
          documentType={'Payment'}
          rows={parseRowData(rowData)}
          columns={sortAndUpdateColumns(COLUMN_CONFIG)}
          needBoldTheme={true}
          allowSearch={true}
          allowFilter={true}
          needTrailingColumn={true}
          currentPage={pagination.page}
          totalPageCount={totalPageCount}
          filterData={filters?.query}
          onFilter={onFilter}
          onSearch={(searchTerm) => {
            setSearchTerm(searchTerm);
          }}
          allowRowEdit={true}
          onPageChange={onPageChange}
          updating={apiCallInProgress.current}
          headerButtons={getDocumentGridHeaderButtons()}
          onRowOpenClick={({ rowData }: any) => setInvoiceForPrint(rowData)}
        />
      </div>
      {showHidePrintPopup && (
        <PrintPreview
          category="PAYMENT"
          document={{
            ...selectedReceipt,
            documentCode: selectedReceipt?.code,
            documentType: 'RECEIVE_PAYMENT'
          }}
          event="PRINT"
          onClose={() => {
            setShowHidePrintPopup(false);
            setSelectedReceipt(null);
          }}
        />
      )}
    </div>
  );
};

export default PaymentReceipts;
