import { DKButton } from 'deskera-ui-library';
import OrderSummary from './OrderSummary';
import Products from '../pages/Products';
import { useRef, useState } from 'react';

interface ICreateOrderProps {
  onSave: () => void;
  onClose: () => void;
}

const CreateOrder: React.FC<ICreateOrderProps> = (props) => {
  const [showOrderSummary, showHideOrderSummary] = useState<boolean>(false);
  const popupWrapperRef = useRef<any>();
  const getHeader = () => {
    return (
      <div className="row-reverse p-h-l p-v-s bg-gray1" style={{ gap: 10 }}>
        {/* <DKButton
          title={'Save'}
          className="bg-blue border-m text-white"
          onClick={props?.onSave}
        /> */}
        <DKButton
          title={'Close'}
          className="bg-white border-m"
          onClick={props?.onClose}
        />
      </div>
    );
  };
  return (
    <>
      <div className="transparent-background">
        <div
          className="popup-window overflow-hidden"
          style={{
            maxHeight: '95%',
            height: '95%',
            // minHeight: '50%',
            width: '55%',
            maxWidth: '90%',
            padding: 0
          }}
          ref={popupWrapperRef}
        >
          <>{getHeader()}</>
          <div className="parent-height p-h-l">
            <Products
              onSave={() => {}}
              onClose={() => {}}
              width={
                popupWrapperRef.current?.getBoundingClientRect()?.width - 50
              }
            />
          </div>
        </div>
      </div>
      {showOrderSummary && (
        <OrderSummary
          onSave={() => {}}
          onClose={() => showHideOrderSummary(false)}
        />
      )}
    </>
  );
};

export default CreateOrder;
