import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  DKLabel,
  DKDateRangePicker,
  DKIcons,
  INPUT_TYPE
} from 'deskera-ui-library';
import DocumentGrid from '../components/common/DocumentGrid';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
  fetchQuotes,
  selectQuotes,
  selectTablesData
} from '../store/slices/booksSlice';
import { BOOKS_DOCS_TYPES, DATE_FORMATS } from '../constants/Enum';
import {
  deepClone,
  getCurrencySymbolFromCode,
  isNotEmpty,
  toCurrencyFormat,
  debounce,
  isEmptyObject
} from '../utility/Helper';
import { getDateFromString, getDateStrFromDate } from '../utility/Date';
import {
  COLUMN_CODE,
  COUNTRY_CODES,
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  DEFAULT_THEME_COLOR
} from '../constants/Constant';
import { startOfYear } from 'date-fns';
import { endOfYear } from 'date-fns/esm';
import { getQueryFromFilter, sortAndUpdateColumns } from '../services/tables';
import PrintPreview from '../components/common/PrintPreview';
import {
  selectTenantSettings,
  selectUserInfo
} from '../store/slices/authSlice';
import { attachmentRenderer } from '../utility/renderer';
import AttachmentPopup from '../components/common/AttachmentPopup';

interface IQuotationsProps {}
const VISIBLE_COLUMNS_CODES: any = [
  COLUMN_CODE.QUOTE.NUMBER,
  COLUMN_CODE.QUOTE.QUOTE_DATE,
  COLUMN_CODE.QUOTE.DUE_DATE,
  COLUMN_CODE.QUOTE.TOTAL_AMOUNT,
  COLUMN_CODE.QUOTE.STATUS,
  COLUMN_CODE.QUOTE.FULFILLMENT
];
const Quotations: React.FC<IQuotationsProps> = (props) => {
  const tableData = useAppSelector(
    selectTablesData(BOOKS_DOCS_TYPES.BOOKS_QUOTE)
  );
  const dispatch = useAppDispatch();
  const quotesData = useAppSelector(selectQuotes);
  const [columns, setColumns] = useState<any[]>([]);
  const [searchText, setSearchText] = useState<string>();
  const [pagination, setPagination] = useState<any>({
    page: DEFAULT_PAGE_NO - 1,
    limit: DEFAULT_PAGE_SIZE,
    sortDir: 'DESC',
    sort: 'documentSequenceCode'
  });

  const [filters, setFilters] = useState<any>();
  const [dateFilters, setDateFilters] = useState<any>({
    financialStartDate: startOfYear(new Date()),
    financialEndDate: endOfYear(new Date())
  });
  const [showHideCalendar, setShowHideCalendar] = useState<boolean>(false);
  const [showHidePrintPopup, setShowHidePrintPopup] = useState<boolean>(false);
  const [selectedQuotation, setSelectedQuotation] = useState<any>();
  const [updating, setUpdating] = useState<boolean>(false);
  const apiCallInProgress = useRef<boolean>(false);
  const settings = useAppSelector(selectTenantSettings);
  const userInfo = useAppSelector(selectUserInfo);
  const [showAttachmentPopup, setShowHideAttachmentPopup] =
    useState<boolean>(false);
  const quoteSelectedFor = useRef<'PRINT' | 'ATTACHMENTS' | undefined>(
    undefined
  );

  useMemo(() => {
    const updateColumns = sortAndUpdateColumns(tableData.columnsMetaData || []);
    updateColumns.forEach((column) => {
      if (column.columnCode === COLUMN_CODE.QUOTE.TOTAL_AMOUNT) {
        column.renderer = (obj: any) => {
          const currencyCode = getCurrencySymbolFromCode(
            obj?.rowData?.currency
          );
          return (
            <div className="row justify-content-end">
              {toCurrencyFormat(obj.value, currencyCode)}
            </div>
          );
        };
      }
      if (column.columnCode === COLUMN_CODE.QUOTE.QUOTE_DATE) {
        column.name =
          userInfo?.country === COUNTRY_CODES.US
            ? 'Estimate Date'
            : 'Quote Date';
      }
    });
    setColumns(updateColumns);
  }, [tableData, userInfo]);

  useEffect(() => {
    fetchQuotesData();
  }, []);

  useEffect(() => {
    if (quoteSelectedFor.current === 'PRINT') {
      setShowHidePrintPopup(isNotEmpty(selectedQuotation));
    }
    if (quoteSelectedFor.current === 'ATTACHMENTS') {
      setShowHideAttachmentPopup(isNotEmpty(selectedQuotation));
    }
  }, [selectedQuotation]);

  useEffect(() => {
    fetchQuotesData();
  }, [searchText, pagination, filters, dateFilters]);

  const getPayload = () => ({
    limit: pagination.limit,
    page: pagination.page,
    search: searchText,
    fetchAttachmentDetails: true,
    query: getQueryFromFilter(
      columns,
      filters?.query,
      dateFilters,
      BOOKS_DOCS_TYPES.BOOKS_QUOTE
    )?.join(','),
    sortDir: pagination.sortDir || 'DESC',
    sort: pagination.sort
  });

  const fetchQuotesData = () => {
    if (!apiCallInProgress.current) {
      apiCallInProgress.current = true;
      setUpdating(true);
      dispatch(fetchQuotes(getPayload())).finally(() => {
        apiCallInProgress.current = false;
        setUpdating(false);
      });
    }
  };

  const setQuotationForPrint = (invoice: any) => {
    setSelectedQuotation(invoice);
    quoteSelectedFor.current = 'PRINT';
  };
  const getContextMenuForRow = (invoice: any) => {
    const menuItems = [];
    menuItems.push({
      title: 'View',
      icon: DKIcons.ic_view,
      onClick: ({ rowIndex, rowData }: any) => setQuotationForPrint(rowData)
    });
    return menuItems;
  };

  const getUpdatedRows = (rows: any[]) => {
    let updatedRows = [];
    if (isNotEmpty(rows)) {
      updatedRows = deepClone(rows);
      updatedRows = updatedRows.map((row, index) => ({
        ...row,
        status: [row.status],
        fulfillmentStatus: [row.fulfillmentStatus],
        [COLUMN_CODE.QUOTE.CONTACT]: row?.contact.name,
        // rowButtons: getActionButtons(row),
        currencyCode: row.currency,
        rowContextMenu: getContextMenuForRow(row),
        rowType: 'QUOTATION',
        [COLUMN_CODE.QUOTE.ID]: row.id,
        [COLUMN_CODE.QUOTE.NUMBER]: row.documentSequenceCode,
        [COLUMN_CODE.QUOTE.QUOTE_DATE]: getDateFromString(
          row.documentDate,
          DATE_FORMATS['DD-MM-YYYY']
        ),
        [COLUMN_CODE.QUOTE.RECURRING]: row.recurring
          ? ['RECURRING']
          : ['NON_RECURRING'],
        [COLUMN_CODE.QUOTE.DUE_DATE]: getDateFromString(
          row.validTillDate,
          DATE_FORMATS['DD-MM-YYYY']
        ),
        shipByDate: getDateFromString(
          row.shipByDate,
          DATE_FORMATS['DD-MM-YYYY']
        )
      }));
    }

    return updatedRows;
  };

  const onSearch = debounce((searchQuery: string) => {
    setSearchText(searchQuery);
  }, 500);

  const getActionButtonsColumn = () => {
    return {
      key: 'actions',
      name: 'Actions',
      type: INPUT_TYPE.BUTTON,
      width: 110,
      options: []
    };
  };
  const onFilter = (data: any) => {
    setFilters(data);
  };
  const onPageChange = (page: number) => {
    setPagination({ ...pagination, page: page });
  };
  const getDateRangePicker = () => {
    return (
      <div className="position-absolute shadow-m bg-white z-index-6 top-12 right-20">
        <DKDateRangePicker
          className="border shadow "
          onClose={() => setShowHideCalendar(false)}
          color={settings?.theme || DEFAULT_THEME_COLOR}
          showPresetList={true}
          startDate={dateFilters.financialStartDate}
          selectedStartDate={dateFilters.financialStartDate}
          selectedEndDate={dateFilters.financialEndDate}
          onSelectDateRange={(startDate: Date, endDate: Date) => {
            if (startDate && endDate) {
              setShowHideCalendar(false);
              setDateFilters({
                financialStartDate: startDate,
                financialEndDate: endDate
              });
            }
          }}
        />
      </div>
    );
  };
  const getDateRangeString = () => {
    if (isNotEmpty(dateFilters)) {
      return (
        getDateStrFromDate(dateFilters.financialStartDate) +
        ' to ' +
        getDateStrFromDate(dateFilters.financialEndDate)
      );
    } else {
      return '';
    }
  };
  const getUpdatedColumns = () => {
    let updatedColumns = [...columns];
    updatedColumns = updatedColumns.filter((item) =>
      VISIBLE_COLUMNS_CODES.includes(item.columnCode)
    );
    updatedColumns.push({
      name: `Linked Invoices`,
      type: 'text',
      index: updatedColumns?.length - 1,
      options: null,
      required: false,
      width: 200,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'linkedSalesInvoices',
      key: 'linkedSalesInvoices',
      allowFilter: false,
      renderer: ({ rowData }: any) => {
        if (isEmptyObject(rowData?.linkedSalesInvoices)) {
          return '-';
        }
        return (
          <>
            {rowData?.linkedSalesInvoices?.map((doc: any) => {
              return (
                <div className="row justify-content-between">
                  <div className="column">{doc.documentSequenceCode}</div>
                </div>
              );
            })}
          </>
        );
      }
    });

    updatedColumns.push({
      name: 'Attachments',
      type: 'text',
      index: 12,
      options: [],
      required: true,
      width: 153,
      editable: false,
      hidden: false,
      uiVisible: true,
      columnCode: 'attachments',
      dataSource: [],
      key: 'attachments',
      allowAddOption: false,
      allowFilter: false,
      id: '4rXh',
      datasource: null,
      formula: null,
      renderer: (obj: any) =>
        attachmentRenderer(obj, () => {
          quoteSelectedFor.current = 'ATTACHMENTS';
          setSelectedQuotation(obj.rowData);
        }),
      currency: null
    });

    return [...updatedColumns, getActionButtonsColumn()];
  };
  const getDocumentGridHeaderButtons = () => {
    let buttons = [];
    buttons.push({
      title: getDateRangeString(),
      className: 'border-m bg-white ml-r position-relative',
      icon: DKIcons.ic_calendar,
      onClick: () => setShowHideCalendar(true)
    });
    return buttons;
  };

  return (
    <div className="column parent-width mb-s position-relative flex-1">
      <div className="row justify-content-between p-l border-radius-m border-m bg-deskera-secondary">
        <DKLabel
          text={`💼 Manage ${
            userInfo.country === COUNTRY_CODES.US ? 'Estimates' : 'Quotes'
          } here! You can view, download, print ${
            userInfo.country === COUNTRY_CODES.US ? 'estimates' : 'quotes'
          }  and even make payments directly for any ${
            userInfo.country === COUNTRY_CODES.US ? 'estimates' : 'quotes'
          }.`}
          className=""
        />
      </div>
      <div className="column pt-xl parent-width mb-s position-relative flex-1">
        <div className="parent-width row position-relative">
          <div
            className="position-absolute z-index-3 mobile-left-0"
            style={{ right: 630, top: 45 }}
          >
            {showHideCalendar && getDateRangePicker()}
          </div>
        </div>
        <DocumentGrid
          title={`📋 My ${
            userInfo?.country === COUNTRY_CODES.US ? 'Estimates' : 'Quotes'
          }`}
          documentType={'Quotations'}
          rows={getUpdatedRows(quotesData?.content || [])}
          columns={getUpdatedColumns()}
          needBoldTheme={true}
          allowSearch={true}
          needTrailingColumn={true}
          allowFilter={true}
          currentPage={pagination.page}
          onSearch={onSearch}
          totalPageCount={quotesData?.totalPages}
          onPageChange={onPageChange}
          onFilter={onFilter}
          filterData={filters?.query}
          allowRowEdit={true}
          headerButtons={getDocumentGridHeaderButtons()}
          updating={updating}
          onRowOpenClick={({ rowData }: any) => setQuotationForPrint(rowData)}
        />
      </div>

      {showHidePrintPopup && (
        <PrintPreview
          category="QUOTE"
          document={{
            ...selectedQuotation,
            documentCode: selectedQuotation?.quotationCode,
            documentType: 'QUOTATION'
          }}
          event="PRINT"
          onClose={() => {
            setShowHidePrintPopup(false);
            setSelectedQuotation(null);
          }}
        />
      )}

      {showAttachmentPopup && (
        <AttachmentPopup
          document={selectedQuotation}
          entityType="QUOTATION"
          attachments={selectedQuotation?.attachmentsWithLink || []}
          onClose={() => {
            setShowHideAttachmentPopup(false);
            setSelectedQuotation(null);
          }}
          onUpload={() => {
            fetchQuotesData();
            setShowHideAttachmentPopup(false);
            setSelectedQuotation(null);
          }}
        />
      )}
    </div>
  );
};

export default Quotations;
