import {
  createAsyncThunk,
  createSelector,
  createSlice
} from '@reduxjs/toolkit';
import { RootState } from '..';
import {
  fetchCurrentContactInfo,
  getInvoices,
  getInvoiceSummary,
  getProducts,
  getQuotes,
  getTenantDetails
} from '../../services/books';
import { getTables } from '../../services/tables';
import { COUNTRIES_ALLOWED_FOR_SALES_ORDER } from '../../constants/Constant';
interface BooksInitialState {
  quotes: any;
  invoices: any;
  invoiceSummary: any;
  tables: any;
  paymentReceipts: any;
  products: any;
  tenantDetails: any;
  loggedInContact: any;
}
const initialState: BooksInitialState = {
  quotes: {},
  invoices: {},
  tables: {},
  paymentReceipts: {},
  products: {},
  tenantDetails: {},
  invoiceSummary: {},
  loggedInContact: {}
};
export const fetchTables = createAsyncThunk(
  'books/getTables',
  async (data: any, thunkAPI) => {
    const response = await getTables(data);
    return response;
  }
);
export const fetchInvoices = createAsyncThunk(
  'books/getInvoice',
  async (data: any, thunkAPI) => {
    const response = await getInvoices(data);
    return response;
  }
);
export const fetchInvoiceSummary = createAsyncThunk(
  'books/getInvoiceSummary',
  async (data: any, thunkAPI) => {
    const response = await getInvoiceSummary(data);
    return response;
  }
);
export const fetchQuotes = createAsyncThunk(
  'books/getQuotes',
  async (data: any, thunkAPI) => {
    const response = await getQuotes(data);
    return response;
  }
);
export const fetchProducts = createAsyncThunk(
  'books/fetchProducts',
  async (data: any, thunkAPI) => {
    const response = await getProducts(data);
    return response;
  }
);

export const fetchLoggedInContact = createAsyncThunk(
  'books/fetchLoggedInContact',
  async (data: any, thunkAPI) => {
    const response = await fetchCurrentContactInfo(data);
    return response;
  }
);

export const fetchTenantDetails = createAsyncThunk(
  'books/fetchTenantDetails',
  async (data: any, thunkAPI) => {
    const response = await getTenantDetails();
    return response;
  }
);

export const booksSlice = createSlice({
  name: 'books',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTables.fulfilled, (state, action) => {
        state.tables = action.payload;
      })
      .addCase(fetchInvoices.fulfilled, (state, action) => {
        state.invoices = action.payload;
      })
      .addCase(fetchInvoiceSummary.fulfilled, (state, action) => {
        state.invoiceSummary = action.payload;
      })
      .addCase(fetchQuotes.fulfilled, (state, action) => {
        state.quotes = action.payload;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.products = action.payload;
      })
      .addCase(fetchLoggedInContact.fulfilled, (state, action) => {
        state.loggedInContact = action.payload;
      })
      .addCase(fetchTenantDetails.fulfilled, (state, action) => {
        state.tenantDetails = action.payload;
      });
  }
});

const selfSelector = (state: RootState) => state;

export const selectTablesData = (objectType: string) =>
  createSelector(selfSelector, (state) => {
    const data = state?.books?.tables?.data || [];
    return data.find((table: any) => table.objectType === objectType);
  });
export const selectInvoices = createSelector(
  selfSelector,
  (state) => state.books.invoices
);

export const selectInvoiceSummary = createSelector(
  selfSelector,
  (state) => state.books.invoiceSummary
);
export const selectQuotes = createSelector(
  selfSelector,
  (state) => state.books.quotes
);
export const selectProducts = createSelector(
  selfSelector,
  (state) => state.books.products
);
export const selectLoggedInContact = createSelector(
  selfSelector,
  (state) => state.books.loggedInContact
);
export const selectTenantDetails = createSelector(
  selfSelector,
  (state) => state.books.tenantDetails
);
export const selectIsSOUnable = createSelector(
  selfSelector,
  (state) =>
    state.books?.tenantDetails?.additionalSettings?.ENABLE_SO &&
    COUNTRIES_ALLOWED_FOR_SALES_ORDER.includes(
      state.books?.tenantDetails?.country
    )
);
