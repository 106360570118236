import React, { Component } from 'react';
import '../../DocPreview.css';
import '../../CommonStyles/FontStyle.css';
import { FONT_SIZE, LINE_ITEM_HEIGHT, NEGATIVE_PAGE_PADDING, PAGE_PADDING } from '../../Constants/Constants';
import DKCustomFieldSection from '../../DKUILibrary/DKCustomFieldSection';
import DKEInvoiceSection, { eInvoiceSectionPosition } from '../../DKUILibrary/DKEInvoiceSection';
import DKSignatureSection from '../../DKUILibrary/DKSignatureSection';
import { AddressType } from '../../Helper/AddressParser';
import ComponentManager from '../../Manager/ComponentManager';
import DocumentManager from '../../Manager/DocumentManager';
import { getLocalisedText } from '../../Translate/LanguageManager';
import { HTMLTag } from '../../Utilities/HTMLTag';
import Utility, { getCapitalized, getFontSizeClass, getIsFulfillment, getIsGoodsReceipt, getIsJournalEntry, getPageHeight, getPX, getVW } from '../../Utilities/Utility';
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";

// import {StyleSheet} from "react";

export default class DocumentTemplate32 extends Component {
    arabicHeaderWidth = '88%'
    styleTemp7 = {
        mainContainer: {
            // alignItems: 'center',
            // justifyContent: 'center',
            position: "relative",
            width: "100%",
            height: "100%",
        }
    };
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ justifyContent: 'space-between', minHeight: getPageHeight(this.state.data.printScale) }}>
                <div className='parent-width' style={{ width: '100%', paddingBottom: getVW(30)  }}>
                    <DKEInvoiceSection
                        data={this.state.data}
                        position={eInvoiceSectionPosition.top}
                    />
                    {this.getHeaderSection()}
                    {ComponentManager.addVerticalSpace(12.5)}
                    {Utility.getIsShowPlaceOfSupply(this.state.data) && this.getPlaceOfSupplyInfoSection()}
                    {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.addVerticalSpace(12.5)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} customFieldPosition={'top'}/>
                    {ComponentManager.addVerticalSpace(12.5)}
                    {Utility.getIsContainsTotalSection(this.state.data) && this.getDueAmountDateSection()}
                    {Utility.getIsShowAccountProductTable(this.state.data) && this.getProductTableSection()}
                    {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(getPX(getVW(PAGE_PADDING, true)))}
                    {this.getTableSection()}
                    {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(getPX(getVW(PAGE_PADDING, true)))}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} customFieldPosition={'bottom'} />
                    <div id={HTMLTag.SIGNATURE}>
                        {ComponentManager.addVerticalSpace(60)}
                        {this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature, this.state.data.printScale)}
                    </div>
                </div>
                <div className='parent-width' style={{ width: '100%' }}>
                    {this.getFooterSection()}
                </div>
                <DKEInvoiceSection
                    data={this.state.data}
                    position={eInvoiceSectionPosition.bottom}
                />
            </div>
        );
    }

    /////// Header Section ///////////
    getHeaderSection() {
        return (
            <div className="RowDiv" style={{ width: '100%', alignItems: 'flex-end' }}>
                <div className="ColumnDiv" style={{ width: '50%' }}>
                    <div className='ColumnDiv parent-width'
                        style={{
                            alignItems: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                            width: Utility.getIsArabicLang() ? this.arabicHeaderWidth : '100%'
                        }}>
                        {this.state.data.showCompanyName && this.getCompanyNameTop()}
                        {this.getDocumentDetailsSection()}
                    </div>
                </div>
                <div className="ColumnDiv" style={{ width: '50%' }}>
                    {this.state.data.showClientBillToAddress && this.getBillingAddress()}
                    {this.state.data.showClientShipToAddress && this.getShippingAddress()}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && this.getSourceOfSupply()}
                </div>
            </div>
        )
    }

    getDocumentDetailsSection() {
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        return documentDetailList.map((element, index) => {
            var value = ''
            var fontStyle = index === documentDetailList.length - 1 ? 'docFontStyleBold' : "docFontStyle"
            if (element.key !== undefined) {
                value = this.state.data[element.key]
            }
            else if (element.customFieldValue !== undefined) {
                value = element.customFieldValue
            }

            return <>
                <text className={fontStyle + getFontSizeClass()}
                    style={{
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale),
                    }}>
                    {element.label + ": " + value}
                </text>
                {ComponentManager.addVerticalSpace(5)}
            </>
        });
    }

    getShippingAddress() {
        return <div className="ColumnDiv" style={{ width: '100%', alignItems: 'flex-end', overflow: 'hidden' }}>
            <text className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                    paddingBottom: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                    textAlign: 'right',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium, ownerType: Utility.getOwnerType(this.state.data, AddressType.shipTo) }, this.state.data.printScale)
                }}>
                    {getLocalisedText(this.state.data.shipToTitle).toUpperCase()}
            </text>
            <div className={"docFontStyle" + getFontSizeClass()}
                style={{
                    overflow: 'hidden',
                    textAlign: 'right',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.shipTo) }, this.state.data.printScale)
                }}
                    dangerouslySetInnerHTML={{__html: getLocalisedText(Utility.getClientShipToAddress(this.state.data))}}>
            </div>
        </div>;
    }

    getBillingAddress() {
        return <>
            <div className="ColumnDiv" style={{ width: '100%', alignItems: 'flex-end', overflow: 'hidden' }}>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        paddingBottom: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                        textAlign: 'right',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium, ownerType: Utility.getOwnerType(this.state.data, AddressType.billTo) }, this.state.data.printScale),
                    }}>{getLocalisedText(this.state.data.billToTitle).toUpperCase()}
                </text>
                <div className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        overflow: 'hidden',
                        textAlign: 'right',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.billTo) }, this.state.data.printScale),
                    }}
                        dangerouslySetInnerHTML={{__html: getLocalisedText(Utility.getClientBillToAddress(this.state.data))}}>
                </div>
            </div>
            {ComponentManager.addVerticalSpace(20)}
        </>;
    }

    getCompanyNameTop() {
        return <text className={"docFontStyleBold" + getFontSizeClass()}
            style={{
                paddingBottom: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }, this.state.data.printScale),
            }}>
                {getLocalisedText(Utility.getCompanyName(this.state.data))}
            </text>;
    }

    /////// Due Amount Date Section ///////////
    getDueAmountDateSection() {
        return (
            <div className="RowDiv">
                <div className="ColumnDiv" style={{
                    padding: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                    backgroundColor: this.state.data.themeColor,
                    borderTopLeftRadius: getVW(Utility.convertToPrintScale(3, this.state.data.printScale)),
                    borderBottomLeftRadius: getVW(Utility.convertToPrintScale(3, this.state.data.printScale)),
                    alignItems: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                }}>
                    <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            color: 'white',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }, this.state.data.printScale),
                        }}>{
                        getCapitalized(getLocalisedText("total")) + " " + getCapitalized(getLocalisedText('amount'))
                    }
                    </text>
                    {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(10, this.state.data.printScale))}
                    <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            color: 'white',
                            direction: Utility.getIsSaudiArabiaCountry(this.state.data) ? 'rtl' : 'ltr',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xLarge }, this.state.data.printScale),
                        }}>
                            {Utility.getTotalValue(this.state.data)}
                    </text>
                </div>

                {this.state.data.showDueDate && this.getDueDateColored()}
            </div>
        )
    }

    getDueDateColored() {
        return <div className="ColumnDiv" style={{
            padding: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
            backgroundColor: this.state.data.themeColor + 'CC',
            borderTopRightRadius: getVW(Utility.convertToPrintScale(3, this.state.data.printScale)),
            borderBottomRightRadius: getVW(Utility.convertToPrintScale(3, this.state.data.printScale)),
            alignItems: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
        }}>
            <text className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                    color: 'white',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }, this.state.data.printScale),
                }}>
                    {ComponentManager.getEndDateLabel(this.state.data.type)}
            </text>
            {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(10, this.state.data.printScale))}
            <text className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                    color: 'white',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xLarge }, this.state.data.printScale),
                }}>
                    {this.state.data.dueDate}
            </text>
        </div>;
    }

    /////// Table Section ///////////
    getTableSection() {
        return (
            <div style={{
              // paddingLeft: getVW(`${Utility.convertToPrintScale(Number(PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`, true),
              // paddingRight: getVW(`${Utility.convertToPrintScale(Number(PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`, true),
              paddingTop: getVW(Utility.convertToPrintScale(15, this.state.data.printScale)),
              // marginLeft: getVW(`${Utility.convertToPrintScale(Number(NEGATIVE_PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`, true),
              width: '100%'
            }}>
                {
                    TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                    <>
                        <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                            dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                        {ComponentManager.addVerticalSpace(5)}
                    </>
                }
                <div style={{ position: "relative", top: getVW(LINE_ITEM_HEIGHT), width: "100%", height: getVW(1), backgroundColor: this.state.data.themeColor, opacity: 0.2 }} />
                <div>
                    {this.getEditableDocumentTable()}
                    <div style={{ position: "relative", bottom: getVW(`${Utility.convertToPrintScale(Number(NEGATIVE_PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`, true), width: "100%", height: getVW(1), backgroundColor: this.state.data.themeColor, opacity: 0.2 }} />
                </div>
            </div>
        )
    }

    getProductTableSection() {
        return (
            <div style={{
                // paddingLeft: getVW(`${Utility.convertToPrintScale(Number(PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`, true),
                // paddingRight: getVW(`${Utility.convertToPrintScale(Number(PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`, true),
                paddingTop: getVW(Utility.convertToPrintScale(15, this.state.data.printScale)),
                // marginLeft: getVW(`${Utility.convertToPrintScale(Number(NEGATIVE_PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`, true),
                width: '100%'
            }}>
                {
                    TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                    <>
                        <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                            dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                        {ComponentManager.addVerticalSpace(5)}
                    </>
                }
                <div style={{ position: "relative", top: getVW(LINE_ITEM_HEIGHT), width: "100%", height: getVW(1), backgroundColor: this.state.data.themeColor, opacity: 0.2 }} />
                <div>
                    {this.getProductTable()}
                    <div style={{ position: "relative", bottom: getVW(NEGATIVE_PAGE_PADDING, true), width: "100%", height: getVW(1), backgroundColor: this.state.data.themeColor, opacity: 0.2 }} />
                </div>
            </div>
        )
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getProductTable() {
        if(Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKProductTable(
                Utility.getTableColumnForAccountProduct(),
                this.getTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerTextColor = this.state.data.themeColor;
        style.footerTextColor = 'white';
        style.headerBackgroundColor = "transparent";
        style.headerBorderColor = "transparent";
        style.itemBorderColor = "transparent";
        style.footerBackgroundColor = this.state.data.themeColor;
        style.footerBorderColor = "transparent";
        return style;
    }

    /////// Footer Section ///////////
    getFooterSection() {
        return (
            <div className="ColumnDiv" style={{ width: '100%', bottom: 0 }}>
                {(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) && <DKSignatureSection data={this.state.data} onSignatureSectionClicked={this.props.tableClicked} />}
                {ComponentManager.addDivider({
                    backgroundColor: this.state.data.themeColor,
                    height: getVW(Utility.convertToPrintScale(2, this.state.data.printScale)),
                    width: '100%'
                })}
                {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(20, this.state.data.printScale))}
                <div className="RowDiv" style={{ width: '100%', alignItems: 'flex-start' }}>
                    {this.getCompanySection()}
                    {((this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) || (this.state.data.showNotes && this.state.data.notes)) && this.getTermsAndNotesSection()}
                </div>
            </div>
        )
    }

    getCompanySection() {
        return <div className="ColumnDiv" style={{ width: '100%' }}>
            <div className="RowDiv" style={{ alignItems: 'center' }}>
                {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) && ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(80, this.state.data.printScale), {position: 'relative'})}
                <div className="ColumnDiv"
                    style={{
                        marginLeft: getVW(10),
                        width: '100%',
                        alignItems: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start'
                    }}>
                    {this.state.data.showCompanyName && this.getCompanyName()}
                    {this.state.data.showFrom && this.getCompanyAddress()}
                </div>
            </div>
        </div>;
    }

    getCompanyAddress() {
        return <div className={"docFontStyle" + getFontSizeClass()}
            style={{
                textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.from) }, this.state.data.printScale)
            }}
            dangerouslySetInnerHTML={{__html: getLocalisedText(Utility.getCompanyAddress(this.state.data))}}></div>;
    }

    getCompanyName() {
        return <text className={"docFontStyleBold" + getFontSizeClass()}
            style={{
                textAlign: 'left',
                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }, this.state.data.printScale),
            }}>
                {getLocalisedText(Utility.getCompanyName(this.state.data))}
            </text>;
    }

    getTermsAndNotesSection() {
        return <div id={HTMLTag.TERMS_NOTE_PREFIX} className="ColumnDiv" style={{ width: '100%', alignItems: 'center' }}>
            <div className="ColumnDiv" style={{ width: '100%', overflow: 'hidden' }}>
                {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) &&
                    ComponentManager.getDescriptionSection(
                        Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition')),
                        this.state.data.termsAndCondition,
                        "100%",
                        undefined,
                        undefined,
                        undefined,
                        getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                        undefined,
                        this.state.data.printScale
                    )}
                {((this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && (this.state.data.showNotes && this.state.data.notes)) && ComponentManager.addVerticalSpace(Utility.convertToPrintScale(20, this.state.data.printScale))}
                {(this.state.data.showNotes && this.state.data.notes) &&
                    ComponentManager.getDescriptionSection(
                        Utility.getNotesTitle(getIsJournalEntry(this.state.data.type) ? getLocalisedText('memo') : getLocalisedText('notes')),
                        this.state.data.notes,
                        "100%",
                        undefined,
                        undefined,
                        undefined,
                        getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                        undefined,
                        this.state.data.printScale
                    )}
            </div>
        </div>;
    }

    getPlaceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getPlaceOfSupply()}

            </div>
        )
    }
    getPlaceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('place_of_supply'),
                    this.state.data.placeOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>

        </div>;
    }

    getSourceOfSupply() {
        return <>
            {Utility.getIsShowSourceOfSupply(this.state.data) &&
                <>
                    {ComponentManager.addVerticalSpace(20)}
                    <div className="ColumnDiv" style={{ width: '100%', alignItems: 'flex-end', overflow: 'hidden' }}>
                        <text className={"docFontStyleBold" + getFontSizeClass()}
                            style={{
                                paddingBottom: getVW(5),
                                textAlign: 'right',
                                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: true }, this.state.data.printScale),
                            }}>{getLocalisedText('source_of_supply').toUpperCase()}
                        </text>
                        <div className={"docFontStyle" + getFontSizeClass()}
                            style={{
                                overflow: 'hidden',
                                textAlign: 'right',
                                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: true }, this.state.data.printScale),
                            }}
                            dangerouslySetInnerHTML={{ __html: this.state.data.sourceOfSupply }}>
                        </div>
                    </div>
                </>
            }

            {Utility.getIsShowDestinationOfSupply(this.state.data) &&
                <>
                {ComponentManager.addVerticalSpace(20)}
                <div className="ColumnDiv" style={{ width: '100%', alignItems: 'flex-end', overflow: 'hidden' }}>
                    <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            paddingBottom: getVW(5),
                            textAlign: 'right',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: true }, this.state.data.printScale),
                        }}>{getLocalisedText('destination_of_supply').toUpperCase()}
                    </text>
                    <div className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            overflow: 'hidden',
                            textAlign: 'right',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: true }, this.state.data.printScale),
                        }}
                        dangerouslySetInnerHTML={{ __html: this.state.data.destinationOfSupply }}>
                    </div>
                </div>
                {ComponentManager.addVerticalSpace(20)}
                </>
            }
        </>;
    }
}
